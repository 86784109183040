import auth from "../middlewares/authMiddleware";
import ShowTasks from "@/components/pages/Tasks/ShowTasks";
import EditTask from "@/components/pages/Tasks/EditTask";
import CreateTask from "@/components/pages/Tasks/CreateTask";

export default [
  {
    path: '/tasks',
    name: 'task.list',
    component: ShowTasks,
    meta: {
      middleware: [auth],
      title: 'Listes des tâches',
      permissions: ['TASKS_READ']
    }
  },
  {
    path: '/tasks',
    name: 'task.create',
    component: CreateTask,
    meta: {
      middleware: [auth],
      title: 'Créer une tâche',
      breadcrumbs: ['task.list'],
      permissions: ['TASKS_WRITE']
    }
  },
  {
    path: '/tasks/:task/edit',
    name: 'task.edit',
    component: EditTask,
    meta: {
      middleware: [auth],
      title: 'Modifier une tâche',
      readOnly: {supported: true, title: 'Voir une tâche'},
      breadcrumbs: ['task.list'],
      permissions: ['TASKS_WRITE']
    }
  },
]