// noinspection DuplicatedCode

import ApiService from './Base/BaseRepository';

const url = '/brands';


export default {
  /**
   * Fetches all brands.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await ApiService
      .setPagination(1000, 1)
      .get(`${url}/`);
  },

  /**
   * Fetches all brands.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0, showLoadingIndicator = true) {
    return await ApiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/`, {
        showLoadingIndicator
      });
  },

  /**
   * Fetches a single brand.
   *
   * @param brandId
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getSingle(brandId) {
    return await ApiService
      .get(`${url}/${brandId}`);
  },

  /**
   * Creates a new brand.
   *
   * @param values
   * @returns {Promise<AxiosResponse<any>>}
   */
  async create(values) {
    return await ApiService.post(`${url}/`, values);
  },

  async delete(brandId) {
    return await ApiService.delete(`${url}/${brandId}`);
  },

  /**
   * Updates the given brand.
   *
   * @param brandId
   * @param values
   * @returns {Promise<AxiosResponse<any>>}
   */
  async update(brandId, values) {
    return await ApiService.patch(`${url}/${brandId}`, values);
  }

}
