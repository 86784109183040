<template>
  <div class="whitespace-nowrap text-white rounded text-center py-1 px-1 bg-blue-400  whitespace-nowrap">
    <fa-icon fixed-width icon="home"/>
    x {{ quantity }}
  </div>
</template>

<script>
export default {
  name: "DeliveryQuantity",
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
  }
}
</script>

<style scoped>

</style>