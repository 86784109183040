<template>
  <div class="bg-white flex gap-x-4 rounded-md shadow-xl p-4">

    <checkbox-element
      v-model="showDriverPositions"
      :occupy-validation-space="false"
      hide-checked-label
      label="Montrer positions des livreurs"
      label-inline
      size="xsmall"
    />

    <checkbox-element
      v-model="showDeliveryOverlays"
      :occupy-validation-space="false"
      hide-checked-label
      label="Montrer informations livraisons"
      label-inline
      size="xsmall"
    />

    <checkbox-element
      v-model="hoverEffectsMap"
      :occupy-validation-space="false"
      hide-checked-label
      label="Déplacement de la carte (Mouseover in sidebar)"
      label-inline
      size="xsmall"
    />

    <checkbox-element
      v-model="hoverEffectsSidebar"
      :occupy-validation-space="false"
      hide-checked-label
      label="Défilement dans la sidebar (Mouseover livraison sur carte)"
      label-inline
      size="xsmall"
    />
  </div>
</template>

<script>
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";

export default {
  name: "RouteManagerSettingsBox",
  components: {CheckboxElement},
  computed: {
    hoverEffectsMap: {
      get() {
        return this.$store.state.routeManager.settings.hoverEffectsMap
      },
      set(bool) {
        this.$store.commit('routeManager/setHoverEffectOnMap', bool)
      }
    },
    hoverEffectsSidebar: {
      get() {
        return this.$store.state.routeManager.settings.hoverEffectsSidebar
      },
      set(bool) {
        this.$store.commit('routeManager/setHoverEffectOnSidebar', bool)
      }
    },
    showDriverPositions: {
      get() {
        return this.$store.state.routeManager.settings.showDriverPositions
      },
      set(bool) {
        this.$store.commit('routeManager/setShowDriverPositions', bool)
      }
    },
    showDeliveryOverlays: {
      get() {
        return this.$store.state.routeManager.settings.showDeliveryOverlays
      },
      set(bool) {
        this.$store.commit('routeManager/setShowDeliveryOverlays', bool)
      }
    }
  }
}
</script>

