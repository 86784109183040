<!-- Returns a clickable ID of the product, which opens a model with stock info & quick actions -->
<template>
  <div>
    <text-link
      @click="openModal"
    >
      #{{ id }}
    </text-link>

    <quick-action-modal-base
      v-if="product"
      ref="productModal"
      :links="getQuickActionButtons"
      :title="getProductName"
    >
      <div class="flex flex-col gap-4 sm:gap-8">
        <div class="flex flex-col md:flex-row items-center justify-between gap-4">

          <div class="flex-1">

            <div class="relative">
              <img :src="getProductImage" alt="Product"
                   class="object-contain w-full h-44 sm:h-96 mx-auto">

              <div v-if="hasCampaignsToDisplay"
                   class="absolute top-0 bottom-auto lg:flex flex-col space-y-1.5">
                <!-- Gift campaigns -->
                <product-card-gift-campaign-badge
                  v-for="campaign in getCampaignsToDisplay"
                  :key="campaign.id"
                  :name="campaign"
                  class="w-fit"

                />
              </div>
            </div>

            <div class="w-full text-center text-xl flex justify-center">
              <div v-if="getPriceHTMLToShow" v-html="getPriceHTMLToShow"></div>
            </div>
          </div>

          <div v-if="getProductStockValues"
               class="p-1 sm:p-4 bg-gray-100 flex flex-col gap-y-1 sm:ga-y-2 rounded-md w-full sm:w-80">

            <div class="flex items-center justify-between py-1 px-2 sm:px-0">
              <div class="font-bold text-lg text-center tracking-wide text-gray-700">
                Stock
              </div>

              <stock-rack
                :rack-number="getStockRackAddress"
              />
            </div>

            <badge v-if="getProductStockValues.real !== undefined"
                   :inset-text="getProductStockValues.real.toString()">
              Stock Réel
            </badge>

            <badge v-if="getProductStockValues.virtual !== undefined"
                   :inset-text="getProductStockValues.virtual.toString()">
              Stock Virtuel
            </badge>

            <badge v-if="getProductStockValues.packed !== undefined"
                   :inset-text="getProductStockValues.packed.toString()">
              Emballé
            </badge>

            <badge v-if="getProductStockValues.onShelf !== undefined"
                   :inset-text="getProductStockValues.onShelf.toString()">
              Sur etagéres
            </badge>

            <badge v-if="getProductStockValues.available !== undefined"
                   :inset-text="getProductStockValues.available.toString()">
              Stock disponible
            </badge>

            <badge v-if="getProductStockValues.locked !== undefined"
                   :inset-text="getProductStockValues.locked.toString()">
              Réservé
            </badge>

            <badge v-if="getProductStockValues.waiting !== undefined"
                   :inset-text="getProductStockValues.waiting.toString()">
              En attente de stock
            </badge>

            <badge v-if="getProductStockValues.inSupplierOrder !== undefined"
                   :inset-text="getProductStockValues.inSupplierOrder.toString()">
              Com fournisseur
            </badge>

            <badge v-if="getProductStockValues.reservedGift !== undefined"
                   :inset-text="getProductStockValues.reservedGift.toString()">
              Réservé Cadeau
            </badge>

          </div>
        </div>

        <div v-if="!$isReadOnly()"
             class="p-4 bg-gray-100 flex flex-col gap-y-2 rounded-md text-center w-full">
          <div>
            <div class="font-bold text-center mb-2 tracking-wide text-gray-700">
              Modification du stock rapide
            </div>

            <add-manual-stock-to-product-form
              :product-id="id"
              :show-custom-reason-field="false"
              :show-labels="false"
              @update="onStockChange"
            />
          </div>
        </div>
      </div>
    </quick-action-modal-base>
  </div>
</template>

<script>
import ProductRepository from "../../../../repositories/ProductRepository";
import Badge from "../../../elements/badges/Badge";
import AddManualStockToProductForm from "../Forms/Stock/AddManualStockToProductForm";
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import TextLink from "@/components/global/TextLink";
import StockRack from "@/components/global/StockRack/StockRack.vue";
import ProductCardGiftCampaignBadge from "@/components/pages/Products/Subcomponents/ProductCardGiftCampaignsBadge.vue";
import {centsToEuroString} from "@/helpers/mathHelper";
import QuickActionModalBase from "@/components/global/QuickActionModal/QuickActionModalBase.vue";

export default {
  name: "ProductQuickActionModal",
  components: {
    QuickActionModalBase,
    ProductCardGiftCampaignBadge, StockRack, TextLink, AddManualStockToProductForm, Badge
  },
  data: () => ({
    product: null,
  }),
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  methods: {
    getProduct() {
      return ProductRepository.getSingle(this.id, true).then((res) => {
        this.product = res.data.data;
      });
    },
    openModal() {
      return this.getProduct().then(() => {
        this.$nextTick(() => {
          this.$refs.productModal.openModal();
        })
      });
    },
    navigateToAction(routeObject, newTab = false) {
      this.$refs.productModal.closeModal();

      return newTab
        ? this.openRouteInNewTab(routeObject)
        : this.$router.push(routeObject);
    },
    onStockChange() {
      this.$emit('update');
      this.$refs.productModal.closeModal();
    }
  },
  computed: {
    getPriceHTMLToShow() {
      if (!this.getCampaigns || !this.getCampaigns.discountedPrice || !this.getCampaigns.lowestPriceInLast30Days) {
        return null;
      }

      return '<div class="flex gap-x-2 items-center">' +
        '<span class="text-gray-400 font-normal text-sm line-through">' + centsToEuroString(this.getCampaigns.lowestPriceInLast30Days) + '</span>' +
        '<span class="text-red-500 font-semibold" :class="getTextClass">' + centsToEuroString(this.getCampaigns.discountedPrice) + '</span>' +
        '</div>';
    },

    hasCampaignsToDisplay() {
      return this.getCampaigns?.nameToDisplay?.length > 0 || false;
    },
    getCampaignsToDisplay() {
      if (!this.hasCampaignsToDisplay)
        return [];

      return this.getCampaigns.nameToDisplay;
    },
    getCampaigns() {
      return this.product.campaigns;
    },
    getProductName() {
      return this.product?.name || '';
    },
    getProductStockValues() {
      return this.product?.stock?.values || [];
    },
    getProductImage() {
      return getPrimaryImageUrlFromImageObject(this.product, "medium");
    },
    getStockRackAddress() {
      return this.product?.pickingConfiguration?.stockAddressRack;
    },
    getQuickActionButtons() {
      return [
        {label: "Modifier", icon: "edit", route: {name: 'products.edit', params: {product: this.id}}},
        {
          label: "Info Packaging",
          icon: "info",
          route: {name: 'products.packaging', params: {product: this.id}}
        },
        {
          label: "Historique stock",
          icon: "history",
          route: {name: 'products.edit', params: {product: this.id}, query: {tab: 'stockTab'}}
        },
        {
          label: "Statistiques ventes",
          icon: "chart-line",
          route: {name: 'products.edit', params: {product: this.id}, query: {tab: 'orderStatsTab'}}
        },
      ];
    }
  },
}
</script>

