<template>
  <list-table
    :columns="columns"
    :filters="filters"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="timeClockTotalsTable"
  >
    <template v-slot:time-template="{row}">
            <span>
                {{ new Date(row.workedSeconds * 1000).toISOString().substring(11, 19) }}
            </span>
    </template>

    <template v-slot:time-template-difference="{row}">
            <span
              v-if="row.workedSecondsDifference"
              :class="{'text-red-500': row.workedSecondsDifference < 0}"
            >
              <p v-if="row.workedSecondsDifference < 0">- {{
                  new Date(Math.abs(row.workedSecondsDifference) * 1000).toISOString().substring(11, 19)
                }}</p>
              <p v-if="row.workedSecondsDifference > 0">+ {{
                  new Date(Math.abs(row.workedSecondsDifference) * 1000).toISOString().substring(11, 19)
                }}</p>
            </span>
    </template>
  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import {
  FILTER_DATETIME_RANGE,
  FILTER_MEMBER_EMPLOYEES,
} from "@/components/listTable/includes/controls/filters/availableFilters";
import TimeClockEntryRepository from "@/repositories/TimeClockEntryRepository";

export default {
  name: "ShowTotalTimeClockEntries",
  components: {ListTable},
  data() {
    return {
      employees: [],

      title: 'Pointage Total',
      repositoryFunc: TimeClockEntryRepository.getTotals,
      columns: [
        {
          caption: 'Member ID',
          dataField: 'memberId',
          width: 80,
        },
        {
          caption: 'Employé',
          dataField: 'memberName',
          sort: {
            dataField: 'memberFullName'
          }
        },
        {
          caption: 'Jours travaillés',
          dataField: 'workedDays',
        },
        {
          caption: 'Heures (+ pauses)',
          cellTemplate: 'time-template'
        },
        {
          caption: 'Différence sur jours',
          cellTemplate: 'time-template-difference'
        },
      ],
      filters: [
        {
          caption: "Employé",
          filterKey: "memberId",
          type: FILTER_MEMBER_EMPLOYEES,
          forceIsVisible: true
        },
        {
          filterKey: 'dateTimeBetween',
          type: FILTER_DATETIME_RANGE,
          caption: 'Date début entre',
          forceIsVisible: true
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>