<template>
  <router-link
    v-if="hasAccessPermission"
    v-slot="{href, route, navigate, isActive}"
    :to="to"
    custom
  >
    <div
      :class="[isActive ? 'text-white' : 'text-gray-400']"
      class="px-1 py-1 hover:text-gray-200 text-sm cursor-pointer rounded-lg w-full flex flex-row items-center gap-x-3"
      @click.exact="navigateFunc(false)"
      @click.ctrl.exact="navigateFunc(true)"
      @mousedown.middle="navigateFunc(true)"
      @click.meta="navigateFunc(true)"
    >
      <fa-icon
        :class="[isActive ? 'text-luxcaddy-500' : 'text-gray-400']"
        :icon="icon ?? 'caret-right'"
        fixed-width
      />
      <slot></slot>
    </div>
  </router-link>
</template>

<script>

import newTabMixin from "@/mixins/newTabMixin";
import {userHasPermission} from "@/helpers/permissionHelper";

export default {
  name: "SidebarNavLinkInDropDown",
  props: {
    icon: {
      type: String,
      required: false
    },
    to: {
      type: Object,
      required: false,
    },
    executeBefore: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    // Count up on SidbarDropDownMenu to determine whether we should show the parent.
    if (this.hasAccessPermission) {
      this.$parent.countUp();
    }
  },
  mixins: [newTabMixin],
  methods: {
    navigateFunc(newTab = false) {
      if (this.executeBefore) {
        this.$emit('before');
      }

      return newTab
        ? this.openRouteInNewTab(this.to)
        : this.$router.push(this.to);
    },
  },
  computed: {
    getResolvedRoute() {
      if (!this.to) {
        return null;
      }

      return this.$router.resolve(this.to);
    },
    hasAccessPermission() {
      if (this.getResolvedRoute === null) {
        return true;
      }
      const permissions = this.getResolvedRoute.route?.meta?.permissions || [];

      return userHasPermission(permissions, this.$store.getters['authUser/getPermissions']);
    }
  }
}
</script>

