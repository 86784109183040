<template>
  <div class="border border-gray-200 | flex flex-col md:flex-row">
    <div class="w-full p-2 md:p-0 md:w-12 bg-gray-100 flex md:items-center md:justify-center">
      <fa-icon :icon="icon" fixed-width/>
    </div>
    <div class="flex-1 p-2 pl-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderInfoCard",
  props: {
    icon: {
      type: String,
      required: true
    }
  }
}
</script>