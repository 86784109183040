<template>
  <div class="flex flex-col overflow-x-hidden">
    <dashboard-notification-menu class="z-40"/>
    <dashboard-navbar
      class="flex-none h-12 z-40"
      @refresh="forceRerender"
    />

    <dashboard-warning-modal></dashboard-warning-modal>

    <div class="flex-1 | flex relative md:static overflow-x-hidden ">

      <transition name="slide">
        <dashboard-sidebar
          v-show="isSidebarShown"
          class="flex-none w-screen md:w-72 absolute h-screen-minus-header md:static transition"
        />
      </transition>

      <div
        :key="componentKey"
        :class="isSidebarShown && !isMobile ? 'w-full-minus-sidebar' : 'w-full'"
        class="h-screen-minus-header flex-none overflow-auto transition flex flex-col vld-parent overflow-hidden"
      >
        <breadcrumb-bar/>

        <div
          class="w-full flex-1 overflow-x-hidden"
        >
          <div :class="{'pt-4 md:p-4 lg:p-6': usePadding}">
            <router-view></router-view>
            <loading-indicator/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isScreenSizeBelowBreakpoint} from "@/helpers/screenWidthHelper";
import DashboardNavbar from "./partials/DashboardNavbar";
import DashboardSidebar from "./partials/DashboardSidebar";
import DashboardWarningModal from "./partials/DashboardWarningModal";
import DashboardNotificationMenu from "@/components/layouts/dashboard/partials/notifications/DashboardNotificationMenu";
import BreadcrumbBar from "@/components/elements/breadcrumbs/BreadcrumbBar";
import LoadingIndicator from "@/components/elements/loading/LoadingIndicator";

export default {
  name: "DashboardLayout",
  components: {
    LoadingIndicator,
    BreadcrumbBar,
    DashboardNotificationMenu,
    DashboardSidebar,
    DashboardWarningModal, DashboardNavbar
  },
  data() {
    return {
      componentKey: 0
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    }
  },
  computed: {
    isSidebarShown() {
      return this.$store.getters['userInterface/isSidebarShown'];
    },
    usePadding() {
      if (this.$route.meta.padding !== undefined) {
        return this.$route.meta.padding;
      }
      return true;
    },
    isMobile() {
      return isScreenSizeBelowBreakpoint('md');
    }
  },
}
</script>