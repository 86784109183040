<template>
  <div class="inline-block has-tooltip group relative">
    <slot></slot>
    <div
      v-if="text && enabled"
      :class="tooltipClasses"
      class="invisible group-hover:visible absolute p-1 px-3 rounded-md border border-gray-700 bg-gray-800 shadow-lg text-gray-100 text-sm opacity-100"
      style="z-index: 9999;">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    text: {
      type: String,
      required: false
    },
    tooltipClasses: {
      type: String,
      default: 'ml-16 -mt-4'
    },
    enabled: {
      type: Boolean,
      default: true
    }
  }
}
</script>

