<template>
  <fulfilment-number-display
    :fulfilment-number="fulfilmentNumber"
    big
    class="cursor-pointer"
    @click="$emit('click', $event)"
  />
</template>

<script>
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";

export default {
  name: "PrintDeliveryFulfilmentLabelButton",
  components: {FulfilmentNumberDisplay},
  props: {
    fulfilmentNumber: {
      type: Number,
      required: true
    },
    deliveryId: {
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>

</style>