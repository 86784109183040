<template>
  <div>
    <page-title>Imprimer Étiquette Fulfilment</page-title>

    <card>
      <form-wrapper
        ref="form"
        :show-changed="true"
        submit-button-label="Imprimer"
        @submit="printFulfilmentLabel"
      >
        <number-input-element
          v-model="fulfilmentNumber"
          label="Numéro Fulfilment"
          validation-rules="required"
        />
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import FulfilmentRepository from "@/repositories/FulfilmentRepository";

export default {
  name: "PrintFulfilmentLabelPage",
  components: {NumberInputElement, FormWrapper, PageTitle, Card},
  data: () => ({
    fulfilmentNumber: null,
  }),
  methods: {
    printFulfilmentLabel(callback) {
      if (!this.fulfilmentNumber) {
        return false;
      }

      FulfilmentRepository.printLabel(
        this.fulfilmentNumber
      ).then(() => {
        this.fulfilmentNumber = null;
        this.$sendSuccessToast("Étiquette envoyé à l'imprimante!");
      }).finally(() => callback());
    }
  }
}
</script>

<style scoped>

</style>