<template>
  <div class="p-4">
    <scanner-listener
      :accept-quantity="false"
      @scan="onScan"
    />

    <div v-if="isInDevMode" class="flex items-center gap-x-2 mb-4">
      <div class="font-bold text-red-500">
        Debug
      </div>
      <touch-optimized-button @click="onScan({code: '11024361'})">Shelf</touch-optimized-button>
      <!-- /printShelfLabel -->
      <touch-optimized-button @click="onScan({code: '1139179007868'})">Product</touch-optimized-button>
      <!-- Product Barcode -->
      <touch-optimized-button
        type="secondary"
        @click="scannedShelfCode = null; scannedProductCode = null; scannedProductData = null;"
      >
        Reset
      </touch-optimized-button>
    </div>

    <modal
      ref="onShelfModal"
      :blocking="false"
      show-close-button
      title="Stock ajoutée!"
      type="light"

    >
      <slot>
        Stock sur étagère: {{ onShelfStock }}
      </slot>
      <template #footer>
        <div class="flex justify-end gap-4">
          <button-element type="primary" @click="$refs.onShelfModal.closeModal()">
            <fa-icon class="mr-2" fixed-width icon="check"></fa-icon>
            OK
          </button-element>
        </div>
      </template>
    </modal>

    <div class="p-4 bg-gray-200">
      <product-income-manage-product-interface
        v-if="scannedProductData !== null"
        :product="scannedProductData"
        @cancel="resetScannedCodes"
        @done="onArrivalDone"
      />
      <div v-else class="text-2xl text-center">
        <div v-if="hasScannedProductCode && !hasScannedShelfCode" class="text-3xl text-center">
          Étagère: {{ returnedRackNumber }}
        </div>

        <div v-else>
          Scan étiquette Étagère
          <div :class="hasScannedShelfCode ? 'text-green-600 font-bold' : 'text-red-600'">
            <div v-if="loading" class="flex justify-center">
              <loading-svg-inline class="w-24 h-24"/>
            </div>
            <div v-else>
              <span v-if="!hasScannedShelfCode">En attente scan étagère...</span>
              <span v-else>Scan OK [{{ scannedShelfCode }}]</span>
            </div>
          </div>

          <div v-if="hasScannedShelfCode" class="mt-4">
            Scan étiquette Produit
            <div :class="hasScannedProductCode ? 'text-green-600 font-bold' : 'text-red-600'">
              <span v-if="!hasScannedProductCode">En attente scan produit...</span>
              <span v-else>Scan OK [{{ scannedProductCode }}]</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScannerListener from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/scanner/ScannerListener";
import ProductRepository from "@/repositories/ProductRepository";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline";
import ProductIncomeManageProductInterface
  from "@/components/pages/TouchInterface/Stock/ProductIncome/Components/ProductIncomeManageProductInterface";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import Modal from "@/components/elements/modals/Modal.vue";

export default {
  name: "ProductIncomeInterfacePage",
  components: {
    Modal,
    ButtonElement,
    TouchOptimizedButton, ProductIncomeManageProductInterface, LoadingSvgInline, ScannerListener
  },
  data: () => ({
    loading: false,

    scannedShelfCode: null,
    returnedProductId: null,
    scannedProductData: null,

    scannedProductCode: null,
    returnedRackNumber: null,

    onShelfStock: null,
  }),
  methods: {
    onScan({code}) {
      // Don't allow scanning if the user is managing a product. Has to cancel or save manually first.
      if (this.scannedProductData !== null) {
        return false;
      }

      // Shelf Barcodes start with 11 and have a length of 8
      if (code.substring(0, 2) === '11' && code.length === 8) {
        this.scanShelfBarcode(code);
      } else {
        this.scanProductBarcode(code);
      }
    },
    scanShelfBarcode(barcode) {
      this.loading = true;
      this.returnedProductId = null;

      // Check if product id encoded in barcode is part of an untreated supplier order item.
      ProductRepository
        .inSupplierOrder
        .getProductIdByShelfBarcode(barcode)
        .then((res) => {
          const productId = res.data.data.productId;
          if (productId) {
            this.scannedShelfCode = barcode;
            this.returnedProductId = productId;
          } else {
            this.$showErrorDialog("Erreur: Produit pas en commande fournisseur.");
          }
        }).finally(() => this.loading = false);
    },
    scanProductBarcode(barcode) {
      // Close the modal showing stock from previous add if still open.
      if (this.$refs.onShelfModal) {
        this.$refs.onShelfModal.closeModal();
      }

      // If a shelf code has been previously scanned, we show the interface to set the arrived quantity
      // Otherwise, we show in which shelf this product is stocked.
      this.returnedRackNumber = null;
      this.scannedProductCode = null;

      if (this.hasScannedShelfCode) {
        ProductRepository.inSupplierOrder.getProductByBarcode(
          barcode,
          this.returnedProductId
        ).then(res => {
          this.scannedProductCode = barcode;
          this.scannedProductData = res.data.data;
        })
      } else {
        ProductRepository.inSupplierOrder.getProductByBarcode(
          barcode
        ).then(res => {
          this.returnedRackNumber = res.data.data.rackNumber;
          this.scannedProductCode = barcode;
        })
      }
    },
    resetScannedCodes() {
      this.scannedShelfCode = null;
      this.returnedProductId = null;
      this.scannedProductData = null;
      this.scannedProductCode = null;
      this.returnedRackNumber = null;
    },
    // User has successfully entered the arrival for a product.
    // We will now display a modal with the current stock.
    onArrivalDone(productId) {
      this.onShelfStock = null;
      ProductRepository.getOnShelfStock(productId).then(res => {
        this.onShelfStock = res.data.data.onShelfStock;
        this.$nextTick(() => {
          this.$refs.onShelfModal.openModal();
          this.resetScannedCodes();
        });
      });
    }
  },
  computed: {
    hasScannedShelfCode() {
      return this.scannedShelfCode !== null;
    },
    hasScannedProductCode() {
      return this.scannedProductCode !== null;
    },
    isInDevMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
}
</script>