import NotificationRepository from "@/repositories/NotificationRepository";
import {vue} from "@/main";

export default {
  state: {
    isMenuOpen: false,
    initiallyLoaded: false,

    notifications: [],
    lastCheck: null,


    pagination: {
      currentPage: 1,
      lastPage: null,
      from: null,
      to: null,
    }
  },
  getters: {
    isMenuOpen: state => state.isMenuOpen,
    hasLoadedInitially: state => state.initiallyLoaded,
    hasUnreadNotifications: state => {
      return state
        .notifications
        .filter(n => !n.isRead)
        .length > 0;
    },
    getNotifications: state => state.notifications,
    getUnreadNotifications: state => state.notifications.filter(n => !n.isRead),
    getUnreadNotificationsCount: state => state.notifications.filter(n => !n.isRead).length,

    getLatestUnreadCriticalMessage: state => {
      let notifications = state
        .notifications
        .filter(n => !n.isRead)
        .filter(n => n.type === "danger")

      return notifications.length > 0 ? notifications[0] : null;
    },

    canGoToNextPage: state => state.pagination.currentPage < state.pagination.lastPage,
    canGoToPreviousPage: state => state.pagination.currentPage > 1,
    getCurrentPage: state => state.pagination.currentPage,
  },
  mutations: {
    setMenuOpen(state, bool) {
      state.isMenuOpen = bool;
    },
    setNotifications(state, notifications) {
      // Ignore initial request when entering page
      if (state.lastCheck !== null) {
        const newNotifications = notifications
          .filter(n => {
            let notificationCreatedAt = vue.$date(n.createdAt);

            return notificationCreatedAt.isAfter(state.lastCheck);
          });

        if (newNotifications.length > 0) {
          newNotifications.forEach(n => {
            vue.$sendNotificationToast(n);
          })
        }
      }

      state.lastCheck = vue.$date();
      state.notifications = notifications;
    },

    setPagination(state, {currentPage, lastPage, from, to}) {
      state.pagination.currentPage = currentPage;
      state.pagination.lastPage = lastPage;
      state.pagination.from = from;
      state.pagination.to = to;
    },
    toggleRead(state, notificationId) {
      let notification = state
        .notifications
        .find(n => n.id === notificationId);

      if (notification) {
        notification.isRead = !notification.isRead;
      }
    },

    setLoadedInitially(state) {
      state.initiallyLoaded = true;
    }
  },
  actions: {
    fetchNotifications({commit}, page) {
      return NotificationRepository
        .getPaginated(30, page, [
          {
            dataField: 'createdAt',
            sort: 'desc'
          }
        ])
        .then((res) => {
          commit('setNotifications', res.data.data);
          commit('setPagination', res.data.meta);
        }).finally(() => {
          commit('setLoadedInitially');
        })
    },
    toggleNotificationRead({commit}, notificationId) {
      commit('toggleRead', notificationId);

      return NotificationRepository
        .toggleRead(notificationId)
        .catch(() => {
          commit('toggleRead', notificationId);
        });
    }
  },
}