<template>
  <div class="container mx-auto p-4">
    <div class="mt-3 md:mt-6 text-center text-5xl">
      Luxcaddy Touch Interface
    </div>

    <touch-optimized-button class="mt-16" @click="openMenu">
      Ouvrir Menu
    </touch-optimized-button>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";

export default {
  name: "TouchInterfaceLandingPage",
  components: {TouchOptimizedButton},
  mounted() {
    this.openMenu();
  },
  methods: {
    openMenu() {
      this.$parent.$refs.navbar.openNavMenu();
    }
  },
}
</script>

<style scoped>

</style>