<template>
  <div>
    <div class="flex flex-wrap items-center gap-2">
      <div>
        <form-label :label="label" :show-required-mark="isInReadOnlyMode ? false : isRequired"></form-label>

      </div>
      <span v-if="displayCharCounter" class="block text-gray-500 text-xs">
        ({{ getValueLength }} chars)
      </span>
    </div>

    <validation-provider ref="validationProvider" v-slot="{ errors }" :rules="validationRules" slim>

      <div
        v-if="!isInReadOnlyMode"
        :class="[(aggressiveErrorDisplay && errors.length) ? 'focus-within:ring-red-200' : 'focus-within:ring-gray-100']"
        class="flex flex-row mt-1 shadow border border-gray-100 text-gray-700 border-gray-200  rounded-md focus-within:ring bg-white focus-within:ring">
                <textarea
                  ref="input"
                  v-model="model"
                  :class="[additionalInputClasses]"
                  :disabled="isDisabled"
                  :rows="$attrs?.rows ?? 3"
                  class="appearance-none w-full p-2 pl-4 outline-none border-none rounded-md flex-1"
                ></textarea>

      </div>

      <div v-else-if="isInReadOnlyMode">
        <div v-if="value" class="flex items-stretch mt-1 flex-row justify-start">
          <span class="flex-initial text-lg">{{ value }}</span>
        </div>
        <div v-else>
          <i>/</i>
        </div>
      </div>

      <input-error-message v-if="!isInReadOnlyMode && occupyValidationSpace" :errors="errors"/>

    </validation-provider>
  </div>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
import InputErrorMessage from "../partials/InputErrorMessage";
import FormLabel from "../partials/FormLabel";


export default {
  name: "TextAreaElement",
  components: {FormLabel, InputErrorMessage, ValidationProvider},
  props: {
    displayCharCounter: {
      type: Boolean,
      default: false
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true
    },
    validationRules: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    additionalInputClasses: {
      type: String,
      required: false,
      default: ''
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({}),
  watch: {
    value: function () {
      if (this.$store.getters['userInterface/isValidationInAggressiveErrorDisplayMode']) {
        this.$store.commit('userInterface/setValidationAggressiveErrorDisplayMode', false);
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.isInReadOnlyMode)
          return false;

        this.$emit('input', value);
        this.$emit('blur', value);
      }
    },
    /**
     * Determines if the input is required to be filled out.
     *
     * @return {boolean}
     */
    isRequired() {
      if (!this.validationRules) {
        return false;
      }

      let rules = this.validationRules.split('|');
      return rules.includes("required");
    },
    /**
     * Determines if the input should show validation errors in aggressive mode (red text).
     *
     * @return {any}
     */
    aggressiveErrorDisplay() {
      return this.$store.getters['userInterface/isValidationInAggressiveErrorDisplayMode'];
    },
    /**
     * Determines if the input should be shown in read only mode.
     */
    isInReadOnlyMode() {
      return this.$isReadOnly();
    },
    isDisabled() {
      return this.$isReadOnly() || this.disabled;
    },

    getValueLength() {
      if (this.value) {
        return this.value.length
      }

      return 0;
    }
  },
}
</script>

