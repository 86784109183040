<template>
  <div>
    <button-element
      :disabled="disabled"
      type="dark"
      @click="openModal"
    >
      <fa-icon fixed-width icon="check"/>
      Finaliser facture
    </button-element>

    <delete-confirmation-modal
      ref="modal"
      :delete-button-icon="null"
      delete-button-label="Finaliser"
      show-close-button
      title="Finaliser facture"
      @delete="finalizeInvoice"
    >
      <div class="text-center">
        Êtes vous sûre que vous voulez finaliser cette facture?<br>
        <div v-if="isInvoiceInternal" class="font-bold mt-4">
          Il ne peut pas être modifié ou supprimé après avoir été finalisé.
        </div>
      </div>
    </delete-confirmation-modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "@/eventBus";
import ExternalInvoicePDFRepository from "@/repositories/ExternalInvoicePDFRepository";

export default {
  name: "InvoiceFinalizeButton",
  components: {DeleteConfirmationModal, ButtonElement},
  computed: {
    ...mapGetters('invoices', [
      'getInvoiceId',
      'isInvoiceInternal'
    ])
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isAccountingInterface: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.isAccountingInterface) {
      // Shortcut / Shortkey pressed
      eventBus.$on('invoice-edit-finalize-and-next', () => {
        if (this.disabled) {
          return this.$showErrorDialog('Button pour finaliser désactivé.');
        } else {
          this.finalizeInvoice().then(() => {
            // Treat next Invoice.
            // If was editing Invoice with PDF attached
            //      -> Redirect to create new invoice with treat next oldest PDF
            // If was editing regular invoice without PDF
            //      -> Just redirect to create new

            if (this.$store.getters["invoices/isTreatingInvoicePDF"]) {
              ExternalInvoicePDFRepository
                .getOldestUnlinkedExternalInvoicePDFId()
                .then((res) => {
                  this.$sendInfoToast("Redirection vers le prochain PDF de facture externe non traité.");
                  this.$router.push({
                    name: 'accounting.invoices.create',
                    query: {
                      bookExternalInvoicePdfId: res.data.data.id
                    }
                  })
                })
                .catch(() => {
                  // Error. No More PDF left to treat probably so redirect to the regular form.
                  this.$sendInfoToast("Redirection vers l'interface normale de création de factures puisqu'il n'y a plus de PDF à traiter.");
                  this.$router.push({
                    name: 'accounting.invoices.create',
                  });
                });
            } else {
              this.$router.push({
                name: 'accounting.invoices.create',
              });
            }
          });
        }
      });
    }
  },
  beforeDestroy() {
    eventBus.$off('invoice-edit-finalize-and-next');
  },
  methods: {
    ...mapActions('invoices', [
      'refetchCurrentInvoice'
    ]),
    openModal() {
      this.$refs.modal.openModal();
    },
    finalizeInvoice() {
      let req = this.isInvoiceInternal
        ? InvoiceRepository
          .internal
          .finalizeInvoice(this.getInvoiceId)
        : InvoiceRepository
          .external
          .finalizeInvoice(this.getInvoiceId);

      return req.then(() => {
        this.$sendSuccessToast("Facture finalisée!");
        this.refetchCurrentInvoice();
      });
    }
  },
}
</script>

