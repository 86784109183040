<template>
  <div>
    <div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">

        <!-- Left side -->
        <div>
          <h2 class="font-bold text-lg mb-4">Articles dans catégorie</h2>

          <card type="light">

            <div class="gap-y-4">

              <selected-blog-posts-in-category-actions
                ref="actions"
                :selected-blog-posts="selectedBlogPosts"
                @change="fetchBlogPostsInCategory"
                @remove="removeBlogPostIds"
              />

              <div v-if="!$isReadOnly()"
                   class="flex flex-wrap items-center justify-between gap-4">

                <div class="flex gap-x-4 items-center">
                  <div class="flex items-baseline gap-x-2">
                    <span class="text-sm">Sélectionner:</span>
                    <span
                      class="text-sm text-luxcaddy border-b border-dotted border-luxcaddy hover:text-luxcaddy-600 hover:underline cursor-pointer select-none"
                      @click="selectAll"
                    >
                                        Tous
                                    </span>
                    <span>|</span>
                    <span
                      class="text-sm text-luxcaddy border-b border-dotted border-luxcaddy hover:text-luxcaddy-600 hover:underline cursor-pointer select-none"
                      @click="unselectAll"
                    >
                                        Aucun
                                    </span>
                  </div>

                  <button-with-menu-element
                    :actions="getActionLinks"
                    :disabled="!selectedBlogPosts.length"
                    button-text="Actions"
                    close-after-action-executed
                    disabled-tooltip="Veullez sélectionner au moins un article"
                    size="small"
                    small-menu
                    stick-direction="right"
                    type="light"
                  />
                </div>

                <div class="flex gap-x-2 items-center">
                  <button-element
                    :disabled="!hasUnsavedChanges"
                    size="small"
                    type="light"
                    @click="fetchBlogPostsInCategory"
                  >
                    Réinitialisation
                  </button-element>

                  <button-element
                    :disabled="!hasUnsavedChanges"
                    size="small"
                    type="primary"
                    @click="setItems"
                  >
                    <fa-icon class="mr-2" icon="check"></fa-icon>
                    Sauvegarder
                  </button-element>
                </div>
              </div>


              <div
                v-if="!blogPosts.length"
                class="bg-gray-200 p-4 text-center mt-4"
              >
                Il n'y a aucun produit dans cette catégorie.
              </div>

              <draggable
                v-model="blogPosts"
                :empty-insert-threshold="200"
                class="py-4"
                group="products"
                handle=".handle"
              >
                <transition-group class="space-y-4" name="flip-list" type="transition">

                  <blog-post-display-card
                    v-for="blogPost in blogPosts"
                    :key="'post-' + blogPost.id"
                    :blog-post="blogPost"
                    :selected="isBlogPostSelected(blogPost.id)"
                    :show-handle="true"
                  >
                    <template v-if="!$isReadOnly()">
                      <div class="flex items-center text-3xl cursor-pointer">
                        <fa-icon v-if="isBlogPostSelected(blogPost.id)"
                                 class="text-luxcaddy-500 hover:text-luxcaddy-600" fixed-width
                                 icon="check-circle"
                                 @click="toggleBlogPostSelection(blogPost)"></fa-icon>
                        <fa-icon v-else
                                 :icon="['far', 'circle']"
                                 class="text-gray-200 hover:text-gray-400" fixed-width
                                 @click="toggleBlogPostSelection(blogPost)"></fa-icon>
                      </div>
                    </template>
                  </blog-post-display-card>
                </transition-group>
              </draggable>
            </div>

          </card>
        </div>

        <!-- Right side -->
        <div>
          <h2 class="font-bold text-lg mb-4">Ajouter articles</h2>

          <card type="light">
            <text-input-element
              v-model="searchTerm"
              label="Recherche article par titre"
            />

            <div v-if="searchedBlogPosts.length" class="gap-y-4">
              <draggable
                v-model="searchedBlogPosts"
                :empty-insert-threshold="200"
                group="products"
                handle=".handle"
              >
                <transition-group class="space-y-4" name="flip-list" type="transition">
                  <blog-post-display-card
                    v-for="blogPost in searchedBlogPosts"
                    :key="'post-' + blogPost.id"
                    :blog-post="blogPost"
                    :show-handle="true"
                    class="bg-yellow-100 hover:bg-yellow-200 opacity-50 border-2 border-dashed"
                  />
                </transition-group>
              </draggable>
            </div>

            <div v-else-if="searchTerm && searchedAtLeastOnce">
              Aucun produit ne correspond aux paramètres de votre requête.
            </div>
          </card>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import draggable from "vuedraggable";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import SelectedBlogPostsInCategoryActions
  from "@/components/pages/Categories/Blog/Sub/SelectedBlogPostsInCategoryActions";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import {debounce} from "@/helpers/debounceHelper";
import CategoryRepository from "@/repositories/CategoryRepository";
import ButtonWithMenuElement from "@/components/elements/buttons/ButtonWithMenuElement";
import BlogRepository from "@/repositories/BlogRepository";
import BlogPostDisplayCard from "@/components/pages/Categories/Blog/Sub/BlogPostDisplayCard";

export default {
  name: "EditBlogPostsInCategory",
  components: {
    BlogPostDisplayCard,
    ButtonWithMenuElement,
    TextInputElement,
    ButtonElement, SelectedBlogPostsInCategoryActions, Card, draggable
  },
  props: {
    categoryId: {
      type: Number,
      required: true
    }
  },
  created() {
    this.unselectAll();
    this.fetchCategory();
    this.fetchBlogPostsInCategory();
  },
  data: () => ({
    blogPosts: [],
    // Contains the list of products how they have been returned from the backend.
    // if changed, we inform the user he should remember to save.
    blogPostsSyncedWithBackend: [],

    searchedBlogPosts: [],
    searchTerm: null,
    searchedAtLeastOnce: false
  }),
  watch: {
    searchTerm: debounce(function () {
      this.searchedAtLeastOnce = false;
      this.searchPosts();
    }),
  },
  computed: {
    getAllPostIds() {
      return this.blogPosts.flatMap(p => parseInt(p.id));
    },
    getCategoryId() {
      return this.categoryId;
    },
    selectedBlogPosts() {
      return this.$store.getters['categories/getSelectedBlogPosts'];
    },
    /**
     * Checks if the values from both array differ. If so, the user modified without changing.
     * @returns {boolean}
     */
    hasUnsavedChanges() {
      let ids = this.blogPosts.flatMap(p => p.id);
      let syncedIds = this.blogPostsSyncedWithBackend.flatMap(p => p.id);

      return !(ids.length === syncedIds.length && ids.every((value, index) => value === syncedIds[index]));
    },
    getActionLinks() {
      return [
        {
          label: 'Copier sél.', icon: 'clone', action: () => {
            this.$refs.actions.$refs.copyBlogPostsModal.openModal();
          }
        },
        {
          label: 'Déplacer sél.', icon: 'exchange-alt', action: () => {
            this.$refs.actions.$refs.moveBlogPostsModal.openModal();
          }
        },
        {
          label: 'Retirer sél.', icon: 'trash-alt', action: () => {
            this.$refs.actions.$refs.removeBlogPostsModal.openModal();
          }
        },
      ];
    },
  },
  methods: {
    fetchBlogPostsInCategory() {
      return BlogRepository.posts.getAllBlogPostsByCategoryId(this.getCategoryId)
        .then(res => {
          this.blogPosts = res.data.data;
          this.blogPostsSyncedWithBackend = res.data.data;
        });
    },
    fetchCategory() {
      CategoryRepository.getSingle(this.getCategoryId).then(res => {
        this.$store.commit('categories/setActiveCategory', res.data.data);
      })
    },
    setItems() {
      CategoryRepository
        .setBlogPosts(this.getCategoryId, this.getAllPostIds)
        .then(() => {
          this.$sendSuccessToast("Modifications enregistrées");
        })
        .finally((() => {
          this.searchTerm = null;
          this.searchedBlogPosts = [];
          this.fetchProductsInCategory();
        }))
    },
    toggleBlogPostSelection(blogPost) {
      this.$store.commit('categories/toggleSelectedBlogPost', blogPost);
    },
    selectAll() {
      this.$store.commit('categories/setSelectedBlogPosts', this.blogPosts);
    },
    unselectAll() {
      this.$store.commit('categories/setSelectedBlogPosts', []);
    },
    removeBlogPostIds(blogPostIds) {
      this.blogPosts = this.blogPosts.filter(p => !blogPostIds.includes(p.id));
      this.unselectAll();
    },
    isBlogPostSelected(blogPostId) {
      return this.selectedBlogPosts.flatMap(p => p.id).includes(blogPostId);
    },
    searchPosts() {
      if (!this.searchTerm)
        return this.searchedBlogPosts = [];

      BlogRepository
        .posts
        .search(this.searchTerm)
        .then((res) => {
          this.searchedBlogPosts = res.data.data.filter(p => !this.getAllPostIds.includes(p.id));
          this.searchedAtLeastOnce = true;
        })
    },
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>