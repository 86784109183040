<template>
  <div>
    <fa-icon
      v-if="value"
      class="text-luxcaddy"
      icon="check"
    />
    <fa-icon
      v-else
      class="text-red-500"
      icon="times"
    />
  </div>
</template>

<script>
export default {
  name: "ListTableTypeBoolean",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>

