<template>
  <form-wrapper
    ref="form"
    :show-changed="true"
    :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer compte'"
    @submit="createOrUpdateMemberHoliday"
  >
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <div>
        <transition name="fade">
          <member-select-input
            v-model="supplierMemberId"
            :disabled='isUpdateMode || employeeMemberId !== null'
            :only-member-types="['supplier']"
            :validation-rules="getSupplierRule"
            label="Fournisseur"
          />
        </transition>
      </div>
      <div>
        <transition name="fade">
          <member-select-input
            v-model="employeeMemberId"
            :disabled='isUpdateMode || supplierMemberId !== null'
            :only-member-types="['employee']"
            :validation-rules="getEmployeeRule"
            label="Employée"
          />
        </transition>
      </div>
      <date-range-picker-element
        v-model="dateRange"
        label="Période de congé"
        validation-rules="required"
      />
      <text-input-element
        v-model="daysCount"
        label="Jours total"
        validation-rules="decimal:1,.|0_5_incremental|required"
      />
    </div>
  </form-wrapper>
</template>

<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import MemberHolidayRepository from "../../../../repositories/MemberHolidayRepository";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";
import dayjs from "dayjs";

export default {
  name: "AddOrUpdateMemberHolidayForm",
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    }
  },
  components: {TextInputElement, DateRangePickerElement, MemberSelectInput, FormWrapper},
  data: () => ({
    supplierMemberId: null,
    employeeMemberId: null,
    daysCount: null,
    dateRange: {
      startDate: null,
      endDate: null
    },
  }),
  mounted() {
    this.getMemberHoliday();
  },
  methods: {
    getMemberHoliday() {
      if (this.isUpdateMode) {
        MemberHolidayRepository.getSingle(this.$route.params.memberId, this.$route.params.holidayId).then((res) => {
          if (res.data.data.isEmployee) {
            this.employeeMemberId = res.data.data.memberId;
          } else {
            this.supplierMemberId = res.data.data.memberId;
          }
          this.daysCount = res.data.data.daysCount;
          this.dateRange.startDate = dayjs(res.data.data.startDate, ['YYYY-MM-DD', 'DD.MM.YYYY']);
          this.dateRange.endDate = dayjs(res.data.data.endDate, ['YYYY-MM-DD', 'DD.MM.YYYY']);
        }).then(() => this.$refs.form.resetForm());
      }
    },
    createOrUpdateMemberHoliday(callback) {
      const memberId = this.supplierMemberId ?? this.employeeMemberId;

      if (this.isUpdateMode) {
        MemberHolidayRepository.update(memberId, this.$route.params.holidayId,
          {
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
            daysCount: this.daysCount

          }).then(() => {
          this.$sendSuccessToast("Congé a été modifié!");
        }).finally(() => callback());
      } else {
        MemberHolidayRepository.add(memberId,
          {
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
            daysCount: this.daysCount

          }).then(() => {
          this.$sendSuccessToast("Congé ajouté!");
          this.$router.push({
            name: 'member-holidays.list',
          })
        }).finally(() => callback());
      }

    },
  },
  computed: {
    getEmployeeRule() {
      return this.supplierMemberId === null ? 'required' : '';
    },
    getSupplierRule() {
      return this.employeeMemberId === null ? 'required' : '';
    },
    isUpdateMode() {
      return this.mode === 'update';
    },
    getTitle() {
      if (this.isUpdateMode) {
        return 'Modifier compte';
      }
      return 'Créer nouveaux compte';
    }
  }
}
</script>