<template>
  <div v-if="loaded">
    <div
      v-if="signatureUrl"
    >
      <img
        :src="signatureUrl"
        alt="Signature"
        class="-rotate-90 ml-32 transform"
      >
    </div>

    <div v-else>
      Pas de signature pour cette livraison.
    </div>
  </div>
</template>

<script>
import DeliveryRepository from "@/repositories/DeliveryRepository";

export default {
  name: "ShowDeliverySignature",
  props: {
    deliveryId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      signatureUrl: null
    }
  },
  created() {
    DeliveryRepository.getSignatureImage(this.deliveryId).then((res) => {
      if (res.data.data && res.data.data.image) {
        this.signatureUrl = res.data.data.image.primary[0].url;
      }
    }).finally(() => {
      this.loaded = true;
    });
  },
}
</script>
