<template>
  <select-element
    ref="tagSelect"
    v-model="model"
    :enable-search="false"
    :label="label"
    :multi="multi"
    :occupy-validation-space="occupyValidationSpace"
    :options="getFormattedTags"
  >
    <template v-slot:items="slotProps">
      <div class="flex items-center gap-x-6 ml-2">
        <!--                <div class="block h-10 w-10">-->
        <!--                    <img :src="slotProps.option.image" :alt="slotProps.option.name">-->
        <!--                </div>-->

        <div :class="[slotProps.selected ? 'font-semibold' : 'font-normal']"
             class="block truncate flex flex-col gap-y-2 mr-2">
          {{ slotProps.option.label }}
        </div>
      </div>
    </template>
  </select-element>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "SelectTagElement",
  components: {SelectElement},
  props: {
    label: {
      type: String,
      required: false
    },
    multi: {
      type: Boolean,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: false
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    model: {
      set(tags) {
        this.$emit('input', tags);
      },
      get() {
        return this.value;
      }
    },
    getFormattedTags() {
      return this.tags.map(t => {
        let object = {
          label: t.name,
          value: t.id
        };

        if (t.image !== null) {
          object.image = t.image?.url || null;
        }

        return object;
      })
    }
  },
}
</script>

