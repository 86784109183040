<template>
  <div>
    <div class="flex justify-between gap-x-2 mb-2">
      <page-title>
        {{ getCampaignName }} #{{ getCampaignId }}
      </page-title>

      <batch-actions-single-model
        :batch-actions="CampaignBatchActions.batchActions"
        :selected-value="CampaignBatchActions.selectValuesFromId(getCampaignId)"
        @executed="reRenderCard"
      />
    </div>
    <card v-if="renderCard">
      <card-tab id="campaignTab" icon="bullhorn" title="Campagne">
        <create-or-update-campaign-form
          :campaign-id="getCampaignId"
          mode="update"
        />
      </card-tab>

      <card-tab id="localisationTab" icon="globe" title="Traduction">
        <update-campaign-localization-form
          :campaign-id="getCampaignId"
        />
      </card-tab>

      <card-tab id="rulesTab" icon="sliders-h" title="Régles">
        <rule-management
          :model-id="getCampaignId"
          rule-able-model="Campaign"
        />
      </card-tab>
    </card>
  </div>
</template>

<script>
import PageTitle from "../../elements/pages/PageTitle";
import CreateOrUpdateCampaignForm from "./Forms/CreateOrUpdateCampaignForm";
import Card from "../../elements/cards/Card";
import CardTab from "../../elements/cards/tabs/CardTab";
import UpdateCampaignLocalizationForm from "./Forms/UpdateCampaignLocalizationForm";
import RuleManagement from "../../forms/rules/sub/RuleManagement";
import BatchActionsSingleModel from "@/batchAction/components/BatchActionsSingleModel.vue";
import CampaignBatchActions from "@/batchAction/campaignBatchActions";

export default {
  name: "EditCampaign",
  components: {
    BatchActionsSingleModel,
    RuleManagement,
    UpdateCampaignLocalizationForm,
    CardTab,
    Card,
    CreateOrUpdateCampaignForm,
    PageTitle
  },
  data: () => ({
    renderCard: true
  }),
  methods: {
    reRenderCard() {
      this.renderCard = false;
      this.$nextTick(() => this.renderCard = true);
    }
  },
  computed: {
    CampaignBatchActions() {
      return CampaignBatchActions
    },
    getCampaignId() {
      return parseInt(this.$route.params.campaign);
    },
    getCampaignName() {
      return this.$store.getters['campaigns/getCampaignName'];
    },
  },
}
</script>

