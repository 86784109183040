<template>
  <div>
    <page-title>Créer livraison</page-title>
    <card>
      <form-wrapper
        ref="form"
        :show-changed="true"
        submit-button-label="Créer"
        @submit="createDelivery"
      >


        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <member-select-input
            v-model="memberId"
            label="Client"
          />

          <delivery-contact-select-input
            v-if="memberId"
            v-model="deliveryContact"
            :member-id="memberId"
            :show-selection-info="true"
          />

          <div
            v-if="getZoneId"
            class="lg:col-span-2"
          >
            <form-label label="Créneau de livraison"/>
            <time-slot-selection
              v-model="deliveryTimeslotId"
              :zone-id="getZoneId"
            />
          </div>
        </div>

        <div class="grid-cols-1 mt-4">
          <text-input-element
            v-model="description"
            label="Description"
            validation-rules="required"
          />
        </div>


        <!--                <time-slot-selection-->
        <!--                    :zone-id="zoneId"-->
        <!--                    :selected-id="selectedNewDeliveryTimeslotId"-->
        <!--                    v-if="zoneId"-->
        <!--                    @change="onTimeslotSelect"-->
        <!--                />-->
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import PageTitle from "../../elements/pages/PageTitle";
import Card from "../../elements/cards/Card";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput";
import DeliveryContactSelectInput from "@/components/elements/forms/elements/DeliveryContactSelectInput";
import TimeSlotSelection from "@/components/global/TimeSlots/TimeSlotSelection";
import FormLabel from "@/components/elements/forms/elements/partials/FormLabel";
import DeliveryRepository from "@/repositories/DeliveryRepository";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";

export default {
  name: "CreateDelivery",
  components: {
    TextInputElement,
    FormLabel,
    TimeSlotSelection,
    DeliveryContactSelectInput,
    MemberSelectInput,
    FormWrapper,
    Card,
    PageTitle
  },
  data: () => ({
    memberId: null,
    deliveryContact: null,
    deliveryTimeslotId: null,
    description: "",
  }),
  watch: {
    memberId: function () {
      if (this.deliveryContact !== null) {
        this.deliveryContact = null;
      }
      if (this.deliveryTimeslotId !== null) {
        this.deliveryTimeslotId = null;
      }
    }
  },
  methods: {
    createDelivery(callback) {
      DeliveryRepository.create({
        memberId: this.memberId,
        deliveryContactId: this.getDeliveryContactId,
        deliveryTimeslotId: this.deliveryTimeslotId,
        description: this.description
      }).then((res) => {
        this.$sendSuccessToast("La livraison a été crée.");
        this.$router.push({
          name: 'deliveries.edit',
          params: {
            deliveryId: res.data.data.id
          }
        })
      }).finally(() => callback());
    }
  },
  computed: {
    getDeliveryContactId() {
      return this.deliveryContact?.id || null;
    },
    getZoneId() {
      return this.deliveryContact?.zoneId || null;
    }
  },
}
</script>

