import NotificationToast from "@/plugins/toast/NotificationToast";
import {vue} from "@/main";

export default {
  install(Vue) {
    const TYPE_WARNING = 'warning';
    const TYPE_ERROR = 'error';
    const TYPE_INFO = 'info';
    const TYPE_SUCCESS = 'success';

    let toastOptions = {
      position: "top-center",
      icon: true,
    }

    /**
     * Returns the time in MS that the notification should be visible for.
     *
     * @param message
     * @param max
     * @return {number}
     */
    let getDurationByMessage = function (message, max = 4000) {
      // Reaction time before the user sees the notification
      let delay = 1500;

      return Number(
        Math.max(
          max,
          (delay) + (message.length * 30)
        )
      );
    };

    let sendToast = function (message, type) {
      const options = {...toastOptions, type, timeout: getDurationByMessage(message)};
      Vue.$toast(message, options);
    };

    Vue.prototype.$sendNotificationToast = function (notification) {
      if (vue.$store.getters['userInterface/isOnTouchInterface'] === true) {
        return false;
      }

      const content = {
        component: NotificationToast,
        props: {
          notification
        },
        listeners: {
          toggleReadClick: (id) => {
            vue.$store.dispatch('notifications/toggleNotificationRead', id);
          }
        },
        timeout: getDurationByMessage(notification.text, 10000)
      };


      this.$toast(content, {
        toastClassName: "notificationtoast",
        icon: false,
      });
    };

    /**
     * Sends a toast.
     * @type {sendToast}
     */
    Vue.prototype.$sendToast = sendToast;

    /**
     * Sends a successful toast.
     * @param message
     */
    Vue.prototype.$sendSuccessToast = function (message) {
      sendToast(message, TYPE_SUCCESS);
    };

    /**
     * Sends a warning toast.
     * @param message
     */
    Vue.prototype.$sendWarningToast = function (message) {
      sendToast(message, TYPE_WARNING);
    };

    /**
     * Sends an erroneous toast.
     * @param message
     */
    Vue.prototype.$sendErrorToast = function (message) {
      sendToast(message, TYPE_ERROR);
    };

    /**
     * Sends an erroneous toast.
     * @param message
     */
    Vue.prototype.$sendInfoToast = function (message) {
      sendToast(message, TYPE_INFO);
    };
  }
}