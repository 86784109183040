import apiService from "./Base/BaseRepository";

const url = '/accounting';

export default {

  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/bank-movements`);
  },

  async getPaginatedForBankAccount(bankAccountNumber, perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/bank-movements/byBankAccount/${bankAccountNumber}`);
  },

  async getSingleBankMovement(bankMovementId) {
    return await apiService
      .get(`${url}/bank-movements/${bankMovementId}`);
  },

  async setDirectBooking(bankMovementId, bookingAccountNumber, vatCode, vatRate, accountingDate) {
    return await apiService
      .patch(`${url}/bank-movements/${bankMovementId}`, {
        bookingAccountNumber,
        vatCode,
        vatRate,
        accountingDate
      })
  },

  async linkPaymentTransaction(bankMovementId, paymentTransactionId) {
    return await apiService
      .post(`${url}/bank-movements/${bankMovementId}/link`, {
        paymentTransactionId
      })
  },

  async unlinkPaymentTransaction(bankMovementId, paymentTransactionId) {
    return await apiService
      .post(`${url}/bank-movements/${bankMovementId}/unlink`, {
        paymentTransactionId
      })
  },

  async createAndLinkTransactions(bankMovementId, transactions, invoiceStrictCheck = false, showLoadingIndicator = true) {
    return await apiService
      .post(`${url}/bank-movements/${bankMovementId}/createAndLink`, {
        createTransactions: transactions,
        invoiceStrictCheck: invoiceStrictCheck
      }, {
        showLoadingIndicator
      });
  },

  async getSuggestions(bankMovementId, withFilters = false, showLoadingIndicator = true) {
    let applyFilters = withFilters ? 1 : 0;
    return await apiService
      .get(`${url}/bank-movements/${bankMovementId}/invoiceSuggestions?withFilters=${applyFilters}`, {
        showLoadingIndicator
      })
  },

  async getVatCodes(memberId = null) {
    let filters = [];

    if (memberId) {
      filters.push({
        filterKey: 'topUsedForMember',
        filterValue: memberId
      });
    }
    return await apiService
      .setFilters(filters)
      .get(`${url}/vatCodes`);
  },

  async getMostUsedVatRate(memberId) {
    return await apiService
      .get(`${url}/${memberId}/mostUsedVatRate`);
  },

  async import(fileList) {
    let formData = new FormData();

    for (const [index, file] of fileList.entries()) {
      formData.append(`file[${index}]`, file.file);
    }
    return await apiService
      .post(`${url}/bank-movements/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
  },

  async getBankAccounts() {
    return await apiService
      .get(`${url}/bank-movements/accounts`);
  },

  batchActions: {
    async executeBatchAction(action, items, params) {
      return await apiService.patch(`${url}/bank-movements/batchAction`, {
        jobAction: action,
        items: items,
        params: params
      });
    }
  }
}