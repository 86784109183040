import SupplierPreviewRepository from "@/repositories/SupplierPreviewRepository";
import dayjs from "dayjs";

const getDefaultState = () => {
  return {
    snapshots: [],
    items: [],
    expectedDeliveryDate: null
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getSnapshots: state => state.snapshots,
    getItems: state => state.items,
    getExpectedDeliveryDate: state => state.expectedDeliveryDate,
  },
  mutations: {
    setSnapshots(state, snapshots) {
      state.snapshots = snapshots;
    },
    setItems(state, items) {
      state.items = items;
    },
    setExpectedDeliveryDate(state, deliveryDate) {
      let date = null;

      let dayjsdate = dayjs(deliveryDate, ['YYYY-MM-DD', 'DD.MM.YYYY']);
      if (dayjsdate.isValid()) {
        date = dayjsdate.format('DD.MM.YYYY');
      }

      state.expectedDeliveryDate = date;
    },
  },
  actions: {
    fetchSnapshots({commit}) {
      return SupplierPreviewRepository.getSnapshots()
        .then((res) => {
          commit('setSnapshots', res.data.data);
        });
    },
    fetchItems({commit}, {sinceSnapshotId = null, deliveryDate = null}) {
      if (deliveryDate) {
        commit('setExpectedDeliveryDate', deliveryDate);
      }

      return SupplierPreviewRepository.get(sinceSnapshotId, deliveryDate)
        .then((res) => {
          commit('setItems', res.data.data.items);
          commit('setExpectedDeliveryDate', res.data.data.expectedDeliveryDate);
        });
    },
  },
}