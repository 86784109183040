<template>
  <div>
    <page-title>Nouveaux clients</page-title>
    <card class="mb-4" type="light">
      <div class="grid items-center grid-cols-1 lg:grid-cols-3 2xl:grid-cols-6 mb-4 gap-x-6 pl-6">
        <select-element
          v-model="year"
          :enableSearch="false"
          :options="getYears"
          active-in-read-only
          label="Année"
          @input="fetchNewCustomerStats"
        />
      </div>
    </card>
    <vue-apex-charts
      v-if="loaded"
      :options="getChartOptions"
      :series="getSeries"
      height="700px"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import MemberRepository from "../../../repositories/MemberRepository";
import Card from "../../elements/cards/Card";
import PageTitle from "../../elements/pages/PageTitle";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  components: {SelectElement, PageTitle, Card, VueApexCharts},

  data: () => ({
    customerStats: [],
    loaded: false,
    year: (new Date()).getFullYear(),
  }),
  created() {
    this.fetchNewCustomerStats();
  },

  computed: {

    getChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          }
        },
        xaxis: {
          title: {
            text: 'Date'
          },
          type: 'datetime',
          min: (new Date(this.year, 1, 1)).getTime(),
          max: (new Date(this.year + 1, 1, 1)).getTime(),
        },
        yaxis: {
          title: {
            text: 'Nouveaux clients'
          },
          labels: {
            formatter: function (val) {
              return Math.floor(val)
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#7E9036'],
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: true,
          },
          x: {
            format: 'MMMM yyyy',
          },
        }
      }
    },
    getSeries() {
      return [
        {
          name: 'Nouveaux clients',
          data: this.customerStats.map(l => {
            return {
              x: l.date,
              y: l.newCustomerCount,
            }
          })
        },
      ]
    },
    getYears() {
      let years = [];
      let date = this.$date();

      while (date.year() >= 2007) {
        years.push(date.year());
        date = date.subtract(1, 'year');
      }

      return years;
    },
  },

  methods: {
    fetchNewCustomerStats() {
      this.loaded = false;
      return MemberRepository.getNewCustomerStats(this.year).then((res) => {
        this.customerStats = res.data.data;
        this.loaded = true;
      })
    },
  },


}
</script>