import Vue from "vue";
import AccountingBankMovementRepository from "@/repositories/AccountingBankMovementRepository";
import InvoiceRepository from "@/repositories/InvoiceRepository";

const getDefaultState = () => {
  return {
    bankMovement: null,
    vatCodes: [],

    // Used on the "Link existing transaction" tab on edit bank movement
    searchTransactions: {
      invoiceId: null,
      returnedTransactions: []
    }
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getBankMovement: state => state.bankMovement,
    getBankMovementId: state => state.bankMovement?.id || null,
    isBankMovementTreated: state => state.bankMovement?.isTreated || false,
    getBankMovementTreatedStatus: state => state.bankMovement?.treatedStatus || null,
    getBankMovementUnattributedAmount: state => state.bankMovement?.unattributedAmount || null,
    getLinkedPaymentTransactions: state => state.bankMovement?.linkedPaymentTransactions || [],
    getLinkedPaymentTransactionCount: state => (state.bankMovement?.linkedPaymentTransactions || []).length,
    hasLinkedPaymentTransactions: state => (state.bankMovement?.linkedPaymentTransactions || []).length > 0,
    getCurrency: state => state.bankMovement?.currency || null,

    isDirectBookingSet: state => {
      if (!state.bankMovement) {
        return false;
      }

      const bm = state.bankMovement;
      return bm.isTreated
        && bm.bookingAccount !== null
        && bm.vatCode !== null
        && bm.vatRate !== null;
    },

    // Search
    getSearchedInvoiceId: state => state.searchTransactions.invoiceId,
    getSearchReturnedTransactions: state => state.searchTransactions.returnedTransactions,
  },
  mutations: {
    setBankMovement(state, bankMovement) {
      if (bankMovement.vatRate == null) {
        bankMovement.vatRate = 0
      }
      Vue.set(state, 'bankMovement', bankMovement);
    },
    setVatCodes(state, vatCodes) {
      state.vatCodes = vatCodes;
    },
    removeDirectBooking(state) {
      state.bankMovement.bookingAccount = null;
      state.bankMovement.vatCode = null;
      state.bankMovement.vatRate = null;
    },
    clearData(state) {
      state.bankMovement = getDefaultState().bankMovement;
      state.vatCodes = getDefaultState().vatCodes;
      state.searchTransactions.invoiceId = getDefaultState().searchTransactions.invoiceId;
      state.searchTransactions.returnedTransactions = getDefaultState().searchTransactions.returnedTransactions;
    },

    setSuggestions(state, suggestions) {
      state.suggestions = suggestions;
    },

    // Search
    setSearchedInvoiceId(state, invoiceId) {
      state.searchTransactions.invoiceId = invoiceId;
    },
    setSearchReturnedTransactions(state, transactions) {
      state.searchTransactions.returnedTransactions = transactions;
    }
  },
  actions: {
    fetchBankMovement({commit}, bankMovementId) {
      return AccountingBankMovementRepository.getSingleBankMovement(bankMovementId).then((res) => {
        commit('setBankMovement', res.data.data);
      });
    },
    setDirectBooking({getters, dispatch}) {
      return AccountingBankMovementRepository
        .setDirectBooking(
          getters.getBankMovementId,
          getters.getBankMovement.bookingAccount,
          getters.getBankMovement.vatCode,
          getters.getBankMovement.vatRate,
          getters.getBankMovement.accountingDate,
        )
        .then(() => dispatch('fetchBankMovement', getters.getBankMovementId));
    },

    // Transactions
    linkPaymentTransaction({getters, dispatch}, paymentTransactionId) {
      return AccountingBankMovementRepository
        .linkPaymentTransaction(getters.getBankMovementId, paymentTransactionId)
        .then(() => dispatch('fetchBankMovement', getters.getBankMovementId));
    },
    unlinkPaymentTransaction({getters, dispatch}, paymentTransactionId) {
      return AccountingBankMovementRepository
        .unlinkPaymentTransaction(getters.getBankMovementId, paymentTransactionId)
        .then(() => dispatch('fetchBankMovement', getters.getBankMovementId));
    },
    createAndLinkTransactions({getters, dispatch}, transactions) {
      return AccountingBankMovementRepository
        .createAndLinkTransactions(getters.getBankMovementId, transactions)
        .then(() => dispatch('fetchBankMovement', getters.getBankMovementId));
    },

    // Search
    fetchTransactionsByInvoiceId({getters, commit}) {
      commit('setSearchReturnedTransactions', []);

      return InvoiceRepository.payments.getPayments(
        "invoiceId",
        getters.getSearchedInvoiceId
      ).then((res) => {
        commit('setSearchReturnedTransactions', res.data.data);
      })
    }
  },
}