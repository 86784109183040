<template>
  <div>
    <button-element
      class="text-sm"
      size="small"
      type="primary"
      @click="openModal"
    >

      <fa-icon
        fixed-width
        icon="plus"
      />
    </button-element>

    <modal
      ref="modal"
      :position="getModalPosition"
      :show-close-button="true"
      title="Ajouter ligne"
      @close="$store.commit('invoices/setInvoiceItemModalOpen', false)"
      @open="$store.commit('invoices/setInvoiceItemModalOpen', true)"
    >
      <div style="width: 800px;">
        <form-wrapper
          ref="form"
          :show-changed="true"
          submit-button-label="Ajouter"
          @submit="addItem"
        >
          <div class=" grid grid-cols-1 lg:grid-cols-4 gap-y-1 gap-x-4">

            <template
              v-if="isInvoiceInternal"
            >
              <vat-input-element
                v-model="vat"
                :disabled="isFinalized"
                class="lg:col-span-4"
                validation-rules="required"
              />

              <booking-account-select-input
                v-model="bookingAccountNumber"
                :allow-unselecting="false"
                :member-id="memberId"
                class="lg:col-span-4"
                internal-invoice
                validation-rules="required"
                @loaded="onBookingAccountsLoaded"
                :multi="false"

              />

              <vat-codes-select-input
                v-model="vatCode"
                :allow-unselecting="false"
                class="lg:col-span-4"
                internal-invoice
                validation-rules="required"

              />


              <text-input-element
                v-model="reference"
                :disabled="isFinalized"
                class="lg:col-span-2"
                label="Réf."
                validation-rules="required"
              />

              <text-input-element
                v-model="name"
                :disabled="isFinalized"
                class="lg:col-span-2"
                label="Labellé"
                validation-rules="required"
              />

              <text-area-element
                v-model="description"
                :disabled="isFinalized"
                class="lg:col-span-4"
                label="Description"
              />

              <number-input-element
                v-model="quantity"
                :disabled="isFinalized"
                class="lg:col-span-2"
                label="Qté."
                validation-rules="required"
              />

              <currency-input-element
                v-model.number="price"
                :disabled="isFinalized"
                :inset-text="getInvoiceCurrencySymbol"
                allow-negative
                class="lg:col-span-2"
                label="Montant"
                validation-rules="required"
              />
            </template>

            <!-- External invoice -->
            <template v-else>
              <currency-input-element
                v-model.number="price"
                :inset-text="getInvoiceCurrencySymbol"
                allow-negative
                class="lg:col-span-2"
                label="Montant"
                validation-rules="required"
              />


              <vat-input-element
                v-model="vat"
                class="lg:col-span-2"
                label="Taux de TVA"
                validation-rules="required"
              />

              <booking-account-select-input
                v-model="bookingAccountNumber"
                :allow-unselecting="false"
                :member-id="memberId"
                class="lg:col-span-4"
                external-invoice
                validation-rules="required"
                @loaded="onBookingAccountsLoaded"
                :multi="false"
              />

              <vat-codes-select-input
                v-model="vatCode"
                :allow-unselecting="false"
                :current-booking-account-number="bookingAccountNumber"
                class="lg:col-span-4"
                external-invoice
                validation-rules="required"
                :member-id="memberId"
                :multi="false"
                @loaded="onVatCodesLoaded"
              />

            </template>

          </div>

          <template v-slot:additionalButtons>
            <button-element class="float-right" type="light" @click="closeModal">Annuler</button-element>
          </template>
        </form-wrapper>
      </div>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import Modal from "@/components/elements/modals/Modal";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import CurrencyInputElement from "@/components/elements/forms/elements/CurrencyInputElement";
import VatInputElement from "@/components/elements/forms/elements/VatInputElement.vue";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import {mapActions, mapGetters} from "vuex";
import VatCodesSelectInput from "@/components/elements/forms/elements/VatCodesSelectInput";
import BookingAccountSelectInput from "@/components/elements/forms/elements/BookingAccountSelectInput";
import {eventBus} from "@/eventBus";
import AccountingBankMovementRepository from "@/repositories/AccountingBankMovementRepository";

export default {
  name: "InvoiceItemAddButton",
  components: {
    BookingAccountSelectInput,
    VatCodesSelectInput,
    VatInputElement,
    CurrencyInputElement,
    NumberInputElement,
    TextAreaElement,
    TextInputElement,
    FormWrapper,
    Modal,
    ButtonElement
  },
  data: () => ({
    reference: null,
    name: '',
    description: null,
    quantity: 1,
    price: 0,
    vat: 3,
    defaultVat: null,
    vatCode: null,
    mostUsedVatCode: [],
    bookingAccountNumber: null,
    bookingAccounts: null,
    mostUsedBookingAccount: []
  }),
  created() {
    this.setItemData();

    if (!this.isInvoiceInternal) {
        AccountingBankMovementRepository.getMostUsedVatRate(this.memberId).then((res) => {
            if (res.data.data.vat !== null) {
                this.defaultVat = res.data.data.vat;
            }
        });

    }

    // Shortcut / Shortkey pressed
    if (this.referencedInvoiceItem === null) {
      eventBus.$on('invoice-edit-open-add-item', () => {
        this.openModal();
      });
    }
  },
  beforeDestroy() {
    if (this.referencedInvoiceItem === null) {
      eventBus.$off('invoice-edit-open-add-item');
    }
  },
  props: {
    referencedInvoiceItem: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      required: false
    },
    memberId: Number,
    default: null
  },
  computed: {
    ...mapGetters('invoices', ['getInvoiceId', 'getInvoice', 'isInvoiceInternal', 'getInvoiceCurrencySymbol']),

    isFinalized() {
      return !this.getInvoice.isDraft;
    },

    getModalPosition() {
      if (this.$store.getters["invoices/isTreatingInvoicePDF"]) {
        return "left";
      }

      return "center";
    }
  },
  methods: {
    ...mapActions('invoices', ['refetchCurrentInvoice']),
    openModal() {
      if (this.item === undefined) {
        this.reference = null;
        this.name = '';
        this.description = null;
        this.quantity = 1;
        this.price = null;
        this.vat = this.defaultVat !== null ? this.defaultVat : 3;
        this.bookingAccountNumber = null;

        if (this.bookingAccounts !== null) {
          this.onBookingAccountsLoaded(this.bookingAccounts, this.mostUsedBookingAccount);
        }
      }

      this.$nextTick(() => {
        this.$refs.modal.openModal();
        this.$nextTick(() => {
          this.$refs.form.focusFirstElement();
        })
        // this.resetForm();
      });
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },

    setItemData() {
      if (!this.isFinalized) {
        return false;
      }

      if (this.item !== undefined) {
        this.reference = this.item.reference;
        this.name = this.item.name;
        this.description = this.item.description;
        this.quantity = this.item.quantity;
        this.price = this.item.price;
        this.vat = this.item.vat;
        this.vatCode = this.item.vatCode;
        this.bookingAccountNumber = this.item.bookingAccountNumber;
      }

      this.resetForm();
    },

    resetForm() {
      this.$nextTick(() => {
        if (this.$refs.form !== undefined) {
          this.$refs.form.resetForm();
        }
      });
    },

    addItem(callback) {
      let req = null;

      if (this.isInvoiceInternal) {
        req = InvoiceRepository.internal.items.addItem(this.getInvoiceId, {
          name: this.name,
          description: this.description,
          order_item_id: null,
          referencedInvoiceItemId: (this.referencedInvoiceItem?.id || null),
          reference: this.reference,
          quantity: this.quantity,
          price: this.price,
          vat: this.vat,
          vatCode: this.vatCode,
          bookingAccountNumber: this.bookingAccountNumber
        });
      } else {
        req = InvoiceRepository.external.items.addItem(this.getInvoiceId, {
          price: this.price,
          vat: this.vat,
          vatCode: this.vatCode,
          bookingAccountNumber: this.bookingAccountNumber
        });
      }

      req.then(() => {
        this.closeModal();
        this.$sendSuccessToast("Ligne ajoutée.");
        this.refetchCurrentInvoice().then(() => {
          this.resetForm();
        });
      }).finally(() => callback());
    },

    onBookingAccountsLoaded(bookingAccounts, mostUsedBookingAccount) {
      this.bookingAccounts = bookingAccounts;
      this.mostUsedBookingAccount = mostUsedBookingAccount;

      const hasDefaultBookingAccount = this.mostUsedBookingAccount.length > 0 && this.mostUsedBookingAccount[0] !== null && this.mostUsedBookingAccount[0].value !== 'undefined'
      const defaultBookingAccountId = hasDefaultBookingAccount ? bookingAccounts[0].value : null;

      if (this.bookingAccountNumber === null && hasDefaultBookingAccount) {
        this.bookingAccountNumber = defaultBookingAccountId;

      }

      this.resetForm();
    },
    onVatCodesLoaded(vatCodes, mostUsedVatCodes) {
      this.vatCodes = vatCodes;
      this.mostUsedVatCode = mostUsedVatCodes;

      const hasDefaultVatCode = this.mostUsedVatCode.length > 0 && this.mostUsedVatCode[0] !== null && this.mostUsedVatCode[0].value !== 'undefined'
      const defaultVatCodeId = hasDefaultVatCode ? vatCodes[0].value : null;

      if (this.vatCode === null && hasDefaultVatCode) {
        this.vatCode = defaultVatCodeId;

      }

      this.resetForm();
    }
  },
}
</script>

