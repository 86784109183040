<template>
  <div>
    <touch-interface-card-with-loading-indicator
      :loading="loading"
      color="white"
      disable-padding
    >
      <div
        class="flex justify-between px-2 pt-2 pb-4"
      >
        <button-element
          size="xsmall"
          @click="callResponsible"
        >
          <fa-icon fixed-width icon="phone"/>
          <br>
          Appeler responsable
        </button-element>
        <button-element
          size="xsmall"
          @click="navigateToHome"
        >
          <fa-icon fixed-width icon="map-marked-alt"/>
          <br>
          Retours vers Depot
        </button-element>
      </div>

      <div class="flex flex-col divide-y divide-gray-400">
        <driver-my-routes-route-item
          v-for="route in routes"
          :key="route.id"
          :route="route"
        />

        <div v-if="routes.length === 0" class="px-4 py-6 text-center bg-gray-200 text-red-500">
          Vous n'avez pas des routes planifiées ajourd'hui.
        </div>
      </div>
    </touch-interface-card-with-loading-indicator>
  </div>
</template>

<script>
import RouteRepository from "@/repositories/RouteRepository";
import DriverMyRoutesRouteItem
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/Components/DriverMyRoutesRouteItem";
import TouchInterfaceCardWithLoadingIndicator
  from "@/components/pages/TouchInterface/Shared/TouchInterfaceCardWithLoadingIndicator";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "DriverMyRoutesInterfacePage",
  components: {ButtonElement, TouchInterfaceCardWithLoadingIndicator, DriverMyRoutesRouteItem},
  data: () => ({
    loading: false,
    routes: [],
  }),
  created() {
    this.getMyRoutes();
  },
  methods: {
    getMyRoutes() {
      this.loading = true;
      RouteRepository
        .my
        .getRoutes()
        .then(res => {
          this.routes = res.data.data;
        }).finally(() => this.loading = false);
    },
    callResponsible() {
      window.location.href = 'tel:+35226459033'
    },
    navigateToHome() {
      window.location.href = `https://www.google.com/maps/dir/?api=1&destination=49.66298718,6.02101211&travelmode=driving&dir_action=navigate`;
    }
  }
}
</script>