<template>
  <div class="flex gap-2 justify-center flex-wrap">
    <touch-interface-holidays-single-year
      v-for="year in getYears"
      :key="year"
      :year="year"
    />
  </div>
</template>

<script>
import TouchInterfaceHolidaysSingleYear
  from "@/components/pages/TouchInterface/PersonalSpace/Holidays/Sub/Year/TouchInterfaceHolidaySingleYear.vue";

export default {
  name: "TouchInterfaceHolidaysYearList",
  components: {TouchInterfaceHolidaysSingleYear},
  computed: {
    getYears() {
      return [
        this.$date().subtract(1, 'year').year(),
        this.$date().year(),
      ].filter(year => year >= 2023);
    }
  },
}
</script>