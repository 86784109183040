<template>
  <button-element
    :disabled="disabled"
    :size="size"
    :type="type"
    is-submit-button
    @click="$emit('click')"
  >
    <slot></slot>
  </button-element>
</template>

<script>
import ButtonElement from "./ButtonElement";

export default {
  name: "FormSubmitButton",
  components: {ButtonElement},
  props: {
    type: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: 'large'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

