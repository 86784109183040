<template>
  <div>
    <touch-interface-card-with-loading-indicator
      :loading="loading"
      color="white"
      disable-padding
    >
      <div class="divide-y divide-gray-400">
        <div
          v-for="route in routes"
          :key="route.id"
          class="flex justify-between bg-gray-200 hover:bg-gray-300 py-3 px-3 text"
          @click="navigateToRouteDetail(route.id)"
        >
          <div class="text-sm md:text-md font-medium">
            {{ $date(route.departure).format('HH:mm') }} -
            <span v-if="route && route.driver">{{ route.driver }}</span>
            <span v-else class="text-red-500 italic">
                            Pas de livreur
                        </span>
          </div>
          <div class="rounded-full bg-gray-500 text-white px-1">
            <fa-icon fixed-width icon="chevron-right"/>
          </div>
        </div>
      </div>
    </touch-interface-card-with-loading-indicator>
  </div>
</template>

<script>
import RouteRepository from "@/repositories/RouteRepository";
import TouchInterfaceCardWithLoadingIndicator
  from "@/components/pages/TouchInterface/Shared/TouchInterfaceCardWithLoadingIndicator";

export default {
  name: "DriverRouteCheckInterfacePage",
  components: {TouchInterfaceCardWithLoadingIndicator},
  created() {
    this.fetchRoutes();
  },
  data: () => ({
    loading: false,
    routes: []
  }),
  methods: {
    fetchRoutes() {
      this.loading = true;
      RouteRepository
        .getAllByDate(this.$date().format('YYYY-MM-DD'))
        .then((res) => {
          this.routes = res.data.data.routes;
        })
        .finally(() => this.loading = false);
    },
    navigateToRouteDetail(routeId) {
      this.$router.push({
        name: 'touchInterface.delivery.fulfilment.routecheck.detail.index',
        params: {
          route: routeId
        }
      })
    }
  },
}
</script>

<style scoped>

</style>