const getDefaultState = () => {
  return {
    displayBars: true, // If disabled, top and bottom bar of Touch Interface UI wont be shown.
    // Currently used for Signature
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getDisplayBars: state => state.displayBars,
  },
  mutations: {
    setDisplayBars(state, bool) {
      state.displayBars = bool;
    },
  },
}