<template>
  <update-localization-form
    v-if="localizations"
    :localizable-id="tagId"
    :localizations="localizations"
    localizable-type="tag"
    @submit="saveLocalizations"
  />
</template>

<script>
import UpdateLocalizationForm from "../../../../forms/UpdateLocalizationForm";
import TagsRepository from "../../../../../repositories/TagsRepository";

export default {
  name: "UpdateTagLocalizationForm",
  components: {UpdateLocalizationForm},
  props: {
    tagId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    localizations: null
  }),
  mounted() {
    TagsRepository.localization.getForTag(this.tagId).then((res) => {
      this.localizations = res.data.data;
    })
  },
  methods: {
    saveLocalizations(payload) {
      TagsRepository
        .localization
        .saveForTag(this.tagId, {
          localizableData: payload
        })
        .then(() => {
          this.$sendSuccessToast('Traduction sauvegardé!')
        })
    },
  },
}
</script>

