<template>
  <div>
    <form-wrapper
      ref="form"
      :show-changed="true"
      :submit-button-label="getSubmitButtonLabel"
      @submit="saveDepartment"
    >

      <form-grid class="grid-cols-1 lg:grid-cols-3">
        <text-input-element
          v-model="name"
          label="Nom"
          validation-rules="required"
        />

        <number-input-element
          v-model="fulfilmentGroupThreshold"
          label="Seuil pour picking"
          validation-rules="required"
        />

        <checkbox-element
          v-model="isInStock"
          label="Seulement produits en stock"
        />

        <product-tag-selection-input
          v-model="tagIds"
        />

        <supplier-select-input
          v-model="supplierIds"
          only-active-suppliers
          type="multi"
        />

        <rack-select-input
          v-model="rackIds"
          id-as-value
          type="multi"
        />
      </form-grid>
    </form-wrapper>

  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import FormGrid from "@/components/elements/forms/FormGrid.vue";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement.vue";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";
import DepartmentRepository from "@/repositories/DepartmentRepository";
import ProductTagSelectionInput from "@/components/elements/forms/elements/ProductTagSelectionInput.vue";
import SupplierSelectInput from "@/components/elements/forms/elements/SupplierSelectInput.vue";
import RackSelectInput from "@/components/elements/forms/elements/RackSelectInput.vue";

export default {
  name: "CreateOrUpdateDepartmentForm",
  components: {
    RackSelectInput,
    SupplierSelectInput,
    ProductTagSelectionInput, CheckboxElement, NumberInputElement, TextInputElement, FormGrid, FormWrapper
  },
  data() {
    return {
      name: null,
      fulfilmentGroupThreshold: null,
      isInStock: false,

      tagIds: [],
      supplierIds: [],
      rackIds: [],
    }
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: value => ["create", "update"].includes(value)
    },
    departmentId: {
      type: Number
    }
  },
  created() {
    this.fetchDepartment();
  },
  methods: {
    fetchDepartment() {
      if (this.isCreateMode) {
        return false;
      }

      return DepartmentRepository
        .getSingle(this.departmentId)
        .then((res) => {
          this.name = res.data.data.name;
          this.fulfilmentGroupThreshold = res.data.data.fulfilmentGroupThreshold;
          this.isInStock = res.data.data.isInStock;

          this.tagIds = res.data.data.tags.flatMap(t => t.id);
          this.supplierIds = res.data.data.suppliersMember.flatMap(s => s.id);
          this.rackIds = res.data.data.racks.flatMap(r => r.id);

          this.$emit('updateTitle', this.name);
        })
    },


    saveDepartment(callback) {
      return this.isCreateMode
        ? this.createDepartment(callback)
        : this.updateDepartment(callback);
    },
    createDepartment(callback) {
      return DepartmentRepository.create({
        name: this.name,
        fulfilmentGroupThreshold: this.fulfilmentGroupThreshold,
        isInStock: this.isInStock,
        tagIds: this.tagIds,
        supplierIds: this.supplierIds,
        rackIds: this.rackIds,
      }).then((res) => {
        this.$sendSuccessToast("Le departmenet a été crée.");
        this.$router.push({
          name: 'departments.edit',
          params: {
            department: res.data.data.id,
          }
        })
      }).finally(() => callback());
    },
    updateDepartment(callback) {
      return DepartmentRepository.update(this.departmentId, {
        name: this.name,
        fulfilmentGroupThreshold: this.fulfilmentGroupThreshold,
        isInStock: this.isInStock,
        tagIds: this.tagIds,
        supplierIds: this.supplierIds,
        rackIds: this.rackIds,
      }).then(() => {
        this.$sendSuccessToast("Le departmenet a été sauvegardée.");
        this.$emit('updateTitle', this.name);
        this.$refs.form.resetForm();
      }).finally(() => callback());
    },
  },
  computed: {
    getSubmitButtonLabel() {
      return this.isCreateMode
        ? "Créer"
        : "Sauvegarder";
    },
    isCreateMode() {
      return this.mode === "create";
    }
  },
}
</script>