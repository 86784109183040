<template>
  <error-alert>
    Vous n'avez pas les autorisations nécessaires pour accéder à cette page.
  </error-alert>
</template>

<script>
import ErrorAlert from "@/components/elements/alerts/ErrorAlert";

export default {
  name: "NoPermission",
  components: {ErrorAlert}
}
</script>

<style scoped>

</style>