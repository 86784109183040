<template>
  <div class="relative">
    <div class="flex justify-between content-center gap-2">
      <!-- Add Button -->
      <div
        v-if="hasAddableTabs"
        class="z-30 p-2 px-4 rounded text-white bg-luxcaddy-700 hover:bg-luxcaddy-600 cursor-pointer"
        @click="openMenu('add')">
        <fa-icon icon="plus"></fa-icon>
      </div>

      <unsaved-changes-modal ref="unsavedChangesModal" @confirmed="onConfirmed"></unsaved-changes-modal>

      <transition name="fade">
        <ul v-show="isMenuOpen"
            class="z-50 absolute left-0 right-auto md:left-auto md:right-0 origin-top-right w-64 max-w-screen-sm mt-2 rounded-md shadow-lg mt-12 "
            role="listbox" tabindex="-1">

          <!-- Add Menu -->
          <div v-if="isMenuOpen" class="px-2 py-2 bg-white rounded-md shadow">
            <li
              v-for="(tab, i) in addableTabs"
              :key="i"
              class="block select-none cursor-pointer px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-100 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              @click="$store.getters['forms/anyDisplayedFormChanged'] ? openConfirmationModal(tab) : addTab(tab)">
              {{ tab.title }}
            </li>
          </div>

        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import UnsavedChangesModal from "../../../modals/UnsavedChangesModal";

export default {
  name: "CardAddHiddenTabsButton",
  components: {UnsavedChangesModal},
  props: {
    tabList: {
      type: Array,
    }
  },
  created() {
    // Closes the menu when the user clicks outside of this component.
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.isMenuOpen = false;
      }
    })
  },
  data: () => ({
    isMenuOpen: false,
    tabTryingToAdd: null,
  }),
  methods: {
    openMenu() {
      if (!this.hasAddableTabs)
        return false;

      this.isMenuOpen = true;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    addTab(tab) {
      if (this.$store.getters['forms/anyDisplayedFormChanged']) {
        this.openConfirmationModal();
      }

      tab.show();
      this.closeMenu();
      this.$emit('tab-added', tab.id);
    },
    openConfirmationModal(tabTryingToAdd) {
      this.tabTryingToAdd = tabTryingToAdd;
      this.$refs.unsavedChangesModal.openModal();
    },
    onConfirmed() {
      this.addTab(this.tabTryingToAdd);
      this.tabTryingToAdd = null;
    }
  },
  computed: {
    addableTabs() {
      return this.tabList.filter(t => t.canBeAddedManually);
    },
    hasAddableTabs() {
      return this.addableTabs.length;
    },
  }
}
</script>

