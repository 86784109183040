<template>
  <shared-invoice-list-table
    internal-only
  />
</template>

<script>
import SharedInvoiceListTable from "@/components/pages/Invoices/Shared/List/SharedInvoiceListTable";

export default {
  name: "ShowInternalInvoices",
  components: {SharedInvoiceListTable},
}
</script>
