<template>
  <div class="flex-grow flex flex-col overflow-auto">
    <order-fulfilment-item
      v-for="item in getItems"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script>
import OrderFulfilmentItem from "./OrderFulfilmentItem";
import {mapGetters} from "vuex";

export default {
  name: "OrderFulfilmentItemList",
  components: {OrderFulfilmentItem},
  computed: {
    ...mapGetters('fulfilment', ['getOrder']),
    getItems() {
      let items = this.getOrder?.items;
      if (items === null) {
        return [];
      }

      let filteredItems = this.getOrder?.itemsToDisplay;
      if (filteredItems !== undefined && filteredItems !== null) {
        return items.filter(h => filteredItems.includes(h.id));
      }

      return items;
    },

  },
}
</script>

