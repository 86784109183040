<template>
  <div class="bg-gray-100 px-2 py-1 w-full flex justify-between items-center gap-2">

    <div class="flex gap-2">
      <fa-icon class="text-xl text-gray-600" fixed-width icon="columns"/>

      <div
        v-for="column in possibleCols"
        :key="column"
        :class="[
                    isColSelected(column)
                    ? 'bg-luxcaddy-500'
                    : 'bg-gray-300 hover:bg-gray-400 cursor-pointer'
                ]"
        class="px-2 rounded-sm text-sm"
        @click="setCols(column)"
      >
        {{ column }}
      </div>
    </div>

    <div class="flex gap-x-2">
      <div class="bg-gray-300 hover:bg-gray-400 cursor-pointer px-1 rounded" @click="collapseAllRoutes">
        <fa-icon
          class="transform transition duration-200 text-gray-600 text-lg"
          icon="compress-alt"
        />
      </div>

      <div class="bg-gray-300 hover:bg-gray-400 cursor-pointer px-1 rounded" @click="expandAllRoutes">
        <fa-icon
          class="transform transition duration-200 text-gray-600 text-lg"
          icon="expand-alt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "RouteManagerSidebarColumnSetting",
  data: () => ({
    possibleCols: [
      1, 2, 3
    ]
  }),
  methods: {
    ...mapMutations('routeManager', [
      'setSidebarCols',
      'collapseAllRoutes',
      'expandAllRoutes',
    ]),
    setCols(col) {
      this.setSidebarCols(col);
    },
    isColSelected(col) {
      return this.getSidebarCols === col;
    }
  },
  computed: {
    ...mapGetters('routeManager', [
      'getSidebarCols'
    ]),
  },
}
</script>

