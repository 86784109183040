<template>
  <create-or-update-brand-form
    mode="update"
  />
</template>

<script>
import CreateOrUpdateBrandForm from "../Forms/CreateOrUpdateBrandForm";

export default {
  name: "EditBrand",
  components: {CreateOrUpdateBrandForm},
}
</script>