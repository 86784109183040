<template>
  <div>
    <scanner-listener
      :accept-quantity="false"
      accept-code128
      @scan="onScan"
    />


    <div v-if="!hasScannedAndDataAvailable" class="">
      <div class="text-2xl text-center">
        Pointage
      </div>

      <div class="h-32">
        <scan-indicator-animation
          color="#eab646"
        />
      </div>
    </div>
    <div
      v-else
      :class="[getTextColor, usedInTouchInterface ? 'text-center mt-4' : 'mb-6']"
      class="mb-6 text-center"
    >
      <div class="text-2xl">
        {{ timeClockData.name }}
      </div>
      <div class="font-bold">
        {{ isIn ? 'Entrée' : 'Sortie' }}
      </div>

      <div v-if="!isIn && timeClockData.timeSinceLastEntry !== null" class="mt-2 text-xl font-bold text-black">
        {{ timeClockData.timeSinceLastEntry }}
      </div>


      <div class="mt-2.5">
        <span class="italic">Total aujourd'hui:</span><br>
        <span class="font-bold text-black">{{ timeClockData.timeToday }}</span>
      </div>
    </div>

    <div class="text-center">
      <button-element
        v-if="showBackButton"
        size="small"
        type="light"
        @click="onBackButtonClick"
      >
        <fa-icon class="mr-2" fixed-width icon="arrow-left"/>
        Retour
      </button-element>
    </div>
  </div>
</template>

<script>
import ScannerListener
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/scanner/ScannerListener.vue";
import TimeClockEntryRepository from "@/repositories/TimeClockEntryRepository";
import ScanIndicatorAnimation from "@/components/elements/loading/ScanIndicatorAnimation.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "BarcodeTimeClockHandler",
  components: {ButtonElement, ScanIndicatorAnimation, ScannerListener},
  data: () => ({
    hasScanned: false,

    timeClockData: {
      name: null,
      type: null,
      timeToday: null,
      timeSinceLastEntry: null,
    }
  }),
  props: {
    usedInTouchInterface: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onScan({code}) {
      if (this.hasScanned) {
        return false;
      }

      this.timeClockData.name = null;
      this.timeClockData.type = null;
      this.timeClockData.timeToday = null;
      this.timeClockData.timeSinceLastEntry = null;

      this.hasScanned = true;
      TimeClockEntryRepository.personal.timeClockByLoginToken(code)
        .then((res) => {
          const data = res.data;

          this.timeClockData.name = data.name;
          this.timeClockData.type = data.type;
          this.timeClockData.timeToday = data.timeToday;

          if (data.timeSinceLastEntry !== undefined) {
            this.timeClockData.timeSinceLastEntry = data.timeSinceLastEntry;
          }

          let vm = this;
          setTimeout(function () {
            vm.onBackButtonClick();
          }, 3000);
        }).catch(() => {
        this.hasScanned = false;
      });
    },
    onBackButtonClick() {
      if (!this.usedInTouchInterface) {
        this.$emit('close');
        return;
      }

      this.hasScanned = false;
    }
  },
  computed: {
    hasScannedAndDataAvailable() {
      return this.hasScanned === true && this.timeClockData.name !== null;
    },
    isIn() {
      return this.hasScannedAndDataAvailable && this.timeClockData.type === "in";
    },
    getTextColor() {
      return this.isIn
        ? 'text-green-500'
        : 'text-yellow-500';
    },
    showBackButton() {
      // If used on login page, always show,
      // if used in touch interface, only show when scanned already
      if (!this.usedInTouchInterface) {
        return true;
      }

      return this.hasScanned;
    }
  },
}
</script>

<style scoped>

</style>