<template>
  <div
    :class="big ? 'text-3xl w-20 h-20' : 'w-12 px-2'"
    class="bg-black text-white text-center flex items-center justify-center rounded py-1 whitespace-nowrap"
    @click="$emit('click', $event)"
  >
    <fa-icon class="mr-1" fixed-width icon="thumbtack"/>
    <template v-if="hasDelivery">
      <span v-if="fulfilmentNumber">{{ fulfilmentNumber }}</span>
      <span v-else>--</span>
    </template>
    <span v-else>
            SL
        </span>
  </div>
</template>

<script>
export default {
  name: "FulfilmentNumberDisplay",
  props: {
    fulfilmentNumber: {
      type: [String, Number]
    },
    big: {
      type: Boolean,
      default: false,
    },
    hasDelivery: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>