<template>
  <create-or-update-short-url-form mode="update"></create-or-update-short-url-form>
</template>

<script>
import CreateOrUpdateShortUrlForm from "../Forms/CreateOrUpdateShortUrlForm";

export default {
  name: "EditShortUrl",
  components: {CreateOrUpdateShortUrlForm},
}
</script>