<template>
  <media-image-display-and-management
    ref="imageManagement"
    :media-able-id="bannerId"
    :possible-labels="getPossibleLabels"
    media-able-type="banner"
  />
</template>

<script>
import MediaImageDisplayAndManagement from "@/components/global/Media/Image/MediaImageDisplayAndManagement";

export default {
  name: "DisplayBannerImages",
  components: {MediaImageDisplayAndManagement},
  props: {
    bannerId: {
      type: Number,
      required: true
    },
  },
  computed: {
    getPossibleLabels() {
      return this.$luxcaddyConfig("banner.images.availableLabels");
    }
  },
  methods: {
    fetchImages() {
      this.$refs.imageManagement.fetchImages();
    }
  }
}
</script>

<style scoped>

</style>