<template>
  <div class="bg-white shadow-md rounded overscroll-auto overflow-x-auto overflow-y-hidden">
    <page-title v-if="title">
      {{ title }}
    </page-title>

    <table class="w-full table-auto overflow-x-hidden">

      <!-- Head -->
      <thead v-if="headings" v-click-outside="onHeadingOutsideClick" class="z-10 sticky top-0 w-full">
      <tr
        class="bg-gray-600 text-white leading-wide tracking-wide text-sm"
      >
        <th
          v-for="heading in getHeadings"
          :key="heading.label"
          :class="getClassByAlign(heading.align)"
          :style="heading.width !== undefined ? `width: ${heading.width}px` : ''" class="relative py-2.5 px-4"
        >

          <div :class="{'cursor-pointer hover:text-gray-300 select-none': heading.sort}"
               class=" flex items-center"
          >
            <div
              v-if="isSortMenuOpenForHeading(heading)"
              class="font-normal text-sm cursor-auto absolute w-52 -left-14 top-10 p-2 bg-white border rounded-md text-black flex flex-col gap-y-2 "
            >
              <div
                class="text-left bg-gray-100 hover:bg-gray-200 px-2 py-1.5 flex gap-x-1 items-center cursor-pointer"
                @click="changeSortDirection(heading, 'asc', false)"
              >
                <fa-icon
                  fixed-width
                  icon="sort-alpha-down"
                />
                Croissant
              </div>

              <div
                class="text-left bg-gray-100 hover:bg-gray-200 px-2 py-1.5 flex gap-x-1 items-center cursor-pointer"
                @click="changeSortDirection(heading, 'desc', false)"
              >
                <fa-icon
                  fixed-width
                  icon="sort-alpha-up"
                />
                Décroissant
              </div>

              <div class="h-1.5"></div>

              <div
                :class="[getSortByHeadingLabel(heading.label) === 'asc' ? 'border-luxcaddy' : 'border-transparent']"
                class="text-left bg-gray-100 hover:bg-gray-200 px-2 py-1.5 cursor-pointer"
                @click="changeSortDirection(heading, 'asc', true)"
              >
                <fa-icon
                  class="inline-block"
                  fixed-width
                  icon="sort-alpha-down"
                />
                Croissant & supprimer autres tris
              </div>

              <div
                :class="[getSortByHeadingLabel(heading.label) === 'desc' ? 'border-luxcaddy' : 'border-transparent']"
                class="text-left bg-gray-100 hover:bg-gray-200 px-2 py-1.5 cursor-pointer"
                @click="changeSortDirection(heading, 'desc', true)"
              >
                <fa-icon
                  class="inline-block"
                  fixed-width
                  icon="sort-alpha-up"
                />
                Décroissant & supprimer autres tris
              </div>

              <div class="h-1.5"></div>

              <div
                :class="[getSortByHeadingLabel(heading.label) === 'desc' ? 'border-luxcaddy' : 'border-transparent']"
                class="text-left bg-gray-100 hover:bg-gray-200 px-2 py-1.5 flex gap-x-1 items-center cursor-pointer"
                @click="changeSortDirection(heading, 'none', false)"
              >
                <fa-icon
                  fixed-width
                  icon="times"
                />
                Enlever triage
              </div>

            </div>

            <div
              class="w-full flex items-center gap-x-1"
              @click="onHeadingClick(heading)"
            >
              <div
                v-if="canUseSort && heading.sort"
                class="flex gap-x-0.5 items-center"
              >
                <fa-icon
                  v-if="getSortByHeadingLabel(heading.label) === 'asc'"
                  class="text-lg"
                  fixed-width
                  icon="sort-alpha-down"
                />
                <fa-icon
                  v-if="getSortByHeadingLabel(heading.label) === 'desc'"
                  class="text-lg"
                  fixed-width
                  icon="sort-alpha-up"
                />

                <span
                  v-if="getSortByHeadingLabel(heading.label) !== 'none'"
                  class="text-xs rounded-full bg-white text-black px-1"
                >
                  {{ getSortOrderByHeadingLabel(heading.label) }}
                </span>
              </div>
              <div
                v-if="heading.label"
                :class="{'underline': canUseSort && heading.sort}"
                class="flex-1 whitespace-nowrap"
              >
                {{ heading.label }}
              </div>
            </div>

            <div v-if="heading.selectbox">
              <input
                class="form-checkbox h-4 w-4 text-gray-600"
                type="checkbox"
                @change="onSelectChange"
              >
            </div>
          </div>
        </th>
      </tr>
      </thead>

      <!-- Body -->
      <tbody class="text-gray-600 text-sm">
      <slot></slot>

      <table-row v-if="showEmpty && !this.complex">
        <table-data-cell :colspan="getHeadings.length" class="text-center">
          <div class="px-8 py-2 text-xl text-gray-500">
            Votre requête n'a donné aucun résultat.
          </div>
        </table-data-cell>
      </table-row>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import PageTitle from "@/components/elements/pages/PageTitle";

export default {
  name: "CustomTable",
  components: {TableDataCell, TableRow, PageTitle},
  data() {
    return {
      openedSortMenu: null,
    }
  },
  props: {
    headings: {type: Array, required: false},

    // Whether Complex Mode is activated that can only be applied from ListTable
    complex: {
      type: Boolean,
      default: false
    },
    complexModeTableIdentifier: {
      type: String
    },
    rows: {
      type: Array,
      required: false,
      default: () => []
    },
    showEmpty: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    disableLoadingIndicator: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    getHeadings() {
      if (this.$isReadOnly()) {
        return this.headings.filter(h => {
          if (h.readOnly) {
            return !h.readOnly;
          }
          return true;
        });
      }
      return this.headings;
    },
    canUseSort() {
      return this.complex && this.complexModeTableIdentifier;
    },
  },
  created() {
    if (this.complex && !this.complexModeTableIdentifier) {
      this.$showErrorDialog("[Technical Error] Tables in complex mode requires an identifier to be set!");
    }

    this.setDefaultSorts();
  },
  methods: {
    setDefaultSorts() {
      if (!this.canUseSort)
        return false;


      // Only set default sorts if this is the first time this table is shown since the last Hard refresh
      if (!this.$store.getters["listTable/hasTableBeenInitialized"](this.complexModeTableIdentifier)) {
        this
          .getHeadings
          .filter(h => h.sort !== undefined && h.sort.default !== undefined && h.sort.default !== null) // Only if default sort set.
          .forEach(h => {
            this.changeSortDirection(h, h.sort.default);
          });
      }
    },

    resetAllSorts() {
      this.$store.commit('listTable/clearTableSort', this.complexModeTableIdentifier);
    },

    changeSortDirection(heading, direction = null, deleteOtherSorts = false) {
      if (!this.canUseSort)
        return false;

      let currentSort = this.getSortByHeadingLabel(heading.label);
      const key = this.getKeyForHeadingLabel(heading.label);

      // If no directions given, allow all.
      let allowedDirections = heading.sort.directions ?? ['asc', 'desc', 'none'];

      // Direction "none" should always be allowed since it's the default.
      if (!allowedDirections.includes("none")) {
        allowedDirections.push("none");
      }

      // Get next key in the Array (index + 1)
      const currentIndex = allowedDirections.indexOf(currentSort);

      // If no direction is explicitly set, we will cycle through the allowedDirectionsArray
      let newIndex = direction === null
        ? (currentIndex + 1) % allowedDirections.length
        : allowedDirections.indexOf(direction);

      const newSort = allowedDirections[newIndex];

      // User selected that all other sorts should be unset.
      if (deleteOtherSorts) {
        this.resetAllSorts();
      }

      this.openedSortMenu = null;

      // Remove the sort at all if not sorting.
      if (newSort === 'none') {
        this.$store.commit('listTable/deleteTableSort', {
          tableIdentifier: this.complexModeTableIdentifier,
          key: key,
        });
        return;
      }

      this.$store.commit('listTable/updateTableSort', {
        tableIdentifier: this.complexModeTableIdentifier,
        key: key,
        sort: newSort,
        dataField: heading.sort.dataField
      });
    },
    getSortByHeadingLabel(headingLabel) {
      const key = this.getKeyForHeadingLabel(headingLabel);

      return this
          .$store
          .getters['listTable/getSorts']
          .find(sort => sort.key === key)
          ?.sort
        ?? 'none';
    },
    getSortOrderByHeadingLabel(headingLabel) {
      const key = this.getKeyForHeadingLabel(headingLabel);

      const sortIndex = this
          .$store
          .getters['listTable/getSorts']
          .filter(sort => sort.tableIdentifier === this.complexModeTableIdentifier)
          .filter(sort => sort.sort !== "none")
          .findIndex(sort => sort.key === key)
        ?? null;

      return sortIndex !== null
        ? sortIndex + 1
        : null;
    },
    getKeyForHeadingLabel(headingLabel) {
      return headingLabel
          .toLowerCase()
          .replaceAll(' ', '_')
        + '-'
        + this.complexModeTableIdentifier;
    },
    getClassByAlign(align) {
      switch (align) {
        case "center":
          return 'text-center';
        case "right":
          return 'text-right';
        default:
          return 'text-left';
      }
    },


    onHeadingClick(heading) {
      if (!this.canUseSort || !heading.sort) {
        return false;
      }

      this.openedSortMenu = this.isSortMenuOpenForHeading(heading)
        ? null
        : this.getKeyForHeadingLabel(heading.label);
    },

    onHeadingOutsideClick() {
      this.openedSortMenu = null;
    },

    isSortMenuOpenForHeading(heading) {
      if (heading === undefined || heading.label === undefined) {
        return false;
      }

      return this.openedSortMenu === this.getKeyForHeadingLabel(heading.label);
    },

    /**
     * Called when the select all checkbox is marked.
     *
     * @param event
     */
    onSelectChange(event) {
      if (event.target.checked) {
        this.$emit('checkall');
      } else {
        this.$emit('uncheckall');
      }
    }
  }
}
</script>

