<template>
  <div>
    <form-wrapper
      ref="form"
      :show-changed="true"
      :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer pointage'"
      @submit="createOrUpdate"
    >
      <form-grid class="grid-cols-1 lg:grid-cols-3">
        <member-select-input
          v-model="memberId"
          :allow-unselecting="false"
          :only-member-types="['employee']"
          label="Employé"
          only-active-member-types
          validation-rules="required"
        />

        <select-element
          v-model="type"
          :allow-unselecting="false"
          :options="availableTypes"
          label="Type"
        />

        <date-picker-element
          v-model="dateTime"
          label="Date & heure"
          validation-rules="required"
          with-time
        />

      </form-grid>
    </form-wrapper>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import FormGrid from "@/components/elements/forms/FormGrid";
import TimeClockEntryRepository from "@/repositories/TimeClockEntryRepository";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CreateOrUpdateTimeClockEntryForm",
  components: {SelectElement, MemberSelectInput, DatePickerElement, FormGrid, FormWrapper},
  props: {
    mode: {
      type: String,
      validator: val => ['create', 'update'].includes(val)
    },
    timeClockEntryId: {
      type: Number,
    }
  },
  data: () => ({
    availableTypes: [
      {label: 'Entrée', value: 'in'},
      {label: 'Sortie', value: 'out'},
    ],

    memberId: null,
    type: 'in',
    dateTime: null,
  }),
  created() {
    if (this.isUpdateMode) {
      this.fetchEntry();
    }
  },
  methods: {
    fetchEntry() {
      TimeClockEntryRepository
        .getSingle(this.timeClockEntryId)
        .then(res => {
          this.memberId = res.data.data.memberId;
          this.type = res.data.data.type;
          this.dateTime = res.data.data.dateTime;
          this.$nextTick(() => {
            this.$refs.form.resetForm();
          })
        });
    },
    createOrUpdate(callback) {
      return this.isUpdateMode
        ? this.update(callback)
        : this.create(callback);
    },
    create(callback) {
      if (this.isUpdateMode)
        return false;

      TimeClockEntryRepository.create({
        memberId: this.memberId,
        type: this.type,
        dateTime: this.dateTime
      }).then((res) => {
        this.$sendSuccessToast('Pointage crée!');
        this.$router.push({
          name: 'timeclock.edit',
          params: {
            entry: res.data.data.id
          }
        });
      }).finally(() => callback());
    },
    update(callback) {
      if (!this.isUpdateMode)
        return false;

      TimeClockEntryRepository.update(
        this.timeClockEntryId,
        {
          memberId: this.memberId,
          type: this.type,
          dateTime: this.dateTime
        }
      ).then(() => {
        this.$sendSuccessToast('Pointage modifiée!');
        this.$refs.form.resetForm();
      }).finally(() => callback());
    }
  },
  computed: {
    isUpdateMode() {
      return this.mode === "update";
    }
  },
}
</script>