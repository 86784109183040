<template>
  <list-table
    :columns="columns"
    :create-button="false"
    :filters="filters"
    :pagination="showPagination"
    :repository-func="repositoryFunc"
    identifier="paymentsLogsTable"
    title="Logs"
  >
    <template v-slot:log-request-template="{ row }">
      <log-table-data-slot v-if="row.request"
                           :log-data="row.request"/>
    </template>

    <template v-slot:log-response-template="{ row }">
      <log-table-data-slot v-if="row.response"
                           :log-data="row.response"/>
    </template>

    <template v-slot:invoice-id-template="{ row }">
      <text-link
        v-if="row.invoiceId"
        :to="{name: 'invoices.edit', params: {id: row.invoiceId}}"
      >
        #{{ row.invoiceId }}
      </text-link>
      <div v-else>
        /
      </div>
    </template>
  </list-table>

</template>

<script>

import ListTable from "@/components/listTable/ListTable";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import LogTableDataSlot from "@/components/pages/Logs/TableSlots/LogTableDataSlot";
import {mapGetters} from "vuex";
import TextLink from "@/components/global/TextLink.vue";
import {
  FILTER_DATETIME_RANGE,
  FILTER_SELECT_MULTI,
  FILTER_SELECT_SINGLE
} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowPaymentsLogTable",
  components: {TextLink, ListTable, LogTableDataSlot},
  props: {
    showPagination: {
      type: Boolean,
      default: false
    },
    filterKey: {
      type: String,
      required: false
    },
    filterValue: {
      type: Number,
      required: false
    },
  },
  computed: {
    ...mapGetters('invoices', ['isInvoiceInternal']),
  },
  data() {
    return {
      title: "Payments Log",
      repositoryFunc: (...args) => {
        if (this.filterKey === undefined && this.filterValue === undefined) {
          return InvoiceRepository.payments.getPaymentsLogPaginated(...args)
        }
        if (this.isInvoiceInternal) {
          return InvoiceRepository.internal.payments.getPaymentsLogs(this.filterKey, this.filterValue, ...args)

        } else {
          return InvoiceRepository.payments.getPaymentsLogsByInvoice(this.filterKey, this.filterValue, ...args)
        }
      },
      columns: [
        {
          caption: 'Fac',
          width: 80,
          cellTemplate: 'invoice-id-template',
        },
        {
          caption: 'Provider',
          dataField: 'paymentProvider',
          sort: {
            dataField: 'paymentProvider'
          }
        },
        {
          caption: 'Action',
          dataField: 'action',
          sort: {
            dataField: 'action'
          }
        },
        {
          caption: 'Identifier',
          dataField: 'identifier',
          sort: {
            dataField: 'identifier'
          }
        },

        {
          caption: 'Status',
          dataField: 'status',
          sort: {
            dataField: 'status'
          }
        },
        {
          caption: 'RequestId',
          dataField: 'requestId',
          sort: {
            dataField: 'requestId'
          },
          visible: false
        },
        {
          caption: 'Request',
          cellTemplate: "log-request-template",
        },
        {
          caption: 'Response',
          cellTemplate: "log-response-template",
        },
        {
          caption: 'CreatedAt',
          dataField: 'createdAt',
          sort: {
            dataField: 'createdAt'
          }
        },
      ],
      filters: [
        {
          caption: 'ID Facture',
          filterKey: "invoiceid",
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Prestataire",
          filterKey: "provider",
          type: FILTER_SELECT_SINGLE,
          filterOptions: this.$luxcaddyConfig('invoice.paymentTransactions.paymentProviders'),
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Action",
          filterKey: "action",
          type: FILTER_SELECT_MULTI,
          filterOptions: this.$luxcaddyConfig('invoice.paymentTransactionsLog.actions'),
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Status",
          filterKey: "status",
          type: FILTER_SELECT_SINGLE,
          filterOptions: this.$luxcaddyConfig('invoice.paymentTransactionsLog.status'),
          forceIsVisible: true,
          small: true,
        },
        {
          caption: 'Identifier',
          filterKey: "identifier",
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Crée entre",
          filterKey: "createdAt",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>