<template>
  <tooltip
    :text="actionText"
    class="inline-block"
    tooltip-classes="mt-1"
  >
    <button
      v-shortkey.once="keys"
      :class="[active ? 'bg-yellow-400' : 'bg-yellow-100']"
      class="border border-yellow-300 px-1 py-1 rounded-md text-sm"
      @click="emitAction"
      @shortkey="emitAction"
    >
      <div class="flex items-center gap-x-1.5">
        <div class="p-0.5 flex items-center rounded-md">
          <div
            v-for="icon in getIcons"
            :key="icon"
            class="flex items-center gap-x-0.5"
          >
            <fa-icon
              :icon="icon"
              class="text-yellow-700"
              fixed-width
            />
          </div>
        </div>
        <div class="text-black font-bold mr-1">
          {{ getText }}
        </div>
      </div>
    </button>
  </tooltip>
</template>

<script>
import Tooltip from "@/components/elements/tooltips/Tooltip.vue";

export default {
  name: "ShortcutButton",
  components: {Tooltip},
  data: () => ({
    hovered: false,
    active: false,
  }),
  props: {
    iconSet: {
      type: [String, Array],
      required: true,
    },
    keys: {
      type: Array,
      required: true
    },
    actionText: {
      type: String,
      required: true
    }
  },
  methods: {
    emitAction() {
      this.$emit('action');
      this.active = true;

      const vm = this;
      setTimeout(function () {
        vm.active = false;
      }, 300);
    }
  },
  computed: {
    getText() {
      return this.keys.join(' + ').toUpperCase();
    },
    getIcons() {
      if (typeof this.iconSet === "string") {
        return [this.iconSet];
      }

      return this.iconSet;
    }
  },
}
</script>