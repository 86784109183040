<template>
  <div class="flex justify-between gap-2">

    <button-element
      :disabled="!isFinalized || isPaid || !isInvoiceInternal"
      type="dark"
      @click="pay"
    >
      <fa-icon class="mr-2" fixed-width icon="wallet"/>
      Payer par Wallet
    </button-element>

    <modal ref="modal" title="Payer par Wallet">
      <div class="flex gap-x-4">
        <button-element
          type="primary"
          @click="payByWallet(false)"
        >
          Payer
        </button-element>

        <button-element
          type="warning"
          @click="payByWallet(true)"
        >
          Payer et notifier le client
        </button-element>
      </div>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import Modal from "@/components/elements/modals/Modal";
import {mapActions, mapGetters} from "vuex";
import InvoiceRepository from "@/repositories/InvoiceRepository";

export default {
  name: "PayByWalletButton",
  components: {Modal, ButtonElement},
  data: () => ({
    instructionRelatedExceptionGiven: false,
    finalizeInvoice: true,
    modalBtnTitle: ''
  }),

  methods: {

    pay() {
      if (!this.isInvoiceInternal) {
        this.payByWallet(false);
        return;
      }
      this.openModal();
    },

    payByWallet(sendCustomerNotification) {
      if (!this.isInvoiceInternal)
        return false;

      InvoiceRepository.internal.payments.payByWallet(
        this.$route.params.id, sendCustomerNotification
      ).then(() => {
        this.closeModal();
        this.$sendSuccessToast("Paiement traité!");
        this.refetchCurrentInvoice();
      })
    },
    openModal() {
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
  },
  computed: {
    ...mapActions('invoices', ['refetchCurrentInvoice']),
    ...mapGetters('invoices', [
      'getInvoice',
      'getOrderId',
      'getMemberId',
      'isInvoiceInternal',

      'isTreatingInvoicePDF',
      'getExternalInvoicePDFIdBeingTreated',
    ]),
    getStatus() {
      return this.getInvoice?.status;
    },
    isFinalized() {
      return this.getStatus === "finalized";
    },
    isPaid() {
      return this.getInvoice?.paymentStatus === 'paid';
    },
  },
}
</script>