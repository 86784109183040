<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :enable-search="false"
    :label="label"
    :limit-option-menu-width="limitOptionMenuWidth"
    :occupy-validation-space="occupyValidationSpace"
    :options="options"
    :touch-optimized="true"
    :type="type"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  ></select-element>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "TouchOptimizedSelect",
  components: {SelectElement},
  props: {
    type: {
      type: String,
      default: "single"
    },
    value: {
      required: true
    },
    options: {
      required: true,
      // Either an Array or Object
      validator: val => typeof val === "object"
    },
    label: {
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    /**
     * When enabled, the option menu will not have the same length as the  input, when the input is very long
     */
    limitOptionMenuWidth: {
      type: Boolean,
      default: false
    },
    /**
     * Whether it is possible to click on the selected option again to unselect it an emit null.
     */
    allowUnselecting: {
      type: Boolean,
      default: true
    },
  },
}
</script>

