<template>
  <card title="Import mouvements bancaire">
    <form-wrapper ref="form"
                  :show-changed="true"
                  :show-submit-button="false"
                  submit-button-label="Télécharger"
                  @submit="uploadFile"
    >

      <file-input-element
        v-model="filesToUpload"
        :image-only="false"
        title="Ajouter fichiers"
      />
      <div v-if="filesToUpload.length" class="flex mt-4 justify-between">
        <button-element v-if="showCancelBth" class=" ml-1 float-left" type="light" @click="cancel">
          Annuler
        </button-element>
        <button-element class="ml-auto" type="primary" @click="$refs.form.onSubmit()">
          {{ submitBtn }}
        </button-element>

      </div>
    </form-wrapper>
  </card>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import AccountingBankMovementRepository from "../../../../../repositories/AccountingBankMovementRepository";
import FileInputElement from "@/components/elements/forms/elements/base/FileInputElement.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "ImportBankMovements",
  components: {ButtonElement, FileInputElement, FormWrapper, Card},
  data: () => ({
    filesToUpload: [],
    hideUploadBtn: false
  }),
  props: {
    showCancelBth: {
      type: Boolean
    },
    submitBtn: {
      type: String,
      default: 'Télécharger'
    },
  },
  methods: {
    cancel() {
      this.filesToUpload = [];
      this.$emit('cancel');
    },
    uploadFile(callback) {
      if (!this.filesToUpload.length) {
        return false;
      }

      AccountingBankMovementRepository.import(this.filesToUpload)
        .then(() => {
          this.$sendSuccessToast("Fichiers téléchargé!");
          this.filesToUpload = [];
          this.$emit('change');
        }).finally(() => callback());
    }
  },
}
</script>

<style scoped>

</style>