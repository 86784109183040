<template>
  <div
    :class="[selected ? 'border-luxcaddy-300' : 'hover:bg-gray-50 border-gray-200 ']"
    class="relative h-full rounded-xl shadow-lg hover:shadow-xl flex flex-row items-center border pr-2 py-1 bg-white ">

    <div v-if="showHandle && !$isReadOnly()" class="w-16 h-full text-center">
      <fa-icon class="text-gray-400 hover:text-gray-500 handle cursor-grab" icon="bars"></fa-icon>
    </div>


    <div class="flex flex-row items-center justify-between gap-x-8 flex-1 px-4">
      <div class="flex flex-row items-center  gap-x-8 flex-1">

        <div class="flex-1">
          <h3 class="text-lg text-gray-800 font-bold overflow-ellipsis tracking-wide flex-1">
            {{ blogPost.title }}
          </h3>

          <span class="text-xs text-gray-400">
                        {{ blogPost.subtitle }}
                    </span>
        </div>

        <div class="w-28 text-center">
          <blog-post-type-label
            :type="blogPost.type"
            class="w-max"
          />
        </div>
      </div>


      <div class="justify-self-end">
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
import BlogPostTypeLabel from "@/components/pages/Blog/Posts/Table/Components/BlogPostTypeLabel";

export default {
  name: "BlogPostDisplayCard",
  components: {BlogPostTypeLabel},
  props: {
    blogPost: {
      type: Object,
      required: true
    },
    showHandle: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
}
</script>

