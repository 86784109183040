<template>
  <div
    v-if="hasResponse"
  >
    <div class="flex justify-between flex-wrap">
      <page-title>Résultats</page-title>

      <!-- Export button shown if reponse returned rows -->
      <div class="flex gap-2">
        <button-element
          v-if="getResponseReturnedRows"
          size="small"
          type="primary"
          @click="exportResults('xlsx')"
        >
          Exporter (Excel)
        </button-element>

        <button-element
          v-if="getResponseReturnedRows"
          size="small"
          type="light"
          @click="$refs.exportCsvModal.openModal()"
        >
          Exporter (CSV)
        </button-element>
      </div>
    </div>

    <!-- Query worked, but no rows returned. -->
    <div v-if="getResponseReturnedRows === false">
      <error-alert size="small">
        Le résultat du rapport exécuté n'a pas renvoyé de données.
      </error-alert>
    </div>

    <!-- Query worked, and we got rows -->
    <div v-else>
      <report-query-response-table/>
    </div>
      <export-csv-options-modal ref="exportCsvModal"></export-csv-options-modal>

  </div>
</template>

<script>
import ErrorAlert from "@/components/elements/alerts/ErrorAlert.vue";
import ReportQueryResponseTable
  from "@/components/pages/ReportQueries/Execute/Sub/Response/ReportQueryResponseTable.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import ReportQueryRepository from "@/repositories/ReportQueryRepository";
import {exportResponse} from "@/helpers/exportHelper";
import ExportCsvOptionsModal from "@/components/pages/ReportQueries/Execute/Sub/ExportCsvOptionsModal.vue";

export default {
  name: "ReportQueryResponseContainer",
  components: {ExportCsvOptionsModal, ButtonElement, PageTitle, ReportQueryResponseTable, ErrorAlert},
  computed: {
    getResponseReturnedRows() {
      return this.$store.getters['reportQuery/getSelectedQueryExecutionHasResultRows'];
    },
    hasResponse() {
      return this.getResponseReturnedRows !== null;
    }
  },
  methods: {
    exportResults(type = "xlsx") {
      let selectionPlaceholders = this.$store.getters["reportQuery/getSelectionPlaceholders"];
      let values = [];

      selectionPlaceholders.forEach(ph => {
        values.push({
          name: ph.placeholder,
          value: ph.value
        });
      });

      return ReportQueryRepository
        .exportQuery(type, this.$store.getters["reportQuery/getSelectedQueryId"], values)
        .then((res) => {
          exportResponse(res, `report`, 'pdf');
        })
    }
  }
}
</script>