import apiService from './Base/BaseRepository';

const url = '/delivery';

export default {
  async getPaginated(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0
  ) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getActiveByDay(date) {
    return this.getPaginated(
      1000,
      1,
      [],
      [
        {filterKey: 'date', filterValue: date},
        {filterKey: 'nonCanceled', filterValue: 1},
      ]
    )
  },

  async getSingle(deliveryId) {
    return await apiService
      .get(`${url}/${deliveryId}`);
  },

  async getSignatureImage(deliveryId) {
    return await apiService
      .get(`${url}/${deliveryId}/signature`);
  },

  async getTranslatedAbsenceMessage(deliveryId) {
    return await apiService
      .get(`${url}/${deliveryId}/translated-absence-message`);
  },

  async create(payload) {
    return await apiService
      .post(`${url}/`, payload);
  },

  async update(deliveryId, payload) {
    return await apiService
      .patch(`${url}/${deliveryId}`, payload);
  },

  async markManuallyAsDelivered(deliveryId, payload) {
    return await apiService
      .patch(`${url}/${deliveryId}/markManuallyAsDelivered`, payload);
  },

  async cancel(deliveryId) {
    return await apiService
      .patch(`${url}/${deliveryId}/cancel`);
  },


  /**
   * Sets the quantity of delivery boxes used.
   *
   * @param deliveryId
   * @param quantity
   * @returns {Promise<*>}
   */
  async setBoxQuantity(deliveryId, quantity) {
    return await apiService
      .patch(`${url}/${deliveryId}/setBoxQuantity`, {
        quantity: quantity
      }, {
        showLoadingIndicator: false
      });
  },

  /**
   * Requests a fulfilment number for a delivery.
   *
   * @param deliveryId
   * @returns {Promise<*>}
   */
  async attributeFulfilmentNumber(deliveryId) {
    return await apiService
      .patch(`${url}/${deliveryId}/attributeFulfilmentNumber`);
  },

  async markDelivered(deliveryId, {wasCustomerPresentOnDelivery, deposits, signature = null} = []) {
    return await apiService.patch(`${url}/${deliveryId}/markDelivered`, {
      deposits,
      wasCustomerPresentOnDelivery,
      signature
    })
  },

  instructions: {
    async getPaginated(deliveryId, perPage = 20, page = 1, sorts = [], filters = [], withTotal = 0) {
      return await apiService
        .setPagination(perPage, page, withTotal)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/${deliveryId}/instruction`);
    },

    async getSingle(deliveryId, instructionId) {
      return await apiService.get(`${url}/${deliveryId}/instruction/${instructionId}`);
    },

    async create(deliveryId, payload) {
      return await apiService.post(`${url}/${deliveryId}/instruction`, payload);
    },

    async update(deliveryId, instructionId, payload) {
      return await apiService.patch(`${url}/${deliveryId}/instruction/${instructionId}`, payload);
    },

    async delete(deliveryId, instructionId) {
      return await apiService.delete(`${url}/${deliveryId}/instruction/${instructionId}`);
    },
  },

  async getUndeliveredFulfilmentIds() {
    return await apiService.get(`${url}/undelivered/fulfillmentIds`);
  },

  async getDeliveryLabelHtml(deliveryId) {
    return await apiService.get(`${url}/${deliveryId}/getDeliveryLabelHtml`, {
      showLoadingIndicator: false
    });
  }
}
