<template>
  <div
    class="flex-none bg-gray-600 border-r border-gray-600 flex flex-col relative"
  >

    <!-- Black overlay when hovering delivery on map -->
    <transition name="fade">
      <div
        v-if="!isDeliveryWithoutRouteHovered && (getHoveredRouteId || getHoveredDriverRouteId) && getHoverInitiatorIsMap"
        class="absolute inset-0 z-40 opacity-70 bg-black"
      ></div>
    </transition>

    <route-manager-sidebar-column-setting/>

    <div class="px-3 py-2 bg-gray-100 flex gap-x-3 text-center">
      <div class="flex items-center gap-x-2">
        <div class="h-4 w-4 bg-red-100 border border-gray-400"></div>
        <div class="text-sm">Non Finalisé</div>
      </div>
      <div class="flex items-center gap-x-2">
        <div class="h-4 w-4 bg-luxcaddy-100 border border-gray-400"></div>
        <div class="text-sm">Finalisé</div>
      </div>
    </div>

    <div class="relative overflow-y-auto h-full">
      <div
        :class="`grid-cols-${getSidebarCols}`"
        class=" p-2 grid items-start gap-4"
      >
        <template v-if="getRoutes.length > 0">
          <route-manager-route-block
            v-for="route in getRoutes"
            :id="`routeblock_${route.id}`"
            :key="route.id"
            :class="{'z-50': ([getHoveredRouteId, getHoveredDriverRouteId].includes(route.id)) && getHoverInitiatorIsMap}"
            :route-id="route.id"
          />
        </template>
        <div
          v-else
          class="bg-gray-300 p-3 w-64 text-center"
        >
          Aucune route n'a encore été créé pour cette journée.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteManagerSidebarColumnSetting
  from "@/components/pages/RouteManager/Includes/Sidebar/Includes/RouteManagerSidebarColumnSetting";
import {mapGetters, mapMutations, mapState} from "vuex";
import RouteManagerRouteBlock
  from "@/components/pages/RouteManager/Includes/Sidebar/Includes/RouteManagerRouteBlock/RouteManagerRouteBlock";

export default {
  name: "RouteManagerSidebar",
  components: {RouteManagerRouteBlock, RouteManagerSidebarColumnSetting},
  methods: {
    ...mapMutations('routeManager', [
      'expandOrCollapseRoute'
    ]),

    scrollToRouteId(routeId) {
      if (this.settings.hoverEffectsSidebar) {

        // If the route block that contains the delivery is collapsed, we open it.
        if (!this.isRouteExpanded(routeId)) {
          this.expandOrCollapseRoute(routeId);
        }

        document
          .getElementById(`routeblock_${routeId}`)
          .scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  watch: {
    getHoveredRouteId(routeId) {
      if (!routeId || !this.getHoverInitiatorIsMap)
        return false;

      // Scroll to Route Block
      this.scrollToRouteId(routeId);
    },
    getHoveredDriverRouteId(routeId) {
      if (!routeId || !this.getHoverInitiatorIsMap)
        return false;

      // Scroll to Route Block
      this.scrollToRouteId(routeId);
    }
  },
  computed: {
    ...mapGetters('routeManager', [
      'getSidebarCols',
      'getRoutes',
      'getHoveredRouteId',
      'getHoverInitiatorIsMap',
      'getHoveredDriverRouteId',
      'isRouteExpanded'
    ]),
    ...mapState('routeManager', [
      'settings'
    ]),

    isDeliveryWithoutRouteHovered() {
      return this.$store.getters['routeManager/isDeliveryWithoutRouteHovered'];
    }
  },

}
</script>

