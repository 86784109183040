import auth from "@/router/middlewares/authMiddleware";
import TouchInterfaceTasksList from "@/components/pages/TouchInterface/PersonalSpace/Tasks/TouchInterfaceTasksList";
import TouchInterfaceHolidayList
  from "@/components/pages/TouchInterface/PersonalSpace/Holidays/TouchInterfaceHolidayList.vue";
import TouchInterfaceNotificationList
  from "@/components/pages/TouchInterface/PersonalSpace/Notifications/TouchInterfaceNotificationList.vue";

export default [
  {
    path: '/touch/my-space/tasks',
    name: 'touchInterface.myspace.tasks.index',
    component: TouchInterfaceTasksList,
    meta: {
      title: 'Mes tâches',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS']
    }
  },
  {
    path: '/touch/my-space/holidays',
    name: 'touchInterface.myspace.holidays.index',
    component: TouchInterfaceHolidayList,
    meta: {
      title: 'Mes congés',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS']
    }
  },
  {
    path: '/touch/my-space/notifications',
    name: 'touchInterface.myspace.notifications.index',
    component: TouchInterfaceNotificationList,
    meta: {
      title: 'Mes notifications',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS']
    }
  },
]