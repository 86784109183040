<template>
  <div
    v-click-outside="closeAllPickers"
    :class="{'z-30 relative': applyZLevel}"
  >
    <form-label
      v-if="label"
      :label="label"
      :show-required-mark="isInReadOnlyMode ? false : isRequired"
      class="mb-1"
    />
    <div
      :class="[
            (aggressiveErrorDisplay && errors.length) ? 'focus-within:ring-red-200' : 'focus-within:ring-luxcaddy',
            small
               ? 'h-6 text-xs'
               : 'h-8 text-sm',
            disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'
        ]"
      class="flex shadow border text-gray-700 border-gray-200 rounded-md focus-within:ring"
    >
      <div class="flex-1 | flex items-center | pr-2 | relative">
        <date-range-picker-shortcuts-icon
          v-if="enableShortCuts"
          :shortcuts="getShortcuts"
          @shortcut="onShortcutSelected"
        />
        <input
          v-model="startDate"
          :class="{'text-xs': smallText, 'bg-gray-100': disabled, 'pl-4': !enableShortCuts, 'pl-1': enableShortCuts}"
          :disabled="disabled"
          :placeholder="startPlaceholder"
          class="formElement | flex-1 | appearance-none pr-1 outline-none border-none rounded-l-md w-0 bg-inherit"
          type="text"
          @blur="onStartDateBlur"
          @keyup.enter="$emit('enter', $event)"
          @keyup.esc="$emit('escape', $event)"
        >
        <div>
          <date-time-picker-icons-with-overlay
            ref="dateTimeStartPickerIcons"
            v-model="startDate"
            :disabled="disabled"
            :overlay-position="overlayPosition"
            :small-icons="smallText"
            :with-time="withTime"
            is-used-for-range
            @close="onOverlayShown(false, 'start')"
            @open="onOverlayShown(true, 'start')"
            @selected="onStartDateSelect"
          />
        </div>
      </div>
      <div class="h-full w-px bg-gray-200"></div>
      <div class="flex-1 | flex items-center | pr-1 | relative">
        <input
          v-model="endDate"
          :class="{'text-xs': smallText, 'bg-gray-100': disabled}"
          :disabled="disabled"
          :placeholder="endPlaceholder"
          class="formElement | flex-1 | appearance-none pl-2 pr-1 outline-none border-none rounded-l-md w-0 bg-inherit"
          type="text"
          @blur="onEndDateBlur"
          @keyup.enter="$emit('enter', $event)"
          @keyup.esc="$emit('escape', $event)"
        >
        <div>
          <date-time-picker-icons-with-overlay
            ref="dateTimeEndPickerIcons"
            v-model="endDate"
            :disabled="disabled"
            :on-date-selection="getOnDateSelectionConfig"
            :overlay-position="overlayPosition"
            :small-icons="smallText"
            :with-time="withTime"
            is-used-for-range
            @close="onOverlayShown(false, 'end')"
            @open="onOverlayShown(true, 'end')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormLabel from "@/components/elements/forms/elements/partials/FormLabel.vue";
import DateTimePickerIconsWithOverlay
  from "@/components/elements/forms/elements/base/includes/DateTimePickerIconsWithOverlay.vue";
import DateRangePickerShortcutsIcon
  from "@/components/elements/forms/elements/base/dateRange/Includes/DateRangePickerShortcutsIcon.vue";

export default {
  name: "DateRangePickerElement",
  components: {DateRangePickerShortcutsIcon, DateTimePickerIconsWithOverlay, FormLabel},
  data() {
    return {
      applyZLevel: false,
    }
  },
  props: {
    value: Object,
    placeholder: String,
    label: String,
    small: Boolean,
    withTime: Boolean,
    startPlaceholder: {
      type: String,
      default: "Date début"
    },
    endPlaceholder: {
      type: String,
      default: "Date fin"
    },
    validationRules: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    smallText: {
      type: Boolean,
      default: false
    },
    /**
     * If true, when user selects start date,
     * the end date will also be set to the same date
     */
    prefillEndDate: {
      type: Boolean,
      default: false
    },
    /**
     * If true, when selecting an end date,
     * it will be set by default to 23:59 instead of 00:00
     */
    endDateEndOfDay: {
      type: Boolean,
      default: false,
    },
    overlayPosition: {
      type: String,
      default: 'right'
    },

    enableShortCuts: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    startDate: {
      get() {
        return this.value.startDate;
      },
      set(startDate) {
        if (startDate === "") {
          startDate = null;
        }

        let endDate = this.endDate;

        // If StartDate Changed & now Before EndDate -> Set EndDate to null.
        let asDayJs = this.$date(startDate, [this.startDatePickerIconsComponent.getReadableFormat(this.withTime)]);
        if (asDayJs.isValid()) {
          if (endDate !== null) {
            let endDateAsDayJs = this.$date(endDate, [this.endDatePickerIconsComponent.getReadableFormat(this.withTime)]);
            if (endDateAsDayJs.isValid() && endDateAsDayJs.isBefore(asDayJs)) {
              endDate = null;
            }
          }
        }

        // If endDate is not set, set its preselected date in the datepicker to the startDate selected
        if (endDate === null) {
          if (asDayJs.isValid()) {
            this.endDatePickerIconsComponent.setDateOnPicker(startDate);
          } else {
            this.endDatePickerIconsComponent.setDateOnPicker(null);
          }
        }

        this.$emit('input', {
          startDate,
          endDate: endDate
        });
      },
    },
    endDate: {
      get() {
        return this.value.endDate;
      },
      set(endDate) {
        if (endDate === "") {
          endDate = null;
        }
        this.$emit('input', {
          endDate,
          startDate: this.startDate
        });
      }
    },
    /**
     * Determines if the input is in readonly mode.
     * @returns {boolean}
     */
    isInReadOnlyMode() {
      return this.$isReadOnly() && !this.activeInReadOnly;
    },
    /**
     * Determines if the input is required to be filled out.
     * @return {boolean}
     */
    isRequired() {
      if (!this.validationRules) {
        return false;
      }

      let rules = this.validationRules.split('|');
      return rules.includes("required");
    },
    /**
     * Determines if the input should show validation errors in aggressive mode (red text).
     * @return {boolean}
     */
    aggressiveErrorDisplay() {
      return this.$store.getters['userInterface/isValidationInAggressiveErrorDisplayMode'];
    },

    /**
     * Config passed to the Pickers that determines
     * what happens when selecting a date on the picker
     */
    getOnDateSelectionConfig() {
      return {
        setTime: {
          hour: this.endDateEndOfDay ? 23 : 0,
          minute: this.endDateEndOfDay ? 59 : 0,
          second: this.endDateEndOfDay ? 59 : 0
        }
      };
    },

    startDatePickerIconsComponent() {
      return this.$refs.dateTimeStartPickerIcons;
    },
    endDatePickerIconsComponent() {
      return this.$refs.dateTimeEndPickerIcons;
    },

    /**
     * The list of Shortcuts that will be displayed in the Bolt (Blitz) Menu if enabled.
     *
     * @returns {[{start: *, end: *, label: string},{start: *, end: *, label: string},{start: *, end: *, label: string},{start: *, end: *, label: string},{start: *, end: *, label: string},null,null,null]}
     */
    getShortcuts() {
      const now = this.$date();

      return [
        [
          {
            start: now.startOf('year').startOf('day'),
            end: now.endOf('year').endOf('day'),
            label: 'Année courante'
          },
          {
            start: now.startOf('month').startOf('day'),
            end: now.endOf('month').endOf('day'),
            label: 'Mois courant'
          },
          {
            start: now.startOf('week').startOf('day'),
            end: now.endOf('week').endOf('day'),
            label: 'Semaine courante'
          },
        ],
        [
          {
            start: now.subtract(1, 'year').startOf('year').startOf('day'),
            end: now.subtract(1, 'year').endOf('year').endOf('day'),
            label: 'Année précédente'
          },
          {
            start: now.subtract(1, 'month').startOf('month').startOf('day'),
            end: now.subtract(1, 'month').endOf('month').endOf('day'),
            label: 'Mois précédent'
          },
          {
            start: now.subtract(1, 'week').startOf('week').startOf('day'),
            end: now.subtract(1, 'week').endOf('week').endOf('day'),
            label: 'Semaine précédente'
          },
        ],
        [
          {start: now.subtract(3, 'month').startOf('day'), end: now, label: '3 derniers mois'},
          {start: now.subtract(6, 'month').startOf('day'), end: now, label: '6 derniers mois'},
          {start: now.subtract(12, 'month').startOf('day'), end: now, label: '12 derniers mois'},
        ]
      ];
    }
  },
  methods: {
    closeAllPickers() {
      this.closeDateTimeStartPickers();
      this.closeDateTimeEndPickers();
    },
    closeDateTimeStartPickers() {
      if (this.startDatePickerIconsComponent) {
        this.startDatePickerIconsComponent.hidePickers();
      }
    },
    closeDateTimeEndPickers() {
      if (this.endDatePickerIconsComponent) {
        this.endDatePickerIconsComponent.hidePickers();
      }
    },
    onOverlayShown(shown, startOrEnd) {
      this.applyZLevel = shown;
      if (startOrEnd === "start") {
        this.closeDateTimeEndPickers();
      } else if (startOrEnd === "end") {
        this.closeDateTimeStartPickers();
      }
    },


    /**
     * If prefill enabled, when user selects a start date,
     * the end date will be set to the same date with 23:59:59 as time
     * @returns {boolean}
     */
    onStartDateSelect() {
      let dayJsStartDate = this.$date(this.startDate, this.startDatePickerIconsComponent.getReadableFormat(this.withTime));

      if (!this.prefillEndDate || this.endDate !== null) {
        //If Start Date changed & endDate now before Startdate -> EndDate set to null.
        // let endDateDayJs = this.$date(this.endDate, [this.startDatePickerIconsComponent.getReadableFormat(this.withTime)]);
        // if (endDateDayJs.isValid() && endDateDayJs.isBefore(dayJsStartDate)) {
        //     this.endDate = null;
        //     // Set Picker Date to today.
        //     this.endDatePickerIconsComponent.setDateOnPicker(this.$date().format('DD.MM.YYYY'));
        // }
        return false;
      }

      if (this.withTime) {
        dayJsStartDate = dayJsStartDate.hour(23).minute(59).second(59);
        this.endDate = dayJsStartDate.format(this.startDatePickerIconsComponent.getReadableFormat(this.withTime));
      } else {
        this.endDate = this.startDate;
      }
    },

    /**
     * Called when the user leaves the start date field after typing manually.
     *
     * When the user entered a valid date but not a time, and the date range picker has time enabled, it should set the time automatically.
     */
    onStartDateBlur(e) {
      let dayJsDate = this.$date(e.target.value,
        [
          this.startDatePickerIconsComponent.getReadableFormat(false),
          this.startDatePickerIconsComponent.getReadableFormat(true)
        ], true);

      if (dayJsDate.isValid()) {
        // Date is entered in exact format: DD.MM.YYYY but supports Time.
        if (this.withTime) {
          this.startDate = dayJsDate.format('DD.MM.YYYY HH:mm:ss');
        }
      }
    },

    /**
     * Called when the user leaves the end date field after typing manually.
     *
     * When the user entered a valid date but not a time, and the date range picker has time enabled, it should set the time automatically.
     */
    onEndDateBlur(e) {
      let dayJsDate = this.$date(e.target.value, this.endDatePickerIconsComponent.getReadableFormat(false), true);

      // Date is entered in exact format: DD.MM.YYYY
      if (this.withTime && dayJsDate.isValid()) {
        dayJsDate = dayJsDate.set('hour', 23).set('minute', 59).set('second', 59);
        this.endDate = dayJsDate.format('DD.MM.YYYY HH:mm:ss');
      }
    },


    /**
     * Applies the dates passed from the selected Shortcuts on both date fields.
     *
     * @param start
     * @param end
     */
    onShortcutSelected({start, end}) {
      this.startDate = start.format(
        this.withTime
          ? 'DD.MM.YYYY HH:mm:ss'
          : 'DD.MM.YYYY'
      );

      // NextTick required because of the overwrite on both Watchers of the respecitvely other date.
      this.$nextTick(() => {
        this.endDate = end.format(
          this.withTime
            ? 'DD.MM.YYYY HH:mm:ss'
            : 'DD.MM.YYYY'
        );
      });
    }
  },
}
</script>