<template>
  <show-payments-table :show-actions="false" :show-pagination="true"/>
</template>

<script>
import ShowPaymentsTable from "@/components/pages/Invoices/Shared/EditInvoice/Payments/ShowPaymentsTable";

export default {
  name: "ShowPayments",
  components: {ShowPaymentsTable}
}
</script>

<style scoped>

</style>