<template>
  <list-table
    :columns="columns"
    :create-button="false"
    :filters="filters"
    :force-filters="getForceFilters"
    :pagination="true"
    :repository-func="repositoryFunc"
    identifier="logsTable"
    title="Logs"
  >

    <template #top>
      <card class="mb-4" size="small">
        <text-input-element
          v-model="filterDataKey"
          :occupy-validation-space="false"
          active-in-read-only
          label="Recherche propriété dans Data"
        />
      </card>
    </template>

    <template v-slot:member-name-template="{ row }">
      <span v-if="row.member">{{ row.member.fullName }}</span>
      <span v-else class="italic text-gray-400">SYSTEM</span>
    </template>

    <template v-slot:log-data-template="{ row }">
      <log-table-data-slot :filter-data-key="filterDataKey" :log-data="row.data"/>
    </template>
  </list-table>
</template>

<script>
import ListTable from "../../listTable/ListTable";
import LogsRepository from "@/repositories/LogsRepository";
import LogTableDataSlot from "@/components/pages/Logs/TableSlots/LogTableDataSlot";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import Card from "@/components/elements/cards/Card";
import {
    FILTER_DATETIME_RANGE, FILTER_LOG_ACTIONS,
    FILTER_LOGGABLE_TYPE, FILTER_SELECT_SINGLE,
    FILTER_SINGLE_BOOLEAN
} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowLogsTable",
  components: {Card, TextInputElement, LogTableDataSlot, ListTable},
  props: {
    defaultLoggableType: {
      type: String,
    },
    defaultLoggableId: {
      type: Number,
    }
  },

  computed: {
    getForceFilters() {
      if (this.defaultLoggableType !== undefined || this.defaultLoggableId !== undefined) {
        return [
          {filterKey: "loggableType", filterValue: this.defaultLoggableType},
          {filterKey: "loggableId", filterValue: this.defaultLoggableId},
        ];
      }

      return [];
    }
  },
  created() {
    if (this.defaultLoggableType !== undefined) {
      this.tableIdentifier = this.tableIdentifier + '-' + this.defaultLoggableType;
    }
  },
  data() {
    return {
      tableIdentifier: "logsTable",
      filterDataKey: null,

      title: "Logs",
      repositoryFunc: LogsRepository.getPaginated,
      columns: [
        {
          caption: 'Membre',
          cellTemplate: "member-name-template",
        },
        {
          caption: 'Action',
          dataField: 'action',
          sort: {
            dataField: 'action'
          }
        },
        {
          caption: 'Data',
          cellTemplate: "log-data-template",
        },
        {
            caption: 'Status',
            dataField: 'status',
        },
        {

          caption: 'Error',
          dataField: 'errorMessage',
          canBreakLine: true,
          visible: false
        },
        {
          caption: "Concerne",
          calculateCellValue: r => {
            return `${r.loggableType} &middot; ID: ${r.loggableId}`;
          },
        },
        {
          caption: 'Executée',
          calculateCellValue: r => {
            if (r.createdAt === null)
              return "";

            return this.$date(r.createdAt).format('DD.MM.YYYY HH:mm:ss');
          },
          sort: {
            dataField: 'createdAt',
            default: 'desc'
          }
        },
      ],
      filters: [
        {
          caption: "Nom Membre",
          filterKey: "memberFullName",
          forceIsVisible: true,
          small: true
        },
        {
          type: FILTER_LOGGABLE_TYPE,
          caption: "Type",
          filterKey: "loggableType",
          visible: this.defaultLoggableType === undefined,
          forceIsVisible: true,
          small: true
        },
        {
          caption: "ID",
          filterKey: "loggableId",
          visible: this.defaultLoggableId === undefined,
          forceIsVisible: true,
          small: true
        },
        {
          type: FILTER_LOG_ACTIONS,
          caption: "Action",
          filterKey: "action",
          forceIsVisible: true,
        },
        {
            caption: "Status",
            filterKey: "status",
            type: FILTER_SELECT_SINGLE,
            filterOptions: this.$luxcaddyConfig('logs.status'),
            forceIsVisible: true,
            small: true,
        },
        {
          caption: "Propriété ( > 0)",
          filterKey: "positiveProperty"
        },
        {
          caption: "Data",
          filterKey: "data"
        },
        {
          caption: "ItemId Propriété",
          filterKey: "itemIdDataProperty"
        },
        {
          filterKey: 'createdAt',
          caption: "Date entre",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true,
        },
        {
          filterKey: 'withArchive',
          caption: "inclure les archives ?",
          type: FILTER_SINGLE_BOOLEAN,
          forceIsVisible: true,
          small: true
        },
      ],
    }
  },
}
</script>
