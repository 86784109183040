<template>
  <div class="container mx-auto mt-4 lg:mt-24">
    <div class="mx-4 mb-4 sm:mb-0">
      <card>
        <div
          class=" flex flex-col lg:flex-row justify-between lg:space-x-12 xl:space-x-32 space-y-12 lg:space-y-0">
          <div class="w-full lg:w-3/4 space-y-3 sm:space-y-6">
            <supplier-select-input
              v-model="supplierId"
              :enable-search="false"
              :limit-option-menu-width="false"
              :occupy-validation-space="false"
              :touch-optimized="true"
              label="Fournisseur"
              only-active-suppliers/>

            <touch-optimized-select
              v-model="department"
              :occupy-validation-space="false"
              :options="possibleDepartments"
              label="Département"
            />

            <text-input-element
              ref="temperatureField"
              v-model="temperature"
              :touch-optimized="true"
              class="lg:w-96"
              inset-text="°C"
              label="Température"
            />


            <touch-optimized-button
              type="secondary"
              @click="createArrivalTemperature"
            >
              Enregistrer
            </touch-optimized-button>

          </div>

          <key-pad class="hidden sm:grid w-full lg:w-1/4" mode="temperature"/>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import KeyPad from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/keypad/KeyPad";
import SupplierSelectInput from "@/components/elements/forms/elements/SupplierSelectInput";
import TouchOptimizedSelect from "@/components/touchOptimized/Form/TouchOptimizedSelect";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import ArrivalTemperatureRepository from "@/repositories/ArrivalTemperatureRepository";

export default {
  name: "ArrivalTemperaturesPage",
  components: {TouchOptimizedButton, TextInputElement, TouchOptimizedSelect, SupplierSelectInput, KeyPad, Card},
  data: () => ({
    supplierId: null,
    department: null,
    temperature: null,

    possibleDepartments: [
      {label: 'Poisson', value: 'fish'},
      {label: 'Viande', value: 'meat'},
      {label: 'Crèmerie', value: 'dairy'},
      {label: 'Surgelé', value: 'frozen'},
    ]
  }),
  mounted() {
    this.focusTemperatureField();
  },
  methods: {
    createArrivalTemperature() {
      if (this.temperature === null || this.department === null || this.supplierId === null) {
        return this.$showErrorDialog("Veuillez remplir tous les champs !");
      }

      const temperature = parseFloat(this.temperature.replace(',', '.'));

      ArrivalTemperatureRepository
        .createForToday(this.department, this.supplierId, temperature)
        .then(() => {
          this.$sendSuccessToast("Température enregistrée.");
          this.supplierId = null;
          this.department = null;
          this.temperature = null;

          this.focusTemperatureField();
        });
    },
    focusTemperatureField() {
      this.$refs.temperatureField.focus();
    }
  },
}
</script>

