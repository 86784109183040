import CampaignRepository from "@/repositories/CampaignRepository";

export default {
  selectValues: campaign => ({id: campaign.id}),
  selectValuesFromId: campaignId => ({id: campaignId}),

  batchActions: [
    {
      label: "Terminer selection",
      action: (callBack, selectedRows) => {
        return CampaignRepository
          .batchActions
          .endCampaigns(selectedRows)
          .finally(() => callBack());
      }
    }
  ]
};