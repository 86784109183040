<template>
  <div>
    <display-tag-images ref="imageDisplay" :tag-id="tagId" class="mb-6"/>
    <upload-tag-images :tag-id="tagId" @uploaded="onImagesUploaded"/>
  </div>
</template>

<script>
import UploadTagImages from "./Upload/UploadTagImages";
import DisplayTagImages from "./Existing/DisplayTagImages";

export default {
  name: "ManageTagImages",
  components: {DisplayTagImages, UploadTagImages},
  props: {
    tagId: {
      type: Number,
      required: true
    }
  },
  methods: {
    // Executed whenever new images have been uploaded
    onImagesUploaded() {
      this.$refs.imageDisplay.fetchImages();
    }
  },
}
</script>

