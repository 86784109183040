import BannerRepository from "@/repositories/BannerRepository";
import Vue from "vue";
import dayjs from "dayjs";

const getDefaultState = () => {
  return {
    banner: {
      id: null,
      name: null,
      type: 'image',
      bannerableType: null,
      bannerableId: null,

      // Internal Link
      linkPageName: null,
      linkPageParams: {id: null},

      // External Link
      linkUrl: null,

      title: null,
      description: null,

      pageLinks: []
    },
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getBanner: state => state.banner,
    getBannerId: state => state.banner.id,
    getBannerPageLinks: state => state.banner.pageLinks,

    // Returns the banner as the create / update endpoints expects it
    // This is mapped when sending in the request payload.
    getBannerPayload: state => {
      let banner = state.banner;

      // Set linkPageParams to null if the id in the object is null
      if (banner.linkPageParams && banner.linkPageParams.id === null) {
        banner.linkPageParams = null;
      }

      // Set all pageLinks-params to null if the id in the object is null.
      banner.pageLinks = banner.pageLinks.flatMap(pl => {
        if (pl.pageParams && pl.pageParams.id === null) {
          pl.pageParams = null;
        }

        return pl;
      })

      return state.banner;
    }
  },
  mutations: {
    /**
     * Takes the banner response from the endpoint and transforms it to a custom format
     * used by the various components.
     *
     * @param state
     * @param banner
     */
    setBannerFromResponse(state, banner) {
      state.banner.id = banner.id;
      state.banner.name = banner.name;
      state.banner.type = banner.type;

      if (state.banner.type === "image") {
        state.banner.bannerableType = banner.bannerableType;
        state.banner.bannerableId = banner.bannerableId;
      }

      state.banner.linkUrl = banner.link?.url ? banner.link.url : null;

      state.banner.linkPageName = banner.link?.name ? banner.link.name : null;

      if (banner.link?.params?.id) {
        state.banner.linkPageParams = {};
        Vue.set(state.banner.linkPageParams, 'id', banner.link.params.id);
      } else {
        state.banner.linkPageParams = null;
      }

      // Map pageLinks in format needed for update.
      state.banner.pageLinks = banner.pageLinks
        ? banner.pageLinks.map(pl => {
          let obj = {};
          obj.pageName = pl.pageIdentifier.name;

          obj.pageParams = typeof pl.pageIdentifier.params === "object" && pl.pageIdentifier.params !== null
            ? pl.pageIdentifier.params
            : {id: null};

          obj.placement = pl.placement;
          obj.weight = pl.weight;
          obj.startDate = pl.period?.start ? dayjs(pl.period.start).format('DD.MM.YYYY HH:mm:ss') : null;
          obj.endDate = pl.period?.end ? dayjs(pl.period.end).format('DD.MM.YYYY HH:mm:ss') : null;

          return obj;
        })
        : [];
    },

    addPageLink(state) {
      state.banner.pageLinks.push({
        pageName: null,
        pageParams: {id: null},
        placement: null,
        weight: null,
        startDate: null,
        endDate: null
      });
    },

    clearLinkPageParams(state) {
      Vue.set(state.banner, 'linkPageParams', {id: null});
    },

    /**
     * Clears all configs related to the link
     * @param state
     */
    resetLinkRelatedProperties(state) {
      state.banner.linkPageName = null;
      Vue.set(state.banner, 'linkPageParams', {id: null});
      state.banner.linkUrl = null;
    },

    resetBanner(state) {
      state.banner = getDefaultState().banner;
    },
    setBannerType(state, type) {
      state.banner.type = type;
    },

    removePageLinkByIndex(state, index) {
      state.banner.pageLinks = state.banner.pageLinks.filter((pl, i) => i !== index);
    }
  },
  actions: {
    fetchBanner({commit}, bannerId) {
      return BannerRepository.getSingle(bannerId).then((res) => {
        commit('setBannerFromResponse', res.data.data);
      });
    },
    createBanner({getters}) {
      return BannerRepository.create(getters.getBannerPayload);
    },
    updateBanner({dispatch, getters}) {
      return BannerRepository.update(getters.getBannerId, getters.getBannerPayload).then(() => {
        dispatch('fetchBanner', getters.getBannerId);
      });
    }
  }
}