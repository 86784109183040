<template>
  <modal ref="modal" :blocking="true" :title="title" :width="20" @close="$emit('close')" @open="$emit('open')">
    <slot>
      Vous avez des modifications non sauvés.<br>
      Si vous continuez, toutes vos modifications non sauvegardées seront abandonnées.
    </slot>

    <template #footer>
      <div class="flex flex-wrap gap-4 justify-between">
        <button-element class="float-right" type="danger" @click="onConfirm">
          <fa-icon class="mr-2" icon="exclamation-triangle"></fa-icon>
          Continuer
        </button-element>
        <button-element class="float-right" type="light" @click="onAbort">
          <i class="fa fa-times mr-2"></i>
          Annuler
        </button-element>
      </div>
    </template>
  </modal>
</template>

<script>
import ButtonElement from "../buttons/ButtonElement";
import Modal from "./Modal";

export default {
  name: "UnsavedChangesModal",
  components: {Modal, ButtonElement},
  props: {
    title: {
      type: String,
      default: 'Abandonner modifications?'
    },
    closeOnAbort: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    onConfirm() {
      this.$emit('confirmed');
      this.closeModal();
    },
    onAbort() {
      if (this.closeOnAbort) {
        this.closeModal();
      }

      this.$emit('abort');
    },
    openModal() {
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
  }
}
</script>

