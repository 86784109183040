<template>
  <create-or-update-short-url-form mode="create"></create-or-update-short-url-form>
</template>

<script>
import CreateOrUpdateShortUrlForm from "../Forms/CreateOrUpdateShortUrlForm";

export default {
  name: "CreateShortUrl",
  components: {CreateOrUpdateShortUrlForm},
}
</script>

