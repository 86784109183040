<template>
  <div>
    <div class="flex justify-between">
      <page-title>
        Lignes
      </page-title>

      <invoice-item-add-button
        v-if="(isInvoiceInternal ? isDraft : true) && !$isReadOnly()"
        :member-id="getMemberId"
      />
    </div>

    <custom-table
      v-if="getItems.length"
      :headings="getTableHeadings"
    >

      <template
        v-for="item in getItems"
      >
        <invoice-item-table-row
          :key="item.id"
          :is-accounting-interface="isAccountingInterface"
          :item="item"
        />
        <invoice-item-table-row
          v-for="subItem in item.referencingInvoiceItems"
          :key="subItem.id"
          :is-accounting-interface="isAccountingInterface"
          :is-sub-item="true"
          :item="subItem"
          class="border-l-4"
        />
      </template>

      <invoice-item-table-total with-border without-vat/>
      <invoice-item-table-total with-vat/>
      <invoice-item-table-total/>

    </custom-table>
    <card
      v-else
      class="text-center"
    >
      <p class="text-red-500 font-semibold">
        Cette facture n'a pas encore des lignes.
      </p>
    </card>
  </div>
</template>

<script>
import CustomTable from "@/components/elements/tables/CustomTable";
import {mapGetters} from "vuex";
import InvoiceItemTableRow from "@/components/pages/Invoices/Shared/EditInvoice/Includes/Table/InvoiceItemTableRow";
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import InvoiceItemTableTotal from "@/components/pages/Invoices/Shared/EditInvoice/Includes/Table/InvoiceItemTableTotal";
import InvoiceItemAddButton
  from "@/components/pages/Invoices/Shared/EditInvoice/Includes/AddItem/InvoiceItemAddButton.vue";

export default {
  name: "InvoiceItemTable",
  components: {InvoiceItemAddButton, InvoiceItemTableTotal, Card, PageTitle, InvoiceItemTableRow, CustomTable},
  props: {
    isAccountingInterface: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('invoices', [
      'getItems', 'isDraft', 'isInvoiceInternal', 'getMemberId'
    ]),
    getTableHeadings() {
      let headings = [];

      if (this.isInvoiceInternal) {
        headings.push({label: 'Réf.'});
        headings.push({label: 'Labellé'});
      }

      if (this.isAccountingInterface) {
        headings.push({label: 'Compte'});
        headings.push({label: 'Code de la TVA'});
      }

      headings.push({label: 'Qté.'});
      headings.push({label: 'Prix'});
      headings.push({label: 'Sous-Total'});
      headings.push({label: 'Montant-Tva'});
      headings.push({label: 'TVA'});

      if (!this.$isReadOnly()) {
        headings.push({
          label: 'Actions',
          align: 'right'
        });
      }

      return headings;
    }
  },
}
</script>

