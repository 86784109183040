<template>
  <transition name="fade">
    <div
      v-if="isPlanningRouteJobInProgress"
      class="absolute z-50 inset-0 bg-black bg-opacity-80 text-white h-full w-full flex flex-col items-center justify-center"
    >

      <div class="text-6xl mb-4 text-red-500">
        Bloqué temporairement
      </div>

      <div class="text-xl">
        Le système est en train de calculer des itinéraires automatiques.
      </div>

      <div>
        Veuillez patienter et revenir dans 10-15 minutes.
      </div>

      <button-element
        class="mt-12"
        type="danger"
        @click="cancelPlanningRequest"
      >
        Annuler le calcul de l'itinéraire
      </button-element>

      <div class="mt-20">
        Ce blocage disparaît automatiquement dès que le calcul des itinéraires est terminé.

        <!-- No need to have a timer since its already loading the routes endpoint in a loop in a different endpoint -->
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "RouteManagerRoutePlanningInProgressOverlay",
  components: {ButtonElement},
  methods: {
    ...mapActions('routeManager/automaticPlanning', [
      'cancelRoutePlanningRequest',
    ]),
    cancelPlanningRequest() {
      this.cancelRoutePlanningRequest().then(() => {
        this.$sendSuccessToast('La planification automatique d\'itinéraires a été annulée!');
      });
    },
  },
  computed: {
    ...mapGetters('routeManager/automaticPlanning', [
      'isPlanningRouteJobInProgress'
    ])
  },
}
</script>

<style scoped>

</style>