<template>
  <div>
    <modal v-if="displayedImageUrl && displayedImageTitle" ref="displayImageModal" :show-close-button="true"
           :title="displayedImageTitle"
           @close="resetImageDisplay">
      <img :alt="displayedImageTitle" :src="displayedImageUrl" class="mx-auto">
    </modal>

    <delete-confirmation-modal v-if="!isInReadOnlyMode" ref="deleteImageConfirmationModal"
                               :title="getImageDeleteTitle" @delete="deleteSelectedImages">
      Êtes-vous sure que vous voulez supprimer
      {{ imageIdsSelectedForRemoval.length > 1 ? 'ces images' : 'cette image' }}?
    </delete-confirmation-modal>

    <div class="flex flex-wrap gap-4">
      <div v-for="label in getTransformedImageGroups" :key="label.labelValue"
           class="bg-gray-100 w-full md:w-auto p-4 rounded-md">
        <div class="flex gap-x-6 justify-between">
          <div class="font-semibold sm:text-lg text-gray-500">
            <fa-icon class="mr-2" fixed-width icon="image"></fa-icon>
            {{ label.labelText }}
          </div>

          <div
            v-if="!isInReadOnlyMode"
            class="font-semibold sm:text-lg text-gray-500 hover:text-white hover:bg-red-500 cursor-pointer rounded-md px-1"
            @click="showImageDeleteConfirmationDialog(label.images.flatMap(i => i.id))">
            <fa-icon fixed-width icon="trash"/>
          </div>
        </div>
        <div class="mt-3 flex flex-row flex-wrap gap-4">
          <div
            v-for="image in label.images"
            :key="image.id"
            class="w-full md:w-auto bg-white shadow-lg p-6 border border-gray-200 rounded-md focus:outline-none flex flex-col">

            <div class="text-center w-full mb-6">
              <div
                class="w-full font-medium text-gray-700 tracking-wider bg-gray-100 px-3 py-1 rounded-md">
                {{ getLanguageByInitials(image.locale) }}
              </div>
            </div>

            <div class="w-56 h-56 relative mx-auto">
              <img :alt="image.locale" :src="image.url"
                   class="absolute w-full h-full object-contain object-center rounded-md"/>

              <div
                class="absolute w-full h-full sticky opacity-0 hover:opacity-80 bg-black transition duration-200 rounded-md z-30  flex justify-evenly items-center">

                <div
                  class="px-3 py-2 flex-0 border border-white text-white cursor-pointer hover:bg-white rounded-md hover:text-gray-800"
                  @click="showFullSizedImages(label.labelText + ' - ' + getLanguageByInitials(image.locale), image.url)">
                  <fa-icon fixed-width icon="eye"></fa-icon>
                </div>

                <div
                  v-if="!isInReadOnlyMode"
                  class="px-3 py-2 flex-0 border border-white text-white cursor-pointer hover:bg-white rounded-md hover:text-gray-800"
                  @click="showImageDeleteConfirmationDialog([image.id])">
                  <fa-icon fixed-width icon="trash"></fa-icon>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../../elements/modals/Modal";
import DeleteConfirmationModal from "../../../elements/modals/DeleteConfirmationModal";
import MediaRepository from "../../../../repositories/MediaRepository";

export default {
  name: "MediaImageDisplayAndManagement",
  components: {DeleteConfirmationModal, Modal},
  props: {
    mediaAbleType: {
      type: String,
      required: true
    },
    mediaAbleId: {
      type: Number,
      required: true
    },
    possibleLabels: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    images: [],
    displayedImageTitle: "",
    displayedImageUrl: null,

    imageIdsSelectedForRemoval: [],
  }),
  created() {
    this.fetchImages();
  },
  computed: {
    getTransformedImageGroups() {
      if (this.images === null || this.images === undefined)
        return [];

      let arr = [];
      for (const [label, images] of Object.entries(this.images)) {
        arr.push({
          labelValue: label,
          labelText: this.getLabelTextByValue(label),
          images: images
        })
      }

      return arr;
    },
    isInReadOnlyMode() {
      return this.$isReadOnly();
    },
    getImageDeleteTitle() {
      if (this.imageIdsSelectedForRemoval.length > 1) {
        return "Supprimer images";
      }
      return "Supprimer image";
    }
  },
  methods: {
    fetchImages() {
      return MediaRepository.getMedia(this.mediaAbleType, this.mediaAbleId).then(res => {
        this.images = res.data.data.image;
      });
    },
    deleteSelectedImages() {
      let imageIds = this.imageIdsSelectedForRemoval;
      const imageCount = imageIds.length;

      MediaRepository.deleteMedia(this.mediaAbleType, this.mediaAbleId, imageIds).then(() => {
        this.$refs.deleteImageConfirmationModal.closeModal();
        this.$sendSuccessToast(
          imageCount > 1
            ? "Les images ont été supprimées."
            : "L'image a été supprimée."
        );
        this.fetchImages();
        this.imageIdsSelectedForRemoval = [];
      });
    },
    showFullSizedImages(title, url) {
      this.displayedImageTitle = title;
      this.displayedImageUrl = url;
      this.$nextTick(() => this.$refs.displayImageModal.openModal());
    },
    showImageDeleteConfirmationDialog(imagesIds = []) {
      if (!imagesIds.length)
        return false;

      this.imageIdsSelectedForRemoval = imagesIds;
      this.$nextTick(() => this.$refs.deleteImageConfirmationModal.openModal());
    },
    resetImageDisplay() {
      this.displayedImageTitle = "";
      this.displayedImageUrl = null;
    },
    getLabelTextByValue(value) {
      return this.possibleLabels.find(l => l.value === value)?.label || "";
    },
    getLanguageByInitials(initials) {
      if (initials === "default")
        return "Défaut";

      let languages = this.$luxcaddyConfig('languages');
      return languages.find(l => l.value === initials).label;
    }
  },
}
</script>

