import ProductRepository from "@/repositories/ProductRepository";
import {dayjs} from "@/plugins/dayJs";
import ExternalInvoicePDFRepository from "@/repositories/ExternalInvoicePDFRepository";


const productEndpoints = [
  {type: 'list', func: ProductRepository.comments.getPaginated},
  {type: 'create', func: ProductRepository.comments.create},
  {type: 'update', func: ProductRepository.comments.update},
  {type: 'delete', func: ProductRepository.comments.delete},
];

const externalInvoicePdfEndpoints = [
  {type: 'list', func: ExternalInvoicePDFRepository.comments.getPaginated},
  {type: 'create', func: ExternalInvoicePDFRepository.comments.create},
  {type: 'update', func: ExternalInvoicePDFRepository.comments.update},
  {type: 'delete', func: ExternalInvoicePDFRepository.comments.delete},
];

const getEndpointFunction = function (modelType, endpointType) {
  switch (modelType.toLowerCase()) {
    case "product":
      return productEndpoints.find(e => e.type === endpointType);
    case "externalinvoicepdf":
      return externalInvoicePdfEndpoints.find(e => e.type === endpointType);
  }
}

const getDefaultState = () => {
  return {
    modelData: {
      id: null,
      type: null,
    },

    comments: [],
    commentIdBeingEdited: null,
    commentIdBeingDeleted: null,

    addCommentMode: false,

    // Determine if something has changed (comment added, updated or deleted)
    // Used to trigger a reload when comment modal closed to update comment count.
    change: false,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getModelId: state => state.modelData.id,
    getModelType: state => state.modelData.type,

    getComments: state => state.comments.filter(c => {
      return c.expiresAt === null
        ? true
        : !dayjs(c.expiresAt, ['DD.MM.YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss']).isSameOrBefore(dayjs());
    }),
    getCommentsWithExpired: state => state.comments,

    getSpecificComment: (state) => (id) => {
      return state.comments.find(c => c.id === id);
    },

    getCommentBeingEdited: state => state.comments.find(c => c.id === state.commentIdBeingEdited),
    getCommentBeingDeleted: state => state.comments.find(c => c.id === state.commentIdBeingDeleted),

    isEditingComment: state => (id) => state.commentIdBeingEdited !== null && state.commentIdBeingEdited === id,
    isDeletingComment: state => (id) => state.commentIdBeingDeleted !== null && state.commentIdBeingDeleted === id,

    isInAddCommentMode: state => state.addCommentMode,

    hasSomethingChanged: state => state.change,
  },
  mutations: {
    setModelData(state, {type, id}) {
      state.modelData = {type, id};
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    setCommentIdBeingEdited(state, id) {
      state.commentIdBeingEdited = id;
    },
    setCommentIdBeingDeleted(state, id) {
      state.commentIdBeingDeleted = id;
    },
    setAddCommentMode(state, bool) {
      state.addCommentMode = bool;
    },
    setChange(state, bool) {
      state.change = bool;
    }
  },
  actions: {
    fetchComments({commit, getters}) {
      return getEndpointFunction(getters.getModelType, 'list')
        .func(getters.getModelId, 999)
        .then((res) => {
          commit('setComments', res.data.data.reverse());
        });
    },

    createComment({dispatch, commit, getters}, payload) {
      return getEndpointFunction(getters.getModelType, 'create')
        .func(getters.getModelId, payload)
        .then(() => {
          dispatch('fetchComments');
          commit('setAddCommentMode', false);
          commit('setChange', true);
        });
    },

    updateComment({dispatch, commit, getters}, payload) {
      return getEndpointFunction(getters.getModelType, 'update')
        .func(getters.getModelId, getters.getCommentBeingEdited.id, payload)
        .then(() => {
          dispatch('fetchComments');
          commit('setCommentIdBeingEdited', null);
          commit('setChange', true);
        });
    },

    deleteComment({dispatch, commit, getters}) {
      return getEndpointFunction(getters.getModelType, 'delete')
        .func(getters.getModelId, getters.getCommentBeingDeleted.id)
        .then(() => {
          dispatch('fetchComments');
          commit('setCommentIdBeingDeleted', null);
          commit('setChange', true);
        });
    },
  },
}