<template>
  <div>
    <page-title>
      {{ title }} #{{ getRecurringTaskId }}
    </page-title>

    <card>
      <create-or-update-recurring-task-form
        :recurring-task-id="getRecurringTaskId"
        mode="update"
        @updateTitle="onUpdateTitle"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import CreateOrUpdateRecurringTaskForm from "@/components/pages/TasksRecurring/Form/CreateOrUpdateRecurringTaskForm";

export default {
  name: "EditRecurringTask",
  components: {CreateOrUpdateRecurringTaskForm, Card, PageTitle},
  computed: {
    getRecurringTaskId() {
      return Number(this.$route.params.task);
    }
  },
  data: () => ({
    title: '[...]'
  }),
  methods: {
    onUpdateTitle(title) {
      this.title = title;
    }
  },
}
</script>

<style scoped>

</style>