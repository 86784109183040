import apiService from '@/repositories/Base/BaseRepository';

const url = '/devices';

export default {
  async get() {
    return await apiService.get(`${url}/`)
  },

  async getPaginated(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0
  ) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getDevice(deviceId) {
    return await apiService.get(`${url}/${deviceId}`);
  },

  async createDevice(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  async updateDevice(deviceId, payload) {
    return await apiService.patch(`${url}/${deviceId}`, payload);
  },

  async deleteSingle(invoiceId) {
    return await apiService.delete(`${url}/${invoiceId}`);
  },

}
