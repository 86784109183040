import apiService from './Base/BaseRepository';

const url = '/tags';

export default {

  /**
   * Fetch all tags
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`)
  },

  /**
   * Fetch all tags
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  /**
   * Fetch all tags that can be set on products.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getProductTags() {
    return this.getPaginated(500, 1, [
        {
          dataField: 'type',
          sort: 'asc'
        }
      ],
      [
        {
          filterKey: "type",
          filterValue: "label,allergen,misc,kirsch,tracesOf",
        }
      ])
  },

  async getSingle(tagId) {
    return await apiService.get(`${url}/${tagId}`)
  },

  async update(tagId, values) {
    return await apiService.patch(`${url}/${tagId}`, values)
  },

  async create(values) {
    return await apiService.post(`${url}/`, values)
  },

  /**
   * Delete tag
   * @param tagId
   * @returns {Promise<AxiosResponse<any>>}
   */
  async deleteSingle(tagId) {
    return await apiService.delete(`${url}/${tagId}`)
  },

  localization: {
    async getForTag(tagId) {
      return await apiService.get(`${url}/${tagId}/localization`);
    },

    async saveForTag(tagId, payload) {
      return await apiService.post(`${url}/${tagId}/localization`, payload);
    },

    async translate(tagId, sourceLanguage, targetLanguages, attributes) {
      return await apiService.post(`${url}/${tagId}/localization/translation`, {
        sourceLanguage, targetLanguages, attributes
      });
    }
  },

}
