<template>
  <shared-edit-invoice
    :invoice-id="Number($route.params.id)"
    is-accounting-interface
  />
</template>

<script>
import SharedEditInvoice from "@/components/pages/Invoices/Shared/EditInvoice/SharedEditInvoice";

export default {
  name: "EditInvoice",
  components: {SharedEditInvoice},
}
</script>