/**
 * Returns the language by the given isoCode.
 *
 * @return {string|null}
 * @param isoCode
 */
export function languageByIsoCode(isoCode) {
  switch (isoCode) {
    case 'de':
      return "Allemand";
    case 'fr':
      return "Français";
    case 'en':
      return "Anglais";
    case 'lb':
      return "Luxembourgish";
    default:
      return isoCode;
  }
}
