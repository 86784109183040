<template>
  <select-element
    :enable-search="false"
    :label="label"
    :options="getFormattedDevices"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  ></select-element>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import DeviceRepository from "@/repositories/DeviceRepository";

export default {
  name: "DeviceSelectInput",
  components: {SelectElement},
  created() {
    DeviceRepository.getPaginated(100, 1).then((res) => {
      this.devices = res.data.data;
    })
  },
  data() {
    return {
      devices: []
    }
  },
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Zone de livraison',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    }
  },
  computed: {
    getFormattedDevices() {
      return this.devices.map(d => ({
        label: d.name + ": " + d.imei + ' - ' + d.phoneNumber,
        value: d.id
      }));
    }
  },
}
</script>

