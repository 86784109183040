<!-- This component is responsible for fetching Notifications every X Seconds -->
<template>
  <div>
    <notification-critical-message
      ref="messageModal"
    />
  </div>
</template>

<script>
import NotificationCriticalMessage
  from "@/components/layouts/touchInterface/partials/Nav/Notifications/NotificationCriticalMessage.vue";

export default {
  name: "NotificationLoadTimer",
  components: {NotificationCriticalMessage},
  data: () => ({
    notificationTimer: null
  }),
  beforeDestroy() {
    this.killTimer();
  },
  created() {
    this.fetchNotifications();
    this.initTimer();
  },
  methods: {
    killTimer() {
      clearInterval(this.notificationTimer);
      this.notificationTimer = null;
    },
    initTimer() {
      let vm = this;
      this.notificationTimer = setInterval(function () {
        vm.fetchNotifications();
      }, (1000 * 60));
    },
    fetchNotifications(page = 1) {
      this.$store.dispatch('notifications/fetchNotifications', page).finally(() => {
        this.checkForUnreadCriticalMessage();
      });
    },
    checkForUnreadCriticalMessage() {
      this.$refs.messageModal.checkForNewMessage();
    }
  }
}
</script>