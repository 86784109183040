<template>
  <div
    :class="[
            isCurrentPage ? 'border-luxcaddy text-luxcaddy bg-luxcaddy-100' : 'border-transparent hover:border-luxcaddy hover:text-luxcaddy hover:bg-luxcaddy-200',
            getWidthClass
        ]"
    class="md:flex justify-center items-center hidden cursor-pointer text-sm select-none leading-5 transition duration-150 ease-in border-t-2"
    @click="currentPage = page"
  >
    {{ page }}
  </div>
</template>

<script>
export default {
  name: "ListTablePaginationPage",
  props: {
    tableIdentifier: {
      type: String,
      required: false
    },
    page: {
      type: Number,
      required: true
    },

    // not required if tableIdentifier set
    currentPageSet: {
      type: Number
    }
  },
  computed: {
    isListTableMode() {
      return this.tableIdentifier !== undefined;
    },

    getPaginationConfig() {
      if (!this.isListTableMode) {
        return null;
      }

      return this.$store.getters['listTable/getPaginationConfigs']
        .find(p => p.tableIdentifier === this.tableIdentifier);
    },

    currentPage: {
      get() {
        if (this.isListTableMode) {
          return this.getPaginationConfig?.currentPage || 1;
        }
        return this.currentPageSet;
      },
      set(page) {
        if (this.isListTableMode) {
          this.$store.commit('listTable/setCurrentPage', {
            tableIdentifier: this.tableIdentifier,
            page: page
          });
          return this.$emit("change", page);
        }
        this.$emit("change", page);
      }
    },
    isCurrentPage() {
      return this.currentPage === this.page;
    },
    getWidthClass() {
      if (this.page > 100000) {
        return 'w-16';
      } else if (this.page > 10000) {
        return 'w-14';
      } else if (this.page > 1000) {
        return 'w-12';
      } else if (this.page > 100) {
        return 'w-10';
      }

      return 'w-8';
    }
  }
}
</script>


