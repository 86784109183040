<template>
  <modal
    ref="modal"
    disable-padding
    mobile-max-width
    title="Commentaires"
    @close="onModalClose"
  >
    <div
      class="md:w-132 h-full md:h-screen-60 overflow-y-auto flex flex-col"
    >

      <!-- Header -->
      <div
        class="border-b-2 py-2 px-4 flex-none h-12"
      >
        <transition
          mode="out-in"
          name="fade-up-down-faster"
        >

          <!-- Header: Is Adding comment -->
          <div
            v-if="isInAddCommentMode"
            key="headerAddingComment"
            class="h-full flex justify-end gap-x-2 items-center"
          >
            <div
              class="text-gray-400 hover:text-gray-500 cursor-pointer flex gap-x-1.5 items-center select-none"
              @click="setAddCommentMode(false)"
            >
              <span class="text-sm">Annuler</span>
              <fa-icon fixed-width icon="times"/>
            </div>
          </div>

          <!-- Header: Regular state -->
          <div
            v-else
            key="headerRegular"
            :class="hasExpiredComments ? 'justify-between' : 'justify-end'"
            class="flex items-center"
          >
            <div
              v-if="hasExpiredComments"
              class="flex justify-end gap-x-2 items-center"
            >
              <toggle-element
                v-model="showExpiredComments"
              />

              <span class="text-gray-400 text-sm select-none">
                Montrer {{ getExpiredCommentCount }} {{ getExpiredCommentCount > 1 ? 'expirées' : 'expirée' }}
              </span>
            </div>

            <div
              :class="{'mt-1.5': !hasExpiredComments}"
              class=" text-sm text-luxcaddy hover:text-luxcaddy-600 cursor-pointer select-none"
              @click="showAddCommentComponent"
            >
              <fa-icon class="mr-1.5" fixed-width icon="plus"/>
              Ajouter
            </div>
          </div>
        </transition>
      </div>


      <div class="h-full p-4">
        <template
          v-if="getComputedComments.length > 0 || isInAddCommentMode"
        >
          <transition-group
            class="flex flex-col gap-y-6"
            name="fade-up-down"
          >
            <comment-box-add
              v-if="isInAddCommentMode"
              key="addComment"
            />

            <comment-box
              v-for="comment in getComputedComments"
              :key="comment.id"
              :comment="comment"
            />

          </transition-group>
        </template>
        <template
          v-else
        >
          <div class="flex flex-col items-center justify-center h-full select-none">
            <fa-icon
              class="text-6xl text-gray-400"
              icon="comments"
            />
            <span class="mt-4 text-gray-400 text-xl">
               Il n'y a pas encore de commentaires.
            </span>
          </div>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal.vue";
import {mapGetters, mapMutations} from "vuex";
import CommentBox from "@/components/global/Comments/Modal/Sub/CommentBox.vue";
import ToggleElement from "@/components/elements/toggles/ToggleElement.vue";
import CommentBoxAdd from "@/components/global/Comments/Modal/Sub/CommentBoxAdd.vue";

export default {
  name: "CommentModal",
  components: {CommentBoxAdd, ToggleElement, CommentBox, Modal},
  methods: {
    ...mapMutations('comments', [
      'setCommentIdBeingEdited',
      'setCommentIdBeingDeleted',
      'setAddCommentMode',
      'setChange',
      'setComments',
    ]),
    openModal() {
      this.$refs.modal.openModal();
    },
    onModalClose() {
      this.setCommentIdBeingEdited(null);
      this.setCommentIdBeingDeleted(null);
      this.setAddCommentMode(false);
      this.setComments([]);

      // When comment Added, Updated or Deleted, emit event
      // to allow for reload on parent.
      if (this.hasSomethingChanged) {
        this.$emit('change');

        this.$nextTick(() => {
          this.setChange(false);
        });
      }
    },
    showAddCommentComponent() {
      this.setCommentIdBeingEdited(null);
      this.setCommentIdBeingDeleted(null);
      this.setAddCommentMode(true);
    }
  },
  data() {
    return {
      showExpiredComments: false
    }
  },
  computed: {
    ...mapGetters('comments', [
      'getComments',
      'getCommentsWithExpired',
      'isInAddCommentMode',
      'hasSomethingChanged',
    ]),
    getComputedComments() {
      return this.showExpiredComments
        ? this.getCommentsWithExpired
        : this.getComments;
    },
    hasExpiredComments() {
      return this.getComments.length !== this.getCommentsWithExpired.length;
    },
    getExpiredCommentCount() {
      return this.getCommentsWithExpired.length - this.getComments.length;
    }
  },
}
</script>