// @ts-nocheck

import './assets/css/tailwind.css';
import './assets/css/index.css';

import Vue from 'vue'
import App from './App.vue'

import {dom, library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {faBell, faCircle, faSquare, faStar} from "@fortawesome/free-regular-svg-icons";
import {
    faArrowLeft,
    faArrowRight,
    faBarcode,
    faBars,
    faBolt,
    faBook,
    faUnlock,
    faBox,
    faBoxes,
    faBullhorn,
    faCalculator,
    faCalendar,
    faCalendarDay,
    faCaretRight,
    faCartPlus,
    faChartBar,
    faChartLine,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCircleNotch,
    faClock,
    faClone,
    faCog,
    faCogs,
    faColumns,
    faComments,
    faCompress,
    faCompressAlt,
    faCopy,
    faCube,
    faCubes,
    faDatabase,
    faDesktop,
    faDolly,
    faDownload,
    faEdit,
    faEgg,
    faEllipsisH,
    faEnvelope,
    faEquals,
    faEuroSign,
    faExchangeAlt,
    faExclamationTriangle,
    faExpand,
    faExpandAlt,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFileAlt,
    faFileInvoice,
    faFilePdf,
    faFileUpload,
    faFilter,
    faFolderOpen,
    faGift,
    faGifts,
    faGlassMartini,
    faGlobe,
    faGreaterThan,
    faGripVertical,
    faHashtag,
    faHistory,
    faHome,
    faImage,
    faImages,
    faInfinity,
    faInfo,
    faInfoCircle,
    faLeaf,
    faLessThan,
    faLevelUpAlt,
    faLightbulb,
    faLink,
    faList,
    faListUl,
    faLock,
    faLongArrowAltRight,
    faMapMarkedAlt,
    faMapPin,
    faMinus,
    faNewspaper,
    faNotEqual,
    faPallet,
    faPaperPlane,
    faPeopleCarry,
    faPercent,
    faPhone,
    faPlay,
    faPlus,
    faQuestionCircle,
    faQuoteRight,
    faRandom,
    faReceipt,
    faSatelliteDish,
    faSearch,
    faSearchPlus,
    faServer,
    faShoppingBasket,
    faShoppingCart,
    faSignature,
    faSignInAlt,
    faSignOutAlt,
    faSitemap,
    faSlidersH,
    faSort,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortDown,
    faSpinner,
    faStepBackward,
    faStepForward,
    faStoreAlt,
    faStream,
    faSync,
    faTag,
    faTags,
    faTasks,
    faTemperatureLow,
    faThLarge,
    faThumbtack,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faTruck,
    faUnlink,
    faUser,
    faUserCircle,
    faUserClock,
    faUserFriends,
    faUsers,
    faUserSecret,
    faUserShield,
    faUserTie,
    faUtensils,
    faWallet,
    faWeight,
    faWrench,
    faSnowflake
} from '@fortawesome/free-solid-svg-icons';
// Custom filters
import './filters/filters';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// Toast Notification Wrapper
import ToastPlugin from '@/plugins/toast/toastPlugin';
// Confirmation dialog
import VueConfirmDialog from "@/plugins/confirmDialog";
// Luxcaddy Config
import luxcaddyConfig from './plugins/luxcaddyConfigPlugin';
// Google Maps
import VueGoogleMaps from 'gmap-vue';
// Dayjs
import './plugins/dayJs';
// Form Validation
import './validation/validation.js';
// Click outside
// !!!
// be aware v-click-outside only works with methods, not with inline stuff like
// v-click-outside="showMenu = false
// !!!
import vClickOutside from 'v-click-outside'
// Signature Pad
import VueSignaturePad from 'vue-signature-pad';

library.add(faUnlock, faPaperPlane, faPlay, faSignature, faReceipt, faFileUpload, faHashtag, faBolt, faStepForward, faStepBackward, faEgg, faRandom, faLightbulb, faWeight, faUserSecret, faUnlink, faSpinner, faBell, faPallet, faUserClock, faDolly, faGripVertical, faUserTie, faNewspaper, faLink, faExternalLinkAlt, faCopy, faStream, faList, faChartBar, faExpandAlt, faSatelliteDish, faFileAlt, faDesktop, faMapPin, faCompressAlt, faComments, faExpand, faDownload, faSortDown, faCompress, faGift, faGifts, faCalendarDay, faHome, faThLarge, faTemperatureLow, faTasks, faBullhorn, faLongArrowAltRight, faInfinity, faSitemap, faEyeSlash, faBook, faSortAlphaUp, faSortAlphaDown, faUserCircle, faTag, faMapMarkedAlt, faPeopleCarry, faUserFriends, faTags, faGlobe, faCube, faSlidersH, faBarcode, faImage, faImages, faBox, faStoreAlt, faQuoteRight, faLessThan, faGreaterThan, faEquals, faNotEqual, faStar, faCartPlus, faShoppingBasket, faServer, faWrench, faUtensils, faGlassMartini, faLeaf, faEnvelope, faPhone, faSquare, faCircle, faClone, faCheck, faTruck, faBars, faUser, faUsers, faArrowLeft, faSearch, faQuestionCircle, faLevelUpAlt, faFileInvoice, faSearchPlus, faCheckCircle, faExchangeAlt, faCheckSquare, faCaretRight, faEuroSign, faDatabase, faChevronRight, faChevronDown, faExclamationTriangle, faCircleNotch, faShoppingCart, faCubes, faClock, faChevronLeft, faBoxes, faInfoCircle, faLock, faPercent, faTimes, faHistory, faTimesCircle, faChartLine, faPlus, faMinus, faEdit, faUserShield, faTrash, faTrashAlt, faEye, faColumns, faInfo, faSync, faFilter, faSort, faCogs, faCog, faArrowRight, faFolderOpen, faListUl, faCalculator, faSignOutAlt, faSignInAlt, faWallet, faFilePdf, faEllipsisH, faThumbtack, faCalendar,faSnowflake)
dom.watch();

Vue.component('fa-icon', FontAwesomeIcon);

Vue.use(Toast);

Vue.use(ToastPlugin);

Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.use(luxcaddyConfig);

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAP_API_KEY,
        libraries: 'places',
        region: 'LU',
        language: 'fr'
    },
})

// Store#
const store = require('./store/store.js').default;

// Router
const router = require('./router/router.js').default;

// ShortKey
const ShortKey = require('vue-shortkey')
Vue.use(ShortKey);

Vue.use(vClickOutside)

Vue.use(VueSignaturePad);


// ProductionTip
Vue.config.productionTip = false

const vue = new Vue({
    render: h => h(App),
    store,
    router
}).$mount('#app')

export {vue};
