<template>
  <div>
    <page-title>
      Créer pointage
    </page-title>

    <card>
      <create-or-update-time-clock-entry-form
        mode="create"
      />
    </card>
  </div>
</template>

<script>
import CreateOrUpdateTimeClockEntryForm from "@/components/pages/TimeClockEntry/Form/CreateOrUpdateTimeClockEntryForm";
import Card from "@/components/elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle";

export default {
  name: "CreateTimeClockEntry",
  components: {PageTitle, Card, CreateOrUpdateTimeClockEntryForm}
}
</script>