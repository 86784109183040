import Login from "../../components/pages/Auth/Login";
import guest from "../middlewares/guestMiddleware";
import guestTouchInterface from "../middlewares/guestTouchInterfaceMiddleware";
import BarcodeLoginPage from "@/components/pages/Auth/BarcodeLoginPage";

export default [
  {
    path: '/login',
    name: 'auth.login',
    component: Login,
    meta: {
      title: 'Connexion',
      layout: 'Auth',
      middleware: [guest],
    }
  },
  {
    path: '/login/barcode',
    name: 'auth.login.barcode',
    component: BarcodeLoginPage,
    meta: {
      title: 'Connexion',
      layout: 'Auth',
      middleware: [guestTouchInterface],
    }
  },
];