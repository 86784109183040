<template>
  <div>
    <card title="Données du membre">
      <create-or-update-member-form :member="member" mode="create"></create-or-update-member-form>
    </card>
  </div>
</template>

<script>
import Card from "../../../elements/cards/Card";
import CreateOrUpdateMemberForm from "../CreateOrUpdateMemberForm";

export default {
  name: "CreateMember",
  components: {
    CreateOrUpdateMemberForm,
    Card,
  },
  data: () => ({
    member: {
      title: null,
      firstName: '',
      familyName: '',
      companyName: '',
      phonePrefix: 352,
      phoneNumber: '',
      street: '',
      city: '',
      postalCode: '',
      country: 'lu',
      language: 'lb',
    },
  }),
}
</script>

