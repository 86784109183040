<template>
  <div>
    <page-title>Étagères</page-title>

    <card>
      <div class="flex justify-between mb-8">
        <div class="flex gap-x-12">
          <toggle-element v-model="enableDrag" label="Activer Glisser-Déposer"></toggle-element>
          <button-element type="primary" @click="openAddRackModal">
            <fa-icon fixed-width icon="plus"></fa-icon>
            Créer étagère
          </button-element>
        </div>
        <info-alert size="small">
          L'ordre commence en haut à gauche, va vers la droite, puis se poursuit en deuxième ligne à gauche à
          nouveau.
        </info-alert>

      </div>

      <rack-actions ref="rackActions"></rack-actions>
      <create-rack-modal ref="addRackModal"></create-rack-modal>

      <div class="h-0.5 mb-6 bg-gray-200"/>

      <draggable v-model="getRacks" ghost-class="opacity-40" handle=".handle">
        <transition-group class="grid gap-4 grid-cols-2 md:grid-cols-8 xl:grid-cols-12" name="slide"
                          type="transition">
          <tooltip v-for="rack in getRacks" :key="rack.id" :class="enableDrag ? 'handle' : 'cursor-pointer'"
                   :enabled="!enableDrag" text="Cliquez pour ouvrir les options" tooltip-classes="mt-4">
            <stock-rack
              :rack-number="rack.number"
              class="hover:bg-gray-400"
              @click="!enableDrag ? openRackActions(rack) : null"
            />
          </tooltip>
        </transition-group>
      </draggable>
    </card>
  </div>
</template>

<script>
import PageTitle from "../../elements/pages/PageTitle";
import Card from "../../elements/cards/Card";
import InfoAlert from "../../elements/alerts/InfoAlert";
import ToggleElement from "../../elements/toggles/ToggleElement";
import draggable from "vuedraggable";
import Tooltip from "../../elements/tooltips/Tooltip";
import RackActions from "./RackActions/RackActions";
import ButtonElement from "../../elements/buttons/ButtonElement";
import CreateRackModal from "./RackActions/CreateRackModal";
import StockRack from "@/components/global/StockRack/StockRack";

export default {
  name: "ShowRacks",
  components: {
    StockRack,
    CreateRackModal,
    ButtonElement, RackActions, Tooltip, ToggleElement, InfoAlert, Card, PageTitle, draggable
  },
  data: () => ({
    enableDrag: false
  }),
  created() {
    this.$store.dispatch('racks/fetchRacks');
  },
  methods: {
    openRackActions(rack) {
      this.$store.commit('racks/setActiveRack', rack);
      this.$refs.rackActions.openEditModal();
    },
    openAddRackModal() {
      this.$refs.addRackModal.openModal();
    }
  },
  computed: {
    getRacks: {
      get() {
        return this.$store.getters['racks/getRacks'];
      },
      set(value) {
        this.$store.dispatch('racks/setRackOrder', value);
      }
    }
  }
}
</script>

