<template>
  <status-indicator
    :small="small"
    :type="getStatusType"
  >
    {{ getTreatedText }}
  </status-indicator>
</template>

<script>
import {mapGetters} from "vuex";
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "BankMovementTreatedStatus",
  components: {StatusIndicator},
  props: {
    small: {
      type: Boolean,
      default: false
    },
    status: {
      type: String
    }
  },
  computed: {
    ...mapGetters('bankMovements', [
      'isBankMovementTreated',
      'bankMovementTreatedStatus'
    ]),
    getTreatedText() {
      if (this.status === "treated") {
        return 'Traité';
      } else if (this.status === "partiallyTreated") {
        return "Partiellement traité";
      }

      return 'Pas traité'
    },

    getStatusType() {

      if (this.status === "treated") {
        return 'primary';
      } else if (this.status === "partiallyTreated") {
        return "warning";
      }

      return 'danger'
    }
  },
}
</script>

<style scoped>

</style>