<template>
  <div class="flex items-middle gap-x-2 select-none">
    <checkbox-element
      v-model="attributeSelection"
      :disabled="!isValueSet"
      :occupy-validation-space="false"
      hide-checked-label
      label-inline
      size="xsmall"
    />

    <div class="flex-1">
      <text-input-element
        v-if="showTextInput"
        :id="getTitleByAttribute + '-' + locale"
        v-model="model"
        :displayCharCounter="displayCharCounter"
        :label="getTitleByAttribute"
      />

      <text-area-element
        v-if="showTextArea"
        :id="getTitleByAttribute + '-' + locale"
        v-model="model"
        :displayCharCounter="displayCharCounter" :label="getTitleByAttribute"
      />
    </div>
  </div>
</template>

<script>
import TextInputElement from "../base/TextInputElement";
import TextAreaElement from "../base/TextAreaElement";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";

export default {
  name: "FormLocalizationInput",
  components: {CheckboxElement, TextAreaElement, TextInputElement},
  props: {
    displayCharCounter: {
      type: Boolean,
      default: true
    },
    attribute: {
      type: String,
      required: true
    },
    value: {
      validator: val => val === null || typeof val === 'string',
      required: true
    },
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (typeof value === "string" && !value.length) {
          return this.$emit('input', null);
        }

        this.$emit('input', value);
      }
    },
    attributeSelection: {
      get() {
        return this.$store.getters["localization/translation/isAttributeForLocaleSelected"](
          this.locale,
          this.attribute
        );
      },
      set() {
        this.$store.commit('localization/translation/toggleAttributeSelection', {
          locale: this.locale,
          attribute: this.attribute
        });
      }
    },
    showTextInput() {
      return ["title", "subtitle", "name"].includes(this.attribute.toLowerCase());
    },
    showTextArea() {
      return ["description", "ingredients"].includes(this.attribute.toLowerCase());
    },
    getTitleByAttribute() {
      switch (this.attribute.toLowerCase()) {
        case "title":
          return "Titre";
        case "name":
          return "Nom";
        case "subtitle":
          return "Sous-Titre";
        case "description":
          return "Description";
        case "ingredients":
          return "Ingrédients";
        default:
          return "";
      }
    },
    isValueSet() {
      return this.value !== null;
    }
  }
}
</script>

