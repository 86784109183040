<template>
  <date-picker-element
    v-model="selectedDate"
    :occupy-validation-space="false"
    @input="fetchInitialData"
  />
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";
import dayjs from "dayjs";

export default {
  name: "RouteManagerActionBoxDateSelection",
  components: {DatePickerElement},
  created() {
    // Check if Date is set from query params
    if (this.$route.query?.date && this.$date(this.$route.query.date).isValid()) {
      this.selectedDate = this.$route.query.date;
    } else {
      // If not, set to todays date.
      this.selectedDate = this.$date().format('YYYY-MM-DD');
    }

    this.fetchInitialData();
  },
  computed: {
    selectedDate: {
      get() {
        let selectedDate = this.$store.state.routeManager.selectedDate;

        let dayjsdate = dayjs(selectedDate, ['YYYY-MM-DD', 'DD.MM.YYYY']);
        if (dayjsdate.isValid()) {
          selectedDate = dayjsdate.format('DD.MM.YYYY');
        }

        return selectedDate
      },
      set(date) {
        let dayjsdate = dayjs(date, ['YYYY-MM-DD', 'DD.MM.YYYY']);
        if (dayjsdate.isValid()) {
          date = dayjsdate.format('YYYY-MM-DD');
        }

        this.$store.commit('routeManager/setSelectedDate', date);
        this.$store.commit('routeManager/showAllDeliveries');
      }
    }
  },
  methods: {
    ...mapActions('routeManager', ['fetchAllRoutes', 'fetchDeliveries']),
    ...mapMutations('routeManager', [
      'clearSelectedDeliveries',
      'clearHiddenTimeSlots',
      'collapseAllRoutes',
      'showAllDeliveries',
    ]),
    fetchInitialData() {
      return Promise.all([
        this.fetchAllRoutes(),
        this.fetchDeliveries(),
      ]);
    },
  },
}
</script>

