<template>
  <select-element
    :allow-unselecting="false"
    :enable-search="false"
    :label="label"
    :options="availableZones"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  ></select-element>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "ZoneSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Zone de livraison',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      availableZones: this.$luxcaddyConfig('zoneList'),
    }
  }
}
</script>

