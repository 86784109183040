<template>
  <div>
    <div v-if="isLoading">
      <loading-svg-inline class="w-12 h-12"/>
    </div>

    <div v-else>
      <custom-table
        v-if="suggestions.length > 0"
        :headings="getHeadings"
      >
        <bank-movement-suggestion-item
          v-for="suggestion in suggestions"
          :key="suggestion.id"
          :bank-movement-id="bankMovementId"
          :suggestion="suggestion"
          @linked="$emit('linked')"
        />
      </custom-table>

      <div
        v-else
        class="p-2 text-red-500 font-bold"
      >
        Il n'y a aucune suggestion pour ce mouvement de banque.
      </div>
    </div>
  </div>
</template>

<script>
import BankMovementSuggestionItem
  from "@/components/pages/Accounting/BankMovements/Edit/Sub/LinkPaymentTransactions/Sub/LinkSuggestions/Sub/BankMovementSuggestionItem";
import CustomTable from "@/components/elements/tables/CustomTable";
import AccountingBankMovementRepository from "@/repositories/AccountingBankMovementRepository";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline.vue";

export default {
  name: "BankMovementSuggestionList",
  components: {LoadingSvgInline, CustomTable, BankMovementSuggestionItem},
  created() {
    this.fetchSuggestions(this.bankMovementId);
  },
  props: {
    bankMovementId: {
      type: Number,
      required: true
    },
    withFilters: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    suggestions: [],
    isLoading: true,
  }),
  watch: {
    bankMovementId: function () {
      this.fetchSuggestions();
    }
  },
  methods: {
    fetchSuggestions() {
      this.isLoading = true;
      AccountingBankMovementRepository
        .getSuggestions(this.bankMovementId, this.withFilters, false)
        .then((res) => {
          this.suggestions = res.data.data;
        }).finally(() => this.isLoading = false);
    }
  },
  computed: {
    getHeadings() {
      return [
        {label: 'ID Membre'},
        {label: 'Nom Membre'},
        {label: 'Nbr.'},
        {label: 'Au total de'},
        {label: 'Factures'},
        {label: ''},
      ];
    }
  }
}
</script>