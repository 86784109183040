import ShowTags from "../../components/pages/Tags/ShowTags";
import auth from "../middlewares/authMiddleware";
import CreateTag from "../../components/pages/Tags/CreateTag/CreateTag";
import EditTag from "../../components/pages/Tags/EditTag/EditTag";

export default [
  {
    path: '/tags',
    name: 'tags.list',
    component: ShowTags,
    meta: {
      middleware: [auth],
      title: 'Listes des tags',
      permissions: ['TAG_READ']
    }
  },
  {
    path: '/tags',
    name: 'tag.create',
    component: CreateTag,
    meta: {
      middleware: [auth],
      title: 'Créer un tag',
      breadcrumbs: ['tags.list'],
      permissions: ['TAG_WRITE']
    }
  },
  {
    path: '/tags/:tag/edit',
    name: 'tag.edit',
    component: EditTag,
    meta: {
      middleware: [auth],
      title: 'Modifier un tag',
      readOnly: {supported: true, title: 'Voir une tag'},
      breadcrumbs: ['tags.list'],
      permissions: ['TAG_WRITE']
    }
  },
]