<template>
  <div>
    <tooltip text="Cliquez pour ouvrir le menu d'action rapide">
      <text-link
        @click="openModal"
      >
        #{{ id }}
      </text-link>
    </tooltip>


    <quick-action-modal-base
      ref="memberModal"
      :links="getQuickActionLinks"
      :title="getMemberName"
      limit-link-width
    >
      <badge :inset-text="getWalletCredit | centsToEuroString">
        <div class="flex gap-x-3 items-center">
          <img alt="" class="object-contain h-6 w-full" src="@/assets/images/wallet.png">
          <span class="font-bold">
              Crédit Wallet
            </span>
        </div>
      </badge>
    </quick-action-modal-base>
  </div>
</template>

<script>
import Tooltip from "@/components/elements/tooltips/Tooltip";
import MemberRepository from "@/repositories/MemberRepository";
import Badge from "@/components/elements/badges/Badge";
import TextLink from "@/components/global/TextLink";
import newTabMixin from "@/mixins/newTabMixin";
import QuickActionModalBase from "@/components/global/QuickActionModal/QuickActionModalBase.vue";

export default {
  name: "MemberQuickActionModal",
  components: {QuickActionModalBase, TextLink, Badge, Tooltip},
  data: () => ({
    member: null,
  }),
  mixins: [newTabMixin],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    getMemberName() {
      return this.member?.fullName || '';
    },
    getWalletCredit() {
      return this.member?.walletAmount || 0;
    },
    getQuickActionLinks() {
      return [
        {label: 'Modifier', route: {name: 'members.edit', params: {member: this.id}}, icon: 'edit'},
        {
          label: 'Historique Wallet',
          route: {
            name: 'members.edit',
            params: {
              member: this.id
            },
            query: {
              tab: 'invoicePayments',
              filter_provider: 'wallet'
            }
          },
          icon: 'euro-sign'
        },
        {
          label: 'Panier d\'achat',
          route: {name: 'carts.edit', params: {member: this.id}},
          icon: 'shopping-basket',
          disabled: !this.member?.isCustomer || false,
          disabledReason: 'Membre n\'est pas un client.'
        },
        {
          label: 'Voir commandes',
          route: {
            name: 'orders.list',
            params: {member: this.id},
            query: {
              filter_memberIdOrName: this.id
            }
          },
          icon: 'boxes'
        },
        {
          label: 'Voir livraisons',
          route: {
            name: 'deliveries.list',
            query: {
              filter_memberIdsOrName: parseInt(this.id)
            }
          },
          icon: 'truck'
        },
        {
          label: 'Voir factures',
          route: {
            name: 'invoices.list',
            query: {
              filter_memberIdsOrName: parseInt(this.id)
            }
          },
          icon: 'file-alt'
        },
        {
          label: 'Marquer toutes les notifications comme lues',
          closeAfter: false,
          action: this.markAllNotificationsAsRead,
          permissions: ['MEMBER_WRITE'],
          icon: 'envelope'
        },
        {
          label: 'Envoyer rappel factures',
          action: this.sendInvoiceReminder,
          closeAfter: false,
          icon: 'paper-plane',
          permissions: ['INTERNAL_INVOICE_READ']
        },
      ];
    }
  },
  methods: {
    getMember() {
      return MemberRepository.getSingle(this.id).then((res) => {
        this.member = res.data.data;
      });
    },
    sendInvoiceReminder() {
      if(!window.confirm("Voulez-vous vraiment envoyer un rappel de facture impayée ?")) {
        return false;
      }

      return MemberRepository.sendInvoiceReminder(this.id).then(() => {
        this.$sendSuccessToast("Envoyé avec succès!")
      });
    },
    markAllNotificationsAsRead() {
      if(!window.confirm("Voulez-vous vraiment marquer toutes les notifications de ce membre comme lues ?")) {
        return false;
      }

      return MemberRepository.notifications.markAllAsRead(this.id).then(() => {
        this.$sendSuccessToast("Toutes les notifications ont été marquées comme lues.")
      });
    },
    openModal() {
      return this.getMember().then(() => {
        this.$nextTick(() => {
          this.$refs.memberModal.openModal();
        })
      });
    },
  }
}
</script>

<style scoped>

</style>