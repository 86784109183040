import ShowBrands from "../../components/pages/Brands/ShowBrands";
import EditBrand from "../../components/pages/Brands/EditBrand/EditBrand";
import CreateBrand from "../../components/pages/Brands/CreateBrand/CreateBrand";
import auth from "../middlewares/authMiddleware";

export default [
  {
    path: '/brands',
    name: 'brands.list',
    component: ShowBrands,
    meta: {
      middleware: [auth],
      title: 'Liste des marques',
      permissions: ['BRAND_READ']
    }
  },
  {
    path: '/brands/:brand/edit',
    name: 'brands.edit',
    component: EditBrand,
    meta: {
      middleware: [auth],
      title: 'Modifier une marque',
      readOnly: {supported: true, title: 'Voir une marque', permissions: ['BRAND_READ']},
      breadcrumbs: ['brands.list'],
      permissions: ['BRAND_WRITE']
    }
  },
  {
    path: '/brands/create',
    name: 'brands.create',
    component: CreateBrand,
    meta: {
      middleware: [auth],
      title: 'Créer une marque',
      breadcrumbs: ['brands.list'],
      permissions: ['BRAND_WRITE']
    }
  }
];