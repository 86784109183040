<template>
  <div>
    <div
      v-if="isInDevMode"
      class="fixed bottom-4 left-4 bg-white rounded-sm border border-gray-300 shadow-lg"
      style="z-index: 9999"
    >
      <div class="relative">
        <transition name="fade-up-down">
          <div
            v-if="justScanned"
            class="absolute left-8 top-6"
          >
            <fa-icon icon="check" size="3x"/>
          </div>
        </transition>

        <fa-icon
          :class="scannerEnabled ? 'text-green-500' : 'text-red-500'"
          fixed-width
          icon="barcode"
          size="6x"
        />
      </div>

    </div>
  </div>
</template>

<script>

import {eventBus} from "@/eventBus";

export default {
  name: "ScannerListener",
  props: {
    acceptQuantity: {
      type: Boolean,
      default: true
    },
    acceptCode128: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    config: {
      prefix: [
        '%', 'ArrowLeft', 'ArrowRight', 'F15',
      ],
      suffix: [
        '%', 'Enter'
      ],
    },

    /*
     * The amount of MS that can pass at max since the last keystroke
     * for it to be considered entered by a scanner.
     */
    scanningThresholdInMs: 90,
    lastKeyStrokeAt: null,

    quantity: 1,
    quantityChanged: false,
    code: '',

    scannerEnabled: true,
    justScanned: false,
  }),
  created() {
    // If new Scanner Lister instance becomes rendered, back it up and set new one as active
    if (window.$scanner) {
      console.log("Moved window.$scanner to window.$previousScanner.");
      window.$previousScanner = window.$scanner;
    }
    window.$scanner = this;
    console.log("Set $window.scanner");

    window.addEventListener("keypress", this.handleKeyPress);

    eventBus.$on('disable-scanner', () => {
      console.info("Scanner disabled.");
      this.scannerEnabled = false;
    });
    eventBus.$on('enable-scanner', () => {
      console.info("Scanner enabled.");
      this.scannerEnabled = true;
    });
  },
  destroyed() {
    window.removeEventListener("keypress", this.handleKeyPress);

    // if this ScannerListener instance is set as previousScanner for whatever reason, clear that.
    if (this.isPreviousScannerCurrentListener) {
      window.$previousScanner = undefined;
    }

    // If previous Scanner instance was active, set it back to that one, otherwise set scanner instance undefined
    if (window.$previousScanner) {
      window.$scanner = window.$previousScanner;
      window.$previousScanner = undefined;

      console.log("Moved window.$previousScanner to window.$scanner.");
    } else {
      if (this.isActiveScannerCurrentListener()) {
        window.$scanner = undefined;
      }
    }

    console.log("Unset window.$scanner");
  },
  methods: {
    isActiveScannerCurrentListener() {
      if (!window.$scanner) {
        return false;
      }

      return window.$scanner._uid === this._uid;
    },
    isPreviousScannerCurrentListener() {
      if (!window.$previousScanner) {
        return false;
      }

      return window.$previousScanner._uid === this._uid;
    },

    handleKeyPress(e) {
      let pressedKey = e.key;

      // Get Quantity (typed before Scanning)
      if (this.isKeyNumber(pressedKey) && !this.isScanning && this.acceptQuantity) {
        if (this.quantity === 1 && !this.quantityChanged) {
          this.quantity = '';
          this.quantityChanged = true;
        }

        this.quantity = parseInt(this.quantity + '' + Number(pressedKey));
      }

      // Reset everything when scanning Prefix
      if (
        this.isInArray(pressedKey, this.config.prefix) &&
        !this.isScanning
      ) {
        this.startScanning();
        this.lastFocus = document.activeElement;
      }

      if (
        !this.isInArray(pressedKey, this.config.suffix) && // If not a prefix
        !this.isInArray(pressedKey, this.config.prefix) && // If not a suffix
        this.acceptKey(pressedKey) &&
        this.isScanning
      ) {
        if (this.getMillisecondsSinceLastScan() > this.scanningThresholdInMs) {
          this.stopScanning();
          return;
        }

        this.addToCode(pressedKey);
      }

      // PreventDefault when Scanning while inside of element
      if (this.isScanning) {
        document.activeElement.blur();
        e.preventDefault();


        // End Scan since we received a suffix.
        if (
          this.isInArray(pressedKey, this.config.suffix) && // if current key is suffix
          this.code !== '' // if code is not empty
        ) {
          this.emitCode(this.code, this.quantity);

          this.stopScanning();
          if (this.lastFocus) {
            this.lastFocus.focus();
          }
        }

      }
    },

    emitCode(code, quantity = null) {
      // Only Emit scanned code if this is the latest scannerListener instance
      if (!window.$scanner || window.$scanner._uid !== this._uid) {
        return false;
      }

      // Don't emit if scanner disabled. This currently is the case when a error dialog is shown.
      if (!this.scannerEnabled) {
        console.error("Scanner is disabled!");
        return false;
      }

      let payload = {code};

      /*
       * For now, as we dont have a possibility to pass a quantity before scanning and its kind of bugged, set fixed to 1.
       */

      // if(quantity !== null) {
      //     payload.quantity = quantity;
      // }
      payload.quantity = 1;

      // disable error
      console.log("emitCode quantity " + quantity);

      this.$emit('scan', payload);


      // Animation for debug mode
      if (this.isInDevMode) {
        this.justScanned = true;
        let vm = this;
        setTimeout(function () {
          vm.justScanned = false;
        }, 1500);
      }
    },

    startScanning() {
      this.lastKeyStrokeAt = (new Date()).getTime();
      this.code = '';
    },
    addToCode(char) {
      if (!this.acceptCode128) {
        char = Number(char);
      }

      this.code = this.code + '' + char;
      this.lastKeyStrokeAt = (new Date()).getTime();

    },
    stopScanning() {
      this.lastKeyStrokeAt = null;
      this.code = '';
      this.quantity = 1;
      this.quantityChanged = false;
    },

    getMillisecondsSinceLastScan() {
      let now = (new Date()).getTime();
      return now - this.lastKeyStrokeAt;
    },

    isInArray(needle, haystack) {
      return haystack.includes(needle);
    },
    isKeyNumber(key) {
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      return numbers.includes(Number(key));
    },

    acceptKey(key) {
      if (this.acceptCode128) {
        return true;
      }

      return this.isKeyNumber(key);
    }
  },
  computed: {
    isScanning() {
      return this.lastKeyStrokeAt !== null;
    },
    isInDevMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
}
</script>

