<template>
  <div>
    <page-title>
      {{ name }} #{{ getTagId }}
    </page-title>

    <card>
      <card-tab id="tagTab" icon="tag" title="Tag">
        <create-or-update-tag-form
          mode="update"
          @updateName="onUpdateName"
        />
      </card-tab>

      <card-tab id="imageTab" icon="images" title="Images">
        <manage-tag-images :tag-id="getTagId"></manage-tag-images>
      </card-tab>

      <card-tab id="localizationTab" icon="globe" title="Traduction">
        <update-tag-localization-form :tag-id="getTagId"></update-tag-localization-form>
      </card-tab>
    </card>
  </div>
</template>
<script>
import CreateOrUpdateTagForm from "../Forms/CreateOrUpdateTagForm";
import PageTitle from "../../../elements/pages/PageTitle";
import CardTab from "../../../elements/cards/tabs/CardTab";
import Card from "../../../elements/cards/Card";
import ManageTagImages from "./Images/ManageTagImages";
import UpdateTagLocalizationForm from "./Localization/UpdateTagLocalizationForm";

export default {
  name: "EditTag",
  components: {UpdateTagLocalizationForm, ManageTagImages, Card, CardTab, PageTitle, CreateOrUpdateTagForm},
  computed: {
    getTagId() {
      return parseInt(this.$route.params.tag);
    }
  },
  data: () => ({
    name: ''
  }),
  methods: {
    onUpdateName(name) {
      this.name = name;
    }
  },
}
</script>