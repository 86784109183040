<template>
  <div class="z-40">
    <fulfilment-nav-menu
      ref="navMenu"
    />

    <aside
      class="w-full h-12 md:h-16 lg:h-full lg:w-24 p-4 flex flex-row lg:flex-col space-10 items-center justify-between relative bg-gray-800 text-white">
      <fulfilment-navbar-button
        :is-router-link="false"
        icon="arrow-left"
        @click="goBack"
      />

      <fulfilment-navbar-button
        :is-router-link="false"
        icon="th-large"
        @click="openNavMenu"
      />

      <fulfilment-navbar-button
        :is-router-link="true"
        :to="{name: 'touchInterface.timeclock.index'}"
        icon="user-clock"
        type="warning"
        @click="openNavMenu"
      />

      <fulfilment-navbar-button
        :is-router-link="false"
        icon="lock"
        type="danger"
        @click="lockScreen"
      />
    </aside>
  </div>
</template>

<script>
import FulfilmentNavbarButton from "./TouchInterfaceNavbarButton";
import FulfilmentNavMenu from "./TouchInterfaceNavMenu";

export default {
  name: "TouchInterfaceNavBar",
  components: {FulfilmentNavMenu, FulfilmentNavbarButton},
  methods: {
    openNavMenu() {
      this.$refs.navMenu.open();
    },
    goBack() {
      this.$router.back();
    },
    lockScreen() {
      this.$store.commit('lockScreen/lockScreen');
    }
  },
}
</script>

