<template>
  <div>
    <card
      class="mb-4"
      size="small"
      type="light"
    >
      <div>
        Contact en cas <span class="text-red-500 font-bold">d'urgence technique</span> (Ilinca & Laurent):
        <fa-icon fixed-width icon="phone"></fa-icon>
        25 30 32 610
      </div>
    </card>

    <dynamic-report-query-graphs-list/>

  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import DynamicReportQueryGraphsList
  from "@/components/pages/Landing/DynamicReportQueryGraphs/DynamicReportQueryGraphsList.vue";

export default {
  name: "Home",
  components: {
    DynamicReportQueryGraphsList,
    Card
  },
}
</script>

