<template>
  <div class="p-2">
    <fruits-vegetable-add-stock-modal
      @stockchange="onStockChange"
    />

    <div class="flex flex-wrap gap-2">
      <fruits-vegetable-stock-product
        v-for="product in getProductsWithStock"
        :key="product.id"
        :product="product"
        class="w-24 h-24 sm:w-36 sm:h-36 2xl:w-28 2xl:h-28 flex-none"
      />
    </div>

    <div class="h-0.5 bg-gray-400 my-4"></div>

    <div class="flex flex-wrap gap-2">
      <fruits-vegetable-stock-product
        v-for="product in getProductsWithoutStock"
        :key="product.id"
        :product="product"
        class="w-24 h-24 sm:w-36 sm:h-36 2xl:w-28 2xl:h-28 flex-none"
      />
    </div>
  </div>
</template>

<script>
import ProductRepository from "@/repositories/ProductRepository";
import FruitsVegetableStockProduct
  from "@/components/pages/TouchInterface/Stock/FruitsVegetables/Components/FruitsVegetableStockProduct";
import FruitsVegetableAddStockModal
  from "@/components/pages/TouchInterface/Stock/FruitsVegetables/Components/FruitsVegetableAddStockModal";

export default {
  name: "FruitsVegetablesVisualStockManagementPage",
  components: {FruitsVegetableAddStockModal, FruitsVegetableStockProduct},
  data: () => ({
    products: []
  }),
  created() {
    ProductRepository.getInventoryByDepartment(
      this.$luxcaddyConfig('touchInterface.fruitsVegetableDepartmentId')
    ).then(res => {
      this.products = res.data.data;
    });
  },
  methods: {
    onStockChange({productId, newStock}) {
      this.products.find(p => p.id === productId).onShelfStock = newStock;
      this.products.sort((a, b) => (a.onShelfStock < b.onShelfStock) ? 1 : -1);
    }
  },
  computed: {
    getProductsWithoutStock() {
      return this.products.filter(p => p.onShelfStock === 0);
    },
    getProductsWithStock() {
      return this.products.filter(p => p.onShelfStock !== 0);
    }
  },
}
</script>
