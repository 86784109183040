import apiService from './Base/BaseRepository';

const url = '/members';

export default {

  /**
   * Gets the supplier data by its id.
   *
   * @param memberId
   * @returns {Promise<*>}
   */
  async getSupplierDetails(memberId) {
    return await apiService.get(`${url}/${memberId}/supplierDetails`);
  },

  /**
   * Returns a list of all suppliers.
   *
   * @return {Promise<AxiosResponse<any>>}
   */
  async getAllSuppliers() {
    return await apiService.get(`${url}/suppliers/list?perPage=999&page=1`);
  },


  async getAllProductSuppliers(searchTerm = "") {
    let filters = [{
      filterKey: 'isProductSupplier',
      filterValue: 1
    }];

    if (searchTerm.length > 0) {
      filters.push({
        filterKey: 'fullName',
        filterValue: searchTerm
      });
    }

    return await apiService
      .setFilters(filters)
      .setPagination(999, 1)
      .get(`${url}/suppliers/list`);
  },

  async getActiveProductSuppliers(searchTerm = "") {
    let filters = [
      {
        filterKey: 'isProductSupplier',
        filterValue: 1
      },
      {
        filterKey: 'isActive',
        filterValue: 1
      }
    ];

    if (searchTerm.length > 0) {
      filters.push({
        filterKey: 'fullName',
        filterValue: searchTerm
      });
    }

    return await apiService
      .setFilters(filters)
      .setPagination(999, 1)
      .get(`${url}/suppliers/list`);
  },

  /**
   * Updates an employee
   *
   * @param memberId
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async storeSupplierDetails(memberId, payload) {
    return await apiService.post(`${url}/${memberId}/supplierDetails`, payload);
  },

  /**
   * Deletes an employee.
   *
   * @param memberId
   * @return {Promise<AxiosResponse<any>>}
   */
  async deactivateSupplierDetails(memberId) {
    return await apiService.patch(`${url}/${memberId}/supplierDetails/deactivate`);
  },

  async activateSupplierDetails(memberId) {
    return await apiService.patch(`${url}/${memberId}/supplierDetails/activate`);
  }

}
