<template>
  <div class="whitespace-nowrap">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PercentageAmount"
}
</script>

<style scoped>

</style>