import apiService from './Base/BaseRepository';
import store from "../store/store";

const url = '/cart';

export default {
  /**
   * Fetches all carts.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`);
  },

  /**
   * Fetches all carts.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  /**
   * Returns a single cart of a customer.
   *
   * @param memberId
   * @param showLoadingIndicator
   * @param includes
   * @return {Promise<AxiosResponse<any>>}
   */
  async getSingle(memberId, showLoadingIndicator = true) {
    return await apiService
      .get(`${url}/${memberId}`, {showLoadingIndicator})
  },

  /**
   * Adds the given item to the cart
   * @param memberId
   * @param itemId
   * @param quantity
   * @return {Promise<any>}
   */
  async setItemQuantity(memberId, itemId, quantity) {
    if (quantity === null) {
      quantity = 0;
    }

    return await apiService.post(`${url}/setItems`, {
      memberId: memberId,
      items: [{productId: itemId, quantity: quantity}]
    }, {showLoadingIndicator: true}).then(() => {
      return store.dispatch('cart/fetchCart', {memberId: memberId, showLoadingIndicator: true});
    });
  },

  /**
   * Removes the given item from the card
   *
   * @param memberId
   * @param itemId
   * @return {Promise<AxiosResponse<any>>}
   */
  async removeItem(memberId, itemId) {
    return await this.setItemQuantity(memberId, itemId, 0);
  },
}
