<!--
    This component expects input in Cents.
    It will show the value in the input as euro
    and returns a value in cents.
-->

<template>
  <base-input-element
    v-model="modelValue"
    :currency-options="getCurrencyOptions"
    :disabled="disabled"
    :label="label"
    :occupy-validation-space="occupyValidationSpace"
    :placeholder="placeholder"
    :validation-rules="validationRules"
    type="currency"
    @blur="$emit('blur', modelValue)"
    @enter="$emit('enter', $event)"
    @escape="$emit('escape', $event)"
    @focus="$emit('focus', $event)"
    @input="$emit('input', $event)"
  />
</template>

<script>
import BaseInputElement from "./base/BaseInputElement";

export default {
  name: "CurrencyInputElement",
  components: {
    BaseInputElement,
  },
  data: () => ({
    errorMessage: '',
    hasFocus: false,
  }),
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    validationRules: {
      type: String,
      default: ''
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      default: "EUR"
    }
  },
  computed: {
    getValidationRules() {
      return this.validationRules?.length ? `${this.validationRules}|decimal` : 'decimal';
    },
    getCurrencyOptions() {
      let options = {
        currency: this.currency,
        currencyDisplay: "symbol",
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        valueScaling: "precision",
        useGrouping: true,
        accountingSign: false,
        locale: "fr-FR",
      };

      if (!this.allowNegative) {
        options.valueRange = {
          "min": 0
        };
      }

      return options;
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update', value);
      }
    }
  }

}
</script>

