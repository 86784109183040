<template>
  <div>
    <touch-optimized-button @click="openModal">
            <span
              v-if="!loading"
              :class="{'font-bold': getSelectedDepartmentId !== null}"
            >
                {{ getSelectedDepartmentName }}
            </span>
      <fa-icon v-else class="animate-spin transition" icon="circle-notch"></fa-icon>
    </touch-optimized-button>

    <touch-optimized-modal ref="modal">

      <div class="flex justify-center">
        <touch-optimized-modal-button
          border-class="border-luxcaddy-300"
          class="mb-24 w-max float-right"
          text-class="text-luxcaddy-300"
          @click="selectDepartment(null)"
        >
          Tous les départements
        </touch-optimized-modal-button>
      </div>

      <div class="flex flex-wrap gap-8 clear-both">
        <touch-optimized-modal-button
          v-for="department in availableDepartments"
          :key="department.id"
          @click="selectDepartment(department)"
        >
          {{ department.name }}
        </touch-optimized-modal-button>
      </div>
    </touch-optimized-modal>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import DepartmentRepository from "@/repositories/DepartmentRepository";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "OrderFulfilmentListDepartmentButton",
  components: {TouchOptimizedModalButton, TouchOptimizedModal, TouchOptimizedButton},
  data: () => ({
    loading: false,
    availableDepartments: []
  }),
  methods: {
    ...mapMutations('fulfilmentFilters', [
      'setSelectedDepartment',
      'setSelectedGroup'
    ]),

    openModal() {
      this.loading = true;
      DepartmentRepository.getAll().then((res) => {
        this.availableDepartments = res.data.data;
        this.$refs.modal.open();
      }).finally(() => this.loading = false);
    },

    selectDepartment(department) {
      this.setSelectedDepartment(department);
      this.$refs.modal.close();

      // Reset selected group when department changes
      this.setSelectedGroup(null);
    }
  },
  computed: {
    ...mapGetters('fulfilmentFilters', [
      'getSelectedDepartment',
      'getSelectedDepartmentId'
    ]),
    getSelectedDepartmentName() {
      return this.getSelectedDepartment?.name || 'Département';
    }
  },
}
</script>

