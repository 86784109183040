<template>
  <div>
    <div class="flex items-start gap-x-4">
      <page-title>
        {{ getBannerName }} #{{ getBannerId }}
      </page-title>

      <banner-type-label
        v-if="getBanner.id !== null"
        :type="getBanner.type"
        class="mt-1"
      />
    </div>

    <card>
      <card-tab id="bannerTab" icon="user-circle" title="Bannière">
        <create-or-update-banner-form
          :banner-id="getBannerId"
          mode="update"
        />
      </card-tab>

      <card-tab id="imageTab" key="imageTab" ref="imageTab" icon="images" title="Images">
        <manage-banner-images
          :banner-id="getBannerId"
        />
      </card-tab>

      <card-tab id="localizationTab" ref="localizationTab" icon="globe" title="Traduction">
        <update-banner-localization-form
          :banner-id="getBannerId"
        />
      </card-tab>


    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import CreateOrUpdateBannerForm from "@/components/pages/Banner/Management/Common/CreateOrUpdateBannerForm";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import UpdateBannerLocalizationForm from "@/components/pages/Banner/Management/EditBanner/UpdateBannerLocalizationForm";
import ManageBannerImages from "@/components/pages/Banner/Management/EditBanner/BannerImages/ManageBannerImages";
import {mapGetters} from "vuex";
import BannerTypeLabel from "@/components/pages/Banner/Components/BannerTypeLabel.vue";

export default {
  name: "EditBanner",
  components: {
    BannerTypeLabel,
    ManageBannerImages,
    UpdateBannerLocalizationForm,
    CardTab,
    CreateOrUpdateBannerForm,
    Card,
    PageTitle
  },
  computed: {
    getBannerId() {
      return Number(this.$route.params.banner);
    },
    ...mapGetters('banners', [
      'getBanner'
    ]),
    getBannerName() {
      return this.getBanner?.name || '[...]';
    },
  },
}
</script>

<style scoped>

</style>