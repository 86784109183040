<template>
  <div>
    <status-indicator
      v-if="isPublished"
      small
      with-icon
    >
      Publiée <span class="font-semibold">({{ getFormattedDate }})</span>
    </status-indicator>
    <status-indicator
      v-else
      small
      with-icon
    >
      Pas publiée <span v-if="willBePublishedInFuture" class="font-semibold">(Sera publié le {{
        getFormattedDate
      }})</span>
    </status-indicator>
  </div>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "BlogPostPublishStatus",
  components: {StatusIndicator},
  props: {
    publishedAt: {
      type: String
    }
  },
  computed: {
    getDateInstance() {
      if (this.publishedAt === null)
        return null;

      return this.$date(this.publishedAt);
    },
    getFormattedDate() {
      if (this.getDateInstance === null)
        return null;

      return this.getDateInstance.format('DD.MM.YY HH:mm');
    },
    isPublished() {
      if (this.getDateInstance === null)
        return false;

      return this.getDateInstance.isBefore(this.$date());
    },
    willBePublishedInFuture() {
      return !this.isPublished && this.getDateInstance !== null;
    }
  },
}
</script>

<style scoped>

</style>