<template>
  <div
    :class="[selected ? 'border-luxcaddy-300' : 'hover:bg-gray-50 border-gray-200 ']"
    class="rounded-lg shadow-xs hover:shadow-sm border bg-white"
  >

    <!-- Top bar -->
    <div
      class="w-full flex justify-between items-center border-b bg-yellow-50 py-1 rounded-t-lg px-1 select-none h-8">
      <fa-icon
        v-if="showHandle && !$isReadOnly()"
        class="text-gray-400 hover:text-gray-500 handle cursor-grab"
        icon="bars"
      />

      <slot></slot>

      <product-quick-action-modal
        :id="parseInt(product.id)"
        class="text-xs"
      />
    </div>


    <!-- Content -->
    <div class="flex flex-col items-center justify-between gap-y-2 flex-1 p-2 h-48">
      <img
        v-if="getProductImage"
        :alt="product.name"
        :src="getProductImage"
        class="object-contain h-24"
      >

      <h3
        :title="product.name"
        class="text-xs text-gray-900 w-full text-center flex-1"
      >
        <product-name :product="product"/>
      </h3>

      <product-info-contents
        :selling-bundle="product.sellingData.bundle"
      />
    </div>

  </div>
</template>

<script>

import ProductQuickActionModal from "../../pages/Products/Subcomponents/ProductQuickActionModal";
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import ProductInfoContents from "@/components/global/Products/ProductInfoContents.vue";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";

export default {
  name: "ProductDisplayCard",
  components: {ProductName, ProductInfoContents, ProductQuickActionModal},
  props: {
    product: {
      type: Object,
      required: true
    },
    showHandle: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    getProductImage() {
      return getPrimaryImageUrlFromImageObject(this.product, "small");
    },
  },
}
</script>

