<template>
  <modal ref="moveCategoryModal" title="Déplacer categorie">
    <info-alert>
      Sélectionnez une catégorie qui doit être le parent de votre catégorie sélectionnée.
    </info-alert>

    <card class="max-h-screen mt-6" type="light">
      <category-selection-tree
        :is-virtual="getCategoryIsVirtual"
        :type="getCategoryType"
        with-root></category-selection-tree>
    </card>
    <div class="flex justify-end gap-4 mt-4">
      <button-element :disabled="!categorySelected" type="primary" @click="moveCategory">
        <fa-icon class="mr-2" fixed-width icon="exchange-alt"></fa-icon>
        Déplacer catégorie
      </button-element>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal";
import CategorySelectionTree from "@/components/elements/categoryManagement/Tree/Selection/CategorySelectionTree";
import InfoAlert from "@/components/elements/alerts/InfoAlert";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import Card from "@/components/elements/cards/Card";

export default {
  name: "ProductCategoryMoveModal",
  components: {Card, ButtonElement, InfoAlert, CategorySelectionTree, Modal},
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  methods: {
    openModal() {
      this.$refs.moveCategoryModal.openModal();
    },
    closeModal() {
      if (this.$refs.moveCategoryModal !== undefined) {
        this.$refs.moveCategoryModal.closeModal();
      }
    },
    moveCategory() {
      if (!this.categorySelected) {
        return false;
      }

      if (this.selectedCategory === "root") {
        let existingRootCategoryIds = this.$store.getters['categories/getAllCategories'].flatMap(c => c.id);
        existingRootCategoryIds.push(this.category.id);

        this.$store.dispatch('categories/updateRootSort', {
          type: "product",
          categoryIdsSorted: existingRootCategoryIds
        }).then(() => {
          this.$sendSuccessToast('Catégorie deplacée.');
          this.closeModal();
        });
      } else {
        // Push category to the end of the selected category's children
        let flatExistingChildren = this.selectedCategory.subCategories.flatMap(c => c.id);
        flatExistingChildren.push(this.category.id);

        this.$store.dispatch('categories/updateChildrenSort', {
          parentId: this.selectedCategory.id,
          categoryIdsSorted: flatExistingChildren
        }).then(() => {
          this.$sendSuccessToast('Catégorie deplacée.');
          this.closeModal();
        });
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('categories/setSelectedCategoryInSelectionTree', null);
  },
  computed: {
    categorySelected() {
      return this.selectedCategory !== null;
    },
    selectedCategory() {
      return this.$store.getters['categories/getSelectedCategoryInSelectionTree'];
    },
    getCategoryType() {
      return this.$store.getters['categories/getCurrentCategoryType'];
    },
    getCategoryIsVirtual() {
      return this.$store.getters['categories/getCurrentCategoryIsVirtual'];
    },
  }
}
</script>

