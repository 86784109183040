<template>
  <div>
    <page-title>
      Modifier pointage
    </page-title>

    <card>
      <create-or-update-time-clock-entry-form
        :time-clock-entry-id="getEntryId"
        mode="update"
      />
    </card>
  </div>
</template>

<script>
import CreateOrUpdateTimeClockEntryForm from "@/components/pages/TimeClockEntry/Form/CreateOrUpdateTimeClockEntryForm";
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";

export default {
  name: "UpdateTimeClockEntry",
  components: {Card, PageTitle, CreateOrUpdateTimeClockEntryForm},
  computed: {
    getEntryId() {
      return parseInt(this.$route.params.entry);
    }
  },
}
</script>

<style scoped>

</style>