<template>
  <div v-if="getOrderInstructions.length > 0">
    <div class="text-center text-6xl mt-6 mb-2 animate-pulse animate-bounce">
      <fa-icon class="text-red-500" icon="exclamation-triangle"/>
    </div>
    <div
      v-for="instruction in getOrderInstructions"
      :key="instruction.id"
      class="rounded-md bg-red-500 p-2 mb-0.5"
    >
            <span class="text-white font-medium">
                {{ instruction.text }}
            </span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OrderFulfilmentInstructionList",
  computed: {
    ...mapGetters('fulfilment', ['getOrderInstructions']),
  },
}
</script>

