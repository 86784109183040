<template>
  <touch-interface-card-with-loading-indicator
    :loading="loading"
    color="white"
    disable-padding
  >
    <div class="flex flex-col divide-y divide-gray-400">
      <driver-my-routes-route-box
        v-for="step in getStepsWithDeliveries"
        :key="step.delivery.id"
        @click="navigateToDelivery(step.delivery.id, step.initialETA.displayTime)"
      >
        <div class="font-semibold truncate">
          {{ $date(step.initialETA.displayTime).format('HH:mm') }}
          &middot;
          <span class="tracking-wide">
                    {{ step.delivery.destination.fullName }}
                </span>
        </div>

        <div class="flex flex-wrap items-start justify-between space-x-2">
          <div
            class="mt-2 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 text-sm items-start flex-wrap">
            <div class="tracking-tight">
              {{ step.delivery.destination.street }}<br>
              {{ step.delivery.destination.postalCode }} {{ step.delivery.destination.city }}
            </div>

            <div class="flex-shrink-0 | flex items-center space-x-1">
              <fulfilment-number-display
                :fulfilment-number="step.delivery.fulfilmentNumber"
              />

              <box-quantity
                :quantity="step.delivery.boxQuantity"
              />

              <div class="ml-0.5 text-sm">#{{ step.delivery.id }}</div>
            </div>
          </div>

          <fa-icon
            v-if="isStepDelivered(step)"
            class="text-luxcaddy-500 mt-3.5 sm:mt-0"
            fixed-width
            icon="check"
            size="2x"
          />

        </div>
      </driver-my-routes-route-box>
    </div>
  </touch-interface-card-with-loading-indicator>
</template>

<script>
import RouteRepository from "@/repositories/RouteRepository";
import DriverMyRoutesRouteBox
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/Components/DriverMyRoutesRouteBox";
import TouchInterfaceCardWithLoadingIndicator
  from "@/components/pages/TouchInterface/Shared/TouchInterfaceCardWithLoadingIndicator";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";
import BoxQuantity from "@/components/global/BoxQuantity/BoxQuantity";

export default {
  name: "DriverMyRoutesRouteDetailPage",
  components: {BoxQuantity, FulfilmentNumberDisplay, TouchInterfaceCardWithLoadingIndicator, DriverMyRoutesRouteBox},
  data: () => ({
    loading: false,
    route: null,
  }),
  created() {
    this.fetchRoute();
  },
  methods: {
    fetchRoute() {
      this.loading = true;
      RouteRepository
        .getSingle(this.$route.params.route)
        .then(res => {
          this.$set(this, 'route', res.data.data);

          // send route id to app
          if (typeof window.Android !== 'undefined') {
            window.Android.setRouteId(this.route.id);
          }

        }).finally(() => this.loading = false);
    },
    navigateToDelivery(deliveryId, initialETATime) {
      this.$router.push({
        name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.index',
        params: {
          route: this.$route.params.route,
          delivery: deliveryId
        },
        query: {
          eta: initialETATime
        }
      })
    },
    isStepDelivered(step) {
      return step?.delivery?.deliveredAt !== null;
    }
  },
  computed: {
    getStepsWithDeliveries() {
      if (this.route === null)
        return [];

      return this.route.steps.filter(s => s.delivery !== undefined);
    }
  },
}
</script>

<style scoped>

</style>