<template>
  <div>
    <page-title>{{ name }} #{{ getCategoryId }}</page-title>

    <card>
      <card-tab id="categoryTab" icon="th-large" title="Catégorie">
        <category-create-or-update-form
          ref="updateForm" :category-id="getCategoryId"
          mode="update"
          @updateName="onUpdateName"
        />
      </card-tab>

      <card-tab id="blogPostTab" icon="newspaper" title="Articles Blog">
        <edit-blog-posts-in-category :category-id="getCategoryId"/>
      </card-tab>

      <card-tab id="imageTab" icon="images" title="Images">
        <manage-product-category-images :product-category-id="getCategoryId"></manage-product-category-images>
      </card-tab>

      <card-tab id="localizationTab" icon="globe" title="Traduction">
        <update-category-localization :category-id="getCategoryId"></update-category-localization>
      </card-tab>
    </card>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import PageTitle from "@/components/elements/pages/PageTitle";
import ManageProductCategoryImages from "@/components/pages/Categories/Common/Images/ManageProductCategoryImages";
import UpdateCategoryLocalization from "@/components/pages/Categories/Common/Localization/UpdateCategoryLocalization";
import CategoryCreateOrUpdateForm from "@/components/pages/Categories/Common/Forms/CategoryCreateOrUpdateForm";
import EditBlogPostsInCategory from "@/components/pages/Categories/Blog/Sub/EditBlogPostsInCategory";

export default {
  name: "EditBlogPostCategory",
  components: {
    EditBlogPostsInCategory,
    CategoryCreateOrUpdateForm,
    UpdateCategoryLocalization,
    ManageProductCategoryImages,
    PageTitle,
    CardTab,
    Card
  },
  computed: {
    getCategoryId() {
      return parseInt(this.$route.params.category);
    }
  },
  data: () => ({
    name: ''
  }),
  methods: {
    onUpdateName(name) {
      this.name = name;
    }
  },
}
</script>

