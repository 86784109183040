const getDefaultState = () => {
  return {
    selectedDay: null,
    selectedDepartment: null,
    selectedRoute: null,
    selectedGroup: null,
    selectedStatus: null,

    selectedSort: null,

    selectedFulfilmentGroup: null,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getSelectedDay: state => state.selectedDay,

    getSelectedDepartment: state => state.selectedDepartment,
    getSelectedDepartmentId: state => state.selectedDepartment?.id || null,

    getSelectedRoute: state => state.selectedRoute,
    getSelectedRouteId: state => state.selectedRoute?.value || null,

    getSelectedGroup: state => state.selectedGroup,
    getSelectedGroupNumber: state => state.selectedGroup?.number || null,
    getSelectedGroupId: state => state.selectedGroup?.id || null,

    getSelectedStatus: state => state.selectedStatus,
    getSelectedStatusValue: state => state.selectedStatus?.value || null,

    getSelectedSort: state => state.selectedSort,
    getSelectedSortValue: state => state.selectedSort?.value || null,

    canSelectGroup: state => state.selectedDay !== null && state.selectedDepartment !== null,
  },
  mutations: {
    setSelectedDay(state, day) {
      state.selectedDay = day;
    },
    setSelectedDepartment(state, department) {
      state.selectedDepartment = department;
    },
    setSelectedRoute(state, route) {
      state.selectedRoute = route;
    },
    setSelectedGroup(state, group) {
      state.selectedGroup = group;
    },
    setSelectedStatus(state, status) {
      state.selectedStatus = status;
    },
    setSelectedSort(state, sort) {
      state.selectedSort = sort;
    }
  },
}