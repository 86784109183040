<!--
    Allows the user to select where the banner should link to.
    This can either be an internal link defined by a PageName and optional params like an id.
-->
<template>
  <card
    ref="card"
    :navigation-through-query-params="false"
    :set-initial-tab="false"
    size="small"
    title="Lien bannière"
    type="light"
  >
    <card-tab id="internalLinkTab" icon="link" title="Lien interne">

      <select-element
        v-model="getBanner.linkPageName"
        :enable-search="false"
        :options="getPageLinkTypesForSelect"
        label="Page"
        @input="clearLinkPageParams"
      />

      <template v-if="getLinkPageConfigForSelection && getBanner.linkPageParams">
        <number-input-element
          v-if="getLinkPageConfigForSelection.requiresId && getBanner.linkPageParams"
          v-model="getBanner.linkPageParams.id"
          label="ID"
        />
      </template>
    </card-tab>
    <card-tab id="externalLinkTab" icon="external-link-alt" title="Lien externe">
      <text-input-element
        v-model="getBanner.linkUrl"
        label="Lien"
        placeholder="https://google.de"
      />
    </card-tab>
  </card>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import {mapGetters, mapMutations} from "vuex";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "BannerLinkTypeSelection",
  components: {SelectElement, NumberInputElement, TextInputElement, CardTab, Card},
  mounted() {
    const banner = this.getBanner;

    if (banner.linkPageName) {
      this.$refs.card.setActiveTabById("internalLinkTab");
    } else if (banner.linkUrl) {
      this.$refs.card.setActiveTabById("externalLinkTab");
    } else {
      // Create mode, when nothing is set, just open the first tab
      this.$refs.card.setActiveTabById("internalLinkTab");
    }

    this.$nextTick(() => {
      this.$emit('loaded');
    })
  },
  methods: {
    ...mapMutations('banners', [
      'clearLinkPageParams',
      'initializeLinkPageParams',
      'resetLinkRelatedProperties'
    ]),
  },
  computed: {
    ...mapGetters('banners', [
      'getBanner'
    ]),
    getPageLinkTypes() {
      return this.$luxcaddyConfig('banner.pageLinkTypes').filter(p => p.canBeLinkedTo);
    },
    getLinkPageConfigForSelection() {
      if (!this.getBanner.linkPageName)
        return null;

      return this.getPageLinkTypes.find(pl => pl.model === this.getBanner.linkPageName);
    },
    getPageLinkTypesForSelect() {
      return this.getPageLinkTypes.map(pl => {
        return {
          label: pl.label,
          value: pl.model,
        }
      })
    }
  },
}
</script>

<style scoped>

</style>