<template>
  <div>
    <div class="flex items-center justify-between gap-3">
      <truck-icon-svg
        v-if="showTruckIcon"
        :style="`color: ${color}`"
        class="w-8 h-8"
      />
      <div v-if="!showTruckIcon" :style="`color: ${color}`" class="text-lg">
        {{ deliveredDeliveries }}/{{ totalDeliveries }}
      </div>
      <div class="flex-1 overflow-hidden h-4 text-xs flex rounded border border-gray-400 bg-gray-100">
        <div :style="`width: ${getPercentage}%; background-color: ${color}`"
             class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
      </div>
      <div class="text-right">
              <span :style="`color: ${color}`" class="text-lg font-medium inline-block">
                {{ getPercentage.toFixed(0) }}%
              </span>
      </div>

      <transition name="fade">
        <button-element
          v-if="showJumpToPositionButton"
          size="xsmall"
          type="routeManager"
          @click="setClickedRoute"
        >
          <fa-icon class="text-gray-500" fixed-width icon="map-pin"/>
        </button-element>
      </transition>
    </div>

    <div
      v-if="device"
      :class="{'mt-2': biggerLineSpacing, 'gap-1.5': !biggerLineSpacing, 'gap-2.5': biggerLineSpacing}"
      class="flex items-center justify-between flex-wrap"
    >
      <div class="font-medium italic">
        {{ device.phoneNumber }}
      </div>

      <luxembourgish-number-plate
        :plate="device.vehiclePlate"
        small
      />
    </div>
  </div>
</template>

<script>
import TruckIconSvg from "@/components/pages/RouteManager/Includes/Progress/TruckIconSvg";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import {mapMutations} from "vuex";
import LuxembourgishNumberPlate from "@/components/pages/Devices/Includes/LuxembourgishNumberPlate";

export default {
  name: "RouteDeliveryProgress",
  components: {LuxembourgishNumberPlate, ButtonElement, TruckIconSvg},
  props: {
    color: {
      type: String,
      required: true,
    },
    showTruckIcon: {
      type: Boolean,
      default: true
    },
    deliveredDeliveries: {
      type: Number,
      required: true
    },
    totalDeliveries: {
      type: Number,
      required: true
    },
    routeId: {
      type: Number,
    },
    showJumpToPositionButton: {
      type: Boolean,
      default: true
    },
    device: {
      type: Object,
      required: false
    },
    biggerLineSpacing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations('routeManager', [
      'setClickedDriverRouteId'
    ]),
    setClickedRoute() {
      if (!this.routeId)
        return false;

      this.setClickedDriverRouteId(this.routeId);
    }
  },
  computed: {
    getPercentage() {
      if (this.deliveredDeliveries === 0 && this.totalDeliveries === 0)
        return 0;

      return (this.deliveredDeliveries / this.totalDeliveries) * 100;
    }
  },
}
</script>

