<template>
  <list-table
    :columns="columns"
    :create-route="{
        name: 'shortUrls.create'
      }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="shortUrlsTable"
  />

</template>

<script>
import ListTable from "../../listTable/ListTable";
import ShortUrlRepository from "../../../repositories/ShortUrlRepository";

export default {
  name: "ShowShortUrls",
  components: {ListTable},
  data() {
    return {
      title: 'ShortUrl',
      repositoryFunc: ShortUrlRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir shortUrl',
              route: (row) => ({
                name: 'shortUrls.edit',
                params: {
                  slug: row.slug,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier shortUrl',
              route: (row) => ({
                name: 'shortUrls.edit',
                params: {
                  slug: row.slug,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer shortUrl',
              confirmation: {
                title: 'Supprimer le shortUrl?',
                text: 'Étes vous sure que vous voulez supprimer ce shortUrl?'
              },
              successText: 'Le shortUrl a été supprimée',
              deleteAction: (row) => ShortUrlRepository.delete(row.id)
            }
          ]
        },
        {
          caption: '#',
          dataField: 'id',
          width: 200,
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'ShortURL',
          calculateCellValue: (r) => {
            return "https://luxcaddy.lu/s/" + r.slug
          },
          additionalClasses: () => {
            return "select-all";
          },
          width: 200,
          sort: {
            dataField: 'slug',
          }
        },
        {
          caption: 'Lien',
          dataField: 'fullUrl',
          sort: {
            dataField: 'fullUrl'
          }
        },

      ],
      filters: [
        {
          caption: 'Slug',
          filterKey: 'slug',
          forceIsVisible: true,
        },
        {
          caption: 'Lien / URL',
          filterKey: 'url',
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>