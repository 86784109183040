<template>
  <div>
    <transition name="fade">
      <google-map
        ref="map"
        :height-in-px="mapHeightInPx"
        :latitude="getLatitude"
        :longitude="getLongitude"
        :markers="getMarkers"
        :zoom-level="13"
      />
    </transition>

    <card class="mt-4" size="paddingless">
      <div class="flex flex-wrap">
        <div
          :class="isPrecise ? 'bg-luxcaddy-700' : 'bg-red-700'"
          class="flex-none px-12 text-white flex flex-col justify-center items-center"
        >
          <div class="font-bold uppercase">
            Geocoding
          </div>
          <div v-if="isPrecise">
            <fa-icon class="mr-1" fixed-width icon="check"/>
            Précis
          </div>
          <div v-else>
            <fa-icon class="mr-1" fixed-width icon="times"/>
            Non Précis
          </div>
        </div>
        <div class="flex-1 flex flex-wrap items-center gap-4 p-4">

          <toggle-element
            v-model="getManualGeocoding"
            :disabled="disableToogle"
            disabled-text="Automatique"
            enabled-text="Manuel"
          />

          <text-input-element
            v-model="latitude"
            :disabled="!getManualGeocoding"
            label="Latitude"
          />

          <text-input-element
            v-model="longitude"
            :disabled="!getManualGeocoding"
            label="Longitude"
          />
        </div>
      </div>
    </card>

  </div>
</template>

<script>
import ToggleElement from "@/components/elements/toggles/ToggleElement";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import GoogleMap from "@/components/elements/display/map/GoogleMap";
import Card from "@/components/elements/cards/Card";

export default {
  name: "GeocodingManagementMap",
  components: {Card, GoogleMap, TextInputElement, ToggleElement},
  props: {
    disableToogle: {
      type: Boolean,
      default: false
    },
    manualGeocoding: {
      type: Boolean
    },
    latitude: {
      type: [Number]
    },
    longitude: {
      type: [Number]
    },
    isPrecise: {
      type: Boolean
    },
    mapHeightInPx: {
      type: Number,
      default: 400
    }
  },
  computed: {

    getManualGeocoding: {
      get() {
        return this.manualGeocoding
      },
      set(value) {
        this.$emit('update:manualGeocoding', value);
      }
    },
    getLatitude: {
      get() {
        return this.latitude;
      },
      set(value) {
        this.$emit('update:latitude', value);
      }
    },
    getLongitude: {
      get() {
        return this.longitude;
      },
      set(value) {
        this.$emit('update:longitude', value);
      }
    },
    getMarkers() {
      return [
        {
          position: {
            lat: this.getLatitude,
            lng: this.getLongitude,
          },
          draggable: this.manualGeocoding,
          dragEvent: this.onMarkerDrag
        }
      ];
    },
  },
  methods: {
    onMarkerDrag(location) {
      this.getLatitude = location.latLng.lat();
      this.getLongitude = location.latLng.lng();
    },
  },
}
</script>

<style scoped>

</style>