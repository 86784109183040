import ApiService from './Base/BaseRepository';

const url = '/logs';


export default {
  /**
   * Fetches all logs.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {


    return await ApiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getLoggableTypes() {
    return await ApiService
      .get(`${url}/loggable-types`);
  },
  async getLogActions() {
    return await ApiService
      .get(`${url}/actions`);
  },

}