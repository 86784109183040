<template>
  <div>
    <list-table
      :columns="columns"
      :create-route="{
                name: 'fees.create'
            }"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      identifier="feesTable"
    >
      <template v-slot:timespan-template="{ row }">
        <div
          :class="{'text-red-400 line-through' : row.endDate !== null && isDateBeforeNow($date(row.endDate))}">
          {{ $date(row.startDate).format('DD.MM.YYYY HH:mm') }}
          <span v-if="row.endDate !== null">
                        <fa-icon class="mx-4" icon="long-arrow-alt-right"></fa-icon>
                        {{ $date(row.endDate).format('DD.MM.YYYY HH:mm') }}
                    </span>
          <span v-else>
                        <fa-icon class="mx-4" icon="long-arrow-alt-right"></fa-icon>
                        <fa-icon class="text-gray-400" icon="infinity"></fa-icon>
                    </span>
        </div>
      </template>
    </list-table>
  </div>
</template>

<script>
import FeeRepository from "../../../repositories/FeeRepository";
import ListTable from "../../listTable/ListTable";
import {FILTER_BOOLEAN} from "../../listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowFees",
  components: {ListTable},
  data() {
    return {
      title: 'Frais',
      repositoryFunc: FeeRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir frais',
              route: (row) => ({
                name: 'fees.edit',
                params: {
                  fee: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier frais',
              route: (row) => ({
                name: 'fees.edit',
                params: {
                  fee: row.id
                }
              }),
            }
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: 'Nom',
          dataField: 'name',
          sort: {
            dataField: 'name',
            default: 'asc'
          },
        },
        {
          caption: 'Prix',
          dataField: 'price',
          dataType: 'euro',
          sort: {
            dataField: 'price'
          }
        },
        {
          caption: 'TVA',
          dataField: 'vat',
          dataType: 'percentage',
          sort: {
            dataField: 'vat'
          },
        },
        {
          caption: 'Active',
          dataType: 'boolean',
          calculateCellValue: (r) => {
            if (r.startDate && this.isDateAfterNow(this.$date(r.startDate))) {
              return false;
            }
            if (r.endDate !== null) {
              return !this.isDateBeforeNow(this.$date(r.endDate));
            }
            return true;
          },
          width: 100,
        },
        {
          caption: 'Période',
          dataField: 'startDate',
          cellTemplate: "timespan-template",
        },
      ],
      filters: [
        {
          caption: '# ID',
          filterKey: 'id',
          forceIsVisible: true,
        },
        {
          caption: 'Nom',
          filterKey: 'name',
          forceIsVisible: true,
        },
        {
          caption: 'Status',
          filterKey: 'isActive',
          type: FILTER_BOOLEAN,
          small: true
        }
      ]
    }
  },
  methods: {
    isDateBeforeNow(dayJsDate) {
      return dayJsDate.isBefore(this.$date());
    },
    isDateAfterNow(dayJsDate) {
      return dayJsDate.isAfter(this.$date());
    }
  }
}
</script>


