/**
 * Redirects to the home page if the user is logged in.
 *
 * @param next
 * @param router
 * @return {*}
 */
export default function guest({next, router}) {
  if (localStorage.getItem('user')) {
    return router.push({name: 'home'});
  }

  return next();
}