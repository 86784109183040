<template>
  <div>
    <select-element
      v-model="selectedRolesModel"
      :disabled="!canUserChangePermissions"
      :options="getRoleOptions"
      class="flex-1"
      label="Rôles"
      multi
      validation-rules="required"
    />

    <div
      v-if="getUniquePermissionsOfAllSelectedRolesChunks.length > 0"
      class="bg-gray-100 p-4 rounded-md">
      <h2 class="font-bold mb-2">{{ getUniquePermissionsOfAllSelectedRolesChunks.length }} Permissions:</h2>

      <div class="grid grid-cols-1 md:grid-cols-4 text-xs gap-y-1">
        <ul
          v-for="(chunkedPermissions, i) in getChunkedUserPermissions"
          :key="'chunk_' + i"
          class="list-disc list-inside"
        >
          <li
            v-for="permission in chunkedPermissions"
            :key="permission"
            :title="permission"
            class="truncate"
          >
            {{ permission }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import {userAllowedToEditOtherUser, userCanSetRole, userHasPermission} from "@/helpers/permissionHelper";
import arrayHelper from "@/helpers/arrayHelper";

export default {
  name: "MemberUserCrudRoles",
  components: {SelectElement},
  props: {
    value: {
      type: Array,
    },
    roleList: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    selectedRolesModel: {
      get() {
        return this.value;
      },
      set(roles) {
        this.$emit('input', roles);
      }
    },

    canUserChangePermissions() {
      // User is allowed to use "SET_MEMBER_ROLES"
      const hasSetRolesPermission = userHasPermission(
        ['MEMBER_SET_ROLES'],
        this.$store.getters['authUser/getPermissions']
      );

      return hasSetRolesPermission &&
        userAllowedToEditOtherUser(this.roleList, this.getCurrentUserRoleIds, this.selectedRolesModel);
    },

    getCurrentUserRoles() {
      return this.roleList
        .filter(r => this.$store.getters["authUser/getRoles"].includes(r.name));
    },

    getCurrentUserRoleIds() {
      return this.getCurrentUserRoles
        .flatMap(r => r.id);
    },

    /**
     * Returns an Array of distinct permissions combined for all selected roles.
     * @returns {*[]}
     */
    getUniquePermissionsOfAllSelectedRolesChunks() {
      let permissionsOfRoles = this.roleList
        .filter(rl => this.selectedRolesModel.includes(rl.id))
        .flatMap(r => r.permissions);

      return [...new Set(permissionsOfRoles)].sort();
    },

    getChunkedUserPermissions() {
      return arrayHelper.splitIntoChunks(this.getUniquePermissionsOfAllSelectedRolesChunks, 4);
    },

    getCurrentUserHighestRoleLevel() {
      return Math.max(...this.getCurrentUserRoles.flatMap(r => r.level));
    },

    getRoleOptions() {
      return this.roleList.map(role => {
        return {
          label: role.name + ' (' + role.permissions.length + ' permissions)',
          value: role.id,
          $isDisabled: !userCanSetRole(this.getCurrentUserHighestRoleLevel, role)
        };
      })
    },
  },
}
</script>