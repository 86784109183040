import Vue from "vue";

Vue.filter('convertTextOperatorToIcon', function (textOperator) {
  switch (textOperator) {
    case "biggerThan":
      return "greater-than";
    case "smallerThan":
      return "less-than";
    case "equals":
      return "equals";
    case "contains":
      return "box";
    case "notEqualTo":
      return "not-equal";
    default:
      return "";
  }
});