<template>
  <product-stock-history-table
    ref="productStockHistory"
  />
</template>

<script>
import ProductStockHistoryTable from "@/components/pages/Products/Stock/ProductStockHistoryTable.vue";

export default {
  name: "GlobalStockHistoryPage",
  components: {ProductStockHistoryTable}
}
</script>

<style scoped>

</style>