<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :enable-search="false"
    :label="label"
    :options="getFloorOptions"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "FloorSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Étage',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    allowUnselecting: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getFloorOptions() {
      let oneToTwenty = [...Array(21).keys()].map(o => {
        if (o === 0) {
          return {label: "Rez-de-chaussée", value: "ground"};
        }
        return {label: o, value: `${o}`};
      });
      return [
        {label: "Maison", value: 'house'},
        {label: "Réception", value: 'reception'},
        ...oneToTwenty,
        {label: '-1', value: "-1"},
        {label: '-2', value: "-2"},
        {label: '-3', value: "-3"},
        {label: '-4', value: "-4"},
        {label: '-5', value: "-5"},
        {label: '-6', value: "-6"},
      ]
    }
  },
}
</script>

