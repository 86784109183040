<template>
  <div>

    <div class="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 xl:gap-2 auto-cols-min">
      <dynamic-report-query-graph-item
        v-for="graphDataSet in getGraphData"
        :id="graphDataSet.id"
        :key="graphDataSet.id"
        :graph-config="graphDataSet.graphConfig"
        :name="graphDataSet.name"
      />
    </div>
  </div>
</template>

<script>
import DynamicReportQueryGraphItem
  from "@/components/pages/Landing/DynamicReportQueryGraphs/Item/DynamicReportQueryGraphItem.vue";

export default {
  name: "DynamicReportQueryGraphsList",
  components: {DynamicReportQueryGraphItem},
  created() {
    this.$store.dispatch("reportQueryGraphs/fetchGraphs");
  },
  computed: {
    getGraphData() {
      return this.$store.getters["reportQueryGraphs/getGraphIdsToLoad"];
    }
  },
}
</script>