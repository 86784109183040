<template>
    <custom-table :headings="getTableHeadings" identifier="bookingAccountReportTable">
        <table-row
                v-for="data in dataSet"
                :key="data.key"
        >
            <table-data-cell class="w-36">{{ data.bookingAccount }}</table-data-cell>
            <table-data-cell class="w-72">{{ data.bookingAccountName }}</table-data-cell>
            <table-data-cell class="w-36">{{ data.vatCode }}</table-data-cell>
            <table-data-cell class="w-36">{{ data.vatRate }}%</table-data-cell>
            <table-data-cell class="w-72">{{ centsToEuroString(data.totalVat) }}</table-data-cell>
            <table-data-cell>{{ centsToEuroString(data.totalPriceWithVat) }}</table-data-cell>
            <table-data-cell>{{ centsToEuroString(data.totalPriceWithoutVat) }}</table-data-cell>
        </table-row>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/elements/tables/CustomTable.vue";
import TableRow from "@/components/elements/tables/TableRow.vue";
import TableDataCell from "@/components/elements/tables/TableDataCell.vue";
import {centsToEuroString} from "@/helpers/mathHelper";

export default {
    name: "BookingAccountReportTable",
    methods: {centsToEuroString},
    components: {TableDataCell, TableRow, CustomTable},
    props: {
        dataSet: {
            type: [Object, Array],
            required: true
        }
    },
    computed: {
        getTableHeadings() {
            return [
                {label: 'Compte Booking'},
                {label: 'Nom Compte'},
                {label: 'Code TVA'},
                {label: 'Taux de TVA'},
                {label: 'Total VAT'},
                {label: 'TTC '},
                {label: 'Total HTVA'},
            ];
        }
    },
}
</script>

<style scoped>

</style>