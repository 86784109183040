<template>
  <!--
      !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

      Beware before changing left-X or right-X values!!
      They might seem weird on recent browser but work perfectly on v69
      of chrome which is used in Luxcaddy App on Zebra TC25.

      Test with chrome v69 after changing!
      Download here: https://vikyd.github.io/download-chromium-history-version/#/

      !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  -->
  <div class="h-screen w-screen-100 overflow-hidden relative">
    <VueSignaturePad
      ref="signaturePad"
      :custom-style="{'background-color': 'white', height: '100%', width: '100%'}"
    />

    <!-- Go Back Button -->
    <div
      class="absolute top-0 right-4 bg-red-300 pl-2 pr-2 py-4 rounded-bl-xl"
      style="writing-mode: vertical-rl;"
      @click="redirectToDeliveryOverview"
    >
      <fa-icon
        class="text-xl transform rotate-90"
        fixed-width
        icon="times"
      />
    </div>


    <!-- Checkmark button -->
    <div
      class="absolute bottom-0 right-4 bg-luxcaddy-300 pl-2 pr-2 py-4 rounded-tl-xl"
      style="writing-mode: vertical-rl;"
      @click="markDeliveryAsDelivered"
    >
      <fa-icon
        class="text-xl transform rotate-90"
        fixed-width
        icon="check"
      />
    </div>

    <!-- Deposits -->
    <div
      class="absolute z-10 -left-2.5 top-0 h-full bg-gray-800 text-white pr-1 pl-1.5 pt-2 leading-tight"
      style="writing-mode: vertical-rl;"
    >
            <span class="truncate font-bold text-lg text-luxcaddy">
                Consignes retournées:
            </span>

      <span
        v-if="getCustomerDeposits.length === 0"
        class="italic text-lg"
      >
                    Pas de consigne retournée au livreur
                </span>

      <span
        v-for="(deposit, i) in getCustomerDeposits"
        :key="deposit.id"
      >
                    <span class="font-bold"> {{ deposit.quantity }}x</span> {{ deposit.localizedName }}
                    <span v-if="i !== getCustomerDeposits.length - 1">&middot;</span>
                </span>
    </div>
  </div>
</template>

<script>
// Todo add check, if no delivery data set -> user refreshed so we throw an error.
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DriverMyRouteDeliveryDetailSignaturePage",
  created() {
    // Redirect back if user refreshed page.
    // if(!this.getIsAllowedOnSignaturePage) {
    //     this.redirectToDeliveryOverview();
    //     return;
    // }


    // Hide Top and Bottom Bar of Layout
    this.$store.commit('touchInterface/ui/setDisplayBars', false);
  },
  beforeDestroy() {
    // Show Top and Bottom Bar of Layout
    this.$store.commit('touchInterface/ui/setDisplayBars', true);
  },
  computed: {
    ...mapGetters('touchInterface/drivers/deliveries', [
      'getDeposits',
      'getSelectedDepositsWithQuantity',
      'getIsAllowedOnSignaturePage'
    ]),
    getCustomerDeposits() {
      return this.getDeposits.map(d => {
        let quantity = this.getSelectedDepositsWithQuantity.find(dq => dq.id === d.id)?.quantity;
        if (quantity === undefined) {
          return d;
        }

        return {...d, name: d.localizedName, quantity}
      }).filter(d => d.quantity !== undefined && d.quantity !== 0);
    },
  },
  methods: {
    ...mapActions('touchInterface/drivers/deliveries', [
      'markDelivered',
      'clearStore'
    ]),
    markDeliveryAsDelivered() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$showErrorDialog("Le client n'a pas encore signé.");
        return;
      }

      // Strip " data:image/png;base64," at the beginning.
      const signature = data.split(',')[1];

      this.markDelivered({
        id: this.$route.params.delivery,
        customerPresent: true,
        signature: signature
      }).then(() => {
        this.$sendSuccessToast("Livraison marqué comme delivré!");
        this.clearStore();

        this.$nextTick(() => {
          // Redirect back to the list of deliveries of the same route.
          this.$router.push({
            name: 'touchInterface.delivery.fulfilment.myroutes.route.index',
            params: {
              route: this.$route.params.route
            }
          });
        });
      });
    },

    redirectToDeliveryOverview() {
      this.$router.push({
        name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.items.mark.index',
        params: {
          route: this.$route.params.route,
          delivery: this.$route.params.delivery
        }
      });
    }
  },
}
</script>