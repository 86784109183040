<template>
  <div>
    <list-table
      ref="table"
      :batch-actions="getBatchActions"
      :columns="columns"
      :create-button="false"
      :pagination="false"
      :select-values="selectValues"
      :selectable="enableBatch"
      :sent-table-rows="orders"
      :show-refresh-button="false"

      disable-clear-batch-errors-on-mount
      identifier="productPackagingOrderTable"
      sub-key="today"
      @loaded="onTableRowsLoaded"

      @batch-executed="onBatchExecuted"
    >
      <template #title>
        <div class="flex space-x-2 text-center items-center font-bold">
          <div class="text-lg w-14 py-0.5 bg-luxcaddy-400 shadow text-white rounded">
            {{ amount }}
          </div>
          <div class="text-gray-500">{{ title }}</div>
        </div>
      </template>

      <template v-slot:status-template="{ row }">
        <order-status-indicator
          :status="row.orderStatus"
        />
      </template>

      <template v-slot:order-id-template="{row}">
        <order-id-link
          :order-id="row.orderId"
        />
      </template>

      <template v-slot:member-id-template="{row}">
        <member-quick-action-modal
          :id="row.memberId"
        />
      </template>

      <template v-slot:fulfilment-number-template="{row}">
        <fulfilment-number-display
          :fulfilment-number="row.fulfillmentNumber"
          :has-delivery="row.timeslot.startDate !== null && row.timeslot.endDate !== null"
        />
      </template>

      <template v-slot:fulfilment-status-template="{row}">
        <order-fulfilment-status-indicator
          :fulfilment-status="row.fulfilmentStatus"
        />
      </template>

      <template v-slot:timeslot-template="{row}">
        <time-slot-as-string
          :from="row.timeslot.startDate"
          :to="row.timeslot.endDate"
          :zone="row.timeslot?.zoneId"
        />
      </template>

    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import OrderStatusIndicator from "@/components/pages/Products/PackagingInfo/Status/OrderStatusIndicator";
import OrderIdLink from "@/components/pages/Orders/OrderIdLink";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";
import TimeSlotAsString from "@/components/global/TimeSlots/TimeSlotAsString";
import OrderRepository from "@/repositories/OrderRepository";
import OrderFulfilmentStatusIndicator
  from "@/components/pages/Orders/EditOrder/Info/OrderFulfilmentStatusIndicator.vue";

export default {
  name: "ProductPackagingOrderTable",
  components: {
    OrderFulfilmentStatusIndicator,
    TimeSlotAsString,
    FulfilmentNumberDisplay,
    MemberQuickActionModal,
    OrderIdLink,
    OrderStatusIndicator,
    ListTable
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    orders: {
      type: Array,
      required: true,
    },
    enableBatch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      amount: 0,

      selectValues: product => {
        return {
          orderId: product.orderId
        };
      },

      columns: [
        {
          caption: '# Com.',
          cellTemplate: 'order-id-template'
        },
        {
          caption: '# Mem.',
          dataField: 'memberId',
          cellTemplate: 'member-id-template'
        },
        {
          caption: 'Client',
          dataField: 'name'
        },
        {
          caption: 'Bac',
          cellTemplate: 'fulfilment-number-template'
        },
        {
          caption: 'à emballer',
          dataField: 'toBePackedQuantity'
        },
        {
          caption: 'emballé',
          dataField: 'packedQuantity'
        },
        {
          caption: 'Status',
          cellTemplate: 'status-template'
        },
        {
          caption: 'Préparation',
          cellTemplate: 'fulfilment-status-template'
        },
        {
          caption: 'Date',
          calculateCellValue: (r) => {
            return r.orderDate === null
              ? ""
              : this.$date(r.orderDate).format('DD.MM.YYYY HH:mm');
          },
        },
        {
          caption: 'Timeslot',
          cellTemplate: 'timeslot-template'
        },

      ]
    };
  },
  methods: {
    onTableRowsLoaded(rows) {
      this.amount = rows.reduce((n, {toBePackedQuantity}) => n + toBePackedQuantity, 0);
    },
    onBatchExecuted() {
      this.$emit('batch-executed');
    }
  },
  computed: {
    getBatchActions() {
      if (!this.enableBatch) {
        return [];
      }

      return [
        {
          label: "Substituer selection par autre produit",
          action: (callBack, selectedRows, additionalParams) => {
            return OrderRepository
              .batchActions
              .substituteProductByProduct(selectedRows, {
                productToBeSubstituted: this.productId,
                substituteByProduct: additionalParams.substituteByProduct
              })
              .finally(() => callBack());
          },
          additionalFields: [
            {
              label: "Substituer par produit",
              type: "productId",
              param: "substituteByProduct",
              validationRules: 'required'
            },
          ],
        },
        {
          label: "Substituer selection par une libellé libre",
          action: (callBack, selectedRows, additionalParams) => {
            return OrderRepository
              .batchActions
              .substituteProductByCustomItem(selectedRows, {
                productToBeSubstituted: this.productId,
                name: additionalParams.name,
                price: additionalParams.price,
                vat: additionalParams.vat,
              })
              .finally(() => callBack());
          },
          additionalFields: [
            {
              label: "Nom / Libellé libre",
              type: "text",
              param: "name",
              validationRules: 'required'
            },
            {
              label: "Prix",
              type: "currency",
              param: "price",
              validationRules: 'required'
            },
            {
              label: "Taux de TVA",
              type: "vat",
              param: "vat",
              validationRules: 'required'
            },
          ],
        },
        {
          label: "Mettre tout sur zero",
          action: (callBack, selectedRows) => {
            return OrderRepository
              .batchActions
              .setProductQuantityToZero(selectedRows, {
                productToBeSetToZero: this.productId,
              })
              .finally(() => callBack());
          },
        },
      ];
    }
  },
}
</script>

<style scoped>

</style>