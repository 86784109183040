<template>
  <div>
    <page-title>Créer facture</page-title>

    <card>
      <template v-if="!isTreatingInvoicePDF">
        <h3 class="font-bold mb-2">
          Type de facture
        </h3>

        <div class="flex gap-x-4 mb-6">
          <div
            :class="[invoiceType === 'external' ? 'border-luxcaddy-500 bg-luxcaddy-300': 'hover:bg-luxcaddy-200']"
            class="bg-luxcaddy-100 rounded-md p-16 cursor-pointer border-2 border-dashed border-transparent"
            @click="invoiceType = 'external'"
          >
            Externe
          </div>
          <div
            :class="[invoiceType === 'internal' ? 'border-luxcaddy-500 bg-luxcaddy-300': 'hover:bg-luxcaddy-200']"
            class="bg-luxcaddy-100 rounded-md p-16 cursor-pointer border-2 border-dashed border-transparent"
            @click="invoiceType = 'internal'"
          >
            Interne
          </div>
        </div>
      </template>

      <template v-if="invoiceType === 'internal'">
        <shared-create-internal-invoice
          @created="onInvoiceCreate"
        />
      </template>
      <template v-else-if="invoiceType === 'external'">
        <shared-create-external-invoice
          @created="onInvoiceCreate"
          @created-with-pdf="onInvoiceCreateWithPdf"
        />
      </template>
    </card>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle";
import SharedCreateInternalInvoice from "@/components/pages/Invoices/Shared/CreateInvoice/SharedCreateInternalInvoice";
import SharedCreateExternalInvoice from "@/components/pages/Invoices/Shared/CreateInvoice/SharedCreateExternalInvoice";

export default {
  name: "CreateInvoice",
  components: {SharedCreateExternalInvoice, SharedCreateInternalInvoice, PageTitle, Card},
  data: () => ({
    invoiceType: 'external'
  }),
  methods: {
    onInvoiceCreate(invoiceId) {
      this.$sendSuccessToast("Facture crée!");

      this.$router.push({
        name: 'accounting.invoices.edit',
        params: {
          id: invoiceId
        }
      });
    },
    onInvoiceCreateWithPdf({invoiceId, pdfState}) {
      this.$sendSuccessToast("Facture crée!");

      this.$router.push({
        name: 'accounting.invoices.edit',
        params: {
          id: invoiceId
        },
        query: {
          pdfZoom: pdfState.zoom,
          pdfPage: pdfState.page,
          pdfScrollTop: pdfState.scrollTop,
          pdfScrollLeft: pdfState.scrollLeft
        }
      });
    }
  },
  computed: {
    isTreatingInvoicePDF() {
      return this.$store.getters["invoices/isTreatingInvoicePDF"];
    },
  },
}
</script>