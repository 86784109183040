import CategoryRepository from "../../../repositories/CategoryRepository";


const flattenCategories = (categories) => {
  return categories.reduce((acc, c) => {
    if (c.subCategories && c.subCategories.length) {
      acc = acc.concat(flattenCategories(c.subCategories));
    }
    acc.push(c);
    return acc;
  }, []);
};


const filter = (array, fn) => {
  return array.reduce((r, o) => {
    let children = filter(o.children || [], fn);
    if (fn(o) || children.length) r.push(Object.assign({}, o, children.length && {children}));
    return r;
  }, []);
}


const getDefaultState = () => {
  return {
    currentCategoryType: null,
    currentCategoryIsVirtual: false,

    categories: [],
    expandedCategories: [],
    hideDisabledCategories: true,

    activeCategory: null,
    selectedProducts: [],

    selectedBlogPosts: [],

    selectedCategoryInSelectionTree: null,
    enableDrag: false,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getCategories: (state) => {
      if (state.hideDisabledCategories) {
        return filter(state.categories, (cat) => cat.isPublished);
      }
      return state.categories;
    },
    getDisabledCategoryCount: state => {
      return state.categories.filter(c => !c.isPublished).length;
    },
    getAllCategories: (state) => {
      return state.categories;
    },
    getFlatCategories: state => flattenCategories(state.categories),
    getExpandedCategories: state => state.expandedCategories,
    getSelectedCategoryInSelectionTree: state => state.selectedCategoryInSelectionTree,
    getActiveCategory: state => state.activeCategory,
    getSelectedProducts: state => state.selectedProducts,
    getSelectedBlogPosts: state => state.selectedBlogPosts,
    getEnableDrag: state => state.enableDrag,
    getCurrentCategoryType: state => state.currentCategoryType,
    getCurrentCategoryIsVirtual: state => state.currentCategoryIsVirtual,
  },
  mutations: {
    clearCategories(state) {
      state.currentCategoryType = null;
      state.categories = [];
      state.expandedCategories = [];
      state.selectedCategoryInSelectionTree = null;
      state.activeCategory = null;
    },

    setCurrentCategoryType(state, type) {
      state.currentCategoryType = type;
    },

    setCurrentCategoryIsVirtual(state, isVirtual) {
      state.currentCategoryIsVirtual = isVirtual;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setHideDisabledCategories(state, bool) {
      state.hideDisabledCategories = bool;
    },
    toggleExpanded(state, categoryId) {
      if (state.expandedCategories.includes(categoryId)) {
        state.expandedCategories = state.expandedCategories.filter(catId => catId !== categoryId);
      } else {
        state.expandedCategories.push(categoryId);
      }
    },
    expandAll(state) {
      state.expandedCategories = flattenCategories(state.categories).map(c => c.id);
    },
    expandNone(state) {
      state.expandedCategories = [];
    },
    setActiveCategory(state, category) {
      state.activeCategory = category;
    },
    clearSelectedProducts(state) {
      state.selectedProducts = [];
    },
    setActiveCategoryItems(state, items) {
      state.activeCategory.items = items;
    },
    toggleSelectedProduct(state, product) {
      if (state.selectedProducts.flatMap(p => p.id).includes(product.id)) {
        state.selectedProducts = state.selectedProducts.filter(p => p.id !== product.id);
      } else {
        state.selectedProducts.push(product);
      }
    },

    toggleSelectedBlogPost(state, post) {
      if (state.selectedBlogPosts.flatMap(p => p.id).includes(post.id)) {
        state.selectedBlogPosts = state.selectedBlogPosts.filter(p => p.id !== post.id);
      } else {
        state.selectedBlogPosts.push(post);
      }
    },

    setSelectedCategoryInSelectionTree(state, category) {
      state.selectedCategoryInSelectionTree = category;
    },
    setSelectedProducts(state, products) {
      state.selectedProducts = products;
    },


    setSelectedBlogPosts(state, blogPosts) {
      state.selectedBlogPosts = blogPosts;
    },

    setEnableDrag(state, bool) {
      state.enableDrag = bool;
    },
    /**
     * Updates the sort of parentIds children.
     * @param state
     * @param parentId
     * @param categoryIdsSorted
     */
    changeChildrenSort(state, {parentId, categoryIdsSorted}) {
      let flatCategories = flattenCategories(state.categories);
      let category = flatCategories.find(c => c.id === parentId);

      category.subCategories = category.subCategories.sort((a, b) => {
        return categoryIdsSorted.indexOf(a.id) - categoryIdsSorted.indexOf(b.id);
      });
    },

    /**
     * Updates the sort of parentIds children.
     * @param state
     * @param parentId
     * @param categoryIdsSorted
     */
    changeRootSort(state, categoryIdsSorted) {
      state.categories = state.categories.sort((a, b) => {
        return categoryIdsSorted.indexOf(a.id) - categoryIdsSorted.indexOf(b.id);
      });
    }
  },
  actions: {
    getAllCategories({commit}, {type, isVirtual = false}) {
      return CategoryRepository.get(type, isVirtual).then((res) => {
        commit('setCategories', res.data.data);
        commit('setCurrentCategoryType', type);
        commit('setCurrentCategoryIsVirtual', isVirtual);
      });
    },
    refetchCategoriesForCurrentType({getters, commit}) {
      if (!getters.getCurrentCategoryType) {
        console.error("CALLED refetchCategoriesForCurrentType, BUT NO TYPE SET")
        return false;
      }
      return CategoryRepository.get(getters.getCurrentCategoryType, getters.getCurrentCategoryIsVirtual).then((res) => {
        commit('setCategories', res.data.data);
      });
    },

    updateChildrenSort({dispatch, commit}, {parentId, categoryIdsSorted}) {
      // First updates sort locally
      commit('changeChildrenSort', {
        parentId,
        categoryIdsSorted,
      });

      // Then push sort to backend to avoid elements jumping around.
      return CategoryRepository.setChildCategories(parentId, categoryIdsSorted).then(() => {
        return dispatch('refetchCategoriesForCurrentType');
      })
    },
    updateRootSort({dispatch}, {type, categoryIdsSorted, isVirtual = false}) {
      // Then push sort to backend to avoid elements jumping around.
      return CategoryRepository.setRootCategories(type, categoryIdsSorted, isVirtual).then(() => {
        return dispatch('refetchCategoriesForCurrentType');
      })
    }
  },
}