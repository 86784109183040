<template>
  <shared-edit-invoice
    :invoice-id="$route.params.id"
    is-internal
  />
</template>

<script>

import SharedEditInvoice from "@/components/pages/Invoices/Shared/EditInvoice/SharedEditInvoice";

export default {
  name: "EditInternalInvoice",
  components: {SharedEditInvoice}
}
</script>

<style scoped>

</style>