<template>
  <div class="p-1.5 sm:p-4">
    <touch-interface-card-with-loading-indicator
      :loading="loading"
      color="white"
      disable-padding
    >
      <table class="table-fixed border-collapse">
        <thead class="text-sm text-left">
        <tr class="bg-gray-200">
          <th class="px-1 whitespace-nowrap w-16 text-xs border border-gray-400">PrID</th>
          <th class="px-1 whitespace-nowrap w-1/2 border border-gray-400">Produit</th>
          <th class="px-1 whitespace-nowrap w-12 text-xs border border-gray-400">Qté</th>
          <th class="px-1 whitespace-nowrap border border-gray-400">Prix ttc</th>
          <th class="px-1 whitespace-nowrap border border-gray-400">Soustotal</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in getOrderItems"
          :key="item.id"
          class="text-sm"
        >
          <td class="border border-gray-400">{{ item.orderableId }}</td>
          <td class="border border-gray-400">{{ item.name }}</td>
          <td class="border border-gray-400 whitespace-nowrap px-1">{{ item.packedQuantity }}</td>
          <td class="border border-gray-400 whitespace-nowrap px-1">
            <money-amount
              :amount="item.unitPrice.amount"
            />
          </td>
          <td class="border border-gray-400 whitespace-nowrap px-1">
            <money-amount
              :amount="item.price.amount"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </touch-interface-card-with-loading-indicator>
  </div>
</template>

<script>
import OrderRepository from "@/repositories/OrderRepository";
import TouchInterfaceCardWithLoadingIndicator
  from "@/components/pages/TouchInterface/Shared/TouchInterfaceCardWithLoadingIndicator";
import MoneyAmount from "@/components/global/Money/MoneyAmount";

export default {
  name: "DriverMyRouteDeliveryDetailOrderedProductsPage",
  components: {MoneyAmount, TouchInterfaceCardWithLoadingIndicator},
  data: () => ({
    loading: false,

    order: null,
  }),
  created() {
    this.fetchOrder();
  },
  methods: {
    fetchOrder() {
      this.loading = true;
      OrderRepository.getSingle(
        this.$route.params.order
      ).then((res) => {
        this.$set(this, 'order', res.data.data);
      }).finally(() => this.loading = false);
    }
  },
  computed: {
    getOrderItems() {
      if (this.order === null) {
        return [];
      }

      return this.order.items;
    }
  },
}
</script>

<style scoped>

</style>