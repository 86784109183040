<template>
  <table-row :class="{'bg-red-100': !product.isLocked}">
    <table-data-cell
      v-if="isNormalProduct"
      class="w-0"
    >
      <div class="flex gap-x-4 items-center">
        <div
          v-if="isReference"
        >
                    <span
                      v-if="isReference"
                      class="ml-4"
                    >
                      ↪
                    </span>
        </div>
        <div class="flex md:flex-row items-center gap-x-2">
          <div>
            <product-quick-action-modal
              v-if="product.orderableId"
              :id="product.orderableId"
              class="text-black"
              @update="onStockChangeThroughProductQuickModal"
            />
          </div>
          <div>
            <fa-icon
              v-if="product.alreadyOrderedFromSupplier"
              icon="truck"
              title="Déjà commandé chez le fournisseur"
            />
          </div>
          <div>
            <fa-icon
              v-if="product.isFrozen"
              icon="snowflake"
              title="Frozen"
            />
          </div>
        </div>

      </div>
    </table-data-cell>

    <table-data-cell v-if="isNormalProduct">
      {{ product.sellingBundleContent }}{{ product.sellingBundleUnit }}
    </table-data-cell>

    <table-data-cell v-if="isReference && !isNormalProduct">
            <span
              v-if="isReference"
              class="ml-4"
            >
                ↪
            </span>
    </table-data-cell>

    <table-data-cell v-if="!isNormalProduct" :colspan="isReference ? 1 : 2" class="w-0">
      <div class="bg-gray-200 rounded px-2 inline-block whitespace-nowrap">
        {{ getBadgeTextByType }}
      </div>
    </table-data-cell>

    <table-data-cell class="whitespace-nowrap">
      <text-input-element
        v-if="isCustomProduct && !readOnly && isEditable"
        v-model="product.name"
        :occupy-validation-space="false"
        class="flex-1"
        @blur="updateValue('name', $event)"
      />
      <span v-else>{{ product.name }}</span>
    </table-data-cell>

    <table-data-cell class="w-0">
      <span>{{ product.orderedQuantity }}</span>
    </table-data-cell>

    <table-data-cell align="center" class="w-0">
      <quantity-selector-element
        v-if="!readOnly && isEditable"
        :delete-on-zero="false"
        :min="product.packedQuantity"
        :quantity.sync="product.toBePackedQuantity"
        :show-add-to-cart-button="false"
        size="small"
        type="light"
        @update:quantity="onQuantityChange('toBePacked', product.toBePackedQuantity)">
      </quantity-selector-element>

      <span v-else>{{ product.toBePackedQuantity }}</span>
    </table-data-cell>

    <table-data-cell align="center" class="w-0">
      <quantity-selector-element
        v-if="!readOnly && isEditable"
        :delete-on-zero="false"
        :max="product.toBePackedQuantity"
        :quantity.sync="product.packedQuantity"
        :show-add-to-cart-button="false"
        :type="product.packedQuantity === product.toBePackedQuantity ? 'primary' : 'warning'"
        size="small"
        @update:quantity="onQuantityChange('packed', product.packedQuantity)">
      </quantity-selector-element>

      <span v-else>{{ product.packedQuantity }}</span>
    </table-data-cell>

    <table-data-cell align="center" class="w-0">
      <template
        v-if="product.isVariableWeight"
      >
        <number-input-element
          v-if="isEditable"
          v-model="product.packedWeight"
          :occupy-validation-space="false"
          :placeholder="product.toBePackedWeight"
          class="w-28"
          inset-position="right"
          inset-text="g"
          @blur="updateValue('packedWeight', $event)"
        />
        <span
          v-else
        >
          {{ product.packedWeight }}
        </span>
      </template>
    </table-data-cell>

    <table-data-cell align="right" class="w-16 whitespace-nowrap">
      <currency-input-element
        v-if="!readOnly && isEditable"
        v-model.number="product.unitPrice.amountNoVat"
        :allow-negative="isDiscountOrCustomItem"
        :occupy-validation-space="false"
        @blur="updateValue('price', $event)"
      />

      <span v-else>
                <money-amount
                  :amount="product.unitPrice.amountNoVat"
                />
            </span>
    </table-data-cell>



    <table-data-cell align="center" class="whitespace-nowrap w-28">
      <vat-input-element
        v-if="!readOnly && isEditable"
        v-model="product.unitPrice.vat"
        :allow-unselecting="false"
        :occupy-validation-space="false"
        label=""
        @blur="updateValue('vat', $event)"
      />
      <span v-else>{{ product.unitPrice.vat | intToPercentageString }}</span>
    </table-data-cell>
    <table-data-cell align="right" class="w-8 whitespace-nowrap">
     <span v-if= "product.priceAddOn !== null">
       {{ product.priceAddOn | intToPercentageString }}
     </span>
    </table-data-cell>
    <table-data-cell align="right" class="w-0">
      <money-amount
        :amount="product.unitPriceWithAddOns.amount"
      />
    </table-data-cell>

    <table-data-cell align="right" class="w-0">
      <money-amount
        :amount="product.price.amount"
      />
    </table-data-cell>


    <table-data-cell class="text-right w-0">
      <button-with-menu-element :actions="actionLinks" button-icon="ellipsis-h" size="small"
                                type="dark"/>
    </table-data-cell>

    <delete-confirmation-modal
      ref="deleteConfirmationModal"
      title="Supprimer produit de la commande?"
      @delete="deleteProduct"
    />

    <!-- Model for substituting a product with another -->
    <order-item-add-reference-modal
      ref="itemAddReferenceModal"
      :quantity-to-add="product.toBePackedQuantity"
      :referenced-id="product.id"
    />
  </table-row>
</template>

<script>
import TableRow from "../../../../elements/tables/TableRow";
import TableDataCell from "../../../../elements/tables/TableDataCell";
import ProductQuickActionModal from "../../../../pages/Products/Subcomponents/ProductQuickActionModal";
import QuantitySelectorElement from "../../../../elements/forms/elements/QuantitySelectorElement";
import DeleteConfirmationModal from "../../../../elements/modals/DeleteConfirmationModal";
import OrderRepository from "../../../../../repositories/OrderRepository";
import {debounce} from "@/helpers/debounceHelper";
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";
import CurrencyInputElement from "../../../../elements/forms/elements/CurrencyInputElement";
import VatInputElement from "../../../../elements/forms/elements/VatInputElement.vue";
import ButtonWithMenuElement from "../../../../elements/buttons/ButtonWithMenuElement";
import OrderItemAddReferenceModal
  from "../../../../pages/Orders/EditOrder/SubstituteProduct/OrderItemAddReferenceModal";
import MoneyAmount from "@/components/global/Money/MoneyAmount";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement.vue";

export default {
  name: "OrderTableProductRow",
  components: {
    NumberInputElement,
    MoneyAmount,
    OrderItemAddReferenceModal,
    ButtonWithMenuElement,
    VatInputElement,
    CurrencyInputElement,
    TextInputElement,
    DeleteConfirmationModal,
    QuantitySelectorElement, ProductQuickActionModal, TableDataCell, TableRow
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    isReference: {
      type: Boolean,
      default: false
    },
    referencedById: {
      type: Number,
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.showPackedWeightPlaceholder();
  },
  watch: {
    getProductPackedWeight() {
      this.showPackedWeightPlaceholder()
    }
  },
  methods: {

    /**
     * Refreshes the order data from the backend
     */
    refreshOrder(showLoadingIndicator = false) {
      return this.$store.dispatch('order/fetchOrder', {orderId: this.getOrderId, showLoadingIndicator});
    },

    onStockChangeThroughProductQuickModal() {
      this.refreshOrder(true);
    },

    /**
     * Triggered whenever one of the quantity related values change.
     */
    onQuantityChange: debounce(function (param, quantity) {
      if (quantity === null || quantity === undefined) {
        quantity = 0;
      }

      return this.updateValue(param, quantity);
    }, 300),

    /**
     * Deleted this product from the order.
     *
     * @return {Promise<*>}
     */
    deleteProduct() {
      return OrderRepository
        .removeItem(this.getOrderId, this.product.id)
        .then(() =>
          this.refreshOrder()
            .then(() => this.$sendSuccessToast("Produit supprimé de la commande.")
            )
        );
    },

    /**
     * Returns the payload used to modify values of this product.
     * Also, before returning, one value is updated.
     *
     * Ex: payload.${valueToModify} = ${value};
     *
     * @param valueToModify
     * @param value
     * @return Object
     */
    getModifiedPayload(valueToModify, value) {
      let payload = {
        ...(this.isCustomProduct && {name: this.product.name}),
        price: this.product.unitPrice.amountNoVat,
        vat: this.product.price.vat,
        toBePackedQuantity: this.product.toBePackedQuantity,
        packedQuantity: this.product.packedQuantity,
        packedWeight: this.product.packedWeight,
      };
      payload[valueToModify] = value;

      if (this.referencedById !== undefined) {
        payload["reference"] = this.referencedById;
      }

      return payload;
    },

    /**
     * Updates a single value on the product.
     */
    updateValue(valueToModify, value, withLoadingIndicator = true, refreshOrderAfterwards = true) {
      return OrderRepository
        .editItem(
          this.getOrderId,
          this.product.id,
          this.getModifiedPayload(valueToModify, value),
          withLoadingIndicator,
          refreshOrderAfterwards
        );
    },

    openDeleteConfirmationModal() {
      this.$refs.deleteConfirmationModal.openModal();
    },

    startReferencingItem(substituteReferencedItem = false) {
      this.$store.commit('order/setReferencedItemId', {
        id: this.product.id,
        substituteReferencedItem: substituteReferencedItem,
        referencedItemQuantity: this.product.toBePackedQuantity,
      });
      this.$refs.itemAddReferenceModal.openModal();
    },

    showPackedWeightPlaceholder() {
      // Set to null if its 0 (not set) to show placeholder
      if (this.product.isVariableWeight && this.product.packedWeight === 0) {
        this.product.packedWeight = null;
      }
    }
  },
  computed: {
    actionLinks() {
      let links = [];

      if (this.isEditable && !this.$isReadOnly()) {
        links = [
          {
            label: 'Supprimer',
            icon: 'trash-alt',
            action: () => {
              this.openDeleteConfirmationModal();
            }
          },
          {
            label: 'Référencer article',
            icon: 'exchange-alt',
            action: () => {
              this.startReferencingItem(false);
            },
            disabled: this.isReference || !this.isNormalProduct // References can not be referred to again - only for products
          },
          {
            label: 'Substituer article',
            icon: 'exchange-alt',
            action: () => {
              this.startReferencingItem(true);
            },
            disabled: this.isReference || !this.isNormalProduct || this.product.packedQuantity > 0 // References can not be referred to again - only for products
          },
        ];
      }

      links.push({
        label: "Afficher dans logs",
        icon: 'file-alt',
        action: () => {
          this.$router.push({
            name: 'orders.edit',
            params: {order: this.getOrderId},
            query: {tab: 'orderLogs', filter_itemIdDataProperty: this.product.id}
          });
        }
      });

      return links;
    },

    isNormalProduct() {
      return this.product.type.toLowerCase() === "product";
    },
    isCustomProduct() {
      return this.product.type.toLowerCase() === "custom";
    },
    isFee() {
      return this.product.type.toLowerCase() === "fee";
    },

    getBadgeTextByType() {
      switch (this.product.type.toLowerCase()) {
        case "custom":
          return "Libellé libre";
        case "fee":
          return "Frais";
        case "gift":
          return "Cadeaux";
        case "fixed":
          return "Remise fixe";
        case "discount":
          return "Remise";
        case "package":
          return "Emballage";
        case "weightmargin":
          return "Marge sur poids";
      }
      return "";
    },

    isDiscountOrCustomItem() {
      const productType = this.product.type.toLowerCase();
      return productType === "discount" || productType === "custom" || productType === "fixed";
    },

    getOrderId() {
      return this.$store.getters['order/getOrderId'];
    },

    getProductPackedWeight() {
      return this.product.packedWeight;
    },

    readOnly() {
      return this.$isReadOnly();
    }
  }
}
</script>

