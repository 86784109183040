import apiService from "@/repositories/Base/BaseRepository";

const url = '/reportQueries';

export default {
  async loadAll() {
    return await this.getPaginated(999, 1);
  },

  async getSingle(reportQueryId, withLoadingIndicator = true) {
    return await apiService
      .get(`${url}/${reportQueryId}`, {
        showLoadingIndicator: withLoadingIndicator
      });
  },

  async getPaginated(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0,
    showLoadingIndicator = true
  ) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`, {
        showLoadingIndicator
      });
  },

  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  async update(reportQueryId, payload) {
    return await apiService.patch(`${url}/${reportQueryId}`, payload);
  },

  async delete(reportQueryId) {
    return await apiService.delete(`${url}/${reportQueryId}`);
  },

  async exportQuery(
    type = "xlsx",
    reportQueryId,
    values,
    exportOptions = []
  ) {
    if (type === "csv") {
      return await apiService
        .downloadBlobPost(`${url}/${reportQueryId}/export-csv`, {
          placeholders: values,
          options: exportOptions
        });
    }

    return await apiService
      .downloadBlobPost(`${url}/${reportQueryId}/export`, {
        placeholders: values,
      });
  },

  async executeQuery(
    reportQueryId,
    values,
    perPage = 20,
    page = 1
  ) {
    return await apiService
      .setPagination(perPage, page, 0)
      .post(`${url}/${reportQueryId}/execute`, {
        placeholders: values,
      });
  },
  async executeQueryAsync(
    reportQueryId,
    values,
    fileFormat,
    options
  ) {
    return await apiService
      .post(`${url}/${reportQueryId}/executeAsync`, {
        placeholders: values,
        fileFormat: fileFormat,
        options: options
      });
  },

  graphs: {
    async getIdsForGraphs(withLoadingIndicator = true) {
      return await apiService
        .get(`${url}/ids-for-graphs`, {
          showLoadingIndicator: withLoadingIndicator
        });
    },

    async getGraphDataById(id) {
      return await apiService
        .get(`${url}/graph-data/${id}`, {
          showLoadingIndicator: false
        });
    }
  }
}
