<template>
  <div class="bg-white p-3 border-2 border-red-500 shadow-xl rounded-md flex flex-col gap-y-3">
    <div class="text-red-500 font-bold">
      Livraisons sans géocodage précis ou sans zone:
    </div>

    <div class="mt-8">
      <div
        v-for="delivery in getInvalidDeliveries"
        :key="delivery.id"
        class="flex gap-x-2"
      >
        <div v-html="getFormattedAddress(delivery)"></div>
        <div>
          <router-link
            :to="{name: 'deliveries.edit', params: {deliveryId: delivery.id}}"
            class="text-red-700 hover:text-red-800 text-md group"
            target="_blank"
          >
            <fa-icon fixed-width icon="edit"></fa-icon>&nbsp;
            <span class="group-hover:underline">Modifier livraison</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "RouteManagerGeocodeWarningBox",
  computed: {
    ...mapGetters('routeManager', ['getInvalidDeliveries']),
  },
  methods: {
    getFormattedAddress(delivery) {
      let dest = delivery.destination;

      return `<span class="font-bold">${dest.fullName}</span>, ${dest.street} ${dest.city}`;
    },
  },
}
</script>

<style scoped>

</style>