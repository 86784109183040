<template>
  <div>
    <page-title>Ventes globales</page-title>

    <order-item-stats-graph
      :default-display-option="2"
      :graph-end="getGraphEnd"
      :graph-start="getGraphStart"
      groupBy="month"
    />
  </div>
</template>
<script>
import OrderItemStatsGraph from "../../global/Graphs/OrderItemStatsGraph";
import PageTitle from "../../elements/pages/PageTitle";

export default {
  components: {PageTitle, OrderItemStatsGraph},
  computed: {
    getGraphStart() {
      return new Date(new Date().getFullYear(), 0, 1);
    },
    getGraphEnd() {
      let date = (new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
      date.setHours(23, 59, 59, 999);
      return date;
    }
  },
}
</script>