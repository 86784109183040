<template>
  <div>
    <barcode-time-clock-handler
      class="mt-2"
      used-in-touch-interface
    />
  </div>
</template>

<script>
import BarcodeTimeClockHandler from "@/components/pages/Auth/sub/BarcodeTimeClockHandler.vue";

export default {
  name: "TimeClockPage",
  components: {BarcodeTimeClockHandler}
}
</script>

<style scoped>

</style>