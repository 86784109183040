<template>
  <list-table
    :columns="columns"
    :create-route="{
            name: 'task.create'
        }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="tasksTable"
  >
    <template v-slot:task-status-template="{row}">
      <task-status
        :status="row.status"
        small
      />
    </template>
  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import TaskRepository from "@/repositories/TaskRepository";
import MemberRepository from "@/repositories/MemberRepository";
import TaskStatus from "@/components/pages/Tasks/Includes/TaskStatus";
import {
  FILTER_DATETIME_RANGE,
  FILTER_MEMBER_EMPLOYEES,
  FILTER_SELECT_SINGLE
} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowTasks",
  components: {TaskStatus, ListTable},
  created() {
    this.getAllEmployees();
  },
  data() {
    return {
      employees: [],

      title: 'Tâches',
      repositoryFunc: TaskRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          showInColumnChooser: false,
          allowReordering: false,
          buttons: [
            {
              type: 'show',
              hint: 'Voir tâche',
              route: (row) => ({
                name: 'task.edit',
                params: {
                  task: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier tâche',
              route: (row) => ({
                name: 'task.edit',
                params: {
                  task: row.id,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer tâche',
              confirmation: {
                title: 'Supprimer tâche?',
                text: 'Étes vous sure que vous voulez supprimer cette tâche?'
              },
              successText: 'La tâche a été supprimée',
              deleteAction: (row) => TaskRepository.deleteSingle(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: 'Status',
          cellTemplate: 'task-status-template',
          sort: {
            dataField: 'status'
          }
        },
        {
          caption: 'Titre',
          dataField: 'title',
          sort: {
            dataField: 'title'
          }
        },
        {
          caption: 'Date début',
          calculateCellValue: row => this.$date(row.startDate).format('DD.MM.YYYY'),
          sort: {
            dataField: 'startDate',
            default: 'desc'
          }
        },
        {
          caption: 'Date fin',
          calculateCellValue: row => this.$date(row.endDate).format('DD.MM.YYYY')
        },
        {
          caption: 'Pour',
          calculateCellValue: row => this.getEmployeeNameById(row.assignedToMemberId) ?? 'N\'importe'
        },
        {
          caption: 'Fait par',
          calculateCellValue: row => this.getEmployeeNameById(row.executedByMemberId) ?? 'N\'importe'
        },

      ],
      filters: [
        {
          caption: "Titre",
          filterKey: "title",
          forceIsVisible: true,
        },
        {
          filterKey: 'startDateBetween',
          caption: "Date début entre",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true,
        },
        {
          caption: "Status",
          filterKey: "status",
          type: FILTER_SELECT_SINGLE,
          filterOptions: [
            {label: 'Dû', value: 'due'},
            {label: 'Suspend', value: 'suspended'},
            {label: 'Fait', value: 'done'},
          ],
          forceIsVisible: true,
        },
        {
          caption: "Employé assigné",
          filterKey: "assignedToMemberId",
          type: FILTER_MEMBER_EMPLOYEES,
        },
        {
          caption: "Fait par",
          filterKey: "executedByMemberId",
          type: FILTER_MEMBER_EMPLOYEES,
        },
      ]
    }
  },
  methods: {
    getEmployeeNameById(employeeId) {
      return this.employees.find(e => e.id === employeeId)?.fullName;
    },
    getAllEmployees() {
      MemberRepository.getAllEmployees().then((res) => {
        this.employees = res.data.data;
      });
    },
  },
}
</script>

<style scoped>

</style>