<template>
  <!--
    This component is used to delete a comment. It is used in the CommentBox.vue component.
    It is a modal that asks the user to confirm the deletion of the comment.
  -->
  <div>
    <button-element
      :loading="isLoading"
      size="xsmall"
      type="danger"
      @click="deleteComment"
    >
      Oui, Supprimer
    </button-element>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "CommentBoxDelete",
  components: {ButtonElement},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    deleteComment() {
      this.isLoading = true;
      this.$store.dispatch('comments/deleteComment')
        .finally(() => this.isLoading = false);
    }
  },
}
</script>