<template>
  <modal ref="markDeliveryAsDelivered" :show-close-button="true" title="Marquer comme livré" @close="onCloseModal">
    <template>

      <member-select-input
        v-model="deliveredBy"
        :only-member-types="['employee']"
        label="Livreur"
        only-active-member-types
        validation-rules="required"
      />


      <date-picker-element
        v-model="deliveredAt"
        label="Livré à"
        validation-rules="required"
        with-time

      />

      <select-element
        v-model="wasCustomerPresent"
        :enable-search="false"
        :options="getClientTypeOptions"
        label="Présence du client"
      />
    </template>

    <template #footer>
      <div class="flex flex-row-reverse mt-4">
        <button-element :disabled="!canMarkAsDelivered"
                        disabled-tooltip="Veuillez d'abord selectionner une date de livrasion, un livreur et marquer la présence du client"
                        @click="markAsDelivered">
          Marquer comme livré
        </button-element>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../elements/modals/Modal";
import ButtonElement from "../../../elements/buttons/ButtonElement";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput.vue";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";
import DeliveryRepository from "@/repositories/DeliveryRepository";
import {required} from "vee-validate/dist/rules";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";

export default {
  name: "MarkDeliveryAsDeliveredModal",
  components: {
    SelectElement,
    DatePickerElement,
    MemberSelectInput,
    ButtonElement, Modal
  },

  props: {
    deliveryId: {
      required: true
    }
  },
  data: () => ({
    deliveredBy: null,
    deliveredAt: null,
    wasCustomerPresent: null
  }),
  methods: {
    openModal() {
      this.$refs.markDeliveryAsDelivered.openModal();
    },
    closeModal() {
      this.$refs.markDeliveryAsDelivered.closeModal();
    },
    onCloseModal() {
      this.deliveredAt = null;
      this.deliveredBy = null;
      this.wasCustomerPresent = null;
    },
    markAsDelivered(callback) {
      if (!this.canMarkAsDelivered) {
        return false;
      }

      DeliveryRepository
        .markManuallyAsDelivered(this.deliveryId, {
          wasCustomerPresentOnDelivery: this.wasCustomerPresent,
          deliveredBy: this.deliveredBy,
          deliveredAt: this.deliveredAt,
        })
        .then(() => {
          this.$sendSuccessToast('Livraison marquée comme livrée!');

          this.$emit('change');
          this.closeModal();
        }).finally(() => callback());
    }
  },
  computed: {
    getClientTypeOptions() {
      return [
        {
          label: 'Client absent',
          value: 0
        },
        {
          label: 'Client present',
          value: 1
        },
      ];

    },
    required() {
      return required
    },
    canMarkAsDelivered() {
      if (!this.deliveredBy || !this.deliveredAt || this.wasCustomerPresent == null) {
        return false
      }
      return true;
    },
  }
}
</script>