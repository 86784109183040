<template>
  <div>
    <page-title>
      {{ title }} #{{ getDepartmentId }}
    </page-title>

    <card>
      <create-or-update-department-form
        :department-id="getDepartmentId"
        mode="update"
        @updateTitle="onUpdateTitle"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import Card from "@/components/elements/cards/Card.vue";
import CreateOrUpdateDepartmentForm from "@/components/pages/Departments/Forms/CreateOrUpdateDepartmentForm.vue";

export default {
  name: "EditDepartment",
  components: {CreateOrUpdateDepartmentForm, Card, PageTitle},
  computed: {
    getDepartmentId() {
      return Number(this.$route.params.department);
    }
  },
  data: () => ({
    title: ''
  }),
  methods: {
    onUpdateTitle(title) {
      this.title = title;
    }
  },
}
</script>