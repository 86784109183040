<template>
  <div class="inline-block relative z-20">
    <tooltip text="Basculer la visibilité des colonnes" tooltip-classes="mt-3 -ml-12">
      <button-element
        size="small"
        type="dark"
        @click="toggleOverlay"
      >
        <fa-icon class="mr-2 hidden md:inline-block" fixed-width icon="eye-slash"></fa-icon>
        Colonnes
      </button-element>
    </tooltip>

    <transition name="fade">
      <div
        v-if="showOverlay"
        v-click-outside="closeOverlay"
        class="absolute bg-white border border-gray-300 rounded-md shadow mt-2 p-4 w-64 max-h-screen-70 overflow-y-auto"
      >
        <div
          v-for="column in toggleableColumns"
          :key="column.name"
          class="mb-2"
        >
          <div class="flex items-center gap-2">
            <toggle-element v-model="column.visible" class="inline-block"></toggle-element>
            <span :class="[column.visible ? 'text-gray-800' : 'text-gray-400']">
                            {{ column.name }}
                        </span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Tooltip from "../../../../elements/tooltips/Tooltip";
import ButtonElement from "../../../../elements/buttons/ButtonElement";
import ToggleElement from "../../../../elements/toggles/ToggleElement";

export default {
  name: "ListTableColumnToggleButton",
  components: {ToggleElement, ButtonElement, Tooltip},
  data: () => ({
    showOverlay: false,
  }),
  props: {
    tableIdentifier: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
  },
  mounted() {
    this.$store.commit('listTable/clearToggleableColumns');

    this.columns.forEach(c => {
      // Only create if not already set in store
      if (!this.toggleableColumns.flatMap(tc => tc.name).includes(c.caption)) {
        this.$store.commit('listTable/updateToggleableColumn', {
          tableIdentifier: this.tableIdentifier,
          name: c.caption,
          visible: c.visible
        });
      }
    })
  },
  methods: {
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
    closeOverlay() {
      this.showOverlay = false;
    }
  },
  computed: {
    toggleableColumns() {
      return this
        .$store
        .getters['listTable/getToggleAbleColumns']
        .filter(tc => tc.tableIdentifier === this.tableIdentifier);
    }
  }
}
</script>

