<template>
  <modal
    ref="modal"
    :show-close-button="false"
    mobile-max-width
    title="Message importante"
  >
    <div class="flex items-center justify-center">
      <fa-icon
        class="text-5xl text-red-500 mb-6"
        icon="exclamation-triangle"
      />
    </div>

    <div
      v-if="getParsedText !== null"
      v-html="getParsedText"
    />

    <template #footer>
      <div class="border-t pt-4 mt-4 flex items-center justify-between">
        <div class="italic text-sm">
          {{ $date(createdAt).fromNow() }}
        </div>
        <button-element
          type="primary"
          @click="markAsRead"
        >
          OK
        </button-element>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import {parseNotificationText} from "@/helpers/NotificationLinkHelper";

export default {
  name: "NotificationCriticalMessage",
  components: {ButtonElement, Modal},
  data() {
    return {
      id: null,
      text: null,
      createdAt: null,
    }
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    checkForNewMessage() {
      const latestUnread = this.$store.getters['notifications/getLatestUnreadCriticalMessage'];

      if (latestUnread !== null) {
        this.text = latestUnread.text;
        this.id = latestUnread.id;
        this.createdAt = latestUnread.createdAt;

        this.$nextTick(() => {
          this.openModal();
        })
      }
    },
    markAsRead() {
      if (this.id === null) {
        return false;
      }

      this.$store.dispatch('notifications/toggleNotificationRead', this.id)
        .then(() => {
          this.id = null;
          this.text = null;
          this.createdAt = null;
          this.$refs.modal.closeModal();
        });
    }
  },
  computed: {
    getParsedText() {
      if (this.text === null) {
        return null;
      }

      return parseNotificationText(this.text);
    }
  },
}
</script>