/**
 * This file contains validation rules for form fields.
 * You can import new rules that can then be used in any form field
 * or extend the validator with your own rules.
 *
 * Documentation: https://vee-validate.logaretm.com/v3/guide/basics.html
 */

import {extend} from 'vee-validate';
import {email, integer, max, max_value, min, min_value, numeric, required} from 'vee-validate/dist/rules';

/**
 * Requires that a value is set
 */
extend('required', {
  ...required,
  message: 'Ce champ est obligatoire.'
});

/**
 * Required that the string contains at least one number.
 */
extend('street_and_number', value => {
  let regex = /.*[0-9].*/;
  if (regex.test(value))
    return true;

  return "Ce champ doit être und adresse valide."
});

/**
 * Requires that the field contains a valid e-mail.
 */
extend('email', {
  ...email,
  message: 'Veuillez entrer une adresse e-mail valide.'
});

/**
 * Required that the field contains at least x characters.
 */
extend('min', {
  ...min,
  message: 'Veuillez entrer au moins {length} caractères.'
});

/**
 * Requires that the field contains no more than x characters.
 */
extend('max', {
  ...max,
  message: 'Veuillez entrer pas plus que {length} caractères.'
});

/**
 * Required that the field contains minimum value
 */
extend('min_value', {
  ...min_value,
  message: 'Veuillez entrer une valeur > {min}.'
});

/**
 * Required that the field contains maximum value
 */
extend('max_value', {
  ...max_value,
  message: 'Veuillez entrer une valeur < {max}.'
});


/**
 * Requires that the field contains only a unsigned / positive number.
 */
extend('numeric', {
  ...numeric,
  message: 'Veuillez entrer une numero entier.'
});

/**
 * Requires that the field contains either a signed or unsigned number.
 */
extend('integer', {
  ...integer,
  message: 'Veuillez entrer une numero.'
});

/**
 * Requires that the field is not equal to 0 (zero).
 */
extend('int_not_zero', value => {

  if (parseInt(value) !== 0)
    return true;

  return "Ce champ ne doit pas être égale à 0."
});

/**
 * Requires that the field contains a valid luxembourgish postal code.
 */
extend('luxembourgish_postal_code', value => {
  if (value.length === 4) {
    return true;
  }

  return "Indiquez un code postal luxembourgeois valide.";
});

extend('0_5_incremental', value => {
  if (value % 0.5 === 0) {
    return true;
  }

  return "Les total jours ne peuvent être incrémentés que de 0,5.";
});

/**
 * Requires that the field contains only a number (with or without decimals).
 */
extend("decimal", {
  validate: (value, args = {}) => {
    let decimals = args[0] ?? 2;
    let separator = args[1] ?? ',';

    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    // noinspection Annotator
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'Veuillez entrer une numero.'
})
