import apiService from './Base/BaseRepository';

let url = '/members';

export default {
  /**
   * Makes a customer out of a member.
   *
   * @param memberId
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async makeMemberACustomer(memberId, payload) {
    return await apiService.post(`${url}/${memberId}/customerDetails`, payload);
  },

  /**
   * Returns the employee data by its id.
   *
   * @param memberId
   * @returns {Promise<*>}
   */
  async getCustomerDetails(memberId) {
    return await apiService.get(`${url}/${memberId}/customerDetails`);
  },

  /**
   * Updates a customer
   *
   * @param memberId
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async storeCustomerDetails(memberId, payload) {
    return await apiService.post(`${url}/${memberId}/customerDetails`, payload);
  },

  /**
   * Deletes an employee.
   *
   * @param memberId
   * @return {Promise<AxiosResponse<any>>}
   */
  async deactivateCustomerDetails(memberId) {
    return await apiService.patch(`${url}/${memberId}/customerDetails/deactivate`);
  },
  async activateCustomerDetails(memberId) {
    return await apiService.patch(`${url}/${memberId}/customerDetails/activate`);
  },

  deliveryContacts: {
    async getAll(memberId) {
      return await apiService.get(`${url}/${memberId}/customerDetails/deliveryContacts`);
    },
    async getSingle(memberId, deliveryContactId) {
      return await apiService.get(`${url}/${memberId}/customerDetails/deliveryContacts/${deliveryContactId}`);
    },
    async create(memberId, payload) {
      return await apiService.post(`${url}/${memberId}/customerDetails/deliveryContacts`, payload);
    },
    async update(memberId, deliveryContactId, payload) {
      return await apiService.patch(`${url}/${memberId}/customerDetails/deliveryContacts/${deliveryContactId}`, payload);
    },
    async delete(memberId, deliveryContactId) {
      return await apiService.delete(`${url}/${memberId}/customerDetails/deliveryContacts/${deliveryContactId}`);
    }
  }

}
