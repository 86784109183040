import auth from "../middlewares/authMiddleware";
import ShowRacks from "../../components/pages/Racks/ShowRacks";


export default [
  {
    path: '/racks',
    name: 'racks.list',
    component: ShowRacks,
    meta: {
      middleware: [auth],
      title: 'Liste des étagères',
      permissions: ['ACCESS_RACK_VIEW']
    }
  },
];