<template>
  <div
    :class="[textClass, borderClass]"
    class="border border-2 text-2xl p-4 md:p-8 font-semibold rounded-lg hover:bg-white hover:text-gray-800 transition ease-in-out duration-150 select-none cursor-pointer"
    @click="emitClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TouchOptimizedModalButton",
  methods: {
    emitClick(e) {
      this.$emit('click', e);
    }
  },
  props: {
    textClass: {type: String, default: "text-white"},
    borderClass: {type: String, default: "border-gray-300"},
  }
}
</script>

