/** these colors are used throughout the routes **/
export const routeColors = [
  '#F0A30A',
  '#A4C400',
  '#0050EF',
  '#A20025',
  '#F472D0',
  '#E51400',
  '#00ABA9',
  '#6D8764',
  '#008A00',
  '#D80073',
  '#FA6800',
  '#4c4c4c',
  '#AA00FF',
  '#D8C100',
  '#1BA1E2',
  '#825A2C',
  '#7A3B3F',
  '#6A00FF',
  '#60A917',
  '#666666',
  '#647687',
  '#B98931',
  '#298181',
  '#49808F',
  '#F47174',
  '#435900',
  '#630303',
];

export function getColorByRouteIndex(routeIndex = 0) {
  return routeColors[routeIndex] ?? routeColors[Math.floor(Math.random() * routeColors.length)];
}

/*
 * The colors used for Tracking PolyLines.
 * Poly-lines with a speed of speedUntil are color in that given color.
 */
export const speedColors = [
  {speedUntil: 10, color: '#3982ff'},
  {speedUntil: 20, color: '#00d9ff'},
  {speedUntil: 30, color: '#00ffdf'},
  {speedUntil: 40, color: '#00ff4a'},
  {speedUntil: 50, color: '#dbff00'},
  {speedUntil: 60, color: '#ffae00'},
  {speedUntil: 100, color: '#ff7300'},
  {speedUntil: 130, color: '#ff714b'},
  {speedUntil: 140, color: '#ff3800'},
  {speedUntil: 99999, color: '#000000'},
];

export function getColorBySpeed(speed) {
  return speedColors.find(color => speed <= color.speedUntil)?.color || '#000000';
}

/**
 * Programmatically lighten or darker a color by the given hex code.
 * Returns another HEX Color Code
 *
 * See: https://stackoverflow.com/a/13532993/6578746
 * @param color
 * @param percent
 * @returns {string}
 */
export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R < 255) ? R : 255;
  G = (G < 255) ? G : 255;
  B = (B < 255) ? B : 255;

  let RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
  let GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
  let BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

  return "#" + RR + GG + BB;
}