<template>
  <div>
    <page-title>Catégories produits</page-title>

    <show-categories
      type="Product"
    />
  </div>
</template>

<script>

import ShowCategories from "@/components/elements/categoryManagement/ShowCategories";
import PageTitle from "@/components/elements/pages/PageTitle";

export default {
  name: "ShowProductCategories",
  components: {
    PageTitle,
    ShowCategories
  },
}
</script>

