<template>
  <div>
    <unsaved-changes-modal v-if="confirm" ref="unsavedChangesModal"
                           @confirmed="onConfirmed"></unsaved-changes-modal>

    <transition name="fade-up-down">
      <div
        v-show="shouldShowButton"
        :class="getButtonClass" class="rounded-lg text-gray-700 cursor-pointer
        transition delay-75 duration-200 select-none ease-in-out transform"
        @click="onClick">
        <fa-icon v-if="icon" :icon="icon" class="mr-2" fixed-width></fa-icon>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import UnsavedChangesModal from "../../modals/UnsavedChangesModal";

export default {
  name: "CardTabButton",
  components: {UnsavedChangesModal},
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      validator: val => ['large', 'small'].includes(val),
      default: 'large'
    },
    confirm: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (this.confirm) {
        return this.$refs.unsavedChangesModal.openModal();
      }
      return this.$emit('click');
    },
    onConfirmed() {
      return this.$emit('click');
    }
  },
  computed: {
    shouldShowButton() {
      return !this.isHidden;
    },
    getButtonClass() {
      let classes = '';

      switch (this.size) {
        default:
        case "large":
          classes += "py-2 px-4 ";
          break;
        case "small":
          classes += "text-sm py-1 px-3 ";
          break;
      }

      if (this.isActive) {
        classes += 'border-b-2 shadow-md '

        if (this.isError) {
          classes += 'border-red-800 bg-red-100'
        } else {
          switch (this.type) {
            default:
            case "primary":
              classes += "border-luxcaddy-600 bg-luxcaddy-100";
              break;
            case "light":
              classes += "border-gray-600 bg-gray-100 text-white";
              break;
          }
        }
      } else {
        classes += 'hover:-translate-y-0.5 ';

        if (this.isError) {
          classes += 'bg-red-200 hover:bg-red-100 hover:border-b-2 hover:red-gray-500';
        } else {
          switch (this.type) {
            default:
            case "primary":
              classes += "bg-luxcaddy-200 hover:bg-luxcaddy-100 hover:border-b-2 hover:border-gray-500";
              break;
            case "light":
              classes += "bg-gray-200 hover:bg-gray-100 hover:border-b-2 hover:border-gray-500";
              break;
          }
        }
      }

      return classes;
    }
  }
}
</script>

