<template>
  <div v-if="hasUnreadNotifications">
    <h2 class="text-white text-lg lg:text-2xl mb-2">
      Notifications non lues
    </h2>

    <div v-if="!hasLoadedInitially" class="flex flex-col items-center justify-center">
      <loading-svg-inline class="w-24 h-48 text-white"/>
    </div>
    <div
      v-else
      class="mb-6 lg:mb-16"
    >
      <transition-group class="flex flex-col space-y-2 bg-gray-700 rounded-md lg:pb-4 my-4 px-1 md:px-4"
                        name="fade">
        <dashboard-notification-menu-notification
          v-for="(notification, i) in getUnreadNotifications"
          :key="notification.id"
          :is-first="i === 0"
          :notification="notification"
          on-touch-interface
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline.vue";
import DashboardNotificationMenuNotification
  from "@/components/layouts/dashboard/partials/notifications/DashboardNotificationMenuNotification.vue";

export default {
  name: "TouchInterfaceNavMenuNotificationList",
  components: {DashboardNotificationMenuNotification, LoadingSvgInline},
  computed: {
    ...mapGetters('notifications', [
      'getUnreadNotifications',
      'hasLoadedInitially'
    ]),
    hasUnreadNotifications() {
      return this.getUnreadNotifications.length > 0;
    }
  },

}
</script>