<template>
  <div>
    <list-table
      :batch-actions="CampaignBatchActions.batchActions"
      :columns="columns"
      :create-route="{
                name: 'campaigns.create'
            }"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :select-values="CampaignBatchActions.selectValues"
      enable-extra-button
      :extra-button="getExtraActionButton"
      :title="title"
      identifier="campaignsTable"
      selectable
    >
      <template v-slot:timespan-template="{ row }">
        <div
          :class="{'text-red-400 line-through' : row.endDate !== null && isDateBeforeNow($date(row.endDate))}">
          {{ $date(row.startDate).format('DD.MM.YYYY HH:mm') }}
          <span v-if="row.endDate !== null">
                        <fa-icon class="mx-4" icon="long-arrow-alt-right"></fa-icon>
                        {{ $date(row.endDate).format('DD.MM.YYYY HH:mm') }}
                    </span>
          <span v-else>
                        <fa-icon class="mx-4" icon="long-arrow-alt-right"></fa-icon>
                        <fa-icon class="text-gray-400" icon="infinity"></fa-icon>
                    </span>
        </div>
      </template>


      <template v-slot:type-template="{ row }">
        <div v-if="row.type.toLowerCase() === 'cart'">
          <fa-icon class="mr-2" fixed-width icon="shopping-cart"></fa-icon>
          Panier
        </div>
        <div v-else-if="row.type.toLowerCase() === 'product'">
          <fa-icon class="mr-2" fixed-width icon="box"></fa-icon>
          Produit
        </div>
      </template>


      <template v-slot:valueType-template="{ row }">
        <div v-if="row.valueType === 'discount'">
          <fa-icon class="mr-2" fixed-width icon="percent"></fa-icon>
          Remise {{ row.value / 100 }}%
        </div>
        <div v-else-if="row.valueType === 'gift'">
          <fa-icon class="mr-2" fixed-width icon="gift"></fa-icon>
          Produit gratuit
        </div>
        <div v-else-if="row.valueType === 'fixed'">
          <fa-icon class="mr-2" fixed-width icon="euro-sign"></fa-icon>
          Remise fixe {{ row.value | centsToEuroString }}
        </div>
      </template>

      <template v-slot:availableStock-template="{ row }">
        <div v-if="row.availableStock === null">
          <fa-icon class="text-gray-400" icon="infinity"></fa-icon>
        </div>
        <div v-else>
          {{ row.availableStock }}
        </div>
      </template>
    </list-table>
  </div>
</template>

<script>
import ListTable from "../../listTable/ListTable";
import CampaignRepository from "../../../repositories/CampaignRepository";
import {
    FILTER_BOOLEAN,
    FILTER_DATETIME_RANGE,
    FILTER_SELECT_MULTI
} from "../../listTable/includes/controls/filters/availableFilters";
import CampaignBatchActions from "@/batchAction/campaignBatchActions";
import {eventBus} from "@/eventBus";

export default {
  name: "ShowCampaigns",
  mounted() {
    eventBus.$on('update-cache', () => {
        CampaignRepository
            .updateProductCampaignsCache(
            ).finally(() => {
            this.$sendSuccessToast('Le cache de la campagne produit est en cours de mise à jour.');
        })
    });
  },
  beforeDestroy() {
    eventBus.$off('update-cache');
  },
  computed: {
    CampaignBatchActions() {
      return CampaignBatchActions;
    },
    getExtraActionButton() {
      return {
          action: 'update-cache',
          button: {
              text: 'Sync les campagnes actives',
              icon: 'sync'
          }
      };
    }
  },
  components: {ListTable},
  data() {
    return {
      title: 'Campagnes',
      repositoryFunc: CampaignRepository.getPaginated,

      filters: [
        {
          caption: 'ID(s) ou Nom produit',
          filterKey: 'idsOrName',
          forceIsVisible: true,
          small: true
        },
        {
          caption: 'Status',
          filterKey: 'isActive',
          type: FILTER_BOOLEAN,
          forceIsVisible: true,
          small: true
        },
        {
          caption: 'Date courte?',
          filterKey: 'isShortDate',
          type: FILTER_BOOLEAN,
          forceIsVisible: true,
          small: true
        },
        {
            caption: 'Appliqué sur',
            filterKey: 'appliedOn',
            filterOptions: this.$luxcaddyConfig('campaigns.availableTypes'),
            type: FILTER_SELECT_MULTI,
            forceIsVisible: true,
            small: true
        },
        {
            caption: 'Type',
            filterKey: 'type',
            filterOptions: this.$luxcaddyConfig('campaigns.availableValueTypes'),
            type: FILTER_SELECT_MULTI,
            forceIsVisible: true,
            small: true
        },
        {
            caption: 'Régle appliquée sur produit(s): ID(s) = XXXX,YYYY',
            filterKey: 'productIds',
            forceIsVisible: true,

        },
        {
            caption: "Période",
            filterKey: "activePeriod",
            type: FILTER_DATETIME_RANGE,
        },
        {
            caption: "Date de fin",
            filterKey: "endDate",
            type: FILTER_DATETIME_RANGE,
        },
      ],

      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir campagne',
              route: (row) => ({
                name: 'campaigns.edit',
                params: {
                  campaign: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier campagne',
              route: (row) => ({
                name: 'campaigns.edit',
                params: {
                  campaign: row.id
                }
              })
            },
            {
              type: 'show',
              hint: 'Show products',
              icon: 'boxes',
              condition: (row) => row.appliedOnProductIds.length > 0,
              route: (row) => ({
                name: 'products.list',
                query:{ filter_productIdsOrName: row.appliedOnProductIds.join(',') }
              })
            }
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
        },
        {
          caption: 'Nom',
          dataField: 'name',
        },
        {
          caption: 'Type',
          cellTemplate: "valueType-template",
        },
        {
          caption: 'Appliqué sur',
          cellTemplate: "type-template",
        },
        {
          caption: 'Date courte?',
          dataField: 'isShortDate',
          dataType: 'boolean',
          calculateCellValue: (r) => r.isShortDate,
        },

        {
          caption: 'Active',
          dataType: 'boolean',
          calculateCellValue: (r) => {
            if (r.startDate && this.isDateAfterNow(this.$date(r.startDate))) {
              return false;
            }
            if (r.endDate !== null) {
              return !this.isDateBeforeNow(this.$date(r.endDate));
            }
            return true;
          },
          width: 100,
        },
        {
          caption: 'Stock disponible',
          dataField: 'availableStock',
          cellTemplate: "availableStock-template"
        },
        {
          caption: 'Période',
          dataField: 'startDate',
          cellTemplate: "timespan-template",
        },
      ],
    }
  },
  methods: {
    isDateBeforeNow(dayJsDate) {
      return dayJsDate.isBefore(this.$date());
    },
    isDateAfterNow(dayJsDate) {
      return dayJsDate.isAfter(this.$date());
    }
  }
}
</script>


