<template>
  <list-table
    :columns="columns"
    :create-route="{
            name: 'arrival-temperatures.create'
        }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    identifier="arrivalTemperatureTable"
    title="Températures d'arrivage"
  >
    <template v-slot:temperature-template="{ row }">
      <span class="font-semibold tracking-tight text-luxcaddy-700">{{ row.temperature }} °C</span>
    </template>

  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import ArrivalTemperatureRepository from "@/repositories/ArrivalTemperatureRepository";
import {FILTER_SELECT_SINGLE} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowArrivalTemperatures",
  components: {ListTable},
  data() {
    return {
      repositoryFunc: ArrivalTemperatureRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'delete',
              hint: 'Supprimer température',
              confirmation: {
                title: 'Supprimer température?',
                text: 'Étes vous sure que vous voulez supprimer cette température?'
              },
              successText: 'La température a été supprimée',
              deleteAction: (row) => ArrivalTemperatureRepository.delete(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: 'Date',
          calculateCellValue: (r) => this.$date(r.date).format('DD.MM.YYYY HH:mm'),
          sort: {
            dataField: 'date',
            default: 'desc'
          }
        },
        {
          caption: 'Fournisseur',
          calculateCellValue: (r) => r.supplier.name
        },
        {
          caption: 'Département',
          calculateCellValue: (r) => this.$luxcaddyConfig('arrivalTemperature.departments').find(at => at.value === r.department)?.label || '??'
        },
        {
          caption: 'Température',
          cellTemplate: "temperature-template",
          dataType: 'euro',
          sort: {
            dataField: 'temperature'
          }
        },
        {
          caption: 'Saisie par',
          calculateCellValue: (r) => r.created_by?.name || 'System'
        },
      ],
      filters: [
        {
          caption: "Département",
          filterKey: "department",
          type: FILTER_SELECT_SINGLE,
          filterOptions: this.$luxcaddyConfig('arrivalTemperature.departments'),
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>

