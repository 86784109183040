<template>
  <touch-interface-card-with-loading-indicator
    :disable-padding="!loading"
    :loading="loading"
    color="white"
  >
    <div v-if="route" class="px-2 py-4 text-center">
      <div>
        <span v-if="route && route.driver">{{ route.driver }}</span>
      </div>
      <div>Départ: {{ $date(route.departure).format('HH:mm') }}h - Bacs: {{ route.totalBoxQuantity }}</div>
      <div>Nbr. de livraisons: <span class="font-bold">{{ getTotalDeliveryCount }}</span></div>
    </div>

    <div class="flex flex-col | border-b border-t border-gray-300 divide-y divide-gray-300">
      <driver-route-check-detail-delivery-box
        v-for="(step, i) in getDeliverySteps"
        :key="step.delivery.id"
        :count="i + 1"
        :delivery="step.delivery"
      />
    </div>
  </touch-interface-card-with-loading-indicator>
</template>

<script>
import TouchInterfaceCardWithLoadingIndicator
  from "@/components/pages/TouchInterface/Shared/TouchInterfaceCardWithLoadingIndicator";
import RouteRepository from "@/repositories/RouteRepository";
import DriverRouteCheckDetailDeliveryBox
  from "@/components/pages/TouchInterface/Delivery/RouteCheck/Components/Detail/DriverRouteCheckDetailDeliveryBox";

export default {
  name: "DriverRouteCheckDetailInterfacePage",
  components: {DriverRouteCheckDetailDeliveryBox, TouchInterfaceCardWithLoadingIndicator},
  data: () => ({
    loading: false,
    route: null,
  }),
  created() {
    this.fetchRoute();
  },
  methods: {
    fetchRoute() {
      this.loading = true;
      RouteRepository
        .getSingle(this.$route.params.route)
        .then((res) => {
          this.$set(this, 'route', res.data.data);
        })
        .finally(() => this.loading = false);
    }
  },
  computed: {
    getTotalDeliveryCount() {
      return this.getDeliverySteps.length;
    },
    getDeliverySteps() {
      if (!this.route)
        return [];

      return this.route.steps.filter(s => s.delivery !== undefined && s.delivery !== null);
    }
  },
}
</script>

<style scoped>

</style>