<template>
  <div>
    <warning-alert
      v-if="!getItems.length"
      size="small"
    >
      Cette commande ne contient pas encore de produits.
    </warning-alert>


    <template v-else>

      <list-table
        v-if="render"
        :columns="getColumns"
        :enable-totals="!onlyIncomeForToday"
        :select-values="selectValues"
        :sent-table-rows="getItems"
        :show-refresh-button="false"
        identifier="supplierOrderItemsTable"
        selectable
        title="Produits"
      >
        <template v-slot:top>
          <div v-if="!onlyIncomeForToday" class="flex gap-x-2 items-center justify-between">
            <select-element
              v-model="orderItemSort"
              :enable-search="false"
              :options="getPossibleItemSorts"
              class="w-80"
              label="Triage par"
            />

            <div
              v-if="!allItemsTreated && !isDraft"
            >
              <button-element
                type="dark"
                @click="updateQuantities"
              >
                <fa-icon fixed-width icon="check"/>
                Enregistrer quantités reçues
              </button-element>
            </div>
          </div>
        </template>

        <template v-slot:first-row>
          <table-row v-if="!isDraft && hasUntreatedItems">
            <table-data-cell
              colspan="8"
            />
            <table-data-cell>
              <div class="flex flex-wrap gap-x-2">
                <button-element
                  size="small"
                  type="light"
                  @click="markAllItemsUndecided"
                >
                  Aucune décision
                </button-element>
                <button-element
                  size="small"
                  @click="setAllItemsReceived"
                >
                  <fa-icon fixed-width icon="check"/>
                  Tous reçu
                </button-element>
                <button-element
                  size="small"
                  type="danger"
                  @click="markAllItemsUnreceived"
                >
                  <fa-icon fixed-width icon="times"/>
                  Aucun reçu
                </button-element>
              </div>
            </table-data-cell>

            <table-data-cell
              v-if="!onlyIncomeForToday"
              colspan="3"
            />
          </table-row>
        </template>

        <template v-slot:product-quick-action-template="{ row }">
          <product-quick-action-modal
            v-if="!onlyIncomeForToday"
            :id="row.productId"
          />
          <span
            v-else
          >
                    #{{ row.productId }}
                </span>
        </template>

        <template v-slot:barcode-template="{ row }">
          <ul class="list-none">
            <li
              v-for="(barcode, i) in row.barcodes"
              :key="i"
            >
              {{ barcode }}
            </li>
          </ul>
        </template>

        <template v-slot:contents-template="{ row }">
          {{ row.bundle.quantity }}x{{ row.bundle.content }}{{ row.bundle.unit }}
        </template>

        <template v-slot:quantity-template="{ row }">
          <div class="flex items-center gap-x-2">
            <number-input-element
              v-if="isDraft"
              v-model="row.orderedBundleQuantity"
              :occupy-validation-space="false"
              class="w-20"
              small
              @blur="onQuantityBlur(row, $event)"
            />

            <div>
              <span v-if="!isDraft">{{ row.orderedBundleQuantity }}</span>
              x {{ row.bundleQuantity }} = {{ getTotalBundleQuantityForItem(row) }}
            </div>
          </div>
        </template>

        <template v-slot:arrival-template="{ row }">
          <supplier-order-item-arrival-column
            :ref="'item' + row.id"
            :income="onlyIncomeForToday"
            :item="row"
          />
        </template>

        <template v-slot:delete-item-template="{ row }">
          <supplier-order-delete-item-button
            :item-id="row.id"
            @deleted="reRender"
          />
        </template>


        <template v-slot:product-name-template="{ row }">
          <product-name :product="row"/>
        </template>
      </list-table>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import WarningAlert from "@/components/elements/alerts/WarningAlert.vue";
import ListTable from "@/components/listTable/ListTable.vue";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal.vue";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement.vue";
import SupplierOrderItemArrivalColumn
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/Items/SupplierOrderItemArrivalColumn.vue";
import {centsToEuroString} from "@/helpers/mathHelper";
import SupplierOrderDeleteItemButton
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/SupplierOrderDeleteItemButton.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";

export default {
  name: "SupplierOrderItemList",
  components: {
    ProductName,
    SelectElement,
    SupplierOrderDeleteItemButton,
    SupplierOrderItemArrivalColumn,
    NumberInputElement,
    ProductQuickActionModal,
    ListTable,
    WarningAlert,
    ButtonElement,
    TableDataCell,
    TableRow,
  },
  props: {
    onlyIncomeForToday: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    render: true,

    selectValues: item => {
      return {
        id: item.id
      };
    },
  }),
  created() {
    this.clearReceivedItemValues();
    this.setBatchErrors([]);
  },
  methods: {
    ...mapMutations('supplierOrders', [
      'clearReceivedItemValues',
      'setBatchErrors',
      'setOrderItemSort'
    ]),
    ...mapActions('supplierOrders', [
      'updateReceivedQuantities',
      'editItem',
      'fetchOrder'
    ]),
    updateQuantities() {
      // After updating quantities, refresh order and re-render table, as we don't have reactivity here
      this.updateReceivedQuantities('backoffice').finally(() => {
        this.fetchOrder(this.getOrderId).then(() => {
          this.render = false;
          this.$nextTick(() => this.render = true);
        });
      });
    },
    markAllItemsUndecided() {
      this.clearReceivedItemValues();
      this.updateAllItemRadioButtons("none");
    },
    setAllItemsReceived() {
      this.clearReceivedItemValues();
      this.getUntreatedItems.forEach((item) => {
        this.$refs["item" + item.id].setItemInReceivedArray();
        this.updateAllItemRadioButtons("received");
      });
    },
    markAllItemsUnreceived() {
      this.clearReceivedItemValues();
      this.getUntreatedItems.forEach((item) => {
        this.$refs["item" + item.id].setItemInReceivedArrayWithQuantityZero();
        this.updateAllItemRadioButtons("not_received");
      });
    },
    updateAllItemRadioButtons(decision) {
      this.getUntreatedItems.forEach((item) => {
        if (item.hasBeenTreated) {
          this.$refs["item" + item.id].decision = "none";
        } else {
          this.$refs["item" + item.id].decision = decision;
        }
      })
    },
    getTotalBundleQuantityForItem(item) {
      return item.orderedBundleQuantity * item.bundleQuantity;
    },
    onQuantityBlur(item, quantity) {
      this.editItem({
        itemId: item.id,
        quantity: quantity
      }).catch(() => {
        this.reRender();
      })
    },

    reRender() {
      this.render = false;
      this.$nextTick(() => {
        this.render = true;
      })
    }
  },
  computed: {
    ...mapGetters('supplierOrders', [
      'getOrderId',
      'getOrderItems',
      'isDraft',
      'getJustInTimeOrderItems',
      'allItemsTreated',
      'getPossibleItemSorts',
      'getOrderItemSort',
    ]),

    getItems() {
      return this.onlyIncomeForToday
        ? this.getJustInTimeOrderItems
        : this.getOrderItems;
    },

    getUntreatedItems() {
      return this.getItems.filter(i => !i.isTreated);
    },

    hasUntreatedItems() {
      return this.getUntreatedItems.length > 0;
    },

    getColumns() {
      let arr = [];

      if (this.isDraft && !this.$isReadOnly()) {
        arr.push({
          caption: 'Actions',
          cellTemplate: 'delete-item-template',
        });
      }

      arr.push({
        caption: '#',
        cellTemplate: "product-quick-action-template",
      });
      arr.push({
        caption: 'Produit',
        cellTemplate: "product-name-template",
      });
      arr.push({
        caption: 'Référence fournisseur',
        dataField: 'supplierReference',
      });
      arr.push({
        caption: 'EAN',
        cellTemplate: "barcode-template",
      });
      arr.push({
        caption: 'StR',
        dataField: "realStock",
      });
      arr.push({
        caption: 'Contenu',
        cellTemplate: "contents-template",
      });
      arr.push({
        caption: 'QteEx',
        cellTemplate: "quantity-template",
      });

      if (!this.isDraft) {
        arr.push({
          caption: 'Arrivage',
          cellTemplate: "arrival-template"
        });
      }

      if (!this.onlyIncomeForToday) {
        arr.push({
          caption: 'Prix',
          dataType: 'euro',
          calculateCellValue: (r) => centsToEuroString(r.unitPrice.amount),
          totals: {
            sum: 'unitPrice.amount',
            formatValue: (val) => centsToEuroString(val)
          }
        });
        arr.push({
          caption: 'Total',
          dataType: 'euro',
          calculateCellValue: (r) => centsToEuroString(r.price.amount),
          totals: {
            sum: 'price.amount',
            formatValue: (val) => centsToEuroString(val)
          }
        });
        arr.push({
          caption: 'TotalReçu',
          dataType: 'euro',
          calculateCellValue: (r) => {
            if (r.receivedSellingUnitQuantity === null) {
              return centsToEuroString(0);
            }
            return centsToEuroString(((r.receivedSellingUnitQuantity / r.bundleQuantity) * r.unitPrice.amount));
          },
          totals: {
            custom: (allRows) => {
              return allRows.reduce(function (sum, current) {
                if (current.receivedSellingUnitQuantity === null) {
                  return sum;
                }

                return sum + ((current.receivedSellingUnitQuantity / current.bundleQuantity) * current.unitPrice.amount);
              }, 0);
            },
            // Format Cents in EUR & suffix € symbol
            formatValue: (val) => centsToEuroString(val),
          }
        });
      }

      return arr;
    },

    // Setter & Getter for the value that should be sorted by
    orderItemSort: {
      get() {
        return this.getOrderItemSort;
      },
      set(value) {
        this.setOrderItemSort(value);
        this.reRender();
      }
    }

  },
}
</script>

