<template>
  <div
    :class="[withoutButton ? 'w-12' : 'w-24', size === 'large' ? 'h-12' : 'h-8']"
    class="flex items-stretch justify-between text-center gap-x-2"
  >
    <div key="input" class="flex-1 h-full align-center">
      <input v-model.number="model" :class="[getBorderColor]"
             class="w-full h-full appearance-none m-0 border rounded-md text-xs px-2.5"
             type="number" @keyup.enter="$emit('add')">
    </div>
    <div
      v-if="!withoutButton"
      :class="[getButtonColors]"
      class="flex-1 select-none h-full text-white flex items-center justify-center rounded-md cursor-pointer"
      @click="$emit('add')"
    >
      <fa-icon :icon="icon" fixed-width></fa-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddAmountElement",
  props: {
    value: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      default: 'cart-plus'
    },
    size: {
      type: String,
      default: 'small'
    },
    type: {
      default: 'primary',
      validator: val => ['primary', 'success', 'danger', 'warning', 'dark', 'light'].includes(val)
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    withoutButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getBorderColor() {
      switch (this.type) {
        case 'light':
          return 'border-gray-400';
        case 'dark':
          return 'border-gray-800';
        case 'success':
          return 'border-green-600';
        case 'danger':
          return 'border-red-600';
        case 'warning':
          return 'border-yellow-600';
      }
      return 'border-luxcaddy-600';
    },
    getButtonColors() {
      switch (this.type) {
        case 'light':
          return 'bg-gray-400 hover:bg-gray-300';
        case 'dark':
          return 'bg-gray-800 hover:bg-gray-900';
        case 'success':
          return 'bg-green-600 hover:bg-green-500';
        case 'danger':
          return 'bg-red-600 hover:bg-red-500';
        case 'warning':
          return 'bg-yellow-600 hover:bg-yellow-500';
      }
      return 'bg-luxcaddy-600 hover:bg-luxcaddy-500';
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === "")
          value = 1;

        this.$emit('input', value);
      }
    }
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>