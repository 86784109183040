<template>
  <select-element
    :enable-search="enableSearch"
    :label="label"
    :multi="type === 'multi'"
    :occupy-validation-space="occupyValidationSpace"
    :options="availableSuppliers"
    :touch-optimized="touchOptimized"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  ></select-element>
</template>

<script>
import SupplierRepository from "../../../../repositories/SupplierRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "SupplierSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    onlyProductSupplier: {
      type: Boolean,
      default: false
    },
    label: {
      default: 'Fournisseur',
      required: false,
    },
    occupyValidationSpace: {
      type: Boolean,
      default: false,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: true
    },
    touchOptimized: {
      type: Boolean,
      default: false,
    },
    onlyActiveSuppliers: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'single'
    }
  },
  created() {
    if (this.options === undefined) {
      this.fetchSuppliers();
    } else {
      this.availableSuppliers = this.options;
    }
  },
  methods: {
    fetchSuppliers() {
      if (this.onlyActiveSuppliers) {
        SupplierRepository
          .getActiveProductSuppliers()
          .then((res) => {
            this.availableSuppliers = res.data.data.map(c => {
              return {
                value: c.memberId,
                label: `#${c.memberId} ${c.name}`
              };
            });
          });
      } else {
        SupplierRepository
          .getAllProductSuppliers()
          .then((res) => {
            this.availableSuppliers = res.data.data.map(c => {
              let label = c.name;
              if (!c.isActive) {
                label = label + '- Inactif'
              }
              return {
                value: c.memberId,
                label: `#${c.memberId} ${label}`,
              };
            });
          });
      }

    }
  },
  data: () => ({
    availableSuppliers: [],
  })
}
</script>

