import auth from "@/router/middlewares/authMiddleware";
import EditProductCategory from "@/components/pages/Categories/Products/EditProductCategory";
import EditBlogPostCategory from "@/components/pages/Categories/Blog/EditBlogPostCategory";

export default [
  {
    path: '/categories/product/:category',
    name: 'categories.product.edit',
    component: EditProductCategory,
    meta: {
      middleware: [auth],
      title: 'Modifier catégorie',
      readOnly: {supported: true},
      breadcrumbs: ['product-categories.list'],
      permissions: ['PRODUCT_READ', 'CATEGORY_READ', 'CATEGORY_WRITE',]
    }
  },
  {
    path: '/categories-virtual/product/:category',
    name: 'categories.virtual.product.edit',
    component: EditProductCategory,
    meta: {
      middleware: [auth],
      title: 'Modifier catégorie',
      readOnly: {supported: true},
      breadcrumbs: ['product-categories-virtual.list'],
      permissions: ['PRODUCT_READ', 'CATEGORY_READ', 'CATEGORY_WRITE',]
    }
  },
  {
    path: '/categories/blogpost/:category',
    name: 'categories.blogpost.edit',
    component: EditBlogPostCategory,
    meta: {
      middleware: [auth],
      title: 'Modifier catégorie',
      readOnly: {supported: true},
      breadcrumbs: ['blog.categories.list'],
      permissions: ['PRODUCT_READ', 'CATEGORY_READ', 'CATEGORY_WRITE',]
    }
  },
];