<template>
  <div>
    <horizontal-scroll>
      <transition-group v-if="getPackages" class="flex flex-row gap-x-6" name="fade-up-down">
        <package-card
          v-for="packageData in getPackages"
          :key="packageData.id"
          :package-data="packageData"
          @added="$emit('added')"
        >
        </package-card>
      </transition-group>
    </horizontal-scroll>
  </div>
</template>

<script>
import PackageRepository from "../../../../../repositories/PackageRepository";
import HorizontalScroll from "../../../../elements/scroll/HorizontalScroll";
import PackageCard from "./PackageCard";

export default {
  name: "AddPackageToOrder",
  components: {PackageCard, HorizontalScroll},
  data: () => ({
    packages: []
  }),
  mounted() {
    this.fetchPackages();
  },
  methods: {
    fetchPackages() {
      PackageRepository.get().then((res) => {
        this.packages = res.data.data;
      });
    }
  },
  computed: {
    getPackages() {
      return this.packages;
    }
  },
}
</script>

