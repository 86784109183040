<template>
  <div class="w-full">

    <touch-optimized-button
      class="w-full"
      type="dark"
      with-border
      @click="openModal"
    >
      Entrée manuelle
    </touch-optimized-button>

    <touch-optimized-modal ref="modal">
      <div class="flex gap-x-12">
        <div class="flex flex-col">
          <div class="flex flex-row gap-4">
            <number-input-element
              v-model="amount"
              :occupy-validation-space="false"
              label="Quantité"
              placeholder="Qté"
              touch-optimized
            />

            <text-input-element
              ref="barcodeField"
              v-model="barcode"
              :occupy-validation-space="false"
              label="Code barre"
              placeholder="Code barre"
              touch-optimized
            />
          </div>

          <touch-optimized-modal-button
            border-class="border-luxcaddy"
            class="mt-24 text-center"
            @click="onEnter"
          >
            Entrer
          </touch-optimized-modal-button>
        </div>

        <key-pad class="w-56"/>
      </div>
    </touch-optimized-modal>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import {mapActions} from "vuex";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import KeyPad from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/keypad/KeyPad";

export default {
  name: "OrderFulfilmentManualBarcodeEntry",
  components: {
    KeyPad,
    TouchOptimizedModalButton,
    TouchOptimizedModal,
    TextInputElement,
    NumberInputElement,
    TouchOptimizedButton
  },
  data: () => ({
    toggled: false,

    amount: 1,
    barcode: null
  }),
  methods: {
    ...mapActions('fulfilment', [
      'fulfillItemByBarcode'
    ]),
    openModal() {
      this.$refs.modal.open();
      this.$nextTick(() => this.$refs.barcodeField.focus());
    },
    onEnter() {
      this.fulfillItemByBarcode({
        barCode: this.barcode,
        quantity: this.amount
      }).then(() => {
        this.amount = 1;
        this.barcode = null;
        this.toggled = false;
      }).finally(() => this.$refs.modal.close());
    }
  },
}
</script>

