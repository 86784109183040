<template>
  <div
    class="group cursor-pointer bg-gray-100 hover:bg-gray-200 rounded-md p-4 flex justify-between items-center"
    @click="navigateToBankAccountPage"
  >
    <div>
      <h2 class="text-2xl font-bold">
        {{ getAccountNumber }}
      </h2>
      <div :class="getAccountBalanceTextClass" class="text-xl font-semibold mt-2">
        <money-amount
          :amount="getAccountBalance"
        />
      </div>
    </div>

    <div class="bg-luxcaddy-300 group-hover:bg-luxcaddy-400 rounded-full w-8 h-8 flex items-center justify-center">
      <fa-icon icon="chevron-right"/>
    </div>
  </div>
</template>

<script>
import MoneyAmount from "@/components/global/Money/MoneyAmount";

export default {
  name: "AccountingBankAccountItem",
  components: {MoneyAmount},
  props: {
    account: {
      type: Object,
      required: true
    },
  },
  methods: {
    navigateToBankAccountPage() {
      this.$router.push({
        name: 'accounting.bankMovements.list',
        params: {bankAccount: this.getAccountNumber}
      });
    }
  },
  computed: {
    getAccountNumber() {
      return this.account.account;
    },
    getAccountBalance() {
      return this.account.balance;
    },
    getAccountBalanceTextClass() {
      if (this.getAccountBalance < 0) {
        return 'text-red-500';
      } else if (this.getAccountBalance > 0) {
        return 'text-luxcaddy-500';
      }
      return '';
    }
  },
}
</script>