<template>
  <div>
    <custom-table :headings="getHeadings">
      <table-row v-for="(file,index) in getOrder.files" :key="index">
        <table-data-cell>
          {{ $date(file.date).format('DD.MM.YYYY HH:mm') }}h
        </table-data-cell>
        <table-data-cell class="flex">
          {{ file.name }}
        </table-data-cell>
        <table-data-cell>
          {{ file.type }}
        </table-data-cell>
        <table-data-cell class="text-right">
          <button-element size="small" @click="showContent(file.id)">
            <fa-icon icon="eye"></fa-icon>
          </button-element>
        </table-data-cell>
      </table-row>
    </custom-table>

    <modal ref="showFileContent" title="File content">
      <pre class="overflow-auto" v-text="content"></pre>
    </modal>
  </div>
</template>

<script>
import CustomTable from "@/components/elements/tables/CustomTable";
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import {mapGetters} from "vuex";
import SupplierOrderRepository from "../../../../../repositories/SupplierOrderRepository";
import Modal from "../../../../elements/modals/Modal";

export default {

  name: "SupplierOrderFileList",
  components: {Modal, TableDataCell, TableRow, CustomTable, ButtonElement},

  data: () => ({
    content: null,
  }),
  computed: {
    ...mapGetters('supplierOrders', [
      'getOrder',
    ]),

    getHeadings() {
      return [
        {label: 'Date fichier'},
        {label: 'Nom'},
        {label: 'Type'},
        {label: 'Actions'},
      ];
    }
  },

  methods: {
    showContent(fileId) {
      SupplierOrderRepository
        .getFile(fileId)
        .then(res => {
          this.content = res.data.data.content;
          this.$refs.showFileContent.openModal();
        });


    },
  }
}
</script>

