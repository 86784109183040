<template>
  <div>
    <div :class="[!isPublished ? 'opacity-50' : '', showActions ? 'rounded-t-md' : 'rounded-md']"
         class="flex flex-row items-center bg-gray-100 mt-1 h-9">
      <div class="px-4 w-11">
        <template v-if="enableDrag">
          <div class="w-full">
            <fa-icon
              v-if="showHandleSelf"
              class="handle cursor-move text-gray-400 animate-pulse"
              fixed-width
              icon="grip-vertical"
            />
          </div>
        </template>

        <template v-else>
          <div v-if="hasChildren" class="w-full cursor-pointer" @click="isExpanded = !isExpanded">
            <fa-icon :class="{'-rotate-90 text-gray-400': !isExpanded}"
                     class="transition transform text-gray-600 hover:text-gray-400"
                     fixed-width icon="chevron-down"></fa-icon>
          </div>

          <div v-else class="w-full">
            <div
              v-if="hasParent"
              class="text-gray-500 opacity-40"
            >
              ↪
            </div>
          </div>
        </template>
      </div>

      <div class="w-2">
        <fa-icon v-if="category.isHomepage" icon="home" style="color:green"></fa-icon>
      </div>
      <div class="flex-1 flex items-center gap-x-4">
        <transition mode="out-in" name="fade">
          <div v-if="!$isReadOnly()" class="flex-0 px-4 flex items-center">
            <button-element size="xsmall" type="light" @click="toggleActions">
              <fa-icon icon="cog"></fa-icon>
              <span class="ml-1 hidden md:inline-block">Actions</span>
            </button-element>
          </div>
        </transition>

        <div class="font-medium tracking-wide pr-4">
          <div class="inline-block text-sm px-1 w-16 text-gray-400 select-none">#{{ category.id }}</div>
          <div class="inline-block">{{ category.name }}</div>
        </div>

        <div class="justify-self-end font-medium tracking-wide pr-4">
          <div class="inline-block text-sm px-1 text-gray-400 select-none">({{ category.itemCount }} items)
          </div>
        </div>
      </div>
    </div>

    <transition name="fade-up-down">
      <category-tree-item-actions
        v-if="showActions"
        :category="category"
      />

    </transition>

    <div class="space-y-1">
      <draggable v-model="category.subCategories" ghost-class="opacity-40" handle=".handle" @input="onDragEnd">
        <transition-group name="fade-up-down">
          <category-tree-item
            v-for="subcategory in category.subCategories" v-show="isExpanded"
            :key="subcategory.id"
            :category="subcategory" :parent-id="category.id"
            :parent-level="getLevel"
            class="ml-2 lg:ml-8 xl:ml-10"
          />
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../../../elements/buttons/ButtonElement";
import draggable from "vuedraggable";
import CategoryTreeItemActions from "@/components/elements/categoryManagement/Tree/CategoryTreeItemActions";

export default {
  name: "CategoryTreeItem",
  components: {
    CategoryTreeItemActions,
    ButtonElement,
    draggable
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    parentLevel: {
      type: Number,
      default: 0
    },
    parentId: {
      type: [Number, null],
      default: null,
    },
    /**
     * Whether a handle should be shown for the current Item
     * Does not affect child items.
     */
    showHandleSelf: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showActions: false
  }),
  methods: {
    onDragEnd(categories) {
      let categoryIdsSorted = categories.flatMap(cat => cat.id);

      this.$store.dispatch('categories/updateChildrenSort', {
        parentId: this.category.id,
        categoryIdsSorted: categoryIdsSorted
      })
    },

    toggleActions() {
      this.showActions = !this.showActions;
    }
  },
  computed: {
    hasParent() {
      return this.parentLevel > 0;
    },
    getLevel() {
      return this.parentLevel + 1;
    },
    hasChildren() {
      return this.category.subCategories?.length > 0 || false;
    },
    isExpanded: {
      get() {
        return this.$store.getters['categories/getExpandedCategories'].includes(this.category.id);
      },
      set() {
        this.$store.commit('categories/toggleExpanded', this.category.id);
      }
    },
    enableDrag() {
      return this.$store.getters['categories/getEnableDrag'];
    },
    isPublished() {
      return this.category.isPublished;
    },
  }
}
</script>

