<template>
  <transition name="fade-up-down">
    <div
      v-show="showAlert"
      :class="size === 'normal' ? 'p-5' : 'p-2'"
      class="bg-white shadow-lg flex items-center rounded-lg ring-1 ring-blue-200 gap-6"
    >
      <div class="mx-4">
        <fa-icon :size="size === 'normal' ? '3x' : '2x'" class="text-blue-500" icon="info-circle"></fa-icon>
      </div>
      <div :class="[size === 'normal' ? 'text-lg' : 'text-sm']" class="flex-1">
        <h4 v-if="title" class="font-medium">{{ title }}</h4>
        <div class="text-gray-500">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "InfoAlert",
  data: () => ({
    showAlert: false
  }),
  created() {
    if (this.showOnCreate) {
      this.showAlert = true;
    }
  },
  props: {
    title: {
      type: String
    },
    showOnCreate: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "normal"
    }
  },
  methods: {
    toggle() {
      this.showAlert = !this.showAlert;
    }
  },
}
</script>

