<template>
  <base-input-element
    :inset-position="insetPosition"
    :inset-text="insetText"
    :label="label"
    :placeholder="placeholder"
    :type="type"
    :validation-rules="getValidationRules"
    :value="value"
    @input="$emit('input', $event)"
  ></base-input-element>
</template>

<script>

import BaseInputElement from "./base/BaseInputElement";

export default {
  name: "StreetInputElement",
  components: {BaseInputElement},
  props: {
    value: {
      required: false,
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: 'Rue et numéro',
    },
    placeholder: {
      type: String,
      required: false
    },
    validationRules: {
      type: String,
      default: '',
    },

    insetText: {
      type: String,
      required: false
    },

    insetPosition: {
      type: String,
    },

    requireStreetNumber: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getValidationRules() {
      if (this.requireStreetNumber) {
        return this.validationRules.length ? `${this.validationRules}|street_and_number` : 'street_and_number';
      }

      return this.validationRules.length ? `${this.validationRules}` : null;
    }
  }
}
</script>

