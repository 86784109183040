<template>
  <div>
    <list-table
      ref="table"
      :columns="getColumns"
      :create-event="isInvoiceFinalized"
      :filters="filters"
      :identifier="tableIdentifier"
      :pagination="showPagination"
      :repository-func="repositoryFunc"
      :select-values="row => ({id: row.id})"
      enable-totals
      selectable
      show-filters-immediately
      title="Payments"
      @create="createButtonClicked"
    >

      <template v-slot:payment-provider-template="{row}">
        {{ row.provider | paymentProviderKeyToReadable }}
      </template>

      <template v-slot:bank-movement-link="{row}">
        <div v-if="row.bankMovementId" class="text-luxcaddy-500">
          <router-link :to="{ name: 'accounting.bankMovements.edit', params: {'id': row.bankMovementId }}">
            <fa-icon fixed-width icon="link"/>&nbsp; {{ row.bankMovementId }}
          </router-link>
        </div>
      </template>
      <template v-slot:invoice-id-template="{ row }">
        <text-link
          v-if="row.invoiceId"
          :to="{name: 'invoices.edit', params: {id: row.invoiceId}}"
        >
          #{{ row.invoiceId }}
        </text-link>
        <div v-else>
          /
        </div>
      </template>

      <template
        v-if="unattributedAmount !== null && unattributedAmount"
        #additionalRow
      >
        <table-data-cell
          class="text-right"
          colspan="5"
        >
            <span class="font-bold">
              Montant non attribué
            </span>
        </table-data-cell>
        <table-data-cell class="text-right" colspan="2">
          {{ unattributedAmount | centsToEuroString }}
        </table-data-cell>
      </template>

    </list-table>
    <payment-add-form ref="paymentAddForm" @change="onChange"/>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import {mapGetters} from "vuex";
import {centsToEuroString} from "@/helpers/mathHelper";
import {FILTER_SELECT_SINGLE} from "@/components/listTable/includes/controls/filters/availableFilters";
import PaymentAddForm from "@/components/pages/Invoices/Shared/EditInvoice/Payments/Includes/Add/PaymentAddForm.vue";
import AccountingBankMovementRepository from "@/repositories/AccountingBankMovementRepository";
import TextLink from "@/components/global/TextLink.vue";
import TableDataCell from "@/components/elements/tables/TableDataCell.vue";
import {eventBus} from "@/eventBus";

export default {
  name: "ShowPaymentsTable",
  components: {
    TableDataCell,
    PaymentAddForm,
    ListTable, TextLink
  },
  props: {
    tableIdentifier: {
      type: String,
      default: 'showPaymentsTable'
    },
    filterKey: {
      type: String,
      required: false
    },
    filterValue: {
      type: Number,
      required: false
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: false
    },
    unattributedAmount: {
      type: Number,
      required: false,
    }
  },
  created() {
    const vm = this;
    eventBus.$on('bank-movement-change', () => {
      vm.$refs.table.refresh();
    });
  },
  computed: {
    ...mapGetters('invoices', ['getInvoiceId', 'isInvoiceInternal', 'isDraft']),
    ...mapGetters('bankMovements', ['getBankMovementId']),

    modalInstance() {
      return this.$refs.paymentAddForm.$refs.addPaymentModal;
    },
    isInvoiceFinalized() {

      if (this.getInvoiceId === undefined || this.getInvoiceId === null) {
        return false;
      }

      return !this.isDraft;

    },
    getColumns() {
      let columns = [
        {
          caption: "Mouv. bancaire",
          cellTemplate: 'bank-movement-link',
          width: 140,
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: '# Facture',
          cellTemplate: "invoice-id-template",
          sort: {
            dataField: 'invoiceId'
          }
        },
        {
          caption: 'Montant préautorisé',
          dataField: 'preAuthorizedAmount',
          dataType: 'euro',
          sort: {
            dataField: 'preAuthorizedAmount'
          },
          totals: {
            sum: "preAuthorizedAmount",
            formatValue: (val) => centsToEuroString(val),
          },
        },
        {
          caption: 'Montant payé',
          dataField: 'payedAmount',
          dataType: 'euro',
          sort: {
            dataField: 'payedAmount'
          },
          totals: {
            sum: "payedAmount",
            formatValue: (val) => centsToEuroString(val),
          },
        },
        {
          caption: 'Prestataire',
          sort: {
            dataField: 'provider'
          },
          cellTemplate: 'payment-provider-template'
        },
        {
          caption: 'Crée',
          calculateCellValue: (r) => {
            return this.$date(r.createdAt).format('DD.MM.YYYY HH:mm');
          },
          sort: {
            dataField: 'createdAt',
            default: 'desc'
          }
        },
        {
          caption: 'Transactionné',
          calculateCellValue: (r) => {
            return r.transactionDate != null ? this.$date(r.transactionDate).format('DD.MM.YYYY HH:mm') : null;
          },
          sort: {
            dataField: 'transactionDate',
            default: 'desc'
          }
        },

      ];

      if (this.showActions) {
        columns.push(
          {
            caption: "Actions",
            buttons: [
              {
                type: 'delete',
                hint: 'Supprimer paiment',
                disabledIf: (row) => this.getInvoiceId === null || !this.isManualProviderType(row.provider),
                confirmation: {
                  title: 'Supprimer paiment?',
                  text: 'Étes vous sure que vous voulez supprimer cette paiment?'
                },
                successText: 'La paiment a été supprimée',
                deleteAction: (row) => InvoiceRepository.payments.deleteInvoicePayment(this.getInvoiceId, row.id).then(() => {
                  this.$refs.table.refresh()
                  this.$emit('change');
                })
              },
            ]
          }
        )
      }

      if (this.tableIdentifier === 'bankMovementLinkedPayment') {
        columns.splice(3, 0,
          {
            caption: '# Member',
            dataField: 'memberName'
          }
        );
        columns.push(
          {
            caption: "Actions",
            buttons: [
              {
                type: 'unlink',
                hint: 'Unlink paiment',
                confirmation: {
                  title: 'DÉLIER TRANSACTION',
                  text: 'Voulez-vous vraiment délier cette transaction de paiement de ce mouvement bancaire ?'
                },
                successText: 'La transaction a été déliée',
                deleteAction: (row) => AccountingBankMovementRepository
                  .unlinkPaymentTransaction(this.getBankMovementId, row.id).then(() => {
                    this.$refs.table.refresh()
                    this.$emit('change');
                  })
              },
            ]
          },
        )
      }

      return columns
    }
  },
  methods: {
    isManualProviderType(provider) {
      const manualProviders = this.$luxcaddyConfig('invoice.paymentTransactions.manualPaymentProviders');

      return manualProviders.some(manualProvider => manualProvider.value === provider);
    },
    onChange() {
      this.$refs.table.refresh()
      this.$emit('change');
    },
    createButtonClicked() {
      this.$nextTick(() => {
        this.modalInstance.openModal();
      });
    }
  },
  data() {
    return {
      title: "Paiements",
      repositoryFunc: (...args) => {
        if (this.filterKey === undefined && this.filterValue === undefined) {
          return InvoiceRepository.payments.getPaginated(...args);
        }
        if (this.isInvoiceInternal) {
          return InvoiceRepository.internal.payments.getPayments(this.filterKey, this.filterValue, ...args)

        } else {
          return InvoiceRepository.payments.getPayments(this.filterKey, this.filterValue, ...args)
        }
      },
      filters: [
        {
          caption: "Prestataire",
          filterKey: "provider",
          type: FILTER_SELECT_SINGLE,
          filterOptions: this.$luxcaddyConfig('invoice.paymentTransactions.paymentProviders'),
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>