/**
 * This store is managing all the different tables throughout the application that are using for Listings of items.
 * It saves stuff like pagination, currently applied sorts and visible columns.
 */
import {checkIfObjectsMatch} from "@/helpers/objectHelper";

const getDefaultState = () => {
  return {
    paginations: [],
    sorts: [],
    toggleAbleColumns: [],
    selectedRows: [],

    // Contains the names of all tables where the respective component has been called
    // at least once since the last hard refresh
    initializedTables: [],

    batchErrors: [],
    batchResult: null
  }
}


const addToInitializedTables = function (state, tableIdentifier) {
  if (tableIdentifier && !state.initializedTables.includes(tableIdentifier)) {
    state.initializedTables.push(tableIdentifier);
  }
}

/**
 * Finds or creates a given Sort
 *
 * @param state
 * @param tableId
 * @param key
 * @param sort
 * @param dataField
 * @return {T}
 */
const findOrCreateSort = function (state, {tableIdentifier, key, sort, dataField}) {
  let existingSort = state.sorts.find(c => c.key === key && c.tableIdentifier === tableIdentifier);

  if (!existingSort) {
    state.sorts.push({
      tableIdentifier,
      key,
      dataField,
      sort,
    });
  }

  addToInitializedTables(state, tableIdentifier);

  return state.sorts.find(c => c.key === key && c.tableIdentifier === tableIdentifier);
}

/**
 * Finds or creates a given toggleAble column.
 *
 * @param state
 * @param tableId
 * @param columnName
 * @param visible
 * @return {*}
 */
const findOrCreateToggleAbleColumn = function (state, {tableIdentifier, name, visible}) {
  let column = state.toggleAbleColumns.find(c => c.name === name && c.tableIdentifier === tableIdentifier);
  if (!column) {
    state.toggleAbleColumns.push({
      tableIdentifier,
      name,
      visible
    });
  }

  addToInitializedTables(state, tableIdentifier);

  return state.toggleAbleColumns.find(c => c.name === name && c.tableIdentifier === tableIdentifier);
}

/**
 * Finds or creates a pagination object for a given table
 *
 * @param state
 * @param tableId
 * @return {*}
 */
const findOrCreatePagination = function (state, {
  tableIdentifier,
  currentPage,
  lastPage,
  perPage,
  totalItems,
  itemsFrom,
  itemsTo,
  withTotals
}) {
  let paginationObject = state.paginations.find(p => p.tableIdentifier === tableIdentifier);
  if (!paginationObject) {
    state.paginations.push({
      tableIdentifier,
      currentPage,
      lastPage,
      perPage,
      totalItems,
      itemsFrom,
      itemsTo,
      withTotals
    });
  }

  addToInitializedTables(state, tableIdentifier);

  return state.paginations.find(p => p.tableIdentifier === tableIdentifier);
}

const findPagination = function (state, tableIdentifier) {
  let paginationObject = state.paginations.find(p => p.tableIdentifier === tableIdentifier);

  // create default if not existing
  if (!paginationObject) {
    state.paginations.push({
      tableIdentifier,
      perPage: 20,
      currentPage: 1,
      lastPage: 0,
      totalItems: 0,
      itemsFrom: 0,
      itemsTo: 0,
      withTotals: 0
    });
  }

  addToInitializedTables(state, tableIdentifier);

  return state.paginations.find(p => p.tableIdentifier === tableIdentifier);
}

// Returns the Index of the given object in the array
const getObjectIndexInArray = function (array, object) {
  return array.findIndex(item => {
    return checkIfObjectsMatch(item, object);
  });
}

const selectRow = function (state, value) {
  if (!isRowSelected(state, value)) {
    state.selectedRows.push(value);
  }
}

const unselectRow = function (state, value) {
  if (typeof value === "object") {
    const index = getObjectIndexInArray(state.selectedRows, value);
    if (index > -1) {
      state.selectedRows.splice(index, 1);
    }
  } else {
    const index = state.selectedRows.indexOf(value);

    if (index > -1) {
      state.selectedRows.splice(index, 1);
    }
  }
}

const isRowSelected = function (state, value) {
  if (typeof value === "object") {
    return getObjectIndexInArray(state.selectedRows, value) > -1;
  } else {
    return state.selectedRows.indexOf(value) > -1;
  }
}

const hasRowError = function (state, value) {
  let index;

  if (typeof value === "object") {
    index = getObjectIndexInArray(state.batchErrors, value);
  } else {
    index = state.selectedRows.indexOf(value);
  }

  return index > -1 && typeof state.batchErrors[index] !== "undefined";
}

const getRowError = function (state, value) {
  let index;

  if (typeof value === "object") {
    index = getObjectIndexInArray(state.batchErrors, value);
  } else {
    index = state.selectedRows.indexOf(value);
  }

  return index > -1
    ? state.batchErrors[index]
    : null;
}


export default {
  state: getDefaultState(),
  getters: {
    getPaginationConfigs: state => state.paginations,
    getSorts: (state) => state.sorts || [],
    getToggleAbleColumns: (state) => state.toggleAbleColumns || [],


    getSelectedRows: (state) => state.selectedRows || [],
    isRowSelected: (state) => (value) => {
      return isRowSelected(state, value);
    },

    getBatchErrors: (state) => state.batchErrors || [],
    hasRowError: (state) => (value) => {
      return hasRowError(state, value);
    },
    getRowError: (state) => (value) => {
      return getRowError(state, value);
    },
    getBatchResult: (state) => state.batchResult,

    hasTableBeenInitialized: (state) => (tableIdentifier) => {
      return state.initializedTables.includes(tableIdentifier);
    }
  },
  mutations: {
    // eslint-disable-next-line no-unused-vars
    clearData(state) {
      state.sorts = [];
      state.toggleAbleColumns = [];
      state.selectedRows = [];
      state.batchErrors = [];
    },

    clearSelectedRows(state) {
      state.selectedRows = [];
    },

    setStateFromResponse(state, {tableIdentifier, responseMeta}) {
      let savedPagination = findOrCreatePagination(state, {
        tableIdentifier,
        currentPage: responseMeta.currentPage,
        lastPage: responseMeta.lastPage,
        perPage: responseMeta.perPage,
        totalItems: responseMeta.total,
        itemsFrom: responseMeta.from,
        itemsTo: responseMeta.to,
        withTotals: responseMeta.lastPage === 9223372036854776000 ? 0 : 1,
      })

      savedPagination.currentPage = responseMeta.currentPage;
      savedPagination.lastPage = responseMeta.lastPage;
      savedPagination.perPage = responseMeta.perPage;
      savedPagination.totalItems = responseMeta.total;
      savedPagination.itemsFrom = responseMeta.from;
      savedPagination.itemsTo = responseMeta.to;
      savedPagination.withTotals = responseMeta.lastPage === 9223372036854776000 ? 0 : 1;
    },

    /*
        PAGINATION
     */
    setItemsPerPage(state, {tableIdentifier, perPage}) {
      let pagination = findPagination(state, tableIdentifier);
      pagination.perPage = perPage;
    },

    setItemsWithTotals(state, {tableIdentifier, withTotals}) {
      let pagination = findPagination(state, tableIdentifier);
      pagination.withTotals = withTotals;
    },

    setCurrentPage(state, {tableIdentifier, page}) {
      let pagination = findPagination(state, tableIdentifier);
      pagination.currentPage = page;
    },

    /*
        SORT
     */
    updateTableSort(state, {tableIdentifier, key, sort, dataField}) {
      let savedSort = findOrCreateSort(state, {tableIdentifier, key, sort, dataField});
      savedSort.sort = sort;
    },
    clearTableSort(state, tableIdentifier) {
      state.sorts = state.sorts.filter(s => s.tableIdentifier !== tableIdentifier);
    },
    deleteTableSort(state, {tableIdentifier, key}) {
      state.sorts = state.sorts.filter(s => {
        if (s.tableIdentifier !== tableIdentifier) {
          return true;
        }
        return s.key !== key;
      });
    },

    /*
        TOGGLEABLE COLUMNS (Visibility)
     */
    clearToggleableColumns(state, tableIdentifier) {
      state.toggleAbleColumns = state.toggleAbleColumns.filter(s => s.tableIdentifier !== tableIdentifier);
    },
    updateToggleableColumn(state, {tableIdentifier, name, visible = false}) {
      let savedColumn = findOrCreateToggleAbleColumn(state, {tableIdentifier, name, visible});
      savedColumn.visible = visible;
    },


    /*
        SELECTION
     */
    selectRow(state, value) {
      selectRow(state, value);
    },
    unselectRow(state, value) {
      unselectRow(state, value);
    },
    clearAllSelectedRows(state) {
      state.selectedRows = [];
    },

    /**
     * Batch Errors
     */
    setBatchErrors(state, errors) {
      state.batchErrors = errors;
    },
    setBatchReturnedData(state, result) {
      state.batchResult = result;
    },
    resetBatchReturnedData(state) {
      state.batchResult = null;
    },
    clearBatchErrors(state) {
      state.batchErrors = [];
    }
  },
}
