<template>
  <table-row :class="{'bg-red-100': product.isUnavailable}">
    <template v-if="isOfTypeProduct">
      <table-data-cell class="w-24">
        <div class="flex items-center gap-x-4">
                    <span
                      v-if="isReference"
                      class="ml-4"
                    >
                        ↪
                    </span>

          <product-quick-action-modal :id="product.id"/>
        </div>
      </table-data-cell>
      <table-data-cell class="w-44">
        <span>{{ product.sellingBundleContent }}{{ product.sellingBundleUnit }}</span>
      </table-data-cell>
    </template>

    <template v-else>
      <table-data-cell v-if="isReference">
        <div
          class="ml-4"
        >
          ↪
        </div>
      </table-data-cell>

      <table-data-cell :colspan="!isReference ? 2 : 1">
        <div class="flex gap-x-3">
          <div class="bg-gray-200 rounded px-2 w-max">
            {{ getBadgeTextByType }}
          </div>
        </div>
      </table-data-cell>
    </template>


    <table-data-cell>
      <div class="flex flex-row items-center gap-x-4">
        <tooltip v-if="product.isUnavailable" text="Le produit n'est pas disponible.">
          <fa-icon class="text-red-500" fixed-width icon="exclamation-triangle"/>
        </tooltip>

        <span>
                    {{ product.name }}
                </span>
      </div>
    </table-data-cell>

    <table-data-cell align="center">
      <div
        v-if="!readOnly && isOfTypeProduct"
        class="flex justify-center"
      >
        <quantity-selector-element
          :quantity.sync="product.quantity"
          confirm-delete
          delete-confirmation-title="Retirer produit?"
          size="small"
          type="light"
          @update:quantity="onQuantityChange(product.quantity)"
        />
      </div>

      <span v-else>{{ product.quantity }}</span>
    </table-data-cell>


    <table-data-cell align="right">
      <money-amount :amount="product.price.amount"/>
    </table-data-cell>

    <table-data-cell align="center">
      <percentage-amount>{{ product.price.vat | intToPercentageString }}</percentage-amount>
    </table-data-cell>

    <table-data-cell align="center">
      <span v-if="product.deliveryTime !== undefined">{{ product.deliveryTime }} jours</span>
    </table-data-cell>

    <table-data-cell>
            <span
              v-if="product.createdAt !== null">{{ this.$date(product.createdAt).format('DD.MM.YYYY HH:mm') }}</span>
    </table-data-cell>

    <table-data-cell class="text-right">
      <template v-if="!readOnly && isOfTypeProduct">
        <button-element size="small" type="danger" @click="openRemoveConfirmationModal()">
          <fa-icon icon="trash-alt"></fa-icon>
        </button-element>
      </template>
    </table-data-cell>

    <delete-confirmation-modal
      v-if="isOfTypeProduct"
      ref="removeConfirmationModal"
      title="Enlever produit du panier?" @delete="removeProduct"/>
  </table-row>
</template>

<script>
import TableRow from "../../../../elements/tables/TableRow";
import TableDataCell from "../../../../elements/tables/TableDataCell";
import ButtonElement from "../../../../elements/buttons/ButtonElement";
import ProductQuickActionModal from "../../../../pages/Products/Subcomponents/ProductQuickActionModal";
import QuantitySelectorElement from "../../../../elements/forms/elements/QuantitySelectorElement";
import DeleteConfirmationModal from "../../../../elements/modals/DeleteConfirmationModal";
import {debounce} from "@/helpers/debounceHelper";
import CartRepository from "../../../../../repositories/CartRepository";
import Tooltip from "../../../../elements/tooltips/Tooltip";
import MoneyAmount from "@/components/global/Money/MoneyAmount";
import PercentageAmount from "@/components/global/Percentage/PercentageAmount";

export default {
  name: "CartTableProductRow",
  components: {
    PercentageAmount,
    MoneyAmount,
    Tooltip,
    DeleteConfirmationModal,
    QuantitySelectorElement, ProductQuickActionModal, ButtonElement, TableDataCell, TableRow
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    isReference: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Refreshes the order data from the backend
     */
    refreshCart(showLoadingIndicator = false) {
      return this.$store.dispatch('cart/fetchCart', {memberId: this.getMemberId, showLoadingIndicator});
    },

    /**
     * Triggered whenever one of the quantity related values change.
     */
    onQuantityChange: debounce(function (quantity) {
      if (quantity === null || quantity === undefined) {
        quantity = 0;
      }

      return this.updateQuantity(quantity);
    }, 300),

    /**
     * Updates the quantity of the product.
     */
    updateQuantity(quantity) {
      if (!quantity) {
        return this.removeProduct();
      }

      CartRepository.setItemQuantity(this.getMemberId, this.product.id, quantity);
    },

    /**
     * Deleted this product from the order.
     *
     * @return {Promise<*>}
     */
    removeProduct() {
      return CartRepository
        .removeItem(this.getMemberId, this.product.id)
        .then(() =>
            this.$sendSuccessToast("Produit enlevé du panier.")
        );
    },

    openRemoveConfirmationModal() {
      this.$refs.removeConfirmationModal.openModal();
    },
  },
  computed: {
    isCustomProduct() {
      return this.product.type.toLowerCase() === "custom";
    },

    getProductImage() {
      return this.product.image?.url;
    },

    getMemberId() {
      return this.$store.getters['cart/getMemberId'];
    },

    isOfTypeProduct() {
      return this.product.type.toLowerCase() === "product";
    },

    getBadgeTextByType() {
      switch (this.product.type.toLowerCase()) {
        case "fee":
          return "Frais";
        case "gift":
          return "Cadeaux";
        case "discount":
          return "Remise";
      }
      return "";
    },
    readOnly() {
      return this.$isReadOnly();
    }
  }
}
</script>

