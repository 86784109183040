<template>
  <div>
    <page-title>
      {{ getTitle }}
    </page-title>

    <card>
      <form-wrapper ref="form" :show-changed="true"
                    :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer marque'"
                    @submit="createOrUpdateBrand">
        <div class="grid grid-cols-12 gap-y-4 md:gap-x-8">
          <text-input-element v-model="name" class="col-span-12 lg:col-span-6 xl:col-span-5"
                              label="Nom de la marque"
                              validation-rules="required"></text-input-element>
        </div>
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import Card from "../../../elements/cards/Card";
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import BrandRepository from "../../../../repositories/BrandRepository";
import PageTitle from "../../../elements/pages/PageTitle";

export default {
  name: "CreateOrUpdateBrandForm",
  components: {
    PageTitle,
    TextInputElement,
    FormWrapper,
    Card
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    }
  },
  data: () => ({
    name: ''
  }),
  mounted() {
    this.getBrand();
  },
  methods: {
    getBrand() {
      if (this.isUpdateMode) {
        BrandRepository.getSingle(this.$route.params.brand).then((res) => {
          this.name = res.data.data.name;
        }).then(() => this.$refs.form.resetForm());
      }
    },
    createOrUpdateBrand(callback) {
      if (this.isUpdateMode) {
        return BrandRepository.update(this.$route.params.brand, {
          name: this.name
        }).then(() => {
          this.$sendSuccessToast(`Marque ${this.name} a été modifié!`);
        }).finally(() => callback());
      }

      return BrandRepository.create({
        name: this.name
      }).then((res) => {
        this.$router.push({name: 'brands.edit', params: {brand: res.data.data.id}});
        this.$sendSuccessToast(`Marque ${this.name} a été crée!`);
      }).finally(() => callback());
    },
  },
  computed: {
    isUpdateMode() {
      return this.mode === 'update';
    },
    getBrandId() {
      if (this.isUpdateMode) {
        return parseInt(this.$route.params.brand);
      }
      return null;
    },
    getTitle() {
      if (this.isUpdateMode) {
        return `${this.name} #${this.getBrandId}`;
      }
      return 'Créer nouvelle marque';
    }
  }
}
</script>

