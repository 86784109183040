<!--
 ScannerLister is NOT needed here, as its already included globally in the Dashboard Layout!
 -->
<template>
  <div>
    <transition name="fade">
      <div
        v-if="isScreenLocked"
        class="opacity-100 md:opacity-95 bg-gradient-to-br from-luxcaddy-400 via-luxcaddy-500 to-luxcaddy-500 z-50 fixed inset-0 h-screen w-screen overflow-y-auto"
      >
        <div class="px-4 md:px-0 h-full flex flex-col items-center justify-center">
          <fa-icon class="text-luxcaddy-800 mb-4 hidden md:block" fixed-width icon="lock" size="4x"></fa-icon>
          <h1 class="text-center font-bold md:font-normal text-xl md:text-7xl text-luxcaddy-800 uppercase md:mt-8">
            {{ getUserName }}
          </h1>

          <div class="h-64 mt-4 mt-2 md:mt-16 text-center">
            <barcode-login-handler
              :show-time-clock-button="false"
              text="Scanner votre code-barres pour déverrouiller"
            >
              <button-element
                type="light"
                @click="logout"
              >
                Déconnexion
              </button-element>
            </barcode-login-handler>
          </div>

          <div class="md:absolute bottom-4 md:left-4 hidden md:block">
            <navbar-live-clock custom-classes="mt-0 text-2xl bg-luxcaddy-700 rounded-md"/>

            <div class="bg-luxcaddy-700 py-2 px-4 rounded-md mt-4 w-80 h-24">
              <logo-svg alt="" class="object-contain"/>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import {debounce} from "@/helpers/debounceHelper";
import NavbarLiveClock from "@/components/layouts/dashboard/partials/navbar/NavbarLiveClock";
import authHelper from "@/helpers/authHelper";
import LogoSvg from "@/components/assets/LogoSvg";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import BarcodeLoginHandler from "@/components/pages/Auth/sub/BarcodeLoginHandler.vue";

export default {
  name: "TouchInterfaceLockScreenTimer",
  components: {BarcodeLoginHandler, ButtonElement, LogoSvg, NavbarLiveClock},
  data: () => ({
    lockTimer: null,
    logoutTimer: null,
  }),
  beforeMount() {
    this.$store.commit('lockScreen/syncFromLocalStorage');
  },
  created() {
    this.clearLockTimer();
    this.clearLogoutTimer();

    if (!this.isScreenLocked) {
      this.resetLockTimer();
    }

    window.onmousemove = this.debounceResetLockTimer;
    window.onmousedown = this.debounceResetLockTimer;  // catches touchscreen presses as well
    window.ontouchstart = this.debounceResetLockTimer; // catches touchscreen swipes as well
    window.ontouchmove = this.debounceResetLockTimer;  // required by some devices
    window.onclick = this.debounceResetLockTimer;      // catches touchpad clicks as well
    window.onkeydown = this.debounceResetLockTimer;
  },
  beforeDestroy() {
    this.clearLockTimer();
    this.clearLogoutTimer();

    window.onmousemove = null;
    window.onmousedown = null;
    window.ontouchstart = null;
    window.ontouchmove = null;
    window.onclick = null;
    window.onkeydown = null;
  },
  watch: {
    isScreenLocked(screenLocked) {
      let vm = this;

      if (screenLocked) {
        // Screen hast just been locked
        // Start a timer that logs the user out completely after X minutes
        vm.clearLockTimer();
        vm.clearLogoutTimer();

        this.logoutTimer = setTimeout(function () {
          authHelper.logout().finally(() => {
            vm.$sendSuccessToast("Vous avez été déconnecté pour cause d'inactivité.");
            vm.$router.push({name: 'auth.login.barcode'});
          });
        }, vm.$luxcaddyConfig('touchInterface.lockScreenLogoutAfterMinutes') * 1000 * 60);
      } else {
        // Screen just unlocked, remove Logout Timer, and start Lock timer again
        this.clearLogoutTimer();
        this.resetLockTimer();
      }
    }
  },
  methods: {
    lockScreen() {
      this.$store.commit('lockScreen/lockScreen');

      // Remove focus from currently active element
      if ("activeElement" in document) {
        document.activeElement.blur();
      }
    },

    clearLogoutTimer() {
      if (this.logoutTimer) {
        clearTimeout(this.logoutTimer);
        this.logoutTimer = null;
      }
    },

    clearLockTimer() {
      if (this.lockTimer) {
        clearTimeout(this.lockTimer);
        this.lockTimer = null;
      }
    },
    startLockTimer() {
      if (this.$luxcaddyConfig('touchInterface.enableLockFunctionality') === false) {
        return false;
      }

      let vm = this;
      this.lockTimer = setTimeout(function () {
        vm.lockScreen();
      }, this.$luxcaddyConfig('touchInterface.lockScreenIdleTimeInSeconds') * 1000);
    },
    resetLockTimer() {
      this.clearLockTimer();
      this.startLockTimer();
    },
    debounceResetLockTimer: debounce(function () {
      if (!this.isScreenLocked) {
        this.resetLockTimer();
      }
    }, 30),

    logout() {
      authHelper.logout().then(() => {
        this.$sendSuccessToast("Déconnexion réussie.");
        this.$router.push({name: 'auth.login'});
      });
    },
  },
  computed: {
    getUserName() {
      return this.$store.getters["authUser/getFullName"];
    },

    isScreenLocked() {
      return this.$store.getters['lockScreen/getIsLocked'];
    },
  },
}
</script>

<style scoped>

</style>