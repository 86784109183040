<template>
  <fulfilment-layout-base>
    <touch-optimized-modal ref="modal"></touch-optimized-modal>

    <div class="w-full bg-gray-800 rounded px-2 py-1 text-lg text-gray-200 mb-2">
      <fa-icon class="mr-1" icon="box"></fa-icon>
      Commandes à livrer du {{ $date(getSelectedDay).format('DD.MM.YYYY') }}
    </div>

    <div class="flex justify-end mb-3">
      <tooltip
        text="Actualiser les données"
        tooltip-classes="mt-3 -ml-12"
      >
        <button-element
          type="dark"
          @click="fetchOrderList"
        >
          <fa-icon fixed-width icon="sync"></fa-icon>
        </button-element>
      </tooltip>
    </div>

    <custom-table :headings="getTableHeadings">
      <order-fulfilment-list-entry
        v-for="order in getFulfilmentOrderList"
        :key="order.id"
        :order="order"
      />
    </custom-table>

    <template #sidebar>
      <div class="px-2 h-full w-full pb-2 pt-4 flex flex-col space-y-10 items-center justify-between relative">
        <div class="flex-grow w-full space-y-6">
          <order-fulfilment-list-date-button/>
          <order-fulfilment-list-department-button/>
          <order-fulfilment-list-route-filter-button/>
          <order-fulfilment-list-group-button/>

          <div class="h-px bg-gray-500 mb-3"></div>
          <order-fulfilment-list-sort-button/>
        </div>

        <div class="flex flex-col space-y-2 w-full text-center text-sm">
          <div class="w-full bg-luxcaddy-300 rounded-md">
            {{ getUsername }}
          </div>

          <navbar-live-clock custom-classes="rounded-md bg-gray-700 text-sm"></navbar-live-clock>
        </div>
      </div>
    </template>
  </fulfilment-layout-base>
</template>

<script>
import FulfilmentLayoutBase
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/LayoutBase/FulfilmentLayoutBase";
import CustomTable from "@/components/elements/tables/CustomTable";
import OrderFulfilmentListEntry
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/list/OrderFulfilmentListEntry";
import NavbarLiveClock from "@/components/layouts/dashboard/partials/navbar/NavbarLiveClock";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import OrderFulfilmentListDepartmentButton from "./buttons/OrderFulfilmentListDepartmentButton";
import OrderFulfilmentListDateButton from "./buttons/OrderFulfilmentListDateButton";
import OrderRepository from "@/repositories/OrderRepository";
import {mapGetters} from "vuex";
import OrderFulfilmentListGroupButton
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/list/buttons/OrderFulfilmentGroupButton";
import OrderFulfilmentListRouteFilterButton
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/list/buttons/OrderFulfilmentRouteFilterButton";
import OrderFulfilmentListSortButton
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/list/buttons/OrderFulfilmentListSortButton.vue";
import Tooltip from "@/components/elements/tooltips/Tooltip";
import ButtonElement from "@/components/elements/buttons/ButtonElement";

export default {
  name: "OrderFulfilmentList",
  components: {
    Tooltip,
    ButtonElement,
    OrderFulfilmentListSortButton,
    OrderFulfilmentListRouteFilterButton,
    OrderFulfilmentListGroupButton,
    OrderFulfilmentListDateButton,
    OrderFulfilmentListDepartmentButton,
    TouchOptimizedModal,
    NavbarLiveClock,
    OrderFulfilmentListEntry,
    CustomTable,
    FulfilmentLayoutBase
  },

  mounted() {
    this.fetchOrderList();
  },
  methods: {
    fetchOrderList() {
      let filters = [];

      if (this.getSelectedDepartmentId) {
        filters.push({
          filterKey: "departmentId",
          filterValue: this.getSelectedDepartmentId,
        });
      }

      if (this.getSelectedDay) {
        filters.push({
          filterKey: "date",
          filterValue: this.getSelectedDay
        })
      }

      if (this.getSelectedGroupId) {
        filters.push({
          filterKey: "fulfilmentGroupId",
          filterValue: this.getSelectedGroupId
        })
      }

      if (this.getSelectedRouteId) {
        filters.push({
          filterKey: "routeId",
          filterValue: this.getSelectedRouteId
        })
      }


      // getSelectedSortValue = sortField,direction eg timeSlot,desc
      let split = this.getSelectedSortValue?.split(',');
      let sorts = [];
      if (split !== undefined) {
        sorts = [
          {dataField: split[0], sort: split[1]}
        ];
      }


      OrderRepository
        .fulfilment
        .getFulfilmentList(999, 1, sorts, filters)
        .then((res) => {
          this.$store.commit('fulfilment/setFulfilmentOrderList', res.data.data);
        });
    }
  },
  watch: {
    getFiltersAndSorts() {
      this.fetchOrderList();
    }
  },
  computed: {
    ...mapGetters('fulfilmentFilters', [
      'getSelectedDepartmentId',
      'getSelectedDay',
      'getSelectedGroupId',
      'getSelectedRouteId',
      'getSelectedSortValue',
    ]),
    ...mapGetters('fulfilment', [
      'getFulfilmentOrderList'
    ]),

    getUsername() {
      return this.$store.getters["authUser/getFullName"];
    },

    getTableHeadings() {
      return [
        {label: 'ComID'},
        {label: 'LivID'},
        {label: 'Status'},
        {label: 'Heure'},
        {label: 'Client'},
        {label: 'Total TTC'},
        {label: 'BAC'},
        {label: 'Done'},
        {label: 'Nbr'},
      ];
    },

    // Only used so that we only need one watcher
    getFiltersAndSorts() {
      return `${this.getSelectedDepartmentId}|${this.getSelectedDay}|${this.getSelectedGroupId}|${this.getSelectedRouteId}|${this.getSelectedSortValue}`;
    }
  },
}
</script>

