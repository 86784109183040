<template>
  <div
    :class="{'mb-4': !withoutMargin}"
    class="text-2xl text-luxcaddy text-center md:text-left"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    documentPageTitle: {
      type: String,
      default: null
    },
    withoutMargin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    documentPageTitle(val) {
      document.title = val;
    }
  }
}
</script>

