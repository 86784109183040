<template>
  <modal ref="modal" :blocking="true" :title="title" :width="20" @close="$emit('close')" @open="$emit('open')">
    <slot>Êtes vous sûre?</slot>
     <template #footer>
        <div class="flex flex-wrap gap-4 justify-between">
          <button-element :type="deleteButtonType" class="float-right" @click="onDelete($event)">
            <i class="fa mr-2" :class="deleteButtonIcon" v-if="deleteButtonIcon !== null"></i>
            {{ deleteButtonLabel }}
          </button-element>
          <button-element :type="cancelButtonType" class="float-right" @click="onAbort($event)">
            <i class="fa fa-times mr-2"></i>
            {{cancelButtonLabel}}
          </button-element>
        </div>
    </template>
  </modal>
</template>

<script>
import ButtonElement from "../buttons/ButtonElement";
import Modal from "./Modal";

export default {
  name: "DeleteConfirmationModal",
  components: {Modal, ButtonElement},
  props: {
    title: {
      type: String,
      default: ''
    },
    closeOnAbort: {
      type: Boolean,
      default: true
    },
    closeOnDelete: {
      type: Boolean,
      default: true
    },
    deleteButtonLabel: {
      type: String,
      default: 'Supprimer'
    },
    cancelButtonLabel: {
      type: String,
      default: 'Annuler'
    },
    cancelButtonType: {
      type: String,
      default: 'light'
    },
    deleteButtonIcon: {
      type: String,
      default: 'fa-trash-alt'
    },
    deleteButtonType: {
      type: String,
      default: 'danger'
    }
 },
  methods: {
    onDelete($event) {
      if (this.closeOnDelete) {
        this.closeModal();
      }

      this.$emit('delete', $event);
    },
    onAbort($event) {
      if (this.closeOnAbort) {
        this.closeModal();
      }

      this.$emit('abort', $event);
    },
    openModal() {
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
  }
}
</script>

