import config from "@/luxcaddyConfig";
import {
  centsToDollarString,
  centsToEuro,
  centsToEuroString,
  centsToMoneyString,
  intToPercentageString
} from "@/helpers/mathHelper";
import {languageByIsoCode} from "@/helpers/languageHelper";
import Vue from "vue";
import {vue} from "@/main";

require("./operatorFilters");

Vue.filter('centsToEuro', function (value) {
  return centsToEuro(value);
})

Vue.filter('centsToEuroString', function (value) {
  return centsToEuroString(value);
})

Vue.filter('centsToDollarString', function (value) {
  return centsToDollarString(value);
})

Vue.filter('centsToMoneyString', function (value, currency) {
  return centsToMoneyString(value, currency);
});

Vue.filter('intToPercentageString', function (value) {
  return intToPercentageString(value);
})

Vue.filter('toCountryNameString', function (value) {
  if (value.toLowerCase() === "lu") {
    return "Luxembourg";
  }

  return vue?.$store?.getters['global/getCountries'].find(c => c.isoCode === value)?.name || value.toUpperCase();
});

Vue.filter('languageByIsoCode', function (isoCode) {
  return languageByIsoCode(isoCode);
});

Vue.filter('paymentProviderKeyToReadable', function (value) {
  return config
    .invoice
    .paymentTransactions
    .paymentProviders
    .find(provider => provider.value === value)
    ?.label || value;
})