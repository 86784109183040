import auth from "../middlewares/authMiddleware";
import ShowProductReportsValorlux from "@/components/pages/ProductReports/Valorlux/ShowProductReportsValorlux";
import ShowProductReportsStatec from "@/components/pages/ProductReports/Statec/ShowProductReportsStatec";

export default [
  {
    path: '/product-reports/valorlux',
    name: 'products.reports.valorlux.list',
    component: ShowProductReportsValorlux,
    meta: {
      middleware: [auth],
      title: 'Rapport Valorlux',
      permissions: ['PRODUCT_VALORLUX_READ']
    }
  },
  {
    path: '/product-reports/statec',
    name: 'products.reports.statec.list',
    component: ShowProductReportsStatec,
    meta: {
      middleware: [auth],
      title: 'Rapport Statec',
      permissions: ['PRODUCT_STATEC_READ']
    }
  },
];