import apiService from './Base/BaseRepository';
import {vue} from "../main";

const url = '/categories';


export default {
  /**
   * Fetches all categories.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get(type = 'product', isVirtual = false, filters = []) {
    let isVirtualFilter = isVirtual ? 1 : 0;
    filters.push({
      filterKey: 'isVirtual',
      filterValue: isVirtualFilter
    });

    return await apiService
      .setFilters(filters)
      .get(`${url}/?type=${type}`);
  },

  /**
   * Returns a single category with its items
   * @param categoryId
   * @return {Promise<AxiosResponse<any>>}
   */
  async getSingle(categoryId) {
    let newUrl = `${url}/${categoryId}`;

    return await apiService
      .get(newUrl);
  },

  /**
   * Creates a new category.
   *
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async createCategory(payload) {
    return await apiService.post(`${url}/`, payload).then(() => {
      return vue.$store.dispatch('categories/refetchCategoriesForCurrentType');
    });
  },

  /**
   * Updates the name of the category.
   *
   * @param categoryId
   * @param name
   * @return {Promise<AxiosResponse<any>>}
   */
  async updateCategory(categoryId, payload) {
    return await apiService.patch(`${url}/update/${categoryId}`, payload).finally(() => {
      return vue.$store.dispatch('categories/refetchCategoriesForCurrentType');
    });
  },

  /**
   * Published a category
   *
   * @param categoryId
   * @return {Promise<AxiosResponse<any>>}
   */
  async enable(categoryId, refetch = true) {
    return await apiService.patch(`${url}/${categoryId}/publish`).finally(() => {
      if (refetch) {
        return vue.$store.dispatch('categories/refetchCategoriesForCurrentType');
      }
    });
  },

  /**
   * Unpublished a category
   *
   * @param categoryId
   * @return {Promise<AxiosResponse<any>>}
   */
  async disable(categoryId) {
    return await apiService.patch(`${url}/${categoryId}/unpublish`).finally(() => {
      return vue.$store.dispatch('categories/refetchCategoriesForCurrentType');
    });
  },

  /**
   * Deletes a category
   *
   * @param categoryId
   * @return {Promise<AxiosResponse<any>>}
   */
  async delete(categoryId) {
    return await apiService.delete(`${url}/${categoryId}`).finally(() => {
      return vue.$store.dispatch('categories/refetchCategoriesForCurrentType');
    });
  },
  async setIsHomepage(categoryId, isHomepage = false) {
    if (isHomepage) {
      return await apiService.patch(`${url}/${categoryId}/markAsHomepage`).finally(() => {
        return vue.$store.dispatch('categories/refetchCategoriesForCurrentType');
      });
    } else {
      return await apiService.patch(`${url}/${categoryId}/unmarkAsHomepage`).finally(() => {
        return vue.$store.dispatch('categories/refetchCategoriesForCurrentType');
      });
    }
  },

  async switchCategoryBetweenVirtualAndReal(categoryId) {
    return await apiService.patch(`${url}/${categoryId}/switch`).finally(() => {
      return vue.$store.dispatch('categories/refetchCategoriesForCurrentType');
    });
  },

  /**
   * Sets the products of a category
   *
   * @param categoryId
   * @param productsArray
   * @return {Promise<AxiosResponse<any>>}
   */
  async setProducts(categoryId, productsArray) {
    return await apiService.patch(`${url}/products/set`, {
      products: productsArray,
      category: categoryId
    });
  },

  /**
   * Sets the products of a category
   *
   * @param categoryId
   * @param blogPostArray
   * @return {Promise<AxiosResponse<any>>}
   */
  async setBlogPosts(categoryId, blogPostArray) {
    return await apiService.patch(`${url}/blogPosts/set`, {
      blogPosts: blogPostArray,
      category: categoryId
    });
  },

  /**
   * Copies the given products to another category
   *
   * @param categoryId
   * @param productsArray
   * @return {Promise<AxiosResponse<any>>}
   */
  async copyProducts(categoryId, productsArray) {
    return await apiService.patch(`${url}/products/copy`, {
      products: productsArray,
      destinationCategory: categoryId
    });
  },

  /**
   * Moves the given products to another category
   *
   * @param sourceCategoryId
   * @param destinationCategoryId
   * @param productsArray
   * @return {Promise<AxiosResponse<any>>}
   */
  async moveProducts(sourceCategoryId, destinationCategoryId, productsArray) {
    return await apiService.patch(`${url}/products/move`, {
      products: productsArray,
      sourceCategory: sourceCategoryId,
      destinationCategory: destinationCategoryId
    });
  },


  /**
   * Copies the given blog posts to another category
   *
   * @param categoryId
   * @param blogPostsArray
   * @return {Promise<AxiosResponse<any>>}
   */
  async copyBlogPosts(categoryId, blogPostsArray) {
    return await apiService.patch(`${url}/blogPosts/copy`, {
      blogPosts: blogPostsArray,
      destinationCategory: categoryId
    });
  },

  /**
   * Moves the given blog posts to another category
   *
   * @param sourceCategoryId
   * @param destinationCategoryId
   * @param blogPostsArray
   * @return {Promise<AxiosResponse<any>>}
   */
  async moveBlogPosts(sourceCategoryId, destinationCategoryId, blogPostsArray) {
    return await apiService.patch(`${url}/blogPosts/move`, {
      blogPosts: blogPostsArray,
      sourceCategory: sourceCategoryId,
      destinationCategory: destinationCategoryId
    });
  },

  /**
   *
   * @param categoryId
   * @param childrenIds
   * @return {Promise<AxiosResponse<any>>}
   */
  async setChildCategories(categoryId, childrenIds) {
    return await apiService.patch(`${url}/${categoryId}/setChildCategories`, {
      categories: childrenIds
    });
  },

  /**
   *
   * @param categoryType
   * @param childrenIds
   * @return {Promise<AxiosResponse<any>>}
   */
  async setRootCategories(categoryType = 'product', childrenIds, isVirtual = false) {
    return await apiService.patch(`${url}/setRootCategories`, {
      type: categoryType,
      categories: childrenIds,
      isVirtual: isVirtual
    });
  },


  localization: {
    async getForCategory(categoryId) {
      return await apiService.get(`${url}/${categoryId}/localization`);
    },

    async saveForCategory(categoryId, payload) {
      return await apiService.post(`${url}/${categoryId}/localization`, payload);
    },

    async translate(categoryId, sourceLanguage, targetLanguages, attributes) {
      return await apiService.post(`${url}/${categoryId}/localization/translation`, {
        sourceLanguage, targetLanguages, attributes
      });
    }
  }
}
