<template>
  <update-localization-form
    v-if="localizations && render"
    :localizable-id="productId"
    :localizations="localizations"
    localizable-type="product"
    @submit="saveLocalizations"
  >
    <product-localization-api-merge
      v-if="localizationsLoaded"
      :existing-localizations.sync="localizations"
      :product-id="productId"
      @reload="reRender"
    />
  </update-localization-form>
</template>

<script>
import ProductRepository from "../../../../../repositories/ProductRepository";
import UpdateLocalizationForm from "../../../../forms/UpdateLocalizationForm";
import ProductLocalizationApiMerge from "@/components/pages/Products/Forms/Localization/ProductLocalizationApiMerge";

export default {
  name: "UpdateProductLocalizationForm",
  components: {ProductLocalizationApiMerge, UpdateLocalizationForm},
  props: {
    productId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    localizations: null,
    render: true,

    localizationsLoaded: false,
  }),
  beforeDestroy() {
    this.$store.commit('localization/translation/clear');
  },
  created() {
    this.$store.commit('localization/translation/clear');
    this.getLocalizations();
  },
  methods: {
    getLocalizations() {
      ProductRepository.localization.getForProduct(this.productId).then((res) => {
        this.localizations = res.data.data;
        this.localizationsLoaded = true;
      })
    },
    saveLocalizations(payload) {
      ProductRepository
        .localization
        .saveForProduct(this.productId, {
          localizableData: payload
        })
        .then(() => {
          this.getLocalizations();
          this.$sendSuccessToast('Traduction sauvegardé!')
        })
    },
    reRender() {
      this.render = false;
      this.$nextTick(() => this.render = true);
    }
  },
}
</script>

