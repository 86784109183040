<template>
  <card class="min-w-36 border-0 bg-white hover:shadow-xl select-none rounded-lg">
    <div class="flex justify-between items-center gap-x-12">
      <div class="flex flex-col flex-wrap gap-y-2">
        <div class="text-md font-bold text-gray-600">{{ fee.name }}</div>

        <div class="text-yellow-500 text-lg font-medium">
          {{ fee.price | centsToEuroString }}
        </div>
      </div>

      <add-amount-element v-model="quantityToAdd" @add="addFee"></add-amount-element>
    </div>
  </card>
</template>

<script>
import Card from "../../../../elements/cards/Card";
import AddAmountElement from "../../../../elements/forms/elements/AddAmountElement";

export default {
  name: "FeeCard",
  components: {AddAmountElement, Card},
  data: () => ({
    quantityToAdd: 1
  }),
  props: {
    fee: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      default: "order"
    }
  },
  methods: {
    addFee() {
      let payload = {
        feeId: this.fee.id,
        toBePackedQuantity: this.quantityToAdd
      };

      if (this.$store.getters['order/isReferencingItem']) {
        payload.reference = this.$store.getters['order/getReferencedItemId'];
      }

      this.$store.dispatch('order/addFeeToOrder', payload).then(() => {
        this.$sendSuccessToast("Les frais ont été ajoutés.");
        this.$emit('added');
      });
    }
  },
  computed: {
    isInOrderContext() {
      return this.context === "order";
    }
  },
}
</script>

