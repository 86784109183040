const bannerTypeImage = 'image';
const bannerTypeText = 'text';

const config = {
  touchInterface: {
    enableLockFunctionality: true,
    lockScreenIdleTimeInSeconds: 1800,// 30 Mins // The time in seconds the user needs to be idle, before the screen is locked
    lockScreenLogoutAfterMinutes: 30, // 30 Mins // The time in minutes after which the user will be logged out after the screen has been locked

    fruitsVegetableDepartmentId: 1,
  },

  home: {
    charts: {
      colors: {
        primary: '#7E9036',
        secondary: '#b1b1b1'
      }
    }
  },

  supportedLocalizationLanguages: [
    {label: 'Allemand', value: 'de'},
    {label: 'Anglais', value: 'en'},
    {label: 'Français', value: 'fr'},
    {label: 'Luxembourgeois', value: 'lb'},
  ],

  member: {
    customer: {
      allowedPaymentProviders: [
        {label: 'Carte de credit (Saferpay)', value: 'saferpay'},
        {label: 'Payconiq', value: 'payconiq'},
        {label: 'Sur Facture', value: 'invoice'},
        {label: 'Sur Facture (Mensuel)', value: 'monthlyInvoice'},
      ]
    }
  },

  languages: [
    {value: "de", label: "Allemand"},
    {value: "en", label: "Anglais"},
    {value: "fr", label: "Français"},
    {value: "lb", label: "Luxembourgeois"}
  ],

  zoneList: [1, 2, 3, 4, 5, 6, 7, 8, 11],

  tagTypes: [
    {label: 'Labelle', value: 'label'},
    {label: 'Allergènes', value: 'allergen'},
    {label: 'Kirsch', value: 'kirsch'},
    {label: 'Misc', value: 'misc'},
    {label: 'Peut contenir traces', value: 'tracesOf'},
  ],

  product: {
    labels: {
      shelf: {
        size: ['medium', 'small', 'mini']
      }
    },
    images: {
      maxFileSizePerImage: (5 * 1024 * 1024), // 5MB
      availableLabels: [
        {label: 'Primaire', value: 'primary'},
        {label: 'Étiquette arrière', value: 'backlabel'},
      ],
    },
    stock: {
      allowedPreOrderDays: [
        {label: 'Non', value: 0},
        {label: '1 jour', value: 1},
        {label: '2 jours', value: 2},
        {label: '3 jours', value: 3},
      ],
      stockModificationReasons: [
        'Arrivage fournisseur',
        'Consommation itix',
        'Périmé',
        'Non livré / Manquant',
        'Cassé',
        'Correction',
        'Achat supermarché'
      ],
      stockModificationReasonsScanInterface: [
        {label: 'Inventaire', value: 'Scan Inventaire', buttonType: "primary"},
        {label: 'Périmé', value: 'Scan Périmé', buttonType: "light"},
        {label: 'Cassé', value: 'Scan Cassé', buttonType: "danger"},
      ],
    },
    supplierUnit: [
      {label: 'Barqu.', value: "bq"},
      {label: 'Bidon.', value: "bi"},
      {label: 'Bocal', value: "bc"},
      {label: 'Boite', value: "bt"},
      {label: 'Botte', value: "bo"},
      {label: 'Boute.', value: "bu"},
      {label: 'Caisse', value: "ca"},
      {label: 'Carton', value: "cr"},
      {label: 'Douzai', value: "dz"},
      {label: 'Embal.', value: "em"},
      {label: 'Filet', value: "fi"},
      {label: 'Goblet', value: "go"},
      {label: 'Kilo', value: "kg"},
      {label: 'Litre', value: "lt"},
      {label: 'Main', value: "ma"},
      {label: 'Palette', value: "pa"},
      {label: 'Paquet', value: "pq"},
      {label: 'Pièce', value: "pc"},
      {label: 'Plate.', value: "pl"},
      {label: 'Poche', value: "ph"},
      {label: 'Pot', value: "po"},
      {label: 'Ravier', value: "ra"},
      {label: 'Sac', value: "sc"},
      {label: 'Sachet', value: "sa"},
      {label: 'Tresse', value: "tr"},
      {label: 'Verre', value: "ve"}
    ]
  },
  order: {
    availableStatuses: [
      {label: 'Initialisée', value: 'initialized', chartColor: '#d5d5d5'},
      {label: 'Confirmée', value: 'confirmed', chartColor: '#b1b1b1'},
      {label: 'Annulée', value: 'canceled', chartColor: '#a34e4e'},
      {label: 'Draft', value: 'draft', chartColor: '#a34e4e'},
    ],
    availableFulfillmentStatuses: [
      {label: 'En attente', value: 'fulfilmentNotStarted', chartColor: '#d5d5d5'},
      {label: 'En préparation', value: 'inFulfilment', chartColor: '#b1b1b1'},
      {label: 'Complète', value: 'fulfilled', chartColor: '#549c67'},
      {label: 'Préparée', value: 'fulfilmentFinalized', chartColor: '#a34e4e'},
    ],
    itemTypes: [
      'Product',
      'Fee',
      'Gift',
      'Deposit',
      'Package',
      'Discount',
      'Fixed'
    ],
  },
  delivery: {
    availableStatuses: [
      {label: 'Initialisée', value: 'initialized', chartColor: '#d5d5d5'},
      {label: 'Confirmée', value: 'confirmed', chartColor: '#b1b1b1'},
      {label: 'Annulée', value: 'canceled', chartColor: '#a34e4e'},
      {label: 'Livrée', value: 'delivered', chartColor: '#549c67'},
      {label: 'En route', value: 'enRoute', chartColor: '#549c67'},
    ],
  },
  invoice: {
    availableStatuses: [
      {label: 'Initialisée', value: 'initialized', chartColor: '#d5d5d5'},
      {label: 'Initialisée(menuselle)', value: 'monthlyInitialized', chartColor: '#d5d5d5'},
      {label: 'Finalise', value: 'finalized', chartColor: '#b1b1b1'},
      {label: 'Finalise(mensuelle)', value: 'monthlyFinalized', chartColor: '#b1b1b1'},
      {label: 'Draft', value: 'draft', chartColor: '#a34e4e'},
    ],
    availablePaymentStatuses: [
      {label: 'Payé', value: 'paid'},
      {label: 'Partiellement payé', value: 'partiallyPaid'},
      {label: 'Non payé', value: 'unpaid'},
      {label: 'Surpayé', value: 'overPaid'},
      {label: 'Paiement en cours', value: 'paymentPending'},
    ],
    external: {
      availableCurrencies: [
        {currency: 'EUR', symbol: '€'},
        {currency: 'USD', symbol: '$'},
      ]
    },
    paymentTransactions: {
      paymentProviders: [
        {label: 'Saferpay', value: 'saferpay'},
        {label: 'Payconiq', value: 'payconiq'},
        {label: 'Wallet', value: 'wallet'},
        {label: 'Virement bancaire', value: 'bankTransfer'},
        {label: 'Ajustement du paiement', value: 'paymentAdjustment'},
        {label: 'Insurance', value: 'insurance'},
        {label: 'Old system', value: 'OLD-SYSTEM'},
        {label: 'Old VISA System', value: 'OLD-VISA-SYSTEM'},
        {label: 'Before payments', value: 'OLD-PAID-SYSTEM'},
      ],
      manualPaymentProviders: [
        {label: 'Ajustement du paiement', value: 'paymentAdjustment'},
        {label: 'Insurance', value: 'insurance'},
        {label: 'Old system', value: 'OLD-SYSTEM'},
        {label: 'Old VISA System', value: 'OLD-VISA-SYSTEM'},
        {label: 'Before payments', value: 'OLD-PAID-SYSTEM'},
      ],

    },
    paymentTransactionsLog: {
      actions: [
        {label: 'Initialize', value: 'initialize'},
        {label: 'Assert', value: 'assert'},
        {label: 'AuthorizeDirect', value: 'authorizeDirect'},
        {label: 'Cancel', value: 'cancel'},
        {label: 'Capture', value: 'capture'},
        {label: 'Callback', value: 'callback'},
      ],
      status: [
        {label: 'Success', value: 'success'},
        {label: 'Error', value: 'error'}
      ]
    }
  },
  bankMovement: {
    availableStatuses: [
      {label: 'Traitée', value: 'treated'},
      {label: 'Non traité', value: 'untreated'},
      {label: 'Partiellement traité', value: 'partiallyTreated'},
    ],
  },
  tag: {
    images: {
      maxFileSizePerImage: (2 * 1024 * 1024), // 2MB
      availableLabels: [
        {label: 'Icone', value: 'icon'},
      ],
    }
  },
  category: {
    types: [
      {
        value: "Product",
        isVirtual: false,
        canCreateRootCategory: true,
        splitRootCategories: false,
        expandAfterLoad: false,
        editCategoryRouteName: 'categories.product.edit'
      },
      {
        value: "Product",
        isVirtual: true,
        canCreateRootCategory: true,
        splitRootCategories: false,
        expandAfterLoad: false,
        editCategoryRouteName: 'categories.virtual.product.edit'
      },
      {
        value: "BlogPost",
        isVirtual: false,
        canCreateRootCategory: false,
        splitRootCategories: true,
        expandAfterLoad: true,
        editCategoryRouteName: 'categories.blogpost.edit'
      },
    ],

    images: {
      maxFileSizePerImage: (5 * 1024 * 1024), // 5MB
      availableLabels: [
        {label: 'Icone', value: 'icon'},
        {label: 'Header', value: 'header'},
      ],
    }
  },
  arrivalTemperature: {
    departments: [
      {label: "Surgelé", value: "frozen"},
      {label: "Crèmerie", value: "dairy"},
      {label: "Viande", value: "meat"},
      {label: "Poisson", value: "fish"},
    ]
  },
  blogPost: {
    images: {
      maxFileSizePerImage: (5 * 1024 * 1024), // 5MB
      availableLabels: [
        {label: 'Primaire', value: 'primary'},
        {label: 'Header', value: 'header'},
        {label: 'En ligne 1', value: 'inline1'},
        {label: 'En ligne 2', value: 'inline2'},
        {label: 'En ligne 3', value: 'inline3'},
        {label: 'En ligne 4', value: 'inline4'},
        {label: 'En ligne 5', value: 'inline5'},
        {label: 'En ligne 6', value: 'inline6'},
        {label: 'En ligne 7', value: 'inline7'},
        {label: 'En ligne 8', value: 'inline8'},
      ],
    },
    identifier: {
      available: [
        {label:'PACKAGING_AND_DEPOSIT_INFO', value:'PACKAGING_AND_DEPOSIT'},
        {label:'HOW_TO_PAY_ORDER_INFO', value:'HOW_TO_PAY_ORDER'},
        {label:'ONCLICK_PAYMENT_INFO', value:'ONCLICK_PAYMENT'},
        {label:'NO_ELEVATOR_INFO', value:'NO_ELEVATOR'},
        {label:'NO_ELEVATOR_INFO', value:'NO_ELEVATOR'},
        {label:'CREATE_SHOPPING_LIST_INFO', value:'CREATE_SHOPPING_LIST'},
      ]
    }
  },
  banner: {
    pageLinkTypes: [
      // Supported By both text & image type
      {
        model: "Home",
        label: "Page principale",
        canBeLinkedTo: true,
        requiresId: false,
        supportedTypes: [bannerTypeImage, bannerTypeText],
        placements: {
          image: ['Main', 'Secondary'],
          text: ['InfoBox'],
        }
      },

      // Supported by text only
      {
        model: "PaymentSelection",
        label: "Caisse - Sélection mode de payment",
        canBeLinkedTo: false,
        requiresId: false,
        supportedTypes: ['text'],
        placements: {
          text: ['InfoBox'],
        }
      },

      // Supported only by image type
      {
        model: "BlogPost",
        label: "Article Blog / Recette",
        canBeLinkedTo: true,
        supportedTypes: ['image'],
        requiresId: true,
        placements: {
          image: ["Secondary"]
        }
      },
      {
        model: "Product",
        label: "Produit",
        canBeLinkedTo: true,
        requiresId: true,
        supportedTypes: ['image'],
        placements: {
          image: ["Main", "Secondary"]
        }
      },
      {
        model: "ProductCategory",
        label: "Catégorie Produit",
        canBeLinkedTo: true,
        supportedTypes: ['image'],
        requiresId: true,
        placements: {
          image: ["Secondary"]
        }
      },
      {
        model: "Brand",
        label: "Marque produit",
        canBeLinkedTo: true,
        supportedTypes: ['image'],
        requiresId: true,
        placements: {
          image: ["Main", "Secondary"]
        }
      },
    ],


    bannerableTypes: [
      {model: "BlogPost", label: "Article Blog / Recette"},
      {model: "Product", label: "Produit"},
      {model: "Category", label: "Catégorie Produit"},
    ],
    placementList: [
      {value: "Main", label: "Primaire"},
      {value: "Secondary", label: "Secondaire"},
      {value: "InfoBox", label: "Box Informations"},
    ],
    images: {
      maxFileSizePerImage: (5 * 1024 * 1024), // 5MB
      availableLabels: [
        {label: 'Primaire', value: 'primary'},
      ],
    },
  },

  route: {
    planningObjectives: [
      {label: 'Réduire les livraisons non attribués', value: 'minimizeUnassigned'},
      {label: 'Minimiser la durée', value: 'minimizeDuration'},
      {label: 'Nombre minimum des routes', value: 'minimizeTourCount', disabledIfSelected: 'maximizeTourCount'},
      {label: 'Minimiser les coûts', value: 'minimizeCost'},
      {label: 'Nombre maximum des routes', value: 'maximizeTourCount', disabledIfSelected: 'minimizeTourCount'},
    ]
  },

  reportQueries: {
    graphTypes: [
      {label: 'Graphique des beignets (Donut)', value: 'Donut'},
      {label: 'Graphique avec barres verticales', value: 'VerticalBars'},
      {label: 'Graphique avec barres horizontales', value: 'HorizontalBars'},
    ]
  },

  notifications: {
    types: [
      {label: "Information (Bleu)", value: "info", color: "blue-500"},
      {label: "Avertissement (Jaune)", value: "warning", color: "yellow-500"},
      {label: "Critique (Rouge)", value: "danger", color: "red-500"},
    ]
  },

  logs: {
    status: [
      {label: 'Success', value: 'success'},
      {label: 'Error', value: 'error'}
    ]
  },

  campaigns: {
    availableTypes: [
      {label: 'Panier', value: 'Cart'},
      {label: 'Produit', value: 'Product'},
    ],
    availableValueTypes: [
      {label: 'Remise Pourcentage', value: 'discount', icon: 'percent'},
      {label: 'Remise Fixe', value: 'fixed', icon: 'euro-sign'},
      {label: 'Cadeau', value: 'gift', icon: 'gift'},
    ],
  }
};

export default config;

export const categoryTypes = () => config.category.types.flatMap(c => c.value);
export const getCategoryConfigByType = (type, isVirtual = false) => config.category.types.find(c => c.value === type && c.isVirtual === isVirtual);