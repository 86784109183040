<template>
  <div
    :class="[getClasses, getSizeClasses]"
    class="rounded-full select-none w-min whitespace-nowrap bg-opacity-80"
    @click="$emit('click', $event)"
  >
    <div class="flex gap-x-2 items-center whitespace-nowrap flex-nowrap font-bold">
      <fa-icon
        v-if="withIcon"
        :icon="getIcon"
        fixed-width
      />

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusIndicator",
  props: {
    type: {
      type: String,
      validator: val => [
        'primary',
        'danger',
        'warning',
        'light',
        'dark'
      ].includes(val),
      default: "primary"
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    customClasses: {
      type: String,
      default: null
    }
  },
  computed: {
    getClasses() {
      if (this.customClasses) {
        return this.customClasses;
      }

      switch (this.type) {
        case "primary":
        default:
          return "bg-luxcaddy-600 text-white";
        case "danger":
          return "bg-red-500 text-red-100";
        case "warning":
          return "bg-yellow-500 text-yellow-700"
        case "light":
          return "bg-gray-100 text-dark";
        case "dark":
          return "bg-gray-900 text-white";
      }
    },
    getIcon() {
      if (this.icon) {
        return this.icon;
      }

      if (!['primary', 'danger', 'warning'].includes(this.type)) {
        return null;
      }

      return this.type === "primary"
        ? 'check'
        : 'times';
    },
    getSizeClasses() {
      if (this.small) {
        return "px-3 py-0.5 text-sm";
      }
      return "px-3 py-1.5";
    }
  }
}
</script>

<style scoped>

</style>