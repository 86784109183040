export default {
  props: {
    /**
     * Determines whether the input should still be active, working
     * and able to edit in readOnly mode.
     */
    activeInReadOnly: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to occupy space for the validation message.
     */
    label: {
      type: String,
      required: false
    },
    /**
     * The validation rules applied on the input.
     */
    validationRules: {
      type: String,
      required: false,
    },
    /**
     * Whether the input is disabled.
     */
    disabled: {
      type: Boolean,
      required: false,
    },
    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    /**
     * Additional css classes that are applied on the input.
     */
    additionalInputClasses: {
      type: String,
      required: false,
      default: ''
    },
  },
  watch: {
    value: function () {
      if (this.$store.getters['userInterface/isValidationInAggressiveErrorDisplayMode']) {
        this.$store.commit('userInterface/setValidationAggressiveErrorDisplayMode', false);
      }
    }
  },
  computed: {
    isInReadOnlyMode() {
      return this.$isReadOnly() && !this.activeInReadOnly;
    },
    /**
     * Determines if the input is required to be filled out.
     * @return {boolean}
     */
    isRequired() {
      if (!this.validationRules) {
        return false;
      }

      let rules = this.validationRules.split('|');
      return rules.includes("required");
    },
    /**
     * Determines if the input should show validation errors in aggressive mode (red text).
     * @return {boolean}
     */
    aggressiveErrorDisplay() {
      return this.$store.getters['userInterface/isValidationInAggressiveErrorDisplayMode'];
    },
  }
};
