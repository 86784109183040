<template>
  <text-input-element
    :inset-position="insetPosition"
    :inset-text="insetText"
    :label="label"
    :occupy-validation-space="occupyValidationSpace"
    :validation-rules="getValidationRules"
    :value="value"
    @enter="$emit('enter', $event)"
    @escape="$emit('escape', $event)"
    @input="$emit('input', $event)"
  ></text-input-element>
</template>

<script>
import TextInputElement from "./base/TextInputElement";

export default {
  name: "EmailInputElement",
  components: {
    TextInputElement
  },
  data: () => ({
    errorMessage: ''
  }),
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      default: 'Email'
    },
    validationRules: {
      type: String,
      default: ''
    },

    insetText: {
      type: String,
      required: false
    },

    insetPosition: {
      type: String,
    },

    occupyValidationSpace: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getValidationRules() {
      return this.validationRules?.length ? `${this.validationRules}|email` : 'email';
    }
  }

}
</script>

