/**
 * This store module keeps a list of all forms (<FormWrapper.vue>) that are currently visible to the user.
 * @return {{displayedForms: *[]}}
 */

const getDefaultState = () => {
  return {
    displayedForms: []
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getDisplayedForms: state => state.displayedForms,
    isAnyFormDisplayed: state => state.displayedForms.length,
    anyDisplayedFormChanged: state => (state.displayedForms.filter(f => f.changed).length || 0) > 0,
  },
  mutations: {
    addDisplayedForm(state, payload) {
      state.displayedForms.push(payload);
    },
    removeDisplayedForm(state, id) {
      state.displayedForms = state.displayedForms.filter(f => f.id !== id);
    },
    updateDisplayedForm(state, {id, changed}) {
      state.displayedForms.find(f => f.id === id).changed = changed;
    },
    /**
     * Clears the displayed forms state. You'll usually want to do this on a route change.
     * @param state
     */
    clearAllDisplayedForms(state) {
      state.displayedForms = [];
    }
  },
  actions: {},
}