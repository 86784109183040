export const FILTER_TEXT = 'text';
export const FILTER_NUMERIC = 'numeric';
export const FILTER_CURRENCY = 'currency';
export const FILTER_EMAIL = 'email';
export const FILTER_DATETIME = 'datetime';
export const FILTER_DATETIME_RANGE = 'datetimeRange';
export const FILTER_SELECT_SINGLE = 'selectSingle';
export const FILTER_SELECT_MULTI = 'selectMulti';
export const FILTER_TAG_TYPE = 'tagType';
export const FILTER_PRODUCT_CATEGORY = 'productCategory';
export const FILTER_LANGUAGE_SELECT = 'language';
export const FILTER_SUPPLIER = 'supplier';
export const FILTER_PRODUCT_SUPPLIER = 'productSupplier';

export const FILTER_PRODUCT_SUPPLIER_MULTI = 'productSupplierMulti';

export const FILTER_BOOKING_ACCOUNT = 'bookingAccount';
export const FILTER_BOOLEAN = 'boolean';
export const FILTER_SINGLE_BOOLEAN = 'singleBoolean';
export const FILTER_PRODUCT_TAG_SINGLE = 'tagSingle';
export const FILTER_PRODUCT_TAG_MULTI = 'tagMulti';
export const FILTER_PRODUCT_SINGLE = 'productSingle';
export const FILTER_PRODUCT_MULTI = 'productMulti';
export const FILTER_MEMBER = 'member';
export const FILTER_MEMBER_EMPLOYEES = 'memberEmployee';
export const FILTER_MEMBER_EMPLOYEES_MULTI = 'memberEmployeeMulti';
export const FILTER_MEMBER_CUSTOMERS = 'memberCustomer';
export const FILTER_LOGGABLE_TYPE = 'loggableType';
export const FILTER_LOG_ACTIONS = 'logActions';
export const FILTER_DEPARTMENT_SINGLE = 'departmentSingle';
export const FILTER_DEPARTMENT_MULTI = 'departmentMulti';
export const FILTER_ORDER_ITEM_TYPE = 'orderItemType';

export const FILTER_DEVICE = "device";
export const FILTER_ROLES = "roles";
export const FILTER_PERMISSIONS = "permissions";
