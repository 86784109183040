<template>
  <div
    class="absolute top-0 left-0 bg-luxcaddy-600 text-white font-bold px-1 text-sm sm:text-md sm:px-2 py-1 border-b border-r border-gray-400 shadow">
    BIO
  </div>
</template>

<script>
export default {
  name: "FruitsVegetableOrganicLabel"
}
</script>

<style scoped>

</style>