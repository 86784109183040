import InvoiceRepository from "@/repositories/InvoiceRepository";

const getDefaultState = () => {
  return {
    invoice: null,

    // When this is set to true, a PDF File will be shown inline next to the invoice forms.
    // Used to shrink the form.
    externalInvoicePDFIdBeingTreated: null,

    // Determines if the z-index of the invoice pdf is so that it will be visible / over the mdoal or not
    // For all other modals, the pdf should be in the background
    invoiceItemModalOpen: false,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getInvoice: state => state.invoice,
    getInvoiceId: state => state.invoice?.id || null,
    getOrderId: state => state.invoice?.orderId || null,
    getMemberId: state => state.invoice?.memberId || null,
    getInvoiceVatNumber: state => state.invoice?.vatNumber || null,
    getItems: state => state.invoice?.items || [],
    isDraft: state => state.invoice?.isDraft || false,
    isCreditNote: state => state.invoice?.isCreditNote || false,
    isInvoiceCurrencyEUR: state => state.invoice?.currency.code === "EUR" || false,
    isInvoiceCurrencyUSD: state => state.invoice?.currency.code === "USD" || false,
    getInvoiceCurrencySymbol: state => state.invoice?.currency.symbol,
    isInvoiceInternal: state => {
      if (state.invoice) {
        return !state.invoice?.isExternal;
      }
      return false;
    },

    isTreatingInvoicePDF: state => state.externalInvoicePDFIdBeingTreated !== null,
    getExternalInvoicePDFIdBeingTreated: state => state.externalInvoicePDFIdBeingTreated,

    isInvoiceItemModalOpen: state => state.invoiceItemModalOpen,
  },
  mutations: {
    setInvoice(state, invoiceData) {
      state.invoice = invoiceData;

      // Includes null
      if (invoiceData !== null && invoiceData.externalInvoicePDFId !== undefined) {
        state.externalInvoicePDFIdBeingTreated = invoiceData.externalInvoicePDFId;
      }
    },

    setExternalInvoicePDFIdBeingTreated(state, id) {
      state.externalInvoicePDFIdBeingTreated = id;
    },


    setInvoiceItemModalOpen(state, bool) {
      state.invoiceItemModalOpen = bool;
    },

  },
  actions: {
    fetchInvoice({commit}, {isInternal, invoiceId}) {
      const req = isInternal
        ? InvoiceRepository.internal.getInvoice(invoiceId)
        : InvoiceRepository.getInvoice(invoiceId);

      return req.then((res) => {
        commit('setInvoice', res.data.data);
      });
    },
    refetchCurrentInvoice({dispatch, getters}) {
      return dispatch('fetchInvoice', {
        isInternal: getters.isInvoiceInternal,
        invoiceId: getters.getInvoiceId
      });
    },
  },
}