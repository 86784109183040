<template>
  <div>
    <card class="mb-4" type="light">
      <div class="grid items-end grid-cols-1 md:grid-cols-7 gap-x-4 ">
        <text-input-element
          v-model="memberFilter"
          :occupy-validation-space="false"
          label="ID(s) ou Nom Membre"
        />
        <select-element
          v-model="memberTypeFilter"
          :enable-search="false"
          :occupy-validation-space="false"
          :options="getMemberTypeFilters"
          label="Type du membre"
        />
        <date-range-picker-element
          v-if="init"
          v-model="dateRange"
          :occupy-validation-space="false"
          class="md:col-span-2"
          end-date-end-of-day
          label="Montrer congés entre"
          with-time
        />


        <checkbox-element
          v-model="crossToday"
          :occupy-validation-space="false"
          label="En congé aujourd’hui"
        />

        <select-element
          v-model="selectedShortcut"
          :enable-search="false"
          :occupy-validation-space="false"
          :options="getShortCuts"
          active-in-read-only
          label="Raccourcis"
          @input="setShortCut"
        />
        <button-element
          size="small"
          @click="getHolidays"
        >
          <fa-icon fixed-width icon="check"/>
        </button-element>
      </div>
    </card>

    <vue-apex-charts
      v-if="loaded"
      :options="chartOptions"
      :series="getFormattedHolidays"
      type="rangeBar"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Card from "@/components/elements/cards/Card";
import dayjs from "dayjs";
import MemberHolidayRepository from "@/repositories/MemberHolidayRepository";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";

export default {
  name: "ShowMemberHolidaysTimeline",
  components: {
    TextInputElement,
    SelectElement,
    DateRangePickerElement, CheckboxElement, ButtonElement, Card, VueApexCharts
  },
  data() {
    return {
      init: false,
      loaded: false,
      holidays: [],
      memberFilter: null,
      selectedShortcut: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      memberTypeFilter: null,
      crossToday: false,

      chartOptions: {
        chart: {
          type: 'rangeBar',
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%',
            rangeBarGroupRows: true
          }
        },
        colors: [
          "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0",
          "#3F51B5", "#546E7A", "#D4526E", "#8D5B4C", "#F86624",
          "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
        ],
        fill: {
          type: 'solid'
        },
        xaxis: {
          type: 'datetime'
        },
        annotations: {
          xaxis: [
            {
              x: dayjs().endOf('day').valueOf(),
              x2: dayjs().startOf('day').valueOf(),
              borderColor: "#e24418",
              label: {
                style: {
                  color: '#eaeaea',
                  background: '#e24418',
                  fontWeight: 2,
                  fontSize: 'small'
                },
                orientation: 'horizontal',
                text: dayjs().format('DD.MM.YYYY')
              }
            }
          ]
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {

            let from = dayjs(val[0]);
            let to = dayjs(val[1]);

            return from.format('DD.MM') + ' - ' + to.format('DD.MM');
          }
        },
        tooltip: {
          custom: function (opts) {
            const fromDate = dayjs(opts.y1);
            const toDate = dayjs(opts.y2);

            return `${fromDate.format('DD.MM.YYYY')} - ${toDate.format('DD.MM.YYYY')}`;
          }
        }
      },


    }
  },
  created() {
    this.dateRange.startDate = this.$date().subtract(2, 'months').startOf('day').format('DD.MM.YYYY HH:mm');
    this.dateRange.endDate = this.$date().add(6, 'months').endOf('day').format('DD.MM.YYYY HH:mm');

    this.$nextTick(() => {
      this.init = true;
    })

    this.getHolidays();
  },
  methods: {
    setShortCut() {
      Object.keys(this.selectedShortcut).forEach((property) => {
        this.$set(this, property, this.selectedShortcut[property]);
      });
    },

    getHolidays() {
      MemberHolidayRepository
        .getForTimeline(this.dateRange.startDate, this.dateRange.endDate, this.memberTypeFilter, this.crossToday, this.memberFilter)
        .then((res) => {
          this.holidays = res.data.data;
          this.loaded = true;
        })
    },
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    }
  },
  computed: {
    getShortCuts() {
      return [
        {
          label: 'Année courante (' + dayjs().startOf('year').format('YYYY') + ')',
          value: {
            dateRange: {
              startDate: dayjs().startOf('year').format('DD.MM.YYYY HH:mm:ss'),
              endDate: dayjs().endOf('year').endOf('day').format('DD.MM.YYYY HH:mm:ss'),
            }
          }
        },
      ]
    },
    getFormattedHolidays() {
      let grouped = this.groupBy(this.holidays, h => h.memberName);

      return Array.from(grouped)
        .map((group) => {
          const holidays = group[1];

          const totalDays = holidays.reduce((accumulator, object) => {
            return accumulator + object.daysCount;
          }, 0);

          const memberName = group[0] + ' (' + totalDays + ')';

          return {
            name: memberName,
            data: holidays.map(h => {
              return {
                x: memberName,
                y: [
                  new Date(h.startDate).getTime(),
                  new Date(h.endDate).getTime(),
                ]
              }
            })
          }
        })
    },
    getMemberTypeFilters() {
      return [
        {label: 'Employées', value: 'employee'},
        {label: 'Fournisseurs', value: 'supplier'},
      ];
    }
  }
}
</script>

