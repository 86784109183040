<template>
  <div>
    <vue-apex-charts
      v-if="loaded"
      :options="chartOptions"
      :series="getSeries"
      height="700px"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ProductRepository from "@/repositories/ProductRepository";
import {centsToEuroString} from "@/helpers/mathHelper";
import dayjs from "dayjs";

export default {
  components: {VueApexCharts},
  name: "ProductBuyingPriceHistory",
  props: {
    productId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    priceLogItems: [],
    loaded: false,

    chartOptions: {
      chart: {
        type: 'area',
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      stroke: {
        curve: 'stepline',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      markers: {
        hover: {
          sizeOffset: 4
        }
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return centsToEuroString(val);
          },
        },
        title: {
          text: 'Price'
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return centsToEuroString(val)
        }
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return centsToEuroString(val);
          }
        },
        x: {
          formatter: function (val) {
            return dayjs(val).format('DD.MM.YYYY HH:mm')
          }
        }
      },
      colors: ['#668d3e']
    },
  }),
  created() {
    this.fetchPriceLog();
  },
  methods: {
    fetchPriceLog() {
      this.loaded = false;
      return ProductRepository.priceLog.getPriceLog(this.productId).then((res) => {
        this.priceLogItems = res.data.data;
        this.loaded = true;
      })
    }
  },
  computed: {
    getSeries() {
      const data = this.priceLogItems.map(l => {
        return {
          x: this.$date(l.createdAt).valueOf(),
          y: l.price
        }
      });

      return [
        {
          data: data,
          name: "Prix"
        }
      ]
    }
  }

}
</script>

<style scoped>

</style>