<template>
  <div>
    <form-wrapper v-if="showForm" ref="form"
                  :show-changed="true"
                  :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer produit'"
                  @submit="createOrUpdateProduct">

      <form-grid class="mb-8 grid-cols-1 xl:grid-cols-2">

        <card icon="info-circle" size="small" title="Informations de base" type="light">

          <form-grid class="xl:col-span-2 grid-cols-1 xl:grid-cols-2">
            <text-input-element v-model="product.name"
                                label="Identificateur du produit"
                                validation-rules="required|min:1|max:200"/>

            <brand-select-input
              v-model="product.brandId"
            />
          </form-grid>

          <form-grid class="xl:col-span-2 grid-cols-1 xl:grid-cols-2">
            <country-select-input
              v-model="product.countryOfOrigin"
              label="Pays"
              nothing-selected-text="Égal à pays fournisseur"
            />

            <date-picker-element
              v-model="product.publishDate"
              label="Date de publication"
              with-time
            />
          </form-grid>

          <form-grid class="grid-cols-1 xl:grid-cols-2">
            <text-input-element
              v-model="product.valorluxReference"
              label="Référence Valorlux"
              validation-rules="max:50"
            />

            <text-input-element
              v-model="product.nc8Reference"
              label="Référence NC8"
              validation-rules="max:50"
            />
          </form-grid>
        </card>

        <card icon="truck" size="small" title="Fournisseur" type="light">

          <form-grid class="grid-cols-1 xl:grid-cols-2">
            <supplier-select-input
              v-model="product.supplierMemberId"
              :limit-option-menu-width="false"
              :occupy-validation-space="false"
              label="Fournisseur"
              only-active-suppliers
              validation-rules="required"
            />

            <text-input-element
              v-model="product.supplierReference"
              label="Référence fournisseur"
            />

            <checkbox-element
              v-model="product.isAutoIncludedInSupplierOrder"
              label="Inclure dans commande fournisseur"
            />

            <select-element
              v-model="product.excludedDeliveryDays"
              :options="getPossibleExcludedDeliveryDays"
              label="Jours de livraison exclus"
              multi
            />

            <text-input-element
              v-model="product.supplierUnitQuantity"
              :validation-rules="getSupplierUnitValidationRule"
              label="Contenu fournisseur"

            />

            <select-element
              v-model="product.supplierUnit"
              :options="getSupplierUnitList"
              label="Unite fournisseur"
            />
          </form-grid>

        </card>

      </form-grid>

      <form-grid class="mb-8 grid-cols-1 xl:grid-cols-3">

        <card class="grid-cols-1 xl:grid-cols-2" icon="file-invoice" size="small" title="Achat" type="light">

          <form-grid class="grid-cols-1 xl:grid-cols-2">
            <currency-input-element
              v-model="product.buyingUnitPrice"
              label="Prix d'achat unitaire"
              @input="updateBuyingPrice"
            />

            <currency-input-element
              v-model="product.buyingPrice"
              label="Prix d'achat HTVA"
            />

            <checkbox-element
              v-model="keepSameMargin"
              label="Garder marge"
            />

            <number-input-element
              v-model="product.buyingBundleQuantity"
              label="Lot"
              validation-rules="required"/>

            <number-input-element
              v-model="product.additionalDeliveryTime"
              :inset-text="getAdditionalDeliveryTimeInsetText"
              label="Temps de liv. (+ jours livr. fourn.)"
              validation-rules="required"/>

              <text-input-element
                  v-model="product.buyingPriceAddOn"
                  inset-text="%"
                  placeholder="5"
                  label="Supplément au prix d'achat"
              />
          </form-grid>
        </card>

        <card class="xl:col-span-2 grid-cols-1 xl:grid-cols-4" icon="euro-sign" size="small" title="Vente"
              type="light">
          <form-grid class="grid-cols-1 xl:grid-cols-4">
            <currency-input-element
              v-model="product.sellingPriceWithoutVat"
              disabled
              label="Prix de vente HTVA"
            />

            <currency-input-element
              v-model="product.sellingPrice"
              label="Prix de vente TTC"
              validation-rules="required"
              @input="setComputedMargin(false)"
            />


            <text-input-element
              v-model="computedMargin"
              inset-text="%"
              label="Marge"
              validation-rules="required"
              @input="calcSellingPriceFromMargin"
            />

            <vat-input-element
              v-model="product.sellingVat"
              validation-rules="required"
            />

            <number-input-element
              v-model="product.sellingBundleQuantity"
              label="Lot"
              validation-rules="required|int_not_zero"
            />

            <number-input-element
              v-model="product.sellingBundleContent"
              :inset-text="product.sellingBundleUnit"
              label="Contenu"
              validation-rules="required|int_not_zero"
            />

            <select-element
              v-model="product.sellingBundleUnit"
              :options="unitList"
              label="Unité"
              validation-rules="required"
            />

            <checkbox-element
              v-model="product.isSellingBundleVariable"
              label="Contenu variable"
            />

            <number-input-element
              v-model="product.sellingBundleNetContent"
              inset-text="g"
              label="Poid égoutté"
              validation-rules="int_not_zero"
            />

            <number-input-element
              v-model="product.maxPerOrder"
              label="Qte. max par commande"
              validation-rules="required|min_value:1"
            />


            <text-input-element
              v-model="product.weightMargin"
              inset-text="%"
              label="Marge sur poids"
              type="percentage"
            />
          </form-grid>
        </card>
      </form-grid>

      <form-grid class="mb-8 grid-cols-1 xl:grid-cols-6">
        <card class="grid-cols-1 xl:col-span-2" icon="clock" size="small" title="Saison"
              type="light">
          <date-range-picker-element
            v-model="product.seasonDateRange"
            label="Debut & fin de la saison"
          />
        </card>

        <card class="xl:grid-cols-2 xl:col-span-2" icon="cubes" size="small" title="Rassemblement"
              type="light">

          <form-grid class="grid-cols-1 xl:grid-cols-2">
            <rack-select-input v-model="product.stockAddressRack" :allow-unselecting="false" label="Étagère"
                               validation-rules="required"/>
            <number-input-element v-model="product.pickingWeight" inset-text="g"
                                  label="Poids"></number-input-element>
            <number-input-element v-model="product.pickingVolume" inset-text="cm2"
                                  label="Volume"></number-input-element>

            <checkbox-element
              v-model="product.mustBeValidatedByBarcode"
              label="Doit être validé par code barre"
            />

          </form-grid>

        </card>
        <card class="xl:grid-cols-2 xl:col-span-2" icon="cubes" size="small" title="Vidange"
              type="light">

          <form-grid class="grid-cols-1 xl:grid-cols-1">
            <select-element
              v-model="product.depositId"
              :enable-search="false"
              :options="depositList"
              label="Vidange"
            />
          </form-grid>

        </card>

        <card class="xl:grid-cols-3 xl:col-span-6 mt-6" icon="boxes" size="small" title="Stock" type="light">
          <form-grid class="grid-cols-1 xl:grid-cols-3">
            <checkbox-element
              v-show="!product.isAutoStock"
              v-model="product.isJustInTimeStock"
              label="Peut être acheté si aucun stock"
            />

            <number-input-element
              v-show="!product.isJustInTimeStock"
              v-model="product.stockMinimal"
              label="Seuil min. du stock"
            />
            <number-input-element
              v-show="displayStockGoal"
              v-model="product.stockGoal"
              label="Stock souhaité"
            />
            <text-input-element
              v-show="product.isAutoStock === true && !isJustInTimeStock"
              v-model="product.autoStockCoefficient"
              label="Coefficient de auto stock"
              placeholder="1.80"
              validation-rules="decimal:2,."
            />

            <select-element
              v-show="product.isJustInTimeStock"
              v-model="product.stockPreOrderDays"
              :enable-search="false"
              :options="this.$luxcaddyConfig('product.stock.allowedPreOrderDays')"
              label="Précommande"
            />
            <checkbox-element
              v-show="!product.isJustInTimeStock"
              v-model="product.isAutoStock"
              label="Auto Stock"
            />

            <checkbox-element
              v-model="product.isInvisibleIfNoStock"
              label="Est invisible si aucun stock"
            />
          </form-grid>
        </card>
      </form-grid>

      <template #additionalButtons>
        <delete-confirmation-modal
          v-if="isUpdateMode"
          ref="deleteModal"
          title="Supprimer produit?"
          @delete="discontinueProduct"
        />

        <button-element v-if="isUpdateMode" class="flex-grow" type="danger" @click="openDeleteModal">
          <fa-icon icon="trash-alt"/>
          <span class="hidden md:inline-block ml-2">Supprimer</span>
        </button-element>
      </template>

    </form-wrapper>
  </div>
</template>

<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import ProductRepository from "../../../../repositories/ProductRepository";
import FormGrid from "../../../elements/forms/FormGrid";
import NumberInputElement from "../../../elements/forms/elements/base/NumberInputElement";
import CheckboxElement from "../../../elements/checkboxes/CheckboxElement";
import CurrencyInputElement from "../../../elements/forms/elements/CurrencyInputElement";
import VatInputElement from "../../../elements/forms/elements/VatInputElement.vue";
import CountrySelectInput from "../../../elements/forms/elements/CountrySelectInput";
import DatePickerElement from "../../../elements/forms/elements/base/DatePickerElement";
import ButtonElement from "../../../elements/buttons/ButtonElement";
import DeleteConfirmationModal from "../../../elements/modals/DeleteConfirmationModal";
import Card from "../../../elements/cards/Card";
import axios from "axios";
import RackRepository from "../../../../repositories/RackRepository";
import DepositRepository from "../../../../repositories/DepositRepository";
import SupplierSelectInput from "@/components/elements/forms/elements/SupplierSelectInput.vue";
import BrandSelectInput from "@/components/elements/forms/elements/BrandSelectInput.vue";
import RackSelectInput from "@/components/elements/forms/elements/RackSelectInput";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";

export default {
  name: "CreateOrUpdateProductForm",
  components: {
    DateRangePickerElement,
    SelectElement,
    RackSelectInput,
    BrandSelectInput,
    SupplierSelectInput,
    Card,
    DeleteConfirmationModal,
    ButtonElement,
    DatePickerElement,
    CountrySelectInput,
    VatInputElement,
    CurrencyInputElement,
    CheckboxElement,
    NumberInputElement,
    FormGrid,
    TextInputElement,
    FormWrapper
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    },
    productId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    keepSameMargin: true,
    computedMargin: null,

    unitList: [
      {label: 'Grammes', value: 'g'},
      {label: 'Millilitres', value: 'ml'},
      {label: 'Pieces', value: 'ut'},
    ],
    rackList: [],
    depositList: [],
    product: {
      // Product
      name: '',
      brandId: null,
      countryOfOrigin: "",
      isPublished: false,
      isShopVisibilityForced: false,
      publishDate: null,
      valorluxReference: '',
      nc8Reference: '',

      // Supplier
      supplierMemberId: null,
      supplierReference: '',
      isAutoIncludedInSupplierOrder: true,
      excludedDeliveryDays: null,

      // Buying
      buyingPrice: 0,
      buyingBundleQuantity: 1,
      additionalDeliveryTime: 0,
      buyingPriceAddOn: null,

      // Selling
      sellingPrice: 0,
      sellingVat: 3,
      sellingBundleUnit: 'g',
      sellingBundleQuantity: 5,
      sellingBundleContent: 5,
      sellingBundleNetContent: null,
      isSellingBundleVariable: false,
      maxPerOrder: 50,

      // Picking
      stockAddressRack: null,
      pickingWeight: null,
      pickingVolume: null,
      mustBeValidatedByBarcode: false,

      // Stock
      stockPreOrderDays: 0,
      stockMinimal: null,
      autoStockCoefficient: null,

      stockGoal: null,
      isAutoStock: false,
      isJustInTimeStock: false,
      isInvisibleIfNoStock: false,

      // Season
      seasonDateRange: {
        startDate: null,
        endDate: null
      },
      depositId: null,

      //Supplier unit
      supplierUnitQuantity: null,
      supplierUnit: null,

      weightMargin: null,

    },

    initialProductName: '',

    isLoading: false,

  }),
  created() {
    this.isLoading = true;

    const rackRequest = this.getRacks();
    const productRequest = this.getProduct();
    const depositRequest = this.getDeposits();

    let requests = [
      rackRequest,
      depositRequest
    ];

    if (this.isUpdateMode) {
      requests.push(productRequest);
    }
    axios
      .all(requests)
      .finally(() => {
        this.$nextTick(() => {
          this.isLoading = false;
          if (this.$refs.form) {
            this.$refs.form.resetForm();
          }
        });
      })

    if (!this.isUpdateMode) {
      this.product.publishDate = this.$date().format('DD.MM.YYYY');
    }
  },
  watch: {
    getBuyingPriceForUnit: function () {
      if (this.keepSameMargin) {
        this.calcSellingPriceFromMargin();
      } else {
        this.setComputedMargin(false);
      }
      this.calcUnitBuyingPrice();
    },
    getSellingVat: function () {
      this.setComputedMargin();
      this.calcSellingPriceWithoutVat();
    },
    getSellingPriceWithVat: function () {
      this.calcSellingPriceWithoutVat();
    },
  },
  methods: {
    updateBuyingPrice() {
      // Don't update while editing BuyingBundleQuantity
      if (this.product.buyingBundleQuantity === null) {
        return false;
      }
      this.product.buyingPrice = this.product.buyingBundleQuantity * this.product.buyingUnitPrice;
    },
    calcSellingPriceWithoutVat() {
      this.product.sellingPriceWithoutVat = this.getSellingPriceWithoutVat;
    },
    calcUnitBuyingPrice() {
      this.product.buyingUnitPrice = this.getBuyingPriceForUnit;
    },

    // Adapt selling Price when margin is changed from the input (user interaction)
    calcSellingPriceFromMargin() {
      const buyingPrice = this.getBuyingPriceForUnit / 100;
      const tva = 1 + (this.getSellingVat / 100);
      const sellingPriceWithVat = ((this.computedMargin / 100) + 1) * buyingPrice * tva * 100;

      this.product.sellingPrice = Math.round(sellingPriceWithVat);
    },

    setComputedMargin(recalculateSellingPrice = true) {
      const sellingPrice = this.getSellingPriceWithoutVat;
      const buyingPrice = this.getBuyingPriceForUnit;

      const margin = Math.round((sellingPrice / buyingPrice - 1) * 10000) / 100;

      this.computedMargin = margin === Infinity || isNaN(margin) || margin <= 0 ? 0 : margin;

      if (recalculateSellingPrice) {
        this.calcSellingPriceFromMargin();
      }
    },

    getRacks() {
      return RackRepository.get().then((res) => {
        this.rackList = res.data.data.map(r => {
          return {
            label: r.number,
            value: r.number
          };
        }).sort((a, b) => a.label - b.label);
      });
    },
    getDeposits() {
      return DepositRepository.get().then((res) => {
        this.depositList = res.data.data.map(r => {
          return {
            label: r.name,
            value: r.id
          };
        });
      });
    },
    getProduct() {
      if (this.isUpdateMode) {
        return ProductRepository.getSingle(this.productId).then((res) => {
          this.setProductPropertiesByResponseData(res.data.data);
        })
      }
      return Promise.resolve();
    },
    createOrUpdateProduct(callback) {
      this.resetStockConfig();
      if (this.isUpdateMode) {
        return ProductRepository.update(this.productId, this.getProductPayload).then(() => {
          this.$sendSuccessToast(`Produit ${this.getProductName} a été modifié!`);
        }).finally(() => callback());
      }

      return ProductRepository.create(this.getProductPayload).then((res) => {
        this.$sendSuccessToast(`Produit ${this.getProductName} a été crée!`);
        this.$router.push({name: 'products.edit', params: {product: res.data.data.id}});
      }).finally(() => callback());
    },
    setProductPropertiesByResponseData(d) {
      this.initialProductName = d.name;

      this.product = {
        id: d.id,
        name: d.name,
        brandId: d.brand?.id || null,
        countryOfOrigin: d.countryOfOriginInitials,
        isPublished: d.isPublished,
        isShopVisibilityForced: d.isShopVisibilityForced,
        publishDate: d.publishDate,
        valorluxReference: d.luxReferences.valorluxReference,
        nc8Reference: d.luxReferences.nc8Reference,

        supplierMemberId: d.supplier?.memberId || null,
        supplierReference: d.supplier?.reference || '',
        excludedDeliveryDays: d.excludedDeliveryDays,
        isAutoIncludedInSupplierOrder: d.isAutoIncludedInSupplierOrder,
        buyingPrice: d.buyingData.price,
        buyingBundleQuantity: d.buyingData.bundleQuantity,
        buyingUnitPrice: this.getBuyingPriceForUnit,
        buyingPriceAddOn:  d.buyingData.buyingPriceAddOn ? (d.buyingData.buyingPriceAddOn / 100).toFixed(2) : null,

        additionalDeliveryTime: d.additionalDeliveryTime || 0,

        sellingPrice: d.sellingData.price,
        sellingPriceWithoutVat: this.getSellingPriceWithoutVat,
        sellingVat: d.sellingData.vat,
        sellingBundleUnit: d.sellingData.bundle.unit,
        sellingBundleQuantity: d.sellingData.bundle.quantity,
        sellingBundleContent: d.sellingData.bundle.content,
        sellingBundleNetContent: d.sellingData.bundle.netContent,
        isSellingBundleVariable: d.sellingData.bundle.isVariable,
        maxPerOrder: d.maxPerOrder,

        stockAddressRack: d.pickingConfiguration.stockAddressRack,
        pickingWeight: d.pickingConfiguration.pickingWeight,
        pickingVolume: d.pickingConfiguration.pickingVolume,
        mustBeValidatedByBarcode: d.pickingConfiguration.mustBeValidatedByBarcode,

        stockPreOrderDays: d.stock.configuration.stockPreOrderDays,
        stockMinimal: d.stock.configuration.stockMinimal,
        autoStockCoefficient: d.stock.configuration.autoStockCoefficient,
        stockGoal: d.stock.configuration.stockGoal,
        isAutoStock: d.stock.configuration.isAutoStock,
        isJustInTimeStock: d.stock.configuration.isJustInTimeStock,
        isInvisibleIfNoStock: d.stock.configuration.isInvisibleIfNoStock,
        depositId: d.depositId,
        supplierUnitQuantity: d.supplierUnit.quantity,
        supplierUnit: d.supplierUnit.unit,
        supplierDeliveryTimeDays: d.supplier.deliveryTimeDays,
        weightMargin: d.weightMargin ? (d.weightMargin / 100).toFixed(2) : null,
        seasonDateRange: {
          startDate: null,
          endDate: null
        },

        commentsCount: d.commentsCount,
      };

      this.setComputedMargin();

      this.$store.commit('products/setProduct', this.product);

      let s = d.season;
      let hasSeason = s.endDay !== null && s.endMonth !== null && s.startDay !== null && s.startMonth !== null;

      if (hasSeason) {
        let startDate = this.$date().date(s.startDay).month(s.startMonth - 1).format('DD.MM.YYYY');
        let endDate = this.$date().date(s.endDay).month(s.endMonth - 1).format('DD.MM.YYYY');

        this.product.seasonDateRange = {
          startDate: startDate,
          endDate: endDate
        }
      }
    },
    discontinueProduct() {
      ProductRepository.discontinue(this.productId).then(() => {
        this.$sendSuccessToast("Produit supprimé!");
        this.$router.push({name: 'products.list'});
      })
    },
    openDeleteModal() {
      this.$refs.deleteModal.openModal();
    },

    resetStockConfig() {
      if (this.product.isJustInTimeStock) {
        this.product.stockMinimal = null;
        this.product.stockGoal = null;
        this.product.autoStockCoefficient = null;
      } else {
        this.product.stockPreOrderDays = 0;
        if (!this.product.isAutoStock) {
          this.product.autoStockCoefficient = null;
        }
      }
    }
  },

  computed: {
    /** Stock related **/
    isJustInTimeStock() {
      return this.product.isJustInTimeStock;
    },

    getBuyingPriceForUnit() {
      // Don't do any calculations while user is editing BuyingBundleQuantity.
      if (this.product.buyingBundleQuantity === null) {
        return 0;
      }

      if (this.product.buyingBundleQuantity === 1) {
        return this.product.buyingPrice;
      }

      return this.product.buyingPrice / this.product.buyingBundleQuantity;
    },

    getSupplierUnitList() {
      return this.$luxcaddyConfig('product.supplierUnit');
    },
    getPossibleExcludedDeliveryDays() {
      return [
        {label: 'Lundi', value: 1},
        {label: 'Mardi', value: 2},
        {label: 'Mecredi', value: 3},
        {label: 'Jeudi', value: 4},
        {label: 'Vendredi', value: 5},
        {label: 'Samedi', value: 6},
      ];
    },
    displayStockGoal() {
      return !this.product.isJustInTimeStock;
    },

    isUpdateMode() {
      return this.mode === 'update';
    },
    getProductName() {
      return this.initialProductName || '';
    },
    showForm() {
      return !this.isLoading;
    },
    getSplitSeasonDateRange() {
      let dateRange = this.product.seasonDateRange;

      if (dateRange === null || dateRange?.startDate === null || dateRange?.endDate === null)
        return null;

      let startDate = this.$date(dateRange.startDate, ['DD.MM.YYYY', 'YYYY-MM-DD']);
      let endDate = this.$date(dateRange.endDate, ['DD.MM.YYYY', 'YYYY-MM-DD']);

      return {
        seasonStartDay: parseInt(startDate.format('D')),
        seasonStartMonth: parseInt(startDate.format('M')),
        seasonEndDay: parseInt(endDate.format('D')),
        seasonEndMonth: parseInt(endDate.format('M')),
      };
    },
    getProductPayload() {
      let product = {...this.product};
      product.sellingPrice = product.sellingPriceWithoutVat;

      if (product.autoStockCoefficient !== null) {
        product.autoStockCoefficient = parseFloat(product.autoStockCoefficient);
      }

      delete product.sellingPriceWithoutVat;

      let seasonDates = this.getSplitSeasonDateRange;

      if (seasonDates !== null) {
        product.seasonStartDay = seasonDates.seasonStartDay;
        product.seasonStartMonth = seasonDates.seasonStartMonth;
        product.seasonEndDay = seasonDates.seasonEndDay;
        product.seasonEndMonth = seasonDates.seasonEndMonth;
      }

      delete product.seasonDateRange;

      if (Array.isArray(product.excludedDeliveryDays) && product.excludedDeliveryDays.length === 0) {
        product.excludedDeliveryDays = null;
      }

      product.weightMargin = product.weightMargin ? product.weightMargin * 100 : product.weightMargin;

      product.buyingPriceAddOn = product.buyingPriceAddOn ? product.buyingPriceAddOn * 100 : product.buyingPriceAddOn;

      return product;
    },
    getAdditionalDeliveryTimeOfSupplier() {
      if (this.product === null)
        return null;


      return this.product.supplierDeliveryTimeDays;
    },
    getAdditionalDeliveryTimeInsetText() {
      if (this.getAdditionalDeliveryTimeOfSupplier === null || this.getAdditionalDeliveryTimeOfSupplier === undefined)
        return '';

      return '+' + this.getAdditionalDeliveryTimeOfSupplier + ' = ' + (parseInt(this.product.additionalDeliveryTime) + parseInt(this.getAdditionalDeliveryTimeOfSupplier))
    },

    getSellingPriceWithVat() {
      return this.product.sellingPrice;
    },
    getSellingVat() {
      if (this.product.sellingVat === null) {
        return null;
      }

      return Number(this.product.sellingVat);
    },
    getSellingPriceWithoutVat() {
      return Math.round(this.getSellingPriceWithVat / (100 + this.getSellingVat) * 100);
    },
    getUnitBuyingPrice() {
      return this.product.buyingPrice;
    },
    getSupplierUnitValidationRule() {
      return this.isSupplierUnitSet ? 'required|decimal:2,.' : 'decimal:2,.';
    },
    isSupplierUnitSet() {
      return this.product.supplierUnit?.length > 0 || false;
    },

  }
}
</script>

