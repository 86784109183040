<template>
  <div>
    <list-table
      ref="table"
      selectable
      :select-values="selectValues"
      :columns="columns"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      identifier="supplierProductCatalogTable"
    />
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import SupplierCatalogRepository from "@/repositories/SupplierCatalogRepository";
import {centsToEuroString} from "@/helpers/mathHelper";
import {FILTER_DATETIME_RANGE} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowSupplierProductCatalog",
  components: {ListTable},
  data() {
    return {
      selectValues: supplierCatalogEntry => {
        return {
          memberId: supplierCatalogEntry.productNumber + '-' + supplierCatalogEntry.price
        };
      },

      title: 'Catalogues fournisseur',
      columns: [
        {
          caption: 'Fournisseur',
          dataField: 'supplierName',
          sort: {dataField: 'supplier'}
        },
        {
          caption: 'Produit',
          dataField: 'productName',
          sort: {dataField: 'productName'}
        },
        {
          caption: 'Nr. produit',
          dataField: 'productNumber',
          sort: {dataField: 'productNumber'}
        },
        {
          caption: 'Code barre',
          dataField: 'barcode',
        },
        {
          caption: 'Prix',
          dataType: 'euro',
          calculateCellValue: (r) => centsToEuroString(r.price),
          sort: {dataField: 'price'}
        },
        {
          caption: 'Desc.',
          dataField: 'description',
        },
        {
          caption: 'Importé le',
          calculateCellValue: (r) => {
            if (r.createdAt === null) {
              return "";
            }

            return this.$date(r.createdAt).format('DD.MM.YYYY HH:mm');
          },
          sort: {dataField: 'createdAt'},
        },
      ],
      filters: [
        {
          caption: 'ID(s) ou Nom Fournisseur',
          filterKey: 'supplierMemberIdsOrName',
          forceIsVisible: true,
        },
        {
          caption: 'Produit',
          filterKey: 'productName',
          forceIsVisible: true,
        },
        {
          caption: 'Nr. produit',
          filterKey: 'productNumber',
          forceIsVisible: true,
        },
        {
          caption: 'Code barre',
          filterKey: 'barcode',
        },
        {
          caption: 'Crée entre',
          filterKey: 'createdAt',
          type: FILTER_DATETIME_RANGE,
        },
      ],
      repositoryFunc: SupplierCatalogRepository.getProductsPaginated
    }
  },
}
</script>