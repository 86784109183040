<template>
  <div>
    <list-table
      v-if="member.id && mode === null"
      ref="tableRef"
      :columns="columns"
      :repository-func="repositoryFunction"
      :title="title"
      create-event
      identifier="customerDeliveryContactsTable"
      @create="createButtonClicked"
    >
      <template v-slot:precise-geocoding-template="{ row }">
        <geocoding-precision-status
          :geocoding="row.geocoding"
          small
        />
      </template>
    </list-table>

    <customer-contact-show-create-edit-form
      v-if="mode !== null"
      ref="contactForm"
      :contact-id="selectedContactId"
      :member-id="member.id"
      :mode="mode"
      @abort="onAbort"
      @change="onContactChange"
    />
  </div>
</template>

<script>

import CustomerRepository from "../../../../../../repositories/CustomerRepository";
import CustomerContactShowCreateEditForm from "./Form/CustomerDeliveryContactShowCreateEditForm";
import ListTable from "../../../../../listTable/ListTable";
import GeocodingPrecisionStatus
  from "@/components/pages/Members/EditMember/Tabs/DeliveryContacts/Includes/GeocodingPrecisionStatus";

export default {
  name: "CustomerDeliveryContactsTab",
  components: {GeocodingPrecisionStatus, ListTable, CustomerContactShowCreateEditForm},
  props: {
    member: {type: Object, required: true}
  },
  data() {
    let vm = this;

    return {
      selectedContactId: null,
      mode: null,

      title: 'Contacts de livraison',
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'edit',
              hint: 'Modifier contact',
              onClick: (row) => this.editButtonClicked(row),
            },
            {
              type: 'delete',
              hint: 'Supprimer contact',
              confirmation: {
                title: 'Supprimer contact de livraison?',
                text: 'Êtes vous sure que vous voulez supprimer ce contact de livraison?'
              },
              successText: 'Le contact de livraison a été supprimé',
              deleteAction: (row) => CustomerRepository.deliveryContacts.delete(vm.member.id, row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
        },
        {
          caption: 'Nom / Société',
          dataField: 'fullName',
        },
        {
          caption: 'Rue et Numero',
          dataField: 'street',
        },
        {
          caption: 'Code postal',
          dataField: 'postalCode',
          visible: false,
        },
        {
          caption: 'Zone',
          dataField: 'zoneId',
          visible: false,
        },
        {
          caption: 'Ville',
          dataField: 'city',
        },
        {
          caption: 'Pays',
          dataField: 'country',
          visible: false,
        },
        {
          caption: 'Géocodage',
          cellTemplate: 'precise-geocoding-template'
        },
      ],
      repositoryFunction: () => {
        return CustomerRepository.deliveryContacts.getAll(vm.member.id);
      }
    }
  },
  computed: {
    isInReadOnlyMode() {
      return this.$isReadOnly();
    },
  },
  methods: {
    createButtonClicked() {
      this.mode = "create";
      this.selectedContactId = null;
    },

    editButtonClicked(row) {
      this.selectedContactId = row.id;
      this.mode = "edit";
    },

    onContactChange() {
      this.onAbort();
      this.$nextTick(() => {
        this.$refs.tableRef.refresh();
      });
    },

    onAbort() {
      this.mode = null;
      this.selectedContactId = null;
    },
  }
}
</script>

