<template>
  <table-row
    :class="{'border-t-4 border-gray-100': withBorder}"
  >
    <table-data-cell :colspan="getColSpan" class="text-right">
      <div v-if="withVat" class="font-bold">Sous-Total TTC</div>
      <div v-else-if="withoutVat" class="font-bold">Sous-Total HTVA</div>
      <div v-else class="font-bold">Montant à payer</div>
    </table-data-cell>
    <table-data-cell class="w-56">
      <!-- Price with VAT -->
      <template v-if="withVat">
        <money-amount
          :amount="getInvoiceAmountWithVat"
          :currency="isInvoiceCurrencyEUR ? 'EUR' : 'USD'"
        />
      </template>

      <!-- Price without VAT -->
      <template v-else-if="withoutVat">
        <money-amount
          :amount="getInvoiceAmountNoVat"
          :currency="isInvoiceCurrencyEUR ? 'EUR' : 'USD'"
        />
      </template>

      <!-- Price to be paid -->
      <template v-else>
        <money-amount
          :amount="getInvoiceUnpaidAmount"
          :currency="isInvoiceCurrencyEUR ? 'EUR' : 'USD'"
        />
      </template>
    </table-data-cell>
  </table-row>
</template>

<script>
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import {mapGetters} from "vuex";
import MoneyAmount from "@/components/global/Money/MoneyAmount";

export default {
  name: "InvoiceItemTableTotal",
  components: {MoneyAmount, TableDataCell, TableRow},
  props: {
    withVat: {
      type: Boolean,
      default: false
    },
    withoutVat: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('invoices', [
      'getInvoice', 'isDraft', 'isInvoiceInternal', 'isInvoiceCurrencyEUR', 'isInvoiceCurrencyUSD'
    ]),
    getInvoiceAmountNoVat() {
      return this.getInvoice?.total.amountNoVat || 0;
    },
    getInvoiceUnpaidAmount() {
      return this.getInvoice?.unpaidAmount || 0;
    },
    getInvoiceAmountWithVat() {
      return this.getInvoice?.total.amount || 0;
    },
    getColSpan() {
      if (this.isInvoiceInternal) {
        return 6;
      }

      return 4;
    }
  },
}
</script>

