<template>
  <update-localization-form
    v-if="localizations"
    :localizable-id="getFeeId"
    :localizations="localizations"
    localizable-type="fee"
    @submit="saveLocalizations"
  />
</template>

<script>
import UpdateLocalizationForm from "../../../forms/UpdateLocalizationForm";
import FeeRepository from "../../../../repositories/FeeRepository";

export default {
  name: "UpdateFeeLocalizationForm",
  components: {UpdateLocalizationForm},
  data: () => ({
    localizations: null
  }),
  mounted() {
    FeeRepository.localization.getForFee(this.getFeeId).then((res) => {
      this.localizations = res.data.data;
    })
  },
  computed: {
    getFeeId() {
      return this.$store.getters['fees/getFeeId'];
    }
  },
  methods: {
    saveLocalizations(payload) {
      FeeRepository
        .localization
        .saveForFee(this.getFeeId, {
          localizableData: payload
        })
        .then(() => {
          this.$sendSuccessToast('Traduction sauvegardé!')
        })
    },
  },
}
</script>

