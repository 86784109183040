import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";

export default {
  selectValues: supplierOrder => {
    return {
      id: supplierOrder.id
    }
  },
  selectValuesFromId: supplierOrderId => ({id: supplierOrderId}),

  batchActions: [
    {
      label: "Attacher PDF",
      showSubmitBtn: false,
      additionalFields: [
        {
          label: "Upload",
          type: "uploadExternalPDFs",
          param: "filesToUpload",
          validationRules: 'required',
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return SupplierOrderRepository.uploadBatchAction(
          'uploadAndLinkToExternalPdfs',
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Relier un PDF existant",
      additionalFields: [
        {
          label: "# PDFs factures externes",
          type: "text",
          param: "externalPdfIds",
          validationRules: 'required',

        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return SupplierOrderRepository.executeBatchAction(
          'linkToExternalPdfs',
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },

    {
      label: "Supprimer le numéro de facture",
      action: (callBack, selectedRows) => {
        return SupplierOrderRepository.executeBatchAction(
          'unMarkInvoiceReceived',
          selectedRows
        ).finally(() => callBack());
      }
    },
  ],
};