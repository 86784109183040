<template>
  <list-table
    :columns="columns"
    :create-route="{
            name: 'taskRecurring.create'
        }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="recurringTasksTable"
  >
    <template v-slot:task-status-template="{row}">
      <task-status
        :status="row.status"
      />
    </template>
  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import MemberRepository from "@/repositories/MemberRepository";
import TaskStatus from "@/components/pages/Tasks/Includes/TaskStatus";
import {FILTER_MEMBER_EMPLOYEES,} from "@/components/listTable/includes/controls/filters/availableFilters";
import TaskRecurringRepository from "@/repositories/TaskRecurringRepository";

export default {
  name: "ShowRecurringTasks",
  components: {TaskStatus, ListTable},
  created() {
    this.getAllEmployees();
  },
  data() {
    return {
      employees: [],

      title: 'Tâches récurrentes',
      repositoryFunc: TaskRecurringRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          showInColumnChooser: false,
          allowReordering: false,
          buttons: [
            {
              type: 'show',
              hint: 'Voir tâche',
              route: (row) => ({
                name: 'taskRecurring.edit',
                params: {
                  task: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier tâche',
              route: (row) => ({
                name: 'taskRecurring.edit',
                params: {
                  task: row.id,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer tâche récurrente',
              confirmation: {
                title: 'Supprimer tâche récurrente?',
                text: 'Étes vous sure que vous voulez supprimer cette tâche récurrente?'
              },
              successText: 'La tâche récurrente a été supprimée',
              deleteAction: (row) => TaskRecurringRepository.deleteSingle(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
        },
        {
          caption: 'Titre',
          dataField: 'title',
          sort: {
            dataField: 'title',
            default: 'asc'
          }
        },
        {
          caption: 'Jours',
          calculateCellValue: row => {
            const map = [
              {day: 1, label: "Lundi"},
              {day: 2, label: "Mardi"},
              {day: 3, label: "Mercredi"},
              {day: 4, label: "Jeudi"},
              {day: 5, label: "Vendredi"},
              {day: 6, label: "Samedi"},
              {day: 7, label: "Dimanche"},
            ]
            let labels = [];
            row.dueOnWeekdays.forEach(weekday => {
              labels.push(map.find(d => d.day === weekday).label)
            });

            return labels.join(', ');
          }
        },
        {
          caption: 'Délais / Temps',
          calculateCellValue: row => row.daysToComplete + ' jours'
        },
        {
          caption: 'Tous les',
          calculateCellValue: row => row.frequencyInWeeks + ' semaines'
        },
        {
          caption: 'Employé 1',
          calculateCellValue: row => this.getEmployeeNameById(row.assignedToMemberId) ?? 'N\'importe'
        },
        {
          caption: 'Employé 2',
          calculateCellValue: row => this.getEmployeeNameById(row.executedByMemberId) ?? 'N\'importe'
        },

      ],
      filters: [
        {
          caption: "Titre",
          filterKey: "title",
          forceIsVisible: true
        },
        {
          caption: "Employé assigné",
          filterKey: "assignedTo",
          type: FILTER_MEMBER_EMPLOYEES,
          forceIsVisible: true
        },
      ]
    }
  },
  methods: {
    getEmployeeNameById(employeeId) {
      return this.employees.find(e => e.id === employeeId)?.fullName;
    },

    getAllEmployees() {
      MemberRepository.getAllEmployees().then((res) => {
        this.employees = res.data.data;
      });
    },
  },
}
</script>

<style scoped>

</style>