import auth from "@/router/middlewares/authMiddleware";
import TouchInterfaceLandingPage from "@/components/pages/TouchInterface/TouchInterfaceLandingPage";
import TimeClockPage from "@/components/pages/TouchInterface/Timeclock/TimeClockPage.vue";

export default [
  {
    path: '/touch',
    name: 'touchInterface.index',
    component: TouchInterfaceLandingPage,
    meta: {
      title: 'Touch interface',
      layout: 'TouchInterface',
      permissions: ['TOUCH_INTERFACE_ACCESS'],
      middleware: [auth]
    }
  },
  {
    path: '/timeclock',
    name: 'touchInterface.timeclock.index',
    component: TimeClockPage,
    meta: {
      title: 'Pointage',
      layout: 'TouchInterface',
      permissions: ['TOUCH_INTERFACE_ACCESS'],
      middleware: [auth]
    }
  },
]