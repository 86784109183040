<template>
  <div>
    <show-payments-table
      v-if="getLinkedPaymentTransactions.length > 0"
      :filter-value="getBankMovementId"
      :unattributed-amount="getBankMovementUnattributedAmount"
      filter-key="bankMovementId"
      table-identifier="bankMovementLinkedPayment"
      @change="onChange"
    />

    <warning-alert
      v-else
      size="small"
    >
      Aucune transaction de paiement n'est liée à ce mouvement bancaire.
    </warning-alert>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import WarningAlert from "@/components/elements/alerts/WarningAlert";
import ShowPaymentsTable from "@/components/pages/Invoices/Shared/EditInvoice/Payments/ShowPaymentsTable.vue";

export default {
  name: "BankMovementLinkedPaymentTransactions",
  components: {
    ShowPaymentsTable,
    WarningAlert
  },
  data: () => ({
    selectedPaymentTransactionId: null,
  }),
  computed: {
    ...mapGetters('bankMovements', [
      'getBankMovementId',
      'getLinkedPaymentTransactions',
      'isBankMovementTreated',
      'getLinkedPaymentTransactionCount',
      'getBankMovementUnattributedAmount'
    ]),
  },
  methods: {
    onChange() {
      this.$store.dispatch('bankMovements/fetchBankMovement', this.getBankMovementId)
      this.amount = this.getBankMovementUnattributedAmount();
    }

  },
}
</script>