<template>
  <div>
    <touch-optimized-button
      type="dark"
      @click="$refs.modal.open()"
    >
      <template v-if="getSelectedSort">
        <span>Triage: </span>
        <span class="font-bold">{{ getSelectedSort.label }}</span>
      </template>
      <span v-else>Triage</span>
    </touch-optimized-button>

    <touch-optimized-modal ref="modal">
      <div class="flex flex-wrap gap-8 clear-both">
        <touch-optimized-modal-button
          v-for="sort in possibleSorts"
          :key="sort.value"
          @click="onClickSort(sort)"
        >
          {{ sort.label }}
        </touch-optimized-modal-button>
      </div>
    </touch-optimized-modal>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "OrderFulfilmentListSortButton",
  components: {TouchOptimizedModalButton, TouchOptimizedModal, TouchOptimizedButton},
  data: () => ({
    possibleSorts: [
      {label: 'Créneau ↑', value: 'timeSlot,asc'},
      {label: 'Créneau ↓', value: 'timeSlot,desc'},
      {label: 'Bac ↑', value: 'fulfilmentNumber,asc'},
      {label: 'Bac ↓', value: 'fulfilmentNumber,desc'},
    ]
  }),
  created() {
    // Set by default to timeSlot
    if (this.getSelectedSort === null) {
      this.setSelectedSort(this.possibleSorts[0]);
    }
  },
  methods: {
    ...mapMutations('fulfilmentFilters', [
      'setSelectedSort'
    ]),
    onClickSort(sort) {
      this.setSelectedSort(sort);
      this.$refs.modal.close();
    },
  },
  computed: {
    ...mapGetters('fulfilmentFilters', [
      'getSelectedSort'
    ]),
  },
}
</script>

