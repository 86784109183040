<template>
  <div>
    <modal
      ref="confirmModal"
      title="Attention"
      @close="onModalClose"
    >
      <div class="text-right">
        L'employé sélectionné reçoit une notification lui indiquant qu'une tâche lui<br>
        a été attribuée ou que la tâche qui lui a été attribuée a été mise à jour.
        <br><br>
        Continuer ?
      </div>

      <template #footer>
        <div class="flex justify-end gap-x-2">
          <button-element type="primary" @click="onSubmit">
            <fa-icon class="mr-2" icon="check"></fa-icon>
            Confirmer
          </button-element>

          <button-element type="light" @click="$refs.confirmModal.closeModal()">
            <fa-icon class="mr-2" icon="times"></fa-icon>
            Annuler
          </button-element>
        </div>
      </template>
    </modal>

    <div>
      <form-wrapper
        v-if="loaded"
        ref="form"
        :show-changed="true"
        :submit-button-label="getSubmitButtonLabel"
        @submit="openConfirmDialog"
      >

        <form-grid class="grid-cols-1 lg:grid-cols-4">
          <text-input-element
            v-model="title"
            class="lg:col-span-3"
            label="Titre"
            validation-rules="required"
          />

          <select-element
            v-model="status"
            :enable-search="false"
            :options="getStatusOptions"
            label="Status"
            validation-rules="required"
          />

          <text-area-element
            v-model="description"
            class="lg:col-span-4"
            label="Description"
          />

          <date-picker-element
            v-model="startDate"
            label="Date de début"
            validation-rules="required"
          />

          <date-picker-element
            v-model="endDate"
            label="Date de fin"
            validation-rules="required"
          />

          <select-element
            v-model="assignedToMemberId"
            :options="getEmployeeOptions"
            label="Employée assignée"
          />

          <select-element
            v-model="executedByMemberId"
            :options="getEmployeeOptions"
            label="Fait par"
          />
        </form-grid>
      </form-wrapper>
    </div>
  </div>
</template>

<script>
import TaskRepository from "@/repositories/TaskRepository";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import FormGrid from "@/components/elements/forms/FormGrid";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";
import MemberRepository from "@/repositories/MemberRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import Modal from "@/components/elements/modals/Modal.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "CreateOrUpdateTaskForm",
  components: {
    ButtonElement,
    Modal, SelectElement, DatePickerElement, TextAreaElement, TextInputElement, FormGrid, FormWrapper
  },
  props: {
    mode: {default: 'create', validator: val => ['create', 'update'].includes(val)},
    taskId: {type: Number}
  },
  data: () => ({
    title: '',
    description: null,
    startDate: null,
    endDate: null,
    assignedToMemberId: null,
    executedByMemberId: null,
    status: 'due',

    loaded: false,
    employees: [],

    submitCallback: null
  }),
  watch: {
    title: function (val) {
      this.$emit('updateTitle', val);
    }
  },
  created() {
    this.fetchEmployees();

    if (this.isUpdateMode) {
      this.fetchTask();
    } else {
      this.startDate = this.$date().format('YYYY-MM-DD');
      this.endDate = this.$date().add(1, 'day').format('YYYY-MM-DD');
      this.loaded = true;
    }
  },
  methods: {
    fetchTask() {
      this.loaded = false;

      TaskRepository
        .getSingle(this.taskId)
        .then((res) => {
          const task = res.data.data;

          this.title = task.title;
          this.description = task.description;
          this.startDate = task.startDate;
          this.endDate = task.endDate;
          this.assignedToMemberId = task.assignedToMemberId;
          this.executedByMemberId = task.executedByMemberId;
          this.status = task.status;
        }).finally(() => {
        this.loaded = true;
        this.$nextTick(() => {
          this.$ref.form.resetForm()
        })
      });
    },
    fetchEmployees() {
      MemberRepository
        .getAllEmployees(true)
        .then(res => this.employees = res.data.data)
    },

    updateTask(callback) {
      TaskRepository.update(
        this.taskId,
        this.getPayload
      ).then(() => {
        this.$sendSuccessToast("La tâche a été modifiée.");
        this.$refs.form.resetForm();
      }).finally(() => callback());
    },

    createTask(callback) {
      TaskRepository.create(
        this.getPayload
      ).then((res) => {
        this.$sendSuccessToast("La tâche a été crée.");
        this.$refs.form.resetForm();
        this.$router.push({
          name: 'task.edit',
          params: {
            task: res.data.data.id,
          }
        })
      }).finally(() => callback());
    },

    openConfirmDialog(callback) {
      this.submitCallback = callback;
      this.$refs.confirmModal.openModal();
    },

    onSubmit() {
      this.isUpdateMode ? this.updateTask(this.submitCallback) : this.createTask(this.submitCallback);
      this.submitCallback = null;
      this.$refs.confirmModal.closeModal();
    },

    onModalClose() {
      if (this.submitCallback !== null) {
        this.submitCallback();
      }
    }
  },
  computed: {
    isUpdateMode() {
      return this.mode === "update";
    },
    getSubmitButtonLabel() {
      return this.isUpdateMode ? "Sauvegarder" : "Créer";
    },

    getPayload() {
      return {
        title: this.title,
        description: this.description,
        startDate: this.startDate,
        endDate: this.endDate,
        assignedToMemberId: this.assignedToMemberId,
        executedByMemberId: this.executedByMemberId,
        status: this.status,
      };
    },

    getEmployeeOptions() {
      return this.employees.map(e => {
        return {
          label: e.fullName,
          value: e.id
        }
      });
    },

    getStatusOptions() {
      return [
        {label: 'Suspend', value: 'suspended'},
        {label: 'Dû', value: 'due'},
        {label: 'Fait', value: 'done'},
      ];
    }
  },
}
</script>