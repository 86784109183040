<template>
  <div>
    <list-table
      :columns="columns"
      :create-button="false"
      :extra-row-settings="getExtraRowSettings"
      :filters="getFilters"
      :pagination="true"
      :repository-export-func="exportRepositoryFunc"
      :repository-func="repositoryFunc"
      :select-values="selectValues"
      :title="title"
      enable-extra-row
      enable-totals
      export-button
      identifier="productSalesStatsTable"
      selectable
      show-filters-immediately
      sub-key="items"
    >
      <template v-slot:product-quick-action-template="{ row }">
        <product-quick-action-modal v-if="row.type === 'Product'" :id="row.id"/>
        <span v-else>{{ row.id }}</span>
      </template>

      <template v-slot:product-name-template="{ row }">
        <product-name :product="row"/>
      </template>

      <template v-slot:sales-quantity-template="{ row }">
        <div class="w-48 text-black border border-yellow-500 font-bold">
          <div :style="`width: ${row.quantityPercentage}%`"
               class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
            <div class="px-2 text-black">
              {{ row.totalQuantity }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sales-price-without-discounts-template="{ row }">
        <div class="w-48 text-black border border-yellow-500 font-bold z-0">
          <div :style="`width: ${row.priceWithoutReferencesPercentage}%`"
               class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
            <div class="px-2 text-black">
              <money-amount
                :amount="row.price.totalPriceWithoutReferences"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sales-price-with-discounts-template="{ row }">
        <div class="w-48 text-black border border-yellow-500 font-bold z-0">
          <div :style="`width: ${row.priceWithReferencesPercentage}%`"
               class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
            <div class="px-2 text-black">
              <money-amount
                :amount="row.price.totalPriceWithReferences"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sales-price-discount-template="{ row }">
        <div class="w-48 text-black border border-yellow-500 font-bold z-0">
          <div :style="`width: ${row.referencesPricePercentage}%`"
               class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
            <div class="px-2 text-black">
              <money-amount
                :amount="row.price.totalReferencesPrice"
              />
            </div>
          </div>
        </div>
      </template>


      <template v-slot:extra-row-template="{ row }">


        <template v-if="row.references?.length >0">
          <product-sales-stats-details
            :references="row.references"
          />
        </template>
      </template>

    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import OrderRepository from "@/repositories/OrderRepository";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal";
import {productFilters} from "@/helpers/listTableModels/ListTableProductFilters";
import {
  FILTER_BOOLEAN,
  FILTER_DATETIME_RANGE,
  FILTER_ORDER_ITEM_TYPE
} from "@/components/listTable/includes/controls/filters/availableFilters";
import {centsToEuroString} from "@/helpers/mathHelper";
import MoneyAmount from "@/components/global/Money/MoneyAmount";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";
import ProductSalesStatsDetails from "@/components/pages/Stats/ProductSalesStatsDetails.vue";

export default {
  name: "ProductSalesStats",
  components: {
    ProductSalesStatsDetails,
    ProductName, MoneyAmount, ProductQuickActionModal, ListTable
  },
  data() {
    return {
      title: 'Statistiques ventes par produit',
      selectValues: product => {
        return {
          id: product.id,
          type: product.type,
        };
      },
      columns: [
        {
          caption: '#',
          width: 80,
          cellTemplate: 'product-quick-action-template',
        },
        {
          caption: 'Type',
          dataField: 'type',
        },
        {
          caption: 'Nom',
          cellTemplate: 'product-name-template'
        },
        {
          caption: 'Nombre',
          cellTemplate: 'sales-quantity-template',
          totals: {
            sum: "totalQuantity",
            backend: {
              key: "orderedQuantity"
            }
          },
          sort: {dataField: 'totalQuantity'}

        },
        {
          caption: 'Total Prix (sans références)',
          cellTemplate: 'sales-price-without-discounts-template',
          totals: {
            sum: "price.totalPriceWithoutReferences",
            formatValue: (val) => centsToEuroString(val),
            backend: {
              key: "totalPriceWithoutReferences"
            }
          },
          sort: {dataField: 'totalPrice'}

        },
        {
          caption: 'Total Prix (avec références)',
          cellTemplate: 'sales-price-with-discounts-template',
          totals: {
            sum: "price.totalPriceWithReferences",
            formatValue: (val) => centsToEuroString(val),
            backend: {
              key: "totalPriceWithReferences"
            }
          },
          sort: {
            dataField: "totalPriceWithReferencesPrice"
          }
        },
        {
          caption: 'Total Prix Références',
          cellTemplate: 'sales-price-discount-template',
          totals: {
            sum: "price.totalReferencesPrice",
            formatValue: (val) => centsToEuroString(val),
            backend: {
              key: "totalReferencesPrice"
            }
          },
          sort: {
            dataField: "totalReferencesPrice"
          }
        },
      ],
      repositoryFunc: OrderRepository.stats.getProductSalesStats,
      exportRepositoryFunc: OrderRepository.stats.getProductSalesStatsExport
    }
  },
  computed: {
    getExtraRowSettings() {
      return {
        showOnlyForSelectionAfterButtonClick: true,
        button: {
          text: 'Afficher références',
          icon: 'link'
        }
      };
    },

    getFilters() {
      let filters = [
        {
          caption: 'Type',
          filterKey: 'type',
          type: FILTER_ORDER_ITEM_TYPE,
          forceIsVisible: true
        },
        {
          filterKey: 'createdPeriod',
          caption: "Commandé entre",
          type: FILTER_DATETIME_RANGE,
          defaultValue: {
            startDate: this.$date().subtract(6, 'months').format('DD.MM.YYYY HH:mm:ss'),
            endDate: this.$date().format('DD.MM.YYYY HH:mm:ss'),
          },
          forceIsVisible: true
        },
        {
          filterKey: 'memberCreatedAt',
          caption: "Member crée entre",
          type: FILTER_DATETIME_RANGE
        },
        ...productFilters
      ];

      // remove isDeleted and isPublished filters as confusing and not useful in this context
      filters = filters.filter(f => f.filterKey !== 'isDeleted' && f.filterKey !== 'isPublished');
      // remove isDiscontinued ias we need to overwrite it
      filters = filters.filter(f => f.filterKey !== 'isDiscontinued');

      filters.push(
        {
          caption: 'Produits discontinués',
          filterKey: 'isDiscontinued',
          type: FILTER_BOOLEAN,
          defaultValue: null,
          small: true
        },
      )

      return filters;
    }
  }
}
</script>

<style scoped>

</style>