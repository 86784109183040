import RackRepository from "../../../repositories/RackRepository";

const getDefaultState = () => {
  return {
    racks: [],
    activeRack: null
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getRacks: state => state.racks,
    getOrderedRackIds: state => state.racks.flatMap(r => r.id),
    getActiveRack: state => state.activeRack,
  },
  mutations: {
    setRacks(state, racks) {
      state.racks = racks;
    },
    setActiveRack(state, rack) {
      state.activeRack = rack;
    }
  },
  actions: {
    fetchRacks({commit}) {
      return RackRepository.get().then((res) => {
        commit('setRacks', res.data.data);
      })
    },
    setRackOrder({commit, getters, dispatch}, racks) {
      commit('setRacks', racks);
      return RackRepository.setOrder(getters.getOrderedRackIds).then(() => {
        dispatch('fetchRacks');
      })
    },
    createRack({dispatch}, payload) {
      return RackRepository
        .create(payload)
        .then(() => {
          dispatch('fetchRacks');
        })
    },
    updateRack({dispatch}, rack) {
      return RackRepository
        .update(rack.id, {
          number: rack.number
        }).then(() => {
          dispatch('fetchRacks');
        })
    },
    deleteRack({dispatch}, rack) {
      return RackRepository
        .delete(rack.id).then(() => {
          dispatch('fetchRacks');
        })
    },
  },
}