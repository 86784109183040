<template>
  <div>
    <div class="flex gap-x-4 justify-between mb-2">
      <page-title>
        <template v-if="getActiveCategory">
          {{ getActiveCategory.name }} #{{ getActiveCategory.id }}
        </template>
      </page-title>

      <button-element
        :disabled="isPublished"
        type="dark"
        @click="publishCategory"
      >
        <span v-if="isVirtual">Activer catégorie virtuel</span>
        <span v-else>Activer catégorie</span>

      </button-element>
    </div>

    <card>
      <card-tab id="categoryTab" icon="th-large" title="Catégorie">
        <category-create-or-update-form ref="updateForm" :category-id="getCategoryId" mode="update"/>
      </card-tab>

      <card-tab id="productsTab" icon="boxes" title="Produits">
        <edit-products-in-category :category-id="getCategoryId"
                                   :is-virtual="isVirtual"></edit-products-in-category>
      </card-tab>

      <card-tab id="imageTab" icon="images" title="Images">
        <manage-product-category-images :product-category-id="getCategoryId"></manage-product-category-images>
      </card-tab>

      <card-tab id="localizationTab" icon="globe" title="Traduction">
        <update-category-localization :category-id="getCategoryId"></update-category-localization>
      </card-tab>
    </card>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import PageTitle from "@/components/elements/pages/PageTitle";
import ManageProductCategoryImages from "@/components/pages/Categories/Common/Images/ManageProductCategoryImages";
import EditProductsInCategory from "@/components/pages/Categories/Products/Sub/EditProductsInCategory";
import UpdateCategoryLocalization from "@/components/pages/Categories/Common/Localization/UpdateCategoryLocalization";
import CategoryCreateOrUpdateForm from "@/components/pages/Categories/Common/Forms/CategoryCreateOrUpdateForm";
import CategoryRepository from "@/repositories/CategoryRepository";
import ButtonElement from "@/components/elements/buttons/ButtonElement";

export default {
  name: "EditProductCategory",
  components: {
    ButtonElement,
    CategoryCreateOrUpdateForm,
    UpdateCategoryLocalization,
    EditProductsInCategory,
    ManageProductCategoryImages,
    PageTitle,
    CardTab,
    Card
  },
  computed: {
    getCategoryId() {
      return parseInt(this.$route.params.category);
    },
    getActiveCategory() {
      return this.$store.getters['categories/getActiveCategory'];
    },
    isPublished() {
      return this.getActiveCategory?.isPublished;
    },
    isVirtual() {
      return this.getActiveCategory?.isVirtual;
    },

  },
  methods: {
    fetchCategory() {
      return CategoryRepository.getSingle(this.getCategoryId).then((res) => {
        this.$store.commit('categories/setActiveCategory', res.data.data);
      });
    },
    publishCategory() {
      CategoryRepository.enable(this.getCategoryId, false).then(() => {
        this.fetchCategory().then(() => {
          this.$sendSuccessToast('Catégorie publiée!');
        });
      });
    }
  },
  created() {
    this.fetchCategory();
  },
}
</script>

