<template>
  <div class="justify-between h-screen flex flex-col lg:flex-row">
    <dashboard-warning-modal></dashboard-warning-modal>

    <div class="flex-grow overflow-auto">
      <div
        :class="{'p-1': enablePadding}"
      >
        <slot></slot>
      </div>
    </div>
    <div class="w-full lg:w-72 flex-shrink-0 bg-gray-200">
      <slot name="sidebar"></slot>
    </div>
  </div>
</template>

<script>
import DashboardWarningModal from "@/components/layouts/dashboard/partials/DashboardWarningModal.vue";

export default {
  name: "FulfilmentLayoutBase",
  components: {DashboardWarningModal},
  props: {
    enablePadding: {
      type: Boolean,
      default: true
    }
  }
}
</script>

