import apiService from './Base/BaseRepository';

const url = '/postalCodes';

export default {
  async get() {
    return await apiService.get(`${url}/`)
  },

  async getPaginated(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0
  ) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async deleteSingle(id) {
    return await apiService.delete(`${url}/${id}`)
  },

  async create(values) {
    return await apiService.post(`${url}/`, values)
  },
}
