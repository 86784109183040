<template>
  <div>
    <modal ref="editRackModal" title="Modifier étagère">
      <form-wrapper ref="form" @submit="updateRack">
        <number-input-element v-model="getActiveRack.number" label="Numéro"
                              validation-rules="required"></number-input-element>
        <template #additionalButtons>
          <div class="flex gap-x-8">
            <button-element type="danger" @click="openDeleteModal">
              <fa-icon fixed-width icon="trash"></fa-icon>
            </button-element>

            <button-element type="light" @click="closeEditModal">
              Annuler
            </button-element>
          </div>
        </template>
      </form-wrapper>
    </modal>

    <delete-confirmation-modal ref="deleteRackModal" title="Supprimer étagère?" @delete="deleteRack">
      Êtes-vous sure que vous voulez supprimer cette étagère?
    </delete-confirmation-modal>
  </div>
</template>

<script>
import Modal from "../../../elements/modals/Modal";
import FormWrapper from "../../../elements/forms/FormWrapper";
import NumberInputElement from "../../../elements/forms/elements/base/NumberInputElement";
import ButtonElement from "../../../elements/buttons/ButtonElement";
import DeleteConfirmationModal from "../../../elements/modals/DeleteConfirmationModal";

export default {
  name: "RackActions",
  components: {DeleteConfirmationModal, ButtonElement, NumberInputElement, FormWrapper, Modal},
  methods: {
    openEditModal() {
      this.$refs.editRackModal.openModal();
    },
    closeEditModal() {
      this.$refs.editRackModal.closeModal();
      this.$store.dispatch('racks/fetchRacks').then(() => {
        this.$store.commit('racks/setActiveRack', null);
      });
    },
    openDeleteModal() {
      this.$refs.deleteRackModal.openModal();
    },
    closeDeleteModal() {
      this.$refs.deleteRackModal.closeModal();
    },
    updateRack(callback) {
      this.$store
        .dispatch('racks/updateRack', this.getActiveRack)
        .then(() => {
          this.$sendSuccessToast("Étagère modifiée!");
          this.closeEditModal();
        }).finally(() => callback());
    },
    deleteRack() {
      this.$store
        .dispatch('racks/deleteRack', this.getActiveRack)
        .then(() => {
          this.$sendSuccessToast("Étagère supprimé!");
          this.closeDeleteModal();
          this.closeEditModal();
        })
    }
  },
  computed: {
    getActiveRack() {
      return this.$store.getters['racks/getActiveRack'] ?? {id: null, number: null};
    }
  }
}
</script>

