<template>
  <div>
    <form-wrapper ref="form" :show-changed="true" :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer tag'"
                  @submit="createOrUpdateTag">
      <div class="flex gap-x-4 flex-wrap">
        <text-input-element v-model="name"
                            class="w-full lg:w-96"
                            label="Nom du tag"
                            validation-rules="required">

        </text-input-element>

        <select-element
          v-model="type"
          :allow-unselecting="false"
          :enable-search="false"
          :options="getAvailableTypes"
          class="w-full lg:w-96"
          label="Type"
          validation-rules="required"
          @input="$emit('input', $event)"
        />

        <checkbox-element
          v-model="isPublished"
          label="Publié"
        />
      </div>
    </form-wrapper>
  </div>
</template>
<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import TagsRepository from "../../../../repositories/TagsRepository";
import CheckboxElement from "../../../elements/checkboxes/CheckboxElement";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CreateOrUpdateTagForm",
  components: {
    SelectElement,
    CheckboxElement,
    FormWrapper,
    TextInputElement,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    }
  },
  data() {
    return {
      name: '',
      type: '',
      isPublished: true
    }
  },
  mounted() {
    this.getTag()
  },
  watch: {
    name: function (val) {
      this.$emit('updateName', val);
    }
  },
  methods: {
    getTag() {
      if (this.isUpdateMode) {
        TagsRepository.getSingle(this.$route.params.tag).then((res) => {
          this.name = res.data.data.name;
          this.type = res.data.data.type;
          this.isPublished = res.data.data.isPublished;
        }).then(() => this.$refs.form.resetForm());
      }
    },
    createOrUpdateTag(callback) {
      if (this.isUpdateMode) {
        return TagsRepository.update(this.$route.params.tag, {
          name: this.name,
          type: this.type,
          isPublished: this.isPublished,
        }).then(() => {
          this.$sendSuccessToast(`Tag ${this.name} a été modifié!`);
        }).finally(() => callback());
      }

      return TagsRepository.create({
        name: this.name,
        type: this.type,
        isPublished: this.isPublished,
      }).then((res) => {
        this.$router.push({name: 'tag.edit', params: {tag: res.data.data.id}});
        this.$sendSuccessToast(`Tag ${this.name} a été crée!`);
      }).finally(() => callback());
    },
  },
  computed: {
    isUpdateMode() {
      return this.mode === 'update';
    },

    getTitle() {
      if (this.isUpdateMode) {
        return 'Modifier tag';
      }
      return 'Créer nouveau tag';
    },

    getAvailableTypes() {
      return this.$luxcaddyConfig('tagTypes');
    }
  }
}
</script>