import ApiService from "@/repositories/Base/BaseRepository";
import apiService from "@/repositories/Base/BaseRepository";

const url = '/invoices/external/pdfs';

export default {
  async uploadPDFs(fileList) {
    let formData = new FormData();

    for (const [index, file] of fileList.entries()) {
      formData.append(`file[${index}]`, file.file);
    }

    return await ApiService.post(`${url}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  async getUnlinkedList(perPage = 8, page = 1, sorts = [], filters = []) {
    return await ApiService
      .setSorts(sorts)
      .setPagination(perPage, page)
      .setFilters(filters)
      .get(`${url}/unlinked`);
  },

  async getSingle(externalInvoicePdfId) {
    return await ApiService
      .get(`${url}/${externalInvoicePdfId}`);
  },

  async getPdfStreamById(externalInvoicePdfId) {
    return await ApiService
      .get(`${url}/${externalInvoicePdfId}/stream`, {
        responseType: 'arraybuffer',
        showLoadingIndicator: false
      });
  },

  async getOldestUnlinkedExternalInvoicePDFId() {
    return await ApiService
      .get(`${url}/unlinked/oldest`);
  },

  async linkToExistingInvoice(externalInvoicePdfId, invoiceId) {
    return await ApiService
      .patch(`${url}/${externalInvoicePdfId}/link-to-existing`, {
        invoiceId
      })
  },

  async deleteExternalInvoicePDF(externalInvoicePdfId) {
    return await ApiService
      .delete(`${url}/${externalInvoicePdfId}`);
  },

  comments: {
    async getPaginated(
      externalInvoicePdfId,
      perPage = 20,
      page = 1,
      sorts = [],
      filters = [],
      withTotals = 0
    ) {
      return await apiService
        .setPagination(perPage, page, withTotals)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/${externalInvoicePdfId}/comments`, {
          showLoadingIndicator: false
        });
    },

    async create(externalInvoicePdfId, payload) {
      return await apiService.post(`${url}/${externalInvoicePdfId}/comments`, payload, {
        showLoadingIndicator: false
      });
    },

    async update(externalInvoicePdfId, commentId, payload) {
      return await apiService.patch(`${url}/${externalInvoicePdfId}/comments/${commentId}`, payload, {
        showLoadingIndicator: false
      });
    },

    async delete(externalInvoicePdfId, commentId) {
      return await apiService.delete(`${url}/${externalInvoicePdfId}/comments/${commentId}`, {
        showLoadingIndicator: false
      });
    },
  },
}
