<template>
  <div class="grid grid-cols-2 gap-x-4 relative">
    <div
      :class="[isTreatingInvoicePDF ? 'col-span-1' : 'col-span-2']"
    >
      <form-wrapper
        ref="form"
        :show-changed="true"
        class="sticky top-20"
        submit-button-label="Créer facture"
        @submit="createInvoice"
      >
        <div :class="getFormGridClasses">
          <text-input-element
            v-model="invoiceNumber"
            label="Numéro de facture"
            validation-rules="required"
          />

          <member-select-input
            v-if="type === 'supplier'"
            v-model="memberId"
            :only-member-types="['supplier']"
            display-member-vat
            label="Fournisseur"
            only-active-member-types
            validation-rules="required"
            @selected="onMemberSelected"
          />
          <member-select-input
            v-else-if="type === 'customer'"
            v-model="memberId"
            :only-member-types="['customer']"
            display-member-vat
            label="Fournisseur"
            only-active-member-types
            validation-rules="required"
            @selected="onMemberSelected"
          />

          <text-input-element
            v-model="date"
            label="Date (YYYY-MM-DD)"
            validation-rules="required"
            @blur="changeDueDate"
          />

          <text-input-element
            v-model="dueDate"
            label="Date d'échéance (YYYY-MM-DD)"
          />

          <currency-input-element
            v-model.number="originalTotalAmount"
            :inset-text="getSymbolForSelectedCurrency"
            allow-negative
            label="Montant Facture"
            validation-rules="required"
          />

          <select-element
            v-model="type"
            :allow-unselecting="false"
            :enable-search="false"
            :options="[
                        {label: 'Fournisseur', value: 'supplier'},
                        {label: 'Client', value: 'customer'}
                    ]"
            label="Type de facture"
          />

          <select-element
            v-model="currency"
            :enable-search="false"
            :options="getCurrencyOptions"
            label="Devise"
            validation-rules="required"
          />

          <text-input-element
            v-model="currencyToEuroConversionRate"
            :disabled="currency === 'EUR'"
            :validation-rules="currency !== 'EUR' ? 'required' : ''"
            label="Taux de conversion en EUR"
          />

        </div>
      </form-wrapper>
    </div>

    <div
      v-if="externalInvoicePdf !== null"
    >
      <external-invoice-pdf-card
        :external-invoice-pdf="externalInvoicePdf"
        :show-delete-button="false"
        :show-supplier-order-link-button="true"
        :show-treat-buttons="false"
        height="h-screen-65"
        @pdfStateChanged="onPdfStateChanged"
        @request-reload="loadExternalInvoicePDF"
      />
    </div>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput";
import CurrencyInputElement from "@/components/elements/forms/elements/CurrencyInputElement";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import ExternalInvoicePdfCard
  from "@/components/pages/Accounting/ExternalInvoicePDFs/Sub/UnlinkedList/Sub/ExternalInvoicePdfCard.vue";
import {mapGetters} from "vuex";
import ExternalInvoicePDFRepository from "@/repositories/ExternalInvoicePDFRepository";

export default {
  name: "SharedCreateExternalInvoice",
  components: {
    ExternalInvoicePdfCard,
    SelectElement,
    TextInputElement, CurrencyInputElement, MemberSelectInput, FormWrapper
  },
  data: () => ({
    type: 'supplier',
    memberId: null,
    date: null,
    dueDate: null,
    originalTotalAmount: null,
    currency: 'EUR',
    currencyToEuroConversionRate: 1,
    invoiceNumber: null,
    paymentTermsDays: 30,

    externalInvoicePdf: null,

    invoicePdfState: {
      zoom: null,
      page: null,
      scrollTop: null,
      scrollLeft: null,
    }
  }),
  created() {
    this.date = this.$date().format('YYYY-MM-DD');
    this.changeDueDate();

    // If creating an invoice from an external Invoice PDF, save it to store.
    if ((this.$route.query?.bookExternalInvoicePdfId || null) !== null) {
      this.$store.commit('invoices/setExternalInvoicePDFIdBeingTreated', this.$route.query.bookExternalInvoicePdfId);
      this.loadExternalInvoicePDF();
    } else {
      this.$store.commit('invoices/setExternalInvoicePDFIdBeingTreated', null);
    }
  },

  methods: {
    loadExternalInvoicePDF() {
      ExternalInvoicePDFRepository
        .getSingle(this.$route.query.bookExternalInvoicePdfId)
        .then((res) => {
          this.externalInvoicePdf = res.data.data;
          this.$refs.form.focusFirstElement();
        })
    },

    onMemberSelected(member) {
      if (member !== undefined && member != null) {
        this.paymentTermsDays = member.paymentTermsDays;
      } else {
        this.paymentTermsDays = 30;
      }
      this.changeDueDate();

    },
    /**
     * Due date will be changed to Date +30 days is date is changed.
     */
    changeDueDate() {
      let date = this.$date(this.date);
      if (date.isValid()) {
        if (this.paymentTermsDays === null) {
          this.dueDate = null;
        } else {
          this.dueDate = date.add(this.paymentTermsDays, 'days').format('YYYY-MM-DD');
        }
      }
    },

    createInvoice(callback) {
      let payload = {
        type: this.type,
        memberId: this.memberId,
        date: this.date,
        dueDate: this.dueDate,
        originalTotalAmount: this.originalTotalAmount,
        currency: this.currency,
        currencyToEuroConversionRate: this.currencyToEuroConversionRate !== null
          ? parseFloat(this.currencyToEuroConversionRate)
          : null,
        invoiceNumber: this.invoiceNumber
      };

      if (this.isTreatingInvoicePDF) {
        payload.linkedExternalInvoicePdfId = this.getExternalInvoicePDFIdBeingTreated;
      }

      InvoiceRepository
        .external
        .createExternalInvoice(payload).then(res => {
        if (this.isTreatingInvoicePDF) {
          this.$emit('created-with-pdf', {
            invoiceId: res.data.data.id,
            pdfState: this.invoicePdfState
          });
        } else {
          this.$emit('created', res.data.data.id);
        }
      }).finally(() => callback());
    },

    onPdfStateChanged(
      {zoom, page, scrollTop, scrollLeft}
    ) {
      this.invoicePdfState.zoom = zoom;
      this.invoicePdfState.page = page;
      this.invoicePdfState.scrollTop = scrollTop;
      this.invoicePdfState.scrollLeft = scrollLeft;
    }
  },
  computed: {
    ...mapGetters('invoices', [
      'isTreatingInvoicePDF',
      'getExternalInvoicePDFIdBeingTreated',
    ]),
    getCurrencyOptions() {
      return this.$luxcaddyConfig('invoice.external.availableCurrencies').flatMap(c => c.currency);
    },
    getSymbolForSelectedCurrency() {
      return this.$luxcaddyConfig('invoice.external.availableCurrencies').find(c => c.currency === this.currency).symbol;
    },
    getFormGridClasses() {
      if (this.isTreatingInvoicePDF) {
        return "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-y-4 md:gap-x-8";
      }
      return "grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-y-4 md:gap-x-8";
    }
  },
}
</script>

<style scoped>

</style>