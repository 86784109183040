<template>
  <transition name="fade-up-down">
    <div v-show="showAlert" :class="$attrs.class"
         class="bg-white shadow-lg p-5 flex items-center rounded-lg  ring-1 ring-red-200 gap-6">
      <div class="mx-4">
        <fa-icon class="text-red-500" icon="exclamation-triangle" size="3x"></fa-icon>
      </div>
      <div class="flex-1 text-lg">
        <h4 v-if="title" class="font-medium">{{ title }}</h4>
        <div class="text-gray-500">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ErrorAlert",
  data: () => ({
    showAlert: false
  }),
  created() {
    if (this.showImmediately) {
      this.showAlert = true;
    }
  },
  props: {
    title: {
      type: String
    },
    showImmediately: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    toggle() {
      this.showAlert = !this.showAlert;
    }
  },
}
</script>

