<template>
  <div class="grid grid-cols-2 gap-1 w-full">
    <order-fulfilment-packaging-item
      v-for="packagingItem in getPackagingItems"
      :key="packagingItem.id"
      :packaging-item="packagingItem"
    />
  </div>
</template>

<script>
import OrderFulfilmentPackagingItem
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/order/OrderFulfilmentPackagingItem";

export default {
  name: "OrderFulfilmentPackagingItemList",
  components: {OrderFulfilmentPackagingItem},
  computed: {
    getPackagingItems() {
      return this.$store.getters["fulfilment/getPackagingItems"];
    }
  },
}
</script>