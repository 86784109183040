<!--
    Usage on columns:
    totals: {
        sum: "key", OR
        average: "key" OR
        custom: (allRows) => () AND OPTIONALLY
        text: "Sum:" (Text shown in front),
        formatValue: (value) => () (Runs a custom function using the result value)
    }
-->
<template>
  <!-- Hack so we can use 2x table rows in one component -->
  <div class="contents">
    <table-row
      v-if="showTotalsRow"
      class="border-t-4 bg-yellow-100"
    >
      <!-- The first column (under the select boxes) -->
      <table-data-cell
        v-if="extraColumn"
      >
        <div class="font-bold text-center">{{ getSelectedRows.length }}</div>
      </table-data-cell>

      <table-data-cell
        v-for="column in columns"
        :key="column.caption"
        :align="getColumnAlign(column)"
      >
            <span
              class="font-bold"
            >
                <span v-if="column.totals && column.totals.text">
                    {{ column.totals.text }}
                </span>

                {{ formatValue(column, getFuncForSelectedColumn(column)) }}
            </span>
      </table-data-cell>
    </table-row>

    <!-- Totals from Backend -->
    <table-row
      v-if="hasTotalsFromBackend"
      class="border-t-4 bg-gray-100"
    >
      <table-data-cell
        v-if="extraColumn"
      />

      <table-data-cell
        v-for="column in columns"
        :key="column.caption"
        :align="getColumnAlign(column)"
      >
            <span
              class="font-bold"
            >
                <span v-if="column.totals && column.totals.text">
                    {{ column.totals.text }}
                </span>

                {{ formatValue(column, getBackendTotalForColumn(column)) }}
            </span>
      </table-data-cell>
    </table-row>
  </div>
</template>

<script>
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";

export default {
  name: "ListTableTotalsRow",
  components: {TableDataCell, TableRow},
  props: {
    // Whether to add an extra column at the beginning which is used for selecting rows.
    extraColumn: {
      type: Boolean,
    },
    columns: {
      type: Array,
      required: true
    },
    tableRows: {
      type: Array,
      required: true
    },

    // Mapped properties for comparison (if the property from selectValues doesn't match the property from the item row)
    selectValuesMap: {
      type: Object,
      default: null
    },
    /**
     * Total values pre-calculated from the backend, which are used when
     * there is no row selected.
     */
    totalValues: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getFuncForSelectedColumn(column) {
      if (!column.totals)
        return null;

      if (column.totals.sum) {
        return this.getSumForColumn(column);
      }

      if (column.totals.average) {
        return this.getAverageForColumn(column);
      }

      return column.totals.custom(
        this.getTableRows
      );
    },
    formatValue(column, value) {
      if (!column.totals || column.totals.formatValue === undefined)
        return value;

      return column.totals.formatValue(value);
    },
    /**
     * Returns the total returned from the backend.
     * @param column
     */
    getBackendTotalForColumn(column) {
      const key = column.totals?.backend?.key;

      return this.totalValues[key] ?? '';
    },

    getSpecialCharOfString(s) {
      if(typeof s !== "string") {
        return null;
      }

      if(s.includes('€')) {
        return '€';
      }
      if(s.includes('$')) {
        return '$';
      }
      if(s.includes('%')) {
        return '%';
      }
      return null;
    },


    // If a string contains a numeric value, returns either int or float, otherwise, the string.
    getValueParsed(value) {
      return Math.round(parseFloat(value) * 100) / 100;
    },

    getSumForColumn(column) {
      const key = column.totals.sum;
      const vm = this;

      let specialChar = null;

      const sum = this.getTableRows
        .filter(r => this.getValueByKeyFromRow(r, key) !== null)
        .reduce(function (sum, current) {
          const value = vm.getValueByKeyFromRow(current, key);

          if(specialChar === null) {
            specialChar = vm.getSpecialCharOfString(value)
          }

          return sum + vm.getValueParsed(value);
        }, 0);

      let parsedSum = this.getValueParsed(sum);

      if(specialChar && !isNaN(parsedSum)) {
        parsedSum = parsedSum.toFixed(2) + specialChar;
      }


      return isNaN(parsedSum) ? null : parsedSum;
    },

    getAverageForColumn(column) {
      const key = column.totals.average;
      const vm = this;

      return this.getTableRows
        .filter(r => this.getValueByKeyFromRow(r, key) !== null)
        .reduce(function (sum, current) {
          return sum + parseInt(vm.getValueByKeyFromRow(current, key));
        }, 0) / this.getTableRows.length;
    },


    getValueByKeyFromRow(row, key) {
      if (key.includes('.')) {
        let keyParts = key.split('.');

        keyParts.forEach(kp => {
          row = row[kp]
        });

        return row ?? null;
      }

      return row[key] ?? null;
    },

    getColumnAlign(column) {
      if (column.dataType && ['euro', 'percentage'].includes(column.dataType)) {
        return 'right';
      }

      return null;
    },

    /*
        Uses values from selectValuesMap
        This is typically needed if property names are transitioned in the selectValues object, so that backend batch actions accept them.
        Here we "undo" this transition, but we need to explicity know which properties have been transitioned (passed in selectValuesMap),
        example in ShowMissingStockTable
     */
    getMappedPropertyFromSelectValueProperty(property) {
      if (this.selectValuesMap === null) {
        return property;
      }
      if (!(property in this.selectValuesMap)) {
        return property;
      }

      return this.selectValuesMap[property];
    }
  },
  computed: {
    showTotalsRow() {
      // Rows selected & matching
      return this.hasSelectedAnyRows && this.getTableRows.length > 0;
    },
    getSelectedRows() {
      return this.$store.getters['listTable/getSelectedRows'];
    },
    getTableRows() {
      const selectedRows = this.getSelectedRows;
      // extraColumn check is an extra check as selection totals shouldnt work when
      // checkboxes to select a column are not enabled.
      if (!this.extraColumn || selectedRows.length === 0) {
        return [];
      }

      // only calculate based on selected rows
      return this.tableRows.filter(row => {
        return selectedRows.find(r => {
          return Object.entries(r).every(([key, value]) => {
            return row[this.getMappedPropertyFromSelectValueProperty(key)] === value;
          });
        })
      });
    },
    hasTotalsFromBackend() {
      if (this.totalValues === null) {
        return false;
      }
      return Object.entries(this.totalValues).length > 0;
    },
    hasSelectedAnyRows() {
      return this.getSelectedRows.length > 0;
    }
  }
}
</script>
