import auth from "../middlewares/authMiddleware";
import ShowDeliveryTimeSlots from "../../components/pages/DeliveryTimeSlots/ShowDeliveryTimeSlots";

export default [
  {
    path: '/delivery-timeslots',
    name: 'deliveryTimeSlots.list',
    component: ShowDeliveryTimeSlots,
    meta: {
      middleware: [auth],
      title: 'Listes des créneaux',
      permissions: ['TIMESLOT_READ', 'TIMESLOT_WRITE']
    }
  },
]