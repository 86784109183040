<template>
  <div>
    <page-title>
      Créer bannière
    </page-title>

    <card>
      <h3 class="font-bold mb-2">
        Type bannière
      </h3>

      <div class="flex gap-x-4 mb-6">
        <div
          :class="[bannerType === 'image' ? 'border-luxcaddy-500 bg-luxcaddy-300': 'hover:bg-luxcaddy-200']"
          class="bg-luxcaddy-100 rounded-md p-16 cursor-pointer border-2 border-dashed border-transparent"
          @click="bannerType = 'image'"
        >
          Image
        </div>
        <div
          :class="[bannerType === 'text' ? 'border-luxcaddy-500 bg-luxcaddy-300': 'hover:bg-luxcaddy-200']"
          class="bg-luxcaddy-100 rounded-md p-16 cursor-pointer border-2 border-dashed border-transparent"
          @click="bannerType = 'text'"
        >
          Text
        </div>
      </div>

      <create-or-update-banner-form
        :banner-type="bannerType"
        mode="create"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import CreateOrUpdateBannerForm from "@/components/pages/Banner/Management/Common/CreateOrUpdateBannerForm";

export default {
  name: "EditBanner",
  components: {CreateOrUpdateBannerForm, Card, PageTitle},
  data() {
    return {
      bannerType: 'image'
    }
  },
}
</script>

<style scoped>

</style>