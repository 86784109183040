<template>
  <modal ref="createRackModal" title="Créer étagère">
    <form-wrapper v-if="renderForm" ref="createRackForm" @submit="createNewRack">
      <number-input-element v-model="rackNumber" label="Numéro"
                            validation-rules="required"></number-input-element>

      <template #additionalButtons>
        <button-element type="light" @click="closeModal">
          Annuler
        </button-element>
      </template>
    </form-wrapper>
  </modal>
</template>

<script>
import Modal from "../../../elements/modals/Modal";
import FormWrapper from "../../../elements/forms/FormWrapper";
import NumberInputElement from "../../../elements/forms/elements/base/NumberInputElement";
import ButtonElement from "../../../elements/buttons/ButtonElement";

export default {
  name: "CreateRackModal",
  components: {ButtonElement, NumberInputElement, FormWrapper, Modal},
  data: () => ({
    rackNumber: null,

    renderForm: false,
  }),
  methods: {
    openModal() {
      this.renderForm = true;
      this.$refs.createRackModal.openModal();
    },
    closeModal() {
      this.renderForm = false;
      this.$refs.createRackModal.closeModal();
    },
    createNewRack(callback) {
      this.$store.dispatch('racks/createRack', {
        number: this.rackNumber
      }).then(() => {
        this.$sendSuccessToast('Étagère crée!');
        this.rackNumber = null;
        this.closeModal();
      }).finally(() => callback())
    }
  }
}
</script>

