<template>
  <modal ref="modal" :show-close-button="true" title="Convertir en commande">
    <template v-if="getMemberId">
      <checkbox-element
        v-model="shouldCreateInvoice"
        label="Créer aussi une facture"
      />
      <checkbox-element
        v-model="shouldCreateDelivery"
        label="Créer aussi une livraison"
      />
      <delivery-contact-select-input v-if="shouldCreateDelivery" v-model="selectedDeliveryContact"
                                     :member-id="getMemberId"
                                     :show-selection-info="true"></delivery-contact-select-input>

      <div v-if="selectedDeliveryContact" class="mb-4 w-full">
        <time-slot-selection
          v-if="selectedDeliveryContact"
          v-model="selectedTimeSlotId"
          :zone-id="selectedDeliveryContact.zoneId"
        />

        <div class="mt-4">
          <card v-if="!selectedTimeSlotId" class="text-center" type="danger">
            Veuillez choisir un créneau horaire.
          </card>

          <card v-else class="text-center flex flex-col" type="light">
            <div class="mb-2">
              <fa-icon icon="truck" size="2x"></fa-icon>
            </div>
            <div>
              Vous avez sélectionné une livraison pour <span
              class="font-bold">{{ getSelectedTimeSlotData.weekday }}</span>
              entre <span class="font-bold">{{ getSelectedTimeSlotData.start }}</span>
              et <span class="font-bold">{{ getSelectedTimeSlotData.end }}</span>.
            </div>
          </card>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-row-reverse mt-4">
        <button-element :disabled="!canConvertCart" disabled-tooltip="Veuillez d'abord selectionner un créneau"
                        @click="convertToOrder">
          Convertir en commande
        </button-element>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../elements/modals/Modal";
import TimeSlotSelection from "../../../global/TimeSlots/TimeSlotSelection";
import Card from "../../../elements/cards/Card";
import ButtonElement from "../../../elements/buttons/ButtonElement";
import OrderRepository from "../../../../repositories/OrderRepository";
import DeliveryContactSelectInput from "../../../elements/forms/elements/DeliveryContactSelectInput";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";

export default {
  name: "ConvertCartToOrderModal",
  components: {CheckboxElement, DeliveryContactSelectInput, ButtonElement, Card, TimeSlotSelection, Modal},
  data: () => ({
    deliveryContacts: [],
    shouldCreateDelivery: true,
    shouldCreateInvoice: true,
    selectedDeliveryContact: null,
    selectedTimeSlotId: null,
  }),
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    convertToOrder() {
      if (!this.canConvertCart) {
        return false;
      }

      const packageOptions = this.$store.getters['cart/getSelectedPackagingOptions'];

      OrderRepository.convertFromCart(
        this.getMemberId,
        this.selectedDeliveryContact?.id,
        this.selectedTimeSlotId,
        packageOptions,
        this.shouldCreateInvoice
      ).then((res) => {
        this.$sendSuccessToast("Le panier a été converti en commande.");
        this.$router.push({name: 'orders.edit', params: {order: res.data.data.id}});
      })
    }
  },
  computed: {
    canConvertCart() {
      if (!this.shouldCreateDelivery) {
        return true;
      }
      return this.selectedDeliveryContact !== null && this.selectedTimeSlotId !== null;
    },
    getSelectedTimeSlotData() {
      let slot = this.$store.getters['cart/getTimeSlots'].timeSlots.find(s => s.id === this.selectedTimeSlotId);

      if (!slot)
        return null;

      const startDate = this.$date(slot.startDate);
      const endDate = this.$date(slot.endDate);

      return {
        id: slot.id,
        weekday: startDate.locale('fr').format("dddd le DD.MM"),
        start: startDate.format("HH:mm"),
        end: endDate.format("HH:mm"),
      }
    },
    getMember() {
      return this.$store.getters['cart/getMember'];
    },
    getMemberId() {
      return this.$store.getters['cart/getMemberId'];
    },
  }
}
</script>