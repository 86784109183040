<template>
  <modal
    ref="instructionModal"
    :show-close-button="true"
    title="Instruction"
  >
    <div class="w-96">
      <form-wrapper
        ref="form"
        :show-changed="false"
        :show-submit-button="mode !== 'show'"
        :submit-button-label="mode === 'create' ? 'Créer instruction' : 'Sauvegarder instruction'"
        @submit="createOrUpdateInstruction"
      >
        <form-grid>
          <text-area-element
            v-model="instruction.text"
            label="Instruction"
          />

          <select-element
            v-model="instruction.type"
            :enable-search="false"
            :options="getInstructionTypeOptions"
            label="Type d'instruction"
          />

          <checkbox-element
            v-if="applyOnceSupported"
            v-model="instruction.applyOnce"
            label="Appliquer seulement une fois"
          />

          <date-picker-element
            v-model="instruction.expiresAt"
            label="Date d'expiration"
            show-set-null-button
          />
        </form-grid>
      </form-wrapper>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import FormGrid from "@/components/elements/forms/FormGrid";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "createOrUpdateInstructionModal",
  components: {SelectElement, CheckboxElement, DatePickerElement, TextAreaElement, FormGrid, FormWrapper, Modal},
  data: () => ({
    instruction: {
      text: '',
      type: '',
      applyOnce: false,
      expiresAt: null
    }
  }),
  props: {
    endpoints: {type: Array,},
    endpointTypes: {type: Object,},
    callEndpointFunc: {type: Function,},

    mode: {type: String, default: ''},
    instructableId: {type: Number},
    instructionId: {type: Number},
    instructionTypes: {type: Array, required: true},
    applyOnceSupported: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getInstructionTypeOptions() {
      let types = [
        {label: 'Administrative', value: 'admin'},
        {label: 'Packaging', value: 'fulfillment'},
        {label: 'Livraison', value: 'delivery'},
      ];

      return types.filter(t => this.instructionTypes.includes(t.value));
    },
    getPayload() {
      let payload = {
        text: this.instruction.text,
        type: this.instruction.type,
        validityDate: this.instruction.expiresAt,
      };

      if (this.applyOnceSupported) {
        payload.applyOnce = this.instruction.applyOnce;
      }

      return payload;
    }
  },
  methods: {
    fetchOrInitiate() {
      if (this.mode === "update" && this.commentId !== null) {
        this.callEndpointFunc(this.endpointTypes.GET, this.instructableId, this.instructionId).then(res => {
          this.instruction.text = res.data.data.text;
          this.instruction.type = res.data.data.type;
          this.instruction.expiresAt = res.data.data.expiresAt;

          if (this.applyOnceSupported) {
            this.instruction.applyOnce = res.data.data.applyOnce;
          }

          this.$nextTick(() => {
            this.$refs.instructionModal.openModal();
          });
        })
      } else {
        this.instruction = {
          text: '',
          type: '',
          applyOnce: false,
          expiresAt: null
        };
        this.$nextTick(() => {
          this.$refs.instructionModal.openModal();
        });
      }
    },

    createOrUpdateInstruction(callback) {
      if (this.mode === 'update') {
        this.callEndpointFunc(this.endpointTypes.UPDATE, this.instructableId, this.instructionId, this.getPayload).then(() => {
          this.$sendSuccessToast('Instruction sauvegardé!');
          this.$emit('change');
          this.resetForm();
          this.closeModal();
        }).finally(() => callback());
      } else {
        this.callEndpointFunc(this.endpointTypes.CREATE, this.instructableId, this.getPayload).then(() => {
          this.$sendSuccessToast('Instruction crée!');
          this.$emit('change');
          this.resetForm();
          this.closeModal();
        }).finally(() => callback());
      }
    },

    resetForm() {
      this.$refs.form.resetForm();
    },

    closeModal() {
      this.$refs.instructionModal.closeModal();
    },
  },
}
</script>

