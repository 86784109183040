<template>
  <div class="p-0 xl:p-8">
    <barcode-login-handler>
      <div v-if="!hideButtonToManualLogin" class="mt-6">
        <button-element
          size="small"
          type="light"
          @click="$router.push({name: 'auth.login'})"
        >
          Entrée manuelle
        </button-element>
      </div>
    </barcode-login-handler>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import BarcodeLoginHandler from "@/components/pages/Auth/sub/BarcodeLoginHandler.vue";

export default {
  name: "BarcodeLoginPage",
  components: {BarcodeLoginHandler, ButtonElement},
  computed: {
    hideButtonToManualLogin() {
      return this.$route.query.man !== undefined;
    }
  }
}
</script>

<style scoped>

</style>