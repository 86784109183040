<!--
This component is used to toggle Visibility etc if the user wants to
highlight a given delivery by passing it to the query url.
-->
<template>
  <div></div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "RouteManagerQueryObserver",
  data: () => ({
    date: null,
    deliveryId: null,
  }),
  mounted() {
    if (this.$route.query) {
      if (this.$route.query.date) {
        this.date = this.$route.query.date;
      }
      if (this.$route.query.deliveryId) {
        this.deliveryId = Number(this.$route.query.deliveryId);

        this.hideOtherRoutes();
        this.setDeliveryHovered();
      }
    }
  },
  methods: {
    ...mapMutations('routeManager', [
      'hideAllDeliveriesOfRoute',
      'setHoveredDeliveryId',
      'expandOrCollapseRoute',
      'setHoverEffectOnMap',
      'setShowDeliveryOverlays',
    ]),
    /**
     * Hides all deliveries that belong to routes other than the route
     * of the delivery provided through the URL query params.
     */
    hideOtherRoutes() {
      const routeIds = this.$store.getters['routeManager/getRoutes']
        .filter(r => r.id !== this.getRouteByDeliveryId.id)
        .flatMap(r => r.id);

      routeIds.forEach(routeId => {
        this.hideAllDeliveriesOfRoute(routeId);
      })
    },
    /**
     * Enavles the highlighting for the delivery that has been provided in the URL.
     */
    setDeliveryHovered() {
      this.setShowDeliveryOverlays(true);
      this.setHoverEffectOnMap(true);
      this.$nextTick(() => {
        this.setHoveredDeliveryId({
          deliveryId: this.deliveryId,
          initiator: 'sidebar'
        });
        if (this.getRouteByDeliveryId) {
          this.expandOrCollapseRoute(this.getRouteByDeliveryId.id);
        }
      });
    }
  },
  computed: {
    /**
     * Returns the id of the route the provided delivery belongs to.
     * @returns {*}
     */
    getRouteByDeliveryId() {
      return this.$store.getters['routeManager/getRouteByDeliveryId'](this.deliveryId);
    }
  },
}
</script>

<style scoped>

</style>