<template>
  <div>
    <page-title>
      {{ getTitle }}
    </page-title>

    <card>
      <form-wrapper ref="form" :show-changed="true" submit-button-label='Créer le code postal'
                    @submit="createPostalCode">
        <div class="grid grid-cols-1 gap-y-4 md:gap-x-8">
          <text-input-element v-model="postalCode"
                              label="Code postal"
                              validation-rules="required">

          </text-input-element>

          <select-element
            v-model="zone"
            :allow-unselecting="false"
            :enable-search="false"
            :options="availableZones"
            label="Zone"
            validation-rules="required"
            @input="$emit('input', $event)"
          />
        </div>
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import Card from "../../../elements/cards/Card";
import PageTitle from "../../../elements/pages/PageTitle";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import DeliveryPostalCodeRepository from "../../../../repositories/DeliveryPostalCodeRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CreatePostalCode",
  components: {
    SelectElement,
    FormWrapper,
    Card, PageTitle,
    TextInputElement,
  },
  data() {
    return {
      postalCode: '',
      zone: '',
      availableZones: this.$luxcaddyConfig('zoneList'),
    }
  },
  methods: {
    createPostalCode(callback) {
      return DeliveryPostalCodeRepository.create({
        postalCode: this.postalCode,
        zone: this.zone
      }).then(() => {
        this.$router.push({name: 'postalCodes.list'});
        this.$sendSuccessToast(`Tag ${this.postalCode} a été crée!`);
      }).finally(() => callback());
    }
  }
}
</script>