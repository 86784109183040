<!--
  This is the starting point for all things comments.
  Allows to open a model to view all comments and
  be able to add new comments.
-->
<template>
  <div>
    <status-indicator
      :custom-classes="getCustomClasses"
      :small="small"
      @click="onClick"
    >
      <div class="w-6">
        <loading-svg-inline
          v-if="loadingComments"
          :size="small ? 'h-5 w-5' : 'h-6 w-6'"
        />

        <fa-icon
          v-else
          fixed-width
          icon="comments"
        />
      </div>


      {{ commentCount }} commentaire{{ commentCount === 1 ? '' : 's' }}
    </status-indicator>

    <comment-modal
      ref="modal"
      @change="$emit('change')"
    />
  </div>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator.vue";
import CommentModal from "@/components/global/Comments/Modal/CommentModal.vue";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline.vue";

export default {
  name: "CommentCountBadge",
  components: {LoadingSvgInline, CommentModal, StatusIndicator},
  data() {
    return {
      loadingComments: false
    }
  },
  props: {
    model: {
      type: String,
      required: true,
      validator: val => ['Product', 'ExternalInvoicePdf'].includes(val)
    },
    modelId: {
      type: Number,
      required: true,
    },
    commentCount: {
      type: Number,
      default: 0
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.loadingComments = true;
      this.$store.commit('comments/setModelData', {
        type: this.model,
        id: this.modelId
      });
      this.$store.dispatch('comments/fetchComments').then(() => {
        this.$refs.modal.openModal();
      }).finally(() => this.loadingComments = false);
    }
  },
  computed: {
    hasComments() {
      return this.commentCount > 0;
    },
    getCustomClasses() {
      if (this.hasComments) {
        return "bg-gray-900 hover:bg-black text-white cursor-pointer";
      }
      return "bg-gray-200 hover:bg-gray-300 cursor-pointer opacity-60 hover:opacity-100";
    }
  },
}
</script>