<template>
  <div>
    <touch-optimized-button
      class="border border-gray-400 p-6"
      type="secondary"
      @click="onClick"
    >
      UNDO
    </touch-optimized-button>

    <touch-optimized-modal v-if="requiresConfirmation" ref="modal">
      <div class="text-3xl mb-24">Êtes-vous sure?</div>

      <div class="flex gap-x-8">
        <touch-optimized-modal-button @click="closeModal">Non</touch-optimized-modal-button>
        <touch-optimized-button class="p-16" type="danger" @click="onConfirmation">Oui</touch-optimized-button>
      </div>
    </touch-optimized-modal>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";

export default {
  name: "OrderFulfilmentUndoButton",
  components: {TouchOptimizedModalButton, TouchOptimizedModal, TouchOptimizedButton},
  props: {
    requiresConfirmation: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClick(e) {
      if (this.requiresConfirmation) {
        this.$refs.modal.open();
      } else {
        this.$emit('click', e);
      }
    },
    onConfirmation() {
      this.$emit('click');
      this.closeModal();
    },
    closeModal() {
      this.$refs.modal.close();
    }
  },
}
</script>

