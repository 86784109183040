<template>
  <div>
    <route-manager-poly-line-generator
      :tracking-points="getTrackingPoints"
    >

    </route-manager-poly-line-generator>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RouteManagerPolyLineGenerator
  from "@/components/pages/RouteManager/Includes/Map/Tracking/RouteManagerPolyLineGenerator";

export default {
  name: "RouteManagerMapTrackingPolylines",
  components: {RouteManagerPolyLineGenerator},
  watch: {
    getTrackedRouteId(id) {
      // Reset interval when tracked Route changes
      clearInterval(this.trackingInterval);
      this.trackingInterval = null;

      if (id !== null) {
        this.updateTracking(id);

        this.trackingInterval = setInterval(() => {
          this.updateTracking(id);
        }, 1000 * 5);
      }
    }
  },
  data: () => ({
    trackingInterval: null
  }),
  beforeDestroy() {
    clearInterval(this.trackingInterval);
    this.trackingInterval = null;
  },
  computed: {
    ...mapGetters('routeManager', [
      'getTrackedRouteId',
      'getTrackingPoints'
    ]),
  },
  methods: {
    ...mapActions('routeManager', [
      'fetchRouteTrackingData',
    ]),
    ...mapMutations('routeManager', [
      'setTrackedRouteId',
      'setTrackingPoints'
    ]),
    updateTracking(routeId) {
      this.fetchRouteTrackingData(routeId);
    }
  },
}
</script>

