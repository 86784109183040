<template>
  <div :class="getColorClassBackground" class="overflow-hidden h-6 text-sm flex rounded relative">
    <div class="absolute font-bold text-black flex items-center justify-center h-6 pl-1">
      {{ percentage.toFixed(0) }}%
    </div>
    <div :class="getColorClassForeground" :style="`width: ${percentage}%`" class="shadow-none flex flex-col"></div>
  </div>
</template>

<script>
export default {
  name: "FulfilmentProgress",
  props: {
    percentage: {
      type: Number,
      required: true,
      validator: val => val >= 0 && val <= 100
    },
    multiColor: {
      type: Boolean,
      default: true,
    },
    warningThreshold: {
      type: Number,
      default: 99,
    },
    dangerThreshold: {
      type: Number,
      default: 0
    },
  },
  computed: {
    getColorClassBackground() {
      if (!this.multiColor) {
        return "bg-luxcaddy-200";
      }
      if (this.percentage <= this.dangerThreshold) {
        return "bg-red-200";
      }
      return this.percentage <= this.warningThreshold
        ? "bg-yellow-200"
        : "bg-luxcaddy-200";
    },
    getColorClassForeground() {
      if (!this.multiColor) {
        return "bg-luxcaddy-500";
      }
      if (this.percentage <= this.dangerThreshold) {
        return "bg-red-500";
      }
      return this.percentage <= this.warningThreshold
        ? "bg-yellow-400"
        : "bg-luxcaddy-500";
    }
  },
}
</script>

