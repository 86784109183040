import apiService from './Base/BaseRepository';

const url = '';

export default {
  /**
   * Returns a list of all countries.
   *
   * @return {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/countries`);
  } ,

  async getCountryPhonePrefix() {
    return await apiService.get(`${url}/countryPhonePrefix`);
  }
}
