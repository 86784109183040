<template>
  <VueCtkDateTimePicker
    v-if="!isInReadOnlyMode"
    v-model="model"
    :button-color="color"
    :color="color"
    :format="format"
    :inline="inline"
    :minute-interval="5"
    :no-shortcuts="true"
    :no-value-to-custom-elem="true"
    :only-time="true"
    locale="fr"
    @validate="emitValidate"
  >
    <base-input-element
      :label="label"
      :occupy-validation-space="occupyValidationSpace"
      :placeholder="placeholder"
      :small="small"
      :type="type"
      :validation-rules="validationRules"
      :value="formattedValue"
    ></base-input-element>
  </VueCtkDateTimePicker>

  <base-input-element
    v-else
    :label="label"
    :occupy-validation-space="occupyValidationSpace"
    :placeholder="placeholder"
    :small="small"
    :type="type"
    :validation-rules="validationRules"
    :value="formattedValue"
  ></base-input-element>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import BaseInputElement from "./BaseInputElement";

export default {
  name: "TimePickerElement",
  components: {BaseInputElement, VueCtkDateTimePicker},
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    formattedValue() {
      if (this.value === null)
        return '';

      return this.value;
    },
    isInReadOnlyMode() {
      return this.$isReadOnly();
    },
  },
  methods: {
    emitValidate(e) {
      this.$emit('validate', e);
    }
  },
  props: {
    value: {
      required: false,
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    validationRules: {
      type: String,
      required: false,
    },
    format: {
      type: String,
      default: "HH:mm"
    },
    inline: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#B2C666'
    }
  }
}
</script>

