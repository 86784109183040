<template>
  <table-row
    :class="{
             'bg-yellow-100 hover:bg-yellow-200': isRowSelected,
             'bg-red-100 hover:bg-red-200': hasRowError
        }"
    @click.stop="selectOrUnselectRowIfSelectable"
  >
    <table-data-cell
      v-for="column in getTableHeadings" :key="column.caption" :align="column.align"
      :class="[
                canBreakLine(column) ? '' : 'whitespace-nowrap',
                getAdditionalClasses(column, row),
                getColumnButtons(column) ? 'w-0' : ''
            ]"
    >
      <!-- ^- w-0 makes button cols as small as possible but without breaking as it also has whitespace-nowrap -->

      <!-- Calculated cell value -->
      <div v-if="column.calculateCellValue !== undefined">
        <div v-if="column.dataType === 'boolean'">
          <list-table-type-boolean :value="column.calculateCellValue(row)"/>
        </div>
        <div v-else-if="column.dataType === 'image'">
          <list-table-type-image :value="column.calculateCellValue(row)"/>
        </div>
        <div v-else v-html="column.calculateCellValue(row)"/>
      </div>

      <!-- Buttons -->
      <div v-else-if="getColumnButtons(column)" class="flex gap-1 whitespace-nowrap">
        <component
          :is="getPossibleButtons.find(b => b.type === button.type).component"
          v-for="(button, i) in getColumnButtons(column)"
          :key="button.type + '-' + i"
          :confirmation-options="button.type === 'delete' || button.type === 'unlink' ? button.confirmation : null"
          :custom-text="button.customText"
          :disabled="isButtonDisabled(button, row)"
          :hint="button.hint"
          :icon="button.icon"
          :show-icon="button.showIcon"
          @click.stop="onClickTableButton(button, row, false)"
          @click-ctrl.stop="onClickTableButton(button, row, true)"
        >
        </component>
      </div>

      <!-- Cell Template Value -->
      <slot
        v-else-if="column.cellTemplate !== undefined"
        :name="column.cellTemplate"
        v-bind="{ row: row }"
      />

      <!-- normal value -->
      <div v-else>
        <!-- Select Checkbox for Batch Action -->
        <div v-if="getValueFromRow(column.dataField) === 'SELECTBOX'">
          <div class="flex items-center gap-x-2">
            <list-table-row-select
              :row="row"
              :select-values="selectValues"
            />

            <!-- Error Message / Icon -->
            <div
              v-if="hasRowError"
            >
              <tooltip :text="getErrorMessageForRow" tooltip-classes="-ml-4">
                <fa-icon class="mb-0.5 text-red-500" fixed-width icon="exclamation-triangle"></fa-icon>
              </tooltip>
            </div>
          </div>
        </div>

        <!-- normal value -->
        <div v-else>

          <!-- Values formatted as euro, percentage or numeric -->
          <div v-if="column.dataType !== undefined && getValueFromRow(column.dataField) !== null">
            <div v-if="column.dataType === 'euro'">
              <money-amount
                :amount="getValueFromRow(column.dataField)"
                class="text-right"
              />
            </div>
            <div v-else-if="column.dataType === 'percentage'">
              <div class="text-right">
                {{ getValueFromRow(column.dataField) | intToPercentageString }}
              </div>
            </div>
            <div v-else-if="column.dataType === 'numeric'">
              <div class="text-right">
                {{ getValueFromRow(column.dataField) }}
              </div>
            </div>
          </div>

          <div v-else>
            {{ getValueFromRow(column.dataField) }}
          </div>
        </div>
      </div>

    </table-data-cell>
  </table-row>
</template>

<script>
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import ListTableTypeBoolean from "@/components/listTable/includes/types/ListTableTypeBoolean";
import ListTableTypeImage from "@/components/listTable/includes/types/ListTableTypeImage";
import ListTableRowSelect from "@/components/listTable/includes/batch/ListTableRowSelect";
import Tooltip from "@/components/elements/tooltips/Tooltip";
import ListTableButtonShow from "@/components/listTable/includes/buttons/ListTableButtonShow";
import ListTableButtonUnlink from "@/components/listTable/includes/buttons/ListTableButtonUnlink";
import ListTableButtonEdit from "@/components/listTable/includes/buttons/ListTableButtonEdit";
import ListTableButtonDelete from "@/components/listTable/includes/buttons/ListTableButtonDelete";
import newTabMixin from "@/mixins/newTabMixin";
import router from "@/router/router";
import {userHasPermission} from "@/helpers/permissionHelper";
import MoneyAmount from "@/components/global/Money/MoneyAmount";


export default {
  name: "ListTableTableRow",
  components: {
    MoneyAmount,
    Tooltip,
    ListTableRowSelect,
    ListTableTypeImage,
    ListTableTypeBoolean,
    TableDataCell,
    TableRow,
    ListTableButtonShow,
    ListTableButtonEdit,
    ListTableButtonDelete,
    ListTableButtonUnlink
  },
  mixins: [newTabMixin],
  props: {
    /**
     * The table headings
     */
    tableHeadings: {
      type: Array,
      required: true
    },
    /**
     * The row data
     */
    row: {
      type: Object,
      required: true
    },
    /**
     * When enabled, there will be checkboxes to select each line
     */
    selectable: {
      type: Boolean,
      default: false
    },
    /**
     * The key which will be used to trigger batch actions.
     *["id"] or multiple values:
     * ["id", "productId]"
     */
    selectValues: {
      type: [Array, Function],
    },
  },
  methods: {
    /**
     * Returns the value for a given column of the row.
     *
     * @param key
     * @returns {Object|string|*}
     */
    getValueFromRow(key) {
      // Columns is meant to show a selectable checkbox
      if (key === undefined) {
        return "SELECTBOX";
      }

      if (!key.includes('.'))
        return this.row[key];

      // Deeper Values like customer.fullName
      const subKeys = key.split('.');
      let value = this.row;

      subKeys.forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (value.hasOwnProperty(key)) {
          value = value[key];
        } else {
          value = "";
        }
      });

      return value;
    },
    /**
     * Returns the buttons that should be shown in a column.
     *
     * @param column
     * @returns {null|*}
     */
    getColumnButtons(column) {
      if (!column.buttons)
        return null;

      return column
        .buttons
        .filter(b => {
          if (b.condition !== undefined)
            return b.condition(this.row);

          return true;
        });
    },
    /**
     * Executes the delete action for this row.
     *
     * @param deleteAction
     * @param successText
     * @param refresh
     */
    deleteRow(deleteAction, successText, refresh = true) {
      deleteAction(this.row).then(() => {
        this.$sendSuccessToast(successText);
        if (refresh) {
          this.$emit('refreshrequest');
        }
      });
    },
    /**
     * Executed when a button of the table is pressed.
     * @param button
     * @param row
     * @param newTab
     */
    onClickTableButton(button, row, newTab = false) {
      if (button.type === 'delete' || button.type === 'unlink') {
        return this.deleteRow(button.deleteAction, button.successText);
      }

      if (button.route !== undefined) {
        return newTab
          ? this.openRouteInNewTab(button.route(row))
          : this.$router.push(button.route(row));
      }

      return button.onClick(row);
    },

    isButtonDisabled(button, row) {
      const userPermissions = this.$store.getters['authUser/getPermissions'];

      // Check permission if user is allowed to access route
      if (button.route !== undefined) {
        const route = router.resolve(button.route(row));
        const routePermissions = route.resolved?.meta?.permissions || null;
        const routeReadOnlyPermissions = route.resolved?.meta?.readOnly?.permissions || null;

        if (routePermissions || routeReadOnlyPermissions) {
          // Use Read Only Permissions if user wants to click show button
          if (routeReadOnlyPermissions && button.type === 'show') {
            return !userHasPermission(routeReadOnlyPermissions, userPermissions);
          }

          // Use general permissions id user wants to click edit/delete button
          return !userHasPermission(route.resolved.meta.permissions, userPermissions);
        }
      } else if (button.deletePermissions !== undefined) {
        // Permissions array, passed from delete buttons
        return !userHasPermission(button.deletePermissions, userPermissions);
      }

      if (button.disabledIf !== undefined) {
        return button.disabledIf(row);
      }

      return false;
    },

    getAdditionalClasses(column, row) {
      if (typeof column.additionalClasses === "function") {
        return column.additionalClasses(row);
      }
      return '';
    },

    /**
     * Whether the column contents should break into a new line
     * when it gets too long.
     *
     * @param column
     * @returns {boolean}
     */
    canBreakLine(column) {
      if (column.canBreakLine) {
        return true;
      }

      return false;
    },

    /**
     * If the table supports Selection, the row will be selected or unselected
     * @returns {boolean}
     */
    selectOrUnselectRowIfSelectable($event) {
      this.$emit('click', $event);

      if (!this.selectable) {
        return false;
      }

      // Shift key pressed while clicking. Don't mark row as selected,
      // instead pass to parent, that will handle and select/unselect ALL
      // rows between this row and the previously selected row.
      if ($event.shiftKey) {
        return false;
      }

      const value = this.selectValues(this.row);
      if (this.isRowSelected) {
        this.$store.commit('listTable/unselectRow', value);
      } else {
        this.$store.commit('listTable/selectRow', value);
      }
    },
  },
  computed: {
    /**
     * Determines if the row is selected to be used in a batch action.
     *
     * @returns {boolean|*}
     */
    isRowSelected() {
      if (!this.selectable)
        return false;

      const value = this.selectValues(this.row);
      return this.$store.getters['listTable/isRowSelected'](value);
    },

    /**
     * Checks if the row has a batch error.
     *
     * @returns {boolean|*}
     */
    hasRowError() {
      if (!this.selectable)
        return false;

      const value = this.selectValues(this.row);
      return this.$store.getters['listTable/hasRowError'](value);
    },
    /**
     * Returns the batch action error message for this row.
     *
     * @returns {boolean|*|string}
     */
    getErrorMessageForRow() {
      if (!this.selectable)
        return false;

      const value = this.selectValues(this.row);
      const errorObject = this.$store.getters['listTable/getRowError'](value);
      return errorObject?.error || '';
    },
    /**
     * Returns a list of different available buttons to be rendered.
     *
     * @returns {[{component: string, type: string}, {component: string, type: string}, {component: string, type: string}]}
     */
    getPossibleButtons() {
      return [
        {type: 'show', component: 'list-table-button-show'},
        {type: 'edit', component: 'list-table-button-edit'},
        {type: 'delete', component: 'list-table-button-delete'},
        {type: 'unlink', component: 'list-table-button-unlink'},
      ];
    },

    getTableHeadings() {
      return this.tableHeadings;
    }
  }
}
</script>

<style scoped>

</style>