import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'

const fullConfig = resolveConfig(tailwindConfig);

export function getScreenWidth() {
  return window.innerWidth;
}

export function getWidthByTailwindBreakpoint(breakpoint) {
  return parseInt(fullConfig.theme.screens[breakpoint]);
}

export function isScreenSizeBelowBreakpoint(breakpoint) {
  return getScreenWidth() <= getWidthByTailwindBreakpoint(breakpoint);
}