import auth from "../middlewares/authMiddleware";
import ShowMemberHolidays from "@/components/pages/MemberHoliday/ShowMemberHolidays";
import CreateMemberHoliday from "@/components/pages/MemberHoliday/Create/CreateMemberHoliday";
import UpdateMemberHoliday from "@/components/pages/MemberHoliday/Update/UpdateMemberHoliday.vue";

export default [
  {
    path: '/memberHolidays/list',
    name: 'member-holidays.list',
    component: ShowMemberHolidays,
    meta: {
      middleware: [auth],
      title: 'Liste des congés',
      permissions: ['MEMBER_HOLIDAY_READ']
    }
  },
  {
    path: '/memberHolidays/create',
    name: 'member-holidays.create',
    component: CreateMemberHoliday,
    meta: {
      middleware: [auth],
      title: 'Ajouter un congé',
      breadcrumbs: ['member-holidays.list'],
      permissions: ['MEMBER_HOLIDAY_WRITE']
    }
  },
  {
    path: '/memberHolidays/:memberId/update/:holidayId',
    name: 'member-holidays.update',
    component: UpdateMemberHoliday,
    meta: {
      middleware: [auth],
      title: 'Modifier un congé',
      breadcrumbs: ['member-holidays.list'],
      permissions: ['MEMBER_HOLIDAY_WRITE']
    }
  },
];