<template>
  <div class="grid grid-cols-3 of gap-2">
    <touch-optimized-button
      v-for="key in getKeys"
      :key="key.key"
      :class="[key.extraClasses !== undefined ?  key.extraClasses : '']"
      padding="py-4"
      size="text-3xl"
      type="dark"
      @click="key.action()"
    >
      {{ key.key }}
    </touch-optimized-button>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";

export default {
  name: "KeyPad",
  components: {TouchOptimizedButton},
  props: {
    mode: {
      type: String,
      default: 'fulfilment',
      validator: val => ['fulfilment', 'temperature'].includes(val)
    }
  },
  data: () => ({
    lastTarget: {
      type: 'document'
    },
  }),
  computed: {
    getAvailableKeys() {
      return [
        {key: '1', action: () => this.addKey('1'), modes: ['*']},
        {key: '2', action: () => this.addKey('2'), modes: ['*']},
        {key: '3', action: () => this.addKey('3'), modes: ['*']},
        {key: '4', action: () => this.addKey('4'), modes: ['*']},
        {key: '5', action: () => this.addKey('5'), modes: ['*']},
        {key: '6', action: () => this.addKey('6'), modes: ['*']},
        {key: '7', action: () => this.addKey('7'), modes: ['*']},
        {key: '8', action: () => this.addKey('8'), modes: ['*']},
        {key: '9', action: () => this.addKey('9'), modes: ['*']},
        {key: '0', action: () => this.addKey('0'), modes: ['*']},
        {key: '+', action: () => this.addKey('+'), modes: ['fulfilment']},
        {key: ',', action: () => this.addKey(','), modes: ['temperature']},
        {key: '-', action: () => this.addKey('-'), modes: ['temperature']},
        {key: 'C', action: () => this.clearSelection('C'), modes: ['*']},
      ];
    },
    getKeys() {
      let keys = this.getAvailableKeys.filter(key => {
        return key.modes.includes(this.mode) || key.modes.includes('*');
      });

      if (this.mode === 'temperature') {
        const lastKey = keys.pop();
        lastKey.extraClasses = "col-span-3"
        keys.push(lastKey);
      }

      return keys;
    }
  },
  methods: {
    addKey(key) {
      this.triggerKey(key);

      if (!this.lastTargetedInput)
        return false;

      if (this.isTextSelected(this.lastTargetedInput)) {
        this.changeInputValue(key);
      } else {
        this.changeInputValue((this.lastTargetedInput.value + key));
      }
    },
    clearSelection() {
      if (!this.lastTargetedInput)
        return false;

      this.changeInputValue("");
    },
    triggerKey(key) {
      const event = new KeyboardEvent('keypress', {key});
      window.dispatchEvent(event);
    },
    changeInputValue(value) {
      if (!this.lastTargetedInput)
        return false;

      this.lastTargetedInput.value = value;

      // Dispatch an "Input" Event on the Input.
      const event = new Event('input', {bubbles: true});
      this.lastTargetedInput.dispatchEvent(event);
      // Scroll to the very right if the inputText is longer than the Input.
      this.lastTargetedInput.scrollLeft = this.lastTargetedInput.scrollWidth;
    },
    focusChanged(event) {
      // Change lastTargetedInput only for Inputs, otherwise it would also
      // work for buttons etc
      if (event.target.tagName.toUpperCase() === 'INPUT') {
        this.lastTargetedInput = event.target;
      }
    },

    // Check if the whole text in an input is "selected".
    // in this case, instead of adding the char, it should replace it.
    isTextSelected(input) {
      if (typeof input.selectionStart === "number") {
        return input.selectionStart === 0 && input.selectionEnd === input.value.length;
      } else if (typeof document.selection != "undefined") {
        input.focus();
        return document.selection.createRange().text === input.value;
      }
    }
  },
  created() {
    window.addEventListener('focusin', this.focusChanged);
  },
  beforeDestroy() {
    window.removeEventListener('focusin', this.focusChanged);
  },
}
</script>

