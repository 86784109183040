import auth from "@/router/middlewares/authMiddleware";
import ShowBookingAccounts from "@/components/pages/BookingAccounts/ShowBookingAccounts";
import CreateBookingAccount from "@/components/pages/BookingAccounts/CreateBookingAccount/CreateBookingAccount";
import UpdateBookingAccount from "@/components/pages/BookingAccounts/UpdateBookingAccount/UpdateBookingAccount";

export default [
  {
    path: '/booking-accounts',
    name: 'booking-accounts.list',
    component: ShowBookingAccounts,
    meta: {
      middleware: [auth],
      title: 'Comptes',
      permissions: ['BOOKING_ACCOUNTS_READ']
    }
  },
  {
    path: '/booking-accounts/create',
    name: 'booking-accounts.create',
    component: CreateBookingAccount,
    meta: {
      middleware: [auth],
      title: 'Créer compte',
      breadcrumbs: ['booking-accounts.list'],
      permissions: ['BOOKING_ACCOUNTS_WRITE']
    }
  },
  {
    path: '/booking-accounts/:bookingAccount/update',
    name: 'booking-accounts.update',
    component: UpdateBookingAccount,
    meta: {
      middleware: [auth],
      title: 'Modifier compte',
      breadcrumbs: ['booking-accounts.list'],
      permissions: ['BOOKING_ACCOUNTS_WRITE']
    }
  }
];