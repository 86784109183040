import auth from "../middlewares/authMiddleware";
import ShowFees from "../../components/pages/Fees/ShowFees";
import EditFee from "../../components/pages/Fees/EditFee";
import CreateFee from "../../components/pages/Fees/CreateFee";

export default [
  {
    path: '/fees',
    name: 'fees.list',
    component: ShowFees,
    meta: {
      middleware: [auth],
      title: 'Liste des frais',
      permissions: ['FEE_READ']
    }
  },
  {
    path: '/fees/:fee/edit',
    name: 'fees.edit',
    component: EditFee,
    meta: {
      middleware: [auth],
      title: 'Modifier frais',
      readOnly: {supported: true, title: 'Voir frais', permissions: ['FEE_READ']},
      breadcrumbs: ['fees.list'],
      permissions: ['FEE_WRITE']
    }
  },
  {
    path: '/fees/create',
    name: 'fees.create',
    component: CreateFee,
    meta: {
      middleware: [auth],
      title: 'Créer frais',
      breadcrumbs: ['fees.list'],
      permissions: ['FEE_WRITE']
    }
  }
];