<template>
  <div>
    <page-title>
      {{ getFeeName }} #{{ getFeeId }}
    </page-title>
    <card>
      <card-tab id="feeTab" icon="euro-sign" title="Frais">
        <create-or-update-fee-form
          v-if="hasFeeLoaded"
          mode="update"
        />
      </card-tab>

      <card-tab id="localizationTab" icon="globe" title="Traduction">
        <update-fee-localization-form
          v-if="hasFeeLoaded"
        />
      </card-tab>

      <card-tab id="rulesTab" icon="sliders-h" title="Régles">
        <rule-management
          :model-id="getFeeId"
          rule-able-model="Fee"
        />
      </card-tab>
    </card>
  </div>
</template>

<script>
import CreateOrUpdateFeeForm from "./Forms/CreateOrUpdateFeeForm";
import PageTitle from "../../elements/pages/PageTitle";
import Card from "../../elements/cards/Card";
import CardTab from "../../elements/cards/tabs/CardTab";
import UpdateFeeLocalizationForm from "./Forms/UpdateFeeLocalizationForm";
import RuleManagement from "../../forms/rules/sub/RuleManagement";

export default {
  name: "EditFee",
  components: {
    RuleManagement, UpdateFeeLocalizationForm, CardTab, Card, PageTitle, CreateOrUpdateFeeForm
  },
  mounted() {
    this.$store.commit('fees/clearFee');
    this.fetchFee();
  },
  methods: {
    fetchFee() {
      this.$store.dispatch('fees/fetchSingle', parseInt(this.$route.params.fee));
    },
  },
  computed: {
    getFeeId() {
      return parseInt(this.$route.params.fee);
    },
    hasFeeLoaded() {
      return this.$store.getters['fees/hasFeeLoaded'];
    },
    getFeeName() {
      return this.$store.getters['fees/getFeeName'];
    }
  },
}
</script>

