<template>
  <list-table
    :columns="columns"
    :create-route="{
       name: 'departments.create'
    }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="departmentsTable"
  />
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import DepartmentRepository from "@/repositories/DepartmentRepository";

export default {
  name: "ShowDepartments",
  components: {ListTable},
  data() {
    return {
      title: 'Departments',
      repositoryFunc: DepartmentRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          showInColumnChooser: false,
          allowReordering: false,
          buttons: [
            {
              type: 'show',
              hint: 'Voir tâche',
              route: (row) => ({
                name: 'departments.edit',
                params: {
                  department: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier tâche',
              route: (row) => ({
                name: 'departments.edit',
                params: {
                  department: row.id,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer departement',
              confirmation: {
                title: 'Supprimer tâche?',
                text: 'Étes vous sure que vous voulez supprimer cette tâche?'
              },
              successText: 'Le departement a été supprimée',
              deleteAction: (row) => DepartmentRepository.delete(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: 'Nom',
          dataField: 'name',
          sort: {
            dataField: 'name',
            default: 'asc'
          }
        },
        {
          caption: 'Seuil pour picking',
          dataField: 'fulfilmentGroupThreshold',
        },
        {
          caption: 'Seulement produits en stock',
          dataType: "boolean",
          calculateCellValue: (r) => r.isInStock,
        },
      ],
      filters: [
        {
          caption: 'ID(s) ou Nom',
          filterKey: 'idsOrName',
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>