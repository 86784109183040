<template>
  <div>
    <h1 v-if="title" class="mb-3 font-semibold sm:text-lg text-gray-600">
      {{ title }}
    </h1>

    <section class="h-full overflow-auto w-full h-full flex flex-col">
      <header
        :class="[draggingOverContainer ? 'border-luxcaddy-500 bg-luxcaddy-100' : 'border-gray-400 bg-gray-50']"
        class="border-dashed border-2 rounded-lg py-12 flex flex-col justify-center items-center"
        @dragleave="dragLeaveHandler" @dragover="dragOverHandler"
        @drop.prevent="dropHandler" @dragenter.prevent="dragEnterHandler">

        <p class="mb-3 font-medium text-gray-500 flex flex-wrap justify-center select-none">
          <span>Glissez et déposez vos</span> &nbsp; <span>fichiers n'importe où ou</span>
        </p>

        <input ref="hiddenFileInput" class="hidden" multiple type="file" @change="onFileInputChange"/>
        <button-element size="small" type="primary" @click="openFileDialog">
          Choisir fichier(s)
        </button-element>

        <span v-if="maxFileSizePerFile" class="text-xs text-gray-400 mt-3">
                    (Max. {{ getHumanReadablemaxFileSizePerFile }} par fichier)
                </span>
      </header>

      <h1 v-if="files.length" class="mb-3 mt-8 font-medium sm:text-md text-gray-600">
        {{ files.length }} fichier(s) à ajouter
      </h1>
    </section>

    <div
      v-if="files.length"
      class="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5"
    >
      <div
        v-for="file in files"
        :key="file.name"
      >
        <file-upload-image-card
          v-if="isFileImage(file)"
          :available-labels="availableLabels"
          :available-languages="getAvailableLanguagesToSelect"
          :enable-media-localization="enableMediaLocalization"
          :file="file"
          @remove="removeFile($event)"
        />

        <file-upload-file-without-preview-card
          v-else
          :file="file"
          @remove="removeFile($event)"
        />
      </div>
    </div>
  </div>

</template>


<script>
import LanguageRepository from "../../../../../repositories/LanguageRepository";
import ButtonElement from "../../../buttons/ButtonElement";
import FileUploadImageCard from "@/components/elements/forms/elements/base/fileUpload/FileUploadImageCard.vue";
import FileUploadFileWithoutPreviewCard
  from "@/components/elements/forms/elements/base/fileUpload/FileUploadFileWithoutPreviewCard.vue";
import {getHumanReadableSize} from "@/helpers/fileHelper";

export default {
  name: "FileInputElement",
  components: {FileUploadFileWithoutPreviewCard, FileUploadImageCard, ButtonElement},
  props: {
    id: {
      type: [String, null]
    },
    title: {
      required: false,
    },
    value: {
      required: false,
    },
    maxFiles: {
      type: Number,
      default: 24
    },
    /**
     * If media localization is enabled, user must choose one of 4 languages (or default)
     * to indicate in language this file is localized for.
     */
    enableMediaLocalization: {
      type: Boolean,
      default: false
    },
    maxFileSizePerFile: {
      type: Number,
      default: 10 * (1024 * 1024)
    },
    /**
     * If we dont pass a list of available Labels,
     * newly added files will automatically be set to "primary".
     */
    availableLabels: {
      type: Array,
      default: () => (['primary'])
    },
    /**
     * ImageOnly is used for all models that have images with our tag / localization system
     */
    imageOnly: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      draggingOverContainer: false,
    }
  },
  created() {
    if (this.enableMediaLocalization) {
      this.fetchLanguages();
    }
  },
  methods: {
    addFile(file) {
      if (this.files.length >= this.maxFiles)
        return false;

      let fileName = file.name;

      if (!file.type.match("image.*") && this.imageOnly) {
        this.$sendWarningToast(`Fichier ${fileName} n'est pas une image.`);
        return false;
      }

      if (file.size > this.maxFileSizePerFile) {
        this.$sendWarningToast(`Fichier ${fileName} ne peut pas être plus grand que ${getHumanReadableSize(this.maxFileSizePerFile)}`);
        return false;
      }

      let fileObject = {
        objectURL: URL.createObjectURL(file),
        file: file
      };

      if (this.imageOnly) {
        if (this.enableMediaLocalization) {
          fileObject.localization = this.getAvailableLanguagesToSelect[0].value;
        } else {
          fileObject.localization = "default";
        }

        if (this.availableLabels.length === 1) {
          fileObject.label = this.availableLabels[0]?.value || "primary";
        }
      }

      this.files.push(fileObject);
    },

    isFileImage(file) {
      const fileType = file.file['type'];
      return ['image/gif', 'image/jpeg', 'image/png'].includes(fileType);
    },

    openFileDialog() {
      this.$refs.hiddenFileInput.click();
    },

    removeFile(objectURL) {
      this.files = this.files.filter(f => f.objectURL !== objectURL);
    },

    onFileInputChange(e) {
      this.sendNotificationIfMoreThanMaxFiles(e.target.files.length);

      for (const file of e.target.files) {
        this.addFile(file);
      }
    },

    dropHandler(e) {
      this.sendNotificationIfMoreThanMaxFiles(e.dataTransfer.files.length);

      for (const file of e.dataTransfer.files) {
        this.addFile(file);
        this.draggingOverContainer = false;
      }
    },

    sendNotificationIfMoreThanMaxFiles(amountTryingToAdd) {
      if ((amountTryingToAdd + this.files.length) > this.maxFiles) {
        this.$sendWarningToast(`Vous ne pouvez pas télécharger plus de ${this.maxFiles} fichiers à la fois.`);
      }
    },

    hasDraggedFiles({dataTransfer: {types = []}}) {
      return types.indexOf("Files") > -1;
    },

    dragEnterHandler(e) {
      if (!this.hasDraggedFiles(e)) {
        return;
      }
      this.draggingOverContainer = true;
    },

    dragLeaveHandler() {
      this.draggingOverContainer = false;
    },

    dragOverHandler(e) {
      if (this.hasDraggedFiles(e)) {
        e.preventDefault();
      }
    },

    fetchLanguages() {
      LanguageRepository
        .get()
        .then((res) => {
          this.availableLanguages = res.data.data;
        });
    },
  },

  computed: {
    files: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },

    getAvailableLanguagesToSelect() {
      if (!this.enableMediaLocalization)
        return undefined;

      if (!this.availableLanguages)
        return undefined;

      let languages = this
        .availableLanguages
        .map(c => {
          return {
            value: c.initials,
            label: c.name,
          };
        });

      languages.unshift({value: 'default', label: 'Défaut'});

      return languages;
    },

    getHumanReadablemaxFileSizePerFile() {
      return getHumanReadableSize(this.maxFileSizePerFile);
    }
  }
}
</script>

