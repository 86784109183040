<template>
  <div>
    <div v-if="loading" class="flex justify-center">
      <loading-svg-inline class="w-24 h-24"/>
    </div>

    <template v-else-if="product.id !== null">
      <div class="px-2 text-lg sm:text-xl font-semibold flex gap-x-2 flex-wrap">
        <div>
          {{ product.name }}
          <loading-svg-inline v-if="subLoading"/>
        </div>
      </div>

      <div class="px-2 pb-1.5 | flex items-center space-x-2">
        <div class="flex-shrink-0 | w-14 h-14 sm:w-24 sm:h-24 flex-none">
          <img :alt="product.name" :src="getProductImageUrl" class="w-full h-full object-contain">
        </div>
        <div class="flex flex-col">

          <div class="text-sm sm:text-lg">
            {{ getSubTitleString }}<br>
            Stock sur étagère: <span class="font-bold">{{ initialOnShelfStock }}</span>
          </div>
        </div>
      </div>

      <div
        class="w-full bg-gray-300 text-gray-800 font-bold text-sm px-2 py-1 border-b border-t border-gray-400">
        Stock
      </div>

      <div class="px-2 py-1 space-x-0.5 flex overflow-hidden">
        <number-input-element
          v-model="product.onShelfStock"
          :occupy-validation-space="false"
          additional-input-classes="text-xl"
          class="w-1/3 flex-none"
          label="St. sur étagère"
          select-on-focus
        />

        <button-element
          v-for="reason in getStockModificationOptions"
          :key="reason.label"
          :type="reason.buttonType"
          class="flex-shrink-0 flex-grow-0 justify-end self-end"
          size="small"
          @click="saveModifiedStock(reason.value)"
        >
          <span class="text-xs">
            {{ reason.label }}
          </span>
        </button-element>
      </div>

      <div class="px-2">
        <number-input-element
          v-model="rack"
          :occupy-validation-space="false"
          additional-input-classes="text-xl"
          label="Emplacement st."
          select-on-focus
          @blur="saveNewRack"
          @enter="saveNewRack"
        />
      </div>

      <div
        class="mt-2 w-full bg-gray-300 text-gray-800 font-bold text-sm px-2 py-1 border-b border-t border-gray-400">
        Promotion
      </div>

      <div class="px-2 pt-2 py-1 flex flex-col">
        <template v-if="hasCampaign">
          <div>
            Type: <span class="font-bold">{{ campaign.name }}</span>
          </div>
          <div>
            Nbr. initial: <span class="font-bold">{{ campaign.initialStock ?? '∞' }}</span> &middot;
            Restant: <span class="font-bold">{{ campaign.availableStock }}</span>
          </div>

          <touch-optimized-button
            :loading="buttonsLoading.endCampaign"
            class="mt-1"
            type="dark"
            @click="endCampaign"
          >
            Terminer promotion
          </touch-optimized-button>
        </template>
        <template v-else>
          <div
            class="flex items-center space-x-1.5 -mt-1.5"
          >
            <number-input-element
              v-model="newDiscount.reservedStock"
              :occupy-validation-space="false"
              class="flex-1"
              placeholder="Nbr."
            />
            <select-element
              v-model="newDiscount.value"
              :enable-search="false"
              :occupy-validation-space="false"
              :options="getDiscountPercentageOptions"
              nothing-selected-text="%"
            />

            <div class="flex space-x-1.5 items-center">
              <div class="text-gray-500 text-sm sm:text-md">
                Courte
              </div>

              <checkbox-element
                v-model="newDiscount.isShortDate"
                :occupy-validation-space="false"
                hide-checked-label
                size="big"
              />
            </div>
          </div>

          <touch-optimized-button
            :loading="buttonsLoading.createDiscount"
            class="mt-3"
            padding="py-2"
            size="small"
            type="primary"
            @click="createDiscount"
          >
            Mettre en promo
          </touch-optimized-button>
        </template>
      </div>


      <div
        class="mt-2 w-full bg-gray-300 text-gray-800 font-bold text-sm px-2 py-1 border-b border-t border-gray-400">
        Imprimer étiquette
      </div>
      <div class="px-2 sm:px-4 py-2">
        <div class="flex space-x-2 flex-wrap">
          <touch-optimized-button
            :loading="buttonsLoading.printNormal"
            class="flex-1"
            type="dark"
            @click="printLabel('medium')"
          >
            Normal
          </touch-optimized-button>
          <touch-optimized-button
            :loading="buttonsLoading.printSmall"
            class="flex-1"
            type="dark"
            @click="printLabel('small')"
          >
            Petit
          </touch-optimized-button>
        </div>
      </div>

      <div
        class="w-full bg-gray-300 text-gray-800 font-bold text-sm px-2 py-1 border-b border-t border-gray-400">
        Picking Produit
      </div>

      <div class="px-2 py-2 grid grid-cols-2 gap-2">
        <number-input-element
          v-model="product.pickingWeight"
          :occupy-validation-space="false"
          additional-input-classes="text-3xl"
          label="Poids (g)"
          touch-optimized
          @blur="saveWeightAndVolume"
          @enter="saveWeightAndVolume"
        />

        <number-input-element
          v-model="product.pickingVolume"
          :occupy-validation-space="false"
          additional-input-classes="text-3xl"
          label="Volume (cm³)"
          touch-optimized
          @blur="saveWeightAndVolume"
          @enter="saveWeightAndVolume"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ProductRepository from "@/repositories/ProductRepository";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline";
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import CampaignRepository from "@/repositories/CampaignRepository";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "ScanEditManagerProductInterface",
  components: {
    ButtonElement,
    SelectElement, CheckboxElement, NumberInputElement, TouchOptimizedButton, LoadingSvgInline
  },
  props: {
    scannedBarcode: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    subLoading: false,

    product: {
      id: null,
      name: null,
      onShelfStock: null,
      sellingData: null,
      pickingWeight: null,
      pickingVolume: null,
    },
    rack: null,
    campaign: [],
    images: [],

    initialOnShelfStock: null,
    initialRack: null,
    initialWeight: null,
    initialVolume: null,

    newDiscount: {
      reservedStock: null,
      value: null,
      isShortDate: false,
    },
    discountPercentageOptions: [5, 10, 15, 20, 25, 30, 50],

    buttonsLoading: {
      endCampaign: false,
      createDiscount: false,
      printNormal: false,
      printSmall: false
    },
  }),
  created() {
    this.fetchData();
  },
  computed: {
    getProductImageUrl() {
      return getPrimaryImageUrlFromImageObject(this.images);
    },
    getSellingData() {
      return this.product.sellingData;
    },
    getSubTitleString() {
      if (this.getSellingData === null) {
        return "";
      }
      const sd = this.getSellingData;

      return `ID: ${this.product.id} - ${sd.bundle.quantity} x ${sd.bundle.content}${sd.bundle.unit}`;
    },
    getStockToModifyValue() {
      // Returns the value the stock needs to be modified by.
      if (this.initialOnShelfStock === this.product.onShelfStock)
        return 0;

      if (this.product.onShelfStock < this.initialOnShelfStock) {
        return 0 - (this.initialOnShelfStock - this.product.onShelfStock);
      }

      return this.product.onShelfStock - this.initialOnShelfStock;
    },
    hasCampaign() {
      return !Array.isArray(this.campaign);
    },
    getDiscountPercentageOptions() {
      return this.discountPercentageOptions.map(d => {
        return {
          label: d + '%',
          value: d * 100
        }
      })
    },
    getStockModificationOptions() {
      return this.$luxcaddyConfig('product.stock.stockModificationReasonsScanInterface');
    },
    hasModifiedCurrentStock() {
      return this.initialOnShelfStock !== this.product.onShelfStock;
    }
  },
  methods: {
    fetchData() {
      this.loading = true;

      return ProductRepository
        .getDataByBarcode(this.scannedBarcode)
        .then((res) => {
          const data = res.data.data;

          this.product.id = data.id;
          this.product.name = data.name;
          this.product.onShelfStock = data.onShelfStock;
          this.$set(this.product, 'sellingData', data.sellingData);
          this.$set(this, 'campaign', data.campaign);
          this.campaign = data.campaign;
          this.images = data.image;
          this.rack = data.rack;
          this.product.pickingWeight = data.pickingWeight;
          this.product.pickingVolume = data.pickingVolume;

          this.initialOnShelfStock = data.onShelfStock;
          this.initialRack = data.rack;
          this.initialWeight = data.pickingWeight;
          this.initialVolume = data.pickingVolume;
        })
        .catch(() => this.$emit('close'))
        .finally(() => this.loading = false);
    },
    saveModifiedStock(reason) {
      if (!this.hasModifiedCurrentStock) {
        return this.$showErrorDialog('La quantité en stock n\'a pas été modifiée.');
      }

      if (!reason) {
        return this.$showErrorDialog('Veuillez choisir une raison!');
      }

      this.subLoading = true;
      ProductRepository
        .stock
        .modifyStock(
          this.product.id,
          {
            quantity: this.getStockToModifyValue,
            comment: reason
          }
        ).then(() => {
        this.$sendSuccessToast('Stock Modifiée!');
        this.initialOnShelfStock = this.product.onShelfStock;
      }).finally(() => this.subLoading = false);
    },
    saveNewRack() {
      if (this.rack === this.initialRack)
        return false;

      this.subLoading = true;
      ProductRepository
        .stock
        .updateRack(this.product.id, this.rack)
        .then(() => {
          this.$sendSuccessToast('Emplacement Modifiée!');
          this.initialRack = this.rack;
        })
        .catch(() => this.fetchData())
        .finally(() => this.subLoading = false);
    },

    saveWeightAndVolume() {
      if (
        this.product.pickingWeight === this.initialWeight &&
        this.product.pickingVolume === this.initialVolume
      ) {
        return false;
      }

      this.subLoading = true;
      ProductRepository.updateProductWeightVolumeByBarcode(
        this.scannedBarcode,
        this.product.pickingWeight,
        this.product.pickingVolume
      )
        .then(() => {
          this.$sendSuccessToast('Modification sauvegardé!');
          this.initialWeight = this.product.pickingWeight;
          this.initialVolume = this.product.pickingVolume;
        })
        .catch(() => this.fetchData())
        .finally(() => this.subLoading = false);
    },

    endCampaign() {
      if (!this.hasCampaign)
        return false;

      this.subLoading = true;
      this.buttonsLoading.endCampaign = true;

      CampaignRepository
        .endCampaign(this.campaign.id)
        .then(() => {
          this.campaign = null;
          this.$sendSuccessToast('Promotion termineé!');

          // We are doing a full reload here since another campaign could become active
          this.fetchData().then(() => {
            this.subLoading = false;
            this.buttonsLoading.endCampaign = false;
          });
        }).catch(() => {
        this.subLoading = false;
        this.buttonsLoading.endCampaign = false;
      });
    },
    createDiscount() {
      if (this.hasCampaign)
        return false;

      this.subLoading = true;
      this.buttonsLoading.createDiscount = true;

      ProductRepository.createDiscount(
        this.product.id,
        this.newDiscount.value,
        this.newDiscount.reservedStock,
        this.newDiscount.isShortDate,
      ).then(() => {
        this.campaign = null;
        this.$sendSuccessToast('Promotion crée!');

        // We are doing a full reload here since another campaign could become active
        this.fetchData();
      }).finally(() => {
        this.subLoading = false;
        this.buttonsLoading.createDiscount = false;
      });
    },
    printLabel(labelType) {
      if (!["small", "medium"].includes(labelType))
        return false;

      if (this.buttonsLoading.printSmall || this.buttonsLoading.printNormal)
        return false;

      if (labelType === "small") {
        this.buttonsLoading.printSmall = true;
      } else {
        this.buttonsLoading.printNormal = true;
      }

      ProductRepository
        .printShelfLabel(this.product.id, labelType)
        .then(() => {
          this.$sendSuccessToast('Envoyé à l\'imprimante.');
        }).finally(() => {
        this.buttonsLoading.printSmall = false;
        this.buttonsLoading.printNormal = false;
      })
    }
  },
}
</script>

<style scoped>

</style>