import ShowMembers from "../../components/pages/Members/ShowMembers";
import CreateMember from "../../components/pages/Members/CreateMember/CreateMember";
import EditMember from "../../components/pages/Members/EditMember/EditMember";
import auth from "../middlewares/authMiddleware";
import MemberSendNotificationPage from "@/components/pages/Members/SendNotification/MemberSendNotificationPage.vue";

export default [
  {
    path: '/members',
    name: 'members.list',
    component: ShowMembers,
    meta: {
      middleware: [auth],
      title: 'Liste des membres',
      permissions: ['ACCESS_MEMBER_VIEW']
    }
  },
  {
    path: '/send-member-notification',
    name: 'members.notifications.send',
    component: MemberSendNotificationPage,
    meta: {
      middleware: [auth],
      title: 'Envoyer un message',
      permissions: ['SEND_NOTIFICATIONS']
    }
  },
  {
    path: '/members/create',
    name: 'members.create',
    component: CreateMember,
    meta: {
      middleware: [auth],
      title: 'Créer un membre',
      breadcrumbs: ['members.list'],
      permissions: ['MEMBER_WRITE']
    }
  },
  {
    path: '/members/:member/edit',
    name: 'members.edit',
    component: EditMember,
    meta: {
      middleware: [auth],
      title: 'Modifier un membre',
      readOnly: {supported: true, title: 'Voir un membre', permissions: ['MEMBER_READ']},
      breadcrumbs: ['members.list'],
      permissions: ['MEMBER_WRITE']
    }
  }
];