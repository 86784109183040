import ApiService from './Base/BaseRepository';
import apiService from './Base/BaseRepository';

const url = '/blog';

export default {
  posts: {
    /**
     * Fetches all blog posts.
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
      return await ApiService
        .setSorts(sorts)
        .setPagination(perPage, page, withTotals)
        .setFilters(filters)
        .get(`${url}/posts`);
    },

    async getSingle(blogPostId) {
      return await ApiService
        .get(`${url}/posts/${blogPostId}`);
    },

    async createPost(payload) {
      return await ApiService
        .post(`${url}/posts`, payload);
    },

    async updatePost(blogPostId, payload) {
      return await ApiService
        .patch(`${url}/posts/${blogPostId}`, payload);
    },

    async deletePost(blogPostId) {
      return await ApiService
        .delete(`${url}/posts/${blogPostId}`);
    },

    async getAllBlogPostsByCategoryId(categoryId) {
      return await apiService
        .get(`/categories/${categoryId}/blogposts`);
    },

    async search(term) {
      return await apiService
        .setPagination(999)
        .setFilters([
          {
            filterKey: 'title',
            filterValue: term
          }
        ])
        .get(`${url}/posts`);
    },

    localization: {
      async getForBlogPost(blogPostId) {
        return await apiService.get(`${url}/posts/${blogPostId}/localization`);
      },

      async saveForBlogPost(blogPostId, payload) {
        return await apiService.post(`${url}/posts/${blogPostId}/localization`, payload);
      },

      async translate(blogPostId, sourceLanguage, targetLanguages, attributes) {
        return await apiService.post(`${url}/posts/${blogPostId}/localization/translation`, {
          sourceLanguage, targetLanguages, attributes
        });
      }
    }
  }
}
