<template>
  <div class="mt-6">
    <custom-table
      :headings="tableHeadings"
      :show-loading="showLoadingIndicator"
    >
      <template>
        <template v-if="isInOrderContext">

          <template v-for="(product, i) in getProducts">
            <order-table-product-row
              :key="product.id + '_' + i + '_product'"
              :is-editable="isEditable"
              :product="product"
            />

            <template v-if="product.reference && product.reference.length">
              <order-table-product-row
                v-for="(reference, x) in product.reference"
                :key="product.id + '_' + i + '_ref_' + reference.id + '_' + x"
                :is-editable="isEditable"
                :product="reference"
                :referenced-by-id="product.id"
                class="border-l- border-opacity-80"
                is-reference
              />
            </template>
          </template>

        </template>

        <template v-else-if="isInCartContext">

          <template v-for="(product, i) in getProducts">
            <cart-table-product-row
              :key="product.id + '_' + i + '_product'"
              :product="product"
            />

            <template v-if="product.reference && product.reference.length">
              <cart-table-product-row
                v-for="(reference, x) in product.reference"
                :key="product.id + '_' + i + '_ref_' + reference.id + '_' + x"
                :product="reference"
                class="border-l-4 border-opacity-80"
                is-reference
              />
            </template>
          </template>

          <table-row v-if="!hasProducts">
            <table-data-cell colspan="8">
              <div class="flex">
                <div class="flex-1 text-center py-8">
                                <span class="font-bolder text-2xl">
                                    Le client n'a aucun produit dans son panier.
                                </span>
                </div>
              </div>
            </table-data-cell>
          </table-row>

        </template>

        <template v-if="hasProducts">
          <table-row v-for="vatDetail in getVatDetails" :key="'vatDetail-' + vatDetail.vat" class="divide-x">
            <table-data-cell :colspan="isInCartContext ? 5 : 7" class="text-right">
              TVA {{ vatDetail.vat | intToPercentageString }}
            </table-data-cell>

            <table-data-cell align="right">
              <money-amount
                :amount="vatDetail.amount"
              />
            </table-data-cell>

            <table-data-cell>
              Base
            </table-data-cell>

            <table-data-cell align="right">
              <money-amount
                :amount="vatDetail.amountNoVat"
              />
            </table-data-cell>
          </table-row>

          <table-row class="divide-x">
            <table-data-cell :colspan="isInCartContext ? 5 : 7" class="text-right">
              Total TTC
            </table-data-cell>

            <table-data-cell align="right">
              <money-amount
                :amount="getTotal.amount"
              />
            </table-data-cell>

            <table-data-cell>
              Total Net.
            </table-data-cell>

            <table-data-cell align="right">
              <money-amount
                :amount="getTotal.amountNoVat"
              />
            </table-data-cell>
          </table-row>

          <table-row class="divide-x">
            <table-data-cell :colspan="isInCartContext ? 5 : 7" class="text-right">
                            <span class="font-bold">
                                Total à payer
                            </span>
            </table-data-cell>

            <table-data-cell align="right">
              <div class="font-bold">
                <money-amount
                  :amount="getTotal.amount"
                />
              </div>
            </table-data-cell>

            <table-data-cell colspan="2"/>
          </table-row>
        </template>
      </template>
    </custom-table>
  </div>
</template>

<script>
import CustomTable from "../../../../elements/tables/CustomTable";
import TableRow from "../../../../elements/tables/TableRow";
import TableDataCell from "../../../../elements/tables/TableDataCell";
import OrderTableProductRow from "./OrderTableProductRow";
import CartTableProductRow from "./CartTableProductRow";
import MoneyAmount from "@/components/global/Money/MoneyAmount";

export default {
  name: "ProductsInCartOrOrderTable",
  components: {
    MoneyAmount,
    CartTableProductRow,
    OrderTableProductRow, TableDataCell, TableRow, CustomTable
  },
  props: {
    /* Only Set for cart */
    memberId: {
      type: Number,
      required: false,
    },
    /* Only Set for order */
    orderId: {
      type: Number,
      required: false
    },
    /* Whether the View should be in Cart or Order context */
    context: {
      type: String,
      validator: val => ['cart', 'order'].includes(val)
    }
  },
  data: () => ({
    selectedItemIdToDelete: null,
  }),
  computed: {
    isInCartContext() {
      return this.context === 'cart';
    },

    isInOrderContext() {
      return this.context === 'order';
    },

    isEditable() {
      return this.isInCartContext
        ? true
        : this.$store.getters['order/isOrderEditable'];
    },

    getProducts() {
      return this.isInCartContext
        ? this.$store.getters['cart/getCartItems']
        : this.$store.getters['order/getOrderItems'];
    },
    getTotal() {
      return this.isInCartContext
        ? this.$store.getters['cart/getTotal']
        : this.$store.getters['order/getTotal'];
    },
    getVatDetails() {
      return this.isInCartContext
        ? this.$store.getters['cart/getVatDetails']
        : this.$store.getters['order/getVatDetails'];
    },
    showLoadingIndicator() {
      return this.$store.getters['loading/showLoadingIndicator'];
    },
    hasProducts() {
      return this.getProducts.length > 0;
    },
    tableHeadings() {

      if (this.isInCartContext) {
        return [
          {label: '#'},
          {label: 'Type'},
          {label: 'Nom'},
          {label: 'Quantité', align: 'center'},
          {label: 'Prix TTC', align: 'right'},
          {label: 'TVA', align: 'center'},
          {label: 'Temps livr.', align: 'center'},
          {label: 'Ajouté à'}, {label: ''},
        ];
      } else if (this.isInOrderContext) {
        return [
          {label: '#'},
          {label: 'Type'},
          {label: 'Nom'},
          {label: 'Qté'},
          {label: 'Qté à emballer'},
          {label: 'Qté emballée'},
          {label: 'Poids emb.'},
          {label: 'Prix par ut', align: 'right'},
          {label: 'TVA', align: 'center'},
          {label: 'Suppl.', align: 'right'},
          {label: 'P. par ut TTC', align: 'right'},
          {label: 'P. TTC', align: 'right'},
          {label: ''},
        ];
      }

      return [];
    }
  }
}
</script>

