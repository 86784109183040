<template>
  <div>
    <page-title>Comptes bancaires</page-title>

    <card>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <accounting-bank-account-item
          v-for="account in bankAccounts"
          :key="account.account"
          :account="account"
        />
      </div>
    </card>
  </div>
</template>

<script>
import AccountingBankMovementRepository from "@/repositories/AccountingBankMovementRepository";
import AccountingBankAccountItem
  from "@/components/pages/Accounting/BankMovements/Accounts/Sub/AccountingBankAccountItem";
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";

export default {
  name: "ShowBankAccounts",
  components: {Card, PageTitle, AccountingBankAccountItem},
  created() {
    this.fetchBankAccounts();
  },
  data: () => ({
    bankAccounts: []
  }),
  methods: {
    fetchBankAccounts() {
      AccountingBankMovementRepository
        .getBankAccounts()
        .then(res => {
          this.bankAccounts = res.data.data;
        })
    }
  },
}
</script>

<style scoped>

</style>