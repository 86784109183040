<template>
  <div v-if="value !== null">
    <img :src="value" alt="Image" class="w-16 h-16 object-contain">
  </div>
</template>

<script>
export default {
  name: "ListTableTypeImage",
  props: {
    value: {
      type: String,
      required: false
    }
  }
}
</script>

