import apiService from './Base/BaseRepository';

const url = '/fees';

export default {
  /**
   * Fetches all fees.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get(withLoadingIndicator = true) {
    return await apiService.get(`${url}/`, {
      showLoadingIndicator: withLoadingIndicator
    });
  },

  /**
   * Fetches all fees.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  /**
   * Fetches a single fee
   *
   * @returns {Promise<AxiosResponse<any>>}
   * @param feeId
   * @param includes
   */
  async getSingle(feeId) {
    return await apiService
      .get(`${url}/${feeId}`);
  },


  /**
   * Creates a new fee.
   *
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  /**
   * Updates a given fee.
   *
   * @param feeId
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async update(feeId, payload) {
    return await apiService.patch(`${url}/${feeId}`, payload);
  },


  localization: {
    async getForFee(feeId) {
      return await apiService.get(`${url}/${feeId}/localization`);
    },

    async saveForFee(feeId, payload) {
      return await apiService.post(`${url}/${feeId}/localization`, payload);
    },

    async translate(feeId, sourceLanguage, targetLanguages, attributes) {
      return await apiService.post(`${url}/${feeId}/localization/translation`, {
        sourceLanguage, targetLanguages, attributes
      });
    }
  },

  rules: {
    async createRule(feeId, payload) {
      return await apiService.post(`${url}/${feeId}/rules`, payload);
    },
    async updateRule(feeId, ruleContext, payload) {
      return await apiService.patch(`${url}/${feeId}/rules/${ruleContext}`, payload);
    },
    async deleteRule(feeId, ruleContext) {
      return await apiService.delete(`${url}/${feeId}/rules/${ruleContext}`);
    },
  }
}
