<template>
  <div v-if="getSellingBundle" class="flex space-x-1 items-center">
    <fa-icon
      v-if="showIcon"
      :class="iconSizeClass"
      class="text-luxcaddy mr-1.5"
      fixed-width
      icon="weight"
    />

    <div :class="textColorClass"
         class="flex items-center space-x-1 tracking-tighter"
    >
      <span v-if="isContentVariable" class="text-xs font-light">+/-</span>
      <span :class="textSizeClass"
            class="font-medium font-mono tracking-tighter flex space-x-1.5 divide-x divide-current"
      >
        <span>{{ getUnitString }}</span>
        <span v-if="getNetWeight && showNet" class="flex space-x-1 pl-1.5">
          <span>℮</span>
          <span>{{ getNetWeight }}</span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductInfoContents",
  props: {
    sellingBundle: {
      type: Object,
      required: true
    },
    textSizeClass: {
      type: String,
      default: 'text-xs'
    },
    iconSizeClass: {
      type: String,
      default: 'text-md'
    },
    textColorClass: {
      type: String,
      default: 'text-gray-500 dark:text-gray-300'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showNet: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getSellingBundle() {
      return this.sellingBundle;
    },
    isContentVariable() {
      return this.getSellingBundle.isVariable || false;
    },
    getUnitString() {
      if (!this.getSellingBundle)
        return null;

      return (this.getSellingBundle.quantity > 1 ? this.getSellingBundle.quantity + 'x' : '') + this.getUnitStringMethod(this.getSellingBundle.content, this.getSellingBundle.unit);
    },
    getNetWeight() {
      if (!this.getSellingBundle.netContent)
        return null;

      return this.getUnitStringMethod(this.getSellingBundle.netContent, this.getSellingBundle.unit);
    },
  },
  methods: {
    getUnitStringMethod(content, unit) {
      if (content >= 1000 && unit !== 'ut') {
        // Wrapping with parseFloat so that .00 is removed while other values stay toFixed(2)
        content = parseFloat((content / 1000).toFixed(2));

        switch (unit) {
          case "g":
            unit = "kg";
            break;
          case "ml":
            unit = "l";
            break;
        }
      }

      return `${content}${unit}`;
    }
  },
}
</script>

<style scoped>

</style>
