<template>
  <div>
    <page-title>Créer campagne</page-title>
    <card>
      <create-or-update-campaign-form mode="create"></create-or-update-campaign-form>
    </card>
  </div>
</template>

<script>
import PageTitle from "../../elements/pages/PageTitle";
import Card from "../../elements/cards/Card";
import CreateOrUpdateCampaignForm from "./Forms/CreateOrUpdateCampaignForm";

export default {
  name: "CreateCampaign",
  components: {CreateOrUpdateCampaignForm, Card, PageTitle}
}
</script>

