<template>
  <form-wrapper
    v-if="shouldRenderForm"
    ref="form"
    :show-changed="isUpdateMode"
    :submit-button-label="isUpdateMode ? 'Modifier' : 'Créer'"
    class="space-y-2"
    @submit="createOrUpdate"
  >
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-8">
      <select-element
        v-model="type"
        :allow-unselecting="false"
        :enable-search="false"
        :options="[{label: 'Recette', value: 'Recipe'}, {label: 'Article Blog', value: 'BlogPost'}, {label: 'Faq', value: 'Faq'}]"
        label="Type"
      />

      <div class="flex items-center gap-x-2">
        <date-picker-element
          v-model="publishedAt"
          class="flex-1"
          label="Date de Publication"
          with-time
        />

        <button-element
          class="mt-1"
          size="small"
          type="dark"
          @click="publishedAt = null"
        >
          <fa-icon class="mr-1" fixed-width icon="clock"/>
          Ne pas publier
        </button-element>
      </div>

      <checkbox-element
        v-model="isListed"
        label="Afficher dans la liste"
      />
        <select-element
                v-if="isFaq"
                v-model="identifier"
                :options="getIdentifierList"
                label="Identifiant infoBox"
        />
    </div>
    <div class="grid grid-cols-1">
      <text-input-element
        v-model="title"
        label="Titre"
        validation-rules="required"
      />
    </div>
    <div class="grid grid-cols-1">
      <text-input-element
        v-model="subtitle"
        label="Sous-Titre"
        validation-rules="required"
      />
    </div>

    <div class="grid grid-cols-1">
      <tiny-mce-editor-element
        v-model="text"
        :merge-custom-config="getTinyImages"
        label="Article"
        validation-rules="required"
      />
    </div>
  </form-wrapper>
</template>

<script>
import BlogRepository from "@/repositories/BlogRepository";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import TinyMceEditorElement from "@/components/elements/forms/elements/base/TinyMceEditorElement";
import MediaRepository from "@/repositories/MediaRepository";
import {getImageFromImageObject} from "@/helpers/imageHelper";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CreateOrUpdateBlogPost",
  components: {
    SelectElement,
    TinyMceEditorElement,
    TextInputElement,
    CheckboxElement,
    ButtonElement,
    DatePickerElement,
    FormWrapper
  },
  data: () => ({
    title: null,
    subtitle: null,
    text: null,
    publishedAt: null,
    type: "Recipe",
    identifier: null,
    isListed: true,

    availableImages: [],
    blogPostLoaded: false,
  }),
  watch: {
    title: function (val) {
      this.$emit('updateTitle', val);
    }
  },
  created() {
    if (this.isUpdateMode) {
      this.blogPostLoaded = false;

      return Promise.all([
        this.fetchBlogPost(),
        this.fetchMedia()
      ]).then(() => {
        this.blogPostLoaded = true;
      })
    } else {
      this.publishedAt = this.$date().format('YYYY-MM-DD HH:mm');
    }
  },
  props: {
    blogPostId: {
      type: Number
    },
    mode: {
      type: String,
      validator: val => ["create", "update"].includes(val)
    }
  },
  methods: {
    createPost() {
      return BlogRepository.posts.createPost(this.getPayload).then(res => {
        this.$sendSuccessToast("Article / Recette crée!");
        this.$router.push({
          name: 'blog.posts.update',
          params: {
            blogPostId: res.data.data.id,
          }
        })
      });
    },
    updatePost() {
      return BlogRepository.posts.updatePost(this.blogPostId, this.getPayload).then(() => {
        this.$sendSuccessToast("Article / Recette modifiée!");
      });
    },
    createOrUpdate(callback) {
      if (this.isUpdateMode) {
        this.updatePost().finally(() => callback());
      } else {
        this.createPost().finally(() => callback());
      }
    },
    fetchBlogPost() {
      return BlogRepository.posts.getSingle(this.blogPostId)
        .then((res) => {
          const data = res.data.data;

          this.title = data.title;
          this.subtitle = data.subtitle;
          this.type = data.type;
          this.text = data.text;
          this.isListed = data.isListed;
          this.publishedAt = data.publishedAt;
          this.identifier = data.identifier;
        })
    },
    fetchMedia() {
      return MediaRepository
        .getMedia('blogPost', this.blogPostId)
        .then(res => {
          this.availableImages = res.data.data;
        })
    }
  },
  computed: {
    isFaq() {
        return this.type === 'Faq'
    },
    getIdentifierList() {
        return this.$luxcaddyConfig('blogPost.identifier.available');
    },
    isUpdateMode() {
      return this.mode === "update";
    },
    shouldRenderForm() {
      if (this.isUpdateMode) {
        return this.blogPostLoaded;
      }
      return true;
    },
    getPayload() {
      return {
        title: this.title,
        subtitle: this.subtitle,
        text: this.text,
        type: this.type,
        isListed: this.isListed,
        identifier: this.identifier,
        publishedAt: this.publishedAt === null
          ? null
          : this.publishedAt,
      };
    },
    getFormattedImages() {

      let images = [];
      this.$luxcaddyConfig('blogPost.images.availableLabels').forEach(c => {
        let image = getImageFromImageObject(this.availableImages, c.value, 'medium', false);
        if (image) {
          images.push(image);
        }
      });


      return images;
    },
    getTinyImages() {
      if (!this.isUpdateMode)
        return null;

      const formatted = this.getFormattedImages.map(imageUrl => {
        return {
          title: imageUrl.substring(imageUrl.lastIndexOf('/') + 1),
          url: imageUrl
        };
      })

      return {
        image_list: formatted
      }
    }
  },
}
</script>

<style scoped>

</style>