<template>
  <div class="group bg-gray-100 rounded-md p-4">

    <div class="flex justify-between items-start ">
      <article
        class="break-all w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 italic font-semibold"
        tabindex="0"
      >
        {{ file.file.name }}
      </article>

      <div
        class="cursor-pointer hover:text-white ml-auto focus:outline-none hover:bg-red-500 p-1 rounded-md"
        @click="removeFile(file)">
        <fa-icon fixed-width icon="trash"></fa-icon>
      </div>
    </div>


    <p class="p-0.5 text-sm">{{ getHumanReadableSize(file.file.size) }}</p>

  </div>
</template>

<script>
import {getHumanReadableSize} from "@/helpers/fileHelper";

export default {
  name: "FileUploadFileWithoutPreviewCard",
  props: {
    file: {
      type: Object,
      required: true
    },
  },
  methods: {
    getHumanReadableSize,
    removeFile() {
      this.$emit('remove', this.file.objectURL);
    },
  },
}
</script>

<style scoped>

</style>