<template>
  <table-row
    :class="{
            'text-red-500': hasNegativeAmount,
            'bg-gray-50': isSubItem
        }"
  >
    <template v-if="isInvoiceInternal">
      <table-data-cell class="w-40">
        <div class="flex items-center gap-2">
          <div
            v-if="isSubItem"
            class="w-4 h-4"
          >
            <div
              class="text-gray-500"
            >
              ↪
            </div>
          </div>
          {{ item.reference }}
        </div>
      </table-data-cell>
      <table-data-cell>
        <div class="font-semibold">
          {{ item.name }}
        </div>

        <div
          v-if="item.description"
          class="mt-0.5 italic text-xs"
        >
          {{ item.description }}
        </div>
      </table-data-cell>
    </template>
    <table-data-cell
      v-if="isAccountingInterface"
      class="whitespace-nowrap"
    >
      {{ item.bookingAccountNumber }}
      <span v-if="isAccountingInterface">
                {{ item.bookingAccountName }}
            </span>
    </table-data-cell>
    <table-data-cell
      v-if="isAccountingInterface"
      class="w-56"
    >
      {{ item.vatCode }}
    </table-data-cell>
    <table-data-cell class="w-24">
      {{ item.quantity }}
    </table-data-cell>
    <table-data-cell class="w-30">
      <money-amount
        :amount="item.price"
        :currency="isInvoiceCurrencyEUR ? 'EUR' : 'USD'"
      />
    </table-data-cell>
    <table-data-cell class="w-30">
      <money-amount
        :amount="item.subTotal"
        :currency="isInvoiceCurrencyEUR ? 'EUR' : 'USD'"
      />
    </table-data-cell>
    <table-data-cell class="w-30">
      <money-amount
        :amount="item.totalVat"
        :currency="isInvoiceCurrencyEUR ? 'EUR' : 'USD'"
      />
    </table-data-cell>
    <table-data-cell class="w-20">
      {{ item.vat + '%' }}
    </table-data-cell>
    <table-data-cell
      v-if="!$isReadOnly()"
      align="right"
    >
      <div class="flex gap-x-1.5 justify-end">
        <template v-if="!isSubItem && isInvoiceInternal && isDraft">
          <!-- It should only be possible to add sub items for internal invoices -->
          <invoice-item-add-button
            :member-id="getMemberId"
            :referenced-invoice-item="item"
          />
        </template>


        <invoice-item-edit-button
          :item="item"
        />

        <invoice-delete-item-button
          v-if="isInvoiceInternal ? isDraft : true"
          :item-id="item.id"
        />
      </div>
    </table-data-cell>
  </table-row>
</template>

<script>
import TableDataCell from "@/components/elements/tables/TableDataCell";
import TableRow from "@/components/elements/tables/TableRow";
import InvoiceDeleteItemButton
  from "@/components/pages/Invoices/Shared/EditInvoice/Includes/DeleteItem/InvoiceDeleteItemButton";
import {mapGetters} from "vuex";
import MoneyAmount from "@/components/global/Money/MoneyAmount";
import InvoiceItemEditButton
  from "@/components/pages/Invoices/Shared/EditInvoice/Includes/EditItem/InvoiceItemEditButton.vue";
import InvoiceItemAddButton
  from "@/components/pages/Invoices/Shared/EditInvoice/Includes/AddItem/InvoiceItemAddButton.vue";

export default {
  name: "InvoiceItemTableRow",
  components: {
    InvoiceItemAddButton,
    InvoiceItemEditButton,
    MoneyAmount,
    InvoiceDeleteItemButton,
    TableRow,
    TableDataCell
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isSubItem: {
      type: Boolean,
      default: false
    },
    isAccountingInterface: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('invoices', [
      'getInvoiceId',
      'getInvoice',
      'isInvoiceInternal',
      'isInvoiceCurrencyEUR',
      'isInvoiceCurrencyUSD',
      'getMemberId'
    ]),
    isDraft() {
      return this.$store.getters['invoices/isDraft'];
    },
    hasNegativeAmount() {
      return this.item.price < 0;
    }
  },
}
</script>

