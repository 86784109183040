<template>
  <div v-if="shouldShowContent">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardTab",
  props: {
    id: {
      type: String,
      required: true
    },
    /**
     * Returns the card tab title that is used for dynamic generation of the corresponding button.
     */
    title: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: false
    },
    /**
     * Reactively determines if the button to enable the card is shown.
     */
    isHidden: {
      type: Boolean,
      default: false
    },
    /**
     * Determines if the Card is available for selection in the add card tab menu
     */
    enableAddingManually: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      active: false,
      isHiddenInternal: false,
    }
  },
  watch: {
    isHiddenInternal: function (bool) {
      return bool ? this.$emit('hidden', this.id) : this.$emit('shown', this.id);
    },
    isHidden: function (bool) {
      this.isHiddenInternal = bool;
    },
    active: function (bool) {
      return bool ? this.$emit('open', this.id) : this.$emit('close', this.id);
    }
  },
  mounted() {
    this.isHiddenInternal = this.isHidden;
  },
  methods: {
    show() {
      this.isHiddenInternal = false;
    },
    hide() {
      this.isHiddenInternal = true;
    }
  },
  computed: {
    shouldShowContent() {
      return this.active && !this.getIsHidden;
    },
    shouldShowButton() {
      return !this.getIsHidden;
    },
    getIsHidden() {
      return this.isHiddenInternal;
    },
    canBeAddedManually() {
      return this.isHiddenInternal && this.enableAddingManually;
    },
  }
}
</script>

