<template>
  <show-missing-stock-table
    context="missingStock"
  />
</template>

<script>
import ShowMissingStockTable from "@/components/pages/MissingStock/ShowMissingStockTable.vue";

export default {
  name: "MissingStockTablePage",
  components: {ShowMissingStockTable}
}
</script>

<style scoped>

</style>