import apiService from './Base/BaseRepository';

const url = '/fulfilment';

export default {
  async getFulfilmentGroupsByDateAndDepartment(departmentId, date) {
    return await apiService
      .get(`${url}/groups-by-department/${departmentId}/${date}`);
  },

  async getFulfilmentGroup(groupId) {
    return await apiService
      .get(`${url}/groups/${groupId}`);
  },

  /**
   * Creates a new fulfilment group (date format YYYY-MM-DD)
   * @param department
   * @param date
   * @returns {Promise<*>}
   */
  async createFulfilmentGroup(departmentId, date) {
    return await apiService
      .post(`${url}/createFulfilmentGroup`, {
        date: date,
        department: departmentId
      });
  },

  async printLabel(fulfilmentNumber) {
    return await apiService.get(`${url}/${fulfilmentNumber}/printLabel`);
  }
}
