<template>
  <button-element
    :disabled="disabled"
    :title="hint"
    inverse
    size="tableaction"
    type="primary"
    @click.stop="$emit('click', $event)"
    @click-ctrl.stop="$emit('click-ctrl', $event)"
  >
    <fa-icon v-if="showIcon" :icon="icon" fixed-width></fa-icon>
    <span
      v-if="customText"
      :class="{'ml-2': showIcon}"
    >
            {{ customText }}
        </span>
  </button-element>
</template>

<script>
import ButtonElement from "../../../elements/buttons/ButtonElement";

export default {
  name: "ListTableButtonShow",
  components: {ButtonElement},
  props: {
    hint: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      default: "eye"
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    customText: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
    },
  }
}
</script>

