<template>
  <label v-if="label.length" :class="getTextSizeBySize" class="block text-gray-500">
    {{ label }}
    <span v-if="showRequiredMark" class="text-red-300">*</span>
  </label>
</template>

<script>
export default {
  name: "FormLabel",
  props: {
    label: {
      type: String,
      default: ''
    },
    showRequiredMark: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "normal",
      validator: val => ["normal", "xsmall", "big"].includes(val)
    }
  },
  computed: {
    getTextSizeBySize() {
      switch (this.size) {
        case "xsmall":
          return "text-xs";
        case "big":
          return "text-lg";
        default:
          return "text-sm sm:text-md";
      }
    }
  },
}
</script>

