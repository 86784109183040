<template>
  <div
    :class="getPaddingClasses"
    class="whitespace-nowrap text-white rounded text-center bg-pink-400"
  >
    <fa-icon fixed-width icon="box"/>
    x {{ quantity }}
  </div>
</template>

<script>
export default {
  name: "BoxQuantity",
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    small: {
      type: Boolean,
      default: false
    },
    withPadding: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getPaddingClasses() {
      if (this.withPadding) {
        return this.small ? 'text-sm p-0.5' : 'py-1 px-1';
      }
      return "";
    }
  },
}
</script>