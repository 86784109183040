const getDefaultState = () => {
  return {
    appVersion: null,
    appRefreshNeeded: false,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getAppVersion: state => state.appVersion,
    isAppRefreshNeeded: state => state.appRefreshNeeded === true,
  },
  mutations: {
    setAppVersion(state, version) {
      state.appVersion = version;
    },
    setAppRefreshNeededTrue(state) {
      state.appRefreshNeeded = true;
    }
  },
}