import auth from "@/router/middlewares/authMiddleware";
import ShowArrivalTemperatures from "@/components/pages/ArrivalTemperatures/ShowArrivalTemperatures";

import CreateArrivalTemperature
  from "@/components/pages/ArrivalTemperatures/CreateTemperature/CreateArrivalTemperature";

export default [
  {
    path: '/arrival-temperatures',
    name: 'arrival-temperatures.list',
    component: ShowArrivalTemperatures,
    meta: {
      middleware: [auth],
      title: 'Liste des températures d\'arrivage',
      permissions: ['ARRIVAL_TEMPERATURES_READ'],
    }
  },
  {
    path: '/arrival-temperatures/create',
    name: 'arrival-temperatures.create',
    component: CreateArrivalTemperature,
    meta: {
      middleware: [auth],
      title: 'Créer une température d\'arrivage',
      breadcrumbs: ['arrival-temperatures.list'],
      permissions: ['ARRIVAL_TEMPERATURES_WRITE'],
    }
  },
];