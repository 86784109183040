<template>
  <div>
    <button-element
      :disabled="!canCancel"
      disabled-tooltip="Impossible d'annuler la commande"
      type="danger"
      @click="$refs.modal.openModal()"
    >
      <fa-icon class="mr-2" fixed-width icon="exclamation-triangle"/>
      Annuler
    </button-element>

    <modal ref="modal" title="Annuler commande">
      <div class="font-bold">
        Êtes-vous sûr de vouloir annuler cette commande ?
      </div>

      <div class="mt-8 flex gap-x-4">
        <button-element
          type="danger"
          @click="cancelOrder"
        >
          <fa-icon fixed-width icon="exclamation-triangle"/>
          Oui, annuler commande
        </button-element>

        <button-element
          type="light"
          @click="$refs.modal.closeModal()"
        >
          Non
        </button-element>
      </div>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import Modal from "@/components/elements/modals/Modal";
import OrderRepository from "@/repositories/OrderRepository";
import {mapGetters} from "vuex";

export default {
  name: "CancelOrderButton",
  components: {Modal, ButtonElement},
  methods: {
    cancelOrder() {
      OrderRepository
        .cancelOrder(this.getOrderId)
        .then(() => {
          this.$sendSuccessToast('La commande a été annulé.');
          this.$refs.modal.closeModal();
          this.$store.dispatch('order/refreshOrder');
        })
    }
  },
  computed: {
    ...mapGetters('order', [
      'getOrderId', 'canCancel'
    ])
  },
}
</script>

<style scoped>

</style>