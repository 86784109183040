<template>
  <card size="small" title="Importation catalogue fournisseur">
    <form-wrapper ref="form"
                  :show-changed="true"
                  submit-button-label="Télécharger"
                  @submit="uploadFile"
    >
      <div class="grid grid-cols-2 gap-4">
        <supplier-select-input
          v-model="supplierId"
          label="Fournisseur"
          validation-rules="required"
        />

        <file-upload-button-element
          v-model="file"
          label="Catalogue (.csv)"
          validation-rules="required"
        />
      </div>

    </form-wrapper>
  </card>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import SupplierSelectInput from "@/components/elements/forms/elements/SupplierSelectInput";
import FileUploadButtonElement from "@/components/elements/forms/elements/upload/FileUploadButtonElement";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import SupplierCatalogRepository from "@/repositories/SupplierCatalogRepository";

export default {
  name: "UploadSupplierProductCatalog",
  components: {FormWrapper, FileUploadButtonElement, SupplierSelectInput, Card},
  data: () => ({
    supplierId: null,
    file: null
  }),
  methods: {
    uploadFile(callback) {
      SupplierCatalogRepository.uploadCatalog(this.supplierId, this.file)
        .then(() => {
          this.$sendSuccessToast("Catalogue téléchargé!");
          this.$emit('change');
        }).finally(() => callback());
    }
  },
}
</script>

<style scoped>

</style>