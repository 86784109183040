<template>
  <div>
    <button-element
      :disabled="!canConvertCart || isInReadOnlyMode"
      :disabled-tooltip="getConvertCartDisabledMessage"
      class="text-base"
      size="medium"
      type="primary"
      @click="onClick"
    >
      <fa-icon class="mr-2" fixed-width icon="exchange-alt"></fa-icon>
      Convertir en commande
    </button-element>
  </div>
</template>

<script>
import ButtonElement from "../../../../elements/buttons/ButtonElement";

export default {
  name: "ConvertCartToOrderButton",
  components: {ButtonElement},
  methods: {
    onClick() {
      this.$emit('click');
    },

    /**
     * Checks if the the user has selected required packaging according to the group.
     * isMandatory -> need to select at least one Packaging option
     * onlyOneChoice -> needs to select EXACTLY one packaging option
     *
     * @param group
     * @returns {boolean}
     */
    checkPackagingGroupRequirementsFulfilled(group) {
      let mappedPackagingIds = group.options.map(o => o.id);
      let selectedPackagingAmountFromGroup = this.$store.getters['cart/getSelectedPackagingOptions'].filter(o => {
        return mappedPackagingIds.includes(o.id);
      }).length;

      if (group.isMandatory) {
        if (group.onlyOneChoice) {
          return selectedPackagingAmountFromGroup === 1;
        }
        return selectedPackagingAmountFromGroup > 1;
      }

      if (group.onlyOneChoice) {
        return selectedPackagingAmountFromGroup <= 1;
      }

      return true;
    }
  },
  computed: {
    canConvertCart() {
      return this.hasAnyProductsInCart && !this.cartHasAnyErrors && this.hasSelectedRequiredPackaging;
    },
    hasAnyProductsInCart() {
      return this.$store.getters['cart/getCartItems'].length;
    },
    cartHasAnyErrors() {
      return this.$store.getters['cart/hasErrors'];
    },
    hasSelectedRequiredPackaging() {
      const availablePackagingGroups = this.$store.getters['cart/getPackageGroups'];
      const groupsPassedRequirement = availablePackagingGroups.filter(g => this.checkPackagingGroupRequirementsFulfilled(g));

      return availablePackagingGroups.length === groupsPassedRequirement.length;
    },
    getConvertCartDisabledMessage() {
      if (this.cartHasAnyErrors) {
        return "Veuillez d'abord corriger les erreurs énumérées ci-dessous."
      }
      if (!this.hasAnyProductsInCart) {
        return "Veuillez d'abord ajouter des produits."
      }
      if (!this.hasSelectedRequiredPackaging) {
        return "Veuillez d'abord choisir l'emballage."
      }

      return null;
    },

    isInReadOnlyMode() {
      return this.$isReadOnly();
    }
  },
}
</script>

