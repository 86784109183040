<template>
  <div v-if="getMember.fullName !== undefined">
    <page-title>
      <div class="flex flex-col md:flex-row gap-y-4 justify-between">
        {{ getPageTitle }}

        <convert-cart-to-order-button
          @click="$refs.convertCartModal.openModal()"></convert-cart-to-order-button>
      </div>
    </page-title>

    <error-alert v-if="cartHasAnyErrors" class="mb-6" show-immediately>
      <div class="font-medium text-md">
        Les problèmes suivants doivent être résolus avant de pouvoir convertir le panier en commande:
      </div>

      <div class="mt-3">
        <div v-for="error in getCartErrors" :key="error" class="text-sm">
          <fa-icon fixed-width icon="chevron-right"></fa-icon>
          {{ error }}
        </div>
      </div>
    </error-alert>

    <transition name="fade-up-down">
      <div v-if="!$isReadOnly()" class="flex flex-col md:flex-row justify-between gap-x-8 gap-y-4">
        <card class="w-full md:w-1/2 flex-grow-0" icon="search-plus" size="small" title="Ajouter produit"
              type="light">
          <add-product-to-cart-or-order type="cart"></add-product-to-cart-or-order>
        </card>

        <card class="w-full md:w-1/2" icon="box" size="small" title="Emballage" type="light">
          <cart-packaging-selection></cart-packaging-selection>
        </card>
      </div>
    </transition>

    <products-in-cart-or-order-table :member-id="getMemberId" context="cart"/>

    <convert-cart-to-order-modal v-if="!$isReadOnly()" ref="convertCartModal"></convert-cart-to-order-modal>
  </div>
</template>

<script>
import Card from "../../../elements/cards/Card";
import ProductsInCartOrOrderTable
  from "../../../global/CartAndOrder/ProductsInCartOrOrderManagement/Table/ProductsInCartOrOrderTable";
import AddProductToCartOrOrder from "../../Orders/EditOrder/AddToOrder/AddProductToCartOrOrder";
import ConvertCartToOrderModal from "./ConvertCartToOrderModal";
import PageTitle from "../../../elements/pages/PageTitle";
import ErrorAlert from "../../../elements/alerts/ErrorAlert";
import CartPackagingSelection from "./CartPackagingSelection";
import ConvertCartToOrderButton from "./ConvertButton/ConvertCartToOrderButton";

export default {
  name: "EditCart",
  components: {
    ConvertCartToOrderButton,
    CartPackagingSelection,
    ErrorAlert,
    PageTitle,
    ConvertCartToOrderModal, AddProductToCartOrOrder, ProductsInCartOrOrderTable, Card
  },
  data: () => ({
    selectedItem: {},
    selectedTimeSlot: null,
  }),
  mounted() {
    this.$store.commit('cart/resetCartState');
    this.getCart();
  },
  methods: {
    getCart() {
      this.$store.dispatch('cart/fetchCart', {memberId: this.getMemberId, showLoadingIndicator: true});
    },
  },
  computed: {
    getMemberId() {
      return parseInt(this.$route.params.member);
    },
    getPageTitle() {
      if (this.getMember) {
        return `Panier d'achat ${this.getMember.fullName} #${this.getMemberId}`;
      }
      return "Panier d'achat";
    },
    getMember() {
      return this.$store.getters['cart/getMember'];
    },
    getCartErrors() {
      return this.$store.getters['cart/getErrors'];
    },
    cartHasAnyErrors() {
      return this.$store.getters['cart/hasErrors'];
    },
  }
}
</script>

