<template>
  <tr>
    <td class="w-64 px-2 py-1">
      <span class="font-bold">{{ valueKey }}: </span>
    </td>
    <td class="px-4 py-1">
            <span
              :class="{'border-b-2 border-dotted border-gray-400 cursor-pointer hover:text-gray-900 hover:border-gray-900': isTooLong && !isExpanded}"
              :title="isExpanded ? '' : 'Cliquez pour afficher la valeur complète'"
              @click="expandValue"
              v-html="getValueToDisplay"
            >
            </span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "LogTableDataLine",
  props: {
    valueKey: {
      type: String,
    },
    value: {}
  },
  data: () => ({
    isExpanded: true,
  }),
  created() {
    if (this.isTooLong) {
      this.isExpanded = false;
    }
  },
  filters: {
    capitalizeFirstLetter(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    expandValue() {
      if (this.isExpanded) {
        return false;
      }

      this.isExpanded = true;
    },
    formatValue(value) {
      // Format null
      if (value === null) {
        return `<span class="italic text-red-500 text-xs">Vide</span>`
      }
      // Format object
      if (typeof value === "object" && !Array.isArray(value)) {
        value = JSON.stringify(value);

      }

      // Format Boolean
      if (
        typeof value === "boolean"
        || (typeof value === "string" && (value === "true" || value === "false"))
      ) {
        let boolvalue = Boolean(value);
        return boolvalue
          ? `<i class="fa fa-check text-luxcaddy-600"></i>`
          : `<i class="fa fa-times text-red-600"></i>`;
      }

      // Format string
      if (typeof value === "string") {
        if (value === "REDACTED") {
          return "*************";
        }
      }

      // Format Date
      const date = this.$date(value, 'YYYY-MM-DD HH:mm:ss', true);
      if (date.isValid()) {
        return date.format('DD.MM.YYYY HH:mm');
      }

      // Format Array
      if (Array.isArray(value)) {
        let str = `<ul class="list-disc list-outside">`;
        value.forEach(v => str += `<li>${this.formatValue(v)}</li>`);
        str += `</ul>`;
        return str;
      }

      // Everything else
      return value;
    },
  },
  computed: {
    maxLengthShown: () => 40,

    isTooLong() {
      if (this.isValueRealObject) {
        return JSON.stringify(this.value).length >= this.maxLengthShown;
      }

      if (typeof this.value !== "string")
        return false;

      return this.value.length >= this.maxLengthShown;
    },
    getValueToDisplay() {
      let value = this.value;

      if (this.isValueRealObject) {
        value = JSON.stringify(value);
      }

      if (this.isTooLong) {
        if (this.isExpanded) {
          return this.formatValue(value);
        }

        return '<i class="fa fa-expand-alt"></i> &nbsp;' + value.substring(0, this.maxLengthShown);
      }
      return this.formatValue(value);
    },

    isValueRealObject() {
      return this.value && typeof this.value === "object" && !Array.isArray(this.value);
    }
  },
}
</script>

<style scoped>

</style>