<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :enable-search="enableSearch"
    :label="label"
    :limit-option-menu-width="limitOptionMenuWidth"
    :multi="type === 'multi'"
    :options="availableRacks"
    :touch-optimized="touchOptimized"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  ></select-element>
</template>

<script>
import RackRepository from "@/repositories/RackRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "RackSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Étagère',
      required: false,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'single'
    },
    /**
     * When enabled, the option menu will not have the same length as the  input, when the input is very long
     */
    limitOptionMenuWidth: {
      type: Boolean,
      default: true
    },
    touchOptimized: {
      type: Boolean,
      default: false,
    },
    allowUnselecting: {
      type: Boolean,
      default: true
    },
    /**
     * If set to true, value is gonna be id instead of number
     */
    idAsValue: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.options === undefined) {
      this.fetchRacks();
    } else {
      this.availableRacks = this.options;
    }
  },
  methods: {
    fetchRacks() {
      RackRepository.get().then((res) => {
        this.availableRacks = res.data.data.map(r => {
          if (this.idAsValue) {
            return {
              label: r.number,
              value: r.id
            };
          }

          return {
            label: r.number,
            value: r.number
          };
        }).sort((a, b) => a.label - b.label);
      });
    }
  },
  data: () => ({
    availableRacks: [],
  })
}
</script>

