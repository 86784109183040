<template>
  <form-wrapper
    :show-changed="true"
    submit-button-label="Sauvegarder"
    @submit="createOrUpdateMember"
  >

    <form-grid class="grid-cols-12">
      <!-- Row 1 -->
      <title-select-input v-model="memberData.title"
                          :validation-rules="getValidationRulesForPersonalFields"
                          class="col-span-12 lg:col-span-6 xl:col-span-2"/>

      <text-input-element v-model="memberData.firstName" :validation-rules="getValidationRulesForPersonalFields"
                          class="col-span-12 lg:col-span-6 xl:col-span-5"
                          label="Prénom"/>

      <text-input-element v-model="memberData.familyName" :validation-rules="getValidationRulesForPersonalFields"
                          class="col-span-12 lg:col-span-6 xl:col-span-5"
                          label="Nom"/>

      <!-- Row 2 -->
      <text-input-element
        v-model="memberData.companyName"
        :validation-rules="getValidationRulesForCompanyField"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Société"
        type="text"
      />

      <country-phone-prefix-select-input
        v-model ="memberData.phonePrefix"
        :allow-unselecting="false"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Phone prefix"
        validation-rules="required"
      />
      <text-input-element
        v-model="memberData.phoneNumber"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Tél"
        validation-rules="required"
      />

      <text-input-element
        v-model="memberData.vatNumber"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Numero TVA"
      />

      <email-input-element
        v-model="memberData.email"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Adresse électronique"
        validation-rules="required"
      />

      <!-- Row 3 -->
      <street-input-element
        v-model="memberData.street"
        :require-street-number="false"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        validation-rules="required"
      />

      <text-input-element
        v-model="memberData.postalCode"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Code postal"
        validation-rules="required|max:10"
      />

      <!-- Row 4 -->
      <text-input-element
        v-model="memberData.city"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Ville"
        validation-rules="required"
      />

      <country-select-input
        v-model="memberData.country"
        :allow-unselecting="false"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Pays"
        validation-rules="required"
      />

      <language-select-input
        v-model="memberData.language"
        :allow-unselecting="false"
        class="col-span-12 lg:col-span-6 xl:col-span-4"
        label="Langue"
        validation-rules="required"
      />
    </form-grid>

  </form-wrapper>
</template>

<script>
import FormWrapper from "../../elements/forms/FormWrapper";
import FormGrid from "../../elements/forms/FormGrid";
import TitleSelectInput from "../../elements/forms/elements/TitleSelectInput";
import TextInputElement from "../../elements/forms/elements/base/TextInputElement";
import EmailInputElement from "../../elements/forms/elements/EmailInputElement";
import StreetInputElement from "../../elements/forms/elements/StreetInputElement";
import CountrySelectInput from "../../elements/forms/elements/CountrySelectInput";
import LanguageSelectInput from "../../elements/forms/elements/LanguageSelectInput";
import MemberRepository from "../../../repositories/MemberRepository";
import CountryPhonePrefixSelectInput from "@/components/elements/forms/elements/CountryPhonePrefixSelectInput.vue";

export default {
  name: "CreateOrUpdateMemberForm",
  components: {
      CountryPhonePrefixSelectInput,
    LanguageSelectInput,
    CountrySelectInput,
    StreetInputElement,
    EmailInputElement,
    TextInputElement,
    TitleSelectInput,
    FormGrid,
    FormWrapper,
  },
  data: () => ({
    memberData: {},
  }),
  mounted() {
    this.memberData = {...this.member};
  },
  props: {
    member: {
      type: Object,
      required: false
    },
    mode: {
      type: String,
      default: 'create'
    }
  },
  methods: {
    createOrUpdateMember(callback) {
      let req = this.isUpdateMode ? this.updateMember() : this.createMember();
      req.finally(() => callback());
    },
    updateMember() {
      return MemberRepository.update(this.$route.params.member, this.memberData).then(() => {
        this.$sendSuccessToast(`Membre ${this.member.fullName} a été modifié!`);
      });
    },
    createMember() {
      return MemberRepository.create(this.memberData).then((res) => {
        this.$sendSuccessToast(`Membre crée!`);
        this.$router.push({name: 'members.edit', params: {member: res.data.data.id}})
      });
    }
  },
  computed: {
    isUpdateMode() {
      return this.mode === 'update';
    },
    isFirstNameSet() {
      return this.memberData.firstName?.length > 0 || false;
    },
    isFamilyNameSet() {
      return this.memberData.familyName?.length > 0 || false;
    },
    isTitleSet() {
      return this.memberData.title !== null;
    },
    isCompanySet() {
      return this.memberData.companyName?.length > 0 || false;
    },
    getValidationRulesForPersonalFields() {
      return this.isCompanySet ? 'max:50' : 'required|max:50';
    },
    getValidationRulesForCompanyField() {
      return this.isFirstNameSet || this.isFamilyNameSet || this.isTitleSet ? 'max:50' : 'required|max:50';
    }
  }
}
</script>

