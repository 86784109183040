<template>
  <button
    v-if="hasValidLink"
    :class="getClassesByType"
    class="border text-xs  rounded-md px-2 py-1 focus:outline-none shadow"
    @click="openLink"
  >
    {{ getText }}
  </button>
</template>

<script>
import {decodeNotificationUrl} from "@/helpers/NotificationLinkHelper";

export default {
  name: "DashboardNotificationButton",
  props: {
    type: {
      type: String,
      validator: val => ['dark', 'light'].includes(val)
    },
    encodedString: {
      type: String,
      required: true
    }
  },
  methods: {
    openLink() {
      window.open(this.getLink, '_blank');
    }
  },
  computed: {
    getClassesByType() {
      switch (this.type) {
        case "dark":
          return "bg-black text-white hover:bg-gray-800 hover:text-white";
        default:
          return "border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white";
      }
    },
    getDecoded() {
      return decodeNotificationUrl(this.encodedString);
    },
    hasValidLink() {
      return this.getLink !== null && this.getText !== null;
    },
    getLink() {
      return this.getDecoded.link;
    },
    getText() {
      return this.getDecoded.text;
    }
  },
}
</script>

<style scoped>

</style>