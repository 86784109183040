import auth from "../middlewares/authMiddleware";
import ShowProducts from "../../components/pages/Products/ShowProducts";
import EditProduct from "../../components/pages/Products/EditProduct/EditProduct";
import CreateProduct from "../../components/pages/Products/CreateProduct/CreateProduct";
import ProductPackagingInfo from "@/components/pages/Products/PackagingInfo/ProductPackagingInfo";
import MissingStockTablePage from "@/components/pages/MissingStock/MissingStockTablePage.vue";

export default [
  {
    path: '/products',
    name: 'products.list',
    component: ShowProducts,
    meta: {
      middleware: [auth],
      title: 'Liste des produits',
      permissions: ['ACCESS_PRODUCT_VIEW']
    }
  },
  {
    path: '/products/:product/packaging-info',
    name: 'products.packaging',
    component: ProductPackagingInfo,
    meta: {
      middleware: [auth],
      title: 'Packaging Info produit',
      breadcrumbs: ['products.list'],
      permissions: ['PRODUCT_READ']
    }
  },
  {
    path: '/products/:product/edit',
    name: 'products.edit',
    component: EditProduct,
    meta: {
      middleware: [auth],
      title: 'Modifier un produit',
      readOnly: {supported: true, title: 'Voir un produit', permissions: ['PRODUCT_READ']},
      breadcrumbs: ['products.list'],
      permissions: ['PRODUCT_WRITE']
    }
  },
  {
    path: '/products/create',
    name: 'products.create',
    component: CreateProduct,
    meta: {
      middleware: [auth],
      title: 'Modifier un produit',
      breadcrumbs: ['products.list'],
      permissions: ['PRODUCT_WRITE']
    }
  },
  {
    path: '/products/missing-stock',
    name: 'products.missing-stock',
    component: MissingStockTablePage,
    meta: {
      middleware: [auth],
      title: 'Défaut de stock',
      breadcrumbs: ['products.list'],
      permissions: ['ACCESS_MEMBER_VIEW']
    }
  }
];