
import ListTable from "@/components/listTable/ListTable.vue";
import VehicleRepository from "@/repositories/VehicleRepository";
import LuxembourgishNumberPlate from "@/components/pages/Devices/Includes/LuxembourgishNumberPlate.vue";
import Vue from "vue";
import {Column} from "@/components/listTable/ListTableTypes";
import {FILTER_BOOLEAN, FILTER_DEVICE} from "@/components/listTable/includes/controls/filters/availableFilters";

export default Vue.extend({
  name: "ShowVehicles",
  components: {LuxembourgishNumberPlate, ListTable},
  data() {
    return {
      tableProps: {
        identifier: "vehiclesTable",
        repositoryFunc: VehicleRepository.getPaginated,
        pagination: true,
        createRoute: {
          name: 'vehicles.create'
        },

        title: "Véhicules",
        columns: [
          {
            caption: "Actions",
            buttons: [
              {
                type: 'show',
                hint: 'Voir véhicule',
                route: (row) => {
                  return {
                    name: 'vehicles.edit',
                    params: {
                      vehicle: row.id,
                    },
                    query: {readonly: true}
                  };
                },
              },
              {
                type: 'edit',
                hint: 'Modifier véhicule',
                route: (row) => {
                  return {
                    name: 'vehicles.update',
                    params: {
                      id: row.id
                    }
                  };
                }
              },
              {
                type: 'delete',
                hint: 'Discontinuer',
                condition: (vehicle) => vehicle.isActive,
                confirmation: {
                  title: 'Abandonner véhicule?',
                  text: 'Étes vous sure que vous voulez abandonner cette véhicule?'
                },
                successText: 'La vehicule a été abandonné',
                deleteAction: (vehicle) => VehicleRepository.toggleActive(vehicle.id),
              },
              {
                type: 'delete',
                hint: 'Reactiver',
                icon: 'check',
                condition: (vehicle) => !vehicle.isActive,
                confirmation: {
                  title: 'Reactiver véhicule?',
                  text: 'Étes vous sure que vous voulez reactiver cette véhicule qui a été abandonné?'
                },
                successText: 'La véhicule a été reactivé',
                deleteAction: (vehicle) => VehicleRepository.toggleActive(vehicle.id),
              },
            ],
          },
          {
            caption: '#',
            dataField: 'id',
            width: 0,
            sort: {
              dataField: 'id',
            },
          },
          {
            caption: "Marque et Modèle",
            cellTemplate: "make-and-model-template",
            sort: {
              dataField: 'makeAndModel',
            },
          },
          {
            caption: "Plaque d'immatriculation",
            cellTemplate: 'vehicle-plate-template',
            sort: {
              dataField: 'vehiclePlate',
              default: "asc"
            },
          },
          {
            caption: "Scanner / Téléphone",
            cellTemplate: "device-template"
          },
          {
            caption: "Capacité Bacs",
            dataField: "boxCapacity",
            sort: {
              dataField: 'boxCapacity',
            },
          },
          {
            caption: "Limite distance",
            calculateCellValue: (r) => (new Intl.NumberFormat()).format(r.distanceLimit) + 'km',
            sort: {
              dataField: 'distanceLimit',
            },
          },
          {
            caption: "Actuellement fonctionnel",
            dataType: "boolean",
            calculateCellValue: (r) => !r.isOutOfOrder,
            sort: {
              dataField: 'isOutOfOrder',
            },
          },
          {
            caption: "Activé",
            dataType: "boolean",
            calculateCellValue: (r) => r.isActive
          },
        ] as Array<Column>,
        filters: [
          {
            caption: "#",
            filterKey: "id",
            forceIsVisible: true,
            small: true,
          },
          {
            caption: "Marque et Modèle",
            filterKey: "makeAndModel",
            forceIsVisible: true,
            small: true,
          },
          {
            caption: "Plaque d'immatriculation",
            filterKey: "vehiclePlate",
            forceIsVisible: true,
            small: true
          },
          {
            caption: 'Scanner / Téléphone',
            filterKey: 'deviceId',
            type: FILTER_DEVICE,
            forceIsVisible: true,
          },
          {
            caption: 'Activé',
            filterKey: 'isActive',
            type: FILTER_BOOLEAN,
            defaultValue: 1,
            forceIsVisible: true,
            small: true,
          },
          {
            caption: 'Actuellement fonctionnel',
            filterKey: 'isOperational',
            type: FILTER_BOOLEAN,
            forceIsVisible: true,
            small: true,
          },
        ] as Array<any>
      }
    }
  },
})
