<template>
  <div class="flex gap-x-6 items-center">
    <modal ref="mapModal" :title="address.fullName" disable-padding icon="map-marked-alt" show-close-button
           @close="mapModalOpen = false" @open="mapModalOpen = true">
      <error-alert v-if="!hasCoordinates" class="rounded-none">
        Nous n'avons pas trouvé la position exacte de cette adresse.
      </error-alert>

      <google-map
        v-if="mapModalOpen"
        :height-in-px="600"
        :latitude="getLatitude"
        :longitude="getLongitude"
        :markers="getMarkers"
        :width-in-px="800"
      />
    </modal>
    <button-element v-if="showMapButton" size="small" tooltip="Afficher sur carte"
                    @click="$refs.mapModal.openModal()">
      <fa-icon class="text-sm" fixed-width icon="map-marked-alt"></fa-icon>
    </button-element>

    <div class="w-max">
      <div v-if="address.fullName">{{ address.fullName }}</div>
      <div v-if="address.street">{{ address.street }}</div>

      <div>
        <span v-if="address.city">{{ address.city }}</span>
        <span v-if="address.country || address.postalCode">
                {{ address.postalCode }} {{ address.country.toUpperCase() }}
            </span>
      </div>
    </div>


  </div>
</template>

<script>
import ButtonElement from "../../buttons/ButtonElement";
import Modal from "../../modals/Modal";
import GoogleMap from "../map/GoogleMap";
import ErrorAlert from "../../alerts/ErrorAlert";

export default {
  name: "AddressDisplay",
  components: {ErrorAlert, GoogleMap, Modal, ButtonElement},
  props: {
    address: {
      type: Object,
      required: true
    },
    showMapButton: {
      type: Boolean,
      required: false,
    }
  },
  data: () => ({
    mapModalOpen: false
  }),
  computed: {
    getGeocoding() {
      return this.address?.geocoding || null;
    },
    getLongitude() {
      return this.getGeocoding?.longitude || null;
    },
    getLatitude() {
      return this.getGeocoding?.latitude || null;
    },
    hasCoordinates() {
      return this.getLongitude !== null && this.getLatitude !== null;
    },
    getMarkers() {
      if (this.getLongitude !== null && this.getLatitude !== null) {
        return [{
          position: {
            lat: this.getLatitude,
            lng: this.getLongitude,
          },
          infoText: this.getMarkerInfoText,
          showOnCreate: true
        }];
      }
      return undefined;
    },
    getMarkerInfoText() {
      const a = this.address;

      let string = `<strong>${a.fullName}</strong><br>`;
      string += a.street ? `${a.street}<br>` : ``;
      string += a.city ? `${a.city}<br>` : ``;
      string += a.postalCode
        ? a.country
          ? `${a.postalCode} ${a.country.toUpperCase()}`
          : `${a.postalCode}`
        : ``;

      return string;
    }
  },
}
</script>

