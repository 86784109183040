<template>
  <div
    :class="'bg-' + getColorStringByType + '-200'"
    :title="$slots.default[0].text"
    class="flex flex-row gap-x-4 items-center justify-between p-1 rounded-md"
  >
        <span
          :class="'text-' + getColorStringByType + '-800'"
          class="ml-2 truncate"
        >
            <slot></slot>
        </span>
    <div
      v-if="insetText"
      :class="'bg-' + getColorStringByType + '-100'"
      :title="insetText"
      class="rounded-md h-8 flex items-center px-3 w-1/3 flex-shrink-0 truncate"
    >
      <span class="text-center w-full">
        {{ insetText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    insetText: {
      type: [String, Number],
      required: false
    },
    type: {
      type: String,
      validator: val => ['primary', 'warning', 'danger'].includes(val),
      default: 'primary'
    }
  },
  computed: {
    getColorStringByType() {
      switch (this.type) {
        case 'warning':
          return "yellow";
        case 'danger':
          return "red";
        default:
          return "luxcaddy";
      }
    }
  },
}
</script>

