<template>
  <card>
    <div class="flex flex-col md:flex-row items-center gap-4 justify-between mb-12">
      <div class="hidden md:block flex flex-row gap-x-4">
        <toggle-element
          v-model="hideDisabled"
          :label="'Masquer les ' + getDisabledCategoryCount + ' catégories désactivés'"
          label-size="xsmall"
        />
      </div>

      <div>
        <toggle-element
          v-model="enableDrag"
          label="Activer Glisser-Déposer"
          label-size="xsmall"
        />
      </div>

      <transition name="fade">
        <div v-if="!$isReadOnly()" class="space-x-4">
          <product-category-create-modal
            ref="createProductCategoryModal"
            :parent-id="null"
          />

          <!-- Add category -->
          <button-element
            v-if="shouldShowAddRootCategoryButton"
            size="medium"
            type="primary"
            @click="$refs.createProductCategoryModal.openModal()"
          >
            <fa-icon icon="plus"></fa-icon>
            <span v-if="isVirtual" class="ml-2 hidden md:inline-block">Ajouter catégorie virtuel</span>
            <span v-else class="ml-2 hidden md:inline-block">Ajouter catégorie</span>
          </button-element>

          <!-- Expand -->
          <button-element size="medium" type="light" @click="expandAll">
            <fa-icon icon="chevron-down"></fa-icon>
            <span class="ml-2 hidden md:inline-block">Vaincre tous</span>
          </button-element>

          <!-- Shrink -->
          <button-element size="medium" type="light" @click="expandNone">
            <fa-icon icon="chevron-right"></fa-icon>
            <span class="ml-2 hidden md:inline-block">Réduire tous</span>
          </button-element>

        </div>
      </transition>
    </div>


    <transition name="fade-up-down">
      <info-alert v-if="enableDrag" class="mb-6">
        Changez l'ordre des catégories en saisissant l'icône (
        <fa-icon class="text-gray-400 mx-2" icon="grip-vertical"></fa-icon>
        ) et en déplaçant la catégorie.
      </info-alert>
    </transition>

    <category-tree/>
  </card>
</template>

<script>
import ToggleElement from "@/components/elements/toggles/ToggleElement";
import ProductCategoryCreateModal from "@/components/pages/Categories/Common/Modals/ProductCategoryCreateModal";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import InfoAlert from "@/components/elements/alerts/InfoAlert";
import Card from "@/components/elements/cards/Card";
import CategoryTree from "@/components/elements/categoryManagement/Tree/CategoryTree";

import {categoryTypes, getCategoryConfigByType} from "@/luxcaddyConfig";

export default {
  name: "ShowCategories",
  components: {CategoryTree, Card, InfoAlert, ButtonElement, ProductCategoryCreateModal, ToggleElement},
  props: {
    type: {
      type: String,
      default: "Product",
      validator: val => categoryTypes().includes(val)
    },
    isVirtual: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.$store.commit('categories/clearCategories');
    this.getCategories();
  },
  computed: {
    hideDisabled: {
      get() {
        return this.$store.state.categories.hideDisabledCategories;
      },
      set(bool) {
        this.$store.commit('categories/setHideDisabledCategories', bool);
      }
    },
    enableDrag: {
      get() {
        return this.$store.getters['categories/getEnableDrag'];
      },
      set(bool) {
        this.$store.commit('categories/setEnableDrag', bool);
      }
    },
    // Whether the button to add a root category should be shown.
    shouldShowAddRootCategoryButton() {
      return this.$luxcaddyConfig('category.types').find(c => c.value === this.type && c.isVirtual === this.isVirtual)?.canCreateRootCategory || false;
    },
    getCategoryConfig() {
      return getCategoryConfigByType(this.type, this.isVirtual);
    },
    getDisabledCategoryCount() {
      return this.$store.getters['categories/getDisabledCategoryCount'];
    }
  },
  methods: {
    getCategories() {
      this.$store.dispatch('categories/getAllCategories', {
        type: this.type,
        isVirtual: this.isVirtual
      }).then(() => {
        if (this.getCategoryConfig.expandAfterLoad) {
          this.expandAll();
        }
      });
    },
    expandAll() {
      this.$store.commit('categories/expandAll');
    },
    expandNone() {
      this.$store.commit('categories/expandNone');
    },
  }
}
</script>

<style scoped>

</style>