<template>
  <div class="flex items-center bg-transparent gap-x-1.5 rounded-md">
    <!-- Start -->
    <list-table-pagination-action-button
      :disabled="currentPage === 1"
      secondary-style
      title="Début"
      @click="currentPage = 1"
    >
      <fa-icon fixed-width icon="step-backward"/>
    </list-table-pagination-action-button>

    <!-- -10 Pages -->
    <list-table-pagination-action-button
      :disabled="!canGo10PagesBack"
      secondary-style
      title="-10 Pages"
      @click="currentPage = currentPage - 10"
    >
      <fa-icon fixed-width icon="chevron-left"/>
      <fa-icon class="-ml-3.5" fixed-width icon="chevron-left"/>
    </list-table-pagination-action-button>

    <!-- -1 Page -->
    <list-table-pagination-action-button
      :disabled="!canGo1PageBack"
      class="ml-3"
      title="-1 Page"
      @click="currentPage--"
    >
      <fa-icon fixed-width icon="chevron-left"/>
    </list-table-pagination-action-button>


    <div class="shadow h-8 flex font-medium text-gray-200 bg-gray-500 rounded">
      <list-table-pagination-page
        v-for="(pageToDisplay, i) in pagesToDisplay"
        :key="pageToDisplay"
        :class="{'rounded-l': i === 0, 'rounded-r': i === pagesToDisplay.length - 1}"
        :current-page-set="page"
        :page="pageToDisplay"
        :table-identifier="tableIdentifier"
        @change="onPageChange"
      />
    </div>


    <!-- +1 Page -->
    <list-table-pagination-action-button
      :disabled="!canGo1PageForward"
      class="mr-3"
      title="+1 Page"
      @click="currentPage++"
    >
      <fa-icon fixed-width icon="chevron-right"></fa-icon>
    </list-table-pagination-action-button>

    <!-- +10 Pages -->
    <list-table-pagination-action-button
      :disabled="!canGo10PagesForward"
      secondary-style
      title="+10 Pages"
      @click="currentPage = currentPage + 10"
    >
      <fa-icon fixed-width icon="chevron-right"></fa-icon>
      <fa-icon class="-ml-3.5" fixed-width icon="chevron-right"></fa-icon>
    </list-table-pagination-action-button>

    <!-- End -->
    <list-table-pagination-action-button
      :disabled="currentPage === getTotalPages"
      secondary-style
      title="Fin"
      @click="currentPage = getTotalPages"
    >
      <fa-icon fixed-width icon="step-forward"/>
    </list-table-pagination-action-button>
  </div>
</template>

<script>
import ListTablePaginationPage from "./ListTablePaginationPage";
import ListTablePaginationActionButton
  from "@/components/listTable/includes/controls/pagination/includes/ListTablePaginationActionButton.vue";

export default {
  name: "PaginationPageSelector",
  components: {ListTablePaginationActionButton, ListTablePaginationPage},
  props: {
    tableIdentifier: {
      type: String,
      required: false
    },
    // Not required if tableIdentifier set
    page: {
      type: Number,
      required: false
    },
    // Not required if tableIdentifier set
    totalPages: {
      type: Number,
      required: false
    }
  },
  methods: {
    onPageChange(page) {
      this.$emit('change', page);

      if (!this.isListTableMode) {
        this.currentPage = page;
      }
    }
  },
  computed: {
    isListTableMode() {
      return this.tableIdentifier !== undefined && this.tableIdentifier !== null;
    },

    getPaginationConfig() {
      if (!this.isListTableMode) {
        return null;
      }

      return this.$store.getters['listTable/getPaginationConfigs']
        .find(p => p.tableIdentifier === this.tableIdentifier);
    },

    currentPage: {
      get() {
        if (this.isListTableMode) {
          return this.getPaginationConfig?.currentPage || 1;
        }
        return this.page || 1;
      },
      set(page) {
        if (this.isListTableMode) {
          this.$store.commit('listTable/setCurrentPage', {
            tableIdentifier: this.tableIdentifier,
            page: page
          });
          this.$emit('change');
        }
        this.$emit('update:page', page);
        this.$emit('change-page', page);
      }
    },
    pagesToDisplay() {
      let maxPages = 7;
      let totalPages = this.getTotalPages;
      let startPage, endPage;
      const currentPage = this.currentPage;

      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;

        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      return Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    },
    getTotalPages() {
      if (this.isListTableMode) {
        return this.getPaginationConfig?.lastPage || 1;
      }
      return this.totalPages;
    },
    getCurrentPage() {
      return this.currentPage;
    },

    canGo1PageBack() {
      return this.getCurrentPage > 1;
    },

    canGo10PagesBack() {
      return this.getCurrentPage >= 11;
    },

    canGo1PageForward() {
      return this.currentPage !== this.getTotalPages;
    },
    canGo10PagesForward() {
      return this.currentPage < (this.getTotalPages - 10);
    }
  }
}
</script>


