import {FILTER_DATETIME_RANGE} from "@/components/listTable/includes/controls/filters/availableFilters";

export function isFilterSet(value, filterType) {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  if (filterType === "boolean" && (value === 1 || value === 0)) {
    return true;
  }
  if (typeof value === "string" && value.length === 0) {
    return false;
  }
  if (filterType === FILTER_DATETIME_RANGE) {
    return value?.startDate !== null || value?.endDate !== null;
  }
  return value !== undefined && value !== null;
}