<template>
  <div>
    <custom-table :headings="getHeadings1" title="Produits commandés">
      <table-row v-for="(item,index) in supplierOrderItems" :key="index"
                 :class="{'text-gray-300': !item.hasMatch}">
        <table-data-cell>
          <button-element
            size="small"
            type="warning"
            @click="navigateToProduct(item.productId)"
            @click-ctrl="navigateToProduct(item.productId, true)"
          >
            <fa-icon icon="edit"></fa-icon>
          </button-element>
        </table-data-cell>
        <table-data-cell>
          <product-quick-action-modal
            :id="item.productId"
          />
        </table-data-cell>
        <table-data-cell class="flex">
          {{ item.supplierReference }}
        </table-data-cell>
        <table-data-cell>
          {{ item.name }}
        </table-data-cell>
        <table-data-cell class="text-right">
          {{ item.orderedQuantity }}
        </table-data-cell>
        <table-data-cell>
          {{ item.orderedUnit }}
        </table-data-cell>
        <table-data-cell :class="{'text-red-700 font-bold': item.quantityMisMatch}" class="text-right">
          {{ item.deliveredQuantity }}
        </table-data-cell>
        <table-data-cell :class="{'text-red-700 font-bold': item.unitMissMatch}">
          {{ item.deliveredUnit }}
        </table-data-cell>
        <table-data-cell class="text-right">
          {{ item.orderedTotal / 100 }} €
        </table-data-cell>
        <table-data-cell :class="{'text-red-700 font-bold': item.totalAmountMisMatch}" class="text-right">
          <span v-if="item.receivedTotal">
            {{ item.receivedTotal / 100 }} €
          </span>
        </table-data-cell>
      </table-row>
    </custom-table>


    <custom-table :headings="getHeadings2" class="mt-6" title="Produits non trouvés">
      <table-row v-for="(item,index) in unMatchedDeliveryNoteItems" :key="index">
        <table-data-cell>
          {{ item.supplierReference }}
        </table-data-cell>
        <table-data-cell>
          {{ item.name }}
        </table-data-cell>
        <table-data-cell :class="{'text-red-700 font-bold': item.deliveredQuantity <= 0}" class="text-right">
          {{ item.deliveredQuantity }}
        </table-data-cell>
        <table-data-cell>
          {{ item.deliveredUnit }}
        </table-data-cell>
        <table-data-cell class="text-right">
          {{ item.price / 100 }} €
        </table-data-cell>
        <table-data-cell class="text-right">
          {{ item.receivedTotalAmount / 100 }} €
        </table-data-cell>
      </table-row>
    </custom-table>

  </div>
</template>
<script>
import SupplierOrderRepository from "../../../../../repositories/SupplierOrderRepository";
import CustomTable from "@/components/elements/tables/CustomTable";
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import newTabMixin from "@/mixins/newTabMixin";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal.vue";

export default {
  name: "ProvencaleDeliveryCheck",
  components: {ProductQuickActionModal, TableDataCell, TableRow, CustomTable, ButtonElement},
  mixins: [newTabMixin],
  props: {
    supplierOrderId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    supplierOrderItems: [],
    unMatchedDeliveryNoteItems: [],
  }),

  mounted() {
    SupplierOrderRepository.compareProvencaleToDeliveryNote(this.supplierOrderId).then((r) => {
        this.supplierOrderItems = r.data.data.items;
        this.unMatchedDeliveryNoteItems = r.data.data.unMatchedDeliveryNoteItems;
      }
    );
  },

  computed: {
    getHeadings1() {
      return [
        {label: 'Action'},
        {label: 'PrId'},
        {label: 'Réf. fo.'},
        {label: 'Produit'},
        {label: 'QteCom'},
        {label: 'UnitéCom'},
        {label: 'QteRecu'},
        {label: 'UnitéRecu'},
        {label: 'TotalCom'},
        {label: 'TotalRecu'},
      ];
    },
    getHeadings2() {
      return [
        {label: 'Réf. fo.'},
        {label: 'Produit'},
        {label: 'QteRecu'},
        {label: 'UnitéRecu'},
        {label: 'Prix unit.'},
        {label: 'TotalRecu'},
      ];
    }
  },

  methods: {
    navigateToProduct(productId, newTab = false) {
      let route = {
        name: 'products.edit',
        params: {
          product: productId
        }
      };

      if (newTab) {
        return this.openRouteInNewTab(route);
      }

      return this.$router.push(route);
    }

  }

}
</script>

