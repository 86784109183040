<template>
  <div>
    <div class="flex items-center gap-x-2">
      <div class="w-24 h-24 flex-none">
        <img :alt="product.name" :src="getProductImageUrl" class="w-full h-full object-contain">
      </div>
      <div class="flex flex-col">
        <div class="text-xl font-semibold">
          {{ product.name }}
        </div>
        <div class="text-lg">
          {{ getSubTitleString }}
        </div>
      </div>
    </div>

    <div class="h-0.5 bg-gray-300 my-3"/>

    <div class="flex gap-x-5 justify-between mb-8">
      <div class="flex flex-col flex-1">
        <number-input-element
          v-model="boxes"
          label="Boites"
          touch-optimized
        />

        <div class="flex flex-wrap">
          <touch-optimized-button
            v-for="boxPreset in boxPresets"
            :key="boxPreset"
            class="w-min border border-gray-400 bg-gray-300"
            padding="py-3 px-4"
            type="secondary"
            @click="boxes = boxPreset"
          >
            {{ boxPreset }}
          </touch-optimized-button>
        </div>
      </div>

      <div class="flex flex-col flex-1">
        <number-input-element
          v-model="units"
          label="Unités"
          touch-optimized
        />

        <div class="flex flex-wrap">
          <touch-optimized-button
            v-for="unitPreset in unitPresets"
            :key="unitPreset"
            class="w-min border border-gray-400 bg-gray-300"
            padding="py-3 px-4"
            type="secondary"
            @click="units = unitPreset"
          >
            {{ unitPreset }}
          </touch-optimized-button>
        </div>
      </div>
    </div>

    <div class="flex gap-x-2">
      <touch-optimized-button
        :disabled="!canValidate"
        type="primary"
        @click="setReceivedQuantity"
      >
        Valider
      </touch-optimized-button>

      <touch-optimized-button type="dark" @click="$emit('cancel')">
        Annuler
      </touch-optimized-button>
    </div>
  </div>
</template>

<script>
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";

export default {
  name: "ProductIncomeManageProductInterface",
  components: {NumberInputElement, TouchOptimizedButton},
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    boxes: 1,
    onShelfStock: null,
    units: null,

    boxPresets: [2, 3, 4, 5, 6, 7, 8],
    unitPresets: [1, 4, 6, 8, 10, 12, 24]
  }),
  methods: {
    setReceivedQuantity() {
      SupplierOrderRepository
        .setReceivedQuantity(
          this.product.productId,
          this.getUnitSum
        ).then(() => {
        this.$emit('done', this.product.productId);
      })
    }
  },
  computed: {
    getProductImageUrl() {
      return getPrimaryImageUrlFromImageObject(
        this.product.images
      );
    },

    getSubTitleString() {
      const sd = this.getSellingData;

      return `ID: ${this.product.productId} - ${sd.bundle.quantity} x ${sd.bundle.content}${sd.bundle.unit}`;
    },
    getSellingData() {
      return this.product.sellingData;
    },
    getUnitSum() {
      if (this.units === null)
        return null;

      return this.boxes * this.units;
    },
    canValidate() {
      return this.getUnitSum !== null;
    }
  },
}
</script>