<template>
  <text-input-element
    :disabled="disabled"
    :inset-text="insetText"
    :label="label"
    :occupy-validation-space="occupyValidationSpace"
    :validation-rules="validationRules"
    :value="value"
    @blur="$emit('blur', $event)"
    @input="$emit('input', $event)"
  />
</template>

<script>
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";

export default {
  name: "VatInputElement",
  components: {TextInputElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Taux de TVA',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    insetText: {
      type: String,
      default: '%'
    }
  }
}
</script>

