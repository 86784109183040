import apiService from "@/repositories/Base/BaseRepository";

const url = '/notifications';

export default {

  async getPaginated(perPage = 50, page = 1, sorts = [], filters = []) {
    return await apiService
      .setSorts(sorts)
      .setPagination(perPage, page)
      .setFilters(filters)
      .get(`${url}`, {showLoadingIndicator: false, ignoreErrorMessageIfNoConnection: true});
  },

  async toggleRead(notificationId) {
    return await apiService
      .post(`${url}/${notificationId}/toggleRead`, null, {showLoadingIndicator: false});
  },

  async sendNotification(memberId, type, text) {
    return await apiService
      .post(`${url}`, {
        type: type,
        memberIds: [memberId],
        text: text
      })
  }
}
