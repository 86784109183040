<template>
  <div>
    <text-area-element
      ref="textElement"
      v-model="getCommentBeingEdited.text"
      :occupy-validation-space="false"
      :rows="4"
      label="Commentaire"
    />

    <date-picker-element
      v-model="getCommentBeingEdited.expiresAt"
      :occupy-validation-space="false"
      class="mt-2"
      label="Date d'expiration"
      with-time
    />

    <div class="flex justify-end mt-3.5">
      <button-element
        :loading="isLoading"
        size="xsmall"
        @click="updateComment"
      >
        <fa-icon class="mr-1.5" fixed-width icon="check"/>
        Sauvegarder
      </button-element>
    </div>
  </div>
</template>

<script>
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement.vue";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";
import {mapGetters} from "vuex";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "CommentBoxEdit",
  components: {ButtonElement, DatePickerElement, TextAreaElement},
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('comments', [
      'getCommentBeingEdited'
    ])
  },
  mounted() {
    this.$refs.textElement.focus();
  },
  methods: {
    updateComment() {
      this.isLoading = true;
      this.$store.dispatch('comments/updateComment', {
        text: this.getCommentBeingEdited.text,
        expiresAt: this.getCommentBeingEdited.expiresAt,
      }).finally(() => this.isLoading = false);
    }
  },
}
</script>