<template>
  <table-row>
    <table-data-cell>
      <member-quick-action-modal
        :id="suggestion.memberId"
      />
    </table-data-cell>
    <table-data-cell>{{ getMemberName }}</table-data-cell>
    <table-data-cell>{{ getInvoiceCount }}</table-data-cell>
    <table-data-cell>
      <money-amount
        :amount="getTotal"
      />
    </table-data-cell>
    <table-data-cell>
            <span
              v-for="(invoice, i) in getAllInvoicesMapped"
              :key="invoice.id"
              class="whitespace-nowrap"
            >
                <router-link
                  :to="{name: 'invoices.edit', params: {id: invoice.invoiceId}}"
                  class="text-luxcaddy-500 font-bold"
                >
                    &nbsp;{{ invoice.number }}
                </router-link>
                ({{ invoice.date }})
                <span v-if="getAllInvoicesMapped[i+1] !== undefined">, </span>
            </span>
    </table-data-cell>
    <table-data-cell>
      <div class="flex justify-end">
        <button-element
          :loading="loading"
          size="small"
          type="light"
          @click="validateSuggestion"
        >
          Valider
        </button-element>
      </div>
    </table-data-cell>
  </table-row>
</template>

<script>
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import AccountingBankMovementRepository from "@/repositories/AccountingBankMovementRepository";
import MoneyAmount from "@/components/global/Money/MoneyAmount";

export default {
  name: "BankMovementSuggestionItem",
  components: {MoneyAmount, ButtonElement, MemberQuickActionModal, TableDataCell, TableRow},
  props: {
    suggestion: {
      type: Object,
      required: true
    },
    bankMovementId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    validateSuggestion() {
      this.loading = true;
      AccountingBankMovementRepository
        .createAndLinkTransactions(
          this.bankMovementId,
          this.getPayloadToLink,
          true,
          false
        ).then(() => {
        this.$emit('linked');
        this.$sendSuccessToast('Lien crée.');
      }).finally(() => this.loading = false);
    },
  },
  computed: {
    getInvoices() {
      return this.suggestion.invoices;
    },
    getMemberName() {
      return this.getInvoices[0].memberName;
    },
    getInvoiceCount() {
      return this.getInvoices.length;
    },
    getTotal() {
      if (this.getInvoiceCount === 1) {
        return this.getInvoices[0].unpaidAmount;
      }
      return this.getInvoices.reduce((accumulator, invoice) => {
        return accumulator + invoice.unpaidAmount;
      }, 0);
    },
    getAllInvoicesMapped() {
      return this.getInvoices.map(i => {
        return {
          invoiceId: i.invoiceId,
          number: i.invoiceNumber,
          date: i.date
        }
      });
    },
    getPayloadToLink() {
      return this.suggestion.suggestedBankTransferTransactions;
    }
  },
}
</script>