import apiService from './Base/BaseRepository';

const url = '/products/reports';

export default {
  async getForValorluxPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/valorlux`);
  },

  async getExportForValorlux(
    sorts = [],
    filters = [],
  ) {
    return await apiService
      .setSorts(sorts)
      .setFilters(filters)
      .downloadBlob(`${url}/valorlux/export`);
  },


  async getForStatecPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/statec`);
  },

  async getExportForStatec(
    sorts = [],
    filters = [],
  ) {
    return await apiService
      .setSorts(sorts)
      .setFilters(filters)
      .downloadBlob(`${url}/statec/export`);
  },

}