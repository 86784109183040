<template>
  <div>
    <form-label
      v-if="label"
      :label="label"
      :show-required-mark="isInReadOnlyMode ? false : isRequired"
      class="mb-1"
    />

    <ValidationProvider
      ref="validationProvider"
      v-slot="{ errors }"
      :name="getName"
      :rules="validationRules"
      class="w-full"
    >
      <multiselect
        v-model="model"
        :allow-empty="allowUnselecting"
        :clear-on-select="false"
        :close-on-select="!multi"
        :custom-label="getCustomLabel"
        :disabled="disabled"
        :internal-search="false"
        :loading="isLoading"
        :multiple="multi"
        :options="getAvailableOptions"
        :placeholder="getNothingSelectedText"
        :searchable="enableSearch"
        :show-labels="false"
        label="label"
        preserve-search
        track-by="value"
        @search-change="onSearchChange"
      >
        <span slot="noResult">Pas d'options disponible</span>

        <template v-slot:option="{ option }">
          <div class="flex gap-x-2">
            <div
              v-if="isOptionPrioritized(option)"
              class="inline-block text-gray-400"
            >
              <fa-icon :icon="['far', 'star']" fixed-width/>
            </div>
            <div
              v-if="getOptionIcon(option)"
              class="inline-block"
            >
              <fa-icon :icon="getOptionIcon(option)" fixed-width/>
            </div>

            <!-- Label is split in multiple parts (object) -->
            <div
              v-if="hasOptionLabelMultipleParts(option.label)"
              class="flex gap-x-1.5"
            >
              <div
                :style="'width: ' + option.label.partOneSize + 'px'"
              >
                {{ option.label.partOne }}
              </div>
              <div>
                {{ option.label.partTwo }}
              </div>
            </div>

            <div
              v-else
            >
              {{ option.label }}
            </div>
          </div>
        </template>

        <span slot="noOptions">{{ getNoOptionsText }}</span>
      </multiselect>

      <template v-if="occupyValidationSpace">
        <input-error-message v-if="!isInReadOnlyMode" :errors="errors"/>
        <div v-else class="h-4"></div>
      </template>
    </ValidationProvider>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import FormLabel from "@/components/elements/forms/elements/partials/FormLabel.vue";
import {ValidationProvider} from "vee-validate";
import InputErrorMessage from "@/components/elements/forms/elements/partials/InputErrorMessage.vue";

export default {
  name: "SelectElement",
  components: {InputErrorMessage, FormLabel, Multiselect, ValidationProvider},
  data() {
    return {
      isSearching: false,
      filteredOptions: []
    }
  },
  props: {
    /**
     * Used for the validation provider
     */
    id: {
      type: String
    },
    /**
     * Model value
     */
    value: {
      type: [String, Number, Array, Object]
    },
    /**
     * Describes the purpose of the element.
     */
    label: {
      type: [String, Object],
    },
    /**
     * The values that can be selected from.
     * Format should be { label: "Test", value: "value" }
     */
    options: {
      type: Array,
      default: (() => [])
    },
    /**
     * Whether multiselect is possible.
     */
    multi: {
      type: Boolean,
      default: false
    },
    /**
     * If enabled, user can type to search
     */
    enableSearch: {
      type: Boolean,
      default: true
    },
    /**
     * If disabled, user can not change the value
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * If enabled, the user can unselect the currently selected option by clicking on it again.
     */
    allowUnselecting: {
      type: Boolean,
      default: true
    },
    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    /**
     * Rules used for validation using vee-validate
     */
    validationRules: {
      type: String,
      required: false,
    },
    /**
     * Text shown in the input when nothing has been selected.
     */
    nothingSelectedText: {
      type: String,
      default: 'Sélectionnez'
    },
    /**
     * Options that are shown on top of the list
     */
    prioritizedOptions: {
      type: Array,
      default: () => []
    },

    /**
     * Enabled = Searches instantly through available options
     * Disabled = Searches by updating the options from outside (async)
     */
    liveSearch: {
      type: Boolean,
      default: true
    },

    /**
     * When enabled, displays a loading indicator inside of the component
     * showing that more options matching the search are loaded async.
     */
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Since Vue-Multiselect is only able to save the options in v-model
     * by their whole object and not by their "track-by" value, we need to
     * have this logic below. Works for both single and multiselect.
     *
     * More on the logic here: https://github.com/shentao/vue-multiselect/issues/1464
     */
    model: {
      get: function () {
        if (this.value === null) {
          return null;
        }

        if (this.value && this.value instanceof Array && this.value.length > 0) {
          const selectedValue = this.value.map(option => {
            return option.value ? option.value : option;
          });

          return this.getTransformedOptions.reduce((result, item) => {
            return selectedValue.includes(item.value) ? [...result, item] : result;
          }, []);
        }


        return this.getTransformedOptions.reduce((result, item) => {
          return this.value === item.value ? [...result, item] : result;
        }, []);
      },
      set: function (value) {
        if (!this.multi) {
          const valueIsObject = value !== null && typeof value === "object" && value.value !== undefined;
          return this.$emit('input', valueIsObject ? value.value : value);
        }

        let items = value.map(item => {
          if (item !== null && typeof item === "object" && item.value !== undefined) {
            return item.value;
          }
          return item;
        })

        this.$emit("input", items);
      }
    },

    /**
     * Returns whether the options should be transformed based on whether the options
     * is a simple array, not containing objects.
     * When an array like ['String1', 'String2'] is transformed, the output will be
     * [
     *     {label: 'String1', value: 'String1'},
     *     {label: 'String2', value: 'String2'},
     * ]
     *
     * @returns boolean
     */
    optionsShouldBeTransformed() {
      return this.options.filter(option => typeof option !== "object").length === this.options.length;
    },

    /**
     * Determines if some values are prioritized and therefor should be sorted on top.
     */
    hasPrioritizedValues() {
      return this.prioritizedOptions.length;
    },

    getTransformedOptions() {
      let options = this.options;
      if (this.isSearching && this.liveSearch) {
        const combined = [...this.filteredOptions, ...this.options];
        options = Array.from(new Set(combined.map(option => option.value)))
            .map(value => {
                return combined.find(option => option.value === value);
            });
      }

      if (this.optionsShouldBeTransformed) {
        return options.map(o => {
          return {label: o.toString(), value: o};
        });
      }

      return options;
    },

    /**
     * Transforms the options into an array of objects, if it isn't already.
     * Also prioritizes the options passed in prioritizedOptions
     *
     * @returns Object
     */
    getAvailableOptions() {
      let options = this.getTransformedOptions;

      if (this.hasPrioritizedValues) {
        options = options.sort((firstOption, secondOption) => {
          const firstIndex = this.prioritizedOptions.indexOf(firstOption.value);
          const secondIndex = this.prioritizedOptions.indexOf(secondOption.value);
          return (
            (firstIndex > -1 ? firstIndex : Infinity) - (secondIndex > -1 ? secondIndex : Infinity)
          );
        });
      }

      // Check if the currently selected value is in the list.
      // If not add it, so the SelectElement is not shown as empty
      // Loading = async use
      if (this.value && (!Array.isArray(this.model))) {
        let found = options.find(o => o.value === this.model) !== undefined;
        if (!found) {
          if (this.isLoading) {
            options.push({
              label: "Téléchargement en cours...",
              value: this.value,
            });
          } else {
            options.push({
              label: this.value,
              value: this.value,
            });
          }
        }
      }

      return options;
    },

    /**
     * Used for the ValidationProvider
     * @returns {*|string|null}
     */
    getName() {
      if (this.id) {
        return this.id;
      }

      return this.label?.toLowerCase().replaceAll(' ', '-') || null;
    },
    /**
     * Whether the user has enabled Read only mode.
     * @returns {boolean}
     */
    isInReadOnlyMode() {
      return this.$isReadOnly() && !this.activeInReadOnly;
    },
    /**
     * Determines if the input is required to be filled out.
     * @return {boolean}
     */
    isRequired() {
      if (!this.validationRules) {
        return false;
      }

      let rules = this.validationRules.split('|');
      return rules.includes("required");
    },
    /**
     * Determines if the input should show validation errors in aggressive mode (red text).
     * @return {boolean}
     */
    aggressiveErrorDisplay() {
      return this.$store.getters['userInterface/isValidationInAggressiveErrorDisplayMode'];
    },

    /**
     * If there is an option with value null, we return the label of that.
     * Otherwise, we use the default text passed as prop.
     */
    getNothingSelectedText() {
      const option = this.getAvailableOptions.find(o => o.value === null);
      if (option) {
        return option.label;
      }

      // If we have a value selected that is not in the options (= preselected & async but not loaded yet)
      // we will show a placeholder Text saying "Loading..."
      if (this.value !== null && this.value !== undefined && this.isLoading) {
        return "Téléchargement en cours...";
      }

      return this.nothingSelectedText;
    },

    /**
     * The text displayed if no options are in the list.
     */
    getNoOptionsText() {
      if (this.isSearching) {
        return "Aucune option n'a été trouvée.";
      }

      if (!this.liveSearch) {
        return "Rechercher pour afficher les résultats";
      }

      return "La liste est vide";
    }
  },
  methods: {
    isOptionPrioritized(option) {
      return this.prioritizedOptions.length > 0 && this.prioritizedOptions.includes(option.value);
    },
    getOptionIcon(option) {
      if (option.icon !== undefined) {
        return option.icon;
      }
      return null;
    },

    hasOptionLabelMultipleParts(optionLabel) {
      return optionLabel !== null && typeof optionLabel === "object";
    },

    onSearchChange(text) {
      text =  text
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")  // Remove diacritical marks
          .toLowerCase();

      this.$emit('search', text);

      if (text === null || (typeof text === "string" && text === "")) {
        this.isSearching = false;
      } else {
         this.filteredOptions =  this.options.filter(option => {
           let label = option.label;
           if (this.hasOptionLabelMultipleParts(label)) {
               label = option.label.partOne + ' ' + option.label.partTwo;
           }
           return label.normalize("NFD")
                 .replace(/[\u0300-\u036f]/g, "")  // Remove diacritical marks
                 .toLowerCase().includes(
                 text
                     .normalize("NFD")
                     .replace(/[\u0300-\u036f]/g, "")  // Remove diacritical marks
                     .toLowerCase()

             )}
        )
        this.isSearching = true;
      }
    },

    getCustomLabel(option) {
      if (this.hasOptionLabelMultipleParts(option.label)) {
        return option.label.partOne + ' ' + option.label.partTwo;
      }

      return option.label;
    }
  },
  watch: {
      // Watcher on the search input to trigger normalization and filtering
      search(newVal) {
          this.onSearchChange(newVal);
      }
    }
}
</script>


<style>
fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 40px;
  height: 30px;
  background: #fff;
  display: block;
}

.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}

.multiselect__spinner:before {
  -webkit-animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.multiselect__spinner:after {
  -webkit-animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 12px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.multiselect {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 32px;
  text-align: left;
  color: #35495e;
  @apply shadow rounded-md focus:outline-none focus:ring focus:ring-luxcaddy-500;;
}

.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.multiselect:focus {
}

.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}

.multiselect--active {
  z-index: 50;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  height: 22px;
  line-height: 16px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 3px 0 7px 5px;
  width: 100%;
  -webkit-transition: border 0.1s ease;
  transition: border 0.1s ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  @apply truncate;
}

.multiselect__input::-webkit-input-placeholder {
  color: #35495e;
}

.multiselect__input::-moz-placeholder {
  color: #35495e;
}

.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}

.multiselect__input::-ms-input-placeholder {
  color: #35495e;
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  min-height: 32px;
  display: block;
  padding: 5px 40px 0 4px;
  border-radius: 5px;
  font-size: 12px;
  @apply border border-gray-200 bg-white;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 3px;
  color: #fff;
  line-height: 1;
  @apply bg-luxcaddy-400;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 18px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}

.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}

.multiselect__current,
.multiselect__select {
  line-height: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}

.multiselect__select {
  position: absolute;
  width: 40px;
  height: 100%;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 55%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 3px;
  padding-top: 2px;
  margin-left: 8px;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 20px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  @apply bg-luxcaddy-100 text-black;
  outline: none;
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff;
}

.multiselect__option--selected {
  @apply bg-gray-200;
  color: #35495e;
  font-weight: 700;
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
  background: inherit;
}

.multiselect__option--selected.multiselect__option--highlight {
  /*@apply bg-red-300;*/
  /*color: #fff;*/
}

.multiselect__option--selected.multiselect__option--highlight:after {
  @apply bg-red-300;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  @apply bg-red-300;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  @apply bg-red-300;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn);
  }
}

@keyframes spinning {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn);
  }
}
</style>