import apiService from "./Base/BaseRepository";

const url = '/timeSlots';

export default {
  /**
   * Returns the zone & available timeslots.
   *
   * @param zone
   * @param payload
   * @returns {Promise<*>}
   */
  async getForZone(zone, payload) {
    return await apiService.get(`${url}/`, {
      params: {
        zone: zone,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    });
  },

  async getTotalsForDate(date) {
    const startDate = date + ' 00:00:00';
    const endDate = date + ' 23:59:59';
    return this.getForZone(1, {
      startDate: startDate,
      endDate: endDate
    })
  },

  /**
   *
   * @param zone
   * @param startDate
   * @param endDate
   * @param deliveryLimit
   * @return {Promise<AxiosResponse<any>>}
   */
  async create({zone, startDate, endDate, deliveryLimit}) {
    return await apiService.post(`${url}/`, {
      zone: zone,
      startDate: startDate,
      endDate: endDate,
      deliveryLimit: deliveryLimit,
    });
  },

  /**
   *
   * @param timeSlotId
   * @param zone
   * @param startDate
   * @param endDate
   * @param deliveryLimit
   * @return {Promise<AxiosResponse<any>>}
   */
  async update(timeSlotId, {startDate, endDate, deliveryLimit}) {
    return await apiService.patch(`${url}/${timeSlotId}`, {
      startDate: startDate,
      endDate: endDate,
      deliveryLimit: deliveryLimit,
    });
  },

  /**
   *
   * @param timeSlotId
   * @return {Promise<AxiosResponse<any>>}
   */
  async delete(timeSlotId) {
    return await apiService.delete(`${url}/${timeSlotId}`);
  },


  /**
   *
   * @return {Promise<AxiosResponse<any>>}
   * @param weekToCopy
   * @param year
   * @param copyToStartDate
   * @param copyToEndDate
   */
  async bulkCopy(weekToCopy, year, copyToStartDate, copyToEndDate) {
    return await apiService.post(`${url}/bulk-copy`, {
      weekToCopy, year, copyToStartDate, copyToEndDate
    });
  },

  /**
   *
   * @param date
   * @returns {Promise<*>}
   */
  async closeDay(date) {
    return await apiService.patch(`${url}/close-day`, {
      date: date
    })
  }
}
