<template>
    <vue-apex-charts
            :options="getChartOptions"
            :series="series"
            :type="getType"
            height="100%"
    />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: "DynamicChart",
    components: {VueApexCharts},
    props: {
        config: {
            type :Object,
            mandatory: true
        },
        series: {
            type: Array,
            required: true
        },
        colors: {
            type: Array,
            default: () => [
                '#7E9036', '#F9844A', '#d7484b', '#4D908E', '#F9C74F', '#277DA1',
                '#F8961E', '#90BE6D', '#F3722C', '#43AA8B', '#577590',
            ]
        },
    },
    computed: {
        getChartOptions() {
            let options = this.config?.options || {};

            if (options.colors === undefined) {
                this.$set(options, 'colors', this.colors);
            }

            if (options?.dataLabels?.append) {
                let appendToEnd =  options.dataLabels.append?.end || '';
                let appendToStart =  options.dataLabels.append?.start || '';

                this.$set(options.dataLabels, 'formatter', function(val) {
                    return appendToStart + ' ' + val + ' ' + appendToEnd;
                });
            }
            return options;
        },
        getType() {
            if (this.config.type !== undefined) {
                return this.config.type;
            }

            if (this.config?.options?.chart?.type !== undefined ) {
                return this.config?.options?.chart?.type;
            }

            return 'bar'
        }
    }
}
</script>

<style scoped>

</style>