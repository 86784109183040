import Vue from 'vue';
import dayjs from 'dayjs';

import 'dayjs/locale/fr';

/**
 * Plugins
 */
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isBetween = require('dayjs/plugin/isBetween');
const isToday = require('dayjs/plugin/isToday');
const isYesterday = require('dayjs/plugin/isYesterday');
const relativeTime = require('dayjs/plugin/relativeTime');
const customParseFormat = require('dayjs/plugin/customParseFormat');


dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

dayjs.locale('fr');

/**
 * Append Dayjs to Vue
 */

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs
    }
  }
});

export {dayjs};