<template>
  <div>
    <div class="flex justify-between items-center gap-x-6 flex-wrap mb-4">

      <div class="flex gap-x-4 items-center flex-col md:flex-row">
        <page-title without-margin>
          <template v-if="getInvoice">
                        <span>
                            {{ isCreditNote ? 'Note de crédit' : 'Facture' }}
                            {{ getInvoiceType }} {{ isInvoiceInternal ? 'interne' : 'externe' }}
                        </span>
            <span v-if="getInvoice">
                            #{{ getInvoice.id }}
                            </span>
          </template>
          <span v-else>[...]</span>
        </page-title>

        <invoice-status :status="getStatus"/>
        <payment-invoice-status :status="getInvoice && getInvoice.paymentStatus"/>
      </div>

      <div class="flex items-center gap-4 flex-wrap">
        <template v-if="getInvoice">

          <!-- Internal Invoice -->
          <template v-if="isInvoiceInternal">
            <button-element
              :disabled="!isFinalized"
              type="dark"
              @click="downloadInvoicePdf"
            >
              <fa-icon class="mr-2" fixed-width icon="file-pdf"/>
              PDF [Luxcaddy]
            </button-element>

            <button-element
              :disabled="!isFinalized"
              type="dark"
              @click.prevent="downloadInvoicePdf($event, true)"
            >
              <fa-icon class="mr-2" fixed-width icon="file-pdf"/>
              PDF [Itix]
            </button-element>
          </template>

          <template v-if="!isSupplierInvoiceType">

            <button-element
              :disabled="!isFinalized"
              type="dark"
              @click="sendEInvoice()"
            >
              E-Invoice
            </button-element>
          </template>

          <pay-by-wallet-button/>

          <invoice-finalize-button
            :disabled="isFinalized || $isReadOnly()"
            :is-accounting-interface="isAccountingInterface"
          />

          <batch-actions-single-model
            v-if="getInvoice && isAccountingInterface"
            :batch-actions="InvoiceBatchActions.batchActions"
            :selected-value="InvoiceBatchActions.selectValuesFromId($route.params.id)"
            @executed="fetchInvoice"
          />
        </template>
      </div>
    </div>

    <div class="bg-white border p-4 mb-4">
      <div class="flex gap-x-6">

        <div v-if="getInvoice" class="flex gap-x-8 items-center">
          <span class="font-bold">N° facture: {{ getInvoice.invoiceNumber }}</span>
        </div>

        <div class="flex gap-x-8 items-center">
          <span class="font-bold">ID membre:</span>
          <member-quick-action-modal
            v-if="getMemberId"
            :id="getMemberId"
          />
        </div>

        <div class="flex gap-x-8 items-center">
          <span class="font-bold">ID Commande:</span>
          <text-link
            v-if="getOrderId !== null"
            :to="{name: 'orders.edit', params: {order: getOrderId}}"
          >
            {{ getOrderId }}
          </text-link>
          <span v-else>/</span>
        </div>

      </div>
    </div>
    <card :isError="getInvoice && !isInvoiceInternal  && getInvoice.isError" size="small">
      <card-tab id="invoiceSettings" icon="cog" title="Données de facturation">
        <div
          :class="[isTreatingInvoicePDF ? 'grid-cols-1 3xl:grid-cols-2' : 'grid-cols-1']"
          class="grid gap-4"
        >
          <!-- Left col if treating pdf, otherwise full width -->
          <div
            :class="[isTreatingInvoicePDF ? 'col-span-1 3xl:col-span-1' : 'col-span-1']"
          >
            <!-- Z-index required, otherwise pdf will render over the modal ?! -->
            <div
              :class="{'z-10': !$store.getters['invoices/isInvoiceItemModalOpen']}"
              class="sticky top-6"
            >
              <form-wrapper
                ref="form"
                :show-changed="true"
                :show-submit-button="isInvoiceInternal ? !isFinalized : true"
                submit-button-label="Sauvegarder"
                @submit="updateInvoice"
              >
                <template
                  v-if="getInvoice !== null"
                >
                  <div class="grid grid-cols-1 lg:grid-cols-6 gap-4">
                    <text-input-element v-model="getInvoice.invoiceNumber"
                                        :disabled="isInvoiceInternal"
                                        label="Numéro de facture"
                    />
                    <date-picker-element v-model="getInvoice.date"
                                         :disabled="isInvoiceInternal && isFinalized"
                                         label="Date de facturation"/>

                    <date-picker-element
                      v-model="getInvoice.dueDate"
                      :disabled="isInvoiceInternal && isFinalized"
                      :occupy-validation-space="false"
                      label="Date d'écheance"
                      show-set-null-button
                    />

                    <text-input-element v-model="getInvoice.companyName"
                                        :disabled="isInvoiceInternal && isFinalized"
                                        label="Société"/>
                    <text-input-element v-model="getInvoice.vatNumber"
                                        :disabled="isInvoiceInternal && isFinalized"
                                        class="lg:col-span-2"
                                        label="Numero TVA"/>

                  </div>

                  <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
                    <title-select-input v-model="getInvoice.title"
                                        :disabled="isInvoiceInternal && isFinalized"/>
                    <text-input-element v-model="getInvoice.familyName"
                                        :disabled="isInvoiceInternal && isFinalized"
                                        label="Nom"/>
                    <text-input-element v-model="getInvoice.firstName"
                                        :disabled="isInvoiceInternal && isFinalized"
                                        label="Prénom"/>

                  </div>

                  <div class="grid grid-cols-1 lg:grid-cols-6 gap-4">
                    <text-input-element v-model="getInvoice.street"
                                        :disabled="isInvoiceInternal && isFinalized"
                                        class="lg:col-span-2"
                                        label="Rue et numéro"
                                        validation-rules="required"/>
                    <text-input-element v-model="getInvoice.city"
                                        :disabled="isInvoiceInternal && isFinalized"
                                        class="lg:col-span-2"
                                        label="Ville"
                                        validation-rules="required"/>

                    <text-input-element
                      v-model="getInvoice.postalCode"
                      :disabled="isInvoiceInternal && isFinalized"
                      label="Code postal"
                      validation-rules="required|max:10"
                    />

                    <country-select-input v-model="getInvoice.country"
                                          :allow-unselecting="false"
                                          :disabled="isInvoiceInternal && isFinalized"
                                          validation-rules="required"/>
                  </div>

                  <!-- Internal invoice only -->
                  <div v-if="!isInvoiceInternal" class="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-4">
                    <select-element
                      v-model="getInvoice.currency.code"
                      :enable-search="false"
                      :options="getCurrenciesList"
                      label="Devise"
                    />

                    <text-input-element
                      v-model="getInvoice.currencyToEuroConversionRate"
                      :disabled="getInvoice.currency.code === 'EUR'"
                      label="Taux de conversion en EUR"
                    />

                    <currency-input-element
                      v-model.number="getInvoice.originalTotalAmount"
                      :currency="getInvoice.currency.code"
                      :inset-text="getSelectedCurrencySymbol"
                      allow-negative
                      label="Montant total"
                    />

                    <currency-input-element
                      v-model.number="getInvoice.vatCorrectorAmount"
                      :inset-text="getSelectedCurrencySymbol"
                      allow-negative
                      label="Montant corr. TVA"
                    />
                  </div>
                </template>
              </form-wrapper>

              <!-- Invoice Items -->
              <invoice-item-table
                v-if="getInvoice !== null"
                :is-accounting-interface="isAccountingInterface"
                class="my-6 "
              />

              <show-payments-table
                v-if="getInvoice !== null"
                :filter-key="getPaymentFilterKey"
                :filter-value="getInvoice.id"
                :show-actions="true"
                table-identifier="showInvoiceTable"
                @change="onChange"
              />
            </div>
          </div>

          <!-- PDF Should only have a high z index on item modal open -->
          <div
            v-if="isTreatingInvoicePDF"
            :class="{'z-9999': $store.getters['invoices/isInvoiceItemModalOpen']}"
            class="3xl:col-span-1"
          >
            <external-invoice-pdf-card
              v-if="externalInvoicePDF !== null"
              :external-invoice-pdf="externalInvoicePDF"
              :initial-page="$route.query.pdfPage ? Number($route.query.pdfPage) : undefined"
              :initial-scroll-left="$route.query.pdfScrollLeft ? Number($route.query.pdfScrollLeft) : undefined"
              :initial-scroll-top="$route.query.pdfScrollTop ? Number($route.query.pdfScrollTop) : undefined"
              :initial-zoom="$route.query.pdfZoom ? Number($route.query.pdfZoom) : undefined"
              :show-delete-button="false"
              :show-treat-buttons="false"
              height="h-screen-65"
              @request-reload="fetchExternalInvoicePDF"
            >
              <template #additionalFooterButtons>
                <button-element
                  v-if="isTreatingInvoicePDF"
                  size="xsmall"
                  type="danger"
                  @click.prevent="unlinkExternalInvoicePdf"
                >
                  <fa-icon class="mr-2" fixed-width icon="unlink"/>
                  Détacher de cette facture
                </button-element>
              </template>
            </external-invoice-pdf-card>
          </div>
        </div>
      </card-tab>

      <card-tab id="paymentsLog" icon="file-alt" title="Payments Log">
        <show-payments-log-table
          v-if="getInvoice !== null"
          :filter-key="getPaymentFilterKey"
          :filter-value="getInvoice.id"
          :invoice-id="getInvoice.id"
        />
      </card-tab>

      <card-tab id="invoiceLogs" icon="file-alt" title="Logs">
        <show-logs-table
          v-if="getInvoice !== null"
          :default-loggable-id="getInvoice.id"
          default-loggable-type="Invoice"
        />
      </card-tab>

    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import TitleSelectInput from "@/components/elements/forms/elements/TitleSelectInput";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import CountrySelectInput from "@/components/elements/forms/elements/CountrySelectInput";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import {mapActions, mapGetters, mapMutations} from "vuex";
import InvoiceItemTable from "@/components/pages/Invoices/Shared/EditInvoice/Includes/Table/InvoiceItemTable";
import InvoiceFinalizeButton
  from "@/components/pages/Invoices/Shared/EditInvoice/Includes/FinalizeInvoice/InvoiceFinalizeButton";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import ShowLogsTable from "@/components/pages/Logs/ShowLogsTable";
import ShowPaymentsTable from "@/components/pages/Invoices/Shared/EditInvoice/Payments/ShowPaymentsTable";
import ShowPaymentsLogTable from "@/components/pages/Invoices/Shared/EditInvoice/Payments/ShowPaymentsLogTable";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import CurrencyInputElement from "@/components/elements/forms/elements/CurrencyInputElement";
import {exportResponse} from "@/helpers/exportHelper";
import InvoiceStatus from "@/components/pages/Invoices/Shared/Misc/InvoiceStatus";
import PaymentInvoiceStatus from "@/components/pages/Invoices/Shared/Misc/PaymentInvoiceStatus";
import MemberQuickActionModal from "../../../Members/Subcomponents/MemberQuickActionModal";
import TextLink from "../../../../global/TextLink";
import BatchActionsSingleModel from "@/batchAction/components/BatchActionsSingleModel.vue";
import InvoiceBatchActions from "@/batchAction/invoiceBatchActions";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import ExternalInvoicePdfCard
  from "@/components/pages/Accounting/ExternalInvoicePDFs/Sub/UnlinkedList/Sub/ExternalInvoicePdfCard.vue";
import ExternalInvoicePDFRepository from "@/repositories/ExternalInvoicePDFRepository";
import PayByWalletButton
  from "@/components/pages/Invoices/Shared/EditInvoice/Includes/PayByWallet/PayByWalletButton.vue";

export default {
  name: "SharedEditInvoice",
  components: {
    PayByWalletButton,
    ExternalInvoicePdfCard,
    SelectElement,
    BatchActionsSingleModel,
    CurrencyInputElement,
    ShowLogsTable,
    CardTab,
    InvoiceFinalizeButton,
    InvoiceItemTable,
    FormWrapper,
    DatePickerElement,
    CountrySelectInput,
    TextInputElement,
    TitleSelectInput,
    Card,
    PageTitle,
    ShowPaymentsTable,
    ShowPaymentsLogTable,
    ButtonElement,
    InvoiceStatus,
    PaymentInvoiceStatus,
    MemberQuickActionModal,
    TextLink,
  },
  created() {
    this.$store.commit('invoices/setExternalInvoicePDFIdBeingTreated', null);
    this.fetchInvoice().then(() => {
      if (this.getExternalInvoicePDFIdBeingTreated !== null) {
        this.fetchExternalInvoicePDF();
      }
    });
  },
  data() {
    return {
      externalInvoicePDF: null
    }
  },
  props: {
    invoiceId: {
      type: [Number, String],
      required: true
    },
    isInternal: {
      type: Boolean,
      default: false
    },
    isAccountingInterface: {
      type: Boolean,
      default: false
    }
  },
  beforeCreate() {
    this.$store.commit('invoices/setInvoice', null);
    this.$store.commit('invoices/setExternalInvoicePDFIdBeingTreated', null);
  },
  methods: {
    ...mapMutations('invoices', ['setInvoice']),
    ...mapActions('invoices', ['refetchCurrentInvoice']),
    onChange() {
      this.refetchCurrentInvoice()
    },
    fetchInvoice() {
      return this.$store.dispatch('invoices/fetchInvoice', {
        isInternal: this.isInternal,
        invoiceId: this.invoiceId
      }).then(() => {
        this.resetFormValidation();
      });
    },
    fetchExternalInvoicePDF() {
      ExternalInvoicePDFRepository
        .getSingle(this.getExternalInvoicePDFIdBeingTreated)
        .then((res) => {
          this.externalInvoicePDF = res.data.data;
        });
    },
    resetFormValidation() {
      this.$nextTick(() => {
        if (typeof this.$refs.form != 'undefined') {
          this.$refs.form.resetForm();
        }
      });
    },
    payByWallet() {
      if (!this.isInvoiceInternal)
        return false;

      InvoiceRepository.internal.payments.payByWallet(
        this.$route.params.id
      ).then(() => {
        this.$sendSuccessToast("Paiement traité!");
        this.refetchCurrentInvoice();
      })
    },
    sendEInvoice() {
      InvoiceRepository.internal.eInvoice(
        this.$route.params.id
      ).then(() => {
        this.$sendSuccessToast("Facture envoye!");
      })
    },
    updateInvoice(callback) {
      let req = null;

      if (this.isInvoiceInternal) {
        req = InvoiceRepository.internal.update(this.getInvoice.id, this.getPayload);
      } else {
        req = InvoiceRepository.external.update(this.getInvoice.id, this.getPayload);
      }

      return req.then(() => {
        this.$sendSuccessToast("Facture modifiée.");
        this.refetchCurrentInvoice().then(() => {
          this.resetFormValidation();
        });
      }).finally(() => callback());
    },
    downloadInvoicePdf($clickEvent, $issuedByItix = false) {
      return InvoiceRepository
        .internal
        .downloadPdf(this.getInvoice.id, $issuedByItix)
        .then(res => {
          exportResponse(res, `invoice-${this.getInvoice.invoiceNumber || ''}`, 'pdf');
        });
    },
    /**
     * Remove the Link Between an external Invoice and an external Invoice PDF
     */
    unlinkExternalInvoicePdf() {
      return InvoiceRepository
        .external
        .removeLinkToExternalInvoicePDF(this.getInvoice.id)
        .then(() => {
          this.$sendSuccessToast("Facture modifiée.");

          this.refetchCurrentInvoice().then(() => {
            this.resetFormValidation();
          });
        })
    }
  },
  computed: {
    InvoiceBatchActions() {
      return InvoiceBatchActions
    },
    ...mapGetters('invoices', [
      'getInvoice',
      'getOrderId',
      'getMemberId',
      'isInvoiceInternal',

      'isTreatingInvoicePDF',
      'getExternalInvoicePDFIdBeingTreated',
    ]),
    getStatus() {
      return this.getInvoice?.status;
    },
    isFinalized() {
      return this.getStatus === "finalized";
    },
    isCreditNote() {
      return this.getInvoice?.isCreditNote || false;
    },
    isPaid() {
      return this.getInvoice?.paymentStatus === 'paid';
    },
    getPayload() {
      let payload = {
        memberId: this.getInvoice.memberId,
        date: this.getInvoice.date,
        dueDate: this.getInvoice.dueDate,
        title: this.getInvoice.title,
        firstName: this.getInvoice.firstName,
        familyName: this.getInvoice.familyName,
        companyName: this.getInvoice.companyName,
        street: this.getInvoice.street,
        postalCode: this.getInvoice.postalCode,
        city: this.getInvoice.city,
        country: this.getInvoice.country,
        vatNumber: this.getInvoice.vatNumber,
        invoiceNumber: this.getInvoice.invoiceNumber
      };

      if (!this.isInvoiceInternal) {
        payload.currency = this.getInvoice.currency.code;
        payload.currencyToEuroConversionRate = this.getInvoice.currencyToEuroConversionRate;
        payload.originalTotalAmount = this.getInvoice.originalTotalAmount;
        payload.vatCorrectorAmount = this.getInvoice.vatCorrectorAmount;
      }

      return payload;
    },
    getPaymentFilterKey() {
      return "invoiceId"
    },
    getInvoiceType() {
      if (this.getInvoice.type === 'supplier') {
        return 'fournisseur';
      }

      return 'client';
    },
    getCurrenciesList() {
      return this
        .$luxcaddyConfig('invoice.external.availableCurrencies')
        .map(c => ({label: c.currency, value: c.currency}));
    },
    getSelectedCurrencySymbol() {
      return this
        .$luxcaddyConfig('invoice.external.availableCurrencies')
        .find(c => c.currency === this.getInvoice.currency.code)
        .symbol;
    },

    isSupplierInvoiceType() {
      if (this.getInvoice.type === 'supplier') {
        return true;
      }

      return false;
    }
  },
}
</script>

