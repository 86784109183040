export const menuLinks = [
  {
    name: "Mon espace",
    icon: "user",
    extraClasses: "",
    pages: [
      {name: "Mes routes", route: {name: "touchInterface.delivery.fulfilment.myroutes"}},
      {name: "Mes tâches", route: {name: 'touchInterface.myspace.tasks.index'}},
      {name: "Mes congés", route: {name: 'touchInterface.myspace.holidays.index'}},
      {name: "Mes notifications", route: {name: 'touchInterface.myspace.notifications.index'}},
    ]
  },
  {
    name: "Packaging",
    icon: "box",
    extraClasses: "",
    pages: [
      {name: "Préparation", route: {name: 'touchInterface.fulfilment.orders'}},
      {name: "Picking", route: {name: 'touchInterface.packaging.picking.index'}},
      {name: "Emballages Check", route: {name: 'touchInterface.delivery.barcodeCheck.index'}},

    ],
    permissions: ['FULFILMENT_READ']
  },
  {
    name: "Livraison",
    icon: "truck",
    extraClasses: "",
    pages: [
      {name: "Imprimer Etiquettes", route: {name: 'touchInterface.delivery.fulfilment.printlabels'}},
      {name: "Route check", route: {name: "touchInterface.delivery.fulfilment.routecheck.index"}},
    ],
    permissions: ['DELIVERY_READ']
  },
  {
    name: "Stock",
    icon: "boxes",
    extraClasses: "",
    pages: [
      {name: "Arrivage", route: {name: 'touchInterface.stock.income.index'}},
      {name: "Arrivage d'aujourd'hui", route: {name: 'touchInterface.stock.income-today.index'}},
      {name: "Scan", route: {name: 'touchInterface.stock.scan-edit.index'}},
      {name: "Fruits & Légumes", route: {name: 'touchInterface.stock.fruits-vegetables.index'}},
      {name: "Temperatures", route: {name: 'touchInterface.stock.arrival-temperatures.index'}},
    ],
    permissions: ['FULFILMENT_READ']
  }
];