// noinspection DuplicatedCode

import apiService from './Base/BaseRepository';

const url = '/timeSlots';


export default {
  /**
   * Fetches all timeSlots in a given span for a given zone.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getInSpan(zone, startDate, endDate) {
    return await apiService.get(`${url}?zone=${zone}&startDate=${startDate}&endDate=${endDate}`);
  },
}
