/**
 * Redirects to the login if the user is not logged in.
 *
 * @param next
 * @param router
 * @return {*}
 */
export default function auth({next, router}) {
  if (!localStorage.getItem('user')) {
    return router.push({name: 'auth.login'});
  }
  return next();
}