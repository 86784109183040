<template>
  <div>
    <custom-table class="shadow-none border border-2">
      <table-row>
        <table-data-cell class="font-bold">Réference</table-data-cell>
        <table-data-cell>{{ getBankMovement.reference }}</table-data-cell>
        <table-data-cell class="font-bold">Compte</table-data-cell>
        <table-data-cell>{{ getBankMovement.account }}</table-data-cell>
      </table-row>
      <table-row>
        <table-data-cell class="font-bold">Montant</table-data-cell>
        <table-data-cell>
          <money-amount
            :amount="getBankMovement.amount"
            :currency="getBankMovement.currency"
          />
        </table-data-cell>
        <table-data-cell class="font-bold">Frais</table-data-cell>
        <table-data-cell>
          <money-amount
            :amount="getBankMovement.fees.amount"
            :currency="getBankMovement.fees.currency"
          />
        </table-data-cell>
      </table-row>
      <table-row>

        <table-data-cell class="font-bold">Type</table-data-cell>
        <table-data-cell>{{ getBankMovement.type }}</table-data-cell>
        <table-data-cell class="font-bold">De</table-data-cell>
        <table-data-cell>{{ getBankMovement.from }}</table-data-cell>
      </table-row>
      <table-row>
        <table-data-cell class="font-bold">Description</table-data-cell>
        <table-data-cell colspan="3">{{ getBankMovement.description }}</table-data-cell>
      </table-row>
      <table-row>
        <table-data-cell class="font-bold">Date</table-data-cell>
        <table-data-cell>
          {{ $date(getBankMovement.date).format('DD.MM.YYYY') }}
        </table-data-cell>
        <table-data-cell class="font-bold">Accounting date</table-data-cell>
        <table-data-cell>
          {{
            $date(getBankMovement.accountingDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss']).format('DD.MM.YYYY')
          }}
        </table-data-cell>
      </table-row>
    </custom-table>

    <card v-if="canSetDirectBooking" class="mt-4">
      <h3 class="font-bold mb-2">
        Booking directe
      </h3>

      <form-wrapper
        ref="form"
        :show-submit-button="canSetDirectBooking"
        :use-button-spacing="false"
        show-changed
        @submit="setDirectBooking"
      >
        <div class="flex gap-4 flex-wrap items-center">
          <vat-input-element
            v-model="getBankMovement.vatRate"
            :disabled="!canSetDirectBooking"
            class="flex-1"
          />
          <vat-codes-select-input
            v-model="getBankMovement.vatCode"
            :disabled="!canSetDirectBooking"
            class="flex-1"
          />

          <booking-account-select-input
            v-model="getBankMovement.bookingAccount"
            :disabled="!canSetDirectBooking"
            :multi="false"
            class="flex-1"
          />

          <date-picker-element
            v-model="getBankMovement.accountingDate"
            :disabled="!canSetDirectBooking"
            class="flex-1"
            label="Date comptable"
          />
        </div>


        <template #additionalButtons>
          <button-element
            :disabled="!canSetDirectBooking || !isDirectBookingSet"
            class="mr-3"
            type="light"
            @click="removeDirectBooking"
          >
            Supprimer
          </button-element>
        </template>
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import CustomTable from "@/components/elements/tables/CustomTable";
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import VatInputElement from "@/components/elements/forms/elements/VatInputElement.vue";
import VatCodesSelectInput from "@/components/elements/forms/elements/VatCodesSelectInput";
import BookingAccountSelectInput from "@/components/elements/forms/elements/BookingAccountSelectInput";
import Card from "@/components/elements/cards/Card";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import MoneyAmount from "@/components/global/Money/MoneyAmount";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";

export default {
  name: "BankMovementDetails",
  components: {
    DatePickerElement,
    MoneyAmount,
    ButtonElement,
    FormWrapper,
    Card,
    BookingAccountSelectInput,
    VatCodesSelectInput,
    VatInputElement,
    TableDataCell,
    TableRow,
    CustomTable
  },
  computed: {
    ...mapGetters('bankMovements', [
      'getBankMovement',
      'hasLinkedPaymentTransactions',
      'getLinkedPaymentTransactionCount',
      'isDirectBookingSet'
    ]),
    canSetDirectBooking() {
      return this.getLinkedPaymentTransactionCount === 0;
    }
  },
  methods: {
    ...mapMutations('bankMovements', [
      'removeDirectBooking'
    ]),
    setDirectBooking(callback) {
      this.$store.dispatch('bankMovements/setDirectBooking').then(() => {
        this.$refs.form.resetForm();
        callback();

        // Booking Removed
        if (!this.isDirectBookingSet) {
          this.$sendSuccessToast('Le statut de booking directe a été supprimé.');
          return;
        }

        this.$sendSuccessToast('Mouvement bancaire marqué comme traité à l\'aide d\'un booking directe.');
      }).finally(() => callback());
    },
  },
}
</script>

<style scoped>

</style>