<template>
  <tr class="border-b border-gray-200 hover:bg-gray-100" @click="$emit('click', $event)">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "TableRow"
}
</script>

