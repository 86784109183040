<template>
  <div>
    <text-input-element ref="input" v-model="searchTerm"
                        label="Recherche"
                        placeholder="Entrez un terme de recherche pour filtrer les frais..."></text-input-element>

    <horizontal-scroll>
      <transition-group v-if="getFees" class="flex flex-row gap-x-6" name="fade-up-down">
        <fee-card v-for="fee in getFees" :key="fee.id" :fee="fee" @added="onFeeAdded"></fee-card>
      </transition-group>
    </horizontal-scroll>
  </div>
</template>

<script>
import FeeRepository from "../../../../../repositories/FeeRepository";
import HorizontalScroll from "../../../../elements/scroll/HorizontalScroll";
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";
import FeeCard from "./FeeCard";

export default {
  name: "AddFeeToOrder",
  components: {FeeCard, TextInputElement, HorizontalScroll},
  data: () => ({
    searchTerm: '',
    fees: []
  }),
  mounted() {
    this.fetchFees();
  },
  methods: {
    fetchFees() {
      FeeRepository.get(false).then((res) => {
        this.fees = res.data.data;
      });
    },
    /**
     * Executed when a fee has been added to the order.
     */
    onFeeAdded() {
      this.searchTerm = '';
      this.$nextTick(() => this.$refs.input.focus());
      this.$emit('added');
    }
  },
  computed: {
    getFees() {
      if (!this.searchTerm.length) {
        return this.fees;
      }

      return this.fees.filter(f => f.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  },
}
</script>

