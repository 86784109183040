import AuthRepository from "@/repositories/AuthRepository";
import store from "@/store/store";

export default {
  login(email, password) {
    return AuthRepository.getSanctumCsrfCookie().then(() => {
      return AuthRepository.login(email, password).then((res) => {
        const data = res.data.data;
        store.commit('listTable/clearData');
        store.commit('listTable/filters/clearFilters');
        this.saveAuth(data.user);
      });
    });
  },

  loginByToken(token) {
    return AuthRepository.getSanctumCsrfCookie().then(() => {
      return AuthRepository.loginByToken(token).then((res) => {
        const data = res.data.data;
        store.commit('listTable/clearData');
        store.commit('listTable/filters/clearFilters');
        this.saveAuth(data.user);
      });
    });
  },

  saveAuth(userData) {
    store.commit('authUser/setUser', userData);
    store.commit('lockScreen/unlockScreen');
  },

  logout() {
    return AuthRepository.logout().finally(() => {
      // Exit Fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      store.commit('authUser/clearUser');
    });
  },
}