/**
 * Wait ${delay} (milliseconds) before performing ${fn} and abort when
 * this is triggered again withing ${delay} ms.
 *
 * @param fn
 * @param delay
 * @return {(function(): void)|*}
 */
export function debounce(fn, delay = 300) {
  let timeoutID = null;

  return function () {
    clearTimeout(timeoutID)
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay)
  }
}

/**
 * Perform ${fn} instantly and ignore subsequent requests to perform ${fn} within a delay of ${delay}.
 * This is useful for example for submit buttons where you dont want to send a request twice when the user accidentally double clicks.
 *
 * @return {(function(...[*]=): void)|*}
 * @param fn
 * @param delay
 */
export function debounceLeading(fn, delay = 300) {
  let timer;
  return function (...args) {
    if (!timer) {
      fn.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(function () {
      timer = undefined;
    }, delay);
  };
}