<template>
  <show-payments-log-table :show-pagination="true"/>

</template>

<script>

import ShowPaymentsLogTable from "@/components/pages/Invoices/Shared/EditInvoice/Payments/ShowPaymentsLogTable";

export default {
  name: "ShowPaymentsLog",
  components: {ShowPaymentsLogTable}

}
</script>

<style scoped>

</style>