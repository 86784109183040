import RouteManager from "@/components/pages/RouteManager/RouteManager";
import auth from "@/router/middlewares/authMiddleware";

export default [
  {
    path: '/route-manager',
    name: 'route-manager.index',
    component: RouteManager,
    meta: {
      middleware: [auth],
      title: 'Route Manager',
      showBreadcrumbBar: false,
      padding: false,
      closeSidebarOnNavigate: true,
      permissions: ['ACCESS_ROUTE_VIEW']
    }
  },
]