<template>
  <div>
    <button-element
      type="dark"
      @click="openModal"
    >
      <fa-icon fixed-width icon="check"/>
      Finaliser et envoyer commande
    </button-element>

    <modal
      ref="modal"
      :show-close-button="true"
      title="Finaliser commande"
    >
      <div >
          <div class="mb-2" >
              Êtes vous sûre que vous voulez finaliser et envoyer cette commande?<br>
              <div class="font-bold mt-4">
                  Elle ne peut pas être modifiée ou supprimée après avoir été finalisée.
              </div>
          </div>
          <form-wrapper
                  ref="form"
                  :show-changed="false"
                  submit-button-label="Finaliser commande"
                  @submit="finalizeOrder"
          >
              <form-grid>
                  <text-input-element v-model="emails" label="Emails (optionnel)" placeholder="transférer à ... (séparés par des virgules)"/>

                  <text-area-element v-model="textEmail" label="Email text (optionnel)"/>
              </form-grid>
          </form-wrapper>
      </div>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import {mapActions, mapGetters} from "vuex";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import FormGrid from "@/components/elements/forms/FormGrid.vue";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement.vue";
import Modal from "@/components/elements/modals/Modal.vue";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";

export default {
  name: "SupplierOrderFinalizeButton",
  components: {TextInputElement, Modal, TextAreaElement, FormGrid, FormWrapper, ButtonElement},
  computed: {
    ...mapGetters('supplierOrders', [
      'getOrderId'
    ])
  },
  data: () => ({
    textEmail: null,
    emails:null
  }),
  methods: {
    ...mapActions('supplierOrders', [
      'fetchOrder'
    ]),
    openModal() {
      this.$refs.modal.openModal();
    },
    finalizeOrder(callback) {
      SupplierOrderRepository
        .finalizeOrder(this.getOrderId, {emails: this.emails, emailText:this.textEmail})
        .then(() => {
          this.$sendSuccessToast("La commande a été finalisée!");
          this.fetchOrder(this.getOrderId);
        }).finally(() => callback());
    }
  },
}
</script>

