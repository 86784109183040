<template>
  <div class="flex gap-x-4">
    <delete-confirmation-modal
      ref="deleteConfirmationModal"
      :title="'Supprimer ' + getTitleByContextType + '?'"
      @delete="deleteRule"
    >
      Êtes-vous sure que vous voulez supprimer les {{ getTitleByContextType }}?
    </delete-confirmation-modal>


    <button-element size="small" type="warning" @click="$emit('edit')">
      <fa-icon fixed-width icon="edit"></fa-icon>
    </button-element>

    <button-element size="small" type="danger" @click="openDeleteRuleModal">
      <fa-icon fixed-width icon="trash"></fa-icon>
    </button-element>
  </div>
</template>

<script>
import ButtonElement from "../../../elements/buttons/ButtonElement";
import DeleteConfirmationModal from "../../../elements/modals/DeleteConfirmationModal";
import FeeRepository from "../../../../repositories/FeeRepository";
import CampaignRepository from "../../../../repositories/CampaignRepository";

export default {
  name: "RuleActions",
  components: {DeleteConfirmationModal, ButtonElement},
  props: {
    rule: {
      type: Object,
      required: true
    },
    ruleAbleModel: {
      type: String,
      required: true
    }
  },
  methods: {
    openDeleteRuleModal() {
      this.$refs.deleteConfirmationModal.openModal();
    },
    deleteRule() {
      switch (this.ruleAbleModel.toLowerCase()) {
        case "fee":
          return this.deleteRuleForFee();
        case "campaign":
          return this.deleteRuleForCampaign();
      }
    },
    deleteRuleForFee() {
      FeeRepository
        .rules
        .deleteRule(this.getFeeId, this.rule.appliedOn)
        .then(() => {
          this.$store.dispatch('fees/refetchCurrentFee').then(() => {
            this.$sendSuccessToast(this.getTitleByContextType + ' supprimés!');
          })
        });
    },
    deleteRuleForCampaign() {
      CampaignRepository
        .rules
        .deleteRule(this.getCampaignId, this.rule.appliedOn)
        .then(() => {
          this.$store.dispatch('campaigns/refetchCurrentCampaign').then(() => {
            this.$sendSuccessToast(this.getTitleByContextType + ' supprimés!');
          })
        });
    },
  },
  computed: {
    getTitleByContextType() {
      return "Régles " + (this.rule.appliedOn === "cart" ? 'panier d\'achat' : 'produit');
    },
    getFeeId() {
      return this.$store.getters["fees/getFeeId"];
    },
    getCampaignId() {
      return this.$store.getters["campaigns/getCampaignId"];
    }
  }
}
</script>

