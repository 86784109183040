<template>
  <div v-if="hasOrder">
    <div class="flex flex-wrap items-center justify-between gap-4 mb-3">
      <div class="flex items-center gap-x-2 flex-wrap">
        <page-title
          :document-page-title="`Commande | ID: ${getOrderId} | ${getInvoiceAddress.fullName}`"
          without-margin
        >
          {{ `${getInvoiceAddress.fullName} #${getOrderId}` }}
        </page-title>

        <div v-if="getOrder"
             class="flex gap-x-4 flex-wrap items-center"
        >
          <order-status-indicator
            :status="getOrder.status"
          />
          <order-fulfilment-status-indicator
            :fulfilment-status="getOrder.fulfilmentStatus"
          />
        </div>
      </div>


      <div class="flex flex-wrap items-center justify-between gap-4">
        <!-- Cancel -->
        <cancel-order-button/>
        <!-- Confirm -->
        <confirm-order-button/>
        <!-- Finalize -->
        <order-fulfill-button
          v-if="!isOrderFulfilmentFinalized"
        />
        <!-- Batch Single Mode -->
        <batch-actions-single-model
          :batch-actions="OrderBatchActions.batchActions"
          :selected-value="OrderBatchActions.selectValuesFromId(getOrderId)"
          @executed="fetchOrder"
        />
      </div>
    </div>

    <div v-if="!isDraft" class="bg-white border p-4 mb-4">
      <div class="flex gap-x-6">

        <div class="flex gap-x-8 items-center">
          <span class="font-bold">ID Facture:</span>
          <text-link
            v-if="getInvoiceId !== null"
            :to="{name: 'invoices.edit', params: {id: getInvoiceId}}"
          >
            {{ getInvoiceId }}
          </text-link>
          <span v-else>/</span>
        </div>

      </div>
    </div>


    <card class="mb-4">
      <card-tab id="orderInfo" icon="box" title="Commande">
        <order-information/>

        <!-- Add product/fee/custom product to order card -->
        <transition mode="out-in" name="fade-up-down">
          <card
            v-if="!isInReadOnlyMode && isOrderEditable"
            :navigation-through-query-params="false"
            class="mt-4"
            icon="cart-plus"
            size="small"
            title="Ajouter à la commande"
            type="light"
          >
            <card-tab id="addProductTab" key="1" ref="addProductTab" title="Produit">
              <add-product-to-cart-or-order context="order"/>
            </card-tab>

            <card-tab id="addCustomProductTab" key="2" ref="addCustomProductTab" title="Libellé libre">
              <add-custom-product-to-order/>
            </card-tab>

            <card-tab id="addFeeTab" key="3" ref="addFeeTab" title="Frais">
              <add-fee-to-order/>
            </card-tab>

            <card-tab id="addPackageTab" key="4" ref="addPackageTab" title="Emballage">
              <add-package-to-order/>
            </card-tab>
          </card>
        </transition>

        <!-- Product table -->
        <products-in-cart-or-order-table
          context="order"
        />
      </card-tab>
      <card-tab id="orderInstructions" icon="book" title="Instructions">
        <order-instruction-management :order-id="getOrderId" class="mt-4"/>
      </card-tab>

      <card-tab id="orderLogs" icon="file-alt" title="Logs">
        <show-logs-table
          :default-loggable-id="getOrderId"
          default-loggable-type="Order"
        />
      </card-tab>
    </card>
  </div>
</template>

<script>
import ProductsInCartOrOrderTable
  from "../../../global/CartAndOrder/ProductsInCartOrOrderManagement/Table/ProductsInCartOrOrderTable";
import OrderInformation from "./Info/OrderInformation";
import AddProductToCartOrOrder from "./AddToOrder/AddProductToCartOrOrder";
import AddCustomProductToOrder from "./AddToOrder/AddCustomProductToOrder";
import Card from "../../../elements/cards/Card";
import CardTab from "../../../elements/cards/tabs/CardTab";
import AddFeeToOrder from "./AddToOrder/AddFeeToOrder";
import OrderInstructionManagement from "@/components/pages/Orders/EditOrder/Instructions/OrderInstructionManagement";
import PageTitle from "@/components/elements/pages/PageTitle";
import {mapGetters, mapMutations} from "vuex";
import OrderFulfillButton from "@/components/pages/Orders/EditOrder/MarkAsFulfilled/OrderFulfillButton";
import ShowLogsTable from "@/components/pages/Logs/ShowLogsTable";
import CancelOrderButton from "@/components/pages/Orders/EditOrder/CancelOrder/CancelOrderButton";
import OrderStatusIndicator from "@/components/pages/Products/PackagingInfo/Status/OrderStatusIndicator";
import OrderFulfilmentStatusIndicator from "@/components/pages/Orders/EditOrder/Info/OrderFulfilmentStatusIndicator";
import AddPackageToOrder from "./AddToOrder/AddPackageToOrder";
import TextLink from "../../../global/TextLink";
import ConfirmOrderButton from "@/components/pages/Orders/EditOrder/Confirm/ConfirmOrderButton";
import BatchActionsSingleModel from "@/batchAction/components/BatchActionsSingleModel.vue";
import OrderBatchActions from "@/batchAction/orderBatchActions";

export default {
  name: "EditOrder",
  components: {
    BatchActionsSingleModel,
    ConfirmOrderButton,
    AddPackageToOrder,
    OrderFulfilmentStatusIndicator,
    OrderStatusIndicator,
    CancelOrderButton,
    ShowLogsTable,
    OrderFulfillButton,
    PageTitle,
    OrderInstructionManagement,
    AddFeeToOrder,
    CardTab,
    Card, AddCustomProductToOrder, AddProductToCartOrOrder, OrderInformation, ProductsInCartOrOrderTable,
    TextLink
  },
  data: () => ({
    selectedItem: {},
    selectedTimeSlot: null,
  }),
  created() {
    this.fetchOrder();
  },
  beforeDestroy() {
    this.clearOrderData();
  },
  methods: {
    ...mapMutations('order', ['clearOrderData']),
    fetchOrder() {
      this.$store.dispatch('order/fetchOrder', {
        orderId: this.getOrderId
      });

      // Reset currently referenced product
      this.$store.commit('order/setReferencedItemId', {
        id: null,
        substituteReferencedItem: false
      });
    },
  },
  computed: {
    OrderBatchActions() {
      return OrderBatchActions
    },
    ...mapGetters('order', [
      'getInvoiceAddress',
      'getInvoiceId',
      'hasOrder',
      'getOrder',
      'isOrderFulfilmentFinalized',
      'isOrderEditable'
    ]),

    getOrderId() {
      return parseInt(this.$route.params.order);
    },
    isInReadOnlyMode() {
      return this.$isReadOnly();
    },
    isDraft() {
      return this.getOrder?.status === "draft";
    }
  }
}
</script>

