<template>
  <form-wrapper
    ref="form"
    :show-changed="true"
    :submit-button-label="isMemberSupplier ? 'Sauvegarder' : 'Créer Fournisseur'" @submit="storeSupplierDetails">

    <form-grid class="grid-cols-1 mb-6">

      <div class="flex items-center gap-x-4 col-span-12">
        <div class="font-bold">Status:</div>
        <member-status-indicator
          :status="getStatus"
        />
      </div>
    </form-grid>

    <form-grid class="grid-cols-3 my-4">
      <email-input-element
        v-for="(email, i) in emails"
        :key="'email-' + i"
        v-model="emails[i]"
        :label="`E-Mail (${i + 1})`"
      />
      <button-element
        class="self-center"
        size="small"
        type="primary"
        @click="addEmailInput"
      >
        <fa-icon fixed-width icon="plus"/>
      </button-element>
    </form-grid>

    <form-grid class="grid-cols-2 mb-6">
      <checkbox-element
        v-model="isProductSupplier"
        label="Fournisseur de marchandise"
      />

      <text-input-element
        v-model="iban"
        label="IBAN"
      />
    </form-grid>

    <form-grid class="grid-cols-1 xl:grid-cols-4 mb-4">
      <text-input-element v-model="customerReference"
                          :validation-rules="getValidationRuleForReference"
                          label="Notre réference client"></text-input-element>

      <number-input-element v-model="paymentTermsDays"
                            inset-text="Jours"
                            label="Délai de paiement"></number-input-element>
      <template v-if="isProductSupplier">
        <number-input-element v-model="deliveryTimeDays"
                              inset-text="Jours"
                              label="Temps de Livraison"
                              validation-rules="required"></number-input-element>

        <currency-input-element v-model.number="minimumOrderAmount"
                                label="Commande minimale"
                                validation-rules="required"></currency-input-element>

        <number-input-element v-model="stockForDays"
                              inset-text="Jours"
                              label="Stock pour"
                              validation-rules="required"></number-input-element>

      </template>
    </form-grid>

    <form-grid class="grid-cols-1 mb-6">
      <checkbox-element v-model="hasAutoOrder"
                        label="Commande automatique"></checkbox-element>
    </form-grid>

    <transition name="fade-up-down">
      <form-grid v-if="hasAutoOrder" class="grid-cols-1 xl:grid-cols-2">
        <number-input-element
          v-model="orderIntervalWeeks"
          inset-text="Semaines"
          label="Commande toutes les"
          validation-rules="required"
        />

        <select-element
          v-model="orderIntervalWeekDays"
          :enable-search="false"
          :options="availableWeekDays"
          label="Jours de commande"
          multi
          validation-rules="required"
        />
      </form-grid>
    </transition>


    <template #additionalButtons>
      <delete-confirmation-modal v-if="isMemberSupplier && isActive" ref="deactivateSupplierModal"
                                 delete-button-label="Désactiver" title="Désactiver fournisseur?"
                                 @abort="closeDeactivateModal" @delete="deactivateSupplierDetails">
        Êtes vous sûre que vous voulez désactiver ce membre comme fournisseur?
      </delete-confirmation-modal>

      <button-element v-if="isMemberSupplier && isActive" class="float-right" type="danger"
                      @click="openDeactivateModal">
        Désactiver
      </button-element>

      <button-element v-if="isMemberSupplier && !isActive" class="float-right" type="warning"
                      @click="activateSupplierDetails">
        Activer
      </button-element>
    </template>

  </form-wrapper>
</template>

<script>
import FormWrapper from "../../../../elements/forms/FormWrapper";
import SupplierRepository from "../../../../../repositories/SupplierRepository";
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";
import CheckboxElement from "../../../../elements/checkboxes/CheckboxElement";
import NumberInputElement from "../../../../elements/forms/elements/base/NumberInputElement";
import ButtonElement from "../../../../elements/buttons/ButtonElement";
import CurrencyInputElement from "../../../../elements/forms/elements/CurrencyInputElement";
import FormGrid from "../../../../elements/forms/FormGrid";
import DeleteConfirmationModal from "../../../../elements/modals/DeleteConfirmationModal";
import MemberStatusIndicator from "../../../../elements/display/member/MemberStatusIndicator";
import EmailInputElement from "@/components/elements/forms/elements/EmailInputElement.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "MemberCreateEditSupplierTab",
  components: {
    SelectElement,
    EmailInputElement,
    DeleteConfirmationModal,
    FormGrid,
    CurrencyInputElement,
    ButtonElement,
    NumberInputElement,
    CheckboxElement,
    TextInputElement,
    FormWrapper,
    MemberStatusIndicator
  },
  props: {
    member: {type: Object, required: true}
  },
  data: () => ({
    customerReference: '',
    isProductSupplier: false,
    hasAutoOrder: false,
    minimumOrderAmount: null,
    deliveryTimeDays: null,
    stockForDays: null,
    paymentTermsDays: null,
    orderIntervalWeeks: null,
    orderIntervalWeekDays: [],
    isActive: false,
    iban: null,

    emails: [''], // Set to '' so at least one email field on create is displayed.

    availableWeekDays: [
      {label: 'Lundi', value: 1},
      {label: 'Mardi', value: 2},
      {label: 'Mecredi', value: 3},
      {label: 'Jeudi', value: 4},
      {label: 'Vendredi', value: 5},
      {label: 'Samedi', value: 6},
      {label: 'Dimanche', value: 7},
    ]
  }),
  mounted() {
    if (this.isMemberSupplier) {
      this.getSupplier();
    }
  },
  methods: {
    getSupplier(id = this.member.id) {
      return SupplierRepository.getSupplierDetails(id).then((res) => {
        let data = res.data.data;

        this.customerReference = data.customerReference;
        this.isProductSupplier = data.isProductSupplier;
        this.hasAutoOrder = data.logisticData?.hasAutoOrder || false;
        this.deliveryTimeDays = data.logisticData.deliveryTimeDays;
        this.minimumOrderAmount = data.logisticData.minimumOrderAmount;
        this.stockForDays = data.logisticData.daysOfStock;
        this.paymentTermsDays = data.paymentTermsDays;
        this.orderIntervalWeeks = data.logisticData.orderIntervalWeeks;
        this.orderIntervalWeekDays = data.logisticData.orderIntervalWeekDays?.map(wd => Number(wd));
        this.isActive = data.isActive;
        this.emails = data.emails ? data.emails : [];
        this.iban = data.iban;

        // Create email input
        if (!this.emails.length) {
          this.addEmailInput();
        }

      }).then(() => this.$refs.form.$refs.validationObserver.reset());
    },
    addEmailInput() {
      this.emails.push("");
    },
    storeSupplierDetails(callback) {
      const emails = this.emails.filter(e => e && typeof e === "string" && e.length > 0);

      let payload = {
        isProductSupplier: this.isProductSupplier,
        hasAutoOrder: this.hasAutoOrder,
        customerReference: this.customerReference,
        deliveryTimeDays: this.deliveryTimeDays,
        minimumOrderAmount: this.minimumOrderAmount,
        stockForDays: this.stockForDays,
        paymentTermsDays: this.paymentTermsDays,
        orderIntervalWeeks: this.orderIntervalWeeks,
        orderIntervalWeekDays: this.orderIntervalWeekDays,
        emails: emails,
        iban: this.iban,
      };

      SupplierRepository.storeSupplierDetails(this.member.id, payload).then(() => {
        this.$sendSuccessToast(`Mise à jour réussie du fournisseur ${this.member.fullName}!`);
      }).then(() => {
        this
          .getSupplier(this.member.id)
          .finally(() => {
            callback();
            this.$emit('change');
          });
      }).catch(() => {
        callback();
      })
    },
    deactivateSupplierDetails() {
      if (!this.isMemberSupplier)
        return false;

      SupplierRepository.deactivateSupplierDetails(this.member.id).then(() => {
        this.closeDeactivateModal();
        this.$sendSuccessToast(`Fournisseur ${this.member.fullName} désactivé!`);
      }).finally(() => {
        this.getSupplier(this.member.id).then(() => this.$emit('change'));
      });
    },

    activateSupplierDetails() {
      if (!this.isMemberSupplier)
        return false;

      SupplierRepository.activateSupplierDetails(this.member.id).then(() => {
        this.$sendSuccessToast(`Fournisseur ${this.member.fullName}  a été activé!`);
      }).finally(() => {
        this.getSupplier(this.member.id).then(() => this.$emit('change'));
      });
    },

    openDeactivateModal() {
      this.$refs.deactivateSupplierModal.openModal();
    },
    closeDeactivateModal() {
      this.$refs.deactivateSupplierModal.closeModal();
    }
  },
  computed: {
    getValidationRuleForReference() {
      return this.isProductSupplier ? 'required|max:191' : 'max:50';
    },
    isMemberSupplier() {
      return this.member.isSupplier;
    },
    getStatus() {
      if (this.isActive) {
        return 'active';
      }

      return 'deactivated';

    },
  }
}
</script>

