<template>
  <div>
    <div
      class="group rounded border-gray-300 transition"
    >
      <div
        class="flex justify-between items-center  rounded-t"
      >
        <div>
          <h2
            class="font-semibold inline-block mr-4"
            style="min-height: 28px;"
          >
            {{ getAuthor }}
          </h2>

          <div
            v-if="createdAt"
            :title="$date(createdAt).format('DD.MM.YYYY HH:mm')"
            class="flex-nowrap inline-block text-gray-600 text-xs"
          >
            {{ $date(createdAt).subtract(3, 'seconds').fromNow() }}
          </div>
        </div>

        <div>
          <slot name="actions"/>
        </div>
      </div>

      <div
        class="mt-1 border-l-2 border-luxcaddy-100 pl-3.5"
      >
        <slot></slot>

        <div v-if="getExpiresAtDayJS">
          <div class="mt-4 text-xs w-max">
            <span
              v-if="getExpiresAtDayJS.isBefore($date())"
              class="border bg-red-100 border-red-300 rounded-full px-1.5"
            >
              Expirée le {{ getExpiresAtDayJS.format('DD.MM.YYYY HH:mm') }}
            </span>
            <span
              v-else
              class="border bg-luxcaddy-100 border-luxcaddy-300 rounded-full px-1.5"
            >
              Expire le {{ getExpiresAtDayJS.format('DD.MM.YYYY HH:mm') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentBoxTemplate",
  props: {
    createdAt: {
      type: String,
    },
    author: {
      type: String
    },
    expiresAt: {
      type: String,
      default: null
    },
  },
  computed: {
    getAuthor() {
      let author = this.author ?? this.$store.getters['authUser/getFullName'];

      return author.length > 28
        ? author.substring(0, 28) + '...'
        : author;
    },
    getExpiresAtDayJS() {
      if (this.expiresAt === null) {
        return null;
      }

      return this.$date(this.expiresAt, ['DD.MM.YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss']);
    }
  },
}
</script>