import apiService from './Base/BaseRepository';

const url = '/short-url';

export default {

  async getPaginated(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0
  ) {

    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}`);
  },

  async getSingle(slug) {
    return await apiService
      .get(`${url}/${slug}`);
  },


  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  async update(shortUrlId, payload) {
    return await apiService.patch(`${url}/${shortUrlId}`, payload);
  },

  async delete(shortUrlId) {
    return await apiService.delete(`${url}/${shortUrlId}`);
  },


}