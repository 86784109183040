<template>
  <div>
    <modal ref="copyProductsModal" show-close-button title="Copier produits" @close="onModalClose">
      <div style="min-width: 600px;">
        <div class="text-xl mb-6">Veulliez sélectionner une catégorie:</div>

        <card class="max-h-screen" type="light">
          <category-selection-tree :is-virtual="isVirtual"/>
        </card>

        <transition name="fade">
          <info-alert v-if="categorySelected" class="mt-10">
            {{ getProductCount }} produits seront copiés dans la catégorie <span
            class="font-bold">{{ selectedCategory.name }}</span>
          </info-alert>
        </transition>
      </div>

      <template #footer>
        <div class="flex justify-end gap-4">
          <button-element :disabled="!categorySelected" type="primary" @click="copySelectedProducts">
            <fa-icon class="mr-2" fixed-width icon="clone"></fa-icon>
            Copier produits selectionnées
          </button-element>
        </div>
      </template>
    </modal>

    <modal ref="moveProductsModal" show-close-button title="Déplacer produits">
      <div style="min-width: 600px;">
        <div class="text-xl mb-6">Veulliez sélectionner une catégorie:</div>

        <card class="max-h-screen" type="light">
          <category-selection-tree :is-virtual="isVirtual"/>
        </card>

        <transition name="fade">
          <info-alert v-if="categorySelected" class="mt-10">
            {{ getProductCount }} produits seront déplacés dans la catégorie <span
            class="font-bold">{{ selectedCategory.name }}</span>
          </info-alert>
        </transition>
      </div>

      <template #footer>
        <div class="flex justify-end gap-4">
          <button-element :disabled="!categorySelected" type="primary" @click="moveSelectedProducts">
            <fa-icon class="mr-2" fixed-width icon="exchange-alt"></fa-icon>
            Déplacer produits selectionnées
          </button-element>
        </div>
      </template>
    </modal>

    <delete-confirmation-modal ref="removeProductsModal" title="Retirer produits" @delete="removeSelectedProducts">
      Étes vous sure que vous voulez retirer les produits selectionnées de cette catégorie?
    </delete-confirmation-modal>
  </div>
</template>

<script>
import Modal from "@/components/elements/modals/Modal";
import CategorySelectionTree from "@/components/elements/categoryManagement/Tree/Selection/CategorySelectionTree";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import InfoAlert from "@/components/elements/alerts/InfoAlert";
import Card from "@/components/elements/cards/Card";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal";
import CategoryRepository from "@/repositories/CategoryRepository";

export default {
  name: "SelectedProductsInCategoryActions",
  components: {DeleteConfirmationModal, Card, InfoAlert, ButtonElement, CategorySelectionTree, Modal},
  props: {
    selectedProducts: {
      type: Array
    },
    isVirtual: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    /*
     *  ACTIONS
     */
    removeSelectedProducts() {
      const productIds = this.getSelectedProducts.flatMap(p => p.id);
      this.$emit('remove', productIds);
    },
    copySelectedProducts() {
      const productsToCopy = this.getSelectedProducts.flatMap(p => p.id);

      CategoryRepository.copyProducts(this.selectedCategory.id, productsToCopy).then(() => {
        this.$sendSuccessToast('Les produits sélectionnés ont été copiés.');
        this.$store.commit('categories/setSelectedProducts', []);
        this.$refs.copyProductsModal.closeModal();
      });
    },
    moveSelectedProducts() {
      const productsToMove = this.getSelectedProducts.flatMap(p => p.id);

      CategoryRepository.moveProducts(this.getActiveCategory.id, this.selectedCategory.id, productsToMove).then(() => {
        this.$sendSuccessToast('Les produits sélectionnés ont été déplacés.');
        this.$store.commit('categories/setSelectedProducts', []);
        CategoryRepository.getSingle(this.getActiveCategory.id).then((res) => {
          this.$store.commit('categories/setActiveCategory', res.data.data);
        });
        this.$refs.moveProductsModal.closeModal();
      });
    },

    onModalClose() {
      this.$store.commit('categories/setSelectedCategoryInSelectionTree', null);
    }
  },
  computed: {
    categorySelected() {
      return this.selectedCategory !== null;
    },
    selectedCategory() {
      return this.$store.getters['categories/getSelectedCategoryInSelectionTree'];
    },
    getActiveCategory() {
      return this.$store.getters['categories/getActiveCategory'];
    },
    getSelectedProducts() {
      return this.$store.getters['categories/getSelectedProducts'];
    },
    getProductCount() {
      return this.selectedProducts?.length || 0;
    }
  }
}
</script>

