<template>
  <batch-actions-button
    :batch-actions="batchActions"
    :single-mode-identifier-object="selectedValue"
    button-label="Action"
    @executed="$emit('executed', $event)"
  />
</template>

<script>
import BatchActionsButton from "@/components/listTable/includes/batch/BatchActionsButton.vue";

export default {
  name: "BatchActionsSingleModel",
  props: {
    batchActions: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: Object,
      required: true
    }
  },
  components: {
    BatchActionsButton
  },
}
</script>

<style scoped>

</style>