<template>
  <div>
    <list-table
      ref="listTable"
      :batch-actions="SupplierOrderBatchActions.batchActions"
      :columns="columns"
      :create-route="{
                name: 'supplier-orders.create'
            }"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"

      :select-values="SupplierOrderBatchActions.selectValues"
      enable-totals
      identifier="supplierOrdersTable"

      selectable
      title="Commandes fournisseurs"
    >
      <template v-slot:status-template="{ row }">
        <supplier-order-status
          :finalized="!row.isDraft"
          small
        />
      </template>

      <template v-slot:received-percentage-template="{ row }">
        <fulfilment-progress
          :percentage="row.receivedPercentage"
          multi-color
        />
      </template>

      <template v-slot:treated-percentage-template="{ row }">
        <fulfilment-progress
          :percentage="row.treatedPercentage"
          multi-color
        />
      </template>
      <template v-slot:member-quick-action-template="{ row }">
        <member-quick-action-modal :id="row.supplierMemberId"/>
      </template>
    </list-table>

    <modal
      ref="supplierOrderUploadPdfModal"
      blocking
      title="Attache PDF"
      @close="onCloseModal"
    >

      <upload-external-invoice-pdfs
        :supplier-order-id="getOrderId"
        show-cancel-bth
        submit-btn="Attache PDF"
        @cancel="cancelUpload"
        @done="setInvoiceFinished"
      />

    </modal>


  </div>
</template>

<script>
import ListTable from "../../listTable/ListTable";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";
import {centsToEuroString} from "@/helpers/mathHelper";
import Modal from "@/components/elements/modals/Modal";
import FulfilmentProgress from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/FulfilmentProgress";
import SupplierOrderStatus from "@/components/pages/SupplierOrders/Status/SupplierOrderStatus";
import {
  FILTER_BOOLEAN,
  FILTER_DATETIME_RANGE,
  FILTER_TEXT,
} from "../../listTable/includes/controls/filters/availableFilters";
import SupplierOrderBatchActions from "@/batchAction/supplierOrderBatchActions";
import UploadExternalInvoicePdfs
  from "@/components/pages/Accounting/ExternalInvoicePDFs/Sub/UploadExternalInvoicePdfs.vue";
import {mapGetters} from "vuex";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal.vue";

export default {
  name: "ShowSupplierOrders",
  computed: {
    SupplierOrderBatchActions() {
      return SupplierOrderBatchActions
    },
    ...mapGetters('supplierOrders', [
      'getOrderId'
    ]),
  },
  components: {
    MemberQuickActionModal,
    UploadExternalInvoicePdfs,
    SupplierOrderStatus, FulfilmentProgress, Modal, ListTable
  },
  data() {
    return {
      title: "Commandes fournisseurs",
      repositoryFunc: SupplierOrderRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'edit',
              icon: 'file-invoice',
              hint: 'Marquer facture comme reçue',
              disabledIf: (supplierOrder) => supplierOrder.invoiceReceived,
              onClick: (supplierOrder) => this.showUploadPdfInvoiceModal(supplierOrder),
            },
            {
              type: 'show',
              hint: 'Voir commande',
              route: (row) => ({
                name: 'supplier-orders.edit',
                params: {
                  order: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier commande',
              route: (row) => ({
                name: 'supplier-orders.edit',
                params: {
                  order: row.id
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer commande',
              confirmation: {
                title: 'Supprimer commande?',
                text: 'Étes vous sure que vous voulez supprimer cette commande?'
              },
              successText: 'La commande a été supprimée',
              deleteAction: (row) => SupplierOrderRepository.deleteSingle(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'ID Fournisseur',
          cellTemplate: 'member-quick-action-template',
        },
        {
          caption: 'Fournisseur',
          dataField: 'supplierName',
        },
        {
          caption: "Status",
          cellTemplate: "status-template"
        },
        {
          caption: "Qté.",
          dataField: 'total.quantity',
        },
        {
          caption: "Total",
          dataType: 'euro',
          calculateCellValue: (r) => {
            return centsToEuroString(r.total.price.amount);
          },
          totals: {
            sum: "total.price.amount",
            formatValue: (val) => centsToEuroString(val)
          },
        },
        {
          caption: "Payé",
          dataType: 'euro',
          calculateCellValue: (r) => {
            return centsToEuroString(r.total.paid.amount);
          },
          totals: {
            sum: "total.paid.amount",
            formatValue: (val) => centsToEuroString(val)
          },
        },
        {
          caption: "Livr. prévue",
          calculateCellValue: r => {
            return this.$date(r.expectedDeliveryDate).format('DD.MM.YY');
          }
        },
        {
          caption: "Reçu",
          cellTemplate: 'received-percentage-template',
          width: 120,
        },
        {
          caption: "Traité",
          cellTemplate: 'treated-percentage-template',
          width: 120,
        },
        {
          caption: "Envoyé le",
          dataField: 'sentAt',
          calculateCellValue: (r) => {
            if (r.sentAt === null)
              return "/";

            return this.$date(r.sentAt).format('DD.MM.YYYY HH:mm');
          },
        },
        {
          caption: "Facture reçu",
          calculateCellValue: r => r.invoiceReceived,
          dataType: 'boolean'
        },

      ],
      filters: [
        {
          caption: "ID(s)",
          filterKey: "ids",
          forceIsVisible: true,
          small: true
        },
        {
          caption: "ID(s) ou Nom Fournisseur",
          filterKey: "supplierMemberIdsOrName",
          forceIsVisible: true,
          small: true
        },
        {
          filterKey: 'expectedDeliveryDate',
          caption: "Livraison entre",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true,
        },
        {
          caption: "Facture reçu?",
          filterKey: 'invoiceReceived',
          type: FILTER_BOOLEAN,
          small: true
        },
        {
          caption: "Est envoyé?",
          filterKey: 'isPlaced',
          type: FILTER_BOOLEAN,
          small: true
        },
        {
          caption: "A des produits?",
          filterKey: 'hasProducts',
          type: FILTER_BOOLEAN,
          small: true
        },
        {
          caption: "ID PDF Facture",
          filterKey: 'externalInvoicePdfId',
          type: FILTER_TEXT
        },
      ]
    }
  },
  methods: {
    showUploadPdfInvoiceModal(supplierOrder) {
      this.$store.commit('supplierOrders/clearOrder');
      this.$store.dispatch('supplierOrders/fetchOrder', supplierOrder.id)
        .then(() => {
          this.$refs.supplierOrderUploadPdfModal.openModal();
        });
    },

    setInvoiceFinished() {
      this.$store.commit('supplierOrders/clearOrder');
      this.$refs.listTable.refresh();
      this.$refs.supplierOrderUploadPdfModal.closeModal();
      this.$sendSuccessToast("Les fichiers ont été atachee.");
    },
    cancelUpload() {
      this.$refs.supplierOrderUploadPdfModal.closeModal();

    },
    onCloseModal() {
      this.$store.commit('supplierOrders/clearOrder');

    },
  }
}
</script>

