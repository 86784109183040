<template>
  <div>
    <div class="flex justify-between flex-wrap items-center mb-3">
      <page-title>Créneaux de livraison</page-title>

      <div v-if="allowModifications" class="flex justify-center gap-x-2">
        <close-zone-time-slots-day-action
          @change="loadTimeSlotsInCurrentView"
        />

        <bulk-copy-time-slots-action
          v-if="currentStartDateShown && currentView === 'week'"
          :active-week="parseInt(currentStartDateShown.getWeek())"
          :active-year="parseInt(currentStartDateShown.format('YYYY'))"
          class="cursor-default select-auto"
        />
      </div>
    </div>

    <card>
      <div class="w-full">
        <div class="bg-gray-100 py-4 px-8 rounded-lg mb-4">
          <div class="flex items-center gap-x-12">
            <fa-icon icon="truck" size="2x"></fa-icon>
            <select-element
              v-model="zone"
              :enable-search="false"
              :options="getZones"
              class="w-full"
              label="Zone de Livraison"
            />
          </div>
        </div>

        <create-edit-delete-delivery-time-slot-modal
          v-if="allowModifications"
          ref="createEditModal"
          @close="onTimeSlotChange"
        />
        <vue-cal
          ref="calendar"
          :disable-views="['day']"
          :drag-to-create-event="true"
          :editable-events="{title: false, drag: false, resize: false, delete: false, create: true}"
          :events="formattedTimeSlotsForCalendar"
          :hide-view-selector="true"
          :snap-to-time="15"
          :time-from="hourBegin * 60"
          :time-to="hourEnd * 60"
          active-view="week"
          locale="fr"
          @ready="onReady"
          @view-change="onViewChange"
          @event-drag-create="onNewEventDrag"
          @event-focus="onEventSelection"
        >
          <template #no-event>
            <span></span>
          </template>

          <template v-slot:event="{ event }">
            <div v-if="event.isTotal">
              <div v-if="event.isAllZoneTotal" class="font-bold">
                Total: {{ event.totalDeliveryCount }} / {{ event.totalDeliveryLimit }}
              </div>
              <div v-else>
                {{ event.totalDeliveryCount }} / {{ event.totalDeliveryLimit }}
              </div>
            </div>
            <div v-else>
              <small class="vuecal__event-time text-md">
                <span>{{ event.start.formatTime() }}</span> - <span>{{ event.end.formatTime() }}</span>
              </small>

              <div v-if="event.deliveryCount !== undefined && event.deliveryLimit !== undefined"
                   :class="event.occupationBgClass"
                   class="rounded-md px-4 w-max mx-auto text-gray-800 mt-1 flex gap-x-4 items-center">
                <div class="font-bold text-white">
                  {{ event.deliveryCount }}/{{ event.deliveryLimit }}
                </div>
              </div>
            </div>
          </template>

        </vue-cal>
      </div>
    </card>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css'
import 'vue-cal/dist/i18n/fr.amd.js';
import CreateEditDeleteDeliveryTimeSlotModal from "./Actions/CreateEditDeleteDeliveryTimeSlotModal";
import BulkCopyTimeSlotsAction
  from "@/components/pages/DeliveryTimeSlots/Manage/Calendar/Actions/BulkCopyTimeSlotsAction";
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import CloseZoneTimeSlotsDayAction
  from "@/components/pages/DeliveryTimeSlots/Manage/Calendar/Actions/CloseZoneTimeSlotsDayAction.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "DeliveryTimeSlotCalendar",
  components: {
    SelectElement,
    CloseZoneTimeSlotsDayAction,
    Card,
    PageTitle,
    BulkCopyTimeSlotsAction,
    CreateEditDeleteDeliveryTimeSlotModal,
    VueCal
  },
  props: {
    allowModifications: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    zone() {
      this.$store.commit('deliveryTimeSlots/clearTimeSlots');

      this.loadTimeSlotsInCurrentView();
    }
  },
  data() {
    return {
      // The time the table should show of each day.
      hourBegin: 6,
      hourEnd: 22,

      currentStartDateShown: null,
      currentEndDateShown: null,
      currentView: null,

      zone: 1,
    }
  },
  computed: {
    formattedTimeSlotsForCalendar() {
      let slots = this.getAvailableTimeSlots.map(slot => {
        let slotClass, occupationBgClass;
        let percentage = Number(
          this.getPercentageOfSlotOccupation(slot.deliveryLimit, slot.deliveryCount)
        );

        if (percentage >= 0 && percentage <= 60) {
          slotClass = "slot-green";
          occupationBgClass = 'bg-luxcaddy-800';
        } else if (percentage > 60 && percentage < 100) {
          slotClass = "slot-yellow";
          occupationBgClass = 'bg-yellow-800';
        } else {
          slotClass = "slot-red";
          occupationBgClass = 'bg-red-800';
        }

        return {
          timeSlotId: slot.id,
          deliveryCount: slot.deliveryCount,
          deliveryLimit: slot.deliveryLimit,
          start: slot.startDate,
          end: slot.endDate,
          available: slot.isAvailable,
          class: slotClass,
          occupationBgClass: occupationBgClass,
          isTotal: false,
        };
      });


      let totalsForAllZones = this.getTotalsForAllZones.map(total => {

        return {
          start: total.date + ' 6:00',
          end: total.date + ' 7:00',
          totalDeliveryLimit: total.totalDeliveryLimit,
          totalDeliveryCount: total.totalDeliveryCount,
          isTotal: true,
          isAllZoneTotal: true,
          class: 'total'
        };

      });

      let totals = this.getTotalsForSelectedZone.map(total => {

        return {
          start: total.date + ' 7:00',
          end: total.date + ' 8:00',
          zone: total.zone,
          totalDeliveryLimit: total.totalDeliveryLimit,
          totalDeliveryCount: total.totalDeliveryCount,
          isTotal: true,
          isAllZoneTotal: false,
          class: 'total'
        };

      });


      return [...slots, ...totals, ...totalsForAllZones];
    },
    getAvailableTimeSlots() {
      return this.$store.getters['deliveryTimeSlots/getTimeSlots'];
    },
    getTotals() {
      return this.$store.getters['deliveryTimeSlots/getTotals'];
    },
    getTotalsForSelectedZone() {
      return this.getTotals.filter(t => t.zone === this.zone);
    },
    getTotalsForAllZones() {

      let allZoneTotals = [];

      this.getTotals.forEach(t => {

        if (typeof allZoneTotals[t.date] === 'undefined') {
          allZoneTotals[t.date] = {
            date: t.date,
            totalDeliveryLimit: 0,
            totalDeliveryCount: 0,
          };
        }

        allZoneTotals[t.date].totalDeliveryLimit += parseInt(t.totalDeliveryLimit);
        allZoneTotals[t.date].totalDeliveryCount += parseInt(t.totalDeliveryCount);

      });

      return Object.values(allZoneTotals);
    },
    getZones() {
      return this.$luxcaddyConfig('zoneList').map(zone => {
        return {
          label: "Zone " + zone,
          value: zone
        }
      })
    }
  },
  methods: {
    getPercentageOfSlotOccupation(limit, orders) {
      return (orders / limit) * 100;
    },
    onReady(e) {
      this.$store.commit('deliveryTimeSlots/clearTimeSlots');
      this.onViewChange(e);
    },
    onViewChange(e) {
      // used to determine whether to show the bulk copy button
      this.currentView = e.view;

      this.currentStartDateShown = e.startDate;
      this.currentEndDateShown = e.endDate;


      let start = this.$date(e.startDate);
      let end = this.$date(e.endDate);

      return this.loadTimeslots(start, end);
    },
    onNewEventDrag(e) {
      if (!this.allowModifications)
        return false;

      this.$refs.createEditModal.setInitialData(null, {
        zone: this.zone,
        startDate: this.$date(e.start),
        endDate: this.$date(e.end),
        deliveryLimit: 20
      });
      this.$refs.createEditModal.openModal();
    },
    onTimeSlotChange() {
      this.loadTimeSlotsInCurrentView(true);
    },
    onEventSelection(e) {
      if (!this.allowModifications || e.isTotal)
        return false;

      this.$refs.createEditModal.setInitialData(e.timeSlotId, {
        zone: this.zone,
        startDate: this.$date(e.start),
        endDate: this.$date(e.end),
        deliveryLimit: e.deliveryLimit,
      });
      this.$refs.createEditModal.openModal();
    },
    /**
     * If force is set, all timeslots in the store between
     * $startDate and $endDate will be deleted and re-fetched.
     *
     * @param startDate
     * @param endDate
     * @param force
     * @return {boolean}
     */
    loadTimeslots(startDate, endDate) {
      this.$store.dispatch('deliveryTimeSlots/fetchTimeSlotsInSpan', {
        zone: this.zone,
        startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
        endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
      });
    },
    loadTimeSlotsInCurrentView() {
      let startDate = this.$date(this.$refs.calendar.view.startDate);
      let endDate = this.$date(this.$refs.calendar.view.endDate);
      this.loadTimeslots(startDate, endDate);
    }
  }

}
</script>

<style>
.vuecal__title-bar {
  @apply bg-luxcaddy-500 text-white;
}


.vuecal__event.slot-green {
  @apply bg-luxcaddy-300 border border-luxcaddy-400 text-white transform
  hover:scale-105 cursor-pointer hover:shadow-xl hover:bg-luxcaddy-400 select-none opacity-80 hover:opacity-100;
}

.vuecal__event.slot-yellow {
  @apply bg-yellow-300 border border-yellow-400 text-white transform
  hover:scale-105 cursor-pointer hover:shadow-xl hover:bg-yellow-400 select-none opacity-80 hover:opacity-100;
}

.vuecal__event.slot-red {
  @apply bg-red-300 border border-red-400 text-white transform
  hover:scale-105 cursor-pointer hover:shadow-xl hover:bg-red-400 select-none opacity-80 hover:opacity-100;
}

.vuecal__menu {
  @apply bg-luxcaddy-200;
}

div.vuecal__title-bar > div > button {
  background: transparent !important;
}

.total {
  @apply bg-gray-200;
  @apply py-2;
  z-index: 2;
}
</style>