<template>
  <div class="flex flex-wrap gap-4">
    <package-selection-group
      v-for="packageGroup in getPackageGroups"
      :key="packageGroup.group"
      :group="packageGroup"
    ></package-selection-group>
  </div>
</template>

<script>
import PackageSelectionGroup from "./PackageSelection/PackageSelectionGroup";

export default {
  name: "CartPackagingSelection",
  components: {PackageSelectionGroup},
  mounted() {
    this.$store.commit('cart/resetPackagingSelection');
  },
  computed: {
    getPackageGroups() {
      return this.$store.getters['cart/getPackageGroups'];
    }
  }
}
</script>

