<template>
  <list-table
    :key="getTableKey"
    :columns="columns"
    :create-route="{
            name: 'deliveries.create'
        }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="deliveriesTable"
    show-filters-immediately
  >

    <template v-slot:destination-template="{ row }">
      <div class="font-bold text-sm mb-1">
        {{ row.destination.fullName }}
      </div>
      <div class="text-xs">
        <p>{{ row.destination.street }}, {{ row.destination.postalCode }} {{ row.destination.city }},
          {{ row.destination.country | toCountryNameString }}</p>
      </div>
    </template>

    <template v-slot:fulfilment-number-template="{ row }">
      <fulfilment-number-display
        :fulfilment-number="row.fulfilmentNumber"
      />
    </template>

    <template v-slot:timeslot-template="{ row }">
      <time-slot-as-string :from="$date(row.timeSlot.startDate)" :to="$date(row.timeSlot.endDate)"
                           :zone="row.timeSlot.zoneId"/>
    </template>

    <template v-slot:box-quantity-template="{ row }">
      <box-quantity :quantity="row.boxQuantity"/>
    </template>

    <template v-slot:status-template="{ row }">
      <delivery-status-indicator
        :status="row.status"
        small
      />
    </template>

    <template v-slot:precise-geocoding-template="{ row }">
      <geocoding-precision-status
        :geocoding="row.destination.geocoding"
        small
      />
    </template>

    <template v-slot:order-id-template="{ row }">
      <order-id-link :order-id="row.orderId"/>
    </template>

    <template v-slot:member-quick-action-template="{ row }">
      <member-quick-action-modal :id="row.memberId"/>
    </template>
  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import DeliveryRepository from "@/repositories/DeliveryRepository";
import DeliveryStatusIndicator from "@/components/elements/display/deliveries/DeliveryStatusIndicator";
import GeocodingPrecisionStatus
  from "@/components/pages/Members/EditMember/Tabs/DeliveryContacts/Includes/GeocodingPrecisionStatus";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";
import {
    FILTER_BOOLEAN,
    FILTER_DATETIME_RANGE, FILTER_MEMBER_EMPLOYEES_MULTI,
    FILTER_SELECT_MULTI,
    FILTER_TEXT
} from "../../listTable/includes/controls/filters/availableFilters";
import TimeSlotAsString from "../../global/TimeSlots/TimeSlotAsString";
import BoxQuantity from "../../global/BoxQuantity/BoxQuantity";
import OrderIdLink from "@/components/pages/Orders/OrderIdLink";

export default {
  name: "ShowDeliveries",
  components: {
    OrderIdLink,
    BoxQuantity,
    TimeSlotAsString,
    FulfilmentNumberDisplay,
    MemberQuickActionModal,
    GeocodingPrecisionStatus,
    DeliveryStatusIndicator,
    ListTable
  },
  computed: {
    // used as a workaround to render the table again when switching from a shortcut (today's orders, tomorrows order, ...) to the regular page,
    getTableKey() {
      return this.$route.name + '_' + Math.random();
    }
  },
  data() {
    return {
      title: 'Livraisons',
      repositoryFunc: DeliveryRepository.getPaginated,
      filters: [
        {
          caption: "ID(s)",
          filterKey: "deliveryIds",
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "ID(s) ou Nom Membre",
          filterKey: "memberIdsOrName",
          forceIsVisible: true,
          small: true,
        },
        {
          filterKey: 'timeslot',
          caption: "Date entre",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true,
        },
        {
          caption: "Commande",
          filterKey: "order",
          type: FILTER_TEXT
        },
        {
          caption: "Zone",
          filterKey: "zone",
          type: FILTER_TEXT
        },
        {
          caption: "Code postal",
          filterKey: "postalCode",
          type: FILTER_TEXT
        },
        {
          caption: "N°",
          filterKey: "fulfillmentNumber",
          type: FILTER_TEXT,
          forceIsVisible: true,
          small: true
        },
        {
          caption: "Status",
          filterKey: "status",
          filterOptions: this.$luxcaddyConfig('delivery.availableStatuses').filter(status => !['initialized', 'enRoute'].includes(status.value)),
          type: FILTER_SELECT_MULTI,
          forceIsVisible: true,
          small: true
        },
        {
          caption: "Sur route",
          filterKey: "onRoute",
          type: FILTER_BOOLEAN,
          small: true
        },
        {
          caption: "Livreur",
          filterKey: "deliveredByDriver",
          type: FILTER_MEMBER_EMPLOYEES_MULTI,
          forceIsVisible: true,
          small: true
        },
      ],
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir livraison',
              route: (row) => ({
                name: 'deliveries.edit',
                params: {
                  deliveryId: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier livraison',
              route: (row) => ({
                name: 'deliveries.edit',
                params: {
                  deliveryId: row.id,
                },
              }),
            }
          ]
        },
        {
          caption: '#',
          dataField: 'id',
          width: 0,
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'Com',
          cellTemplate: 'order-id-template'
        },
        {
          caption: 'Mem',
          cellTemplate: 'member-quick-action-template'
        },
        {
          caption: 'Destination',
          cellTemplate: 'destination-template',
        },
        {
          caption: 'Créneau',
          cellTemplate: 'timeslot-template',
          sort: {
            dataField: 'timeSlot',
          }
        },
        {
          caption: 'Status',
          cellTemplate: 'status-template',
          width: 0,
        },
        {
          caption: 'Livreur',
          dataField: "driverMemberName",
          visible: false

        },
        {
          caption: 'N°',
          cellTemplate: 'fulfilment-number-template',

        },
        {
          caption: 'Bacs',
          cellTemplate: 'box-quantity-template',
        },
        {
          caption: 'Géo',
          cellTemplate: 'precise-geocoding-template'
        },
      ]
    }
  },
}
</script>

