import axios from 'axios';
import {vue} from '@/main';

import store from "../../store/store";
import router from "../../router/router";

const baseURL = process.env.VUE_APP_API_URL;


function handleAppVersionLogicFromResponse(response) {
  if (!response || !response.headers || !Object.prototype.hasOwnProperty.call(response.headers, 'x-app-version')) {
    return false;
  }
  let appVersionFromStore = store.getters['app/getAppVersion'];
  let appVersionFromHeader = response.headers["x-app-version"];

  // App version not previously set
  if (appVersionFromStore === null || appVersionFromStore === undefined) {
    store.commit('app/setAppVersion', appVersionFromHeader);
  } else {
    // App Version was already set
    if (appVersionFromStore !== appVersionFromHeader) {
      // Versions differ, force reload on next route change
      store.commit('app/setAppRefreshNeededTrue');
    }
  }
}

/**
 * ApiService Class which extends Axios
 * and holds an instance in this.service
 */
class ApiService {
  constructor() {
    axios.defaults.withCredentials = true;

    let service = axios.create({
      baseURL: baseURL
    });

    service.interceptors.request.use(this.requestInterceptor, this.requestInterceptorError);
    service.interceptors.response.use(this.responseInterceptorSuccess, this.responseInterceptorError);

    this.resetQueryParams();

    this.service = service;
    this.cancelTokenSource = axios.CancelToken.source();

    // We have to make the service available using window,
    // as the axios interceptors don't have access to `this`.
    window.apiService = this;
  }

  /**
   * Parses the error from the erroneous request.
   * If no error is found, defaultMessage will be outputted.
   *
   * @param erroneousRequest
   * @param defaultMessage
   */
  showErrorMessageIfGiven(erroneousRequest, defaultMessage = null) {
    if (erroneousRequest.response?.data?.error !== undefined) {
      vue.$showErrorDialog(erroneousRequest.response.data.error);
    } else {
      if (defaultMessage !== null) {
        vue.$showErrorDialog(defaultMessage);
      } else {
        if (erroneousRequest.response) {
          vue.$showErrorDialog("Erreur! Code: " + erroneousRequest.response.status);
        }
      }
    }
  }

  /**
   * Resets pagination, sort, filters, ...
   */
  resetQueryParams() {
    this.sorts = [];
    this.includes = [];
    this.filters = [];
    this.page = null;
    this.perPage = null;
    this.withTotals = 0;
  }

  /**
   * Executed before each request.
   *
   * @param config
   * @return {*}
   */
  requestInterceptor(config) {
    const service = window.apiService;

    config.headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': 'fr',
    };

    //axios.defaults.withCredentials = true;


    // Add cancel token to the request and save it to the store,
    // to be able to cancel this request programmatically.
    config.cancelToken = service.cancelTokenSource.token;

    let showLoadingIndicator = config.showLoadingIndicator === undefined ? true : config.showLoadingIndicator;

    store.commit('loading/addRequest', {
      url: config.url,
      cancelTokenSource: service.cancelTokenSource,
      showLoadingIndicator: showLoadingIndicator
    });

    return config;
  }

  /**
   *
   * @param error
   * @return {Promise<never>}
   */
  requestInterceptorError(error) {
    return Promise.reject(error);
  }


  /**
   * Executed after a request succeeded.
   *
   * @param response
   * @return {*}
   */
  responseInterceptorSuccess(response) {
    //Remove the request from the store as its finished.
    store.commit('loading/removeRequestByUrl', response.config.url);

    let notifications = response?.data?.notifications;
    if (notifications?.warnings) {
      store.commit('userInterface/setWarningNotifications', notifications.warnings);
    }

    handleAppVersionLogicFromResponse(response);

    return response;
  }

  /**
   * Executed after a request failed.
   *
   * @param error
   * @return {Promise<never>}
   */
  responseInterceptorError(error) {
    const service = window.apiService;

    if (error.response) {
      //Remove the cancelTokenSource from the store as the request is finished.
      store.commit('loading/removeRequestByUrl', error.response.config.url);

      handleAppVersionLogicFromResponse(error.response);
    }

    // Only process errors if the request has not been canceled.
    if (!axios.isCancel(error)) {
      if (!error.response) {
        store.dispatch('loading/cancelAllRequests');

        if (error.config.ignoreErrorMessageIfNoConnection !== true) {
          vue.$showErrorDialog("Nous ne pouvons pas nous connecter au serveur.");
        }
      }

      switch (error.response?.status) {
        case 403:
          service.showErrorMessageIfGiven(error, "Vous n'avez pas la permission d'accéder à cette ressource.");
          break;
        case 413:
          service.showErrorMessageIfGiven(error, "Le fichier est trop grand.");
          break;
        case 401:
        case 419:
          if (!['auth.login', 'auth.login.barcode'].includes(router.currentRoute.name)) {
            store.commit('authUser/clearUser');
            router.push({name: 'auth.login'});
          } else {
            service.showErrorMessageIfGiven(error);
          }
          break;
        case 404:
          service.showErrorMessageIfGiven(error, "La ressource demandée n'a pas pu être trouvée.");
          break;
        default:
          service.showErrorMessageIfGiven(error);
          break;
      }
    }

    return Promise.reject(error);
  }

  /**
   *
   * @param sortArray
   * @return {ApiService}
   */
  setSorts(sortArray) {
    this.sorts = sortArray
      .filter(s => s.sort !== 'none')
      .flatMap(s => `${s.dataField},${s.sort}`);

    return this;
  }

  /**
   *
   * @param perPage
   * @param page
   * @param withTotals
   * @return {ApiService}
   */
  setPagination(perPage = 20, page = 1, withTotals = 0) {
    this.perPage = perPage;
    this.page = page;

    this.withTotals = withTotals;
    return this;
  }

  /**
   *
   * @return {ApiService}
   * @param filters
   */
  setFilters(filters) {
    // {
    //      filterKey: "product",
    //      filterValue: 1,
    //      defaultValue: 1
    // }

    this.filters = filters;

    return this;
  }

  addFilter(filterKey, filterValue) {
    this.filters.push({
      filterKey, filterValue
    });
    return this;
  }

  /**
   * URL-encode the string, but replace some encoded characters with their non encoded version.
   * @param value
   * @return {string}
   */
  encodeUriAndReplace(value) {
    return encodeURIComponent(value)
      .replaceAll("%5B", "[")
      .replaceAll("%5D", "]")
      .replaceAll("%2C", ",");
  }

  /**
   *
   * @param key
   * @param value
   * @return {string}
   */
  keyValueToUrlEncodedString(key, value) {
    return this.encodeUriAndReplace(key) + "=" + this.encodeUriAndReplace(value);
  }

  /**
   * Converts the data that should be appended to the query to a query string.
   * @return {string}
   */
  convertRequestDataToQueryString(currentUrl) {
    let valueArray = [];

    // Sorts
    this.sorts.forEach(sort => {
      valueArray.push(this.keyValueToUrlEncodedString("sort[]", sort))
    })


    // Filters
    this.filters.forEach(filter => {
      let value = filter.filterValue;

      // When a filter is composed by multiple values, they are joined here.
      // This is the case when a filter has multiple sub-inputs, for example to specify
      // a range (e.g price from -> price to)
      if (value !== null && typeof value === "object") {

        if (value.startDate !== undefined && value.endDate !== undefined) {
          // Value is from a DateTime Range Filter

          // Check if either start or end date at least are set
          if (value.startDate !== null || value.endDate !== null) {
            value = `${value.startDate},${value.endDate}`;
          } else {
            // if both start & end date are null, we dont send this filter as we consider it to be an empty range filter
            value = null;
          }

        } else {
          // Value is from any other type of filter (not date time range)
          value = Object.values(value)
            .map(v => v === null ? "null" : v) // Allow one of the two range subfilter values to be null
            .filter(v => {
              return v !== null && v !== "" && v !== undefined;
            }).join(',');

          // If both values are null, we dont want to send it.
          if (value === "null,null") {
            value = null;
          }

        }
      }

      if (value !== null && value !== "") {
        valueArray.push(this.keyValueToUrlEncodedString(`filter[${filter.filterKey}]`, value));
      }
    });

    // Includes
    this.includes.forEach(include => {
      valueArray.push(this.keyValueToUrlEncodedString("include[]", include));
    })

    // Page
    if (this.page !== null) {
      valueArray.push(this.keyValueToUrlEncodedString('page', this.page));
    }

    // PerPage
    if (this.perPage !== null) {
      valueArray.push(this.keyValueToUrlEncodedString('perPage', this.perPage));
    }

    // withTotals
    if (this.withTotals !== null) {
      valueArray.push(this.keyValueToUrlEncodedString('withTotals', this.withTotals));
    }

    // Reset params after path has been generated so next requests are not reusing them.
    this.resetQueryParams();

    if (!valueArray.length) {
      return currentUrl;
    }

    return currentUrl.includes("?")
      ? (currentUrl + "&" + valueArray.join("&"))
      : (currentUrl + "?" + valueArray.join("&"));
  }

  /**
   *
   * @param url
   * @param config
   * @return {Promise<*>}
   */
  get(url, config = null) {
    url = this.convertRequestDataToQueryString(url);

    return this.service.get(url, config);
  }

  /**
   *
   * @param url
   * @param payload
   * @param config
   * @return {Promise<*>}
   */
  post(url, payload, config = null) {
    url = this.convertRequestDataToQueryString(url);

    return this.service.post(url, payload, config);
  }

  /**
   *
   * @param url
   * @param payload
   * @param config
   * @return {Promise<*>}
   */
  patch(url, payload, config = null) {
    url = this.convertRequestDataToQueryString(url);

    return this.service.patch(url, payload, config);
  }

  /**
   *
   * @param url
   * @param payload
   * @param config
   * @return {Promise<*>}
   */
  put(url, payload, config = null) {
    url = this.convertRequestDataToQueryString(url);

    return this.service.put(url, payload, config);
  }

  /**
   *
   * @param url
   * @param payload
   * @param config
   * @return {Promise<*>}
   */
  delete(url, config = null) {
    url = this.convertRequestDataToQueryString(url);

    return this.service.delete(url, config);
  }

  /**
   *
   * @param url
   * @param config
   * @return {Promise<*>}
   */
  downloadBlob(url, config = null) {
    return this.get(url, {...config, responseType: 'blob'});
  }

  downloadBlobPost(url, payload) {
    return this.post(url, payload, {responseType: 'blob'});
  }

}

let apiService = new ApiService();
export default apiService;