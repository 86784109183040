<template>
    <span
      :class="getClasses"
    >
        {{ getProductName }}
    </span>
</template>

<script>
export default {
  name: "ProductName",
  props: {
    product: {
      type: Object,
    },
    customName: {
      type: String,
      default: null
    },
    customIsPublished: {
      type: Boolean,
      default: null
    },
    customIsDiscontinued: {
      type: Boolean,
      default: null
    },
    customIsVisibilityForced: {
      type: Boolean,
      default: null
    },
  },
  computed: {
    isUnpublishedButNotDiscontinued() {
      return !this.isPublished && !this.isDiscontinued;
    },
    isDiscontinued() {
      if (this.customIsDiscontinued !== null) {
        return this.customIsDiscontinued;
      }

      return this.product.isDiscontinued === undefined ? false : this.product.isDiscontinued;
    },
    isUnpublishedButVisibilityForces() {
      return !this.isPublished && this.isForceShopVisibility;
    },
    getClasses() {
      return {
        'text-red-500 font-bold': this.isUnpublishedButNotDiscontinued,
        'text-yellow-500 font-bold': this.isUnpublishedButVisibilityForces,
        'line-through': this.isDiscontinued
      };
    },

    // ----------------------------
    isPublished() {
      if (this.customIsPublished !== null) {
        return this.customIsPublished;
      }
      return this.product.isPublished === undefined
        ? true
        : this.product.isPublished;
    },
    getProductName() {
      if (this.customName !== null) {
        return this.customName;
      }

      return this.product.name;
    },
    isForceShopVisibility() {
      if (this.customIsVisibilityForced !== null) {
        return this.customIsVisibilityForced;
      }

      return this.product.isShopVisibilityForced === undefined
        ? false
        : this.product.isShopVisibilityForced;
    },
  },
}
</script>