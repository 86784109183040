<template>
  <div class="grid grid-cols-6 items-start gap-x-8">
    <select-element
      v-model="pageLink.pageName"
      :enable-search="false"
      :options="getPageLinkTypesForSelect"
      label="Page"
      validation-rules="required"
      @input="pageLink.placement = null"
    />

    <div>
      <number-input-element
        v-if="selectedPageNameRequiresId && pageLink.pageParams !== null"
        v-model="pageLink.pageParams.id"
        label="ID"
      />
    </div>

    <select-element
      v-model="pageLink.placement"
      :enable-search="false"
      :options="getPlacementList"
      label="Placement"
      validation-rules="required"
    />

    <div>
      <number-input-element
        v-model="pageLink.weight"
        label="Poids"
        validation-rules="required"
      />

      <div v-if="getBanner.type === 'text' && getSeverityByWeight !== null">
        <div
          v-if="getSeverityByWeight === 'info'"
          class="px-2 rounded-md bg-gray-400 text-white"
        >
          <fa-icon
            class="mr-2"
            fixed-width
            icon="info"
          />
          Information
        </div>
        <div v-else-if="getSeverityByWeight === 'warning'">
          <div
            v-if="getSeverityByWeight === 'warning'"
            class="px-2 rounded-md bg-yellow-400 text-white"
          >
            <fa-icon
              class="mr-2"
              fixed-width
              icon="exclamation-triangle"
            />
            Avertissement modéré
          </div>
        </div>
        <div v-else-if="getSeverityByWeight === 'danger'">
          <div
            v-if="getSeverityByWeight === 'danger'"
            class="px-2 rounded-md bg-red-400 text-white"
          >
            <fa-icon
              class="mr-2"
              fixed-width
              icon="exclamation-triangle"
            />
            Avertissement critique
          </div>
        </div>
      </div>
    </div>


    <div class="col-span-2 flex gap-x-3">
      <date-range-picker-element
        v-model="pageLinkDate"
        class="flex-1"
        end-date-end-of-day
        label="Durée"
        with-time
      />

      <div>
        <form-label class="mb-3" label="Supprimer" size="xsmall"></form-label>
        <button-element size="small" type="danger" @click="$emit('remove')">
          <fa-icon fixed-width icon="times"/>
        </button-element>
      </div>
    </div>

  </div>
</template>

<script>
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import Vue from "vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import FormLabel from "@/components/elements/forms/elements/partials/FormLabel";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import {mapGetters} from "vuex";

export default {
  name: "BannerPageLinkManagementLine",
  components: {
    SelectElement,
    DateRangePickerElement, FormLabel, ButtonElement, NumberInputElement
  },
  props: {
    pageLink: {
      type: Object,
      required: true
    }
  },
  watch: {
    getLinkPageConfigForSelection() {
      // Reset Page Params (id) if id not required
      if (!this.selectedPageNameRequiresId) {
        Vue.set(this.pageLink, 'pageParams', {id: null});
      }
    },
  },
  computed: {
    ...mapGetters('banners', [
      'getBanner'
    ]),
    getPageLinkTypes() {
      return this
        .$luxcaddyConfig('banner.pageLinkTypes')
        .filter(t => t.supportedTypes.includes(this.getBanner.type));
    },
    getLinkPageConfigForSelection() {
      if (!this.getSelectedPageIdentifierName)
        return null;

      return this.getPageLinkTypes.find(pl => pl.model === this.getSelectedPageIdentifierName);
    },
    selectedPageNameRequiresId() {
      return this.getLinkPageConfigForSelection?.requiresId || false;
    },
    getPageLinkTypesForSelect() {
      return this.getPageLinkTypes.map(pl => {
        return {
          label: pl.label,
          value: pl.model,
        }
      })
    },

    getSelectedPageIdentifierName() {
      return this.pageLink.pageName;
    },

    getPlacementList() {
      return this.$luxcaddyConfig('banner.placementList')
        .filter(placement => this.getLinkPageConfigForSelection && this.getLinkPageConfigForSelection.placements[this.getBanner.type].includes(placement.value));
    },
    pageLinkDate: {
      get() {
        return {
          startDate: this.pageLink.startDate,
          endDate: this.pageLink.endDate,
        }
      },
      set({startDate, endDate}) {
        this.pageLink.startDate = startDate;
        this.pageLink.endDate = endDate;
      }
    },

    getSeverityByWeight() {
      const severity = this.pageLink.weight;

      if (severity === null) {
        return null;
      }

      if (severity <= 33) {
        return "info";
      }

      if (severity > 33 && severity <= 66) {
        return "warning";
      }

      return "danger";
    }
  },
}
</script>