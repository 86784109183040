<template>
  <div class="grid grid-cols-1 gap-8">

    <div
      v-if="stockData !== null"
      class="flex gap-2 flex-wrap w-full"
    >
      <badge
        :inset-text="stockData.real.toString()"
        class="flex-1"
      >
        Stock Réel
      </badge>

      <badge
        :inset-text="stockData.virtual.toString()"
        class="flex-1"
      >
        Stock virtuel
      </badge>

      <badge
        :inset-text="stockData.packed.toString()"
        class="flex-1"
      >
        Emballé
      </badge>

      <badge
        :inset-text="stockData.onShelf.toString()"
        class="flex-1"
      >
        Sur etagéres
      </badge>

      <badge
        :inset-text="stockData.available.toString()"
        class="flex-1"
      >
        Stock disponible
      </badge>

      <badge
        :inset-text="stockData.locked.toString()"
        class="flex-1"
      >
        Réservé
      </badge>

      <badge
        :inset-text="stockData.waiting.toString()"
        class="flex-1"
      >
        En attente de stock
      </badge>

      <badge
        :inset-text="stockData.inSupplierOrder.toString()"
        class="flex-1"
      >
        Com fournisseur
      </badge>

      <badge
        :inset-text="stockData.reservedGift.toString()"
        class="flex-1"
      >
        Réservé cadeau
      </badge>
    </div>

    <card icon="box" rounded size="small"
          title="Modification manuelle du stock"
          type="light"
    >
      <add-manual-stock-to-product-form
        :product-id="productId"
        form-fields-equal-width
        @update="onStockUpdate"
      />
    </card>

    <product-stock-history-table
      ref="productStockHistory"
      :product-id="productId"
    />
  </div>
</template>

<script>
import AddManualStockToProductForm from "../../Forms/Stock/AddManualStockToProductForm";
import Card from "../../../../elements/cards/Card";
import ProductStockHistoryTable from "../../Stock/ProductStockHistoryTable";
import ProductRepository from "@/repositories/ProductRepository";
import Badge from "@/components/elements/badges/Badge.vue";

export default {
  name: "ManageProductStock",
  components: {Badge, ProductStockHistoryTable, Card, AddManualStockToProductForm},
  props: {
    productId: {type: Number, required: true}
  },
  data() {
    return {
      stockData: null
    }
  },
  created() {
    this.getCurrentStock();
  },
  methods: {
    /**
     * Updates the stock history table + current stock values when stock has been modified.
     */
    onStockUpdate() {
      this.getCurrentStock();
      this.$refs.productStockHistory.refreshTable();
    },
    getCurrentStock() {
      ProductRepository.getSingle(this.productId).then((res) => {
        this.$set(this, 'stockData', res.data.data.stock.values);
      });
    }
  }
}
</script>

