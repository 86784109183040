import apiService from './Base/BaseRepository';
import {Vehicle} from "@/components/pages/Vehicles/Vehicle";

const url = '/vehicles';

export default {
    async getPaginated(
        perPage = 20,
        page = 1,
        sorts = [],
        filters = [],
        withTotals = 0
    ) {
        return await apiService
            .setPagination(perPage, page, withTotals)
            .setSorts(sorts)
            .setFilters(filters)
            .get(`${url}/`);
    },

    async getSingle(vehicleId: number | null) {
        return await apiService
            .get(`${url}/${vehicleId}`);
    },

    async createVehicle(payload: Vehicle) {
        return await apiService.post(`${url}/`, payload);
    },

    async updateVehicle(vehicleId: number | null, payload: Vehicle) {
        return await apiService.patch(`${url}/${vehicleId}`, payload);
    },

    async toggleActive(vehicleId: number | null) {
        return await apiService.patch(`${url}/${vehicleId}/toggleActive`, null);
    },
}