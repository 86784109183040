<template>
  <div
    v-if="hasSelectedAndLoadedQuery && getPlaceHolders.length > 0"
    class="mt-12"
  >
    <h3 class="font-bold">
      Données complémentaires
    </h3>

    <div class="mt-2 grid grid-cols-1 lg:grid-cols-4 gap-4">
      <report-query-placeholder-input
        v-for="placeHolder in getPlaceHolders"
        :key="placeHolder.placeholder"
        :place-holder="placeHolder"
        @enter="$emit('submit')"
      />
    </div>
  </div>
</template>

<script>
import ReportQueryPlaceholderInput
  from "@/components/pages/ReportQueries/Execute/Sub/Placeholder/ReportQueryPlaceholderInput.vue";

export default {
  name: "ReportQueryPlaceholderList",
  components: {ReportQueryPlaceholderInput},
  computed: {
    hasSelectedAndLoadedQuery() {
      return this.$store.getters['reportQuery/hasSelectedAndLoadedQuery'];
    },
    getPlaceHolders() {
      let unique = [];
      this.$store.getters['reportQuery/getSelectionPlaceholders'].forEach((placeholder) => {
        if (!unique.find(p => p.placeholder === placeholder.placeholder)) {
          unique.push(placeholder);
        }
      })

      return unique;
    }
  },
}
</script>