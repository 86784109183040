<template>
  <div
    :class="customClasses"
    class="text-xs text-gray-300 leading-5 py-1 px-4"
  >
    <fa-icon class="mr-2" fixed-width icon="clock"></fa-icon>
    <span
      v-html="timeString"
    />
  </div>
</template>

<script>
export default {
  name: "NavbarLiveClock",
  data: () => ({
    timeString: 'XX.XX.XXXX XX:XX:XX',
    timeInterval: null,
  }),
  props: {
    customClasses: {
      type: String,
      default: "bg-gray-700 rounded-md"
    }
  },
  methods: {
    setCurrentTime() {
      let date = new Date();

      this.timeString = this.addLeadingZero(date.getDate()) + "."
        + this.addLeadingZero((date.getMonth() + 1)) + "."
        + this.addLeadingZero(date.getFullYear()) + " &middot; "
        + this.addLeadingZero(date.getHours()) + ":"
        + this.addLeadingZero(date.getMinutes()) + ":"
        + this.addLeadingZero(date.getSeconds())
    },
    addLeadingZero(value) {
      return value < 10 ? "0" + value : value;
    }
  },
  created() {
    let vm = this;

    this.setCurrentTime();
    this.timeInterval = setInterval(function () {
      vm.setCurrentTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  },
}
</script>

