<template>
  <div v-show="showModal">
    <!-- background -->
    <div class="fixed inset-0 bg-gray-800 opacity-60" style="z-index: 9997"></div>


    <div
      ref="modalRef"
      :class="{
                    'md:justify-center md:items-center left-0': position === 'center',
                    'md:justify-center md:items-start': position === 'left',
                    ...getModalOffsetClasses
                }"
      class="fixed top-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto md:flex md:flex-col"
      style="z-index: 9998"
      @click="closeModal(true)"
    >

      <div
        class="auto relative shadow-xl w-screen sm:w-auto sm:max-w-screen-xl max-h-screen "
        @click.stop=""
      >
        <!-- Title -->
        <div
          v-if="title || getIconByType"
          :class="[compact ? 'p-2' : 'p-5']"
          class="bg-gray-800 text-gray-900 flex justify-between gap-x-12 font-normal rounded-t"
        >
          <h5
            :class="[compact ? 'text-xl' : 'text-lg sm:text-2xl']"
            class="text-white uppercase truncate"
          >
            <fa-icon
              v-if="getIconByType"
              :class="[getIconTextColorByType, 'mr-2']"
              :icon="getIconByType"
            />

            {{ title }}
          </h5>
          <button-element v-if="showCloseButton" :size="compact ? 'xsmall' : 'small'" type="dark"
                          @click="closeModal(false)">
            <i class="fa fa-times"></i>
          </button-element>
        </div>

        <!-- Content -->
        <div
          :class="{'p-5' : !disablePadding}"
          class="text-md text-justify font-normal bg-white rounded-b">
          <slot></slot>
          <!-- Footer -->
          <div v-if="!!$slots.footer" class="mt-6">
            <slot name="footer"></slot>
          </div>
        </div>


      </div>

    </div>
  </div>
</template>

<script>
import ButtonElement from "../buttons/ButtonElement";

export default {
  name: "Modal",
  components: {ButtonElement},
  data: () => ({
    showModal: false,
  }),
  props: {
    title: {
      type: String,
      default: null
    },
    blocking: {
      type: Boolean,
      default: true
    },
    submitOnEnterKeydown: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      required: false
    },
    disablePadding: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    /**
     * If this is set to true, the width on mobile will be device width, not more.
     */
    mobileMaxWidth: {
      type: Boolean,
      default: false
    },

    position: {
      type: String,
      default: "center",
      validator: val => ['center', 'left'].includes(val)
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.$emit('open');

      this.$nextTick(() => {
        document.body.scrollTop = 0;
      })
    },
    closeModal(clickOnBackground = false) {
      let shouldClose = clickOnBackground ? !this.blocking : true;

      if (!shouldClose)
        return false;

      this.showModal = false;
      this.$emit('close');
    },
  },
  computed: {
    getIconByType() {
      switch (this.type) {
        default:
          if (this.icon)
            return this.icon;
          return;
        case "default":
          return null;
        case "warning":
          return "exclamation-triangle";
      }
    },
    getIconTextColorByType() {
      switch (this.type) {
        default:
        case "default":
          return null;
        case "warning":
          return "text-yellow-400";
      }
    },

    /*
        Centered in the middle is position === center
        If position === left, offset to left will be decided based
        on whether the sidebar is opened or not.
     */
    getModalOffsetClasses() {
      if (this.position === "center") {
        return "";
      }

      // Position -> left

      return {
        'left-96': this.$store.getters['userInterface/isSidebarShown'],
        'left-24': !this.$store.getters['userInterface/isSidebarShown'],
      }
    }
  }
}
</script>