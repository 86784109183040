import config from '../luxcaddyConfig';
import store from "../store/store";

export default {
  install(Vue) {
    /**
     * Returns the given key from the config.
     * You can get nested values by splitting it with a dot (.) in between.
     *
     * For example:
     * product: {
     *     stock: {
     *         amount: 3
     *     }
     * }
     * can be retrieved using 'product.stock.amount'
     */
    Vue.prototype.$luxcaddyConfig = function (key) {
      if (key.includes('.')) {
        let keyParts = key.split('.');

        let conf = config;
        keyParts.forEach(kp => {
          conf = conf[kp]
        });

        return conf;
      }

      return config[key];
    };

    Vue.prototype.$isReadOnly = function () {
      return store.getters['userInterface/isInReadOnlyMode'];
    }
  }
}