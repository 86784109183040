<template>
  <card
    class="z-auto"
    disabled-flex-on-footer
    size="small"
    type="light"
  >
    <template
      #title
    >
      <div
        :title="externalInvoicePdf.fileName"
        class="truncate hover:underline cursor-pointer hover:text-gray-100"
        style="font-size: 8pt"
        @click="openPDFInBrowser"
      >
        <fa-icon
          class="inline-block"
          fixed-width
          icon="download"
        />

        {{ externalInvoicePdf.fileName }}

        <span class="text-xs">
            ({{ getFileSize }})
        </span>
      </div>
    </template>

    <div
      v-if="isLoadingPDF"
      :class="height"
      class="w-full flex justify-center items-center"
    >
      <loading-svg-inline
        size="h-16 w-16"
      />
    </div>
    <template
      v-else
    >
      <div
        v-if="errorLoading"
        class="w-full h-102 flex flex-col items-center justify-center gap-2 "
      >
        <fa-icon
          class="text-4xl text-red-500"
          fixed-width
          icon="exclamation-triangle"
        />
        Le PDF n'a pas pu être chargé.
      </div>

      <!-- PDF loaded -->
      <template
        v-else
      >
        <div class="flex items-center justify-between gap-x-2">
          <div class="flex gap-x-2 items-center">
            <div class="tracking-tight font-light text-sm select-none">
              Page
            </div>
            <div>
              <select-element
                v-model="pdfSettings.currentPage"
                :allow-unselecting="false"
                :nothing-selected-text="null"
                :occupy-validation-space="false"
                :options="Array.from({length: pdfSettings.totalPages}, (_, i) => i + 1)"
                class="w-16"
              />
            </div>
            <div>/ {{ pdfSettings.totalPages }}</div>
          </div>


          <button-element
            v-if="allowTextLayer && !pdfSettings.enableTextLayer"
            size="xsmall"
            type="light"
            @click="enableTextLayer"
          >
            Activer copy-paste
          </button-element>

          <div class="flex gap-x-2 items-center">
            <div class="tracking-tight font-light text-sm select-none">
              Zoom:
            </div>
            <div>
              <select-element
                v-model="pdfSettings.zoom"
                :allow-unselecting="false"
                :nothing-selected-text="null"
                :occupy-validation-space="false"
                :options="getAllowedZoomLevels"
                class="w-24"
              />
            </div>
          </div>
        </div>


        <div
          ref="pdfContainer"
          :class="[height]"
          class="mt-2 overflow-auto border pdf-viewer-wrapper relative overscroll-none"
          @scroll="onContainerDivScroll"
        >
          <pdf
            ref="pdfViewer"
            :page="pdfSettings.currentPage"
            :src="{data: pdfStreamData}"
            :style="'width: ' + pdfSettings.zoom + '%'"
            class="pdf-main"
            @num-pages="onPageNumCounted"
            @page-loaded="onPageLoaded"
          />
          <div
            v-if="allowTextLayer"
            ref='textLayer'
            :style='{
                            left: pdfSettings.textLayerViewport.left + "px",
                            top: pdfSettings.textLayerViewport.top + "px",
                            height: pdfSettings.textLayerViewport.height + "px",
                            width: pdfSettings.textLayerViewport.width + "px"
                        }'
            class="pdf-viewer--text-layer"
          />
        </div>

        <div class="mt-4 mb-2 flex justify-center">
          <comment-count-badge
            :comment-count="externalInvoicePdf.commentCount"
            :model-id="getId"
            model="ExternalInvoicePdf"
            small
            @change="$emit('request-reload')"
          />
        </div>

        <div class="flex gap-2 flex-wrap items-center justify-center  text-xs">
          <div>
            <fa-icon
              class="-mr-0.5"
              fixed-width
              icon="hashtag"
            />
            {{ getExternalInvoicePdfId }}
          </div>

          &middot;
          <div>
            <fa-icon
              class="mr-1"
              fixed-width
              icon="file-upload"
            />

            <span v-if="externalInvoicePdf.createdAt !== null">
              {{ $date(externalInvoicePdf.createdAt).format('DD.MM.YYYY HH:mm:ss') }}
              </span>
            <span v-else>???</span>
          </div>

          <template
            v-if="hasBeenTreated"
          >

            &middot;

            <div>
              <fa-icon
                class="mr-1 text-luxcaddy-500"
                fixed-width
                icon="check"
              />
              Traité: {{ $date(externalInvoicePdf.bookedAt).format('DD.MM.YYYY HH:mm:ss') }}
            </div>
          </template>
        </div>
      </template>
    </template>

    <!-- Custom footer, used for supplier order linked list -->
    <template
      v-if="$slots.footer && !isLoadingPDF"
      #footer
    >
      <slot name="footer"></slot>
    </template>

    <!-- Regular footer -->
    <template
      v-else-if="!isLoadingPDF"
      #footer
    >
      <div
        v-if="showTreatButtons || showDeleteButton || showSupplierOrderLinkButton || $slots.additionalFooterButtons !== undefined"
      >
        <div class="flex gap-2 flex-wrap items-start">
          <button-element
            v-if="showTreatButtons"
            :disabled="isTreated || errorLoading"
            size="xsmall"
            type="light"
            @click="createNewInvoiceFromPDF"
          >
            <fa-icon
              class="mr-2"
              fixed-width
              icon="link"
            />
            Traiter
          </button-element>

          <button-element
            v-if="showTreatButtons"
            :disabled="isTreated || errorLoading"
            size="xsmall"
            type="light"
            @click="openLinkToExistingModal"
          >
            <fa-icon
              class="mr-2"
              fixed-width
              icon="link"
            />
            Facture existante
          </button-element>

          <button-element
            v-if="showSupplierOrderLinkButton"
            :disabled="externalInvoicePdf.supplierOrderIds.length === 0"
            size="xsmall"
            type="light"
            @click="showLinkedSupplierOrders(false)"
            @click-ctrl="showLinkedSupplierOrders(true)"
          >
            <fa-icon
              class="mr-2"
              fixed-width
              icon="external-link-alt"
            />
            {{ externalInvoicePdf.supplierOrderIds.length }} Com. Fournisseurs
          </button-element>

          <button-element
            v-if="showDeleteButton"
            :disabled="isTreated"
            size="xsmall"
            type="danger"
            @click="showDeleteConfirmationModal"
          >
            <fa-icon
              fixed-width
              icon="trash"
            />
          </button-element>

          <slot name="additionalFooterButtons"></slot>
        </div>
      </div>
    </template>


    <!--
        MODALS
    -->

    <delete-confirmation-modal
      ref="deleteConfirmationModal"
      :title="'Supprimer Fichier ' + externalInvoicePdf.fileName + '?'"
      @delete="deleteExternalInvoicePDF"
    >
      Êtes-vous sure que vous voulez supprimer les fichier '{{ externalInvoicePdf.fileName }}'?
    </delete-confirmation-modal>


    <modal
      ref="linkToExistingModal"
      title="Liér PDF a facture existante"
      @close="linkToExistingInvoiceId = null"
    >
      <form-wrapper
        submit-button-label="Liér"
        @submit="linkToExistingInvoice"
      >
        <number-input-element
          v-model="linkToExistingInvoiceId"
          label="# Facture"
          validation-rules="required"
        />
      </form-wrapper>
    </modal>
  </card>
</template>

<script>
import Card from "@/components/elements/cards/Card.vue";
import ExternalInvoicePDFRepository from "@/repositories/ExternalInvoicePDFRepository";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline.vue";
import pdf from '@teckel/vue-pdf';
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import {getHumanReadableSize} from "@/helpers/fileHelper";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal.vue";
import Modal from "@/components/elements/modals/Modal.vue";
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement.vue";
import newTabMixin from "@/mixins/newTabMixin";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import {debounce} from "@/helpers/debounceHelper";
import CommentCountBadge from "@/components/global/Comments/CommentCountBadge.vue";

const PDFJS = require('pdfjs-dist/build/pdf.js');

export default {
  name: "ExternalInvoicePdfCard",
  mixins: [newTabMixin],
  components: {
    CommentCountBadge,
    SelectElement,
    NumberInputElement,
    FormWrapper, Modal, DeleteConfirmationModal, ButtonElement, LoadingSvgInline, Card, pdf
  },
  props: {
    externalInvoicePdf: {
      type: Object,
      required: false
    },
    height: {
      type: String,
      default: 'h-96'
    },
    allowTextLayer: {
      type: Boolean,
      default: true
    },


    initialZoom: {
      type: Number,
    },
    initialPage: {
      type: Number,
    },
    initialScrollTop: {
      type: Number,
    },
    initialScrollLeft: {
      type: Number,
    },

    /**
     * Buttons
     */
    showTreatButtons: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showSupplierOrderLinkButton: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.loadPdfStream();
  },
  watch: {
    pdfStateToEmit() {
      this.emitPdfState();
    },
  },
  data() {
    return {
      isLoadingPDF: false,
      errorLoading: false,

      pdfStreamData: null,


      pdfSettings: {
        zoom: 100,

        totalPages: 1,
        currentPage: 1,

        textLayerViewport: {
          left: 0,
          top: 0,
          height: 0,
          width: 0,
        },

        container: {
          scrollTop: 0,
          scrollLeft: 0
        },

        enableTextLayer: false,
      },

      linkToExistingInvoiceId: null,

      initialScrollApplied: false,
    }
  },
  computed: {
    getAllowedZoomLevels() {
      return [200, 180, 160, 140, 120, 100, 80, 60, 40, 20].map(z => {
        return {
          label: z + '%',
          value: z
        }
      });
    },

    getId() {
      return this.externalInvoicePdf.id;
    },
    isTreated() {
      return this.externalInvoicePdf.isTreated;
    },
    getFileSize() {
      return getHumanReadableSize(this.externalInvoicePdf.fileSize);
    },

    getExternalInvoicePdfId() {
      return this.externalInvoicePdf.id;
    },

    pdfStateToEmit() {
      const zoom = this.pdfSettings.zoom;
      const page = this.pdfSettings.currentPage;
      const scrollTop = this.pdfSettings.container.scrollTop;
      const scrollLeft = this.pdfSettings.container.scrollLeft;

      return `${zoom}|${page}|${scrollTop}|${scrollLeft}`;
    },

    hasBeenTreated() {
      return this.externalInvoicePdf.bookedAt !== null;
    }
  },
  methods: {
    loadPdfStream() {
      this.isLoadingPDF = true;
      ExternalInvoicePDFRepository
        .getPdfStreamById(this.getId)
        .then(res => this.pdfStreamData = res.data)
        .catch(() => this.errorLoading = true)
        .finally(() => {
          const vm = this;

          vm.$nextTick(() => {
            vm.isLoadingPDF = false;

            this.$nextTick(() => {
              if (this.initialZoom) {
                vm.pdfSettings.zoom = vm.initialZoom;
              }

              if (this.initialPage) {
                vm.pdfSettings.currentPage = vm.initialPage;
              }

              vm.emitPdfState();
            });
          });

        });
    },
    onPageNumCounted(pageNum) {
      if (pageNum === undefined) {
        return false;
      }

      this.pdfSettings.totalPages = pageNum;
    },

    onContainerDivScroll(e) {
      this.pdfSettings.container.scrollTop = Number(e.target.scrollTop);
      this.pdfSettings.container.scrollLeft = Number(e.target.scrollLeft);
    },

    emitPdfState() {
      this.$emit('pdfStateChanged', {
        zoom: this.pdfSettings.zoom,
        page: this.pdfSettings.currentPage,
        scrollTop: this.pdfSettings.container.scrollTop,
        scrollLeft: this.pdfSettings.container.scrollLeft
      })
    },
    onPageLoaded() {
      if (!this.initialScrollApplied) {
        if (this.initialScrollTop) {
          this.$refs.pdfContainer.scrollTop = this.initialScrollTop;
        }

        if (this.initialScrollLeft) {
          this.$refs.pdfContainer.scrollLeft = this.initialScrollLeft;
        }
      }

      this.debouncedRenderTextLayer();
    },

    enableTextLayer() {
      this.pdfSettings.enableTextLayer = true;
      this.renderTextLayer();
    },

    debouncedRenderTextLayer: debounce(function () {
      this.renderTextLayer()
    }, 300),

    renderTextLayer() {
      if (!this.allowTextLayer || !this.pdfSettings.enableTextLayer) {
        return;
      }

      this.$refs.pdfViewer.pdf.forEachPage((page) => {
        if (page.pageNumber === this.pdfSettings.currentPage) {
          page.getTextContent()
            .then(content => {
              this.$refs.textLayer.innerHTML = ""
              this.pdfSettings.textLayerViewport.height = this.$refs.pdfViewer.$refs.canvas.height;
              this.pdfSettings.textLayerViewport.width = this.$refs.pdfViewer.$refs.canvas.width;

              // get the REAL scale comparing the current canvas width and the "normal" Viewport width at a scale of 1.0
              const scale = this.$refs.pdfViewer.$refs.canvas.width / page.getViewport({scale: 1}).width
              const viewport = page.getViewport({scale: scale});

              this.$nextTick(() => {
                PDFJS.renderTextLayer({
                  textContent: content,
                  container: this.$refs.textLayer,
                  viewport: viewport,
                });

              })
            });
        }
      });
    },
    openPDFInBrowser() {
        const pdfBlob = new Blob([this.pdfStreamData], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
    },

    createNewInvoiceFromPDF() {
      this.$router.push({
        name: 'accounting.invoices.create',
        query: {
          bookExternalInvoicePdfId: this.getId,
        }
      })
    },


    showLinkedSupplierOrders(openInNewTab) {
      if (!this.externalInvoicePdf.supplierOrderIds.length) {
        return false;
      }

      const routeObject = {
        name: 'supplier-orders.list',
        query: {
          filter_externalInvoicePdfId: this.externalInvoicePdf.id
        }
      };

      return openInNewTab
        ? this.openRouteInNewTab(routeObject)
        : this.$router.push(routeObject);
    },

    /*
        DELETE
     */
    showDeleteConfirmationModal() {
      this.$refs.deleteConfirmationModal.openModal();
    },
    deleteExternalInvoicePDF() {
      ExternalInvoicePDFRepository
        .deleteExternalInvoicePDF(this.getId)
        .then(() => this.$emit('request-reload'))
        .finally(() => {
          this.$refs.deleteConfirmationModal.closeModal();
        })
    },

    /*
        LINK TO EXISTING INVOICE
     */
    openLinkToExistingModal() {
      this.$refs.linkToExistingModal.openModal();
    },
    linkToExistingInvoice(callBack) {
      ExternalInvoicePDFRepository.linkToExistingInvoice(
        this.getId,
        this.linkToExistingInvoiceId
      ).then(() => {
        this.$sendSuccessToast(`PDF liée avec Facture #${this.linkToExistingInvoiceId}.`);
        this.$refs.linkToExistingModal.closeModal();
        this.$emit('request-reload');
      }).finally(() => callBack())
    }
  },
}
</script>

<style>
.pdf-viewer-wrapper {
  /*background: #444;*/
  overflow-scrolling: touch;
}

.pdf-viewer-wrapper .zoom-active {
  cursor: grab;
}

.pdf-viewer-wrapper .text-selection {
  cursor: default;
}

.pdf-main {
  overflow: hidden;
  /*margin: 30px auto;*/
}

.pdf-viewer--text-layer {
  /*margin: 30px auto;*/
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0;
}

.pdf-viewer--text-layer::selection {
  @apply bg-luxcaddy-400;
}

.pdf-viewer--text-layer span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}
</style>
