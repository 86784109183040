<template>
  <div>
    <page-title>Sélectionner un client pour voir leur panier</page-title>
    <card>
      <member-select-input
        v-model="memberId"
        :only-member-types="['customer']"
        label="Choisir client"
      />

      <button-element
        :disabled="memberId === null"
        class="mt-4"
        disabled-tooltip="Veuillez d'abord sélectionner un client"
        type="primary"
        @click="navigateToCart"
      >
        Voir panier
      </button-element>
    </card>
  </div>
</template>

<script>
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput";
import Card from "@/components/elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle";
import ButtonElement from "@/components/elements/buttons/ButtonElement";

export default {
  name: "CartSelectMember",
  components: {ButtonElement, PageTitle, Card, MemberSelectInput},
  data: () => ({
    memberId: null,
  }),
  methods: {
    navigateToCart() {
      if (!this.memberId)
        return false;

      this.$router.push({
        name: 'carts.edit',
        params: {
          member: this.memberId
        }
      })
    }
  },
}
</script>