<template>
  <div>
    <page-title>
      <span v-if="isCreateMode">Créer appareil</span>
      <span v-else>{{ name }} #{{ deviceId }}</span>
    </page-title>

    <card>
      <form-wrapper
        ref="form"
        :show-changed="true"
        :submit-button-label="isCreateMode ? 'Créer' : 'Sauvegarder'"
        @submit="onSubmit"
      >
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-6">
          <text-input-element
            v-model="imei"
            label="IMEI"
          />
          <text-input-element
            v-model="name"
            label="Nom de l'appareil"
          />
          <text-input-element
            v-model="phoneNumber"
            label="No. de tél."
          />
        </div>
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import DeviceRepository from "@/repositories/DeviceRepository";
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";

export default {
  name: "CreateUpdateDeviceForm",
  components: {Card, PageTitle, TextInputElement, FormWrapper},
  data: () => ({
    imei: '',
    name: '',
    phoneNumber: '',
  }),
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    deviceId: {
      type: Number,
      default: null
    }
  },
  mounted() {
    if (!this.isCreateMode) {
      DeviceRepository
        .getDevice(this.deviceId)
        .then((res) => {
          this.imei = res.data.data.imei;
          this.name = res.data.data.name;
          this.phoneNumber = res.data.data.phoneNumber;

          this.$nextTick(() => this.$refs.form.resetForm());
        });
    }
  },
  methods: {
    onSubmit(callback) {
      if (this.isCreateMode) {
        return this.createDevice().finally(() => callback());
      } else {
        return this.updateDevice().finally(() => callback());
      }
    },

    createDevice() {
      return DeviceRepository.createDevice({
        imei: this.imei,
        name: this.name,
        phoneNumber: this.phoneNumber,
      }).then((res) => {
        this.$sendSuccessToast("Terminal crée!");
        this.$router.push({
          name: 'devices.edit',
          params: {
            id: res.data.data.id
          }
        })
      })
    },
    updateDevice() {
      return DeviceRepository.updateDevice(this.deviceId, {
        imei: this.imei,
        name: this.name,
        phoneNumber: this.phoneNumber,
      }).then(() => {
        this.$sendSuccessToast("Terminal modifié!");
        this.$nextTick(() => this.$refs.form.resetForm());
      })
    }
  },
  computed: {
    isCreateMode() {
      return this.mode === 'create';
    }
  },
}
</script>

