<template>
  <modal
    ref="modal"
    :show-close-button="true"
    :title="title"
    mobile-max-width
  >
    <slot></slot>

    <div class="mt-4 p-4 bg-gray-100 ml-auto mr-auto flex flex-col gap-y-2 rounded-md text-center">
      <div class="font-bold text-center mb-2 tracking-wide text-gray-700 ">
        Actions rapides
      </div>

      <div
        :class="{'w-132': limitLinkWidth}"
        class="flex flex-wrap gap-2 items-center justify-center"
      >
        <button-element
          v-for="link in getLinks"
          :key="link.label"
          :disabled="link.disabled !== undefined && link.disabled"
          :disabled-tooltip="link.disabledReason !== undefined ? link.disabledReason : undefined"
          size="small"
          type="light"
          @click="executeAction(link, false)"
          @click-ctrl="executeAction(link, true)"
        >
          <fa-icon :icon="link.icon" class="mr-2" fixed-width/>
          {{ link.label }}
        </button-element>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import {userHasPermission} from "@/helpers/permissionHelper";
import newTabMixin from "@/mixins/newTabMixin";

export default {
  name: "QuickActionModalBase",
  components: {ButtonElement, Modal},
  props: {
    title: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: true
    },
    limitLinkWidth: {
      type: Boolean,
      default: false
    }
  },
  mixins: [newTabMixin],
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },

    checkPermissions(linkObject) {
      if (typeof linkObject.permissions === "undefined") {
        return true;
      }

      const userPermissions = this.$store.getters['authUser/getPermissions'];
      return userHasPermission(linkObject.permissions, userPermissions);
    },

    executeAction(linkObject, newTab = false) {
      if (!this.checkPermissions(linkObject)) {
        return false;
      }

      if (typeof linkObject.action === "undefined") {
        newTab
          ? this.openRouteInNewTab(linkObject.route)
          : this.$router.push(linkObject.route);
      } else {
        linkObject.action();
      }

      // Close Modal after
      if ((typeof linkObject.closeAfter === "undefined" || linkObject.closeAfter === true) && newTab === false) {
        this.closeModal();
      }
    },
    closeModal() {
      if (this.$refs.modal) {
        this.$refs.modal.closeModal();
      }
    }
  },
  computed: {
    getLinks() {
      return this.links.filter(linkObject => this.checkPermissions(linkObject));
    }
  },
}
</script>