<template>
  <select-tag-element
    :label="label"
    :multi="selectMultiple"
    :occupy-validation-space="occupyValidationSpace"
    :tags="availableTags"
    :value="value"
    class="flex-shrink-0"
    @input="$emit('input', $event)"
  />
</template>

<script>
import SelectTagElement from "./Tags/SelectTagElement";
import TagsRepository from "../../../../repositories/TagsRepository";

export default {
  name: "ProductTagSelectionInput",
  components: {SelectTagElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Tags',
      required: false,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    selectMultiple: {
      type: Boolean,
      default: true
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    if (this.options === undefined) {
      this.fetchTags();
    } else {
      this.availableTags = this.options;
    }
  },
  methods: {
    fetchTags() {
      TagsRepository
        .getProductTags()
        .then((res) => {
          this.availableTags = res.data.data
        });
    }
  },
  data: () => ({
    availableTags: [],
  })
}
</script>

