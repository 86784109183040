<template>
  <modal ref="createProductCategoryModal" title="Créer categorie">
    <category-create-or-update-form
      :parent-id="parentId"
      mode="create"
      @done="closeModal()"
    />
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal";
import CategoryCreateOrUpdateForm from "@/components/pages/Categories/Common/Forms/CategoryCreateOrUpdateForm";

export default {
  name: "ProductCategoryCreateModal",
  components: {CategoryCreateOrUpdateForm, Modal},
  props: {
    parentId: {
      type: Number,
      default: null,
    }
  },
  methods: {
    openModal() {
      this.$refs.createProductCategoryModal.openModal();
    },
    closeModal() {
      this.$refs.createProductCategoryModal.closeModal();
    }
  }
}
</script>

