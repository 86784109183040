<template>
  <form-wrapper
    ref="form"
    :show-changed="true"
    @submit="createOrUpdate"
  >
    <text-input-element
      v-model="getBanner.name"
      label="Nom"
      validation-rules="required"
    />

    <card
      v-if="isBannerOfTypeImage"
      ref="card"
      size="small"
      title="Utiliser les données de ce modèle"
      type="light"
    >
      <select-element
        v-model="getBanner.bannerableType"
        :enable-search="false"
        :options="getPossibleBannerableTypes"
        label="Type"
      />

      <number-input-element
        v-model="getBanner.bannerableId"
        label="ID"
      />
    </card>

    <banner-link-type-selection
      v-if="isCreateMode || (isUpdateMode && bannerFetched)"
      class="mt-4"
      @loaded="$refs.form.resetForm()"
    />

    <banner-page-link-management
      class="mt-6"
    />

  </form-wrapper>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import {mapActions, mapGetters, mapMutations} from "vuex";
import BannerLinkTypeSelection from "@/components/pages/Banner/Management/Common/BannerLinkTypeSelection";
import BannerPageLinkManagement from "@/components/pages/Banner/Management/Common/PageLinks/BannerPageLinkManagement";
import Card from "@/components/elements/cards/Card";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CreateOrUpdateBannerForm",
  components: {
    SelectElement,
    NumberInputElement,
    Card,
    BannerPageLinkManagement,
    BannerLinkTypeSelection,
    TextInputElement,
    FormWrapper
  },
  data: () => ({
    bannerFetched: false,
  }),
  props: {
    mode: {
      type: String,
      validator: val => ["create", "update"].includes(val),
      default: "create"
    },
    bannerId: {
      type: Number,
    },
    // Only used in create mode
    bannerType: {
      type: String,
    }
  },
  watch: {
    bannerType(type) {
      this.setBannerType(type);
    }
  },
  created() {
    this.resetBanner();

    this.$nextTick(() => {
      if (this.isUpdateMode) {
        this
          .fetchBanner(this.bannerId)
          .then(() => {
            this.resetForm();
            this.bannerFetched = true;
          });
      } else {
        this.getBanner.type = this.bannerType;
      }
    })
  },
  methods: {
    ...mapActions('banners', [
      'fetchBanner', 'createBanner', 'updateBanner',
    ]),
    ...mapMutations('banners', [
      'resetBanner', 'setBannerType'
    ]),
    resetForm() {
      this.$refs.form.resetForm();
    },
    dispatchCreateBanner(callback) {
      return this.createBanner().then((res) => {
        this.$sendSuccessToast("Bannière crée!");

        this.$nextTick(() => {
          this.$router.push({
            name: 'banners.edit',
            params: {
              banner: res.data.data.id
            }
          })
        });
      }).finally(() => callback());
    },
    dispatchUpdateBanner(callback) {
      return this.updateBanner().then(() => {
        this.$sendSuccessToast("Bannière modifiée!");
        this.resetForm();
      }).finally(() => callback());
    },
    createOrUpdate(callback) {
      if (this.isCreateMode) {
        this.dispatchCreateBanner(callback);
      } else if (this.isUpdateMode) {
        this.dispatchUpdateBanner(callback);
      }
    },
  },
  computed: {
    ...mapGetters('banners', [
      'getBanner'
    ]),
    isUpdateMode() {
      return this.mode === "update";
    },
    isCreateMode() {
      return this.mode === "create";
    },
    getPossibleBannerableTypes() {
      return this
        .$luxcaddyConfig('banner.bannerableTypes')
        .map(bannerableType => {
          return {
            label: bannerableType.label,
            value: bannerableType.model
          }
        });
    },
    isBannerOfTypeImage() {
      return this.getBanner.type === "image";
    }
  },
}
</script>

<style scoped>

</style>