import { render, staticRenderFns } from "./SupplierOrderStatus.vue?vue&type=template&id=818c6d62&scoped=true&"
import script from "./SupplierOrderStatus.vue?vue&type=script&lang=js&"
export * from "./SupplierOrderStatus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "818c6d62",
  null
  
)

export default component.exports