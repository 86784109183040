<template>
  <div>
    <form-label v-if="!!label" :label="label" :show-required-mark="false" :size="labelSize"
                class="mb-1"></form-label>

    <div
      :class="{'opacity-40 cursor-not-allowed': disabled}"
      class="h-full flex flex-col"
    >
      <div class="flex items-center">
        <div v-if="disabledIcon || disabledText" :class="[isActive ? 'text-gray-300' : 'text-gray-500']"
             class="flex items-center">
          <span v-if="disabledText" class="text-xs mr-2">{{ disabledText }}</span>
          <i v-if="disabledIcon" :class="`fa-` + disabledIcon" class="fas"></i>
        </div>
        <!-- Switch Container -->
        <div
          :class="{'bg-luxcaddy-300': disabled, 'bg-gray-300': !isActive, 'ml-3': disabledIcon || disabledText,  'mr-3': (enabledIcon || enabledText), 'w-14 h-7': !small, 'w-7 h-3': small}"
          :style="getStyles"
          class=" flex-shrink-0 flex items-center rounded-full px-1 transition duration-150 ease-in-out cursor-pointer"
          @click="onSwitchClicked">
          <!-- Switch -->
          <div
            :class="{'translate-x-7': isActive && !small, 'translate-x-3': isActive && small, 'w-5 h-5': !small, 'w-2 h-2': small}"
            class="bg-white rounded-full shadow-md transform transition duration-150 ease-in-out">
          </div>
        </div>
        <div v-if="enabledIcon || enabledText" :class="[isActive ? 'text-gray-500' : 'text-gray-300']"
             class="flex items-center">
          <i v-if="enabledIcon" :class="`fa-` + enabledIcon" class="fas"></i>
          <span v-if="enabledText" class="text-xs ml-2">{{ enabledText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormLabel from "../forms/elements/partials/FormLabel";

export default {
  name: "ToggleElement",
  components: {FormLabel},
  data: () => ({
    isToggleActive: false
  }),
  props: {
    // Whether the toggle can be used at all
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: false,
    },
    labelSize: {
      type: String,
      validator: val => ["normal", "xsmall"].includes(val),
      default: "normal"
    },
    disabledText: {
      type: String,
      required: false
    },
    enabledText: {
      type: String,
      required: false
    },
    disabledIcon: {
      type: String,
      required: false
    },
    enabledIcon: {
      type: String,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    },
    hexColor: {
      type: String,
      default: "#5D6B28",
    }
  },
  computed: {
    isActive() {
      return this.value;
    },

    getStyles() {
      if (this.isActive && !this.disabled) {
        return 'background-color: ' + this.hexColor;
      }
      return null;
    }
  },
  methods: {
    onSwitchClicked() {
      if (!this.disabled) {
        return this.$emit('input', !this.value);
      }
    }
  }
}
</script>

