<template>
  <div>

    <div
      :class="onTouchInterface ? 'border-gray-600' : 'border-gray-100'"
      class="px-2 md:px-4 lg:px-8 pb-4 flex justify-between border-b-2"
    >
      <button-element
        :disabled="!canGoToPreviousPage"
        :size="onTouchInterface ? 'small' : 'xsmall'"
        type="light"
        @click="goToPreviousPage"
      >
        &lt; Plus récent
      </button-element>

      <button-element
        :disabled="!canGoToNextPage"
        :size="onTouchInterface ? 'small' : 'xsmall'"
        type="light"
        @click="goToNextPage"
      >
        Plus ancien &gt;
      </button-element>
    </div>

    <div
      class="my-4 px-2 md:px-4 lg:px-8 flex flex-col space-y-4"
    >
      <dashboard-notification-menu-notification
        v-for="(notification, i) in getNotifications"
        :key="notification.id"
        :is-first="i === 0"
        :notification="notification"
        :on-touch-interface="onTouchInterface"
      />
    </div>

    <div
      v-if="getNotifications.length >= 8"
      :class="onTouchInterface ? 'border-gray-600' : 'border-gray-100'"
      class="px-2 md:px-4 lg:px-8 py-4 flex justify-between border-t-2"
    >
      <button-element
        :disabled="!canGoToPreviousPage"
        :size="onTouchInterface ? 'small' : 'xsmall'"
        type="light"
        @click="goToPreviousPage"
      >
        &lt; Plus récent
      </button-element>

      <button-element
        :disabled="!canGoToNextPage"
        :size="onTouchInterface ? 'small' : 'xsmall'"
        type="light"
        @click="goToNextPage"
      >
        Plus ancien &gt;
      </button-element>
    </div>
  </div>
</template>

<script>
import DashboardNotificationMenuNotification
  from "@/components/layouts/dashboard/partials/notifications/DashboardNotificationMenuNotification.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import {mapGetters} from "vuex";

export default {
  name: "DashboardNotificationList",
  components: {ButtonElement, DashboardNotificationMenuNotification},
  props: {
    /**
     * When set to true, it means we are displaying the notification in the
     * menu on the touch interface, instead of the regular Backoffice menu.
     */
    onTouchInterface: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.fetchNotifications(1);
  },
  methods: {
    goToNextPage() {
      if (!this.canGoToNextPage)
        return false;

      this.fetchNotifications(this.getCurrentPage + 1);
    },
    goToPreviousPage() {
      if (!this.canGoToPreviousPage)
        return false;

      this.fetchNotifications(this.getCurrentPage - 1);
    },
    fetchNotifications(page = 1) {
      this.$store.dispatch('notifications/fetchNotifications', page);
    }
  },
  computed: {
    ...mapGetters('notifications', [
      'isMenuOpen',
      'getNotifications',
      'canGoToPreviousPage',
      'canGoToNextPage',
      'getCurrentPage'
    ])
  },
}
</script>