<template>
  <div>
    <form-wrapper
      ref="form"
      :use-button-spacing="false"
      class="flex flex-col md:flex-row gap-x-4 items-center"
      submit-button-label="Créer & lier"
      @submit="createAndLink"
    >
      <div class="flex gap-4">
        <text-input-element
          v-model="invoiceId"
          class="w-full md:w-56"
          label="ID Facture"
          validation-rules="required"
        />

        <currency-input-element
          v-model.number="amount"
          :inset-text="getCurrency === 'EUR' ? '€' : '$'"
          allow-negative
          class="w-full md:w-56"
          label="Montant"
          validation-rules="required"
        />
      </div>
    </form-wrapper>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import CurrencyInputElement from "@/components/elements/forms/elements/CurrencyInputElement";
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "@/eventBus";

export default {
  name: "BankMovementCreateAndLinkTransaction",
  components: {CurrencyInputElement, TextInputElement, FormWrapper},
  data: () => ({
    invoiceId: null,
    amount: null
  }),
  created() {
    this.amount = this.getBankMovementUnattributedAmount();
  },
  methods: {
    ...mapActions('bankMovements', [
      'createAndLinkTransactions'
    ]),
    ...mapGetters('bankMovements', [
      'getBankMovementUnattributedAmount'
    ]),
    createAndLink(callback) {
      this.createAndLinkTransactions([
        {
          invoiceId: this.invoiceId,
          amount: this.amount,
        }
      ]).then(() => {
        this.$sendSuccessToast('La transaction a été créée et liée.');
        this.invoiceId = null;
        this.amount = this.getBankMovementUnattributedAmount();
        eventBus.$emit('bank-movement-change');
      }).finally(() => callback());
    }
  },
  computed: {
    ...mapGetters('bankMovements', [
      'getCurrency'
    ]),
  },
}
</script>