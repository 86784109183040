import auth from "../../middlewares/authMiddleware";
import ArrivalTemperaturesPage
  from "@/components/pages/TouchInterface/Stock/ArrivalTemperatures/ArrivalTemperaturesPage";
import FruitsVegetablesVisualStockManagementPage
  from "@/components/pages/TouchInterface/Stock/FruitsVegetables/FruitsVegetablesVisualStockManagementPage";
import ProductIncomeInterfacePage
  from "@/components/pages/TouchInterface/Stock/ProductIncome/ProductIncomeInterfacePage";
import ScanEditInterfacePage from "@/components/pages/TouchInterface/Stock/ScanEdit/ScanEditInterfacePage";
import ProductIncomeTodayInterfacePage
  from "@/components/pages/TouchInterface/Stock/ProductIncomeToday/ProductIncomeTodayInterfacePage.vue";
import ProductIncomeTodayEditInterfacePage
  from "@/components/pages/TouchInterface/Stock/ProductIncomeToday/Edit/ProductIncomeTodayEditInterfacePage.vue";

export default [
  {
    path: '/touch/stock/arrival-temperatures',
    name: 'touchInterface.stock.arrival-temperatures.index',
    component: ArrivalTemperaturesPage,
    meta: {
      title: 'Temperatures',
      layout: 'TouchInterface',
      permissions: ['TOUCH_INTERFACE_ACCESS'],
      middleware: [auth],
    }
  },
  {
    path: '/touch/stock/fruits-vegetables',
    name: 'touchInterface.stock.fruits-vegetables.index',
    component: FruitsVegetablesVisualStockManagementPage,
    meta: {
      title: 'Stock Fruits & Légumes',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/stock/income',
    name: 'touchInterface.stock.income.index',
    component: ProductIncomeInterfacePage,
    meta: {
      title: 'Stock - Arrivage',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/stock/income/today',
    name: 'touchInterface.stock.income-today.index',
    component: ProductIncomeTodayInterfacePage,
    meta: {
      title: 'Stock - Arrivage aujourd\'hui',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/stock/income/today/:order/edit',
    name: 'touchInterface.stock.income-today.edit',
    component: ProductIncomeTodayEditInterfacePage,
    meta: {
      title: 'Stock - Valider arrivage',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/stock/scan-edit',
    name: 'touchInterface.stock.scan-edit.index',
    component: ScanEditInterfacePage,
    meta: {
      title: 'Stock - Scan & Modifier',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
]
