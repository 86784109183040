<template>
  <div class="py-2 px-4">
    <list-table
      ref="listTable"
      :columns="columns"
      :createButton="false"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      identifier="supplierOrdersWithIncomeForTodayTable"
      title="Arrivage d'aujourd'hui"
    >
    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable.vue";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";

export default {
  name: "ProductIncomeTodayInterfacePage",
  components: {ListTable},
  data() {
    return {
      selectedOrderToSetInvoiceId: null,

      title: "Arrivage d'aujourd'hui",
      repositoryFunc: SupplierOrderRepository.getPaginatedWithIncomeForToday,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir commande',
              route: (row) => ({
                name: 'touchInterface.stock.income-today.edit',
                params: {
                  order: row.id,
                },
              })
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
        },
        {
          caption: 'Fournisseur',
          dataField: 'supplierName',
        },
        {
          caption: "Qté. commandé",
          dataField: 'total.quantity',
        },
        {
          caption: "Traité",
          calculateCellValue: (r) => {
            return r.treatedPercentage + "%";
          },
        },
        {
          caption: "Envoyé le",
          dataField: 'sentAt',
          calculateCellValue: (r) => {
            if (r.sentAt === null)
              return "/";

            return this.$date(r.sentAt).format('DD.MM.YYYY HH:mm');
          },
        },

      ],
      filters: []
    }
  },
}
</script>

<style scoped>

</style>