import ReportQueryRepository from "@/repositories/ReportQueryRepository";


const getDefaultState = () => {
  return {
    initialLoad: false,
    graphsToLoadData: [],
    dataSets: [],
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getGraphIdsToLoad: state => state.graphsToLoadData,
    hasLoadedDataSetForId: state => (id) => {
      return state.dataSets.find(d => d.id === id) !== undefined;
    },
    getDataSetForId: state => (id) => {
      return state.dataSets.find(d => d.id === id)?.data;
    },

    hasInitiallyLoaded: state => {
      return state.initialLoad;
    }
  },
  mutations: {
    setGraphsToLoadData(state, data) {
      state.graphsToLoadData = data;
      state.initialLoad = true;
    },
    addGraphDataSet(state, {id, data}) {
      // Remove old dataset for this graph
      state.dataSets = state.dataSets.filter(d => d.id !== id);

      state.dataSets.push({
        id,
        data: data
      });
    }
  },
  actions: {
    fetchGraphById({commit}, graphId) {
      ReportQueryRepository.graphs.getGraphDataById(graphId).then((res) => {
        commit('addGraphDataSet', {
          id: graphId,
          data: res.data.data
        });
      })
    },
    fetchGraphs({commit, getters}) {
      ReportQueryRepository.graphs.getIdsForGraphs(!getters.hasInitiallyLoaded).then((res) => {
        const graphIds = res.data.data.flatMap(d => d.id);
        commit('setGraphsToLoadData', res.data.data);

        graphIds.forEach(graphId => {
          ReportQueryRepository.graphs.getGraphDataById(graphId).then((res) => {
            commit('addGraphDataSet', {
              id: graphId,
              data: res.data.data
            });
          })
        });
      });
    },
  },
}