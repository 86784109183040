<template>
  <div
    :class="[textSizeClass, paddingClasses]"
    class="bg-yellow-600 rounded-md text-white flex items-center w-min"
  >
    <fa-icon class="mr-1" fixed-width icon="gift"/>
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "ProductCardGiftCampaignBadge",
  props: {
    name: {
      type: String,
      required: true
    },
    textSizeClass: {
      type: String,
      default: "text-sm"
    },
    paddingClasses: {
      type: String,
      default: 'px-2 py-0.5'
    }
  }

}
</script>

<style scoped>

</style>
