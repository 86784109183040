import InvoiceRepository from "@/repositories/InvoiceRepository";

export default {
  selectValues: invoice => ({
    id: invoice.id
  }),
  selectValuesFromId: invoiceId => ({id: invoiceId}),
  batchActions: [
    {
      label: "Export Multiline (envoyé par mail)",
      action: (callBack, selectedRows) => {
        return InvoiceRepository.executeBatchAction(
          "multilineExport",
          selectedRows.flatMap(i => i.id)
        ).finally(() => callBack());
      }
    },
    {
      label: "Export Multiline (export)",
      action: (callBack, selectedRows) => {
        let additionalFields = {};
        additionalFields.exportXML = true;
        return InvoiceRepository.executeBatchAction(
          "multilineExport",
          selectedRows.flatMap(i => i.id),
          additionalFields
        ).finally(() => callBack());
      }
    },
    {
      label: "Lier une mouvement bancaire",
      additionalFields: [
        {
          label: "Id Movement bancaire",
          type: "number",
          param: "bankMovementId",
          validationRules: 'required',
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return InvoiceRepository.executeBatchAction(
          "linkToBankMovement",
          selectedRows.flatMap(i => i.id),
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Report generation",
      skipConfirmationModal: true,
      action: (callBack, selectedRows) => {
        return InvoiceRepository.executeBatchAction(
          "generateReport",
          selectedRows
        ).finally(() =>  callBack());
      }
    },
  ],
};