import FeeRepository from "../../../repositories/FeeRepository";

const getDefaultState = () => {
  return {
    fee: {
      id: null,
      name: '',
      startDate: null,
      endDate: null,
      vat: 0,
      price: 0
    },
    rules: []
  }
}

export default {
  state: getDefaultState(),
  getters: {
    hasFeeLoaded: state => state.fee.id !== null,
    getFee: state => state.fee,
    getFeeName: state => state.fee?.name,
    getFeeId: state => state.fee.id,
    getRules: state => state.rules.sort((a, b) => a.appliedOn.toLowerCase().localeCompare(b.appliedOn.toLowerCase())),
  },
  mutations: {
    setFee(state, payload) {
      state.rules = payload.rules === undefined ? [] : payload.rules;

      //delete payload.rules;
      state.fee = payload;
    },
    clearFee(state) {
      state.fee = getDefaultState().fee;
      state.rules = getDefaultState().rules;
    },
    clearRules(state) {
      state.rules = getDefaultState().rules;
    },
    addNewRule(state, context) {
      state.rules.push({
        appliedOn: context,
        filters: [],
        new: true
      })
    },
  },
  actions: {
    fetchSingle({commit}, feeId) {
      return FeeRepository.getSingle(feeId).then((res) => {
        commit('setFee', res.data.data);
      });
    },
    refetchCurrentFee({dispatch, getters}) {
      return dispatch('fetchSingle', getters.getFeeId);
    },
    // eslint-disable-next-line no-empty-pattern
    createFee({}, payload) {
      return FeeRepository.create(payload);
    },
    updateFee({getters}, payload) {
      return FeeRepository.update(getters.getFeeId, payload);
    },
  },
}