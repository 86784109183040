<template>
  <shared-invoice-list-table/>
</template>

<script>
import SharedInvoiceListTable from "@/components/pages/Invoices/Shared/List/SharedInvoiceListTable";

export default {
  name: "ShowInvoices",
  components: {SharedInvoiceListTable}
}
</script>