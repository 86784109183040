<template>
  <select-element
    :limit-option-menu-width="limitOptionMenuWidth"
    :occupy-validation-space="occupyValidationSpace"
    :options="getAvailableGoals"
    :touch-optimized="touchOptimized"
    :value="value"
    multi
    validation-rules="required"
    @input="$emit('input', $event)"
  />
</template>


<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";

export default {
  components: {SelectElement},
  name: "AutomaticRoutePlanningGoalsSelection",
  props: {
    occupyValidationSpace: {
      type: Boolean,
      default: true,
    },
    /**
     * When enabled, the option menu will not have the same length as the  input, when the input is very long
     */
    limitOptionMenuWidth: {
      type: Boolean,
      default: true
    },
    touchOptimized: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    getAvailableGoals() {
      return this.$luxcaddyConfig('route.planningObjectives').map(goal => {
        if (goal.disabledIfSelected !== undefined) {
          return {...goal, $isDisabled: this.model.includes(goal.disabledIfSelected)}
        }

        return goal;
      });
    }
  },
}
</script>

<style scoped>

</style>