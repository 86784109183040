<template>
  <show-missing-stock-table
    :supplier-order-id="getOrderId"
    context="supplierOrderSuggestions"
    @changed="$emit('changed', $event)"
  />

</template>

<script>
import ShowMissingStockTable from "@/components/pages/MissingStock/ShowMissingStockTable.vue";
import {mapGetters} from "vuex";

export default {
  name: "SupplierOrderSuggestionsTable",
  components: {ShowMissingStockTable},
  computed: {
    ...mapGetters('supplierOrders', [
      'getOrderId'
    ])
  },
}
</script>

<style scoped>

</style>