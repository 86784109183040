<template>
  <custom-table :headings="getFilterHeadings">
    <table-row
      v-for="filter in rule.filters"
      :key="filter.property + '-' + filter.operator + '-' + filter.comparedToValue">
      <table-data-cell align="center" class="w/1-3">
        {{ filter.property }}
      </table-data-cell>
      <table-data-cell align="center" class="w/1-3">
        <div class="flex flex-col items-center">
          <fa-icon :icon="filter.operator | convertTextOperatorToIcon"></fa-icon>
        </div>
      </table-data-cell>
      <table-data-cell align="center" class="w/1-3">
        {{ filter.comparedToValue }}
      </table-data-cell>
    </table-row>
  </custom-table>
</template>

<script>
import TableRow from "../../../elements/tables/TableRow";
import CustomTable from "../../../elements/tables/CustomTable";
import TableDataCell from "../../../elements/tables/TableDataCell";

export default {
  name: "RuleFilterTable",
  components: {TableDataCell, CustomTable, TableRow},
  props: {
    rule: {
      type: Object,
      required: true
    }
  },
  computed: {
    getFilterHeadings() {
      return [
        {label: 'Propriété', align: "center"},
        {label: 'Operateur', align: "center"},
        {label: 'Par rapport à', align: "center"},
      ];
    },
  }
}
</script>

