import apiService from './Base/BaseRepository';

const url = '/accounting/booking-accounts';


export default {
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getAllBookingAccounts(memberId = null) {

    let filters = [];
    if (memberId !== null) {
      filters.push({
        filterKey: 'topUsedForMember',
        filterValue: memberId
      })
    }
    return await apiService
      .setPagination(999, 1)
      .setFilters(filters)
      .get(`${url}/`);
  },
  async getSingle(bookingAccountId) {
    return await apiService.get(`${url}/${bookingAccountId}`);
  },

  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  async update(bookingAccountId, payload) {
    return await apiService.patch(`${url}/${bookingAccountId}`, payload);
  },

  async deleteSingle(bookingAccountId) {
    return await apiService.delete(`${url}/${bookingAccountId}`);
  }
}
