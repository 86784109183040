<template>
  <div>
    <button-element class="mb-0.5" type="light" @click="openCopyWeekModal">
      <fa-icon fixed-width icon="copy"/>
      Copier créneaux de la semaine {{ activeWeek }} ({{ activeYear }})
    </button-element>

    <modal ref="copyModal" blocking title="Copier créneaux de livraison">
      <info-alert size="small">
        <div class="mr-2">
          Cette action copie les créneaux de livraison de <span
          class="font-bold text-red-500">tous les zones</span>, not seulement la zone selectionnée!<br>
          Tous les créneaux horaires existants concernés seront supprimés.
        </div>
      </info-alert>

      <form-wrapper
        ref="form"
        :show-changed="true"
        submit-button-label="Copier"
        @submit="bulkCopy"
      >

        <transition name="fade">
          <div v-if="showMissingError"
               class="mt-4 bg-red-400 text-white text-center py-3 px-5 rounded-md shadow">
            Vous devez sélectionner une date de début et de fin !
          </div>
        </transition>

        <date-range-picker-element
          v-model="dateRangeToCopyTo"
          class="mt-3"
          label="Période"
        />

        <template #additionalButtons>
          <button-element type="warning" @click="resetAndCloseModel">
            Annuler
          </button-element>
        </template>
      </form-wrapper>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import Modal from "@/components/elements/modals/Modal";
import InfoAlert from "@/components/elements/alerts/InfoAlert";
import TimeSlotRepository from "@/repositories/TimeSlotRepository";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";

export default {
  name: "BulkCopyTimeSlotsAction",
  components: {DateRangePickerElement, FormWrapper, InfoAlert, Modal, ButtonElement},
  data: () => ({
    dateRangeToCopyTo: {
      startDate: null,
      endDate: null
    },

    showMissingError: false,
  }),
  props: {
    activeWeek: {
      type: Number
    },
    activeYear: {
      type: Number
    }
  },
  methods: {
    openCopyWeekModal() {
      this.$refs.copyModal.openModal();
    },
    resetAndCloseModel() {
      this.dateRangeToCopyTo = {
        startDate: null,
        endDate: null
      };
      if (this.$refs.copyModal) {
        this.$refs.copyModal.closeModal();
      }
    },
    bulkCopy(callback) {
      if (this.dateRangeToCopyTo === null) {
        this.showMissingError = true;
        return;
      }

      this.showMissingError = false;
      TimeSlotRepository.bulkCopy(
        this.activeWeek,
        this.activeYear,
        this.dateRangeToCopyTo.startDate,
        this.dateRangeToCopyTo.endDate
      ).then(() => {
        this.resetAndCloseModel();
        this.$sendSuccessToast("Les créneaux ont étée copiées.")
      }).finally(() => callback());
    }
  },
}
</script>