import auth from "../../middlewares/authMiddleware";
import OrderFulfilmentList
  from "../../../components/pages/TouchInterface/Packaging/Fulfilment/list/OrderFulfilmentList";
import OrderFulfilmentDetails
  from "../../../components/pages/TouchInterface/Packaging/Fulfilment/order/OrderFulfilmentDetails";

export default [
  {
    path: '/touch/fulfilment/orders',
    name: 'touchInterface.fulfilment.orders',
    component: OrderFulfilmentList,
    meta: {
      title: 'Préparation',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/fulfilment/order/:id',
    name: 'touchInterface.fulfilment.orders.details',
    component: OrderFulfilmentDetails,
    meta: {
      title: 'Préparation',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
];