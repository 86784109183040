import apiService from './Base/BaseRepository';

const url = '/racks';

export default {

  /**
   * Fetch all racks
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`)
  },

  /**
   * Creates a new rack
   *
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  /**
   * Updates a given rack
   *
   * @param rackId
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async update(rackId, payload) {
    return await apiService.patch(`${url}/${rackId}`, payload);
  },

  /**
   * Deletes the given rack.
   *
   * @param rackId
   * @return {Promise<AxiosResponse<any>>}
   */
  async delete(rackId) {
    return await apiService.delete(`${url}/${rackId}`);
  },

  /**
   * Set the order of the racks by passing them all as an ordered array of rackIds
   * @param rackIds
   * @return {Promise<AxiosResponse<any>>}
   */
  async setOrder(rackIds) {
    return await apiService.patch(`${url}/setOrder`, {
      racks: rackIds
    });
  }
}