<template>
  <div>
    <page-title>
      {{ name }} #{{ getDepositId }}
    </page-title>

    <card>
      <card-tab id="depositTab" title="Deposit">
        <create-or-update-deposit-form
          mode="update"
          @updateName="onUpdateName"
        />
      </card-tab>
      <card-tab id="localizationTab" icon="globe" title="Traduction">
        <update-deposit-localization-form
          :deposit-id="getDepositId"
        />
      </card-tab>
    </card>
  </div>
</template>

<script>
import CreateOrUpdateDepositForm from "../Forms/CreateOrUpdateDepositForm";
import CardTab from "../../../elements/cards/tabs/CardTab";
import Card from "../../../elements/cards/Card";
import UpdateDepositLocalizationForm from "../Forms/UpdateDepositLocalizationForm";
import PageTitle from "@/components/elements/pages/PageTitle";

export default {
  name: "EditDeposit",
  components: {PageTitle, Card, CreateOrUpdateDepositForm, CardTab, UpdateDepositLocalizationForm},
  data: () => ({
    name: ''
  }),
  computed: {
    getDepositId() {
      return parseInt(this.$route.params.deposit);
    },
  },
  methods: {
    onUpdateName(name) {
      this.name = name;
    }
  },
}
</script>