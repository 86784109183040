<template>
  <div
    :class="[isFullyPicked ? 'bg-white hover:bg-gray-100' : 'bg-yellow-100 hover:bg-yellow-200']"
    class="p-4 shadow-md rounded-md cursor-pointer"
    @click="goToGroup"
  >

    <div class="flex flex-col md:flex-row space-y-3 md:space-y-0">
      <div class="md:w-4/6 flex space-y-3 space-x-3 md:space-x-0 md:space-y-0 md:gap-x-8 items-center">
        <div class="border p-2 border border-black bg-white text-4xl font-medium rounded-md shadow-md">
          {{ group.number }}
        </div>

        <div>
          <div class="text-lg font-bold text-yellow-600">{{ group.department.name }} du
            {{ $date(group.date).format('DD.MM.YY') }}
          </div>
          <div class="italic">{{ group.volume }} cm<sup>3</sup> - crée le
            {{ $date(group.createdAt).format('DD.MM') }} à {{ $date(group.createdAt).format('HH:mm') }}h -
            Première livraison: {{ $date(group.lowestDeliveryDate).format('HH:mm') }}h
          </div>
        </div>
      </div>

      <div class="md:w-2/6 font-bold">
        {{ group.totalPacked }} / {{ group.totalToBePacked }} validés
        <fulfilment-progress :percentage="group.progress"></fulfilment-progress>
      </div>
    </div>
  </div>
</template>

<script>
import FulfilmentProgress from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/FulfilmentProgress";

export default {
  name: "PickingGroupListItem",
  components: {FulfilmentProgress},
  props: {
    group: {
      type: Object
    }
  },
  methods: {
    goToGroup() {
      return this.$router.push({
        name: 'touchInterface.packaging.picking.details',
        params: {group: this.group.id}
      });
    }
  },
  computed: {
    isFullyPicked() {
      return this.group.progress === 100;
    },
  },
}
</script>

