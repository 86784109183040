<template>
  <div v-if="!$isReadOnly()">
    <transition name="fade-up-down">
      <product-search-selection
        :context="context"
        :excluded-product-ids="getExcludedProducts"
        :quantity-to-add="quantityToAdd"
        :show-quantity-selector="true"
        @added="$emit('added')"
      />
    </transition>
  </div>
</template>

<script>
import ProductSearchSelection from "../../../../global/Products/ProductSearchSelection";

export default {
  name: "AddProductToCartOrOrder",
  components: {ProductSearchSelection},
  props: {
    quantityToAdd: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      validator: val => ['cart', 'order'].includes(val)
    },
    context: {
      type: String,
      default: "cart"
    },
  },
  computed: {
    getExcludedProducts() {
      return []
    }
  }
}
</script>

