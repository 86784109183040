<template>
  <div class="flex gap-x-1.5">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ShortcutMenu"
}
</script>

<style scoped>

</style>