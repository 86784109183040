<template>
  <div class="p-4 rounded-md bg-gray-100">
    <div class="flex flex-wrap justify-between">
      <h2 class="font-bold text-lg">
        {{ task.title }}
      </h2>

      <span :class="{'text-red-400': dueInPast}" class="italic font-medium flex-shrink-0">
                A faire {{ $date(task.endDate).format('DD.MM.YYYY') }} le plus târd
            </span>
    </div>

    <div
      v-if="task.description"
      class="text-sm md:text-md italic mt-1.5"
      v-html="task.description"
    />

    <button-element class="mt-6" type="light" @click="markTaskAsDone">
      Tâche accomplie
    </button-element>
  </div>
</template>

<script>
import MyRepository from "@/repositories/MyRepository";
import ButtonElement from "@/components/elements/buttons/ButtonElement";

export default {
  name: "TouchInterfaceTaskCard",
  components: {ButtonElement},
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  methods: {
    markTaskAsDone() {
      MyRepository.tasks.markTaskDone(this.task.id).then(() => {
        this.$sendSuccessToast('Tâche marquée comme âccomplie.');
        this.$emit('reload');
      })
    }
  },
  computed: {
    dueInPast() {
      return this.$date(this.task.endDate).isBefore(this.$date());
    }
  },
}
</script>

<style scoped>

</style>