import auth from "../middlewares/authMiddleware";
import ShowSupplierOrders from "@/components/pages/SupplierOrders/ShowSupplierOrders";
import EditSupplierOrder from "@/components/pages/SupplierOrders/EditSupplierOrder/EditSupplierOrder";
import CreateSupplierOrder from "@/components/pages/SupplierOrders/CreateSupplierOrder/CreateSupplierOrder";

export default [
  {
    path: '/supplier-orders',
    name: 'supplier-orders.list',
    component: ShowSupplierOrders,
    meta: {
      middleware: [auth],
      title: 'Commandes fournisseur',
      permissions: ['ACCESS_SUPPLIER_ORDER_VIEW']
    }
  },
  {
    path: '/supplier-orders/create',
    name: 'supplier-orders.create',
    component: CreateSupplierOrder,
    meta: {
      middleware: [auth],
      title: 'Créer une commande',
      breadcrumbs: ['supplier-orders.list'],
      permissions: ['SUPPLIER_ORDER_WRITE']
    }
  },
  {
    path: '/supplier-orders/:order/edit',
    name: 'supplier-orders.edit',
    component: EditSupplierOrder,
    meta: {
      middleware: [auth],
      title: 'Modifier une commande',
      readOnly: {supported: true, title: 'Voir une commande', permissions: ['SUPPLIER_ORDER_READ']},
      breadcrumbs: ['supplier-orders.list'],
      permissions: ['SUPPLIER_ORDER_WRITE']
    }
  },
];