<template>
  <div>
    <card
      size="small"
      title="Pages sur lesquelles la bannière est liée"
      type="light"
    >
      <transition-group class="flex flex-col gap-y-3" name="flip-list" type="transition">
        <banner-page-link-management-line
          v-for="(pageLink, i) in getBannerPageLinks"
          :key="getKeyByPageLink(pageLink, i)"
          :page-link="pageLink"
          @remove="removePageLinkByIndex(i)"
        />
      </transition-group>

      <button-element
        size="small"
        @click="addPageLink"
      >
        <fa-icon fixed-width icon="plus"/>
        Ajouter lien
      </button-element>
    </card>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import {mapGetters, mapMutations} from "vuex";
import BannerPageLinkManagementLine
  from "@/components/pages/Banner/Management/Common/PageLinks/BannerPageLinkManagementLine";
import ButtonElement from "@/components/elements/buttons/ButtonElement";

export default {
  name: "BannerPageLinkManagement",
  components: {ButtonElement, BannerPageLinkManagementLine, Card},
  computed: {
    ...mapGetters('banners', [
      'getBannerPageLinks'
    ])
  },
  methods: {
    ...mapMutations('banners', [
      'removePageLinkByIndex',
      'addPageLink'
    ]),
    getKeyByPageLink(pageLink, index) {
      return pageLink.pageName + '_' + index;
    }
  },
}
</script>

<style scoped>

</style>