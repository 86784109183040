<!-- This table is used for both Missing Stock page and Supplier orders suggestions -->
<template>
  <div>
    <list-table
      :batch-actions="getBatchActionsByContext"
      :columns="columns"
      :filters="getFiltersByContext"
      :identifier="getIdentifierByContext"
      :pagination="false"
      :repository-func="repositoryFunc"
      :select-values="selectValues"
      :select-values-map="{'itemId': 'productId', 'quantity': 'bundleOrderedQty'}"
      :show-filters-immediately="true"
      :title="getTitleByContext"
      enable-totals
      selectable
    >
      <template v-slot:product-id-template="{ row }">
        <product-quick-action-modal
          :id="row.productId"
        />
      </template>
      <template v-slot:product-name-template="{ row }">
        <product-name
          :custom-name="row.productName"
          :custom-is-published="row.productIsPublished"
          :custom-is-visibility-forced="row.productIsShopVisibilityForced"
          :custom-is-discontinued="row.productIsDiscontinued"
        />
      </template>
    </list-table>
  </div>
</template>

<script>
import ListTable from "../../listTable/ListTable";
import ProductRepository from "@/repositories/ProductRepository";
import {
  FILTER_DATETIME,
  FILTER_PRODUCT_SUPPLIER
} from "@/components/listTable/includes/controls/filters/availableFilters";
import {centsToEuroString} from "@/helpers/mathHelper";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal.vue";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";

export default {
  name: "ShowMissingStockTable",
  components: {ProductName, ProductQuickActionModal, ListTable},
  props: {
    context: {
      type: String,
      validator: val => ["missingStock", 'supplierOrderSuggestions'].includes(val),
      required: true
    },
    supplierOrderId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      repositoryFunc: (sorts, filters) => {
        return this.isMissingStockContext
          ? ProductRepository.stock.getMissingStockDetails(sorts, filters)
          : SupplierOrderRepository.getProductSuggestions(this.supplierOrderId, sorts, filters)
      },
      selectValues: line => {
        return {
          itemId: line.productId,
          quantity: line.bundleOrderedQty
        };
      },
      columns: [
        {
          caption: 'Id',
          cellTemplate: 'product-id-template'
        },
        {
          caption: 'Réf. Fournisseur',
          dataField: 'supplierReferences',
        },
        {
          caption: 'Produit',
          cellTemplate: "product-name-template",

        },
        {
          caption: 'Reel',
          dataField: 'realStock',
        },
        {
          caption: 'Dispo',
          dataField: 'availableStock',
        },
        {
          caption: 'Attente',
          dataField: 'waitingStock',
        },
        {
          caption: 'Qte. par jour',
          dataField: 'averagePerDay',
        },
        {
          caption: 'Stock goal',
          dataField: 'computedStockGoal',
        },
        {
          caption: 'Min stock',
          dataField: 'minStock',
        },
        {
          caption: 'Lot Qty',
          dataField: 'buyingBundleQty',
        },
        {
          caption: 'Order Qty',
          dataField: 'bundleOrderedQty',
        },
        {
          caption: 'isAuto',
          dataType: 'boolean',
          dataField: 'isAutoStock',
          calculateCellValue: (r) => r.isAutoStock,
        },
        {
          caption: 'Date de livraison',
          calculateCellValue: (r) => this.$date(r.expectedDeliveryDate).format('DD.MM.YY'),
          sort: {
            dataField: 'expectedDeliveryDate',
          },
        },
        {
          caption: 'Total',
          dataField: 'totalBuyingPrice',
          dataType: 'euro',
          totals: {
            sum: "totalBuyingPrice",
            formatValue: (val) => centsToEuroString(val)
          },
        },

      ],
      filters: [
        {
          caption: "Nom",
          filterKey: "supplierMemberIdentity",
          type: FILTER_PRODUCT_SUPPLIER,
          forceIsVisible: true,
        },
        {
          caption: "Compute starting from",
          filterKey: "computeStartingFrom",
          type: FILTER_DATETIME,
          forceIsVisible: true,
        },
        {
          caption: "Date de livraison",
          filterKey: "expectedDeliveryDate",
          type: FILTER_DATETIME,
          forceIsVisible: true,
        },
      ]
    }
  },
  computed: {
    isMissingStockContext() {
      return this.context === "missingStock";
    },
    getIdentifierByContext() {
      return this.isMissingStockContext
        ? "missingStockTable"
        : "supplierOrderSuggestionsTable";
    },
    getFiltersByContext() {
      let filters = [
        {
          caption: "Compute starting from",
          filterKey: "computeStartingFrom",
          type: FILTER_DATETIME,
          forceIsVisible: true,
        }
      ];

      if (this.isMissingStockContext) {
        filters.unshift({
          caption: "Nom",
          filterKey: "supplierMemberIdentity",
          type: FILTER_PRODUCT_SUPPLIER,
          forceIsVisible: true,
        });
        filters.push({
          caption: "Date de livraison",
          filterKey: "expectedDeliveryDate",
          type: FILTER_DATETIME,
          forceIsVisible: true,
        })
      }

      return filters;
    },
    getTitleByContext() {
      return this.isMissingStockContext
        ? 'Défauts de Stock'
        : 'Suggestions';
    },
    getBatchActionsByContext() {
      return this.isMissingStockContext
        ? []
        : [
          {
            label: "Ajouter à la commande",
            action: (callBack, selectedRows, additionalParams) => {
              return SupplierOrderRepository.batchAddProductItems(
                this.supplierOrderId,
                selectedRows,
                additionalParams
              ).finally(() => {
                callBack();
                this.$store.dispatch('supplierOrders/fetchOrder', this.supplierOrderId)
                  .finally(() => this.$emit('changed'));
              });
            },
            additionalFields: [
              {
                label: "Forcer",
                type: "checkbox",
                param: "force"
              },
            ],
          },
        ];
    }
  },
}
</script>
