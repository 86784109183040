<template>
  <select-element
    :enable-search="enableSearch"
    :label="label"
    :limit-option-menu-width="limitOptionMenuWidth"
    :multi="type === 'multi'"
    :occupy-validation-space="occupyValidationSpace"
    :options="availableRoles"
    :touch-optimized="touchOptimized"
    :type="type"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import RoleRepository from "@/repositories/RoleRepository";

export default {
  name: "RoleSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Rôle',
      required: false,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      validator: val => ['single', 'multi'].includes(val)
    },
    /**
     * When enabled, the option menu will not have the same length as the  input, when the input is very long
     */
    limitOptionMenuWidth: {
      type: Boolean,
      default: true
    },
    touchOptimized: {
      type: Boolean,
      default: false,
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.options === undefined) {
      this.fetchRoles();
    } else {
      this.availableRoles = this.options;
    }
  },
  methods: {
    fetchRoles() {
      RoleRepository
        .getRoles(999, 1)
        .then((res) => {
          this.availableRoles = res.data.data.map(d => {
            return {
              value: d.id,
              label: d.name,
            };
          });
        });
    }
  },
  data: () => ({
    availableRoles: [],
  })
}
</script>