<template>
  <div :class="[showBorder ? 'border' : '', getCardTypeClasses, rounded ? 'rounded-md' : '']" class="shadow">

    <!-- Title Bar -->
    <div v-if="isTitleContainerShown" :class="[getTitleClasses, rounded ? 'rounded-t-md' : '']"
         class="flex min-w-0 items-center">
      <div
        class="flex min-w-0 justify-between items-center flex-1"
      >
        <div class="min-w-0 font-medium tracking-wide flex space-x-4 items-center">
          <h2 :class="getTitleSizeClass" class="min-w-0">
            <span v-if="title">{{ title }}</span>
            <slot name="title"></slot>
          </h2>

          <fa-icon
            v-if="showHelpIcon"
            class="text-gray-400 hover:text-blue-500 cursor-pointer"
            icon="info-circle"
            @click="$emit('help')"
          />
        </div>
        <fa-icon v-if="icon" :icon="icon"/>
      </div>
    </div>

    <div v-if="hasTabs" :class="[getButtonContainerBackground]" class="font-semibold py-3 px-4">
      <div class="flex flex-row flex-wrap gap-4">
        <card-tab-button
          v-for="(tab, i) in tabsWithVisibleButton"
          :key="i"
          :confirm="anyFormInCardChanged && !(getActiveTab.id === tab.id)"
          :icon="tab.icon"
          :is-active="getActiveTab && tab && getActiveTab.id === tab.id"
          :is-error="isError"
          :is-hidden="!tab.shouldShowButton"
          :size="size"
          :type="type"
          @click="setActiveTab(tab)"
        >
          {{ tab.title }}
        </card-tab-button>
        <card-add-hidden-tabs-button
          v-if="hasHiddenTabs && !isReadOnly && showAddHiddenTabsButton"
          :tab-list="getHiddenTabs"
          @tab-added="tabShown"
        />
      </div>
    </div>

    <div v-if="$slots.default" :class="getContentClasses" class="text-gray-600">
      <slot></slot>
    </div>

    <div
      v-if="$slots.footer"
      :class="{
                'flex flex-row-reverse': !disabledFlexOnFooter,
                'px-8 py-4': size === 'large' && enableFooterPadding,
                'px-4 py-3': size === 'small' && enableFooterPadding,
            }"
      class="border-t border-gray-200"
    >
      <slot name="footer"></slot>
    </div>
  </div>

</template>

<script>
import CardTabButton from "./tabs/CardTabButton";
import CardAddHiddenTabsButton from "./tabs/internals/CardAddHiddenTabsButton";

export default {
  name: "Card",
  components: {CardAddHiddenTabsButton, CardTabButton},
  props: {
    title: {
      type: String,
      required: false
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      validator: val => ['large', 'small', 'paddingless'].includes(val),
      default: 'large'
    },
    rounded: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    showHelpIcon: {
      type: Boolean
    },
    isError: {
      type: Boolean,
      default: false
    },
    enableFooterPadding: {
      type: Boolean,
      default: true
    },
    /**
     * Should be disabled for every card but one
     * if there are multiple cards with tabs on one page.
     */
    navigationThroughQueryParams: {
      type: Boolean,
      default: true
    },
    /**
     * Set this to false if you have more complex logic that relies on the open/close
     * of tabs and don't want to open one tab initially automatically
     */
    setInitialTab: {
      type: Boolean,
      default: true
    },
    showAddHiddenTabsButton: {
      type: Boolean,
      default: true
    },
    disabledFlexOnFooter: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tabs: [],
  }),
  watch: {
    '$route.query.tab'(tabId) {
      // Set the active Tab correctly if the user uses the browser back function
      if (this.navigationThroughQueryParams) {
        if (tabId && this.getActiveTab && (tabId !== this.getActiveTab.id)) {
          this.setActiveTabById(tabId);
        }
      }
    }
  },
  mounted() {
    this.setTabs();

    if (this.setInitialTab && this.hasTabs) {
      if (this.navigationThroughQueryParams && this.hasRequestedNavigationThroughQueryParam) {
        this.setActiveTabById(this.getTabToNavigateTo);
      } else {
        this.setActiveTab(this.tabs[0]);
      }
    }
  },
  methods: {
    /**
     * Sets the active tabs.
     * Active = The tab which the contents if shown of.
     */
    setActiveTab(tab) {
      if (this.navigationThroughQueryParams && (this.getActiveTab && tab.id !== this.getActiveTab.id)) {
        this.$router.push({query: {...this.$route.query, tab: tab.id}})
      }
      this.tabs.map(t => t.active = t === tab);
    },

    /**
     * Sets the active tab to tabId.
     * Can be used by the parent by setting a reference ($refs).
     */
    setActiveTabById(tabId) {
      this.tabs.map(t => t.active = t.id === tabId);
    },

    /**
     * Fetches the available tabs by searching for them recursively in any children.
     */
    setTabs() {
      this.$children.forEach(c => this.lookForTabsInChildren(c));
    },

    /**
     * Recursively checks if any children are tabs.
     * @param node
     */
    lookForTabsInChildren(node) {
      if (node.$options.name === 'CardTab' && (node.$parent === this)) {
        return this.tabs.push(node);
      }
      node.$children?.forEach(c => {
        this.lookForTabsInChildren(c);
      });
    },

    /**
     * A Tab became available, as the user selected it using the "+" Button
     * @param tabId
     */
    tabShown(tabId) {
      this.setActiveTabById(tabId);
    },
  },
  computed: {
    getCardId() {
      return this._uid;
    },

    /**
     * Determines if any form inside of this card has unsaved changes.
     */
    anyFormInCardChanged() {
      return this.$store.getters['forms/getDisplayedForms'].filter(f => {
        return f.cardParentId === this.getCardId
          && f.changed === true;
      }).length > 0;
    },

    /**
     * Determines if there are any tabs in the card.
     * @return {boolean}
     */
    hasTabs() {
      return this.tabs.length > 0;
    },
    /**
     * Returns the tabs that can by hidden be the user.
     * @return {*[]}
     */
    getHiddenTabs() {
      return this.tabs.filter(t => t.isHiddenInternal);
    },
    /**
     * Returns true if there are tabs that can be hidden by the user.
     * @return {boolean}
     */
    hasHiddenTabs() {
      return this.getHiddenTabs.length > 0;
    },
    /**
     * Returns the Tabs that should show their respective button to switch to them.
     * @return {*[]}
     */
    tabsWithVisibleButton() {
      return this.tabs.filter(t => t.shouldShowButton);
    },
    /**
     * Returns the active tab the users currently sees the content of.
     * @return {null|*}
     */
    getActiveTab() {
      if (!this.hasTabs) {
        return null;
      }

      return this.tabs
        .find(t => t.active === true);
    },
    /**
     * Determines if the card is in ReadOnly mode.
     * @return {any}
     */
    isReadOnly() {
      return this.$isReadOnly();
    },

    /**
     * Determines if it has been requested to a specific tab using a vue router query param.
     * @return {boolean}
     */
    hasRequestedNavigationThroughQueryParam() {
      return this.$route.query.tab !== undefined;
    },

    /**
     * Returns the tab that has been requested to navigate to through a vue router query param.
     * @return {string|(string | null)[]|null}
     */
    getTabToNavigateTo() {
      if (this.hasRequestedNavigationThroughQueryParam) {
        return this.$route.query.tab;
      }
      return null;
    },

    getCardTypeClasses() {
      switch (this.type) {
        case 'danger':
          return "bg-red-200 border-red-300";
        case 'light':
        case 'primary-light':
          return "bg-gray-50 border-gray-300";
        default:
          return "bg-white border-gray-300";
      }
    },

    getTitleSizeClass() {
      switch (this.size) {
        default:
        case 'large':
          return "text-xl";
        case 'small':
          return "text-md";
      }
    },

    getContentClasses() {
      switch (this.size) {
        default:
        case 'large':
          return "px-6 pb-6 pt-6";
        case 'small':
          return "p-4";
        case 'paddingless':
          return "";
      }
    },

    getTitleClasses() {
      let classes = "";

      switch (this.size) {
        case 'large':
          classes += "py-4 px-6 ";
          break;
        case 'small':
          classes += "py-2 px-3 ";
          break;
      }

      switch (this.type) {
        case 'danger':
          classes += "text-white bg-red-200";
          break;
        case 'light':
          classes += "bg-gray-600 text-white";
          break;
        case 'primary-light':
          classes += 'bg-luxcaddy-200 text-gray-700'
          break;
        default:
          classes += "text-white bg-luxcaddy-600";
          break;
      }

      return classes;
    },

    isTitleContainerShown() {
      return this.title || this.showBackButton || this.icon || this.showHelpIcon || this.$slots.title;
    },

    getButtonContainerBackground() {
      if (this.isError) {
        return 'bg-red-600';
      }
      switch (this.type) {
        default:
        case "primary":
          return this.isTitleContainerShown ? 'bg-luxcaddy-300' : 'bg-luxcaddy-500';
        case "light":
          return this.isTitleContainerShown ? 'bg-gray-300' : 'bg-gray-500';
      }
    }
  }
}
</script>

