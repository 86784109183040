<template>
  <td
    :class="[getClassByAlign, disablePadding ? '' : 'py-1.5 px-4']"
    :colspan="colspan"
    class="bg-transparent z-40"
  >
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "TableDataCell",
  props: {
    align: {
      type: String,
      default: 'left'
    },
    colspan: {
      type: [String, Number],
      default: 1,
    },
    disablePadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClassByAlign() {
      switch (this.align) {
        case "center":
          return 'text-center';
        case "right":
          return 'text-right clear-both';
        default:
          return 'clear-both';
      }
    }
  }
}
</script>

