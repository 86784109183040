<template>
  <div>
    <time-picker-element
      v-model="departureOnlyTime"
      :color="color"
      :occupy-validation-space="false"
      class="w-max"
      @validate="updateTimestamp"
    ></time-picker-element>
  </div>
</template>

<script>
import TimePickerElement from "@/components/elements/forms/elements/base/TimePickerElement";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "RouteManagerDepartureTime",
  components: {TimePickerElement},
  props: {
    routeId: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      default: '#9EB544'
    }
  },
  data: () => ({
    departureOnlyTime: null,
  }),
  created() {
    this.departureOnlyTime = this.$date(this.getRoute.departure).format('HH:mm');
  },
  methods: {
    ...mapMutations('routeManager', [
      'setRouteDepartureTime'
    ]),
    ...mapActions('routeManager', [
      'updateRoute'
    ]),
    updateTimestamp() {
      const split = this.departureOnlyTime.split(':');
      this.fullDepartureTime = this.$date(this.getSelectedDate)
        .hour(split[0])
        .minute(split[1])
        .second(0);
    }
  },
  computed: {
    ...mapGetters('routeManager', [
      'getSelectedDate'
    ]),
    getRoute() {
      return this.$store.getters['routeManager/getRouteById'](this.routeId);
    },
    fullDepartureTime: {
      get() {
        return this.getRoute.departure;
      },
      set(value) {
        this.setRouteDepartureTime({
          routeId: this.routeId,
          departure: value
        });
        this.updateRoute({
          routeId: this.routeId,
          driverId: this.getRoute.driverMemberId,
          departure: value ? value.format('YYYY-MM-DD HH:mm:ss') : null
        });
      }
    }
  },
}
</script>

