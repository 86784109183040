import ApiService from './Base/BaseRepository';
import apiService from './Base/BaseRepository';

const url = '/banners';

export default {
  /**
   * Fetches all banners
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await ApiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/`);
  },

  /**
   * Fetches a single banner.
   * @param bannerId
   * @returns {Promise<*>}
   */
  async getSingle(bannerId) {
    return await ApiService
      .get(`${url}/${bannerId}`);
  },

  /**
   * Updates a banner.
   * @param bannerId
   * @param payload
   * @returns {Promise<*>}
   */
  async create(payload) {
    return await ApiService
      .post(`${url}/`, payload);
  },

  /**
   * Updates a banner.
   * @param bannerId
   * @param payload
   * @returns {Promise<*>}
   */
  async update(bannerId, payload) {
    return await ApiService
      .patch(`${url}/${bannerId}`, payload);
  },

  /**
   * Deletes a given banner.
   *
   * @param bannerId
   * @returns {Promise<AxiosResponse<any>>}
   */
  async delete(bannerId) {
    return await ApiService.delete(`${url}/${bannerId}`);
  },


  localization: {
    async getForBanner(bannerId) {
      return await apiService.get(`${url}/${bannerId}/localization`);
    },

    async saveForBanner(bannerId, payload) {
      return await apiService.post(`${url}/${bannerId}/localization`, payload);
    },

    async translate(bannerId, sourceLanguage, targetLanguages, attributes) {
      return await apiService.post(`${url}/${bannerId}/localization/translation`, {
        sourceLanguage, targetLanguages, attributes
      });
    }
  },
}
