<template>
  <div>
    <router-link
      v-if="isRouterLink"
      :class="getClassesByType"
      :to="to"
      class="relative w-8 h-8 md:w-12 md:h-12 lg:h-16 lg:w-16 flex items-center justify-center rounded-lg cursor-pointer hover:duration-300 hover:ease-linear focus:bg-white"
    >
      <div
        v-if="getBadgeCount !== 0"
        class="absolute rounded-full bg-red-500 text-white w-6 h-4 lg:h-6 lg:w-6 text-xs top-0 -right-3.5 lg:right-0 text-center lg:pt-1"
      >
        {{ getBadgeCount }}
      </div>
      <fa-icon :icon="icon" class="text-lg md:text-2xl lg:text-3xl"></fa-icon>
    </router-link>
    <div
      v-else
      :class="getClassesByType"
      class="relative w-8 h-8 md:w-12 md:h-12 lg:h-16 lg:w-16 flex items-center justify-center rounded-lg cursor-pointer hover:duration-300 hover:ease-linear focus:bg-white"
      @click="emitClick"
    >
      <div
        v-if="getBadgeCount !== 0"
        class="absolute rounded-full bg-red-500 text-white w-6 h-4 lg:h-6 lg:w-6 text-xs top-0 -right-3.5 lg:right-0 text-center lg:pt-1"
      >
        {{ getBadgeCount }}
      </div>
      <fa-icon :icon="icon" class="text-lg md:text-2xl lg:text-3xl"></fa-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "TouchInterfaceNavbarButton",
  props: {
    icon: {
      type: String,
      required: true
    },
    to: {
      type: [Object, String],
      required: false
    },
    type: {
      type: String,
      default: "primary"
    },
    isRouterLink: {
      type: Boolean,
      default: true
    },
    badgeCount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    emitClick(e) {
      this.$emit('click', e);
    }
  },
  computed: {
    getClassesByType() {
      switch (this.type) {
        case "danger":
          return "text-red-500 hover:text-gray-800 hover:bg-red-500";
        case "warning":
          return "text-yellow-500 hover:text-gray-800 hover:bg-yellow-500";
        default:
          return "text-white hover:text-gray-800 hover:bg-white";
      }
    },
    getBadgeCount() {
      if (this.badgeCount > 99) {
        return "99+";
      }
      return this.badgeCount;
    }
  },
}
</script>

