<template>
  <div>
    <form-label v-if="label" :label="label" class="mb-1"></form-label>

    <div v-if="$isReadOnly()">
      {{ quantity }}
    </div>
    <div v-else>
      <delete-confirmation-modal v-if="confirmDelete && deleteOnZero" ref="confirmationModal"
                                 :title="deleteConfirmationTitle" @delete="onDeleteConfirmed">
        <slot name="deleteConfirmationMessage"></slot>
      </delete-confirmation-modal>

      <transition class="shadow-md" mode="out-in" name="fade-up-down-faster">
        <template v-if="showAddToCartButton ? isSelected : true">
          <div :class="[isSmall ? 'h-8 w-24' : 'h-10 w-32']"
               class="flex items-stretch justify-between text-center">
            <div key="removeOne"
                 :class="[getButtonColors, !canRemoveOne ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer']"
                 class="flex-1 select-none text-white flex items-center justify-center rounded-l-md"
                 @click="removeOne">
                            <span class="transition">
                                <fa-icon v-if="showTrashIcon" fixed-width icon="trash-alt"></fa-icon>
                                <fa-icon v-else-if="showMinusIcon" fixed-width icon="minus"></fa-icon>
                            </span>
            </div>
            <div key="input" class="flex-1 h-full align-center">
              <input v-model.number="model" :class="[getInputPadding, getBorderColor, isSmall ? 'text-xs' : '']"
                     class="w-full h-full appearance-none m-0 border-t border-b"
                     type="number">
            </div>
            <div key="addOne"
                 :class="[getButtonColors, !canAddOne ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer']"
                 class="flex-1 select-none h-full text-white flex items-center justify-center rounded-r-md "
                 @click="addOne">
              <fa-icon fixed-width icon="plus"></fa-icon>
            </div>
          </div>
        </template>

        <div v-else
             key="add"
             :class="[isSelected ? 'rounded-r-md' : 'rounded-md', getButtonColors, isSmall ? 'h-8 w-8' : 'h-10 w-12']"
             class="bg-gray-600 select-none hover:bg-gray-500 text-white flex items-center justify-center cursor-pointer"
             @click="addOne">
          <fa-icon fixed-width icon="cart-plus"></fa-icon>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import DeleteConfirmationModal from "../../modals/DeleteConfirmationModal";
import FormLabel from "./partials/FormLabel";

export default {
  name: "QuantitySelectorElement",
  components: {FormLabel, DeleteConfirmationModal},
  props: {
    quantity: {
      type: Number,
      required: true
    },
    label: {
      type: String
    },
    size: {
      type: String,
      default: 'large'
    },
    type: {
      default: 'primary',
      validator: val => ['primary', 'success', 'danger', 'warning', 'dark', 'light'].includes(val)
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    showAddToCartButton: {
      type: Boolean,
      default: false
    },
    confirmDelete: {
      type: Boolean,
      default: false
    },
    deleteConfirmationTitle: {
      type: String,
      default: ''
    },
    deleteOnZero: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    addOne() {
      if (!this.canAddOne) {
        return false;
      }

      this.model++;
    },
    removeOne() {
      if (!this.canRemoveOne)
        return false;

      if (this.model === 1 && this.confirmDelete)
        return this.$refs.confirmationModal.openModal();

      this.model--;
    },
    onDeleteConfirmed() {
      this.model = 0;
    }
  },
  computed: {
    canAddOne() {
      if (this.max === undefined) {
        return true;
      }
      return this.model < this.max;
    },
    canRemoveOne() {
      if (this.min === undefined) {
        return this.model !== 0;
      }
      return this.model > this.min;
    },

    isSelected() {
      return this.model > 0;
    },
    isSmall() {
      return this.size === 'small';
    },
    showMinusIcon() {
      return this.model > 1 || !this.deleteOnZero;
    },
    showTrashIcon() {
      return this.model === 1 && this.deleteOnZero;
    },
    getInputPadding() {
      const length = this.quantity.toString().length;
      const sm = this.isSmall;

      switch (length) {
        case 1:
          return sm ? 'pl-3' : 'pl-4';
        case 2:
          return sm ? 'pl-2.5' : 'pl-3';
        case 3:
          return sm ? 'pl-1.5' : 'pl-2';
        default:
          return '';
      }
    },
    getButtonColors() {
      switch (this.type) {
        case 'light':
          return 'bg-gray-400 hover:bg-gray-300';
        case 'dark':
          return 'bg-gray-800 hover:bg-gray-900';
        case 'success':
          return 'bg-green-600 hover:bg-green-500';
        case 'danger':
          return 'bg-red-600 hover:bg-red-500';
        case 'warning':
          return 'bg-yellow-600 hover:bg-yellow-500';
      }
      return 'bg-luxcaddy-600 hover:bg-luxcaddy-500';
    },
    getBorderColor() {
      switch (this.type) {
        case 'light':
          return 'border-gray-400';
        case 'dark':
          return 'border-gray-800';
        case 'success':
          return 'border-green-600';
        case 'danger':
          return 'border-red-600';
        case 'warning':
          return 'border-yellow-600';
      }
      return 'border-luxcaddy-600';
    },
    model: {
      get() {
        return this.quantity;
      },
      set(value) {
        if (typeof value === "string") {
          value = 0;
        }

        value = isNaN(parseInt(value)) ? 0 : parseInt(value);

        if (value < 1) {
          value = 0;
        }
        if (value > 999) {
          value = 999;
        }
        if (this.max) {
          if (value > this.max) {
            value = this.max;
          }
        }

        this.$emit('update:quantity', value);
      }
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>