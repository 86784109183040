<template>
  <table-row
    class="relative cursor-pointer h-14"
    @click="$router.push({name: 'touchInterface.fulfilment.orders.details', params: {id: order.id}})"
  >
    <table-data-cell align="center">
      {{ order.id }}
    </table-data-cell>
    <table-data-cell align="center">
      <span v-if="order.delivery">{{ order.delivery.id }}</span>
    </table-data-cell>
    <table-data-cell>
      <order-fulfilment-status-indicator
        :fulfilment-status="order.fulfillmentStatus"
        small
      />
    </table-data-cell>
    <table-data-cell>
      <time-slot-as-string
        :from="order.delivery?.timeSlot?.startDate"
        :to="order.delivery?.timeSlot?.endDate"
        :zone="order.delivery?.timeSlot?.zoneId"
      />
    </table-data-cell>
    <table-data-cell>
             <span v-if="order.delivery">
                {{ order.delivery.destination.fullName }}
             </span>
      <span v-else>
                {{ order.memberFullName }}
             </span>
    </table-data-cell>
    <table-data-cell>
      <money-amount :amount="order.total.amount"/>
    </table-data-cell>
    <table-data-cell>
      <fulfilment-number-display
        :fulfilment-number="order.delivery?.fulfilmentNumber"
        :has-delivery="order.delivery !== null"
      />
    </table-data-cell>
    <table-data-cell class="w-24">
      <fulfilment-progress
        :percentage="order.progression.progressionPercentage"
      />
    </table-data-cell>
    <table-data-cell>
      <box-quantity
        v-if="order.delivery"
        :quantity="order.delivery.boxQuantity"
      />
    </table-data-cell>
  </table-row>
</template>

<script>
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import MoneyAmount from "@/components/global/Money/MoneyAmount";
import TimeSlotAsString from "../../../../../global/TimeSlots/TimeSlotAsString";
import BoxQuantity from "@/components/global/BoxQuantity/BoxQuantity";
import OrderFulfilmentStatusIndicator from "@/components/pages/Orders/EditOrder/Info/OrderFulfilmentStatusIndicator";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";
import FulfilmentProgress from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/FulfilmentProgress";

export default {
  name: "OrderFulfilmentListEntry",
  components: {
    FulfilmentProgress,
    FulfilmentNumberDisplay,
    OrderFulfilmentStatusIndicator,
    BoxQuantity,
    TimeSlotAsString, MoneyAmount, TableDataCell, TableRow
  },
  props: {
    order: {
      type: Object,
      required: true
    },
  },
}
</script>

