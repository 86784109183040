<template>
  <div
    :class="getColorByType"
    class="rounded-md shadow-sm flex gap-x-2 px-2 items-center w-max"
  >
    <fa-icon v-if="getIconByType" :icon="getIconByType"/>
    <span>{{ getTextByType }}</span>
  </div>
</template>

<script>
export default {
  name: "InstructionTableTypeInclude",
  props: {
    type: {
      type: String,
      required: true
    }
  },
  created() {
    this.TYPE_FULFILLMENT = 'fulfillment';
    this.TYPE_ADMIN = 'admin';
    this.TYPE_DELIVERY = 'delivery';
  },
  computed: {
    getTextByType() {
      switch (this.type) {
        case this.TYPE_FULFILLMENT:
          return "Packaging";
        case this.TYPE_ADMIN:
          return "Administrative";
        case this.TYPE_DELIVERY:
          return "Livraison";
        default:
          return this.type;
      }
    },
    getIconByType() {
      switch (this.type) {
        case this.TYPE_FULFILLMENT:
          return "box";
        case this.TYPE_ADMIN:
          return "user-shield";
        case this.TYPE_DELIVERY:
          return "truck";
        default:
          return null;
      }
    },
    getColorByType() {
      switch (this.type) {
        case this.TYPE_FULFILLMENT:
          return "bg-luxcaddy-300 text-black";
        case this.TYPE_ADMIN:
          return "bg-yellow-300 text-black";
        case this.TYPE_DELIVERY:
          return "bg-gray-600 text-white";
        default:
          return null;
      }
    }
  },
}
</script>

