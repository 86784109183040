<template>
  <div>
    <page-title>Créer requête</page-title>

    <card>
      <create-or-update-report-query-form mode="create"/>
    </card>
  </div>
</template>

<script>
import Card from "../../../elements/cards/Card";
import CreateOrUpdateReportQueryForm from "@/components/pages/ReportQueries/Forms/CreateOrUpdateReportQueryForm.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";

export default {
  name: "CreateReportQuery",
  components: {PageTitle, CreateOrUpdateReportQueryForm, Card},
}
</script>
