<template>
  <div>
    <page-title>
      Créer departement
    </page-title>

    <card>
      <create-or-update-department-form
        mode="create"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import Card from "@/components/elements/cards/Card.vue";
import CreateOrUpdateDepartmentForm from "@/components/pages/Departments/Forms/CreateOrUpdateDepartmentForm.vue";

export default {
  name: "CreateDepartment",
  components: {CreateOrUpdateDepartmentForm, Card, PageTitle}
}
</script>