<template>
  <div>
    <button-element
      class="text-sm"
      size="small"
      type="warning"
      @click="openModal"
    >

      <fa-icon
        fixed-width
        icon="edit"
      />
    </button-element>

    <modal
      ref="modal"
      :position="getModalPosition"
      :show-close-button="true"
      title="Modifier ligne"
      @close="$store.commit('invoices/setInvoiceItemModalOpen', false)"
      @open="$store.commit('invoices/setInvoiceItemModalOpen', true)"
    >
      <form-wrapper
        v-if="modalOpened"
        ref="form"
        :show-changed="true"
        submit-button-label="Modifier"
        @submit="updateItem"
      >
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-x-4" style="width: 800px;">

          <!--
            Internal Invoice:
              Draft: Edit all
              Finalized: Edit only Booking Data
          -->
          <template
            v-if="isInvoiceInternal"
          >
            <vat-input-element
              v-model="vat"
              :disabled="isFinalized"
              class="lg:col-span-2"
              validation-rules="required"
            />
            <booking-account-select-input
              v-model="bookingAccountNumber"
              :allow-unselecting="false"
              :member-id="getMemberId"
              class="lg:col-span-2"
              validation-rules="required"
              @loaded="onBookingAccountsLoaded"
              :multi="false"
            />

            <vat-codes-select-input
              v-model="vatCode"
              :allow-unselecting="false"
              class="lg:col-span-2"
              validation-rules="required"
            />


            <text-input-element
              v-model="reference"
              :disabled="isFinalized"
              class="lg:col-span-2"
              label="Réf."
              validation-rules="required"
            />

            <text-input-element
              v-model="name"
              :disabled="isFinalized"
              class="lg:col-span-4"
              label="Labellé"
              validation-rules="required"
            />

            <text-area-element
              v-model="description"
              :disabled="isFinalized"
              class="lg:col-span-4"
              label="Description"
            />

            <number-input-element
              v-model="quantity"
              :disabled="isFinalized"
              class="lg:col-span-2"
              label="Qté."
              validation-rules="required"
            />

            <currency-input-element
              v-model.number="price"
              :disabled="isFinalized"
              :inset-text="getInvoiceCurrencySymbol"
              allow-negative
              class="lg:col-span-2"
              label="Montant"
              validation-rules="required"
            />
          </template>

          <!-- External invoice -->
          <template v-else>
            <currency-input-element
              v-model.number="price"
              :inset-text="getInvoiceCurrencySymbol"
              allow-negative
              class="lg:col-span-2"
              label="Montant"
              validation-rules="required"
            />
            <vat-input-element
              v-model="vat"
              class="lg:col-span-2"
              label="Taux de TVA"
              validation-rules="required"
            />

            <booking-account-select-input
              v-model="bookingAccountNumber"
              :allow-unselecting="false"
              :member-id="getMemberId"
              class="lg:col-span-4"
              validation-rules="required"
              @loaded="onBookingAccountsLoaded"
              :multi="false"
            />

            <vat-codes-select-input
              v-model="vatCode"
              :allow-unselecting="false"
              class="lg:col-span-4"
              validation-rules="required"
            />

          </template>

        </div>

        <template v-slot:additionalButtons>
          <button-element class="float-right" type="light" @click="closeModal">Annuler</button-element>
        </template>
      </form-wrapper>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import Modal from "@/components/elements/modals/Modal";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import CurrencyInputElement from "@/components/elements/forms/elements/CurrencyInputElement";
import VatInputElement from "@/components/elements/forms/elements/VatInputElement.vue";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import {mapActions, mapGetters} from "vuex";
import VatCodesSelectInput from "@/components/elements/forms/elements/VatCodesSelectInput";
import BookingAccountSelectInput from "@/components/elements/forms/elements/BookingAccountSelectInput";

export default {
  name: "InvoiceItemEditButton",
  components: {
    BookingAccountSelectInput,
    VatCodesSelectInput,
    VatInputElement,
    CurrencyInputElement,
    NumberInputElement,
    TextAreaElement,
    TextInputElement,
    FormWrapper,
    Modal,
    ButtonElement
  },
  data: () => ({
    modalOpened: false,

    reference: null,
    name: '',
    description: null,
    quantity: 1,
    price: 0,
    vat: 3,
    vatCode: null,
    bookingAccountNumber: null
  }),
  created() {
    this.setItemData();
  },
  props: {
    referencedInvoiceItem: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      required: false
    },
  },
  computed: {
    ...mapGetters('invoices', ['getInvoiceId', 'getInvoice', 'isInvoiceInternal', 'getInvoiceCurrencySymbol', 'getMemberId']),

    getModalPosition() {
      if (this.$store.getters["invoices/isTreatingInvoicePDF"]) {
        return "left";
      }

      return "center";
    },

    isFinalized() {
      return !this.getInvoice.isDraft;
    },
  },
  methods: {
    ...mapActions('invoices', ['refetchCurrentInvoice']),
    openModal() {
      this.modalOpened = true;

      if (this.item === undefined) {
        this.reference = null;
        this.name = '';
        this.description = null;
        this.quantity = 1;
        this.price = 0;
        this.vat = 3;
        this.vatCode = "NSTF";
        this.bookingAccountNumber = 60630000;
      }

      this.$nextTick(() => {
        this.$refs.modal.openModal();
        this.resetForm();
      });
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },

    setItemData() {
      this.reference = this.item.reference;
      this.name = this.item.name;
      this.description = this.item.description;
      this.quantity = this.item.quantity;
      this.price = this.item.price;
      this.vat = this.item.vat;
      this.vatCode = this.item.vatCode;
      this.bookingAccountNumber = this.item.bookingAccountNumber;

      this.resetForm();
    },

    resetForm() {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetForm();
      }
    },

    updateItem(callback) {
      if (this.isInvoiceInternal) {
        return this.isFinalized ? this.updateBookingData(callback) : this.updateInternalDraftInvoiceItem(callback);
      }
      return this.updateExternalInvoiceItem(callback);
    },

    // used for finalized internal item
    updateBookingData(callback) {
      InvoiceRepository
        .internal
        .items
        .updateBookingData(this.getInvoiceId, this.item.id, {
          vatCode: this.vatCode,
          bookingAccountNumber: this.bookingAccountNumber
        })
        .then(() => {
          this.closeModal();
          this.$sendSuccessToast("Ligne modifiée.");
          this.refetchCurrentInvoice();
        }).finally(() => callback());
    },

    updateInternalDraftInvoiceItem(callback) {
      InvoiceRepository.internal.items.editItem(
        this.getInvoiceId,
        this.item.id,
        {
          vatCode: this.vatCode,
          bookingAccountNumber: this.bookingAccountNumber,
          vat: this.vat,
          price: this.price,
          quantity: this.quantity,
          name: this.name,
          description: this.description,
          reference: this.reference,
        }
      ).then(() => {
        this.closeModal();
        this.$sendSuccessToast("Ligne modifiée.");
        this.refetchCurrentInvoice();
      }).finally(() => callback());
    },

    updateExternalInvoiceItem(callback) {
      InvoiceRepository.external.items.editItem(
        this.getInvoiceId,
        this.item.id,
        {
          vatCode: this.vatCode,
          bookingAccountNumber: this.bookingAccountNumber,
          vat: this.vat,
          price: this.price
        }
      ).then(() => {
        this.closeModal();
        this.$sendSuccessToast("Ligne modifiée.");
        this.refetchCurrentInvoice();
      }).finally(() => callback());
    },

    onBookingAccountsLoaded(bookingAccounts, mostUsedBookingAccount) {
      const defaultBookingAccountId = 60630000;
      const hasDefaultBookingAccount = mostUsedBookingAccount && bookingAccounts.find(b => b.value === defaultBookingAccountId);

      if (this.bookingAccountNumber === null && hasDefaultBookingAccount) {
        this.bookingAccountNumber = defaultBookingAccountId;
      }

      this.resetForm();
    }
  },
}
</script>

