<template>
  <div>
    <form-wrapper
      ref="form"
      :show-changed="true"
      submit-button-label="Créer facture"
      @submit="createInvoice"
    >
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-4 md:gap-x-8">
        <member-select-input
          v-model="memberId"
          :only-member-types="['customer']"
          display-member-vat
          label="Client"
          only-active-member-types
          validation-rules="required"
        />
        <date-picker-element
          v-model="date"
          display-format="DD.MM.YYYY"
          format="YYYY-MM-DD"
          label="Date"
          validation-rules="required"
        />
      </div>
    </form-wrapper>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";
import InvoiceRepository from "@/repositories/InvoiceRepository";

export default {
  name: "SharedCreateInternalInvoice",
  components: {DatePickerElement, MemberSelectInput, FormWrapper},
  data: () => ({
    memberId: null,
    date: null
  }),
  created() {
    this.date = this.$date().format('YYYY-MM-DD');
  },
  methods: {
    createInvoice(callback) {
      InvoiceRepository.internal.createInvoice({
        memberId: this.memberId,
        date: this.date
      }).then((res) => {
        this.$emit('created', res.data.data.id);
      }).finally(() => callback());
    }
  },
}
</script>

