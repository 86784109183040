<template>
  <touch-optimized-modal ref="modal">
    <div class="flex justify-between gap-x-24 mb-12">
      <div>
        <div class="text-5xl text-yellow-200 text-bold mb-4">
          {{ getItem.name }}
        </div>
      </div>

      <div>
        <img :alt="getItem.name" :src="getImage" class="w-32 h-32 object-contain">
      </div>
    </div>
    <div class="flex justify-between gap-x-24">
      <div class="flex flex-col gap-y-12">
        <number-input-element ref="weightInput" v-model="weight" :inset-text="getUnit"
                              class="w-64"></number-input-element>

        <div>
          <div v-if="addedWeights.length">
            <div v-for="(weight, i) in addedWeights" :key="i + '_' + weight">
              <span class="text-yellow-400 text-lg mr-3">{{ i + 1 }})</span>
              <span class="text-xl">
                            {{ weight }} {{ getUnit }}
                        </span>
            </div>
          </div>

          <div class="mt-4 border-t-2 border-yellow-400 text-right pt-2 text-xl text-bold">
            {{ getPackedWeight }}{{ getUnit }}/<span class="text-yellow-400">{{
              getToBePackedWeight
            }}{{ getUnit }}</span>
          </div>
        </div>

        <touch-optimized-button
          v-if="getPackedQuantity > 0"
          class="w-full text-gray-800"
          type="secondary"
          with-border
          @click="resetPacked"
        >
          UNDO
        </touch-optimized-button>
      </div>

      <div class="flex flex-col gap-y-8">
        <key-pad class="w-80"></key-pad>
        <touch-optimized-button class="w-full text-gray-800" type="secondary" with-border @click="addWeight">
          OK {{ getUnfulfilledQuantity }}
        </touch-optimized-button>
      </div>
    </div>
  </touch-optimized-modal>
</template>

<script>
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import KeyPad from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/keypad/KeyPad";
import {mapActions, mapGetters, mapMutations} from "vuex";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";

export default {
  name: "OrderFulfilmentItemWeightModal",
  components: {TouchOptimizedButton, KeyPad, NumberInputElement, TouchOptimizedModal},
  data: () => ({
    weight: null,
    addedWeights: []
  }),
  props: {
    itemId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions('fulfilment', ['fulfillItem', 'resetItemPackedQuantity']),
    ...mapMutations('fulfilment', ['setItemIdForWeightInput']),

    addWeight() {
      if (this.weight === null || this.weight === 0) {
        this.$showErrorDialog('Veullez entrer un poids valable.')
        return false;
      }

      this.fulfillItem({
        itemId: this.itemId,
        packedQuantity: 1,
        packedWeight: this.weight
      }).then(() => {
        this.addedWeights.push(this.weight);
        this.weight = null;
      }).finally(() => {
        this.focusInput();

        if (this.isFullyPacked) {
          setTimeout(() => {
            this.close();
          }, 500);
        }
      });
    },

    resetPacked() {
      this.resetItemPackedQuantity(this.itemId).then(() => {
        this.addedWeights = [];
      })
    },

    focusInput() {
      this.$refs.weightInput.focus();
    },

    open() {
      this.$refs.modal.open();
      this.$nextTick(() => {
        this.focusInput();
      })
    },

    close() {
      this.addedWeights = [];
      this.weight = null;
      this.setItemIdForWeightInput(null);
      this.$refs.modal.close();
    }
  },
  computed: {
    ...mapGetters('fulfilment', ['getItemById']),

    getItem() {
      return this.getItemById(this.itemId);
    },

    getToBePackedWeight() {
      return this.getItem.toBePackedWeight;
    },
    getPackedWeight() {
      return this.getItem.packedWeight;
    },
    getUnit() {
      return this.getItem.bundle.unit;
    },
    isFullyPacked() {
      return this.getItem.toBePackedQuantity === this.getItem.packedQuantity;
    },

    getToBePackedQuantity() {
      return this.getItem.toBePackedQuantity;
    },
    getPackedQuantity() {
      return this.getItem.packedQuantity;
    },
    getUnfulfilledQuantity() {
      return this.getToBePackedQuantity - this.getPackedQuantity;
    },
    getImage() {
      return getPrimaryImageUrlFromImageObject(this.getItem.image);
    },
  }
}
</script>

