<template>
  <div class="flex gap-x-3 items-center">
        <span
          v-if="!getDataArray.length"
          class="italic text-xs">
                Aucune donnée supplémentaire n'a été fournie.
            </span>

    <table
      v-else
      class="table-fixed"
    >
      <log-table-data-line
        v-for="(dataLine, i) in getDataArray"
        :key="dataLine[0]"
        :class="i % 2 === 1 ? 'bg-luxcaddy-50 hover:bg-luxcaddy-100' : 'bg-luxcaddy-200 hover:bg-luxcaddy-300'"
        :value="dataLine[1]"
        :value-key="dataLine[0]"
      />
    </table>

    <fa-icon
      v-if="getDataArray.length"
      class="cursor-pointer text-gray-300 hover:text-gray-400"
      fixed-width
      icon="copy"
      @click="copyObject"
    />
  </div>
</template>

<script>
import {copyTextToClipboard} from "@/helpers/copyToClipboardHelper";
import LogTableDataLine from "@/components/pages/Logs/TableSlots/LogTableDataLine";

export default {
  name: "LogTableDataSlot",
  components: {LogTableDataLine},
  props: {
    logData: {
      type: [Object, Array]
    },
    filterDataKey: {
      type: String,
      default: null
    }
  },

  methods: {
    copyObject() {
      copyTextToClipboard(JSON.stringify(this.logData))
        .then(() => this.$sendSuccessToast("Le JSON a été copié"))
        .catch(() => this.$showErrorDialog("Le JSON n'a pas pu être copié."));
    },
  },
  computed: {
    getDataArray() {
      if (this.filterDataKey) {
        // [key, value]
        // ['title', 'M']
        return Object.entries(this.logData)
          .filter(value => value[0].toLowerCase().includes(this.filterDataKey.toLowerCase()))
      }
      return Object.entries(this.logData);
    },
  },
}
</script>

<style scoped>

</style>