<template>
  <gmap-custom-marker :marker="getMarkerPos" :z-index="isHovered ? 30 : 0">
    <div
      @mouseenter="setHovered"
      @mouseleave="setUnhovered"
    >
      <div class="text-md z-40 flex flex-col items-center">
        <div :style="`border-color: ${driver.color}`" class="w-8 h-8 bg-white border pt-1 rounded-full z-50">
          <truck-icon-svg :style="`color: ${driver.color}`" class="w-6 h-6 ml-1"/>
        </div>

        <div :style="`color: ${driver.color}; margin-top: -1.35rem`" class="mx-auto z-40">
          <fa-icon class="text-md h-10 -mt-0.5" fixed-width icon="sort-down"/>
        </div>
      </div>

      <!-- Hover Overlay -->
      <transition name="fade-up-down">
        <div
          v-if="isHovered"
          :style="`border-color: ${driver.color}`"
          class="z-20 absolute cursor-auto -mt-16 -ml-4 rounded-md px-4 py-2 border-2 shadow-2xl min-w-max opacity-95 bg-white"
        >
          <div class="flex items-center justify-between gap-x-4">
            <div class="w-8 h-10"></div>

            <div class="flex items-center gap-x-6">
              <div>
                <div class="font-bold">Chauffeur:</div>
                <div>
                  {{ driver.driverName }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2  gap-x-4">
            <route-delivery-progress
              :color="driver.color"
              :delivered-deliveries="driver.deliveredDeliveries"
              :device="getHoveredDriverRoute.device"
              :percentage="40"
              :show-jump-to-position-button="false"
              :show-truck-icon="false"
              :total-deliveries="driver.totalDeliveries"
              bigger-line-spacing
            />
          </div>
        </div>
      </transition>
    </div>
  </gmap-custom-marker>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import TruckIconSvg from "@/components/pages/RouteManager/Includes/Progress/TruckIconSvg";
import RouteDeliveryProgress from "@/components/pages/RouteManager/Includes/Progress/RouteDeliveryProgress";

export default {
  name: "RouteManagerDriverMapMarker",
  components: {
    RouteDeliveryProgress,
    TruckIconSvg,
    GmapCustomMarker,
  },
  props: {
    driver: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('routeManager', ['getHoveredDriverRouteId', 'getHoveredDriverRoute']),

    getMarkerPos() {
      return {
        lat: this.driver.position.latitude,
        lng: this.driver.position.longitude
      }
    },
    isHovered() {
      return this.getHoveredDriverRouteId === this.driver.routeId;
    },
  },
  methods: {
    ...mapMutations('routeManager', [
      'setHoveredDriverRouteId',
      'clearHoveredDriverRouteId',
    ]),
    setHovered() {
      this.setHoveredDriverRouteId({
        driverRouteId: this.driver.routeId,
        initiator: 'map'
      })
    },
    setUnhovered() {
      this.clearHoveredDriverRouteId();
    }
  },
}
</script>

