<template>
  <form-wrapper ref="form"
                :show-changed="false"
                :submit-button-label="isInUpdateMode ? 'Sauvegarder' : 'Créer'"
                @submit="createOrUpdate">


    <div>
      <div
        v-for="(filter, index) in filters"
        :key="index"
        :class="[index === 0 ? 'md:items-end' : 'md:items-start']"
        class="flex flex-col md:flex-row gap-x-2 mb-3 bg-gray-200 rounded-md p-4 md:bg-white md:p-0 md:rounded-none"
      >
        <div
          class="flex-1 | grid grid-cols-1 md:grid-cols-3 gap-4 "
        >
          <select-element
            v-model="filter.property"
            :enable-search="false"
            :label="index === 0 ? 'Propriété' : null"
            :occupy-validation-space="false"
            :options="availableProperties"
          />

          <select-element
            v-model="filter.operator"
            :enable-search="false"
            :label="index === 0 ? 'Operateur' : null"
            :occupy-validation-space="false"
            :options="availableOperators"
            show-selected-icon
          />

          <date-picker-element
            v-if="isDateFilter(filter.property)"
            :id="'comparedToValue-' + index"
            v-model="filter.comparedToValue"
            :label="index === 0 ? 'Par rapport à' : null"
            format="YYYY-MM-DD"
          />

          <text-input-element
            v-else
            :id="'comparedToValue-' + index"
            v-model="filter.comparedToValue"
            :label="index === 0 ? 'Par rapport à' : null"
            :occupy-validation-space="false"
            :placeholder="getPlaceholder(filter.property)"

          />
        </div>

        <button-element class="h-full mt-6 md:mt-0" size="small" type="danger"
                        @click="deleteFilterByIndex(index)">
          <fa-icon icon="trash"></fa-icon>
          <span class="inline-block md:hidden ml-3">Supprimer</span>
        </button-element>

      </div>

      <button-element class="mt-2" size="small" type="primary" @click="addFilter">
        <fa-icon icon="plus"></fa-icon>
        Ajouter régle
      </button-element>
    </div>

    <template #additionalButtons>
      <button-element type="light" @click="$emit('cancel')">
        Annuler
      </button-element>
    </template>
  </form-wrapper>
</template>

<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import ButtonElement from "../../../elements/buttons/ButtonElement";
import FeeRepository from "../../../../repositories/FeeRepository";
import CampaignRepository from "../../../../repositories/CampaignRepository";
import RuleRepository from "../../../../repositories/RuleRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";

export default {
  name: "CreateOrUpdateRuleForm",
  components: {DatePickerElement, SelectElement, ButtonElement, TextInputElement, FormWrapper},
  data: () => ({
    availableProperties: [],
    availableOperators: [
      {icon: 'greater-than', label: 'Plus que', value: 'biggerThan'},
      {icon: 'less-than', label: 'Moins que', value: 'smallerThan'},
      {icon: 'equals', label: 'Égal à', value: 'equals'},
      {icon: 'box', label: 'Contient', value: 'contains'},
      {icon: 'not-equal', label: 'Non égal à', value: 'notEqualTo'},
    ],
    filters: []
  }),
  props: {
    mode: {
      type: String,
      default: "create",
    },
    context: {
      type: String,
      required: true
    },
    ruleAbleModel: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchProperties();
    this.copyFilters();

    if (this.isInCreateMode) {
      if (!this.filters.length) {
        this.addFilter();
      }
    }
  },
  methods: {
    createOrUpdate(callback) {
      return this.isInUpdateMode ? this.updateRule(callback) : this.createRule(callback);
    },
    updateRule(callback) {
      switch (this.getModel) {
        case "Fee":
          FeeRepository
            .rules
            .updateRule(this.$store.getters['fees/getFeeId'], this.context, {
              filters: this.getMappedFilters
            })
            .then(() => this.reFetchAndNotify('update'))
            .finally(() => callback());
          break;
        case "Campaign":
          CampaignRepository
            .rules
            .updateRule(this.$store.getters['campaigns/getCampaignId'], this.context, {
              filters: this.getMappedFilters
            })
            .then(() => this.reFetchAndNotify('update'))
            .finally(() => callback());
          break;
        default:
          return false;
      }
    },
    createRule(callback) {
      switch (this.getModel) {
        case "Fee":
          FeeRepository
            .rules
            .createRule(this.$store.getters['fees/getFeeId'], this.getRulePayload)
            .then(() => this.reFetchAndNotify())
            .finally(() => callback());
          break;
        case "Campaign":
          CampaignRepository
            .rules
            .createRule(this.$store.getters['campaigns/getCampaignId'], this.getRulePayload)
            .then(() => this.reFetchAndNotify())
            .finally(() => callback());
          break;
        default:
          return false;
      }
    },
    reFetchAndNotify(action = 'create') {
      let call;
      switch (this.getModel) {
        case "Fee":
          call = this.$store.dispatch('fees/refetchCurrentFee');
          break;
        case "Campaign":
          call = this.$store.dispatch('campaigns/refetchCurrentCampaign');
          break;
        default:
          return false;
      }

      return call
        .then(() => {
          if (action === 'create') {
            this.$sendSuccessToast("Régles crées.");
          } else {
            this.$sendSuccessToast("Régles sauvegardées.");
          }
          this.$emit('done');
        });
    },
    copyFilters() {
      this.filters = [
        ...this.getRules
          .find(r => r.appliedOn === this.context)
          ?.filters ?? []
      ];
    },
    addFilter() {
      this.filters.push({
        property: null,
        comparedToValue: null,
        operator: null,
      });
    },
    deleteFilterByIndex(index) {
      this.filters = this.filters.filter((f, i) => i !== index);
    },
    fetchProperties() {
      RuleRepository
        .fetchProperties(this.context[0].toLowerCase() + this.context.slice(1))
        .then((res) => {
          this.availableProperties = res.data.data;
        })
    },
    isDateFilter(filterProperty) {
      return filterProperty === "startDate" || filterProperty === 'endDate';
    },

    getPlaceholder(filterProperty) {
      if (filterProperty === "startTime" || filterProperty === 'endTime') {
        return 'H:i';
      }

      return null;
    },
  },
  computed: {
    isInUpdateMode() {
      return this.mode === "update";
    },

    isInCreateMode() {
      return this.mode === "create";
    },
    getMappedFilters() {
      return this.filters
        .filter(f =>
          f.property !== null &&
          f.comparedToValue !== null &&
          f.operator !== null
        )
        .map((f) => {
          return {
            property: f.property,
            operator: f.operator,
            value: f.comparedToValue
          };
        });
    },
    getRulePayload() {
      return {
        appliedOn: this.context,
        filters: this.getMappedFilters
      };
    },
    getRules() {
      switch (this.getModel) {
        case "Fee":
          return this.$store.getters['fees/getRules'];
        case "Campaign":
          return this.$store.getters['campaigns/getRules']
        default:
          return null;
      }
    },
    getModel() {
      return this.ruleAbleModel;
    }
  }
}
</script>

