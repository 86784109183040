<template>
  <button
    :class="[
            getBackgroundColor,
            loading ? '' : padding,
            size,
            {'opacity-50 cursor-not-allowed focus-none': disabled || loading}
        ]"
    class="w-full rounded-md select-none border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
    @click="onClick"
  >
    <div class="flex justify-between gap-y-2">
      <div v-if="loading" class="mx-auto">
        <loading-svg-inline class="w-12 h-12"/>
      </div>
      <span v-else class="flex-grow">
                <slot></slot>
            </span>
    </div>
  </button>
</template>

<script>
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline";

export default {
  name: "TouchOptimizedButton",
  components: {LoadingSvgInline},
  props: {
    type: {
      type: String,
      default: "primary",
      validator: val => ["primary", "secondary", "dark", "danger"].includes(val)
    },
    padding: {
      type: String,
      default: "py-3"
    },
    size: {
      type: String,
      default: 'text-xl'
    },
    withBorder: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledErrorMessage: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getBackgroundColor() {
      switch (this.type) {
        default:
          return this.disabled ? "bg-gray-50 cursor-not-allowed" : "bg-luxcaddy-200 hover:bg-luxcaddy-300";
        case "secondary":
          return this.disabled ? "bg-gray-200 cursor-not-allowed" : "bg-gray-200 hover:bg-gray-300";
        case "dark":
          return this.disabled ? "bg-gray-500 text-white cursor-not-allowed" : "bg-gray-500 hover:bg-gray-900 text-white";
        case "danger":
          return this.disabled ? "bg-red-500 text-white cursor-not-allowed" : "bg-red-500 hover:bg-red-900 text-white";
      }
    },
  },
  methods: {
    onClick(e) {
      if (this.disabled) {
        if (this.disabledErrorMessage) {
          this.$showErrorDialog(this.disabledErrorMessage);
        }
        return false;
      }

      if (this.loading) {
        return false;
      }

      return this.$emit('click', e);
    }
  }
}
</script>

