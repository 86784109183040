<template>
  <div>
    <div class="flex flex-wrap items-center gap-2">
      <div>
        <form-label
          v-if="label"
          :label="label"
          :show-required-mark="isInReadOnlyMode ? false : isRequired"
          class="mb-1"
        />
      </div>

      <span v-if="displayCharCounter" class="block text-gray-500 text-xs">
            ({{ getValueLength }} chars)
          </span>
    </div>


    <!--
        See https://vee-validate.logaretm.com/v3/api/validation-provider.html#props
     -->
    <validation-provider
      ref="validationProvider"
      v-slot="{ errors }"
      :name="getName"
      :rules="validationRules"
      slim
    >

      <div
        v-if="!isInReadOnlyMode"
        :class="[
                    isInsetLeft ? 'flex-row-reverse' : 'flex-row',
                    (aggressiveErrorDisplay && errors.length) ? 'focus-within:ring-red-200' : 'focus-within:ring-luxcaddy',
                    touchOptimized
                        ? 'h-12 sm:h-14 text-xl sm:text-3xl'
                        : small
                            ? 'h-6 text-xs'
                            : 'h-8 text-sm',
                    isInReadOnlyMode
                        ? 'bg-transparent'
                        : 'bg-white'
                ]"
        class="flex shadow border text-gray-700 border-gray-200 rounded-md focus-within:ring">

        <input
          v-if="type === 'currency'"
          ref="inputRef"
          :class="[additionalInputClasses, disabled ? 'cursor-not-allowed' : '', value && value < 0 ? 'text-red-500' : '']"
          :disabled="isInReadOnlyMode || disabled"
          :placeholder="getPlaceholder"
          class="formElement | appearance-none w-full p-2 pl-4 outline-none border-none rounded-md flex-1"
          type="text"
          @blur="$emit('blur', $event)"
          @keyup.enter="$emit('enter', $event)"
          @keyup.esc="$emit('escape', $event)"
        />

        <input
          v-else
          ref="inputRef"
          :class="[additionalInputClasses, disabled ? 'cursor-not-allowed' : '']"
          :disabled="isInReadOnlyMode || disabled"
          :max="max"
          :min="min"
          :placeholder="getPlaceholder"
          :step="step"
          :type="type"
          :value="value"
          class="formElement | appearance-none w-full p-2 pl-4 outline-none border-none rounded-md flex-1"
          @blur="blurInput"
          @focus="onFocus"
          @input="emitInput"
          @keyup.enter="$emit('enter', $event)"
          @keyup.esc="$emit('escape', $event)"
        >
        <div
          v-if="insetText"
          :class="[isInsetLeft ? 'ml-1' : 'mr-1']"
          class="rounded-md bg-gray-200 text-gray-800 my-1 pt-0.5 px-2 font-light flex-grow-0 select-none flex items-center">
          {{ insetText }}
        </div>
      </div>

      <div v-else-if="isInReadOnlyMode">
        <div v-if="value"
             :class="[isInsetLeft ? 'flex-row-reverse justify-end' : 'flex-row justify-start']"
             class="flex items-stretch">
          <div :class="[isInsetLeft ? 'ml-2' : 'mr-2', !insetText ? '-ml-0' : '']"
               class="flex-initial text-lg">
            {{ getReadOnlyModeValue }}
          </div>
          <div v-if="insetText" class="flex-initial bg-gray-200 p-1 px-2 text-sm rounded-md">
            {{ insetText }}
          </div>
        </div>
        <div v-else>
          <i>/</i>
        </div>
      </div>

      <template v-if="occupyValidationSpace">
        <input-error-message v-if="!isInReadOnlyMode" :errors="errors"/>
        <div v-else class="h-4"></div>
      </template>

    </validation-provider>
  </div>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
import InputErrorMessage from "../partials/InputErrorMessage";
import FormLabel from "@/components/elements/forms/elements/partials/FormLabel";
import formInputMixin from "@/mixins/formInputMixin";
import {useCurrencyInput} from 'vue-currency-input';
import {watch} from 'vue';

export default {
  name: "BaseInputElement",
  components: {FormLabel, InputErrorMessage, ValidationProvider},
  mixins: [formInputMixin],
  setup(props) {
    if (props.type === "currency") {
      const {inputRef, setOptions, setValue} = useCurrencyInput(props.currencyOptions);

      watch(
        () => props.value,
        (value) => {
          setValue(value)
        }
      )

      watch(
        () => props.currencyOptions,
        (options) => {
          setOptions(options)
        }
      )

      return {inputRef};
    }
  },
  props: {
    displayCharCounter: {
      type: Boolean,
      default: false
    },
    value: {
      required: false,
      type: [Number, String]
    },
    id: {
      type: [String, null]
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: [String, Number],
      required: false
    },
    insetText: {
      type: String,
      required: false
    },
    insetPosition: {
      type: String,
      default: 'right'
    },
    step: {
      type: String,
      required: false
    },
    min: {
      type: String,
      required: false
    },
    max: {
      type: String,
      required: false
    },
    fixedHeight: {
      type: Boolean,
      default: true
    },
    touchOptimized: {
      type: Boolean,
      default: false
    },
    selectOnFocus: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    currencyOptions: {
      type: Object,
      default: null
    }
  },
  methods: {
    emitInput(e) {
      if (this.isInReadOnlyMode)
        return false;

      this.$emit('input', e.target.value);
    },
    blurInput(e) {
      if (this.isInReadOnlyMode)
        return false;


      this.$emit('blur', e.target.value);
    },
    focus() {
      this.$refs.inputRef.focus();
    },
    onFocus(e) {
      this.$emit('focus', e);

      // Preselect the text on touch
      if (this.touchOptimized || this.selectOnFocus) {
        this.$refs.inputRef.select();
      }
    },
    syncCurrencyValue(value) {
      if (this.type === "currency") {
        if (this.$refs.validationProvider) {
          this.$refs.validationProvider.syncValue(value);
        }
      }
    }
  },
  watch: {
    // Manually pass the value to VeeValidate for Currency inputs
    // as they don't provide regular v-model logic
    value: function (value) {
      this.syncCurrencyValue(value);
    }
  },
  mounted() {
    this.syncCurrencyValue(this.value);
  },
  computed: {
    getName() {
      if (this.id) {
        return this.id;
      }

      return this.label?.toLowerCase().replaceAll(' ', '-') || null;
    },

    /**
     * Returns the Input Placeholder.
     *
     * @returns {string}
     */
    getPlaceholder() {
      return this.placeholder || '';
    },
    isInsetLeft() {
      return this.insetPosition === 'left';
    },
    getValueLength() {
      if (this.value) {
        return this.value.length
      }

      return 0;
    },
    getReadOnlyModeValue() {
      if (this.value === null || this.value === undefined) {
        return "/";
      }

      if (this.type === "currency") {
        let symbol = this.currencyOptions.currency === "EUR"
          ? "€"
          : this.currencyOptions.currency === "USD"
            ? '$'
            : this.currencyOptions.currency;

        return (this.value / 100).toFixed(2).toString().replace('.', ',') + ` ${symbol}`;
      }

      return this.value;
    }
  },
}
</script>

