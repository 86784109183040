<template>
  <status-indicator
    :customClasses="getCustomClasses"
    :small="small"
  >
    {{ getText }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "OrderFulfilmentStatusIndicator",
  components: {StatusIndicator},
  props: {
    fulfilmentStatus: {
      type: String
    },
    small: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    getCustomClasses() {
      switch (this.fulfilmentStatus) {
        case "fulfilmentFinalized":
          return "bg-luxcaddy-600 text-white"
        case "fulfilled":
          return "bg-blue-200 text-blue-900"
        case "inFulfilment":
        default:
          return "bg-yellow-200 text-yellow-900"
        case "fulfilmentNotStarted":
          return "bg-gray-300 text-gray-600"
      }
    },
    getText() {
      return this.$luxcaddyConfig('order.availableFulfillmentStatuses').find(l => l.value === this.fulfilmentStatus)?.label || this.fulfilmentStatus;
    }
  },
}
</script>

<style scoped>

</style>