<template>
  <update-localization-form
    v-if="localizations"
    :localizable-id="depositId"
    :localizations="localizations"
    localizable-type="deposit"
    @submit="saveLocalizations"
  />
</template>

<script>
import UpdateLocalizationForm from "../../../forms/UpdateLocalizationForm";
import DepositRepository from "../../../../repositories/DepositRepository";

export default {
  name: "UpdateDepositLocalizationForm",
  components: {UpdateLocalizationForm},
  props: {
    depositId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    localizations: null
  }),
  mounted() {
    DepositRepository.localization.getForDeposit(this.depositId).then((res) => {
      this.localizations = res.data.data;
    })
  },
  methods: {
    saveLocalizations(payload) {
      DepositRepository
        .localization
        .saveForDeposit(this.depositId, {
          localizableData: payload
        })
        .then(() => {
          this.$sendSuccessToast('Traduction sauvegardé!')
        })
    },
  },
}
</script>

