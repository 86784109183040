<template>
  <div>
    <rule-display
      v-for="rule in getRules"
      :key="rule.appliedOn"
      :rule="rule"
      :rule-able-model="ruleAbleModel"
      class="mb-4"
    />

    <div v-if="canCreateNewContexts" class="">
      <h3 class="text-xl text-luxcaddy mb-2">Créer régles pour:</h3>

      <div class="mt-5">
        <button-element
          v-for="(context, i) in ruleContextsPossibleToCreate"
          :key="i"
          class="mr-2"
          size="small"
          type="light"
          @click="addNewRule(context.value)"
        >
          <div class="px-2 my-0.5">
            <fa-icon class="mr-2" icon="plus"></fa-icon>
            <span>{{ context.label }}</span>
          </div>
        </button-element>
      </div>
    </div>
  </div>
</template>

<script>
import RuleDisplay from "./RuleDisplay";
import ButtonElement from "../../../elements/buttons/ButtonElement";

export default {
  name: "RuleManagement",
  components: {ButtonElement, RuleDisplay},
  props: {
    ruleAbleModel: {
      type: String,
      required: true,
      validator: val => [
        'Fee',
        'Campaign'
      ].includes(val)
    },
    modelId: {
      type: Number,
      required: true
    }
  },
  methods: {
    addNewRule(context) {
      this.$store.commit(`${this.getStorePrefixByModel}/addNewRule`, context);
    },
    clearStore() {
      this.$store.commit('fees/clearRules');
      this.$store.commit('campaigns/clearRules');
    },
    fetchRules() {
      return this.$store.dispatch(`${this.getStorePrefixByModel}/fetchSingle`, this.modelId);
    },
  },
  mounted() {
    this.clearStore();
    this.fetchRules();
  },
  computed: {
    getRules() {
      return this.$store.getters[`${this.getStorePrefixByModel}/getRules`]
    },
    availableRuleContexts() {
      return [
        {label: "Panier d'achat", value: "Cart"},
        {label: "Produit", value: "Product"},
        {label: "Nombre de commandes membres", value: "MemberOrdersCount"},
        {label: "Limit campagnes per membres", value: "MemberCampaignsCount"},
        {label: "PromoCode", value: "Promocode"},
        {label: "ZoneTimeSlot", value: "ZoneTimeSlot"},
        {label: "Prestataire de paiement", value: "PaymentProvider"},
      ];
    },
    ruleContextsPossibleToCreate() {
      let existingContexts = this.getRules.flatMap(r => r.appliedOn);
      return this.availableRuleContexts.filter(c => !existingContexts.includes(c.value));
    },
    canCreateNewContexts() {
      return this.ruleContextsPossibleToCreate.length > 0;
    },
    legendList() {
      return [
        {icon: 'greater-than', label: 'Plus que'},
        {icon: 'less-than', label: 'Moins que'},
        {icon: 'equals', label: 'Égal à'},
        {icon: 'box', label: 'Contient'},
        {icon: 'not-equal', label: 'Non égal à'},
      ];
    },
    getStorePrefixByModel() {
      switch (this.ruleAbleModel.toLowerCase()) {
        case "fee":
          return "fees";
        case "campaign":
        default:
          return "campaigns";
      }
    }
  },
}
</script>

