import auth from "../middlewares/authMiddleware";
import ShowSupplierPreviewTable from "@/components/pages/SupplierPreview/ShowSupplierPreviewTable";

export default [
  {
    path: '/supplier/preview',
    name: 'suppliers.preview.list',
    component: ShowSupplierPreviewTable,
    meta: {
      middleware: [auth],
      title: 'Actuellement en commande',
      permissions: ['SUPPLIER_ORDER_PREVIEW_READ']
    }
  },
];