var render = function render(){var _vm=this,_c=_vm._self._c;return _c('google-map',{ref:"map",attrs:{"latitude":49.8425618,"longitude":6.1729622,"options":{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false,
          clickableIcons: false
      }}},[_c('transition-group',{attrs:{"name":"fade-up-down"}},_vm._l((_vm.getDeliveriesForMap),function(delivery){return _c('route-manager-delivery-map-marker',{key:delivery.id,attrs:{"delivery":delivery}})}),1),(_vm.getDriverPositions.length > 0)?_c('transition-group',{attrs:{"name":"fade-up-down"}},_vm._l((_vm.getDriverPositions),function(driver){return _c('route-manager-driver-map-marker',{key:driver.color + '-' + driver.routeId,attrs:{"driver":driver}})}),1):_vm._e(),_c('route-manager-map-tracking-polylines')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }