<template>
  <navbar-button-template
    v-if="$store.getters['authUser/getPermissions'].includes('TOUCH_INTERFACE_ACCESS')"
    :emit-click="false"
  >
    <router-link
      :to="{ name: 'touchInterface.index' }"
    >
      <fa-icon
        fixed-width
        icon="pallet"
      />
    </router-link>
  </navbar-button-template>
</template>

<script>
import NavbarButtonTemplate from "@/components/layouts/dashboard/partials/navbar/NavbarButtonTemplate.vue";

export default {
  name: "NavbarTouchInterfaceButton",
  components: {NavbarButtonTemplate}
}
</script>

<style scoped>

</style>