<template>
  <validation-provider ref="validationProvider" v-slot="{ errors }" :name="getName">
    <div
      :class="{
            'flex flex-row-reverse items-center space-x-2 sm:space-x-0 sm:gap-x-2': labelInline,
            'opacity-40': disabled
            }">
      <form-label v-if="label" :class="{'mb-1': !labelInline}" :label="label" :show-required-mark="false"
                  :size="size" class="select-none"></form-label>

      <!-- Read Only Mode -->
      <div v-if="isInReadOnlyMode" :class="[isChecked ? 'bg-luxcaddy-600' : 'bg-gray-600']"
           class="inline-flex items-center text-center rounded text-white py-0.5 px-2">
            <span v-show="isChecked">
                <fa-icon fixed-width icon="check"></fa-icon>
            </span>
        <span v-show="!isChecked">
                <fa-icon fixed-width icon="times"></fa-icon>
            </span>
      </div>

      <!-- Normal mode -->
      <label
        v-else
        :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer focus-within:ring focus-within:ring-luxcaddy'"
        class="group inline-flex items-center | focus:outline-none "
        tabindex="0"
        @click="toggleValue"
        @keyup.enter="toggleValue"
      >

                <span v-show="isChecked"
                      :class="getCheckboxPadding"
                      class="border border-gray-300 group-hover:bg-gray-100 rounded-md flex items-center text-green-500">
                    <fa-icon :class="{'text-xs': isXSmall}" fixed-width icon="check"></fa-icon>
                </span>
        <span v-show="!isChecked"
              :class="getCheckboxPadding"
              class="border border-gray-300 group-hover:bg-gray-100 rounded-md flex items-center">
                    <fa-icon :class="{'text-xs': isXSmall}" fixed-width icon="times"></fa-icon>
                </span>

        <span
          v-if="!hideCheckedLabel"
          :class="{'text-lg': isInReadOnlyMode}"
          class="ml-3 block text-sm text-gray-500 select-none"
        >
                    {{ isChecked ? checkedLabel : uncheckedLabel }}
                </span>
      </label>
    </div>

    <input-error-message v-if="!isInReadOnlyMode && occupyValidationSpace" :errors="errors"/>
  </validation-provider>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
import FormLabel from "../forms/elements/partials/FormLabel";
import InputErrorMessage from "../forms/elements/partials/InputErrorMessage";
import formInputMixin from "@/mixins/formInputMixin";

export default {
  name: "CheckboxElement",
  components: {InputErrorMessage, FormLabel, ValidationProvider},
  mixins: [formInputMixin],
  props: {
    label: {type: String, required: false},
    value: {type: [Boolean, Number]},
    emitAsNumber: {type: Boolean, default: false},
    hideCheckedLabel: {type: Boolean, default: false},
    labelInline: {type: Boolean, default: false},
    occupyValidationSpace: {type: Boolean, default: true},
    size: {type: String, default: "normal", validator: val => ["xsmall", "normal", "big"].includes(val)},
    checkedLabel: {type: String, default: "Oui"},
    uncheckedLabel: {type: String, default: "Non"},
  },
  methods: {
    syncForValidation(value) {
      if (this.$refs.validationProvider) {
        this.$refs.validationProvider.syncValue(value);
      }
    },
    toggleValue() {
      if (this.disabled) {
        return false;
      }

      this.model = !this.value;
    }
  },
  mounted() {
    this.syncForValidation(this.value);
  },
  watch: {
    value: function (value) {
      this.syncForValidation(value);
    }
  },
  computed: {
    isXSmall() {
      return this.size === "xsmall";
    },
    isBig() {
      return this.size === "big";
    },

    getCheckboxPadding() {
      if (this.isXSmall) {
        return "py-0.5 px-1"
      } else if (this.isBig) {
        return "py-2.5 px-3"
      }
      return "py-1.5 px-2";
    },

    model: {
      get() {
        return this.emitAsNumber ? (this.value ? 1 : 0) : this.value;
      },
      set(value) {
        const val = this.emitAsNumber ? (value ? 1 : 0) : value;
        this.$emit('input', val);
      }
    },

    isChecked() {
      return this.value;
    },

    getName() {
      if (!this.label) {
        return null;
      }
      return this.label.toLowerCase().replaceAll(' ', '-');
    },
  },
}
</script>

<style scoped>
input[type="checkbox"] {
  box-shadow: none;
}
</style>