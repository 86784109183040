<template>
  <div v-if="render"
       class="py-2 px-4 overflow-y-auto"
  >
    <page-title>
      <div class="flex flex-col items-center gap-2 lg:flex-row lg:justify-between">
               <span>
                   Arrivage d'aujourd'hui commande <span v-if="getOrder">{{ getOrder.id }} ({{
                   getOrder.supplierName
                 }})</span>
               </span>

        <button-element
          type="dark"
          @click="updateQuantities"
        >
          <fa-icon fixed-width icon="check"/>
          Enregistrer quantités reçues
        </button-element>
      </div>
    </page-title>

    <supplier-order-item-list
      :onlyIncomeForToday="true"
    />

  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import SupplierOrderItemList
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/SupplierOrderItemList.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "ProductIncomeTodayEditInterfacePage",
  components: {
    ButtonElement,
    SupplierOrderItemList,
    PageTitle,
  },
  data: () => ({
    render: true,
  }),
  created() {
    this.clearReceivedItemValues();
    this.setBatchErrors([]);
    this.fetchOrder(this.$route.params.order);
  },
  beforeDestroy() {
    this.clearOrder();
  },
  methods: {
    ...mapActions('supplierOrders', [
      'fetchOrder',
      'updateReceivedQuantities'
    ]),
    ...mapMutations('supplierOrders', [
      'clearOrder',
      'setBatchErrors',
      'clearReceivedItemValues'
    ]),
    updateQuantities() {
      this.updateReceivedQuantities('touchinterface').finally(() => {
        this.fetchOrder(this.$route.params.order).finally(() => {
          this.render = false;
          this.$nextTick(() => {
            this.render = true;
          });
        })
      });
    }
  },
  computed: {
    ...mapGetters('supplierOrders', [
      'getOrder'
    ]),
  },
}
</script>

<style scoped>

</style>