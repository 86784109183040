import auth from "../middlewares/authMiddleware";
import ShowDeliveries from "@/components/pages/Deliveries/ShowDeliveries";
import CreateDelivery from "@/components/pages/Deliveries/CreateDelivery";
import EditDelivery from "@/components/pages/Deliveries/EditDelivery/EditDelivery";

let deliveryRoute = {
  component: ShowDeliveries,
  meta: {
    middleware: [auth],
    permissions: ['ACCESS_DELIVERY_VIEW'],
    title: 'Listes des livraisons ',
  }
};

export default [
  {
    ...deliveryRoute,
    path: '/deliveries',
    name: 'deliveries.list',
  },
  {
    ...deliveryRoute,
    path: '/deliveries-today',
    name: 'deliveries.list.today',
  },
  {
    ...deliveryRoute,
    path: '/deliveries-tomorrow',
    name: 'deliveries.list.tomorrow',
  },
  {
    path: '/deliveries/create',
    name: 'deliveries.create',
    component: CreateDelivery,
    meta: {
      middleware: [auth],
      title: 'Créer livraison',
      breadcrumbs: ['deliveries.list'],
      permissions: ['DELIVERY_WRITE']
    }
  },
  {
    path: '/deliveries/:deliveryId',
    name: 'deliveries.edit',
    component: EditDelivery,
    meta: {
      middleware: [auth],
      title: 'Modifier livraison',
      readOnly: {supported: true, title: 'Voir une livraison', permissions: ['DELIVERY_READ']},
      breadcrumbs: ['deliveries.list'],
      permissions: ['DELIVERY_WRITE']
    }
  },
]