<template>
  <div>
    <div class="flex items-start flex-wrap justify-between">
      <page-title>
        PDFs factures à traiter
      </page-title>

      <div class="flex gap-x-2 items-center">
        <button-element
          :disabled="externalInvoicePdfs.length === 0"
          size="small"
          type="light"
          @click="redirectToTreatOldest"
        >
          <fa-icon fixed-width icon="link"/>
          Traiter fac. la plus ancienne
        </button-element>

        <button-element
          size="small"
          @click="showUploadCard = !showUploadCard"
        >
          <fa-icon fixed-width icon="plus"/>
          Télécharger
        </button-element>
      </div>
    </div>

    <div
      v-if="showUploadCard"
      class="mt-3 mb-6"
    >
      <upload-external-invoice-pdfs
        @after-upload="onNewPDFsUploaded"
      />
    </div>

    <!-- There are untreated PDFs available -->
    <template
      v-if="externalInvoicePdfs.length > 0"
    >
      <div
        class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4"
      >
        <external-invoice-pdf-card
          v-for="externalInvoicePdf in externalInvoicePdfs"
          :key="externalInvoicePdf.id"
          :allow-text-layer="false"
          :external-invoice-pdf="externalInvoicePdf"
          @request-reload="loadUnlinkedInvoicePDFs"
        />
      </div>

      <div class="flex justify-between mt-8">
        <pagination-page-size-selector
          :items-from="itemsFrom"
          :items-to="itemsTo"
          :items-total="itemsTotal"
          :page-sizes="[8, 12, 16, 20, 24]"
          :per-page.sync="perPage"
        />

        <pagination-page-selector
          :page.sync="currentPage"
          :total-pages="totalPages"
        />
      </div>
    </template>

    <!-- All PDFs treated -->
    <info-alert
      v-else-if="!isLoading"
      show-immediately
    >
      Il n'y a pas de factures à lier pour le moment, veuillez revenir plus tard.
    </info-alert>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import UploadExternalInvoicePdfs
  from "@/components/pages/Accounting/ExternalInvoicePDFs/Sub/UploadExternalInvoicePdfs.vue";
import ExternalInvoicePdfCard
  from "@/components/pages/Accounting/ExternalInvoicePDFs/Sub/UnlinkedList/Sub/ExternalInvoicePdfCard.vue";
import ExternalInvoicePDFRepository from "@/repositories/ExternalInvoicePDFRepository";
import PaginationPageSelector
  from "@/components/listTable/includes/controls/pagination/includes/PaginationPageSelector.vue";
import InfoAlert from "@/components/elements/alerts/InfoAlert.vue";
import PaginationPageSizeSelector
  from "@/components/listTable/includes/controls/pagination/includes/PaginationPageSizeSelector.vue";

export default {
  name: "ExternalInvoicePDFsList",
  components: {
    PaginationPageSizeSelector,
    InfoAlert,
    PaginationPageSelector,
    ExternalInvoicePdfCard, UploadExternalInvoicePdfs, ButtonElement, PageTitle
  },
  data() {
    return {
      showUploadCard: false,
      isLoading: false,

      externalInvoicePdfs: [],

      // List Meta
      currentPage: 1,
      totalPages: 1,
      perPage: 8,
      itemsFrom: 0,
      itemsTo: 0,
      itemsTotal: 0,
    }
  },
  created() {
    this.loadUnlinkedInvoicePDFs();
  },
  watch: {
    perPage(perPage) {
      if (this.currentPage === 1) {
        this.loadUnlinkedInvoicePDFs(perPage, this.page);
      }

      this.currentPage = 1;
    },
    currentPage(page) {
      this.loadUnlinkedInvoicePDFs(this.perPage, page);
    }
  },
  methods: {
    loadUnlinkedInvoicePDFs(perPage = 8, page = 1) {
      this.isLoading = true;
      ExternalInvoicePDFRepository.getUnlinkedList(perPage, page).then(res => {
        this.externalInvoicePdfs = res.data.data;
        this.currentPage = res.data.meta.currentPage;
        this.totalPages = res.data.meta.lastPage;
        this.itemsFrom = res.data.meta.from;
        this.itemsTo = res.data.meta.to;
        this.itemsTotal = res.data.meta.total;
      }).finally(() => this.isLoading = false);
    },
    onNewPDFsUploaded() {
      this.loadUnlinkedInvoicePDFs();
      this.showUploadCard = false;
    },
    /**
     * We redirect to the create invoice page with the booking pdf id param.
     * But first we have to get it, by loading the id of the oldest invoice pdf
     * that has not been treated yet.
     */
    redirectToTreatOldest() {
      ExternalInvoicePDFRepository
        .getOldestUnlinkedExternalInvoicePDFId()
        .then((res) => {
          this.$router.push({
            name: 'accounting.invoices.create',
            query: {
              bookExternalInvoicePdfId: res.data.data.id
            }
          })
        });
    }
  },
}
</script>