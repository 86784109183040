<template>
  <div>
    <button-element
      size="small"
      type="danger"
      @click="openModal"
    >
      <fa-icon fixed-width icon="trash"></fa-icon>
    </button-element>

    <delete-confirmation-modal ref="modal" title="Supprimer ligne" @delete="deleteItem">
      Voulez-vous supprimer cette ligne de la facture?
    </delete-confirmation-modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InvoiceDeleteItemButton",
  components: {DeleteConfirmationModal, ButtonElement},
  props: {
    itemId: {
      type: Number,
      required: true
    },
  },
  computed: {
    ...mapGetters('invoices', ['getInvoiceId', 'isInvoiceInternal']),
  },
  methods: {
    ...mapActions('invoices', ['refetchCurrentInvoice']),
    openModal() {
      this.$refs.modal.openModal();
    },
    deleteItem() {
      if (this.isInvoiceInternal) {
        InvoiceRepository
          .internal
          .items
          .deleteItem(this.getInvoiceId, this.itemId)
          .then(() => {
            this.$sendSuccessToast('La ligne a été supprimée.');
            this.refetchCurrentInvoice();
          })
      } else {
        InvoiceRepository
          .external
          .items
          .deleteItem(this.getInvoiceId, this.itemId)
          .then(() => {
            this.$sendSuccessToast('La ligne a été supprimée.');
            this.refetchCurrentInvoice();
          })
      }

    }
  },
}
</script>

