<template>
    <update-localization-form
            v-if="localizations"
            :localizable-id="getBlogPostId"
            :localizations="localizations"
            localizable-type="BlogPost"
            @submit="saveLocalizations"
    />
</template>

<script>
import UpdateLocalizationForm from "@/components/forms/UpdateLocalizationForm";
import BlogRepository from "@/repositories/BlogRepository";

export default {
    name: "UpdateBlogPostLocalization",
    components: {UpdateLocalizationForm},
    data: () => ({
        localizations: null
    }),
    props: {
        blogPostId: {
            type: Number,
            required: true
        }
    },
    mounted() {
        BlogRepository.posts.localization.getForBlogPost(this.getBlogPostId).then((res) => {
            this.localizations = res.data.data;
        })
    },
    computed: {
        getBlogPostId() {
            return this.blogPostId;
        }
    },
    methods: {
        saveLocalizations(payload) {
            BlogRepository
                .posts
                .localization
                .saveForBlogPost(this.getBlogPostId, {
                    localizableData: payload
                })
                .then(() => {
                    this.$sendSuccessToast('Traduction sauvegardé!')
                })
        },
    },
}
</script>

