import auth from "../middlewares/authMiddleware";
import ShowOrders from "../../components/pages/Orders/ShowOrders";
import EditOrder from "../../components/pages/Orders/EditOrder/EditOrder";
import CreateOrder from "@/components/pages/Orders/CreateOrder/CreateOrder.vue";

let orderRoute = {
  component: ShowOrders,
  meta: {
    middleware: [auth],
    title: 'Liste des commandes',
    permissions: ['ACCESS_ORDER_VIEW']
  }
};

export default [
  {
    ...orderRoute,
    name: 'orders.list',
    path: '/orders',
    meta: {
      title: 'Voir commandes',
      permissions: ['ACCESS_ORDER_VIEW']
    }
  },
  {
    ...orderRoute,
    name: 'orders.list.today',
    path: '/orders-today',
    meta: {
      title: 'Voir commandes',
      permissions: ['ACCESS_ORDER_VIEW']
    }
  },
  {
    ...orderRoute,
    name: 'orders.list.tomorrow',
    path: '/orders-tomorrow',
    meta: {
      title: 'Voir commandes',
      permissions: ['ACCESS_ORDER_VIEW']
    }
  },
  {
    path: '/orders/create',
    name: 'orders.create',
    component: CreateOrder,
    meta: {
      middleware: [auth],
      title: 'Créer commande',
      breadcrumbs: ['orders.list'],
      permissions: ['ORDER_WRITE']
    }
  },
  {
    path: '/orders/:order/edit',
    name: 'orders.edit',
    component: EditOrder,
    meta: {
      middleware: [auth],
      title: 'Modifier commande',
      readOnly: {supported: true, title: 'Voir une commande'},
      breadcrumbs: ['orders.list'],
      permissions: ['ORDER_WRITE']
    }
  },
];