<template>
  <status-indicator
    :small="small"
    :type="getStatusType"
  >
    {{ getLabelByStatus }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "MemberStatusIndicator",
  components: {StatusIndicator},
  props: {
    status: {
      type: String,
      validator: val => ['active', 'deactivated', 'anonymized'].includes(val)
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getStatusType() {
      switch (this.status) {
        case "active":
        default:
          return "primary";
        case "deactivated":
          return "danger";
        case "anonymized":
          return "danger";
      }
    },
    getLabelByStatus() {
      switch (this.status) {
        case "active":
          return "Active";
        case "deactivated":
          return "Désactivé";
        case "anonymized":
          return "Anonymisé";
      }
      return this.status;
    }
  },
}
</script>

