import DeliveryTimeSlotRepository from "../../../repositories/DeliveryTimeSlotRepository";

const getDefaultState = () => {
  return {
    currentLoadedZone: null,
    timeSlots: [],
    totals: []
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getTimeSlots: state => state.timeSlots,
    getTotals: state => state.totals,
    getCurrentZone: state => state.currentLoadedZone,
  },
  mutations: {
    clearTimeSlots(state) {
      state.timeSlots = [];
      state.currentLoadedZone = null;
      state.totals = [];
    },
    setTimeSlots(state, slots) {
      state.timeSlots = slots;
    },
    setTotals(state, totals) {
      state.totals = totals;
    },
    setCurrentLoadedZone(state, zone) {
      state.currentLoadedZone = zone;
    },
  },
  actions: {
    fetchTimeSlotsInSpan({commit, getters}, payload) {
      if (getters.getCurrentZone !== payload.zone) {
        commit('clearTimeSlots');
      }

      DeliveryTimeSlotRepository
        .getInSpan(
          payload.zone,
          payload.startDate,
          payload.endDate
        ).then((res) => {
        commit('setTimeSlots', res.data.data.timeSlots);
        commit('setTotals', res.data.data.totals);
        commit('setCurrentLoadedZone', payload.zone);
      });
    }
  },
}