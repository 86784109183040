<template>
  <div>
    <display-product-images ref="imageDisplay" :product-category-id="productCategoryId" class="mb-6"/>
    <upload-product-images :product-category-id="productCategoryId" @uploaded="onImagesUploaded"/>
  </div>
</template>

<script>
import UploadProductImages from "./Upload/UploadProductCategoryImages";
import DisplayProductImages from "./Existing/DisplayProductCategoryImages";

export default {
  name: "ManageProductCategoryImages",
  components: {DisplayProductImages, UploadProductImages},
  props: {
    productCategoryId: {
      type: Number,
      required: true
    }
  },
  methods: {
    // Executed whenever new images have been uploaded
    onImagesUploaded() {
      this.$refs.imageDisplay.fetchImages();
    }
  },
}
</script>

