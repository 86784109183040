<template>
  <div>
    <select-element
      v-model="model"
      :options="getOptions"
      label="Sélectionner un rapport"
    />

    <div v-if="hasSelectedQuery">
      <hr class="my-4">
      <h3
        :class="[getSelectedQueryData.description !== null ? 'mb-2' : 'mb-4']"
        class="font-bold text-2xl"
      >
        {{ getSelectedQueryData.name }}
      </h3>
      <div
        v-if="getSelectedQueryData.description"
        class="text-sm italic mb-4">
        {{ getSelectedQueryData.description }}
      </div>
    </div>

    <div v-else>
      <info-alert class="mt-4" size="small">
        Veuillez sélectionner un rapport dans la liste pour l'exécuter.
      </info-alert>
    </div>
  </div>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import {userHasAtLeastOnePermission} from "@/helpers/permissionHelper";
import store from "@/store/store";
import InfoAlert from "@/components/elements/alerts/InfoAlert.vue";

export default {
  name: "ReportQuerySelect",
  components: {InfoAlert, SelectElement},
  data() {
    return {
      displaySQLScript: false
    }
  },
  created() {
    this.$store.dispatch('reportQuery/fetchAvailableQueries');
  },
  watch: {
    model: function (queryId) {
      if (queryId === null) {
        this.$store.commit('reportQuery/setSelectedQueryData', null);
        return;
      }

      this.$store.commit('reportQuery/resetSelectionResults');
      this.$store.dispatch('reportQuery/fetchQuery', queryId);
    }
  },
  computed: {
    model: {
      get() {
        return this.$store.getters['reportQuery/getSelectedQueryId'];
      },
      set(value) {
        this.$store.commit('reportQuery/setSelectedQueryId', value);
      }
    },
    /**
     * Returns all available Reports.
     * Reports where the user does not match the permissions will be disabled.
     *
     * User needs to have at least ONE of the assigned permissions to each Report.
     * @returns {*}
     */
    getOptions() {
      return this.$store.getters['reportQuery/getAvailableQueries'].map(query => {
        const isAllowed = userHasAtLeastOnePermission(query.permissionNames, store.getters['authUser/getPermissions']);

        return {
          label: query.name,
          value: query.id,
          $isDisabled: !isAllowed
        };
      });
    },
    hasSelectedQuery() {
      return this.getSelectedQueryData !== null;
    },
    getSelectedQueryData() {
      return this.$store.getters['reportQuery/getSelectedQueryData'];
    },
  },
}
</script>