<template>
  <div>
    <page-title>
      Modifier congé pour fournisseur / employée
    </page-title>
    <card>
      <add-or-update-member-holiday-form mode="update"/>
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import AddOrUpdateMemberHolidayForm from "@/components/pages/MemberHoliday/Forms/AddOrUpdateMemberHolidayForm.vue";

export default {
  name: "UpdateMemberHoliday",
  components: {Card, PageTitle, AddOrUpdateMemberHolidayForm},
}
</script>
