<template>
    <span
      class="text-sm text-gray-200 font-semibold hover:text-white cursor-pointer bg-gray-800 hover:bg-gray-900 py-1 px-4 rounded-md"
      @click="logout">
            <fa-icon icon="lock"></fa-icon>
            <span class="ml-4 hidden md:inline-block">Déconnexion</span>
    </span>
</template>

<script>
import authHelper from "@/helpers/authHelper";
import store from "@/store/store";

export default {
  name: "NavbarLogoutButton",
  methods: {
    logout() {
      authHelper.logout().finally(() => {
        store.commit('authUser/clearUser');
        this.$sendSuccessToast("Déconnexion réussie.");
        this.$router.push({name: 'auth.login'});
      });
    },
  },
}
</script>

