<template>
  <div class="md:p-2 grid grid-cols-1 gap-2">
    <picking-group-list-item v-if="fulfilmentGroup" :group="fulfilmentGroup" class="mb-5"></picking-group-list-item>
    <picking-list-item
      v-for="item in getFulfilmentGroupItems"
      :key="item.productId"
      :item="item"
    />
  </div>
</template>

<script>
import FulfilmentRepository from "@/repositories/FulfilmentRepository";
import PickingListItem from "@/components/pages/TouchInterface/Packaging/Picking/Details/PickingGroupDetailsListItem";
import PickingGroupListItem from "../List/Includes/PickingGroupListItem";

export default {
  name: "PickingGroupDetails",
  components: {PickingGroupListItem, PickingListItem},
  data: () => ({
    fulfilmentGroup: null,
  }),
  mounted() {
    this.getFulfilmentGroup();
  },
  methods: {
    getFulfilmentGroup() {
      const group = this.$route.params.group;
      FulfilmentRepository.getFulfilmentGroup(group).then((res) => {
        this.fulfilmentGroup = res.data.data;
      })
    }
  },
  computed: {
    getFulfilmentGroupItems() {
      return this.fulfilmentGroup?.items || [];
    }
  },
}
</script>

