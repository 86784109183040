<template>
  <div>
    <div class="flex flex-col lg:flex-row gap-x-3">
      <div
        class="lg:w-96 lg:max-h-screen-80 overflow-y-auto flex-shrink-0 bg-gray-50 rounded-md p-2 border"
      >
        <h2 v-if="isVirtual" class="text-lg font-bold mb-4 text-center">Virtuel Catégorie produits</h2>
        <h2 v-else class="text-lg font-bold mb-4 text-center">Catégorie produits</h2>

        <edit-product-category-list
          :included-category-ids="getProductCategoryIds"
          :is-virtual="isVirtual"
          :product-id="productId"
        />
      </div>

      <edit-product-category-products-in-category
        v-if="productId && product"
        :category-id="selectedCategoryId"
        :current-product="product"
        @change="fetchProduct"
      />
    </div>
  </div>
</template>

<script>
import EditProductCategoryList from "@/components/pages/Products/EditProduct/Categories/Sub/EditProductCategoryList";
import EditProductCategoryProductsInCategory
  from "@/components/pages/Products/EditProduct/Categories/Sub/EditProductCategoryProductsInCategory";
import ProductRepository from "@/repositories/ProductRepository";

export default {
  name: "ManageProductCategories",
  components: {EditProductCategoryProductsInCategory, EditProductCategoryList},
  data: () => ({
    selectedCategoryId: null,
    product: null
  }),
  props: {
    productId: {
      type: Number,
      required: true
    },
    isVirtual: {
      type: Boolean,
      required: false
    }
  },
  created() {
    this.$store.commit('categories/setSelectedCategoryInSelectionTree', null);
    this.fetchProduct();
  },
  methods: {
    fetchProduct() {
      ProductRepository
        .getSingleWithCategories(this.productId, this.isVirtual)
        .then((res) => {
          this.product = res.data.data;
        });
    }
  },
  computed: {
    /**
     * Returns an array of category ids to product is included in.
     * @returns {*}
     */
    getProductCategoryIds() {
      return this.product?.categories?.flatMap(cat => cat.id) || null;
    }
  },
}
</script>

<style scoped>

</style>