<template>
  <svg class="w-full h-full" viewBox="0 0 887.7 166.76" xmlns="http://www.w3.org/2000/svg">
    <g id="Calque_2" data-name="Calque 2">
      <g id="Layer_1" data-name="Layer 1">
        <path class="fill-luxcaddy"
              d="M111.93,44.59,124.4,32.15c16.29-16.31,44.28-18.48,62-.78,18,18,16,45.84-.45,62.32l-46.29,46.13c-18,18-47.38,19.69-66.46.61L13.57,80.76c-19-19.05-17.31-48.48.62-66.46s47.54-19.84,66.58-.76Zm-50-12.12c-8-8-20.78-7.54-28.91.61S24.37,54,32.4,62L92,121.66a20.3,20.3,0,0,0,28.71-.37h0l.2-.22L167.09,74.9a17.3,17.3,0,0,0,.47-24.47h0l-.17-.18a17.07,17.07,0,0,0-24.13.43l-.1.11h0L111.71,82.28Z"/>
        <path class="fill-gray-800"
              d="M417,92.83l24.1-34.31a5.77,5.77,0,0,0,1.25-3.32c0-1.48-.78-3.25-4.51-3.25H426.46a7.1,7.1,0,0,0-4.11,1,11.24,11.24,0,0,0-2.71,3.13L405.7,78.15,392.18,56.27A12.9,12.9,0,0,0,389.41,53a6.65,6.65,0,0,0-4-1.06H372.85c-3.73,0-4.51,1.81-4.51,3.35a5.66,5.66,0,0,0,1.25,3.19l23.78,34.32-25.19,38.77a5.84,5.84,0,0,0-1.51,3.19c0,1.49.78,3.26,4.51,3.26H383A6.83,6.83,0,0,0,387.2,137a9.81,9.81,0,0,0,2.63-3.15l14.81-25.41L419.58,134a11.21,11.21,0,0,0,2.78,3.19,6.92,6.92,0,0,0,3.53,1h13.36c3.74,0,4.52-1.78,4.52-3.32a5.77,5.77,0,0,0-1.26-3.15Z"/>
        <path class="fill-gray-800"
              d="M476.7,71.85c2.55-3.31,6.71-4.92,12.73-4.92,4.75,0,8.29,1,10.51,2.89s3.84,5.05,4.68,9.33a4.24,4.24,0,0,0,1.61,3l.09.06a7.8,7.8,0,0,0,4.15.79h8.65a7.69,7.69,0,0,0,4.56-1l.13-.1A4.93,4.93,0,0,0,525,78.13c0-7.67-2.48-14-7.34-18.71C511,53,501.29,49.69,488.73,49.69c-11.89,0-21.1,3.33-27.36,9.91s-9.43,16.08-9.43,28.29v15.93c0,11.79,3.17,20.93,9.44,27.17s15.46,9.4,27.35,9.4h.12c12.51,0,22.21-3.27,28.83-9.73,4.87-4.73,7.34-11,7.33-18.65a4.9,4.9,0,0,0-1.2-3.9l-.13-.1a7.69,7.69,0,0,0-4.51-1h-8.66a8.08,8.08,0,0,0-4.19.78l-.09.06a4.26,4.26,0,0,0-1.6,3c-.86,4.3-2.54,7.42-5,9.29S493.51,123,488.73,123c-5.77,0-9.79-1.6-12.28-4.91s-3.66-8.91-3.66-16.68V88.7C472.79,80.88,474.1,75.21,476.7,71.85Z"/>
        <path class="fill-gray-800"
              d="M596,53.14c-5-2.29-12-3.45-20.68-3.45a55.26,55.26,0,0,0-18.06,2.47,28.31,28.31,0,0,0-11.12,6.93,17.55,17.55,0,0,0-5.34,12.19A3.93,3.93,0,0,0,542,74.35l.08.07a6.06,6.06,0,0,0,3.95,1h10.8a8.34,8.34,0,0,0,3.92-.61l.13-.07a5,5,0,0,0,1.7-2.81,8.3,8.3,0,0,1,4.45-5,20.23,20.23,0,0,1,8.33-1.47c4.86,0,8.49,1.13,10.77,3.36s3.46,6.33,3.46,12.24v3.66c-2-.2-4-.34-6.08-.48l-2.68-.19c-3.3-.24-6.23-.24-8.81-.24a56,56,0,0,0-17.21,2.5,24.9,24.9,0,0,0-12.64,8.79c-3.15,4.15-4.75,9.86-4.75,17,0,9.65,2.91,16.75,8.64,21.12s14.6,6.53,26.48,6.53h4.83c8.31,0,14.89-1.34,19.53-4a22.51,22.51,0,0,0,10-10.56,33.5,33.5,0,0,0,2.89-13.75V79.77a39,39,0,0,0-3.06-16.31A20.69,20.69,0,0,0,596,53.14ZM557.8,112c0-4.49,1.25-7.69,3.73-9.52s6.39-2.9,11.48-2.9c2.35,0,4.74.05,7.31.16,2.83.12,5.93.38,9.23.76v6.94c0,6-1.14,10.33-3.39,12.84s-6,3.73-11.34,3.73H572a17.82,17.82,0,0,1-10.52-2.66C559,119.62,557.8,116.48,557.8,112Z"/>
        <path class="fill-gray-800"
              d="M254,21.85H242.62V138.72h21.13v-105C263.75,27.19,259.36,21.85,254,21.85Z"/>
        <path class="fill-gray-800"
              d="M699.54,22.23h0a8.66,8.66,0,0,0-5.82-2.32H682.37v37c-5-4.4-12.06-6.64-20.9-6.66-11.24,0-20,3.43-26,10.18s-9,16.46-9,28.92v15.89c0,11,3.45,19.87,10.27,26.22S653.15,141,665.16,141c12.24,0,21.73-3.21,28.22-9.56s9.83-15.49,9.92-27.17V29.32A12.55,12.55,0,0,0,699.54,22.23Zm-16.8,67.4V102c0,7.28-1.48,12.79-4.4,16.4s-7.31,5.37-13.13,5.37h0c-11.81,0-17.61-7.13-17.72-21.77V89.47c0-7.28,1.49-12.8,4.44-16.4s7.38-5.37,13.26-5.37h0c5.28,0,9.42,1.47,12.29,4.36C681,75.65,682.81,81.56,682.74,89.63Z"/>
        <path class="fill-gray-800"
              d="M791.69,21.73h0a8.68,8.68,0,0,0-5.79-2.32H774.55v37c-5-4.41-12.06-6.64-20.88-6.65-11.25,0-20,3.41-26,10.16s-9,16.45-9,28.92v15.84c0,11,3.46,19.87,10.27,26.21s16.35,9.55,28.36,9.57c12.22,0,21.72-3.22,28.22-9.58s9.81-15.5,9.84-27.17V28.76A12.54,12.54,0,0,0,791.69,21.73Zm-16.78,79.68c0,8-1.81,13.92-5.37,17.47-2.86,2.86-6.95,4.31-12.16,4.31h0c-11.83,0-17.63-7.15-17.73-21.78V88.91c0-14.64,5.8-21.76,17.73-21.77h0c5.27,0,9.4,1.46,12.27,4.35,3.56,3.58,5.33,9.49,5.28,17.57Z"/>
        <path class="fill-gray-800"
              d="M872.41,52.05c-4.15,0-7.34,1.54-9.21,4.47a13.58,13.58,0,0,0-.72,1.42l-2,6.4-15,44.44L826,57.45a8.64,8.64,0,0,0-2.38-3.77,6.32,6.32,0,0,0-3.78-1H808.67c-3.73,0-4.51,1.86-4.51,3.44a5.31,5.31,0,0,0,.58,2.23l30.88,75.7-1.26,2.83a42.91,42.91,0,0,1-4,7.56,8.8,8.8,0,0,1-4.65,3.54,28.76,28.76,0,0,1-8.57,1h-1.28a5.1,5.1,0,0,0-3.7,1l-.12.13a6.09,6.09,0,0,0-.92,3.93v7.57a6,6,0,0,0,.91,4l.12.13a5,5,0,0,0,3.06,1,5.49,5.49,0,0,0,.58,0h4a44.69,44.69,0,0,0,15.84-2.33,21.08,21.08,0,0,0,10.1-8.23,83.24,83.24,0,0,0,8-16.27l11.37-30.47c.11-.25,10.4-25.37,17.35-43.33,2.31-5.94,3.94-10.3,5-13.34l.23-.66Z"/>
        <path class="fill-gray-800"
              d="M354.91,50.84l-11.34,0h0a10.59,10.59,0,0,0-4.44,1,9.05,9.05,0,0,0-5.35,8.09v4.61h0V110a11.78,11.78,0,0,1-.46,2.41,13.24,13.24,0,0,1-3,5.22,15.29,15.29,0,0,1-11.93,4.72c-5.16,0-8.74-1.27-10.64-3.78s-2.91-6.29-2.91-11.16V64.51l0-6.36c0-.48,0-1-.05-1.47,0-.69-.08-1.35-.13-2l-.1-.44a2.62,2.62,0,0,0-.68-1.24c-.07-.06-.27-.2-.49-.35s-.22-.14-.39-.23-.3-.15-.57-.25a8.17,8.17,0,0,0-1-.27l-.13,0c-.16,0-.39-.07-.85-.14-.64-.08-1.21-.14-1.47-.16-1.25-.11-3.12,0-5.08,0-1.34,0-2.69.07-4,.07a13.81,13.81,0,0,0-1.74.12,9.17,9.17,0,0,0-1.06.19l-.73.21a3.08,3.08,0,0,0-.88.49l-.11.1h0l-.13.11,0,0a3.2,3.2,0,0,0-.53.7,4.73,4.73,0,0,0-.42,1c0,.07,0,.12,0,.19a8.08,8.08,0,0,0-.22,2c0,.77,0,1.3,0,1.73,0,1,0,1.41,0,2.72,0,2.21,0,4.42,0,5.28,0,2.21,0,11.93,0,38.8v2.07c0,.59-.05,1.19-.05,1.79s0,1.45.08,2.17c1.19,17.38,16.52,31.12,35.26,31.12a37.25,37.25,0,0,0,13.6-2.55c.55-.22,1.08-.47,1.61-.71h0l.63-.28a35.28,35.28,0,0,0,9.56-6.62,32.2,32.2,0,0,0,9.93-23.13c0-.42,0-.84,0-1.25.23-2.32.34-4.74.34-7.28Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoSvg"
}
</script>

<style scoped>

</style>