import auth from "../middlewares/authMiddleware";
import ShowLogsTable from "@/components/pages/Logs/ShowLogsTable";

export default [
  {
    path: '/logs',
    name: 'logs.list',
    component: ShowLogsTable,
    meta: {
      middleware: [auth],
      title: 'Logs',
      permissions: ['READ_ALL_LOGS']
    }
  },
];