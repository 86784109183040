<template>
  <div>
    <page-title>
      {{ getTitle }}
    </page-title>

    <card>
      <form-wrapper
        ref="form"
        :show-changed="true"
        :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer compte'"
        @submit="createOrUpdateBookingAccount"
      >
        <div class="grid grid-cols-12 gap-y-4 md:gap-x-8">
          <number-input-element
            v-if="!isUpdateMode"
            v-model.number="id"
            class="col-span-12 lg:col-span-6 xl:col-span-5"
            label="ID"
            validation-rules="required"
          />

          <text-input-element
            v-model="name"
            class="col-span-12 lg:col-span-6 xl:col-span-5"
            label="Nom compte"
            validation-rules="required"
          />
        </div>
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import Card from "../../../elements/cards/Card";
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import PageTitle from "../../../elements/pages/PageTitle";
import BookingAccountRepository from "@/repositories/BookingAccountRepository";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";

export default {
  name: "CreateOrUpdateBookingAccount",
  components: {
    NumberInputElement,
    PageTitle,
    TextInputElement,
    FormWrapper,
    Card
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    }
  },
  data: () => ({
    id: null,
    name: '',
  }),
  mounted() {
    this.getBookingAccount();
  },
  methods: {
    getBookingAccount() {
      if (this.isUpdateMode) {
        BookingAccountRepository.getSingle(this.$route.params.bookingAccount).then((res) => {
          this.id = res.data.data.id;
          this.name = res.data.data.name;
        }).then(() => this.$refs.form.resetForm());
      }
    },
    createOrUpdateBookingAccount(callback) {
      if (this.isUpdateMode) {
        return BookingAccountRepository.update(this.$route.params.bookingAccount, {
          name: this.name
        }).then(() => {
          this.$sendSuccessToast(`Compte ${this.name} a été modifié!`);
        }).finally(() => callback());
      }

      return BookingAccountRepository.create({
        id: this.id,
        name: this.name
      }).then((res) => {
        this.$router.push({name: 'booking-accounts.update', params: {bookingAccount: res.data.data.id}});
        this.$sendSuccessToast(`Compte ${this.name} a été crée!`);
      }).finally(() => callback());
    },
  },
  computed: {
    isUpdateMode() {
      return this.mode === 'update';
    },
    getTitle() {
      if (this.isUpdateMode) {
        return 'Modifier compte';
      }
      return 'Créer nouveaux compte';
    }
  }
}
</script>

