
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import Card from "@/components/elements/cards/Card.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";
import Vue from "vue";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement.vue";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";
import VehicleRepository from "@/repositories/VehicleRepository";
import {AxiosResponse} from "axios";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import DeviceRepository from "@/repositories/DeviceRepository";

export default Vue.extend({
  name: "CreateUpdateVehicleForm",
  components: {SelectElement, CheckboxElement, NumberInputElement, TextInputElement, PageTitle, Card, FormWrapper},
  created() {
    this.fetchDeviceList();

    if (!this.isCreateMode) {
      this.fetchVehicle();
    }
  },
  data() {
    return {
      vehiclePlate: '' as string,
      boxCapacity: null as number | null,
      distanceLimit: null as number | null,
      makeAndModel: '' as string,
      isOutOfOrder: false as boolean,
      deviceId: null as number | null,

      deviceList: [] as Array<object>,
    };
  },
  props: {
    mode: {
      type: String as () => "create" | "update",
      default: 'create' as const
    },
    vehicleId: {
      type: Number as () => number | null,
      default: null as null
    }
  },
  methods: {
    fetchDeviceList(): void {
      DeviceRepository
        .getPaginated(100, 1)
        .then((res: AxiosResponse) => {
          this.deviceList = res.data.data;
        })
    },

    fetchVehicle(): void {
      VehicleRepository.getSingle(this.vehicleId)
        .then((res: AxiosResponse) => {
          const vehicle = res.data.data;

          this.vehiclePlate = vehicle.vehiclePlate;
          this.boxCapacity = vehicle.boxCapacity;
          this.distanceLimit = vehicle.distanceLimit;
          this.makeAndModel = vehicle.makeAndModel;
          this.isOutOfOrder = vehicle.isOutOfOrder;
          this.deviceId = vehicle.device.id;
        })
    },

    onSubmit(callback: () => Promise<void>) {
      if (this.isCreateMode) {
        return this.createVehicle().finally(() => callback());
      } else {
        return this.updateVehicle().finally(() => callback());
      }
    },

    createVehicle() {
      return VehicleRepository.createVehicle(
        {
          makeAndModel: this.makeAndModel,
          vehiclePlate: this.vehiclePlate,
          boxCapacity: this.boxCapacity,
          distanceLimit: this.distanceLimit,
          isOutOfOrder: this.isOutOfOrder,
          deviceId: this.deviceId as number,
        }
      ).then((res: AxiosResponse) => {
        this.$sendSuccessToast("Vehicule crée!");
        this.$router.push({
          name: 'vehicles.update',
          params: {
            id: res.data.data.id
          }
        })
      })
    },

    updateVehicle() {
      return VehicleRepository.updateVehicle(this.vehicleId, {
        makeAndModel: this.makeAndModel,
        vehiclePlate: this.vehiclePlate,
        boxCapacity: this.boxCapacity,
        distanceLimit: this.distanceLimit,
        isOutOfOrder: this.isOutOfOrder,
        deviceId: this.deviceId as number,
      }).then(() => {
        this.$sendSuccessToast("Terminal modifié!");
        this.$nextTick(() => (this.$refs.form as any).resetForm());
      })
    }
  },
  computed: {
    isCreateMode(): boolean {
      return this.mode === 'create';
    },

    getFormattedDevices(): Array<object> {
      return this.deviceList.map((device: any) => {
        return {
          label: device.imei + ' / Tel: ' + device.phoneNumber,
          value: device.id
        }
      })
    },
  },
})
