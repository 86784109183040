<template>
  <create-update-device-form :device-id="getDeviceId" mode="update"/>
</template>

<script>
import CreateUpdateDeviceForm from "@/components/pages/Devices/Forms/CreateUpdateDeviceForm";

export default {
  name: "UpdateDevice",
  components: {CreateUpdateDeviceForm},
  computed: {
    getDeviceId() {
      return Number(this.$route.params.id);
    }
  },
}
</script>

