<template>
  <div>
    <form-wrapper
      :use-button-spacing="false"
      class="flex flex-col md:flex-row gap-x-4 items-center"
      submit-button-icon="search"
      submit-button-label="Recherche"
      submit-button-type="light"
      @submit="launchSearch"
    >
      <number-input-element
        v-model="searchedInvoiceId"
        class="w-full md:flex-1"
        label="Recherche par ID Facture"
        validation-rules="required"
      />
    </form-wrapper>

    <template v-if="searchLaunched">
      <custom-table
        :headings="getHeadings"
        disable-loading-indicator
      >
        <table-row
          v-for="transaction in getSearchReturnedTransactions"
          :key="transaction.identifier"
          :class="{'bg-luxcaddy-100': isTransactionLinked(transaction.identifier)}"
        >
          <table-data-cell>{{ transaction.provider | paymentProviderKeyToReadable }}</table-data-cell>
          <table-data-cell>{{ transaction.identifier }}</table-data-cell>
          <table-data-cell align="right">
            <money-amount
              v-if="transaction.prauthorizedAmount"
              :amount="transaction.preauthorizedAmount"
              :currency="transaction.currency"
            />
            <span v-else>/</span>
          </table-data-cell>
          <table-data-cell align="right">
            <money-amount
              v-if="transaction.payedAmount"
              :amount="transaction.payedAmount"
              :currency="transaction.currency"
            />
            <span v-else>/</span>
          </table-data-cell>
          <table-data-cell>{{ $date(transaction.createdAt).format('DD.MM.YYYY') }}</table-data-cell>
          <table-data-cell>
            <div class="flex justify-end">
              <button-element
                :disabled="isTransactionLinked(transaction.identifier)"
                size="small"
                @click="linkTransaction(transaction.id)"
              >
                <fa-icon fixed-width icon="link"/>
              </button-element>
            </div>
          </table-data-cell>
        </table-row>
      </custom-table>
    </template>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import {mapActions, mapGetters} from "vuex";
import CustomTable from "@/components/elements/tables/CustomTable";
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import MoneyAmount from "@/components/global/Money/MoneyAmount";
import NumberInputElement from "../../../../../../../../elements/forms/elements/base/NumberInputElement";
import {eventBus} from "@/eventBus";

export default {
  name: "BankMovementListExistingPaymentTransaction",
  components: {NumberInputElement, MoneyAmount, ButtonElement, TableDataCell, TableRow, CustomTable, FormWrapper},
  data: () => ({
    searchLaunched: false,
  }),
  methods: {
    ...mapActions('bankMovements', [
      'fetchTransactionsByInvoiceId',
      'linkPaymentTransaction',
    ]),
    launchSearch(callback) {
      this.searchLaunched = true;
      this.fetchTransactionsByInvoiceId().finally(() => callback());
    },
    isTransactionLinked(identifier) {
      return this
        .getLinkedPaymentTransactions
        .find(lpt => lpt.identifier === identifier) !== undefined;
    },
    linkTransaction(transactionId) {
      this
        .linkPaymentTransaction(transactionId)
        .then(() => {
          this.$sendSuccessToast("La transaction de paiement sélectionnée a été liée au mouvement bancaire.");

          eventBus.$emit('bank-movement-change');
        })
    }
  },
  computed: {
    ...mapGetters('bankMovements', [
      'getSearchReturnedTransactions',
      'getLinkedPaymentTransactions'
    ]),
    searchedInvoiceId: {
      get() {
        return this.$store.getters['bankMovements/getSearchedInvoiceId'];
      },
      set(InvoiceId) {
        this.$store.commit('bankMovements/setSearchedInvoiceId', InvoiceId);
      }
    },
    getHeadings() {
      return [
        {label: 'Methode'},
        {label: 'Identifier'},
        {label: 'Montant práutorisé', align: 'right'},
        {label: 'Montant payé', align: 'right'},
        {label: 'Crée'},
        {label: 'Actions', align: 'right'},
      ];
    },
  },
}
</script>