<template>
  <div>
    <div class="text-gray-400 text-xs mb-2">
      This Test page does not work on production.
    </div>

    <card>
      <card-tab id="select" title="Select Element">
        <test-page-select-element/>
      </card-tab>

      <card-tab id="datepicker" title="Datepicker Element">
        <test-page-date-picker-element/>
      </card-tab>
    </card>

  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card.vue";
import CardTab from "@/components/elements/cards/tabs/CardTab.vue";
import TestPageDatePickerElement from "@/components/pages/TestPage/Tabs/TestPageDatePickerElement.vue";
import TestPageSelectElement from "@/components/pages/TestPage/Tabs/TestPageSelectElement.vue";

export default {
  name: "TestPage",
  components: {
    TestPageSelectElement,
    TestPageDatePickerElement,
    CardTab,
    Card
  },

}
</script>

<style scoped>

</style>