<template>
  <transition name="fade-up-down">
    <div
      v-if="isOpen"
      class="fixed z-50 h-screen w-screen inset-0 bg-gray-800 flex justify-center items-center flex-col"
    >

      <div class="h-10 lg:h-14 w-full top-0 left-0 right-0 bg-gray-600">
        <div class="flex h-full justify-between items-center mx-3">
          <div>
            <slot name="titlebar"></slot>
          </div>
          <div
            class="flex space-x-2 items-center px-2 cursor-pointer text-white hover:text-gray-200 lg:text-lg select-none"
            @click="close"
          >
            <div>
              Fermer
            </div>
            <fa-icon
              class="text-2xl"
              icon="times"
            />
          </div>
        </div>
      </div>

      <div
        class="w-full flex-1 h-screen-80 overflow-y-auto flex flex-col lg:justify-center lg:items-center text-white">
        <div class="py-6 px-4 min-h-0">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TouchOptimizedModal",
  data: () => ({
    isOpen: false
  }),
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.$emit('close');
      this.isOpen = false;
    },
  },
}
</script>

