<template>
  <div class="flex items-center gap-x-2">
    <span class="text-blue-900 text-5xl font-semibold">{{ getBoxQuantity }}</span>
    <span class="text-black text-lg font-semibold">Bacs</span>
    <touch-optimized-button padding="py-6" type="dark" @click="addBox">+</touch-optimized-button>
    <touch-optimized-button padding="py-6" type="dark" @click="removeBox">-</touch-optimized-button>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "OrderFulfilmentBoxQuantityManagement",
  components: {TouchOptimizedButton},
  methods: {
    ...mapActions('fulfilment', [
      'setBoxQuantity'
    ]),
    addBox() {
      if (this.getBoxQuantity >= 99)
        return false;

      const newQuantity = this.getBoxQuantity + 1;
      this.setBoxQuantity(newQuantity);
    },
    removeBox() {
      if (this.getBoxQuantity === 0)
        return false;

      const newQuantity = this.getBoxQuantity - 1;
      this.setBoxQuantity(newQuantity);
    }
  },
  computed: {
    ...mapGetters('fulfilment', [
      'getBoxQuantity',
    ]),
  },
}
</script>

