<template>
  <card
    :title="name"
    class="max-h-120 "
    size="small"
    type="light"
  >
    <div
      v-if="!hasLoadedDataSet"
      class="flex items-center justify-center">
      <loading-svg-inline class="w-36 h-full"/>
    </div>

    <!-- Data loaded -->
    <div
      v-else-if="(getDataSetForBarChart !== null) || (graphConfig !== null)"
      class="h-102"
    >
        <dynamic-chart
          :series="getDataSetForChart"
          :config="graphConfig"
        ></dynamic-chart>
    </div>
  </card>
</template>

<script>
import Card from "@/components/elements/cards/Card.vue";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline.vue";
import DynamicChart from "@/components/elements/charts/DynamicChart.vue";

export default {
  name: "DynamicReportQueryGraphItem",
  components: {DynamicChart, LoadingSvgInline, Card},
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true,
    },
    graphConfig: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.graphConfig.cacheTimeout !== undefined) {
      this.timeInterval = setInterval(() => {
        this.fetchData();
      }, (this.graphConfig.cacheTimeout * 1000));
    }

  },
  beforeDestroy() {
    if (this.graphConfig.cacheTimeout !== undefined) {
      clearInterval(this.timeInterval);
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch('reportQueryGraphs/fetchGraphById', this.id);
    }
  },
  computed: {
    hasLoadedDataSet() {
      return this.$store.getters["reportQueryGraphs/hasLoadedDataSetForId"](this.id);
    },

    getDataSetForChart() {
        if (!this.hasLoadedDataSet || this.isDonutChart) {
          return this.getDataSetForDonutChart;
        }

        return this.getDataSetForBarChart;
    },
    getDataSetForBarChart() {
      if (!this.hasLoadedDataSet || this.isDonutChart) {
        return null;
      }

      let series = [];
      const data = this.$store.getters["reportQueryGraphs/getDataSetForId"](this.id);

      // 1st Column = Key / X-Axis
      // 2nd column = Values

      // Counts the Amount of Series to add.
      const valuesCount = Object.keys(data[0]).length;

      // Each Column after the 1st represent a data series
      for (let i = 1; i < valuesCount; i++) {
        let name = Object.keys(data[0])[i];

        series.push({
          name: name,
          data: data.map(d => {
            const values = Object.values(d);

            return {
              x: values[0],
              y: values[i],
            }
          })
        });
      }

      return series;
    },

    getDataSetForDonutChart() {
      if (!this.hasLoadedDataSet || !this.isDonutChart) {
        return null;
      }

      const data = this.$store.getters["reportQueryGraphs/getDataSetForId"](this.id);

      const labels = data.map(obj => Object.keys(obj).slice(0)).flat();
      if (this.graphConfig.options) {
          this.$set(this.graphConfig.options, 'labels', labels);
      } else {
          this.$set(this.graphConfig, 'options', {
              labels: labels,
          });
      }


      return data.map(obj => Object.values(obj).slice(0)).flat();
    },

    isDonutChart() {
      return ["donut", "pie"].includes(this.getType);
    },

    getType() {
      if (this.graphConfig.type !== undefined) {
        return this.graphConfig.type;
      }

      if (this.graphConfig?.options?.chart?.type !== undefined ) {
        return this.graphConfig?.options?.chart?.type;
      }

      return 'bar'
    }
  },
}
</script>