<template>
  <card
    v-if="isTargetLanguageSelected"
    rounded
    size="small"
  >
    <div class="flex items-center gap-x-2">
      <div class="font-bold mr-3">Traduction automatique:</div>
      <span class="text-luxcaddy text-sm w-24 text-center truncate">{{
          $store.getters["localization/translation/getSelectedLocale"] | languageByIsoCode
        }}</span>
      <div class="w-12">
        <fa-icon fixed-width icon="arrow-right"/>
      </div>

      <div class="flex gap-x-3 md:w-80">
        <localization-translation-box-target-language
          v-for="locale in $store.getters['localization/translation/getAvailableTargetLocales']"
          :key="locale"
          :locale="locale"
        />
      </div>

      <button-element
        class="ml-3"
        size="small"
        type="light"
        @click="fetchTranslations"
      >
        Traduire
      </button-element>
    </div>
  </card>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import Card from "@/components/elements/cards/Card.vue";
import LocalizationTranslationBoxTargetLanguage
  from "@/components/forms/localizationTranslation/LocalizationTranslationBoxTargetLanguage.vue";

export default {
  name: "LocalizationTranslationBox",
  components: {LocalizationTranslationBoxTargetLanguage, Card, ButtonElement},
  props: {
    localizableType: {
      type: String,
      required: true
    },
    localizableId: {
      type: Number,
      required: true
    }
  },
  methods: {
    fetchTranslations() {
      this.$store.dispatch('localization/translation/fetchTranslation', {
        localizableType: this.localizableType,
        localizableId: this.localizableId
      }).then((res) => {
        this.$emit('translations', res.data.data);
      });
    }
  },
  computed: {
    isTargetLanguageSelected() {
      return this.$store.getters['localization/translation/getSelectedLocale'] !== null;
    }
  },
}
</script>

<style scoped>

</style>