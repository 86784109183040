const getDefaultState = () => {
  return {
    selectedProduct: {
      id: null,
      name: null,
      images: null,
      onShelfStock: null,
      isOrganic: null
    },
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getSelectedProductId: state => state.selectedProduct.id,
    getSelectedProductName: state => state.selectedProduct.name,
    getSelectedProductImages: state => state.selectedProduct.images,
    getSelectedProductOnShelfStock: state => state.selectedProduct.onShelfStock,
    getSelectedProductIsOrganic: state => state.selectedProduct.isOrganic,

    isProductSelected: state => state.selectedProduct.id !== null,
  },
  mutations: {
    setSelectedProduct(state, product) {
      state.selectedProduct.id = product.id;
      state.selectedProduct.name = product.name;
      state.selectedProduct.images = product.images;
      state.selectedProduct.onShelfStock = product.onShelfStock;
      state.selectedProduct.isOrganic = product.isOrganic;
    },
    unsetSelectedProduct(state) {
      state.selectedProduct.id = null;
      state.selectedProduct.name = null;
      state.selectedProduct.images = null;
      state.selectedProduct.onShelfStock = null;
      state.selectedProduct.isOrganic = null;
    }
  },
}