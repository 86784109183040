<template>
  <nav
    v-if="hasBreadcrumbs && showBreadcrumbBar && hasParent"
    aria-label="Breadcrumb"
    class="lg:h-10 bg-white shadow border-b border-gray-200 py-1 px-4 lg:px-6 text-sm flex flex-col md:flex-row gap-y-3 md:gap-y-0 flex-wrap justify-between flex-wrap items-center"
  >
    <ol class="list-none p-0 inline-flex">
      <li
        v-for="(breadcrumb, i) in breadcrumbList"
        :key="i"
        class="flex items-center"
      >
                <span
                  :class="[isCurrentRoute(breadcrumb.route) ? 'font-medium text-gray-600 italic' : 'text-luxcaddy-600 hover:underline cursor-pointer']"
                  @click="goToRoute(i)"
                >
                    {{ breadcrumb.title }}
                </span>

        <fa-icon
          v-if="!isCurrentRoute(breadcrumb.route)"
          class="w-3 h-3 mx-3 text-gray-400"
          fixed-width
          icon="chevron-right"
        />
      </li>
    </ol>

    <div class="flex gap-x-5 items-center">
      <!--
          Todo, Make this shortcut system more flexible when using on pages other than invoice edit
          Also move, eventBus Methods to a file with constants
          Maybe even consider removal of the eventBus stuff and think of a better way,
          as this is discourage by vue and removed in Vue 3.
      -->
      <shortcut-menu
        v-if="$route.name === 'accounting.invoices.edit'"
      >
        <shortcut-button
          :icon-set="['plus']"
          :keys="['alt', 'l']"
          action-text="Ajouter ligne"
          @action="eventBus.$emit('invoice-edit-open-add-item')"
        />
        <shortcut-button
          :icon-set="['check']"
          :keys="['alt', 'n']"
          action-text="Finaliser + Nouveau"
          @action="eventBus.$emit('invoice-edit-finalize-and-next')"
        />
      </shortcut-menu>

      <read-only-toggle
        v-if="currentRouteSupportsReadOnlyMode"
        class="justify-self-end"
      />
    </div>
  </nav>
</template>

<script>
import ReadOnlyToggle from "../readonly/ReadOnlyToggle";
import ShortcutMenu from "@/components/elements/breadcrumbs/shortcut/ShortcutMenu.vue";
import ShortcutButton from "@/components/elements/breadcrumbs/shortcut/ShortcutButton.vue";
import {eventBus} from "@/eventBus";

export default {
  name: "BreadcrumbBar",
  components: {ShortcutButton, ShortcutMenu, ReadOnlyToggle},
  data: () => ({
    breadcrumbList: []
  }),
  watch: {
    '$route'() {
      this.updateList();
    },
    isInReadOnlyMode() {
      this.updateList();
    }
  },
  mounted() {
    this.updateList();
  },
  methods: {
    goToRoute(route) {
      if (this.breadcrumbList[route].route) {
        this.$router.push({name: this.breadcrumbList[route].route});
      }
    },
    isCurrentRoute(routeName) {
      return this.$route.name === routeName;
    },
    updateList() {
      this.breadcrumbList = [];

      // Add Children
      this.$route.meta.breadcrumbs?.forEach(b => {
        let route = this.getRouteByName(b);
        let routeTitle = this.getRouteTitle(route);

        this.addBreadCrumb(routeTitle, b);
      });

      // Add self
      this.addBreadCrumb(this.getRouteTitle(this.$router.currentRoute), this.$router.currentRoute.name);
    },
    addBreadCrumb(routeTitle, routeLink) {
      this.breadcrumbList.push({
        title: routeTitle,
        route: routeLink,
      })
    },
    getRouteByName(routeName) {
      return this.$router.options.routes.find(r => r.name === routeName);
    },
    getRouteTitle(route) {
      if (!route)
        return '';

      return this.isInReadOnlyMode ? route?.meta?.readOnly?.title || route?.meta?.title || '' : route?.meta?.title || '';
    }
  },
  computed: {
    eventBus() {
      return eventBus
    },
    showBreadcrumbBar() {
      if (this.$route.meta.showBreadcrumbBar !== undefined) {
        return this.$route.meta.showBreadcrumbBar;
      }
      return true;
    },
    hasBreadcrumbs() {
      return Array.isArray(this.breadcrumbList) && this.breadcrumbList.length;
    },
    hasParent() {
      return this.hasBreadcrumbs && this.breadcrumbList.length > 1;
    },
    currentRouteSupportsReadOnlyMode() {
      return this.$route.meta?.readOnly?.supported || false;
    },
    isInReadOnlyMode() {
      return this.$isReadOnly();
    }
  }
}
</script>

