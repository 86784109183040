import auth from "../middlewares/authMiddleware";
import ShowBlogPostTable from "@/components/pages/Blog/Posts/ShowBlogPostTable";
import CreateBlogPostPage from "@/components/pages/Blog/Posts/CreateBlogPostPage";
import UpdateBlogPostPage from "@/components/pages/Blog/Posts/UpdateBlogPostPage";
import ShowBlogCategories from "@/components/pages/Blog/Categories/ShowBlogCategories";

export default [
  {
    path: '/blog',
    name: 'blog.list',
    component: ShowBlogPostTable,
    meta: {
      middleware: [auth],
      title: 'Blog',
      permissions: ['BLOG_READ']
    }
  },
  {
    path: '/blog-categories',
    name: 'blog.categories.list',
    component: ShowBlogCategories,
    meta: {
      middleware: [auth],
      title: 'Catégories Blog',
      permissions: ['BLOG_READ']
    }
  },
  {
    path: '/blog/posts/create',
    name: 'blog.posts.create',
    component: CreateBlogPostPage,
    meta: {
      middleware: [auth],
      title: 'Créer article blog / recette',
      breadcrumbs: ['blog.list'],
      permissions: ['BLOG_WRITE']
    }
  },
  {
    path: '/blog/posts/:blogPostId/update',
    name: 'blog.posts.update',
    component: UpdateBlogPostPage,
    meta: {
      middleware: [auth],
      title: 'Modifier article blog / recette',
      breadcrumbs: ['blog.list'],
      permissions: ['BLOG_WRITE']
    }
  }
];