import apiService from './Base/BaseRepository';

const url = '/products';

export default {
  /**
   * Fetches all products.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`);
  },

  async getOnShelfStock(productId) {
    return await apiService.get(`${url}/${productId}/getOnShelfStock`);
  },

  async getPackagingInfo(productId) {
    return await apiService.get(`${url}/${productId}/packagingInfo`);
  },

  /**
   * Fetches all products from algolia.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async search(query, hitsPerPage = 20, withLoadingIndicator = false) {
    return await apiService
      .setPagination(hitsPerPage)
      .get(`${url}/search/${query}`, {
        showLoadingIndicator: withLoadingIndicator
      });
  },

  async getAllProductsByCategoryId(categoryId) {
    return await apiService
      .get(`/categories/${categoryId}/products`);
  },

  /**
   * Fetches all products.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0,
    showLoadingIndicator = true
  ) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`, {
        showLoadingIndicator
      });
  },

  async getPaginatedForSelect(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0,
    showLoadingIndicator = true
  ) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/select`, {
        showLoadingIndicator
      });
  },

  /**
   * Fetches all products for export.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getExport(
    sorts = [],
    filters = [],
  ) {

    return await apiService
      .setSorts(sorts)
      .setFilters(filters)
      .downloadBlob(`${url}/export`);
  },


  /**
   * Returns a single product.
   * @param productId
   * @param withLoadingIndicator
   * @return {Promise<AxiosResponse<any>>}
   */
  async getSingle(productId, withLoadingIndicator = true) {
    return await apiService
      .get(`${url}/${productId}`, {
        showLoadingIndicator: withLoadingIndicator
      });
  },

  async getSingleWithCategories(productId, isVirtual = false) {
    let isVirtualFilter = isVirtual ? 1 : 0;

    return await apiService
      .get(`${url}/${productId}/withCategories?isVirtual=${isVirtualFilter}`);
  },

  /**
   * Fetches some data on our product on external services like open food facts.
   * Currently only ingredients.
   */
  async getProductDataFromApi(productId) {
    return await apiService
      .get(`${url}/${productId}/dataFromApi`);
  },

  /**
   * Creates a new product.
   * @return {Promise<AxiosResponse<any>>}
   */
  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  /**
   * Creates a new product.
   * @return {Promise<AxiosResponse<any>>}
   */
  async update(productId, payload) {
    return await apiService.patch(`${url}/${productId}`, payload);
  },

  async duplicate(productId) {
    return await apiService.post(`${url}/${productId}/duplicate`);
  },

  /**
   * Deletes a product.
   * @return {Promise<AxiosResponse<any>>}
   */
  async discontinue(productId) {
    return await apiService.patch(`${url}/${productId}/discontinue`);
  },

  async undiscontinue(productId) {
    return await apiService.patch(`${url}/${productId}/undiscontinue`);
  },
  localization: {
    async getForProduct(productId) {
      return await apiService.get(`${url}/${productId}/localization`);
    },

    async saveForProduct(productId, payload) {
      return await apiService.post(`${url}/${productId}/localization`, payload);
    },

    async translate(productId, sourceLanguage, targetLanguages, attributes) {
      return await apiService.post(`${url}/${productId}/localization/translation`, {
        sourceLanguage, targetLanguages, attributes
      });
    }
  },

  barcodes: {
    async getForProduct(productId) {
      return await apiService.get(`${url}/${productId}/barcodes`);
    },

    async getSingle(productId, barcodeId) {
      return await apiService.get(`${url}/${productId}/barcodes/${barcodeId}`);
    },

    async create(productId, payload) {
      return await apiService.post(`${url}/${productId}/barcodes`, payload);
    },

    async update(productId, barcodeId, payload) {
      return await apiService.patch(`${url}/${productId}/barcodes/${barcodeId}`, payload);
    },

    async delete(productId, barcodeId) {
      return await apiService.delete(`${url}/${productId}/barcodes/${barcodeId}`);
    },
  },

  stock: {
    async modifyStock(productId, payload) {
      return await apiService.post(`${url}/${productId}/modifyStock`,
        payload
      );
    },

    async updateRack(productId, rack) {
      return await apiService.patch(`${url}/${productId}/updateRack`,
        {
          rack
        }
      );
    },

    async getStockHistory(
      productId,
      perPage = 20,
      page = 1,
      sorts = [],
      filters = [],
      withCount = 0
    ) {
      return await apiService
        .setPagination(perPage, page, withCount)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/${productId}/stockHistory`);
    },

    async getMissingStockDetails(
      sorts = [],
      filters = [],
    ) {
      return await apiService
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/stockDetails`);
    },
  },

  tags: {
    async setLinkedTags(productId, tagIds = []) {
      return await apiService.post(`${url}/${productId}/tag`, {
        tagIds
      });
    },
    async getLinkedTags(productId) {
      return await apiService.get(`${url}/${productId}/tag`);
    }
  },

  comments: {
    async getPaginated(
      productId,
      perPage = 20,
      page = 1,
      sorts = [],
      filters = [],
      withTotals = 0
    ) {
      return await apiService
        .setPagination(perPage, page, withTotals)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/${productId}/comments`, {
          showLoadingIndicator: false
        });
    },

    async getSingle(productId, commentId) {
      return await apiService.get(`${url}/${productId}/comments/${commentId}`);
    },

    async create(productId, payload) {
      return await apiService.post(`${url}/${productId}/comments`, payload, {
        showLoadingIndicator: false
      });
    },

    async update(productId, commentId, payload) {
      return await apiService.patch(`${url}/${productId}/comments/${commentId}`, payload, {
        showLoadingIndicator: false
      });
    },

    async delete(productId, commentId) {
      return await apiService.delete(`${url}/${productId}/comments/${commentId}`, {
        showLoadingIndicator: false
      });
    },
  },

  catalogComparison: {
    /**
     * Fetches all products.
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getComparisonSupplierCatalog(
      perPage = 20,
      page = 1,
      sorts = [],
      filters = [],
      withCount = 0
    ) {

      return await apiService
        .setPagination(perPage, page, withCount)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/compare/supplierCatalog`);
    },

    async executeBatchAction(action, items, params) {
      return await apiService.patch(`${url}/batchAction`, {
        jobAction: action,
        items: items,
        params: params
      });
    }
  },


  priceLog: {
    async getPriceLog(productId) {
      return await apiService.get(`${url}/${productId}/priceLog`);
    }
  },

  async getInventoryByDepartment(departmentId) {
    return await apiService
      .addFilter('department', departmentId)
      .get(`${url}/inventory`);
  },

  inSupplierOrder: {
    async getProductIdByShelfBarcode(shelfBarcode) {
      return await apiService
        .get(`${url}/inSupplierOrder/byShelfBarcode?shelfBarcode=${shelfBarcode}`);
    },
    async getProductByBarcode(productBarcode, previouslyScannedProductId = null) {
      let link = `${url}/inSupplierOrder/byBarcode?barcode=${productBarcode}`;

      // If previouslyScannedProductId is not given, it will only return productId and ShelfNumber
      if (previouslyScannedProductId !== null) {
        link = `${link}&previouslyScannedProductId=${previouslyScannedProductId}`
      }

      return await apiService
        .get(link);
    }
  },

  async getDataByBarcode(barcode) {
    return await apiService.get(`${url}/dataByBarcode?barcode=${barcode}`)
  },

  async updateProductWeightVolumeByBarcode(barcode, weight, volume) {
    return await apiService.patch(`${url}/update-product-weight-volume`, {
      barcode,
      weight,
      volume
    });
  },

  async createDiscount(productId, discountValue, reservedStock, isShortDate) {
    return await apiService.post(`${url}/${productId}/createDiscount`, {
      value: discountValue,
      reservedStock: reservedStock,
      isShortDate: isShortDate
    });
  },


  async printShelfLabel(productId, size) {
    return await apiService.get(`${url}/${productId}/printShelfLabel?size=${size}`);
  }
}
