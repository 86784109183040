<template>
  <show-instructions-table
    :apply-once-supported="false"
    :endpoints="getEndpoints"
    :instructable-id="deliveryId"
    :instruction-types="['delivery']"
  />
</template>

<script>
import ShowInstructionsTable from "@/components/forms/instructions/ShowInstructionsTable";
import DeliveryRepository from "@/repositories/DeliveryRepository";

export default {
  name: "DeliveryInstructionManagement",
  components: {ShowInstructionsTable},
  props: {
    deliveryId: {
      type: Number,
      required: true
    }
  },
  computed: {
    getEndpoints() {
      return [
        {type: 'list', func: DeliveryRepository.instructions.getPaginated.bind(this)},
        {type: 'get', func: DeliveryRepository.instructions.getSingle.bind(this)},
        {type: 'create', func: DeliveryRepository.instructions.create.bind(this)},
        {type: 'update', func: DeliveryRepository.instructions.update.bind(this)},
        {type: 'delete', func: DeliveryRepository.instructions.delete.bind(this)},
      ];
    }
  },
}
</script>

