import ReportQueryRepository from "@/repositories/ReportQueryRepository";
import Vue from "vue";
import {exportResponse} from "@/helpers/exportHelper";

const getDefaultState = () => {
  return {
    availableQueries: [],

    selectedQueryId: null,
    selectedQueryData: null,

    selectedQueryExecutionResponse: null,
    selectedQueryExecutionMeta: {
      currentPage: 1,
      from: 1,
      to: 1,
      perPage: 20,
      lastPage: 1,
      total: null,
    },
    selectedQueryExecutionHasResultRows: null,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getAvailableQueries: state => state.availableQueries,

    getSelectedQueryId: state => state.selectedQueryId,
    getSelectedQueryData: state => state.selectedQueryData,

    getSelectionPlaceholders: state => state.selectedQueryData.placeHolders,

    hasSelectedAndLoadedQuery: state => state.selectedQueryData !== null,

    getPlaceHolderValue: (state) => (key) => {
      return state.selectedQueryData.placeHolders.find(ph => ph.placeholder === key).value;
    },

    getSelectedQueryExecutionResponse: state => state.selectedQueryExecutionResponse,
    getSelectedQueryExecutionMeta: state => state.selectedQueryExecutionMeta,
    getSelectedQueryExecutionHasResultRows: state => state.selectedQueryExecutionHasResultRows,


    getMetaPerPage: state => state.selectedQueryExecutionMeta.perPage,
    getMetaCurrentPage: state => state.selectedQueryExecutionMeta.currentPage,
  },
  mutations: {
    setAvailableQueries(state, queries) {
      state.availableQueries = queries;
    },
    setSelectedQueryId(state, queryId) {
      state.selectedQueryId = queryId;
      state.selectedQueryData = null;
    },
    setSelectedQueryData(state, queryData) {
      Vue.set(state, 'selectedQueryData', queryData);
    },

    resetSelection(state) {
      state.selectedQueryId = null;
      state.selectedQueryData = null;
      state.selectedQueryExecutionResponse = null;
      state.selectedQueryExecutionMeta = null;
      state.selectedQueryExecutionHasResultRows = null;
    },

    resetSelectionResults(state) {
      state.selectedQueryExecutionResponse = null;
      state.selectedQueryExecutionMeta = null;
      state.selectedQueryExecutionHasResultRows = null;
    },

    setPlaceHolderValue(state, {key, value}) {
      let placeholders = state.selectedQueryData.placeHolders.filter(ph => ph.placeholder === key);
      placeholders.forEach(placeholder => {
        placeholder.value = value;
      })
    },


    setSelectedQueryExecutionResponse(state, response) {
      state.selectedQueryExecutionResponse = response;
    },

    setSelectedQueryExecutionMeta(state, meta) {
      state.selectedQueryExecutionMeta = meta;
    },

    /**
     * True = has Result Rows
     * False = Has no Result Rows
     *
     * Null = No Result queries yet
     * @param state
     * @param bool
     */
    setSelectedQueryExecutionHasResultRows(state, bool) {
      state.selectedQueryExecutionHasResultRows = bool;
    },

    setMetaPerPage(state, perPage) {
      state.selectedQueryExecutionMeta.perPage = perPage;
    },
    setMetaCurrentPage(state, currentPage) {
      state.selectedQueryExecutionMeta.currentPage = currentPage;
    }
  },
  actions: {
    fetchAvailableQueries({commit}) {
      return ReportQueryRepository.loadAll().then((res) => {
        commit('setAvailableQueries', res.data.data);
      })
    },
    fetchQuery({commit, state}) {
      return ReportQueryRepository
        .getSingle(state.selectedQueryId)
        .then((res) => {
          let data = res.data.data;

          // Add value property to each placeholder.
          if (data.placeHolders) {
            data.placeHolders = data.placeHolders.map(ph => {
              return {
                ...ph,
                value: null
              };
            });
          }

          commit('setSelectedQueryData', data);
        })
    },
    executeSelectedQuery({commit, getters}, {perPage = 20, page = 1}) {
      commit('setSelectedQueryExecutionResponse', null);
      commit('setSelectedQueryExecutionHasResultRows', null);
      commit('setSelectedQueryExecutionMeta', null);


      let selectionPlaceholders = getters.getSelectionPlaceholders;
      let values = [];

      selectionPlaceholders.forEach(ph => {
        values.push({
          name: ph.placeholder,
          value: ph.value
        });
      });


      return ReportQueryRepository
        .executeQuery(
          getters.getSelectedQueryId,
          values,
          perPage,
          page
        )
        .then((res) => {
          if (res.data) {
            const responseRows = res.data?.data || null;

            commit('setSelectedQueryExecutionResponse', responseRows);
            commit('setSelectedQueryExecutionMeta', res.data.meta);
            commit('setSelectedQueryExecutionHasResultRows', responseRows !== null);
          }

        });
    },
    executeSelectedQueryAsync({commit, getters}, {fileFormat = 'xlsx', options = []}) {
      commit('setSelectedQueryExecutionResponse', null);
      commit('setSelectedQueryExecutionHasResultRows', null);
      commit('setSelectedQueryExecutionMeta', null);


      let selectionPlaceholders = getters.getSelectionPlaceholders;
      let values = [];

      selectionPlaceholders.forEach(ph => {
        values.push({
          name: ph.placeholder,
          value: ph.value
        });
      });

      return ReportQueryRepository
        .executeQueryAsync(
          getters.getSelectedQueryId,
          values,
          fileFormat,
          options
        );
    },
    executeSelectedQueryAndDownload({commit, getters}, {fileFormat = 'xlsx'}) {
      commit('setSelectedQueryExecutionResponse', null);
      commit('setSelectedQueryExecutionHasResultRows', null);
      commit('setSelectedQueryExecutionMeta', null);


      let selectionPlaceholders = getters.getSelectionPlaceholders;
      let values = [];

      selectionPlaceholders.forEach(ph => {
        values.push({
          name: ph.placeholder,
          value: ph.value
        });
      });

      return ReportQueryRepository
        .exportQuery(
          fileFormat,
          getters.getSelectedQueryId,
          values,
        ).then((res) => {
          exportResponse(res, `report`, 'pdf');
        });
    },


  },
}