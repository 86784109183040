const checkIfObjectsMatch = function (objectOne, objectTwo) {
  let matchingKeyValues = [];
  const keys = Object.keys(objectTwo);

  // Loop through each key in the existing object and check if both the key and the given value match
  // if they match, push it to an array of matching Key Value pairs
  keys.forEach(k => {
    if (objectOne[k] !== undefined && objectTwo[k] !== undefined) {
      if (objectTwo[k] === objectOne[k]) {
        matchingKeyValues.push(k);
      }
    }
  })

  // Check the length of matching key value pairs and compare against the Object length.
  // if the length matches, the object is contained in the array.
  return matchingKeyValues.length === keys.length;
}

export {checkIfObjectsMatch}