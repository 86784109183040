<template>
  <navbar-button-template
    @click="toggleOpenMenu"
  >
    <div v-if="hasUnreadNotifications" class="h-2 w-2 rounded-md absolute right-0 mr-1"
         style="background-color: #EB8234"></div>
    <fa-icon
      :class="{'animate-ring': hasUnreadNotifications}"
      :icon="['far', 'bell']"
      fixed-width
    />
  </navbar-button-template>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import NavbarButtonTemplate from "@/components/layouts/dashboard/partials/navbar/NavbarButtonTemplate.vue";

export default {
  name: "NavbarNotificationButton",
  components: {NavbarButtonTemplate},
  methods: {
    ...mapMutations('notifications', [
      'setMenuOpen'
    ]),
    toggleOpenMenu() {
      this.isMenuOpen
        ? this.setMenuOpen(false)
        : this.setMenuOpen(true);
    }
  },
  computed: {
    ...mapGetters('notifications', [
      'hasUnreadNotifications',
      'isMenuOpen'
    ])
  }
}
</script>

<style scoped>
@keyframes ring {
  0% {
    transform: rotate(0deg)
  }
  5% {
    transform: rotate(0deg)
  }
  15% {
    transform: rotate(0deg)
  }
  25% {
    transform: rotate(20deg)
  }
  35% {
    transform: rotate(-15deg)
  }
  45% {
    transform: rotate(10deg)
  }
  55% {
    transform: rotate(-5deg)
  }
  60% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(0deg)
  }
}

.animate-ring {
  animation: ring 1.6s linear infinite;
}
</style>