<template>
  <tr class="text-sm lg:text-xl border border-gray-200">
    <td class="text-right pr-3 lg:pr-6">{{ getFormattedDate(holiday.startDate) }}</td>
    <td class="text-left pl-3 lg:pl-6">{{ getFormattedDate(holiday.endDate) }}</td>
    <td class="text-right">{{ holiday.daysCount }}</td>
  </tr>
</template>
^
<script>
export default {
  name: "TouchInterfaceHolidaysSingleHoliday",
  props: {
    holiday: {
      type: Object
    },
  },
  methods: {
    isYearSameAsSelected(dayjsDate) {
      return dayjsDate.year() === this.$store.getters["touchInterface/myHolidays/getSelectedYear"];
    },
    getFormattedDate(date) {
      let dayJs = this.$date(date);

      return this.isYearSameAsSelected(dayJs)
        ? dayJs.format('DD MMM')
        : dayJs.format('DD MMM YYYY');
    }
  },
}
</script>