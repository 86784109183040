<template>
  <div>
    <touch-optimized-button
      :disabled="!canSelectGroup"
      disabled-error-message="Choisissez d'abord une date et un département."
      @click="openModal"
    >
            <span
              v-if="!loading"
              :class="{'font-bold': getSelectedGroup}"
            >
                {{ getButtonText }}
            </span>
      <fa-icon v-else class="animate-spin transition" icon="circle-notch"></fa-icon>
    </touch-optimized-button>

    <touch-optimized-modal ref="modal">
      <div class="flex justify-center">

        <touch-optimized-modal-button
          border-class="border-luxcaddy-300"
          class="mb-24 w-max float-right"
          text-class="text-luxcaddy-300"
          @click="selectGroup(null)"
        >
          Tous les groupes
        </touch-optimized-modal-button>
      </div>


      <div class="flex flex-wrap gap-8 clear-both">
        <touch-optimized-modal-button
          v-for="group in availableGroups"
          :key="group.id"
          @click="selectGroup(group)"
        >
                    <span class="text-3xl">
                        {{ group.number }}
                    </span>
        </touch-optimized-modal-button>
      </div>
    </touch-optimized-modal>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import {mapGetters, mapMutations} from "vuex";
import FulfilmentRepository from "@/repositories/FulfilmentRepository";

export default {
  name: "OrderFulfilmentListGroupButton",
  components: {TouchOptimizedModalButton, TouchOptimizedModal, TouchOptimizedButton},
  data: () => ({
    loading: false,
    availableGroups: []
  }),
  watch: {
    getSelectedDepartmentId(id) {
      if (id === null) {
        this.setSelectedGroup(null);
      }
    }
  },
  methods: {
    ...mapMutations('fulfilmentFilters', [
      'setSelectedGroup'
    ]),

    openModal() {
      this.loading = true;
      FulfilmentRepository
        .getFulfilmentGroupsByDateAndDepartment(
          this.getSelectedDepartmentId,
          this.getSelectedDay
        )
        .then((res) => {
          this.availableGroups = res.data.data.groups;
          this.$refs.modal.open();
        }).finally(() => this.loading = false);
    },

    selectGroup(group) {
      this.setSelectedGroup(group);
      this.$refs.modal.close();
    }
  },
  computed: {
    ...mapGetters('fulfilmentFilters', [
      'canSelectGroup',
      'getSelectedGroup',
      'getSelectedDay',
      'getSelectedDepartmentId',
    ]),
    getButtonText() {
      if (this.getSelectedGroup) {
        return `Groupe ${this.getSelectedGroup.number}`;
      }
      return "Groupe";
    }
  },
}
</script>

