const getDefaultState = () => {
  return {
    isLocked: false,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getIsLocked: state => state.isLocked,
  },
  mutations: {
    lockScreen(state) {
      state.isLocked = true;
      localStorage.setItem('lockScreen_locked', "true");
    },
    unlockScreen(state) {
      state.isLocked = false;
      localStorage.removeItem('lockScreen_locked');
    },
    syncFromLocalStorage(state) {
      if (localStorage.getItem('lockScreen_locked') !== null) {
        state.isLocked = true;
      } else {
        state.isLocked = false;
      }
    }
  },
}