<template>
  <div
    v-html="deliveryLabelHTMLCode"
  />
</template>

<script>
import DeliveryRepository from "@/repositories/DeliveryRepository";

export default {
  name: "DeliveryLabelHtml",
  props: {
    deliveryId: {
      type: Number,
      required: true
    }
  },
  watch: {
    deliveryId: function () {
      this.fetchLabelHTML();
    }
  },
  data: () => ({
    deliveryLabelHTMLCode: ''
  }),
  created() {
    this.fetchLabelHTML();
  },
  methods: {
    fetchLabelHTML() {
      DeliveryRepository
        .getDeliveryLabelHtml(this.deliveryId)
        .then(res => {
          this.deliveryLabelHTMLCode = res.data;
          this.$nextTick(() => {
            this.$emit('ready');
          })
        });
    }
  },
}
</script>