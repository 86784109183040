<template>
  <select-element
    :label="label"
    :multi="multi"
    :occupy-validation-space="occupyValidationSpace"
    :options="getCategories"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import CategoryRepository from "../../../../repositories/CategoryRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CategorySelectionInput",
  components: {SelectElement},
  props: {
    type: {
      type: String,
      required: true,
      validator: val => ["product"].includes(val)
    },
    value: {
      required: true
    },
    label: {
      default: 'Category',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    multi: {
      type: Boolean,
      required: true
    },
  },
  data: () => ({
    categories: []
  }),
  mounted() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories() {
      CategoryRepository.get(this.type).then((res) => {
        this.categories = res.data.data;
      })
    },
    flattenCategories(categories, parentPath = '') {
      const flatCategories = [];

      for (const category of categories) {
        const {subCategories, name} = category;
        const fullPath = parentPath ? parentPath + ' > ' + name : name;
        flatCategories.push({...category, name: fullPath});

        if (subCategories) {
          const subFlatCategories = this.flattenCategories(subCategories, fullPath);
          flatCategories.push(...subFlatCategories);
        }
      }

      return flatCategories;
    },
  },
  computed: {
    getReducedCategories() {
      return this.flattenCategories(this.categories);
    },
    getCategories() {
      return this.getReducedCategories.map(c => {
        const id = ('[#' + c.id + '] ');
        return {
          label: {
            partOne: id,
            partOneSize: 60,
            partTwo: c.name
          },
          value: c.id
        };
      });
    }
  }
}
</script>

