<template>
  <card size="small" title="Facture" type="primary">
    <template v-if="hasReceivedInvoice">
      <div class="flex justify-between gap-x-4">
        <div>
          <span class="font-bold">No. de la facture: </span>
          {{ getInvoiceNumber }}
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Card from "@/components/elements/cards/Card";

export default {
  name: "SupplierOrderInvoiceCard",
  components: {Card},
  data: () => ({
    invoiceNumber: null,
  }),
  props: {
    showCancelButton: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    if (this.hasReceivedInvoice) {
      this.invoiceNumber = this.getInvoiceNumber;
    }
  },
  computed: {
    ...mapGetters('supplierOrders', [
      'hasReceivedInvoice',
      'getInvoiceNumber',
      'getOrderId'
    ]),
  },
  methods: {
    ...mapMutations('supplierOrders', [
      'setInvoiceNumber'
    ])
  },
}
</script>

<style scoped>

</style>