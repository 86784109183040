import auth from "../middlewares/authMiddleware";
import ShowDevices from "@/components/pages/Devices/ShowDevices";
import CreateDevice from "@/components/pages/Devices/Create/CreateDevice";
import UpdateDevice from "@/components/pages/Devices/Update/UpdateDevice";

export default [
  {
    path: '/devices',
    name: 'devices.list',
    component: ShowDevices,
    meta: {
      middleware: [auth],
      title: 'Liste des appareils',
      permissions: ['DEVICES_READ']
    }
  },
  {
    path: '/devices/create',
    name: 'devices.create',
    component: CreateDevice,
    meta: {
      middleware: [auth],
      title: 'Créer un appareil',
      breadcrumbs: ['devices.list'],
      permissions: ['DEVICES_WRITE']
    }
  },
  {
    path: '/devices/:id',
    name: 'devices.edit',
    component: UpdateDevice,
    meta: {
      middleware: [auth],
      readOnly: {supported: true, title: 'Voir un appareil'},
      title: 'Modifier appareil',
      breadcrumbs: ['devices.list'],
      permissions: ['DEVICES_WRITE']
    }
  },
];