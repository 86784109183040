<template>
  <div class="box-border overflow-x-auto overflow-hidden relative">
    <div v-dragscroll.x class="whitespace-nowrap overflow-x-auto overflow-y-hidden pb-6 max-w-full">
      <div class="cursor-grab">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {dragscroll} from 'vue-dragscroll'


export default {
  name: "HorizontalScroll",
  directives: {
    dragscroll
  },
}
</script>

