<template>
  <div class="flex flex-col flex-wrap gap-y-4 place-items-center px-2 py-4">
    <div class="flex flex-col space-y-1 overflow-ellipsis text-center">
      <span class="text-gray-300 overflow-ellipsis">{{ getUsername }}</span>
    </div>

    <navbar-live-clock/>

    <div class="mt-2 h-0.5 w-full bg-gray-700 opacity-50 rounded-xl"></div>
  </div>
</template>

<script>
import NavbarLiveClock from "@/components/layouts/dashboard/partials/navbar/NavbarLiveClock";

export default {
  name: "SidebarUserDisplay",
  components: {NavbarLiveClock},
  computed: {
    getUsername() {
      return this.$store.getters["authUser/getFullName"];
    },
  }
}
</script>

