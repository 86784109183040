import apiService from './Base/BaseRepository';

export default {
  getUrlPathByImageAbleType(imageAbleType) {
    switch (imageAbleType) {
      case "product":
        return '/products';
      case "tag":
        return '/tags';
      case "category":
        return '/categories';
      case "blogPost":
        return '/blog/posts';
      case "banner":
        return '/banners';
    }
  },

  /**
   * Uploads multiple images along with Label & localization.
   * Format complies to the v-model output emitted by the FileInputElement Component.
   *
   * @param imageAbleType -> The model that has media (eg: product, tag)
   * @param imageAbleId -> The ID of the model that has media
   * @param fileList
   * @return {Promise<AxiosResponse<any>>}
   */
  async uploadImages(imageAbleType, imageAbleId, fileList) {
    let formData = new FormData();
    formData.append("fileType", "image");

    for (const [index, file] of fileList.entries()) {
      formData.append(`file[${index}]`, file.file);
      formData.append(`fileMeta[${index}][label]`, file.label);
      formData.append(`fileMeta[${index}][localization]`, file.localization);
    }

    let url = this.getUrlPathByImageAbleType(imageAbleType);

    return await apiService.post(`${url}/${imageAbleId}/media/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  /**
   * Returns a list of media.
   *
   * @param imageAbleType
   * @param imageAbleId
   * @return {Promise<AxiosResponse<any>>}
   */
  async getMedia(imageAbleType, imageAbleId) {
    let url = this.getUrlPathByImageAbleType(imageAbleType);
    return await apiService.get(`${url}/${imageAbleId}/media`);
  },


  /**
   * Deletes a list of media.
   *
   * @param mediaIds
   * @return {Promise<AxiosResponse<any>|boolean>}
   */
  async deleteMedia(imageAbleType, imageAbleId, mediaIds = []) {
    if (!mediaIds.length) {
      return false;
    }

    let url = this.getUrlPathByImageAbleType(imageAbleType);

    return await apiService.delete(`${url}/${imageAbleId}/media`, {
      data: {
        mediaIds: mediaIds
      }
    });
  },
}