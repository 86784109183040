<template>
  <div>
    <import-bank-movements
      v-if="showImportCard"
      class="mb-6"
      @change="onFileUploadDone"
    />
    <list-table
      ref="table"
      :batch-actions="batchActions"
      :columns="columns"
      :extra-row-settings="getExtraRowSettings"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :select-values="selectValues"
      :title="getTableTitle"
      create-event
      enable-extra-row
      enable-totals
      identifier="bankMovementsTable"
      selectable
      sub-key="items"
      @create="showImportCard = !showImportCard"
      @loaded="onDataLoaded"
    >

      <template v-slot:treated-status-template="{ row }">
        <bank-movement-treated-status
          :status="row.treatedStatus"
          small
        />
      </template>

      <template v-slot:extra-row-template="{ row }">
        <div
          v-if="row.isTreated"
          class="p-2">
          Déjà traité.
        </div>

        <template v-else>
          <bank-movement-suggestion-list
            :bank-movement-id="row.id"
            with-filters
            @linked="onSuggestionLinked('table', row.id)"
          />
        </template>
      </template>

    </list-table>

    <modal
      ref="suggestionsModal"
      title="Suggestions"
      @close="selectedBankMovementId = null"
      @open="setOpenTab('filteredSuggestionTab')"
    >
      <card
        ref="invoiceSuggestionCard"
      >
        <card-tab
          id="filteredSuggestionTab"
          key="1"
          ref="filteredSuggestionTab"
          title="Avec Filtres"
          @open="setOpenTab('filteredSuggestionTab')"
        >
          <bank-movement-suggestion-list
            v-if="selectedBankMovementId"
            :bank-movement-id="selectedBankMovementId"
            with-filters
            @linked="onSuggestionLinked('modal')"
          />
        </card-tab>
        <card-tab
          id="unfilteredSuggestionTab"
          key="2"
          ref="unfilteredSuggestionTab"
          title="Sans Filtres"
          @open="setOpenTab('unfilteredSuggestionTab')"
        >
          <bank-movement-suggestion-list
            v-if="selectedBankMovementId"
            :bank-movement-id="selectedBankMovementId"
            @linked="onSuggestionLinked('modal')"
          />
        </card-tab>
      </card>
    </modal>
  </div>
</template>

<script>
import ListTable from "../../../listTable/ListTable";
import AccountingBankMovementRepository from "../../../../repositories/AccountingBankMovementRepository";
import {
  FILTER_BOOKING_ACCOUNT,
  FILTER_CURRENCY,
  FILTER_DATETIME_RANGE,
  FILTER_SELECT_MULTI,
  FILTER_SELECT_SINGLE,
} from "../../../listTable/includes/controls/filters/availableFilters";
import ImportBankMovements from "./Import/ImportBankMovements";
import {centsToEuroString, centsToMoneyString} from "@/helpers/mathHelper";
import Modal from "@/components/elements/modals/Modal";
import BankMovementSuggestionList
  from "@/components/pages/Accounting/BankMovements/Edit/Sub/LinkPaymentTransactions/Sub/LinkSuggestions/BankMovementSuggestionList";
import BankMovementTreatedStatus from "@/components/pages/Accounting/BankMovements/Edit/Sub/BankMovementTreatedStatus";
import CardTab from "@/components/elements/cards/tabs/CardTab.vue";
import Card from "@/components/elements/cards/Card.vue";

export default {
  name: "ShowBankMovements",
  components: {
    Card,
    CardTab,
    BankMovementTreatedStatus, BankMovementSuggestionList, Modal, ImportBankMovements, ListTable
  },
  watch: {
    '$route': function () {
      this.$refs.table.getData();
    }
  },
  data() {
    return {
      currentBalance: null,
      selectedBankMovementId: null,

      showTreatedStatuses: true,

      showImportCard: false,
      title: "Bank Movements",
      repositoryFunc: (...args) => {
        if (!this.getBankAccountNumber) {
          return AccountingBankMovementRepository.getPaginated(...args);
        }
        return AccountingBankMovementRepository.getPaginatedForBankAccount(this.getBankAccountNumber, ...args);
      },

      batchActions: [
        {
          label: "Traiter avec booking direct",
          additionalFields: [
            {
              label: "TVA",
              type: "vat",
              param: "vatRate",
              defaultValue: 0,
            },
            {
              label: "Code TVA",
              type: "vatCode",
              param: "vatCode",
            },
            {
              label: "Compte",
              type: "bookingAccount",
              param: "bookingAccountNumber",
            },
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalParams) => {
            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalParams
            ).finally(() => callBack());
          }
        },
        {
          label: "Assurance véhicule",
          additionalFields: [
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalFields) => {
            additionalFields.bookingAccountNumber = "61412000";
            additionalFields.vatCode = "NSGSD";
            additionalFields.vatRate = 0;

            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalFields
            ).finally(() => callBack());
          }
        },
        {
          label: "Taxe véhicule",
          additionalFields: [
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalFields) => {
            additionalFields.bookingAccountNumber = "64660000";
            additionalFields.vatCode = "NSTF";
            additionalFields.vatRate = 0;

            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalFields
            ).finally(() => callBack());
          }
        },
        {
          label: "Leasing véhicule",
          additionalFields: [
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalFields) => {
            additionalFields.bookingAccountNumber = "61153000";
            additionalFields.vatCode = "NSGSD";
            additionalFields.vatRate = 17;

            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalFields
            ).finally(() => callBack());
          }
        },
        {
          label: "Loyer",
          additionalFields: [
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalFields) => {
            additionalFields.bookingAccountNumber = "61112000";
            additionalFields.vatCode = "NSGSD";
            additionalFields.vatRate = 17;

            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalFields
            ).finally(() => callBack());
          }
        },
        {
          label: "Salaires",
          additionalFields: [
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalFields) => {
            additionalFields.bookingAccountNumber = "47141000";
            additionalFields.vatCode = "NSTF";
            additionalFields.vatRate = 0;

            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalFields
            ).finally(() => callBack());
          }
        },
        {
          label: "Worldline",
          additionalFields: [
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalFields) => {
            additionalFields.bookingAccountNumber = "51710000";
            additionalFields.vatCode = "NSTF";
            additionalFields.vatRate = 0;

            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalFields
            ).finally(() => callBack());
          }
        },
        {
          label: "CCSS",
          additionalFields: [
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalFields) => {
            additionalFields.bookingAccountNumber = "46210000";
            additionalFields.vatCode = "NSTF";
            additionalFields.vatRate = 0;

            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalFields
            ).finally(() => callBack());
          }
        },
        {
          label: "Frais de compte",
          additionalFields: [
            {
              label: "Date comptable",
              type: "date",
              param: "accountingDate",
              props: {
                'only-date': true,
              }
            },
          ],
          action: (callBack, selectedRows, additionalFields) => {
            additionalFields.bookingAccountNumber = "61333000";
            additionalFields.vatCode = "NSGSD";
            additionalFields.vatRate = 0;

            return AccountingBankMovementRepository.batchActions.executeBatchAction(
              "update",
              selectedRows,
              additionalFields
            ).finally(() => callBack());
          }
        },

      ],

      selectValues: bankMovement => {
        return {
          id: bankMovement.id
        };
      },

      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              icon: 'link',
              hint: 'Voir suggestions pour créer lien',
              disabledIf: (bankMovement) => bankMovement.isTreated,
              onClick: (bankMovement) => {
                this.selectedBankMovementId = bankMovement.id;
                this.$nextTick(() => {
                  this.$refs.suggestionsModal.openModal();
                });
              },
            },
            {
              type: 'edit',
              hint: 'Modifier mouvement bancaire',
              route: (row) => {
                return {
                  name: 'accounting.bankMovements.edit',
                  params: {id: row.id},
                };
              },
            },
          ]
        },
        {
          caption: "ID",
          dataField: 'id',
        },
        {
          caption: 'Date',
          calculateCellValue: (r) => this.$date(r.date).format('DD.MM.YY'),
          sort: {
            dataField: 'date',
            default: 'desc'
          }
        },
        {
          caption: 'Réference',
          dataField: 'reference',
          sort: {
            dataField: 'reference'
          },
          visible: false,
        },
        {
          caption: 'Compte',
          dataField: 'account',
          sort: {
            dataField: 'account'
          },
          calculateCellValue: (row) => {
            if (!row.account) {
              return '/';
            }

            return '...' + row.account.slice(-5);
          }
        },
        {
          caption: 'Type',
          sort: {
            dataField: 'type'
          },
          calculateCellValue: (row) => {
            if (!row.type) {
              return '/';
            }
            return row.type.substring(0, 2);
          }
        },
        {
          caption: 'Montant',
          dataType: 'numeric',
          calculateCellValue: (row) => {
            return centsToMoneyString(row.amount, row.currency);
          },
          additionalClasses: (row) => {
            return row.amount < 0 ? 'text-red-500' : '';
          },
          sort: {
            dataField: 'amount'
          },
          totals: {
            sum: 'amount',
            formatValue: (val) => centsToEuroString(val)
          }
        },
        {
          caption: 'Frais',
          calculateCellValue: (row) => {
            return centsToMoneyString(row.fees.amount, row.fees.currency);
          },
          additionalClasses: (row) => {
            return row.fees.amount < 0 ? 'text-red-500 font-semibold' : 'text-luxcaddy-600';
          },
          sort: {
            dataField: 'fees'
          },
          visible: false,
          totals: {
            sum: 'fees.amount',
            formatValue: (val) => centsToEuroString(val)
          }
        },
        {
          caption: 'De',
          dataField: 'from',
          sort: {
            dataField: 'from'
          }
        },
        {
          caption: 'Description',
          dataField: 'description',
          sort: {
            dataField: 'description'
          },
          canBreakLine: true,
        },
        {
          caption: "Traité Status",
          cellTemplate: "treated-status-template"
        },
        {
          caption: 'Booking Account',
          dataField: 'bookingAccount',
          sort: {
            dataField: 'bookingAccount'
          },
          visible: false,
        },
        {
          caption: 'Taux de TVA',
          dataField: 'vatRate',
          sort: {
            dataField: 'vatRate'
          },
          visible: false,
        },
        {
          caption: 'Code TVA',
          dataField: 'vatCode',
          sort: {
            dataField: 'vatCode'
          },
          visible: false
        },
        {
          caption: 'Crée le',
          calculateCellValue: (r) => this.$date(r.createdAt).format('DD.MM.YY HH:mm'),
          sort: {
            dataField: 'createdAt',
          },
          visible: false
        },
        {
          caption: 'Date comptable',
          calculateCellValue: (r) => this.$date(r.accountingDate).format('DD.MM.YY'),
          visible: false
        },
        {
          caption: 'Statement Number',
          dataField: 'statementNumber',
          sort: {
            dataField: 'statementNumber',
            default: 'desc'
          },
          visible: false
        },
        {
          caption: 'Solde de clôture annuel',
          dataField: 'closingBalanceYear',
          visible: false
        },
        {
          caption: 'Montant non attribué',
          dataField: 'unattributedAmount',
          dataType: 'numeric',
          calculateCellValue: (row) => {
            return centsToMoneyString(row.unattributedAmount, row.currency);
          },
          additionalClasses: (row) => {
            return row.unattributedAmount < 0 ? 'text-red-500' : '';
          },
          visible: false
        }
      ],
      filters: [
        {
          caption: "#",
          filterKey: "id",
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Reference",
          filterKey: "reference",
          small: true,
        },
        {
          caption: "Type",
          filterKey: "type",
        },
        {
          caption: "De",
          filterKey: "from",
          forceIsVisible: true
        },
        {
          caption: "Description",
          filterKey: "description",
          forceIsVisible: true
        },
        {
          caption: 'Type montant',
          filterKey: 'amountType',
          type: FILTER_SELECT_SINGLE,
          defaultValue: null,
          filterOptions: [
            {label: 'Tous', value: null},
            {label: 'Debit', value: "debit"},
            {label: 'Credit', value: "credit"},
          ],
        },
        {
          caption: "Booking account",
          filterKey: "bookingAccount",
          type: FILTER_BOOKING_ACCOUNT,
        },
        {
          filterKey: 'amount',
          inputs: [
            {
              caption: 'Montant de',
              inputKey: 'amount_from',
              type: FILTER_CURRENCY
            },
            {
              caption: 'à',
              inputKey: 'amount_to',
              type: FILTER_CURRENCY
            }
          ]
        },
        {
          caption: 'Statut traité',
          filterKey: 'treatedStatus',
          filterOptions: this.$luxcaddyConfig('bankMovement.availableStatuses'),
          type: FILTER_SELECT_MULTI,
          forceIsVisible: true,
          small: true
        },
        {
          filterKey: 'date',
          caption: "Date",
          type: FILTER_DATETIME_RANGE
        },
        {
          filterKey: 'accountingDate',
          caption: "Date comptable",
          type: FILTER_DATETIME_RANGE
        },
        {
          filterKey: 'createdAt',
          caption: "Date creation",
          type: FILTER_DATETIME_RANGE
        },
        {
          caption: 'Statement Number',
          filterKey: 'statementNumber',
          forceIsVisible: true,
          small: true
        },
        {
          caption: 'Solde de clôture annuel',
          filterKey: 'closingBalanceYear',
        },
      ]
    }
  },
  methods: {
    setOpenTab(tab) {
      this.$refs.invoiceSuggestionCard.setActiveTab(this.$refs[tab]);
    },
    onFileUploadDone() {
      this.$refs.table.refresh().then(() => {
        this.showImportCard = false;
      });
    },
    onDataLoaded(data) {
      this.currentBalance = data.balance;
    },
    onSuggestionLinked(throughType, bankMovementId = null) {
      if (throughType === "modal") {
        this.selectedBankMovementId = null;
        this.$refs.suggestionsModal.closeModal();
        this.$refs.table.refresh();
        return;
      }


      // update the treated status of the bank movement locally, so we can show it as treated without a reload.
      if (throughType === 'table' && bankMovementId !== null) {
        this.$refs.table.updateTableRowValue('id', bankMovementId, 'isTreated', true);
        this.$refs.table.updateTableRowValue('id', bankMovementId, 'treatedStatus', "treated");
      }
    }
  },
  computed: {
    getBankAccountNumber() {
      return this.$route.params?.bankAccount || null;
    },
    getTableTitle() {
      let title = 'Mouvements bancaires';

      if (this.getBankAccountNumber) {
        title = title + ' - ' + this.getBankAccountNumber;
      }

      if (this.currentBalance) {
        title = title + ` (${centsToEuroString(this.currentBalance)})`;
      }

      return title;
    },
    getExtraRowSettings() {
      return {
        showOnlyForSelectionAfterButtonClick: true,
        button: {
          text: 'Traiter sélection',
          icon: 'link'
        }
      };
    }
  }
}
</script>
