<template>
  <create-or-update-brand-form mode="create"></create-or-update-brand-form>
</template>

<script>
import CreateOrUpdateBrandForm from "../Forms/CreateOrUpdateBrandForm";

export default {
  name: "CreateBrand",
  components: {CreateOrUpdateBrandForm},
}
</script>

