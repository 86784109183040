import auth from "../middlewares/authMiddleware";
import ShowShortUrls from "../../components/pages/ShortUrl/ShowShortUrls";
import CreateShortUrls from "../../components/pages/ShortUrl/Create/CreateShortUrl";
import EditShortUrl from "../../components/pages/ShortUrl/Edit/EditShortUrl";


export default [
  {
    path: '/short-url',
    name: 'shortUrls.list',
    component: ShowShortUrls,
    meta: {
      middleware: [auth],
      title: 'Liste des ShortUrl',
      permissions: ['SHORT_URL_READ']
    }
  },
  {
    path: '/short-url/:slug/edit',
    name: 'shortUrls.edit',
    component: EditShortUrl,
    meta: {
      middleware: [auth],
      title: 'Modifier shortUrl',
      readOnly: {supported: true, title: 'Voir shorturl'},
      breadcrumbs: ['shortUrls.list'],
      permissions: ['SHORT_URL_WRITE']
    }
  },
  {
    path: '/short-url',
    name: 'shortUrls.create',
    component: CreateShortUrls,
    meta: {
      middleware: [auth],
      title: 'Créer un shortUrl',
      breadcrumbs: ['shortUrls.list'],
      permissions: ['SHORT_URL_WRITE']
    }
  },
];