<template>
  <div
    :style="'border-color: ' + getRoute.hexColor"
    class="bg-white flex flex-col border-t-4"
  >
    <div class="w-full bg-white | p-1">
      <route-manager-route-block-actions
        :color="getRoute.hexColor"
        :route-id="routeId"/>
    </div>

    <div :style="`background-color: ${getRoute.hexColor}`" class="flex items-center justify-between gap-x-1 p-1">
      <route-manager-departure-time :color="getRoute.hexColor" :route-id="routeId" class="w-20"/>

      <select-element
        v-model="deliveryEmployeeId"
        :allow-unselecting="false"
        :occupy-validation-space="false"
        :options="getEmployeesForSelect"
        class="w-40"
      />
    </div>


    <div
      :class="{'opacity-25': allDeliveriesHidden && hasAnyDeliveries}"
      class="flex flex-col relative">

      <div
        v-if="getRoute.lastDriverPosition !== null"
        class="w-full text-center font-bold text-sm py-0.5 uppercase px-2 border-b border-gray-300"
      >

        <route-delivery-progress
          :color="getSelectElementBgHexColor"
          :delivered-deliveries="getDeliveredDeliveriesCount"
          :device="getRoute.device"
          :route-id="getRoute.id"
          :show-jump-to-position-button="getShowDriverPositions"
          :total-deliveries="getTotalDeliveriesCount"
        />
      </div>

      <transition name="fade-up-down">
        <div v-if="isExpanded">
          <draggable v-model="getSteps" draggable=".stepWithDelivery" ghost-class="opacity-40"
                     handle=".handle"
                     @input="onDragEnd">
            <route-manager-route-block-delivery-step
              v-for="(step, i) in getSteps"
              :key="i"
              :is-arrival="(i+1) === getSteps.length"
              :is-departure="i === 0"
              :number="i"
              :number-color="getRoute.hexColor"
              :route-id="getRoute.id"
              :step="step"
            />
          </draggable>
        </div>
      </transition>

      <div
        :class="{'border-t border-gray-300': !isExpanded}"
        :style="`color: ${getRoute.hexColor}`"
        class="gap-x-2 p-2 text-xs cursor-pointer hover:bg-gray-50 text-right flex justify-between font-bold"
        @click="expandOrCollapseRoute"
      >
        <div class="flex justify-between gap-x-4 w-full">
          <div class="flex gap-x-4 font-bold">
            <span>{{ getRoute.totalDuration }}<sup>min</sup></span>
            <span>{{ getRoute.totalDistance }}<sup>km</sup></span>
            <span>{{ getRoute.totalBoxQuantity }}/{{ getRoute.totalEstimatedBoxQuantity }}</span>
          </div>
          <transition name="fade">
            <div
              v-if="isTrackingForRouteEnabled"
              class="bg-red-600 px-1 rounded-md text-white"
            >
              <fa-icon class="animate-pulse" fixed-width icon="satellite-dish"/>
            </div>
          </transition>
        </div>
        <fa-icon
          :class="[isExpanded ? 'rotate-180' : '']"
          class="transform transition duration-200 text-gray-500 text-lg"
          icon="chevron-down"
        />
      </div>
    </div>


  </div>
</template>

<script>
import RouteManagerRouteBlockDeliveryStep
  from "@/components/pages/RouteManager/Includes/Sidebar/Includes/RouteManagerRouteBlock/Includes/RouteManagerRouteBlockDeliveryStep";
import {mapActions, mapGetters, mapMutations} from "vuex";
import RouteManagerRouteBlockActions
  from "@/components/pages/RouteManager/Includes/Sidebar/Includes/RouteManagerRouteBlock/Includes/RouteManagerRouteBlockActions";
import draggable from "vuedraggable";
import {shadeColor} from "@/helpers/routeManagerHelper";
import RouteManagerDepartureTime
  from "@/components/pages/RouteManager/Includes/Sidebar/Includes/RouteManagerRouteBlock/Includes/RouteManagerDepartureTime";
import RouteDeliveryProgress from "@/components/pages/RouteManager/Includes/Progress/RouteDeliveryProgress";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "RouteManagerRouteBlock",
  components: {
    SelectElement,
    RouteDeliveryProgress,
    RouteManagerDepartureTime,
    RouteManagerRouteBlockActions,
    RouteManagerRouteBlockDeliveryStep,
    draggable
  },
  props: {
    routeId: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    deliveryMan: '',
  }),
  methods: {
    ...mapActions('routeManager', [
      'updateRoute',
      'setDeliveries'
    ]),
    ...mapMutations('routeManager', [
      'setRouteSteps',
      'setClickedDriverRouteId'
    ]),
    expandOrCollapseRoute() {
      this.$store.commit('routeManager/expandOrCollapseRoute', this.routeId);
    },
    onDragEnd(steps) {
      let deliveriesSorted = steps.filter(s => s.delivery !== undefined).flatMap(s => s.delivery.id);

      this.setDeliveries({
        routeId: this.getRoute.id,
        deliveryIds: deliveriesSorted
      });
    },
    setClickedDriverRouteId(routeId) {
      this.setClickedDriverRouteId(routeId);
    }
  },
  computed: {
    ...mapGetters('routeManager', [
      'getEmployeesForSelect',
      'getShowDriverPositions',
      'getTrackedRouteId'
    ]),
    getRoute() {
      return this.$store.getters['routeManager/getRouteById'](this.routeId);
    },
    getSteps: {
      get() {
        return this.getRoute.steps;
      },
      set(steps) {
        this.setRouteSteps({
          routeId: this.getRoute.id,
          steps
        })
      }
    },
    getTotalDeliveriesCount() {
      return this.getSteps.filter(s => s.delivery).length;
    },
    getDeliveredDeliveriesCount() {
      return this.getSteps.filter(s => s.delivery && s.delivery.deliveredAt).length;
    },
    hasAnyDeliveries() {
      return this.getSteps.filter(s => s.delivery !== undefined).length > 0;
    },
    isExpanded() {
      return this.$store.getters['routeManager/isRouteExpanded'](this.routeId);
    },
    allDeliveriesHidden() {
      return this.$store.getters["routeManager/areAllDeliveriesOfRouteHidden"](this.routeId);
    },
    getSelectElementBgHexColor() {
      return shadeColor(this.getRoute.hexColor, 15);
    },
    deliveryEmployeeId: {
      get() {
        return this.getRoute.driverMemberId;
      },
      set(driverId) {
        this.getRoute.driverMemberId = driverId;
        this.updateRoute({
          routeId: this.routeId,
          driverId: driverId,
          departure: this.getRoute.departure
        })
      }
    },

    isTrackingForRouteEnabled() {
      return this.getTrackedRouteId === this.getRoute.id;
    }
  },
}
</script>

