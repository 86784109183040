<template>
  <modal
    ref="addPaymentModal"
    :show-close-button="true"
    title="Ajouter un paiement"
    @close="resetForm"
  >
    <div style="width: 800px;">
      <form-wrapper
        ref="form"
        :show-changed="true"
        submit-button-label="Ajouter"
        @submit="addPayment"
      >
        <div class=" grid grid-cols-1 lg:grid-cols-2 gap-x-2">
          <payment-provide-select-input v-model="provider" manual-only validation-rules="required"/>

          <currency-input-element
            v-model.number="amount"
            allow-negative
            label="Montant"
            validation-rules="required"
          />
        </div>
      </form-wrapper>
    </div>

  </modal>

</template>

<script>

import Modal from "@/components/elements/modals/Modal.vue";
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import {mapGetters} from "vuex";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import PaymentProvideSelectInput from "@/components/elements/forms/elements/PaymentProviderSelectInput.vue";
import CurrencyInputElement from "@/components/elements/forms/elements/CurrencyInputElement.vue";

export default {
  name: "PaymentAddForm",
  components: {CurrencyInputElement, PaymentProvideSelectInput, FormWrapper, Modal},
  data: () => ({
    provider: null,
    amount: null
  }),
  computed: {
    ...mapGetters('invoices', [
      'getInvoiceId',
    ])
  },
  methods: {
    openModal() {
      this.$nextTick(() => {
        this.$refs.addPaymentModal.openModal();
        this.$nextTick(() => {
          this.$refs.form.focusFirstElement();
        })
        this.resetForm();
      });
    },
    closeModal() {
      this.resetForm();
      this.$refs.addPaymentModal.closeModal();
    },
    resetForm() {
      this.provider = null;
      this.amount = null;
      this.$nextTick(() => {
        if (this.$refs.form !== undefined) {
          this.$refs.form.resetForm();
        }
      });
    },
    addPayment(callback) {
      InvoiceRepository.payments.addPayment(this.getInvoiceId, {
        provider: this.provider,
        amount: this.amount
      }).then(() => {
        this.closeModal();
        this.$sendSuccessToast("Paymen ajoutée.");
        this.$emit('change');
        this.resetForm();
      }).finally(() => callback());
    }
  }
}
</script>

<style scoped>

</style>

