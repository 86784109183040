<template>
  <select-element
    :enable-search="false"
    :label="label"
    :options="availableSizes"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "LabelSizeSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Size',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      availableSizes: this.$luxcaddyConfig('product.labels.shelf.size'),
    }
  }
}
</script>

