import apiService from './Base/BaseRepository';

const url = '/orders/unfulfilledItems';

export default {
  async get(sorts = [], filters = []) {
    return await apiService
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getExport(sorts = [], filters = []) {
    return await apiService
      .setSorts(sorts)
      .setFilters(filters)
      .downloadBlob(`${url}/export`);
  },
}