<template>
    <span
      :class="isTypeImage ? 'bg-red-400' : 'bg-gray-700'"
      class="text-white px-2 py-1 rounded text-xs tracking-wider"
    >
        <fa-icon
          v-if="getIconByType"
          :icon="getIconByType"
          class="mr-2"
          fixed-width
        />
        {{ getLabelByType }}
    </span>
</template>

<script>
export default {
  name: "BannerTypeLabel",
  props: {
    type: {
      type: String,
      validator: val => ["image", "text"].includes(val)
    }
  },
  computed: {
    getLabelByType() {
      switch (this.type) {
        case "image":
          return "Image";
        case "text":
          return "Texte";
        default:
          return this.type;
      }
    },
    getIconByType() {
      switch (this.type) {
        case "image":
          return "image";
        case "text":
          return "quote-right";
        default:
          return null;
      }
    },
    isTypeImage() {
      return this.type === "image";
    }
  },
}
</script>

<style scoped>

</style>