import apiService from './Base/BaseRepository';

const url = '/time-clock-entries';

export default {
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getTotals(sorts = [], filters = []) {
    return await apiService
      .setSorts(sorts)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/totals`);
  },

  async getSingle(timeClockEntryId) {
    return await apiService.get(`${url}/${timeClockEntryId}`)
  },

  async update(timeClockEntryId, values) {
    return await apiService.patch(`${url}/${timeClockEntryId}`, values)
  },

  async create(values) {
    return await apiService.post(`${url}/`, values)
  },

  async deleteSingle(timeClockEntryId) {
    return await apiService.delete(`${url}/${timeClockEntryId}`)
  },


  personal: {
    async timeClockByLoginToken(loginToken) {
      return await apiService.post(`/timeclock`, {
        token: loginToken
      });
    }
  }
}
