<template>
  <modal ref="warningModal" :blocking="true" title="Attention" type="warning" @close="clearWarnings">
    <ul>
      <li v-for="warning in getWarnings" :key="warning.key" v-html="warning.msg"></li>
    </ul>

    <template #footer>
      <div class="flex justify-end gap-4">
        <button-element type="warning" @click="closeModal">
          <fa-icon class="mr-2" fixed-width icon="check"></fa-icon>
          J'ai compris
        </button-element>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../elements/modals/Modal";
import ButtonElement from "../../../elements/buttons/ButtonElement";

export default {
  name: "DashboardWarningModal",
  components: {ButtonElement, Modal},
  watch: {
    hasWarnings(bool) {
      if (bool) {
        this.$refs.warningModal.openModal();
      }
    }
  },
  methods: {
    clearWarnings() {
      let vm = this;
      setTimeout(function () {
        vm.$store.commit('userInterface/setWarningNotifications', []);
      }, 300);
    },
    closeModal() {
      this.$refs.warningModal.closeModal();
      this.clearWarnings();
    }
  },
  computed: {
    getWarnings() {
      return this.$store.getters['userInterface/getWarnings'];
    },
    hasWarnings() {
      return this.getWarnings.length > 0;
    }
  },
}
</script>

