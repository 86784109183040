<template>
  <modal ref="modal" disable-padding show-close-button title="Référencer Article" @close="onModalClose">
    <card :navigation-through-query-params="false" :show-add-hidden-tabs-button="false" size="small" type="light">
      <card-tab id="substituteWithProduct" key="1" ref="productTab" title="Produit">
        <add-product-to-cart-or-order
          :quantity-to-add="quantityToAdd"
          context="order"
          @added="onAdded"></add-product-to-cart-or-order>
      </card-tab>

      <card-tab id="addCustomProductTab" key="2" ref="addCustomProductTab" title="Libellé libre">
        <add-custom-product-to-order @added="onAdded"></add-custom-product-to-order>
      </card-tab>

      <card-tab id="addFeeTab" key="3" ref="addFeeTab" :is-hidden="getSubstituteReferencedItem === true"
                title="Frais">
        <add-fee-to-order @added="onAdded"></add-fee-to-order>
      </card-tab>
    </card>
  </modal>
</template>

<script>
import Modal from "../../../../elements/modals/Modal";
import Card from "../../../../elements/cards/Card";
import CardTab from "../../../../elements/cards/tabs/CardTab";
import AddProductToCartOrOrder from "../AddToOrder/AddProductToCartOrOrder";
import AddCustomProductToOrder from "../AddToOrder/AddCustomProductToOrder";
import AddFeeToOrder from "../AddToOrder/AddFeeToOrder";

export default {
  name: "OrderItemAddReferenceModal",
  components: {AddFeeToOrder, AddCustomProductToOrder, AddProductToCartOrOrder, CardTab, Card, Modal},
  props: {
    quantityToAdd: {
      type: Number,
      default: 1
    },
    // The ID of the product that will be substituted
    referencedId: {type: Number, required: true},
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    onModalClose() {
      this.$store.commit('order/setReferencedItemId', {
        id: null,
        substituteReferencedItem: false,
        referencedItemQuantity: 0
      });
    },
    /**
     * Close the modal when any custom product / fee / product has been added.
     */
    onAdded() {
      this.closeModal();
    }
  },
  computed: {
    getSubstituteReferencedItem() {
      return this.$store.getters['order/getSubstituteReferencedItem'];
    }
  },
}
</script>

