<template>
  <div>
    <div v-if="loaded">
      <page-title class="mb-1">
        Packaging info: {{ product.name }}
        <div class="text-gray-500 text-sm font-medium">
          {{ product.sellingBundle }}
        </div>
      </page-title>

      <div class="bg-white border p-4 mb-4 flex gap-x-8 items-center">
        <span class="font-bold">ID Produit:</span>
        <product-quick-action-modal
          :id="getProductId"
        />
      </div>

      <div class="space-y-6">
        <div>
          <product-packaging-order-table
            :orders="orders.today"
            :product-id="getProductId"
            enable-batch
            title="A livrer ajourd'hui"
            @batch-executed="getPackagingInfo"
          />
        </div>
        <div>
          <product-packaging-order-table
            :orders="orders.reserved"
            :product-id="getProductId"
            title="Réservés"
          />
        </div>
        <div>
          <product-packaging-order-table
            :orders="orders.waiting"
            :product-id="getProductId"
            title="En attente"
          />
        </div>

        <div>
          <product-packaging-supplier-table
            :orders="orders.supplierOrders"
            :product-id="getProductId"
            title="En commande fournisseur"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ProductRepository from "@/repositories/ProductRepository";
import PageTitle from "@/components/elements/pages/PageTitle";
import ProductPackagingOrderTable from "@/components/pages/Products/PackagingInfo/Tables/ProductPackagingOrderTable";
import ProductPackagingSupplierTable
  from "@/components/pages/Products/PackagingInfo/Tables/ProductPackagingSupplierOrderTable";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal.vue";

export default {
  name: "ProductPackagingInfo",
  components: {
    ProductQuickActionModal,
    ProductPackagingSupplierTable,
    ProductPackagingOrderTable,
    PageTitle,
  },
  data: () => ({
    loaded: false,

    product: null,
    productId: null,
    orders: {
      today: [],
      reserved: [],
      waiting: [],
      supplierOrders: [],
    }


  }),
  mounted() {
    this.getPackagingInfo();
  },
  methods: {
    refresh() {
      return this.getPackagingInfo();
    },
    getPackagingInfo() {
      ProductRepository.getPackagingInfo(this.$route.params.product).then((res) => {
        this.loaded = false;

        const data = res.data.data;

        this.product = data.product;
        this.orders.today = data.today;
        this.orders.reserved = data.reserved;
        this.orders.waiting = data.waiting;
        this.orders.supplierOrders = data.supplierOrders;

        this.$nextTick(() => {
          this.loaded = true;
        })
      })
    }
  },
  computed: {
    getProductId() {
      return parseInt(this.$route.params.product);
    },
  }
}
</script>

<style scoped>

</style>