<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :disabled="disabled"
    :enable-search="enableSearch"
    :label="label"
    :limit-option-menu-width="limitOptionMenuWidth"
    :multi="multi"
    :occupy-validation-space="occupyValidationSpace"
    :options="availableBookingAccounts"
    :prioritized-options="mostUsedBookingAccounts"
    :touch-optimized="touchOptimized"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import BookingAccountRepository from "../../../../repositories/BookingAccountRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "BookingAccountSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Booking Account',
      required: false,
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: true
    },
    memberId: {
      type: Number,
      default: null
    },
    /**
     * When enabled, the option menu will not have the same length as the  input, when the input is very long
     */
    limitOptionMenuWidth: {
      type: Boolean,
      default: true
    },
    touchOptimized: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multi: {
      type: Boolean,
      required: true
    },
    allowUnselecting: {
      type: Boolean,
      default: true
    },
    internalInvoice: {
      type: Boolean,
      default: false
    },
    externalInvoice: {
      type: Boolean,
      default: false
    }

  },
  mounted() {
    if (this.options === undefined) {
      this.fetchBookingAccount()
        .then(() => this.emitLoaded());
    } else {
      this.availableBookingAccounts = this.options;
      this.emitLoaded();
    }
  },
  methods: {
    fetchBookingAccount() {
      return BookingAccountRepository
        .getAllBookingAccounts(this.memberId)
        .then((res) => {
          this.availableBookingAccounts = res.data.data.filter(c => {
            // Internal Invoice Items should only have Booking Accounts starting with "7"
            if (this.internalInvoice) {
              return c.id.toString().startsWith("7")
            }

            // External Invoice Items should only have Booking Accounts >NOT< starting with "7"
            if (this.externalInvoice) {
              return !c.id.toString().startsWith("7");
            }

            return true;
          }).map(c => {
            if (c.countUsagePerMember > 0) {
              this.mostUsedBookingAccounts.push(c.id);
            }

            return {
              value: c.id,
              label: c.name,
            };
          });
        });
    },
    emitLoaded() {
      this.$emit('loaded', this.availableBookingAccounts, this.mostUsedBookingAccounts);
    },

  },
  data: () => ({
    availableBookingAccounts: [],
    mostUsedBookingAccounts: []
  })
}
</script>

