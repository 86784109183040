import auth from "../middlewares/authMiddleware";
import ShowTimeClockEntries from "@/components/pages/TimeClockEntry/ShowTimeClockEntries";
import UpdateTimeClockEntry from "@/components/pages/TimeClockEntry/UpdateTimeClockEntry";
import CreateTimeClockEntry from "@/components/pages/TimeClockEntry/CreateTimeClockEntry";
import ShowTotalTimeClockEntries from "../../components/pages/TimeClockEntry/ShowTotalTimeClockEntries";

export default [
  {
    path: '/time-clock-entries',
    name: 'timeclock.list',
    component: ShowTimeClockEntries,
    meta: {
      middleware: [auth],
      title: 'Listes des pointages',
      permissions: ['TIME_CLOCK_READ']
    }
  },
  {
    path: '/time-clock-totals',
    name: 'totalTimeclock.list',
    component: ShowTotalTimeClockEntries,
    meta: {
      middleware: [auth],
      title: 'Total Listes des pointages',
      permissions: ['TIME_CLOCK_READ']
    }
  },
  {
    path: '/time-clock-entries/:entry/edit',
    name: 'timeclock.edit',
    component: UpdateTimeClockEntry,
    meta: {
      middleware: [auth],
      title: 'Modifier pointage',
      breadcrumbs: ['timeclock.list'],
      permissions: ['TIME_CLOCK_WRITE']
    }
  },
  {
    path: '/time-clock-entries/create',
    name: 'timeclock.create',
    component: CreateTimeClockEntry,
    meta: {
      middleware: [auth],
      title: 'Créer pointage',
      breadcrumbs: ['timeclock.list'],
      permissions: ['TIME_CLOCK_WRITE']
    }
  },
]