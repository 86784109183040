<template>
  <div>
    <form-wrapper ref="form" :show-submit-button="false" @submit="uploadImages">
      <file-input-element
        v-model="filesToUpload"
        :available-labels="getLabelList"
        :max-file-size-per-file="getImageMaxFileSize"
        title="Ajouter images"></file-input-element>
    </form-wrapper>

    <div v-if="filesToUpload.length" class="flex mt-4">
      <button-element class="ml-auto" type="primary" @click="$refs.form.onSubmit()">
        Télécharger
      </button-element>
    </div>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import FileInputElement from "@/components/elements/forms/elements/base/FileInputElement";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import MediaRepository from "@/repositories/MediaRepository";

export default {
  name: "UploadBlogPostImages",
  components: {ButtonElement, FileInputElement, FormWrapper},
  props: {
    blogPostId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    filesToUpload: []
  }),
  methods: {
    uploadImages(callback) {
      if (!this.filesToUpload.length)
        return false;

      MediaRepository
        .uploadImages("blogPost", this.blogPostId, this.filesToUpload)
        .then(() => {
          this.$sendSuccessToast("Les images ont été téléchargées.");
          this.filesToUpload = [];
          this.$emit('uploaded');
        }).finally(() => callback());
    },
  },
  computed: {
    getImageMaxFileSize() {
      return this.$luxcaddyConfig('blogPost.images.maxFileSizePerImage');
    },
    getLabelList() {
      return this.$luxcaddyConfig('blogPost.images.availableLabels');
    },
  },
}
</script>

