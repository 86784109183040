import apiService from './Base/BaseRepository';

const url = '/members';

export default {

  /**
   * Returns the employee data by its id.
   *
   * @param memberId
   * @returns {Promise<*>}
   */
  async getEmployeeDetails(memberId) {
    return await apiService.get(`${url}/${memberId}/employeeDetails`);
  },

  /**
   * Updates an employee
   *
   * @param memberId
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async storeEmployeeDetails(memberId, payload) {
    return await apiService.post(`${url}/${memberId}/employeeDetails`, payload);
  },

  /**
   * Deletes an employee.
   *
   * @param memberId
   * @return {Promise<AxiosResponse<any>>}
   */
  async deactivateEmployeeDetails(memberId) {
    return await apiService.patch(`${url}/${memberId}/employeeDetails/deactivate`);
  },
  async activateEmployeeDetails(memberId) {
    return await apiService.patch(`${url}/${memberId}/employeeDetails/activate`);
  },

  async printLoginLabel(memberId) {
    return await apiService.get(`${url}/${memberId}/employeeDetails/printLoginLabel`);
  }

}
