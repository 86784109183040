<template>
  <show-instructions-table
    :endpoints="getEndpoints"
    :instructable-id="memberId"
    :instruction-types="['fulfillment', 'admin', 'delivery']"
    table-id="showMemberInstructionsTable"
  />
</template>

<script>
import ShowInstructionsTable from "@/components/forms/instructions/ShowInstructionsTable";
import MemberRepository from "@/repositories/MemberRepository";

export default {
  name: "MemberInstructionsTab",
  components: {ShowInstructionsTable},
  props: {
    memberId: {
      type: Number,
      required: true
    }
  },
  computed: {
    getEndpoints() {
      return [
        {type: 'list', func: MemberRepository.instructions.getPaginated.bind(this)},
        {type: 'get', func: MemberRepository.instructions.getSingle.bind(this)},
        {type: 'create', func: MemberRepository.instructions.create.bind(this)},
        {type: 'update', func: MemberRepository.instructions.update.bind(this)},
        {type: 'delete', func: MemberRepository.instructions.delete.bind(this)},
      ];
    }
  },
}
</script>

