<template>
  <div>
    <form-wrapper v-if="contact"
                  ref="form"
                  :show-changed="true"
                  :submit-button-label="mode === 'create' ? 'Créer contact' : 'Sauvegarder contact'"
                  @submit="createOrEditContact">


      <div class="grid grid-cols-1 xl:grid-cols-2 gap-8">
        <geocoding-management-map
          v-if="contact"
          :disableToogle="disableGeocoding"
          :is-precise.sync="isPrecise"
          :latitude.sync="geocodingLatitude"
          :longitude.sync="geocodingLongitude"
          :manual-geocoding.sync="manualGeocoding"
        />

        <form-grid>
          <title-select-input v-model="contact.title" :validation-rules="getValidationRulesForPersonalFields"
                              class="col-span-12 md:col-span-3"></title-select-input>
          <text-input-element v-model="contact.firstName" :validation-rules="getValidationRulesForPersonalFields"
                              class="col-span-12 md:col-span-3"
                              label="Prénom"></text-input-element>
          <text-input-element v-model="contact.familyName" :validation-rules="getValidationRulesForPersonalFields"
                              class="col-span-12 md:col-span-3"
                              label="Nom"></text-input-element>
          <text-input-element v-model="contact.companyName" :validation-rules="getValidationRulesForCompanyField"
                              class="col-span-12 md:col-span-3"
                              label="Société"
          ></text-input-element>


          <floor-select-input v-model="contact.floor"
                              class="col-span-12 md:col-span-3"
                              validation-rules="required"></floor-select-input>
          <street-input-element v-model="contact.street"
                                class="col-span-12 md:col-span-6"
                                validation-rules="required"></street-input-element>
          <zone-postal-code-input
            v-model="contact.postalCode"
            class="col-span-12 md:col-span-6"
            label="Code postal"
            validation-rules="required|luxembourgish_postal_code"
          />

          <text-input-element v-model="contact.city" class="col-span-12 md:col-span-6" label="Ville"
                              validation-rules="required"></text-input-element>
          <country-select-input v-model="contact.country" :allow-unselecting="false" class="col-span-12 md:col-span-4"
                                only-luxembourg validation-rules="required"></country-select-input>
          <country-phone-prefix-select-input
              v-model ="contact.phonePrefix"
              :allow-unselecting="false"
              class="col-span-12 lg:col-span-6 xl:col-span-4"
              label="Phone prefix"
              validation-rules="required"
          />
          <phone-input-element v-model="contact.phone" class="col-span-12 md:col-span-4"
                               validation-rules="required"></phone-input-element>

          <text-input-element v-model="contact.instructions" class="col-span-12"
                              label="Instructions de livraison"
                              validation-rules="required"></text-input-element>
        </form-grid>
      </div>

      <template v-slot:additionalButtons>
        <button-element class="float-right" type="light" @click="emitAbort">
          Annuler
        </button-element>
      </template>

    </form-wrapper>
  </div>
</template>

<script>
import TitleSelectInput from "@/components/elements/forms/elements/TitleSelectInput";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import CountrySelectInput from "@/components/elements/forms/elements/CountrySelectInput";
import PhoneInputElement from "@/components/elements/forms/elements/PhoneInputElement";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import CustomerRepository from "@/repositories/CustomerRepository";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import StreetInputElement from "@/components/elements/forms/elements/StreetInputElement";
import FormGrid from "@/components/elements/forms/FormGrid";
import FloorSelectInput from "@/components/elements/forms/elements/FloorSelectInput";
import GeocodingManagementMap
  from "@/components/pages/Members/EditMember/Tabs/DeliveryContacts/Form/Includes/GeocodingManagementMap";
import ZonePostalCodeInput from "../Includes/ZonePostalCodeInput";
import CountryPhonePrefixSelectInput from "@/components/elements/forms/elements/CountryPhonePrefixSelectInput.vue";

export default {
  name: "CustomerContactShowCreateEditForm",
  components: {
      CountryPhonePrefixSelectInput,
    GeocodingManagementMap,
    FloorSelectInput,
    FormGrid,
    StreetInputElement,
    FormWrapper,
    ButtonElement,
    PhoneInputElement,
    CountrySelectInput,
    TextInputElement,
    TitleSelectInput,
    ZonePostalCodeInput
  },
  data: () => ({
    contact: null,

    manualGeocoding: false,
    geocodingLatitude: null,
    geocodingLongitude: null,
    isPrecise: false,
    disableGeocoding: false
  }),
  props: {
    memberId: {
      type: Number,
      required: true,
    },
    contactId: {
      type: Number
    },
    mode: {
      validator: val => val === null || typeof val === "string",
      required: true
    }
  },
  watch: {
    manualGeocoding(newBool, oldBool) {
      // Set from Manual to Automatic
      if (oldBool === true && newBool === false) {
        if (this.isGeoCodingValid(this.contact.geocoding)) {
          this.geocodingLongitude = this.contact.geocoding.longitude;
          this.geocodingLatitude = this.contact.geocoding.latitude;
          this.isPrecise = this.contact.geocoding.isPrecise;
        }
      }
    }
  },
  mounted() {
    if (this.mode === "edit") {
      this.fetchContact();
    } else {
      this.contact = {
        title: null,
        firstName: '',
        familyName: '',
        companyName: '',
        phoneNumber: '',
        phonePrefix: 352,
        street: '',
        postalCode: '',
        city: '',
        country: 'lu',
        instructions: '',
        floor: '',
      };

      this.$nextTick(() => {
        this.setDefaultMarker();
        this.resetFormValidationState();
      });
    }
  },
  methods: {
    fetchContact() {
      CustomerRepository.deliveryContacts.getSingle(
        this.memberId,
        this.contactId
      ).then((res) => {
        let contactData = res.data.data;

        if (this.isGeoCodingValid(contactData.geocoding)) {
          if (contactData.geocoding?.isManual) {
            this.disableGeocoding = contactData.geocoding?.isManual;
            this.manualGeocoding = contactData.geocoding?.isManual;
          }
          this.geocodingLatitude = contactData.geocoding.latitude;
          this.geocodingLongitude = contactData.geocoding.longitude;
          this.isPrecise = contactData.geocoding.isPrecise;
        } else {
          this.setDefaultMarker();
        }

        this.contact = contactData;

        this.$nextTick(() => this.resetFormValidationState());
      });
    },

    isGeoCodingValid(geocoding) {
      if (geocoding === null) {
        return false;
      }
      return geocoding.latitude && geocoding.longitude;
    },

    setDefaultMarker() {
      this.manualGeocoding = false;
      this.geocodingLatitude = 49.755461;
      this.geocodingLongitude = 6.117866;
    },

    createOrEditContact(callback) {
      let payload = {
        title: this.contact.title,
        firstName: this.contact.firstName,
        familyName: this.contact.familyName,
        companyName: this.contact.companyName,
        phoneNumber: this.contact.phone,
        street: this.contact.street,
        postalCode: this.contact.postalCode,
        city: this.contact.city,
        country: this.contact.country,
        floor: this.contact.floor,
        instructions: this.contact.instructions,
        manualGeocoding: this.manualGeocoding,
        phonePrefix: this.contact.phonePrefix,
      };

      if (this.manualGeocoding) {
        payload.latitude = this.geocodingLatitude;
        payload.longitude = this.geocodingLongitude;
      }

      if (this.mode === 'edit') {
        return CustomerRepository
          .deliveryContacts
          .update(this.memberId, this.contact.id, payload)
          .then(() => {
            this.$sendSuccessToast(`Contact ${this.getContactFullName} a été modifié!`);
            this.$emit('change');
          }).finally(() => callback());
      } else if (this.mode === 'create') {
        return CustomerRepository
          .deliveryContacts
          .create(this.memberId, payload)
          .then(() => {
            this.$sendSuccessToast(`Contact ${payload.firstName} ${payload.familyName} a été crée!`);
            this.$emit('change');
          }).finally(() => callback());
      }
      return false;
    },

    resetFormValidationState() {
      if (this.$refs.form?.$refs?.validationObserver) {
        this.$refs.form.$refs.validationObserver.reset();
      }
    },

    emitAbort() {
      this.$emit('abort');
    }
  },
  computed: {
    getContactFullName() {
      return this.contact?.fullName || '...';
    },
    isInReadOnlyMode() {
      return this.$isReadOnly();
    },

    /*
     *  VALIDATION
     */
    isFirstNameSet() {
      return this.contact.firstName?.length > 0 || false;
    },
    isFamilyNameSet() {
      return this.contact.familyName?.length > 0 || false;
    },
    isTitleSet() {
      return this.contact.title !== null;
    },
    isCompanySet() {
      return this.contact.companyName?.length > 0 || false;
    },
    getValidationRulesForPersonalFields() {
      return this.isCompanySet ? 'max:50' : 'required|max:50';
    },
    getValidationRulesForCompanyField() {
      return this.isFirstNameSet || this.isFamilyNameSet || this.isTitleSet ? 'max:50' : 'required|max:50';
    },
  }
}
</script>

