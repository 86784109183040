<template>
  <div>
    <page-title>
      {{ getTitle }}
    </page-title>

    <card>
      <form-wrapper ref="form" :show-changed="true" submit-button-label="Créer marque"
                    @submit="createArrivalTemperature">
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-y-4 md:gap-x-8">
          <text-input-element
            v-model="temperature"
            inset-text="°C"
            label="Témperature"
            validation-rules="required"
          />

          <select-element
            v-model="department"
            :allow-unselecting="false"
            :enable-search="false"
            :options="getDepartments"
            label="Département"
            validation-rules="required"
          />

          <date-picker-element
            v-model="date"
            :only-date="false"
            label="Date de l'arrivage"
          />

          <supplier-select-input
            v-model="supplierId"
            label="Fournisseur"
            only-active-suppliers
          >

          </supplier-select-input>
        </div>
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";
import SupplierSelectInput from "@/components/elements/forms/elements/SupplierSelectInput";
import ArrivalTemperatureRepository from "@/repositories/ArrivalTemperatureRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CreateArrivalTemperatureForm",
  components: {SelectElement, SupplierSelectInput, DatePickerElement, TextInputElement, FormWrapper, PageTitle, Card},
  data: () => ({
    temperature: null,
    department: null,
    date: null,
    supplierId: null,
  }),
  methods: {
    createArrivalTemperature(callback) {
      ArrivalTemperatureRepository
        .create(this.department, this.supplierId, this.temperature, this.date)
        .then(() => {
          this.$sendSuccessToast("Température crée!");
          this.$router.push({
            name: 'arrival-temperatures.list'
          })
        }).finally(() => callback());
    }
  },
  computed: {
    getDepartments() {
      return this.$luxcaddyConfig('arrivalTemperature.departments');
    },
    getTitle() {
      return 'Créer nouvelle température d\'arrivage';
    }
  }
}
</script>

