<template>
  <div
    v-html="orderLabelHTMLCode"
  />
</template>

<script>

import OrderRepository from "@/repositories/OrderRepository";

export default {
  name: "OrderLabelHtml",
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  watch: {
    orderId: function () {
      this.fetchLabelHTML();
    }
  },
  data: () => ({
    orderLabelHTMLCode: ''
  }),
  created() {
    this.fetchLabelHTML();
  },
  methods: {
    fetchLabelHTML() {
      OrderRepository.getOrderLabelHtml(this.orderId)
        .then(res => {
          this.orderLabelHTMLCode = res.data;
          this.$nextTick(() => {
            this.$emit('ready');
          })
        });
    }
  },
}
</script>