import apiService from './Base/BaseRepository';

const url = '/auth';

export default {
  async getSanctumCsrfCookie() {
    return await apiService.get('/sanctum/csrf-cookie');
  },


  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async login(email, password) {
    return await apiService.post(`${url}/login`, {
      email, password
    });
  },

  async loginByToken(token) {
    return await apiService.post(`${url}/token`, {
      token
    }, {
      showLoadingIndicator: false
    });
  },

  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async logout() {
    return await apiService.post(`${url}/logout`);
  },

}
