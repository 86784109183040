<template>
  <show-instructions-table
    :apply-once-supported="false"
    :endpoints="getEndpoints"
    :instructable-id="orderId"
    :instruction-types="['fulfillment', 'admin']"
  />
</template>

<script>
import ShowInstructionsTable from "@/components/forms/instructions/ShowInstructionsTable";
import OrderRepository from "@/repositories/OrderRepository";

export default {
  name: "OrderInstructionManagement",
  components: {ShowInstructionsTable},
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  computed: {
    getEndpoints() {
      return [
        {type: 'list', func: OrderRepository.instructions.getPaginated.bind(this)},
        {type: 'get', func: OrderRepository.instructions.getSingle.bind(this)},
        {type: 'create', func: OrderRepository.instructions.create.bind(this)},
        {type: 'update', func: OrderRepository.instructions.update.bind(this)},
        {type: 'delete', func: OrderRepository.instructions.delete.bind(this)},
      ];
    }
  },
}
</script>

