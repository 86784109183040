<template>
  <div>
    <page-title>
      {{ getTitle }}
    </page-title>

    <card>
      <form-wrapper ref="form" :show-changed="true"
                    :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer shortUrl'"
                    @submit="createOrUpdateShortUrl">
        <div class="grid grid-cols-6 gap-y-4 md:gap-x-8">
          <text-input-element v-model="slug" class="col-span-12 lg:col-span-6 xl:col-span-5"
                              label="Slug"
                              validation-rules="required"></text-input-element>
        </div>
        <div class="grid grid-cols-6 gap-y-4 md:gap-x-8">
          <text-input-element v-model="url" class="col-span-12 lg:col-span-6 xl:col-span-5"
                              label="Full url"
                              validation-rules="required"></text-input-element>
        </div>
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import Card from "../../../elements/cards/Card";
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import PageTitle from "../../../elements/pages/PageTitle";
import ShortUrlRepository from "../../../../repositories/ShortUrlRepository";

export default {
  name: "CreateOrUpdateShortUrlForm",
  components: {
    PageTitle,
    TextInputElement,
    FormWrapper,
    Card
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    }
  },
  data: () => ({
    slug: '',
    url: '',
    id: ''
  }),
  mounted() {
    this.getShortUrl();
  },
  methods: {
    getShortUrl() {
      if (this.isUpdateMode) {
        ShortUrlRepository.getSingle(this.$route.params.slug).then((res) => {
          this.slug = res.data.data.slug;
          this.url = res.data.data.fullUrl;
          this.id = res.data.data.id;
        }).then(() => this.$refs.form.resetForm());
      }
    },
    createOrUpdateShortUrl(callback) {
      if (this.isUpdateMode) {
        return ShortUrlRepository.update(this.id, {
          slug: this.slug,
          fullUrl: this.url,
        }).then(() => {
          this.$sendSuccessToast(`ShortUrl a été modifié!`);
        }).finally(() => callback());
      }

      return ShortUrlRepository.create({
        slug: this.slug,
        fullUrl: this.url,
      }).then(() => {
        this.$router.push({name: 'shortUrls.edit', params: {slug: this.slug}});
        this.$sendSuccessToast(`ShortUrl a été crée!`);
      }).finally(() => callback());
    },
  },
  computed: {
    isUpdateMode() {
      return this.mode === 'update';
    },
    getTitle() {
      if (this.isUpdateMode) {
        return `${this.slug} #${this.id}`;
      }
      return 'Créer nouvelle URL courte';
    }
  }
}
</script>

