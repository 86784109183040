<template>
  <div>
    <page-title>
      Créer facture
    </page-title>
    <card>
      <shared-create-internal-invoice
        @created="onInvoiceCreate"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import SharedCreateInternalInvoice from "@/components/pages/Invoices/Shared/CreateInvoice/SharedCreateInternalInvoice";
import Card from "@/components/elements/cards/Card";

export default {
  name: "CreateInternalInvoice",
  components: {Card, SharedCreateInternalInvoice, PageTitle},
  methods: {
    onInvoiceCreate(invoiceId) {
      this.$sendSuccessToast("Facture crée!");

      this.$router.push({
        name: 'invoices.edit',
        params: {
          id: invoiceId
        }
      });
    }
  },
}
</script>

<style scoped>

</style>