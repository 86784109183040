<template>
  <div>
    <list-table
      ref="table"
      :columns="getColumns"
      :create-button="false"
      :filters="getFilters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="isStockHistoryForSingleProduct ? null : 'Historique Stock'"
      identifier="productStockHistoryTable"
    >
      <template v-slot:stock-change-type-template="{ row }">
        <div class="flex gap-x-1">
          <div>{{ row.type }}</div>
          <div v-if="isSupplierOrderReference(row) || isCustomerOrderReference(row)">&middot;</div>
          <text-link
            v-if="isSupplierOrderReference(row)"
            :to="{name: 'supplier-orders.edit', params: {order: row.reference}}"
          >
            #{{ row.reference }}
          </text-link>
          <text-link
            v-else-if="isCustomerOrderReference(row)"
            :to="{name: 'orders.edit', params: {order: row.reference}}"
          >
            #{{ row.reference }}
          </text-link>
        </div>
      </template>

      <template v-slot:product-template="{ row }">
        <div class="flex gap-x-1">
          <product-name
            :product="row.product"
          />
          <div>&middot;</div>
          <product-quick-action-modal
            :id="row.product.id"
          />
        </div>
      </template>

      <template v-slot:quantity-template="{ row }">
        <div
          :class="{
                        'bg-red-400 font-bold text-white': row.quantity < 0,
                        'bg-luxcaddy-400 text-white': row.quantity > 0,
                        'w-10': row.quantity.length <= 4,
                        'w-14': row.quantity.length > 4
                }"
          class="p-1 rounded-sm w-10 text-center font-bold"
        >
          {{ row.quantity }}
        </div>
      </template>
    </list-table>

  </div>
</template>

<script>
import ProductRepository from "../../../../repositories/ProductRepository";
import ListTable from "@/components/listTable/ListTable";
import TextLink from "@/components/global/TextLink";

import {
  FILTER_DATETIME_RANGE,
  FILTER_MEMBER,
  FILTER_SELECT_SINGLE,
  FILTER_TEXT,
} from "@/components/listTable/includes/controls/filters/availableFilters";
import StockHistoryRoutes from "@/repositories/StockHistoryRoutes";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal.vue";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";

export default {
  name: "ProductStockHistoryTable",
  components: {ProductName, ProductQuickActionModal, ListTable, TextLink},
  props: {
    productId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      historyList: [],

      // Example of how to pass a more complicated repository method where you need to pass addition info
      // to the pagination/sort/filter info returned from ListTable
      repositoryFunc: (...args) => {
        if (this.isStockHistoryForSingleProduct) {
          return ProductRepository.stock.getStockHistory(this.productId, ...args);
        }
        return StockHistoryRoutes.getStockHistory(...args);
      },
    }
  },
  methods: {
    refreshTable() {
      this.$refs.table.refresh();
    },

    isSupplierOrderReference(row) {
      return row.reference && row.type === 'ARRIVAGE FOURNISSEUR'
    },
    isCustomerOrderReference(row) {
      return row.reference && row.type === 'COMMANDE CLIENT'
    },
  },
  computed: {
    isStockHistoryForSingleProduct() {
      return this.productId !== null;
    },
    getColumns() {
      let columns = [
        {
          caption: "Type & Réf.",
          cellTemplate: 'stock-change-type-template'
        },
        {
          caption: "Quantité",
          cellTemplate: 'quantity-template',
          sort: {
            dataField: 'quantity'
          }
        },
        {
          caption: "Explication",
          dataField: 'comment',
          sort: {
            dataField: 'comment',
          },
          additionalClasses: () => {
            return "italic";
          },
        },
        {
          caption: "Utilisateur",
          calculateCellValue: r => r.user || 'SYSTEM',
        },
        {
          caption: "StR",
          dataField: 'currentRealStock',
        },
        {
          caption: "StA",
          dataField: 'currentAvailableStock',
        },
        {
          caption: "Date & Heure",
          calculateCellValue: r => this.$date(r.createdAt).format('DD.MM.YYYY HH:mm'),
          sort: {
            dataField: 'createdAt',
            default: 'desc'
          }
        },
      ];

      // Show product details if on global stock history
      if (!this.isStockHistoryForSingleProduct) {
        columns.unshift({
          caption: "Produit",
          cellTemplate: 'product-template'
        });
      }

      return columns;
    },
    getFilters() {
      let filters = [
        {
          caption: 'Type Modification',
          filterKey: 'type',
          type: FILTER_SELECT_SINGLE,
          defaultValue: null,
          filterOptions: [
            {label: 'Tous', value: null},
            {label: 'Modification manuelle', value: 'manual'},
            {label: 'Arrivage fournisseur', value: 'supplierOrder'},
            {label: 'Commande client', value: 'customerOrder'},

          ],
          forceIsVisible: true,
        },
        {
          caption: "Explication",
          filterKey: "comment",
          type: FILTER_TEXT
        },
        {
          caption: "Membre",
          filterKey: "member",
          type: FILTER_MEMBER,
          forceIsVisible: true,
        },
        {
          filterKey: 'createdAt',
          type: FILTER_DATETIME_RANGE,
          caption: "Date entre"
        },
      ];

      if (!this.isStockHistoryForSingleProduct) {
        filters.unshift({
          caption: 'ID(s) / Nom produit',
          filterKey: 'productIdsOrName',
          forceIsVisible: true,
        });
      }

      return filters;
    }
  },
}
</script>

