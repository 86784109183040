import auth from "@/router/middlewares/authMiddleware";
import GlobalStockHistoryPage from "@/components/pages/StockHistory/GlobalStockHistoryPage.vue";

export default [
  {
    path: '/stockHistory',
    name: 'stockHistory.list',
    component: GlobalStockHistoryPage,
    meta: {
      middleware: [auth],
      title: 'Modifications Stock',
      permissions: ['ACCESS_PRODUCT_VIEW']
    }
  },
];