<template>
  <div>
    <list-table
      ref="table"
      :batch-actions="batchActions"
      :columns="columns"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :select-values="selectValues"
      :title="title"
      identifier="supplierPriceComparisonTable"
      selectable
    >
      <template v-slot:product-name-template="{ row }">
        <product-name
          :product="row"
        />
      </template>
    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import ProductRepository from "@/repositories/ProductRepository";
import {centsToEuroString} from "@/helpers/mathHelper";
import {
  FILTER_PRODUCT_SUPPLIER,
  FILTER_SELECT_SINGLE,
  FILTER_TEXT
} from "@/components/listTable/includes/controls/filters/availableFilters";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";

export default {
  name: "ShowSupplierPriceComparison",
  components: {ProductName, ListTable},
  data() {
    return {
      title: 'Comparateur de prix',
      selectValues: r => {
        return {
          id: r.id
        }
      },
      batchActions: [
        {
          label: "Adapter Prix d'achat uniquement",
          additionalFields: [
            {
              label: "Actualiser fournisseur",
              type: "checkbox",
              param: "updateSupplierIdToCompared"
            },
            {
              label: "Actualiser réf. fournisseur",
              type: "checkbox",
              param: "updateSupplierReferenceToCompared"
            }
          ],
          action: (callBack, selectedRows, additionalParams) => {
            return ProductRepository.catalogComparison.executeBatchAction(
              "updateBuyingPriceToCatalog",
              selectedRows,
              additionalParams
            ).finally(() => callBack());
          }
        },
        {
          label: "Adapter Prix d'achat et prix de vente",
          additionalFields: [
            {
              label: "Actualiser fournisseur",
              type: "checkbox",
              param: "updateSupplierIdToCompared"
            },
            {
              label: "Actualiser réf. fournisseur",
              type: "checkbox",
              param: "updateSupplierReferenceToCompared"
            }
          ],
          action: (callBack, selectedRows, additionalParams) => {
            return ProductRepository.catalogComparison.executeBatchAction(
              "updatePriceToCatalogAndKeepSameMargin",
              selectedRows,
              additionalParams
            ).finally(() => callBack());
          }
        },
        {
          label: "Adapter Prix d'achat et prix de vente avec marge specifiée",
          additionalFields: [
            {
              label: "Marge",
              type: "number",
              param: "margin"
            },
            {
              label: "Actualiser fournisseur",
              type: "checkbox",
              param: "updateSupplierIdToCompared"
            },
            {
              label: "Actualiser réf. fournisseur",
              type: "checkbox",
              param: "updateSupplierReferenceToCompared"
            }
          ],
          action: (callBack, selectedRows, additionalParams) => {
            return ProductRepository.catalogComparison.executeBatchAction(
              "updatePriceToCatalogWithMargin",
              selectedRows,
              additionalParams
            ).finally(() => callBack());
          }
        },
        {
          label: "Adapter Prix d'achat et définir prix de vente Luxcaddy",
          additionalFields: [
            {
              label: "Prix de vente",
              type: "currency",
              param: "sellingPrice"
            },
            {
              label: "Actualiser fournisseur",
              type: "checkbox",
              param: "updateSupplierIdToCompared"
            },
            {
              label: "Actualiser réf. fournisseur",
              type: "checkbox",
              param: "updateSupplierReferenceToCompared"
            }
          ],
          action: (callBack, selectedRows, additionalParams) => {
            return ProductRepository.catalogComparison.executeBatchAction(
              "updatePriceToCatalogWithSellingPrice",
              selectedRows,
              additionalParams
            ).finally(() => callBack());
          }
        }
      ],
      columns: [
        {
          caption: '#',
          width: 80,
          calculateCellValue: r => r.id,
        },
        {
          caption: 'PrId',
          calculateCellValue: r => r.productId
        },
        {
          caption: 'Fourn. actuel',
          calculateCellValue: r => r.supplier?.fullName
        },
        {
          caption: 'Fourn. comparé',
          calculateCellValue: r => r.productCatalog?.supplierName,
          sort: {dataField: 'comparedSupplier'}
        },

        {
          caption: 'FoId Fichier',
          calculateCellValue: r => r.productCatalog?.productNumber
        },
        {
          caption: 'Produit',
          cellTemplate: 'product-name-template'
        },
        {
          caption: 'Contenu',
          calculateCellValue: r => r.bundle?.quantity + 'x ' + r.bundle?.content + r.bundle?.unit
        },
        {
          caption: 'Emb.',
          calculateCellValue: r => r.buyingData?.bundleQuantity
        },
        {
          caption: 'Notre prix A.Unitaire',
          dataType: 'euro',
          calculateCellValue: r => r.buyingData?.unitPrice ? centsToEuroString(r.buyingData.unitPrice) : ''
        },
        {
          caption: 'Prix catalogue',
          dataType: 'euro',
          calculateCellValue: r => r.productCatalog?.price ? centsToEuroString(r.productCatalog.price) : '',
          sort: {dataField: 'comparedPrice'}
        },
        {
          caption: 'Diff.',
          dataType: 'percentage',
          calculateCellValue: r => r.priceDifference ? r.priceDifference + '%' : r.priceDifference,
          sort: {dataField: 'comparedPriceDifference'}
        },
        {
          caption: 'Marge',
          dataType: 'percentage',
          calculateCellValue: r => r.marge ? r.marge + '%' : r.marge,
        },
        {
          caption: 'Prix V. TTC',
          dataType: 'euro',
          calculateCellValue: r => r.sellingPrice ? centsToEuroString(r.sellingPrice) : ''
        },
      ],
      repositoryFunc: ProductRepository.catalogComparison.getComparisonSupplierCatalog,
      filters: [
        {
          caption: "Connecter produits par",
          filterKey: "joinBy",
          type: FILTER_SELECT_SINGLE,
          defaultValue: 'barcode',
          filterOptions: [
            {value: 'supplierReference', label: 'Réf. Fournisseur'},
            {value: 'barcode', label: 'Code barre'},
          ]
        },
        {
          filterKey: 'comparedPriceDifference',
          inputs: [
            {
              caption: 'Diff. prix % de',
              inputKey: 'comparedPriceDifference_from',
              type: FILTER_TEXT
            },
            {
              caption: 'Diff. prix % de',
              inputKey: 'comparedPriceDifference_to',
              type: FILTER_TEXT
            }
          ],
          forceIsVisible: true,
        },
        {
          caption: "Prix comparé",
          filterKey: "comparedPriceIs",
          type: FILTER_SELECT_SINGLE,
          filterOptions: [
            {value: 'bigger', label: 'Plus'},
            {value: 'less', label: 'Moins'},
          ],
          forceIsVisible: true,
          small: true
        },
        {
          caption: "Afficher",
          filterKey: "onlyMatchedProducts",
          type: FILTER_SELECT_SINGLE,
          filterOptions: [
            {value: true, label: 'uniquement les match'},
            {value: false, label: 'tous'},
          ],
          forceIsVisible: true,
        },
        {
          caption: "Comparer avec import du fournisseur",
          filterKey: "importedSupplierId",
          type: FILTER_PRODUCT_SUPPLIER,
        },
        {
          caption: "Fournisseur actuel",
          filterKey: "actualSupplierId",
          type: FILTER_PRODUCT_SUPPLIER,
        },
      ]
    }
  },
  methods: {},
}
</script>