<template>
  <div v-if="!$isReadOnly()">
    <transition name="fade-up-down">
      <form-wrapper
        :show-changed="true"
        submit-button-icon="cart-plus"
        submit-button-label="Ajouter"
        @submit="addCustomProduct">

        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4">
          <text-input-element v-model="name" label="Nom" validation-rules="required"></text-input-element>

          <currency-input-element v-model.number="price" allow-negative label="Prix TTC"
                                  validation-rules="required"></currency-input-element>
          <vat-input-element
            v-model="vat"
            validation-rules="required"
          />
        </div>

        <template #additionalButtons>
          <add-amount-element v-model="toBePackedQuantity" size="large" without-button></add-amount-element>
        </template>

      </form-wrapper>
    </transition>
  </div>
</template>

<script>
import FormWrapper from "../../../../elements/forms/FormWrapper";
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";
import CurrencyInputElement from "../../../../elements/forms/elements/CurrencyInputElement";
import VatInputElement from "../../../../elements/forms/elements/VatInputElement.vue";
import OrderRepository from "../../../../../repositories/OrderRepository";
import AddAmountElement from "../../../../elements/forms/elements/AddAmountElement";

export default {
  name: "AddCustomProductToOrder",
  components: {
    AddAmountElement,
    VatInputElement, CurrencyInputElement, TextInputElement, FormWrapper
  },
  data: () => ({
    name: '',
    toBePackedQuantity: 1,
    price: 0,
    vat: 16
  }),
  created() {
    if (this.isReferencingItem) {
      if (this.$store.getters['order/getReferencedItemQuantity'] !== 0) {
        this.toBePackedQuantity = this.$store.getters['order/getReferencedItemQuantity'];
      }
    }
  },
  methods: {
    addCustomProduct(callback) {
      let payload = {
        name: this.name,
        toBePackedQuantity: this.toBePackedQuantity,
        price: this.price,
        vat: this.vat
      };

      if (this.isReferencingItem) {
        payload.reference = this.getReferencedItemId;
        payload.substituteReferencedItem = this.$store.getters['order/getSubstituteReferencedItem'];
      }

      OrderRepository
        .addCustomItem(this.getOrderId, payload, true, true)
        .then(() => {
          this.$sendSuccessToast('Libellé libre ajoutée.')
          this.name = '';
          this.toBePackedQuantity = 1;
          this.price = 0;
          this.vat = 16;
          this.$emit('added');
        }).finally(() => callback());
    }
  },
  computed: {
    getOrderId() {
      return this.$store.getters['order/getOrderId'];
    },
    isReferencingItem() {
      return this.$store.getters['order/isReferencingItem'];
    },
    getReferencedItemId() {
      return this.$store.getters['order/getReferencedItemId'];
    }
  }
}
</script>

