import apiService from './Base/BaseRepository';

const url = '/members';

export default {

  /**
   * Returns the employee data by its id.
   *
   * @param memberId
   * @returns {Promise<*>}
   */
  async getUserDetails(memberId) {
    return await apiService.get(`${url}/${memberId}/userDetails`);
  },

  /**
   * Updates an employee
   *
   * @param memberId
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async storeUserDetails(memberId, payload) {
    return await apiService.post(`${url}/${memberId}/userDetails`, payload);
  },

  /**
   * Deletes an employee.
   *
   * @param memberId
   * @return {Promise<AxiosResponse<any>>}
   */
  async deactivateUserDetails(memberId) {
    return await apiService.patch(`${url}/${memberId}/userDetails/deactivate`);
  },

  async activateUserDetails(memberId) {
    return await apiService.patch(`${url}/${memberId}/userDetails/activate`);
  }

}
