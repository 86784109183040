<template>
  <div>
    <touch-optimized-button @click="openModal">
            <span
              v-if="!loading"
              :class="{'font-bold': getSelectedRouteId !== null}"
            >
                {{ getSelectedRouteName }}
            </span>
      <fa-icon v-else class="animate-spin transition" icon="circle-notch"></fa-icon>
    </touch-optimized-button>

    <touch-optimized-modal ref="modal">
      <div class="flex justify-center">

        <touch-optimized-modal-button
          border-class="border-luxcaddy-300"
          class="mb-24 w-max float-right"
          text-class="text-luxcaddy-300"
          @click="selectRoute(null)"
        >
          Tous les routes
        </touch-optimized-modal-button>
      </div>


      <div class="flex flex-wrap justify-center gap-8">
        <touch-optimized-modal-button
          v-for="route in availableRoutes"
          :key="route.value"
          @click="selectRoute(route)"
        >
          {{ route.label }}
        </touch-optimized-modal-button>
      </div>
    </touch-optimized-modal>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import {mapGetters, mapMutations} from "vuex";
import RouteRepository from "@/repositories/RouteRepository";

export default {
  name: "OrderFulfilmentListRouteFilterButton",
  components: {TouchOptimizedModalButton, TouchOptimizedModal, TouchOptimizedButton},
  data: () => ({
    loading: false,
    availableRoutes: []
  }),
  watch: {
    getSelectedDay() {
      this.setSelectedRoute(null);
      this.loadRoutes();
    }
  },
  methods: {
    ...mapMutations('fulfilmentFilters', [
      'setSelectedRoute'
    ]),

    openModal() {
      this.loadRoutes()
        .then(() => this.$refs.modal.open());
    },

    loadRoutes() {
      this.loading = true;
      return RouteRepository
        .getSelectOptionsForDate(this.getSelectedDay)
        .then((res) => {
          this.availableRoutes = res.data.data.routes;
        }).finally(() => this.loading = false);
    },

    selectRoute(route) {
      this.setSelectedRoute(route);
      this.$refs.modal.close();
    }
  },
  computed: {
    ...mapGetters('fulfilmentFilters', [
      'getSelectedRoute',
      'getSelectedRouteId',
      'getSelectedDay',
    ]),
    getSelectedRouteName() {
      return this.getSelectedRoute?.label || 'Routes';
    }
  },
}
</script>

