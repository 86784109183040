import apiService from './Base/BaseRepository';

const url = '/roles';

export default {

  /**
   * Fetches a list of roles.
   *
   * @return {Promise<AxiosResponse<any>>}
   */
  async getRoles(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0,
  ) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getSingle(roleId) {
    return await apiService
      .get(`${url}/${roleId}`);
  },

  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  /**
   * Creates a new product.
   * @return {Promise<AxiosResponse<any>>}
   */
  async update(roleId, payload) {
    return await apiService.patch(`${url}/${roleId}`, payload);
  },

  async delete(roleId) {
    return await apiService.delete(`${url}/${roleId}`);
  },
}
