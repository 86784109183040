<template>
  <div :style="setMaxHeight ? 'max-height: 500px' : ''" class="overflow-y-auto">
    <category-selection-tree-item
      v-if="withRoot"
      key="root"
      :compact="compact"
      is-root
    />

    <category-selection-tree-item
      v-for="category in getCategories"
      :key="category.id"
      :categories-marked-as-included="categoriesMarkedAsIncluded"
      :category="category"
      :class="{'ml-8': withRoot}"
      :compact="compact"
      :start-expanded="startExpanded"
    />
  </div>
</template>

<script>
import CategorySelectionTreeItem
  from "@/components/elements/categoryManagement/Tree/Selection/CategorySelectionTreeItem";
import {categoryTypes} from "@/luxcaddyConfig";

export default {
  name: "CategorySelectionTree",
  components: {CategorySelectionTreeItem},
  created() {
    this.fetchCategories();
  },
  data: () => ({
    categories: [],
  }),
  props: {
    withRoot: {
      type: Boolean,
      default: false
    },
    setMaxHeight: {
      type: Boolean,
      default: true
    },
    /**
     * When set to true, tree will be completely expanded.
     */
    startExpanded: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    /**
     * Expects an array of categoryIds that should be shown with a "selected" marker/icon
     */
    categoriesMarkedAsIncluded: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: "Product",
      validator: val => categoryTypes().includes(val)
    },
    isVirtual: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    fetchCategories() {
      this.$store.dispatch('categories/getAllCategories', {type: this.type, isVirtual: this.isVirtual});
    },
  },
  computed: {
    getCategories() {
      return this.$store.getters['categories/getAllCategories'];
    }
  }
}
</script>

