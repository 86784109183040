<template>
  <div>
    <delivery-label-html
      v-if="getDeliveryId && getOrderFulfilmentNumber"
      ref="deliveryLabelHtml"
      :delivery-id="getDeliveryId"
    />
    <order-label-html
      v-if="getOrderId && !getDeliveryId && !getOrderFulfilmentNumber"
      ref="orderLabelHtml"
      :order-id="getOrderId"
    />

    <!-- Jump to another order by fulfilment id -->
    <touch-optimized-modal
      ref="navigateByFulfilmentNumberModal"
    >
      <h1 class="text-2xl sm:text-3xl mb-4">
        Veullez indiquer numéro BAC
      </h1>
      <number-input-element
        ref="navigateToFulfilmentNumberInput"
        v-model="navigateToFulfilmentNumber"
      />

      <key-pad class=""/>

      <touch-optimized-modal-button
        border-class="border-yellow-200"
        class="mt-4 lg:mb-16"
        text-class="text-xl md:text-2xl lg:text-3xl text-yellow-200"
        @click="navigateToOrderByFulfilmentNumber"
      >
        Confirmer
      </touch-optimized-modal-button>
    </touch-optimized-modal>

   <!-- Jump to another order by  id -->
    <touch-optimized-modal
      ref="navigateByOrderIdModal"
    >
      <h1 class="text-2xl sm:text-3xl mb-4">
        Veullez indiquer numero de commande
      </h1>
      <number-input-element
        ref="navigateToOrderIdInput"
        v-model="navigateToOrderId"
      />

      <key-pad class=""/>

      <touch-optimized-modal-button
        border-class="border-yellow-200"
        class="mt-4 lg:mb-16"
        text-class="text-xl md:text-2xl lg:text-3xl text-yellow-200"
        @click="navigateToOrderById"
      >
        Confirmer
      </touch-optimized-modal-button>
    </touch-optimized-modal>

    <fulfilment-layout-base :enable-padding="false" class="print:hidden">

      <div class="h-screen flex justify-between">
        <!-- Left side bar -->
        <aside
          class="h-full w-96 flex-shrink-0 bg-gray-200 flex flex-col space-y-0.5 items-center relative p-0.5 pr-1 overflow-y-auto">

          <div
            class="bg-gray-300 w-full py-1 px-2 border border-gray-400 border-opacity-80 flex justify-between items-center">
            <div class="text-blue-900 text-opacity-80 font-bold flex flex-col">
              <div v-if="getOrderId">ComID #{{ getOrderId }}</div>
              <div v-if="getDeliveryId">LivID #{{ getDeliveryId }}</div>
            </div>
            <order-fulfilment-status-indicator
              v-if="getOrderFulfilmentStatus"
              :fulfilment-status="getOrderFulfilmentStatus"
              class="flex-none"
              small
            />
          </div>

          <div class="w-full bg-white border border-gray-400 border-opacity-80 px-2 py-1 text-center">
                    <span class="text-blue-900 text-opacity-80 text-lg font-bold">
                        <time-slot-as-string
                          :from="getOrderDeliveryTimeStartDate"
                          :to="getOrderDeliveryTimeEndDate"
                          :zone="getOrderDeliveryZone"
                          width-class="w-full"
                        />
                    </span>
          </div>

          <order-fulfilment-instruction-list class="w-full"/>
        </aside>

        <!-- item list -->
        <order-fulfilment-item-list/>
      </div>

      <template #sidebar>
        <div class="px-2 h-full w-full pb-2 flex flex-col space-y-10 items-center justify-between relative">
          <div class="flex-grow w-full space-y-3 mt-4">
            <order-fulfilment-list-department-button/>

            <div class="grid grid-cols-2 gap-3 mt-4">
              <touch-optimized-button v-if="getDeliveryId" :disabled="!getOrderFulfilmentNumber"
                                      @click="printDeliveryLabel">Etiquette
              </touch-optimized-button>
              <touch-optimized-button v-if="!getDeliveryId && getOrderId" @click="printOrderLabel">
                Etiquette
              </touch-optimized-button>
              <touch-optimized-button
                :disabled="getPreviousFulfilmentOrderId === null"
                @click="navigateToOrder('previous')"
              >
                Back
              </touch-optimized-button>
              <touch-optimized-button @click="openNavigateByFulfilmentNumberModal">Par BAC
              </touch-optimized-button>
              <touch-optimized-button
                :disabled="getNextFulfilmentOrderId === null"
                @click="navigateToOrder('next')"
              >
                Next
              </touch-optimized-button>
            </div>
              <touch-optimized-button @click="openNavigateByOrderIdModal">Par ComID
              </touch-optimized-button>
            <touch-optimized-button
              @click="navigateToOrderList()"
            >
              Rétour Liste
            </touch-optimized-button>

            <scanner-listener @scan="onScan"/>

            <div class="h-0.5 w-full bg-gray-400"></div>

            <order-fulfilment-manual-barcode-entry/>

            <order-fulfilment-packaging-item-list/>

            <div class="h-0.5 w-full bg-gray-400"></div>

            <order-fulfilment-attribute-fulfilment-number v-if="getDeliveryId"/>

            <order-fulfilment-box-quantity-management v-if="getDeliveryId"/>

              <div class="flex items-center gap-x-2">
                  <span class="text-2xl font-bold">ComID: </span>
                  <span class="align-center text-blue-500 text-5xl font-semibold">{{ getOrderId }}</span>
              </div>
          </div>
        </div>
      </template>
    </fulfilment-layout-base>
  </div>
</template>

<script>
import FulfilmentLayoutBase
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/LayoutBase/FulfilmentLayoutBase";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import ScannerListener from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/scanner/ScannerListener";
import KeyPad from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/keypad/KeyPad";
import OrderFulfilmentListDepartmentButton from "../list/buttons/OrderFulfilmentListDepartmentButton";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import {mapActions, mapGetters} from "vuex";
import OrderFulfilmentItemList from "./OrderFulfilmentItemList";
import OrderFulfilmentManualBarcodeEntry from "./Sub/OrderFulfilmentManualBarcodeEntry";
import OrderFulfilmentBoxQuantityManagement from "./Sub/OrderFulfilmentBoxQuantityManagement";
import OrderFulfilmentAttributeFulfilmentNumber from "./Sub/OrderFulfilmentAttributeFulfilmentNumber";
import OrderFulfilmentInstructionList
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/order/Sub/OrderFulfilmentInstructionList";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import DeliveryLabelHtml from "@/components/pages/TouchInterface/Packaging/Fulfilment/order/DeliveryLabelHtml";
import OrderFulfilmentStatusIndicator from "@/components/pages/Orders/EditOrder/Info/OrderFulfilmentStatusIndicator";
import TimeSlotAsString from "@/components/global/TimeSlots/TimeSlotAsString";
import OrderFulfilmentPackagingItemList
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/order/OrderFulfilmentPackagingItemList";
import OrderLabelHtml from "@/components/pages/TouchInterface/Packaging/Fulfilment/order/OrderLabelHtml.vue";

export default {
  name: "OrderFulfilmentDetails",
  components: {
    OrderLabelHtml,
    OrderFulfilmentPackagingItemList,
    TimeSlotAsString,
    OrderFulfilmentStatusIndicator,
    DeliveryLabelHtml,
    TouchOptimizedModalButton,
    NumberInputElement,
    OrderFulfilmentInstructionList,
    OrderFulfilmentAttributeFulfilmentNumber,
    OrderFulfilmentBoxQuantityManagement,
    OrderFulfilmentManualBarcodeEntry,
    OrderFulfilmentItemList,
    TouchOptimizedButton,
    OrderFulfilmentListDepartmentButton,
    KeyPad,
    ScannerListener,
    TouchOptimizedModal,
    FulfilmentLayoutBase
  },
  data: () => ({
    number: null,
    navigateToFulfilmentNumber: null,
    navigateToOrderId: null,
  }),
  created() {
    this.fetchOrder();
  },
  watch: {
    getOrderIdFromParams: function () {
      this.fetchOrder();
    },
    getFilters() {
      this.fetchOrder();
    }

  },
  methods: {
    ...mapActions('fulfilment', ['fulfillItemByBarcode']),
    fetchOrder() {
      const id = this.$route.params.id;

      let filters = [];

      if (this.getSelectedDepartmentId) {
        filters.push({
          filterKey: "departmentId",
          filterValue: this.getSelectedDepartmentId,
        });
      }

      return this.$store.dispatch('fulfilment/fetchOrder', {orderId: id, filters: filters});
    },
    onScan({quantity, code}) {
      this.fulfillItemByBarcode({
        barCode: code,
        quantity: quantity
      });
    },
    navigateToOrder(direction) {
      switch (direction) {
        case "previous":
          if (this.getPreviousFulfilmentOrderId) {
            this.$router.push({
              name: 'touchInterface.fulfilment.orders.details',
              params: {
                id: this.getPreviousFulfilmentOrderId
              }
            });
          }
          break;
        case "next":
          if (this.getNextFulfilmentOrderId) {
            this.$router.push({
              name: 'touchInterface.fulfilment.orders.details',
              params: {
                id: this.getNextFulfilmentOrderId
              }
            });
          }
          break;
      }
    },
    navigateToOrderList() {
      this.$router.push({
        name: 'touchInterface.fulfilment.orders'
      });
    },
    openNavigateByFulfilmentNumberModal() {
      this.navigateToFulfilmentNumber = null;
      this.$refs.navigateByFulfilmentNumberModal.open();
      this.$nextTick(() => this.$refs.navigateToFulfilmentNumberInput.focus());
    },

    openNavigateByOrderIdModal() {
      this.navigateToOrderId = null;
      this.$refs.navigateByOrderIdModal.open();
      this.$nextTick(() => this.$refs.navigateToOrderIdInput.focus());
    },
    navigateToOrderByFulfilmentNumber() {
      if (this.navigateToFulfilmentNumber === null)
        return false;

      let order = this.$store.getters['fulfilment/getFulfilmentOrderList'].find(o => o.delivery?.fulfilmentNumber === this.navigateToFulfilmentNumber);
      this.$refs.navigateByFulfilmentNumberModal.close();
      if (!order) {
        return this.$showErrorDialog('Pas de commande avec numéro BAC indiqué trouvée.');
      }

      this.$router.push({
        name: 'touchInterface.fulfilment.orders.details',
        params: {
          id: order.id
        }
      });
    },

    navigateToOrderById() {
      if (this.navigateToOrderId === null)
        return false;

      let order = this.$store.getters['fulfilment/getFulfilmentOrderList'].find(o => o.id === this.navigateToOrderId);
      this.$refs.navigateByOrderIdModal.close();
      if (!order) {
        return this.$showErrorDialog('Pas de commande avec numéro indiqué trouvée.');
      }

      this.$router.push({
        name: 'touchInterface.fulfilment.orders.details',
        params: {
          id: order.id
        }
      });
    },
    printDeliveryLabel() {
      this.$refs.deliveryLabelHtml.fetchLabelHTML();
      window.print();
    },
    printOrderLabel() {
      this.$refs.orderLabelHtml.fetchLabelHTML();
      window.print();
    }
  },
  computed: {
    ...mapGetters('fulfilmentFilters', [
      'getSelectedDepartmentId',
    ]),
    ...mapGetters('fulfilment', [
        'getOrder',
        'getOrderId',
        'getOrderDeliveryTimeStartDate',
        'getOrderDeliveryTimeEndDate',
        'getOrderFulfilmentNumber',
        'getDeliveryId',
        'getNextFulfilmentOrderId',
        'getPreviousFulfilmentOrderId',
        'getOrderFulfilmentStatus',
        'getOrderDeliveryZone'
      ]
    ),
    getOrderIdFromParams() {
      return this.$route.params.id;
    },

    // Only used so that we only need one watcher
    getFilters() {
      return `${this.getSelectedDepartmentId}`;
    }
  },
}
</script>

