import apiService from './Base/BaseRepository';

const url = '/packages';

export default {
  /**
   * Fetches all packages.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get(withLoadingIndicator = false) {
    return await apiService.get(`${url}/`, {
      showLoadingIndicator: withLoadingIndicator
    });
  }
}