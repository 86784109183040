<template>
  <div class="flex flex-col md:flex-row flex-wrap justify-between">
    <div>
      <pagination-page-size-selector
        :table-identifier="tableIdentifier"
        @change="onPageChange"
      />
    </div>

    <div>
      <pagination-page-selector
        :table-identifier="tableIdentifier"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import PaginationPageSizeSelector from "./includes/PaginationPageSizeSelector.vue";
import PaginationPageSelector
  from "@/components/listTable/includes/controls/pagination/includes/PaginationPageSelector.vue";

export default {
  name: "ListTablePagination",
  components: {PaginationPageSelector, PaginationPageSizeSelector},
  props: {
    tableIdentifier: {
      type: String,
      required: true
    },
  },
  methods: {
    onPageChange() {
      this.$emit('change');
    }
  },
}
</script>


