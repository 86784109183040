<template>
  <div
    :class="[isSelected ? 'border-luxcaddy' : 'border-transparent']"
    class="rounded-md p-2 bg-white border hover:bg-luxcaddy-100 select-none">

    <div class="flex gap-x-16 justify-between">
      <div class="font-bold cursor-pointer" @click="toggleSelection">
        {{ option.name }}<br>
        <span class="text-luxcaddy font-bold">{{ option.priceWithVat | centsToEuroString }}</span>
      </div>

      <div v-if="isSelected">
                <span v-if="option.isComputedQuantity">
                    <!-- Computed and wont be changed -->
                    <select-element
                      v-model="option.quantity"
                      :enable-search="false"
                      :occupy-validation-space="false"
                      :options="[option.quantity]"
                      disabled/>
                </span>
        <span v-else>
                    <select-element
                      v-model="quantity"
                      :enable-search="false"
                      :occupy-validation-space="false"
                      :options="getPossibleQuantities"/>
                </span>
      </div>
    </div>
  </div>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "PackageSelectionOption",
  components: {SelectElement},
  props: {
    option: {
      type: Object,
      required: true
    },
    groupName: {
      type: String,
      required: true
    }
  },
  computed: {
    getPossibleQuantities() {
      return [...Array(this.option.maximumQuantity - 1).keys()];
    },

    isSelected() {
      return this.$store.getters['cart/getSelectedPackagingOptions'].find(o => o.id === this.option.id);
    },
    quantity: {
      get() {
        return this.$store.getters['cart/getSelectedPackagingOptions'].find(o => o.id === this.option.id)?.quantity || 0;
      },
      set(value) {
        return this.$store.commit('cart/setSelectedPackagingQuantity', {
          id: this.option.id,
          quantity: value
        });
      }
    }
  },
  methods: {
    toggleSelection() {
      const quantity = this.option.isComputedQuantity ? this.option.quantity : undefined

      this.$store.commit('cart/selectOrUnselectPackaging', {
        id: this.option.id,
        quantity
      });
    }
  },
}
</script>

