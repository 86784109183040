<template>
  <form-wrapper
    ref="form"
    :show-changed="true"
    :submit-button-label="isCreateMode ? 'Créer' : 'Sauvegarder'"
    @submit="submitCategory"
  >
    <form-grid class="grid-cols-1">
      <text-input-element
        v-model="name"
        label="Nom"
      />

      <checkbox-element
        v-model="hideOutOfStock"
        label="Cacher les produits sans stock"
      />
    </form-grid>
  </form-wrapper>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import FormGrid from "@/components/elements/forms/FormGrid";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import CategoryRepository from "@/repositories/CategoryRepository";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";

export default {
  name: "CategoryCreateOrUpdateForm",
  components: {CheckboxElement, TextInputElement, FormGrid, FormWrapper},
  data: () => ({
    name: '',
    hideOutOfStock: false,
  }),
  props: {
    categoryId: {
      type: Number,
      required: false
    },
    mode: {
      type: String,
      default: 'create'
    },
    parentId: {
      type: Number,
      required: false
    }
  },
  mounted() {
    this.fetchCategoryInUpdateMode();
  },
  watch: {
    name: function (val) {
      this.$emit('updateName', val);
    }
  },
  methods: {
    fetchCategoryInUpdateMode() {
      if (this.isCreateMode)
        return false;

      CategoryRepository.getSingle(this.categoryId).then((res) => {
        this.name = res.data.data.name;
        this.hideOutOfStock = res.data.data.hideOutOfStock;
        this.$nextTick(() => this.$refs.form.resetForm());
      });
    },
    resetForm() {
      this.name = '';
    },
    createCategory() {
      if (!this.getCurrentCategoryType) {
        console.error("no category type set.");
        return false;
      }

      return CategoryRepository.createCategory({
        parentId: this.parentId,
        name: this.name,
        sortIndex: 100,
        type: this.getCurrentCategoryType,
        isVirtual: this.getCurrentCategoryIsVirtual,
        hideOutOfStock: this.hideOutOfStock
      }).then(() => {
        this.$sendSuccessToast('Catégorie crée!');
        this.resetForm();
        this.$emit('done');
      });
    },
    updateCategory() {
      return CategoryRepository.updateCategory(this.categoryId, {
        name: this.name,
        hideOutOfStock: this.hideOutOfStock
      }).then(() => {
        this.$sendSuccessToast('Catégorie modifiée!');
        this.$refs.form.resetForm();
      });
    },
    submitCategory(callback) {
      if (this.isCreateMode) {
        return this.createCategory().finally(() => callback());
      }
      return this.updateCategory().finally(() => callback());
    }
  },
  computed: {
    isCreateMode() {
      return this.mode === 'create';
    },
    getCurrentCategoryType() {
      return this.$store.getters['categories/getCurrentCategoryType'];
    },

    getCurrentCategoryIsVirtual() {
      return this.$store.getters['categories/getCurrentCategoryIsVirtual'];
    }
  }
}
</script>

