import auth from "../middlewares/authMiddleware";
import ShowPostalCodes from "../../components/pages/PostalCode/ShowPostalCodes";
import CreatePostalCodes from "../../components/pages/PostalCode/Create/CreatePostalCodes";

export default [
  {
    path: '/postalCodes',
    name: 'postalCodes.list',
    component: ShowPostalCodes,
    meta: {
      middleware: [auth],
      title: 'Listes des code postaux',
      permissions: ['POSTAL_CODE_READ']
    }
  },
  {
    path: '/postalCodes',
    name: 'postalCodes.create',
    component: CreatePostalCodes,
    meta: {
      middleware: [auth],
      title: 'Créer un code postal',
      breadcrumbs: ['postalCodes.list'],
      permissions: ['POSTAL_CODE_WRITE']
    }
  },
]