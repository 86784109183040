<template>
  <div>
    <form-wrapper ref="form" @submit="saveTags">
      <div class="grid gap-6 grid-cols-1 md:grid-cols-1 xl:grid-cols-4">
        <select-tag-element v-model="selectedLabels" :tags="availableLabels" label="Labelles" multi/>
        <select-tag-element v-model="selectedAllergens" :tags="availableAllergens" label="Allèrgenes" multi/>
        <select-tag-element v-model="selectedMiscs" :tags="availableMiscs" label="Misc" multi/>
        <select-tag-element v-model="selectedKirschs" :tags="availableKirschs" label="Kirsch" multi/>
        <select-tag-element v-model="selectedTracesOf" :tags="availableTracesOf" label="Peut contenir traces"
                            multi/>
      </div>
    </form-wrapper>
  </div>
</template>

<script>
import TagsRepository from "../../../../../repositories/TagsRepository";
import FormWrapper from "../../../../elements/forms/FormWrapper";
import SelectTagElement from "../../../../elements/forms/elements/Tags/SelectTagElement";
import ProductRepository from "../../../../../repositories/ProductRepository";

export default {
  name: "ManageProductTags",
  components: {SelectTagElement, FormWrapper},
  props: {
    productId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    availableLabels: [],
    availableAllergens: [],
    availableMiscs: [],
    availableKirschs: [],
    availableTracesOf: [],

    selectedLabels: [],
    selectedAllergens: [],
    selectedMiscs: [],
    selectedKirschs: [],
    selectedTracesOf: [],

  }),
  created() {
    Promise.all([
      this.getTags(),
      this.getLinkedTags(),
    ]).then(() => {
      this.$refs.form.resetForm();
    })
  },
  methods: {
    /**
     * Fetches the tags that are available to set.
     */
    getTags() {
      return TagsRepository.getProductTags().then((res) => {
        this.availableLabels = res.data.data.filter(t => t.type === "label");
        this.availableAllergens = res.data.data.filter(t => t.type === "allergen");
        this.availableMiscs = res.data.data.filter(t => t.type === "misc");
        this.availableKirschs = res.data.data.filter(t => t.type === "kirsch");
        this.availableTracesOf = res.data.data.filter(t => t.type === "tracesOf");
      })
    },
    /**
     * Fetches the tags that are already set for this product.
     */
    getLinkedTags() {
      return ProductRepository.tags.getLinkedTags(this.productId).then((res) => {
        this.selectedLabels = res.data.data.filter(t => t.type === "label").flatMap(t => t.id);
        this.selectedAllergens = res.data.data.filter(t => t.type === "allergen").flatMap(t => t.id);
        this.selectedMiscs = res.data.data.filter(t => t.type === "misc").flatMap(t => t.id);
        this.selectedKirschs = res.data.data.filter(t => t.type === "kirsch").flatMap(t => t.id);
        this.selectedTracesOf = res.data.data.filter(t => t.type === "tracesOf").flatMap(t => t.id);
      })
    },
    saveTags(callback) {
      ProductRepository
        .tags
        .setLinkedTags(this.productId, this.getSelectedTagIds)
        .then(() => {
          this.$sendSuccessToast("Les tags ont été modifiées.");
        }).finally(() => callback());
    },
  },
  computed: {
    getSelectedTagIds() {
      return []
        .concat(this.selectedLabels)
        .concat(this.selectedAllergens)
        .concat(this.selectedMiscs)
        .concat(this.selectedKirschs)
        .concat(this.selectedTracesOf)
        .flatMap(tagId => tagId);
    }
  }
}
</script>

