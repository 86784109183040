import auth from "../middlewares/authMiddleware";
import ShowRoles from "@/components/pages/Roles/ShowRoles.vue";
import EditRole from "@/components/pages/Roles/Edit/EditRole.vue";
import CreateRole from "@/components/pages/Roles/Create/CreateRole.vue";

export default [
  {
    path: '/roles/list',
    name: 'roles.list',
    component: ShowRoles,
    meta: {
      middleware: [auth],
      title: 'Liste des rôles',
      permissions: ['ROLES_MANAGE']
    }
  },
  {
    path: '/roles/create',
    name: 'roles.create',
    component: CreateRole,
    meta: {
      middleware: [auth],
      title: 'Ajouter une rôle',
      breadcrumbs: ['roles.list'],
      permissions: ['ROLES_MANAGE']
    }
  },
  {
    path: '/roles/:roleId',
    name: 'roles.edit',
    component: EditRole,
    meta: {
      middleware: [auth],
      title: 'Modifier une rôle',
      breadcrumbs: ['roles.list'],
      permissions: ['ROLES_MANAGE']
    }
  },
];