<template>
  <div class="bg-gray-700 h-full pt-4 lg:flex lg:flex-col lg:items-center lg:justify-center">
    <dashboard-notification-list
      class="md:w-2/3 3xl:w-1/2"
      on-touch-interface
    />
  </div>
</template>

<script>
import DashboardNotificationList
  from "@/components/layouts/dashboard/partials/notifications/DashboardNotificationList.vue";

export default {
  name: "TouchInterfaceNotificationList",
  components: {DashboardNotificationList}
}
</script>