import auth from "../middlewares/authMiddleware";
import GlobalSellingStats from "../../components/pages/Stats/GlobalSellingStats";
import NewCustomerGraph from "../../components/pages/Stats/NewCustomersGraph";
import ProductSalesStats from "@/components/pages/Stats/ProductSalesStats";
import SupplierSalesStats from "../../components/pages/Stats/SupplierSalesStats";
import SupplierSalesStatsDetails from "../../components/pages/Stats/SupplierSalesStatsDetails";

export default [
  {
    path: '/stats/global-selling',
    name: 'stats.global',
    component: GlobalSellingStats,
    meta: {
      middleware: [auth],
      title: 'Commande Chiffre',
      permissions: ['ORDER_STATS_READ']
    }
  },
  {
    path: '/stats/new-customers',
    name: 'stats.new-customers',
    component: NewCustomerGraph,
    meta: {
      middleware: [auth],
      title: 'Nouveaux clients',
      permissions: ['ACCESS_MEMBER_VIEW']
    }
  },
  {
    path: '/stats/product-sales',
    name: 'stats.product-sales',
    component: ProductSalesStats,
    meta: {
      middleware: [auth],
      title: 'Statistiques ventes',
      permissions: ['ORDER_STATS_READ']
    }
  },
  {
    path: '/stats/supplier-sales',
    name: 'stats.supplier-sales',
    component: SupplierSalesStats,
    meta: {
      middleware: [auth],
      title: 'Statistiques fournisseurs',
      permissions: ['ORDER_STATS_READ']
    }
  },
  {
    path: '/stats/supplier-sales/:supplier',
    name: 'stats.supplier-sales-details',
    component: SupplierSalesStatsDetails,
    meta: {
      middleware: [auth],
      title: 'Statistiques fournisseurs details',
      breadcrumbs: ['stats.supplier-sales'],
      permissions: ['ORDER_STATS_READ']
    }
  },
];