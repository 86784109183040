<template>
  <status-indicator
    :small="small"
    :type="getType"
  >
    {{ getText }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "OrderStatusIndicator",
  components: {StatusIndicator},
  props: {
    status: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getType() {
      switch (this.status) {
        case "canceled":
          return "danger"
        case "initialized":
          return "light";
        case "confirmed":
          return "primary"
        case "draft":
          return "light"
        default:
          return "primary"
      }
    },
    getText() {
      return this.$luxcaddyConfig('order.availableStatuses').find(l => l.value === this.status)?.label || this.status;
    }
  },
}
</script>

<style scoped>

</style>