import ProductRepository from "@/repositories/ProductRepository";

export default {
  selectValues: product => ({id: product.id}),
  selectValuesFromId: productId => ({id: productId}),
  batchActions: [
    {
      label: "Publier",
      action: (callBack, items) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "publish",
          items
        ).finally(() => callBack());
      }
    },
    {
      label: "Dépublier",
      additionalFields: [
        {
          label: "Forcer la visibilité des produit sur shop?",
          type: "checkbox",
          param: "forceShopVisibility",
          props: {
              'with-time': true
          }
        },
      ],
      action: (callBack, selectedRows,additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "unPublish",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Discontinuer",
      action: (callBack, selectedRows) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "discontinue",
          selectedRows
        ).finally(() => callBack());
      }
    },
    {
      label: "Modifier date de publication",
      additionalFields: [
        {
          label: "Date de publication",
          type: "date",
          param: "publishDate",
          validationRules: 'required',
          props: {
            'with-time': true
          }
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "edit",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Modifier Étagère",
      additionalFields: [
        {
          label: "Étagère",
          type: "racks",
          param: "rackNumber",
          validationRules: 'required',
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "edit",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },

    {
      label: "Modifier prix de vente",
      additionalFields: [
        {
          label: "Nouveau prix de vente",
          type: "currency",
          param: "sellingPrice",
          validationRules: 'required|min_value:0',
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "edit",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Modifier prix de vente selon marge",
      additionalFields: [
        {
          label: "Marge",
          type: "number",
          param: "sellingPriceByMargin",
          validationRules: 'required|min_value:0|max_value:100',
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "edit",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Modifier prix d'achat unitaire",
      additionalFields: [
        {
          label: "Nouveau prix d'achat unitaire'",
          type: "currency",
          param: "unitBuyingPrice",
          validationRules: 'required|min_value:0',
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "edit",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Editer tags",
      additionalFields: [
        {
          label: "Méthode",
          type: "select",
          param: "method",
          validationRules: 'required',
          props: {
            enableSearch: false,
            options: [
              {label: 'ajouter', value: 'add'},
              {label: 'supprimer', value: 'remove'},
              {label: 'définir', value: 'set'}
            ]
          }
        },
        {
          label: "Tags",
          type: "productTag",
          param: "tagIds",
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "editTags",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },

    {
      label: "Créer des étiquettes de rayon",
      additionalFields: [
        {
          label: "Taille",
          type: "size",
          param: "size",
          validationRules: 'required',
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "printShelfLabel",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Importer ingrédients par OpenFoodFact",
      action: (callBack, selectedRows) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "updateIngredientsFromExternalApi",
          selectedRows
        ).finally(() => callBack());
      }
    },
    {
      label: "Ajouter à la catégorie",
      additionalFields: [
        {
          label: "Categorie",
          type: "productCategories",
          param: "categoryId",
          validationRules: 'required',
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "addToCategory",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Créer promo",
      additionalFields: [
        {
          label: "Name",
          type: "text",
          param: "title",
        },
        {
          label: "Remise",
          type: "number",
          param: "value",
          validationRules: 'required|min_value:0',
        },
        {
          label: "Stock réservé",
          type: "number",
          param: "reservedStock"
        },
        {
          label: "Date & heure debut",
          type: "date",
          param: "startDate",
          props: {
            'with-time': true
          }
        },
        {
          label: "Date & heure fin",
          type: "date",
          param: "endDate",
          props: {
            'with-time': true
          }
        },
        {
          label: "Date courte?",
          type: "checkbox",
          param: "isShortDate"
        }
      ],
      action: (callBack, selectedRows, additionalParams) => {
        additionalParams.value = additionalParams.value * 100;
        return ProductRepository.catalogComparison.executeBatchAction(
          "createDiscount",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Créer promo 1+1",
      additionalFields: [
        {
          label: "Name",
          type: "text",
          param: "title",
        },
        {
          label: "Stock réservé",
          type: "number",
          param: "reservedStock"
        },
        {
          label: "Date & heure debut",
          type: "date",
          param: "startDate",
          props: {
            'with-time': true
          }
        },
        {
          label: "Date & heure fin",
          type: "date",
          param: "endDate",
          props: {
            'with-time': true
          }
        },
        {
          label: "Date courte?",
          type: "checkbox",
          param: "isShortDate"
        },
        {
          label: "Client doit confirmer?",
          type: "checkbox",
          param: "needsConfirmation"
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        additionalParams.value = 5000;
        additionalParams.applyPerTranche = 2;
        additionalParams.isXPlus1Campaign = true;
        return ProductRepository.catalogComparison.executeBatchAction(
          "createDiscount",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Créer promo 2+1",
      additionalFields: [
        {
          label: "Name",
          type: "text",
          param: "title",
        },
        {
          label: "Stock réservé",
          type: "number",
          param: "reservedStock"
        },
        {
          label: "Date & heure debut",
          type: "date",
          param: "startDate",
          props: {
            'with-time': true,
          }
        },
        {
          label: "Date & heure fin",
          type: "date",
          param: "endDate",
          props: {
            'with-time': true,
          }
        },
        {
          label: "Date courte?",
          type: "checkbox",
          param: "isShortDate"
        },
        {
          label: "Client doit confirmer?",
          type: "checkbox",
          param: "needsConfirmation"
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        additionalParams.value = 3333;
        additionalParams.applyPerTranche = 3;
        additionalParams.isXPlus1Campaign = true;
        return ProductRepository.catalogComparison.executeBatchAction(
          "createDiscount",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Créer promo 3+1",
      additionalFields: [
        {
          label: "Name",
          type: "text",
          param: "title",
        },
        {
          label: "Stock réservé",
          type: "number",
          param: "reservedStock"
        },
        {
          label: "Date & heure debut",
          type: "date",
          param: "startDate",
          props: {
            'with-time': true,
          }
        },
        {
          label: "Date & heure fin",
          type: "date",
          param: "endDate",
          props: {
            'with-time': true,
          }
        },
        {
          label: "Date courte?",
          type: "checkbox",
          param: "isShortDate"
        },
        {
          label: "Client doit confirmer?",
          type: "checkbox",
          param: "needsConfirmation"
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        additionalParams.value = 2500;
        additionalParams.applyPerTranche = 4;
        additionalParams.isXPlus1Campaign = true;
        return ProductRepository.catalogComparison.executeBatchAction(
          "createDiscount",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Créer promo 4+1",
      additionalFields: [
        {
          label: "Name",
          type: "text",
          param: "title",
        },
        {
          label: "Stock réservé",
          type: "number",
          param: "reservedStock"
        },
        {
          label: "Date & heure debut",
          type: "date",
          param: "startDate",
          props: {
            'with-time': true,
          }
        },
        {
          label: "Date & heure fin",
          type: "date",
          param: "endDate",
          props: {
            'with-time': true,
          }
        },
        {
          label: "Date courte?",
          type: "checkbox",
          param: "isShortDate"
        },
        {
          label: "Client doit confirmer?",
          type: "checkbox",
          param: "needsConfirmation"
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        additionalParams.value = 2000;
        additionalParams.applyPerTranche = 5;
        additionalParams.isXPlus1Campaign = true;
        return ProductRepository.catalogComparison.executeBatchAction(
          "createDiscount",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      }
    },
    {
      label: "Créer promo 6+1",
      additionalFields: [
        {
          label: "Name",
          type: "text",
          param: "title",
        },
        {
          label: "Stock réservé",
          type: "number",
          param: "reservedStock"
        },
        {
          label: "Date & heure debut",
          type: "date",
          param: "startDate",
          props: {
            'with-time': true,
          }
        },
        {
          label: "Date & heure fin",
          type: "date",
          param: "endDate",
          props: {
            'with-time': true,
          }
        },
        {
          label: "Date courte?",
          type: "checkbox",
          param: "isShortDate"
        },
        {
          label: "Client doit confirmer?",
          type: "checkbox",
          param: "needsConfirmation"
        },
      ],
      action: (callBack, selectedRows, additionalParams) => {
        additionalParams.value = 1428;
        additionalParams.applyPerTranche = 7;
        additionalParams.isXPlus1Campaign = true;
        return ProductRepository.catalogComparison.executeBatchAction(
          "createDiscount",
          selectedRows,
          additionalParams
        ).finally(() => callBack());
      },

    },
    {
      label: "Terminer campagnes du produits",
      action: (callBack, items) => {
        return ProductRepository.catalogComparison.executeBatchAction(
          "endProductCampaigns",
          items
        ).finally(() => callBack());
      }
    },
  ]
};