<template>
  <div>
    <div
      v-if="hasLoadedProduct"
      class="flex gap-x-4 justify-between items-center mb-6"
    >
      <div class="flex gap-x-4 items-center">
        <page-title
          :document-page-title="`Produit | ID: ${getProductId} | ${getProductName}`"
          without-margin
        >
          #{{ getProductId }} | {{ getProductName }}
        </page-title>

        <product-publication-status
          :is-published="isProductPublished"
          :is-visibility-forced="isForceShopVisibility"
        />

        <comment-count-badge
          :comment-count="getProductCommentsCount"
          :model-id="getProductId"
          model="Product"
          @change="reloadProduct"
        />
      </div>

      <div class="flex gap-x-4">
        <batch-actions-single-model
          :batch-actions="ProductBatchActions.batchActions"
          :selected-value="ProductBatchActions.selectValuesFromId(getProductId)"
          @executed="reloadProduct"
        />
      </div>
    </div>

    <div class="bg-white border p-4 mb-4 flex gap-x-8 items-center">
      <span class="font-bold">ID Produit:</span>
      <product-quick-action-modal
        :id="getProductId"
      />
    </div>

    <card>
      <card-tab id="productTab" key="productTab" ref="productTab" icon="cube" title="Produit">
        <create-or-update-product-form
          ref="editProductForm"
          :product-id="getProductId"
          mode="update"
        />
      </card-tab>

      <card-tab id="imageTab" key="imageTab" ref="imageTab" icon="images" title="Images">
        <manage-product-images :product-id="getProductId"></manage-product-images>
      </card-tab>

      <card-tab id="localizationTab" ref="localizationTab" icon="globe" title="Traduction">
        <update-product-localization-form :product-id="getProductId"/>
      </card-tab>

      <card-tab id="tagTab" ref="tagTab" icon="tags" title="Tags">
        <manage-product-tags :product-id="getProductId"/>
      </card-tab>

      <card-tab id="categoryTab" ref="categoryTab" icon="stream" title="Categories">
        <manage-product-categories :product-id="getProductId"/>
      </card-tab>
      <card-tab id="virtualCategoryTab" ref="virtualCategoryTab" icon="stream" title="Virtuel Categories">
        <manage-product-categories :product-id="getProductId" is-virtual/>
      </card-tab>

      <card-tab id="barcodeTab" ref="barcodeTab" icon="barcode" title="Code barres">
        <manage-product-barcodes :product-id="getProductId"/>
      </card-tab>

      <card-tab id="stockTab" ref="stockTab" icon="box" title="Stock">
        <manage-product-stock :product-id="getProductId"/>
      </card-tab>

      <card-tab id="buyingPriceHistoryTab" ref="buyingPriceHistoryTab" icon="history"
                title="Historique Prix d'Achat">
        <product-buying-price-history
          :product-id="getProductId"
        />
      </card-tab>

      <card-tab id="orderStatsTab" ref="orderStatsTab" icon="history" title="Statistiques de vente">
        <order-item-stats-graph
          :default-display-option="0"
          :filters="[
                  {
                    filterKey: 'orderableId',
                    filterValue: this.getProductId,
                  } ,
                  {
                    filterKey: 'type',
                    filterValue: 'Product',
                  }
                ]"
          :graph-end="getGraphEnd"
          :graph-start="getGraphStart"
          groupBy="month"
        />
      </card-tab>

      <card-tab id="logTab" ref="logTab" icon="file-alt" title="Logs">
        <show-logs-table
          :default-loggable-id="getProductId"
          default-loggable-type="Product"
        />
      </card-tab>
    </card>
  </div>
</template>

<script>
import CreateOrUpdateProductForm from "../Forms/CreateOrUpdateProductForm";
import Card from "../../../elements/cards/Card";
import CardTab from "../../../elements/cards/tabs/CardTab";
import UpdateProductLocalizationForm from "../Forms/Localization/UpdateProductLocalizationForm";
import ManageProductBarcodes from "./Barcodes/ManageProductBarcodes";
import ManageProductStock from "./Stock/ManageProductStock";
import PageTitle from "../../../elements/pages/PageTitle";
import ManageProductTags from "./Tags/ManageProductTags";
import ManageProductImages from "../Forms/Images/ManageProductImages";
import ShowLogsTable from "@/components/pages/Logs/ShowLogsTable";
import ManageProductCategories from "@/components/pages/Products/EditProduct/Categories/ManageProductCategories";
import ProductBuyingPriceHistory
  from "@/components/pages/Products/EditProduct/BuyingPriceHistory/ProductBuyingPriceHistory";
import OrderItemStatsGraph from "@/components/global/Graphs/OrderItemStatsGraph";
import ProductPublicationStatus from "@/components/pages/Products/EditProduct/Status/ProductPublicationStatus";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal.vue";
import BatchActionsSingleModel from "@/batchAction/components/BatchActionsSingleModel.vue";
import ProductBatchActions from "@/batchAction/productBatchActions";
import CommentCountBadge from "@/components/global/Comments/CommentCountBadge.vue";
export default {
  name: "EditProduct",
  components: {
    CommentCountBadge,
    BatchActionsSingleModel,
    ProductQuickActionModal,
    ProductPublicationStatus,
    OrderItemStatsGraph,
    ProductBuyingPriceHistory,
    ManageProductCategories,
    ShowLogsTable,
    ManageProductImages,
    ManageProductTags,
    PageTitle,
    ManageProductStock,
    ManageProductBarcodes,
    UpdateProductLocalizationForm,
    CardTab,
    CreateOrUpdateProductForm,
    Card
  },
  computed: {
    ProductBatchActions() {
      return ProductBatchActions;
    },
    getProductId() {
      return parseInt(this.$route.params.product);
    },
    getProductName() {
      return this.$store.getters['products/getProductName'] || '[...]';
    },
    isProductPublished() {
      return this.$store.getters['products/isProductPublished'];
    },
    isForceShopVisibility() {
      return this.$store.getters['products/isForceShopVisibility'];
    },
    getProductCommentsCount() {
      return this.$store.getters['products/getProductCommentsCount'];
    },
    hasLoadedProduct() {
      return this.$store.getters['products/hasLoadedProduct'];
    },
    getGraphStart() {
      return new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1);
    },
    getGraphEnd() {
      let date = (new Date(new Date().getFullYear(), new Date().getMonth(), 0));
      date.setHours(23, 59, 59, 999);
      return date;
    }
  },
  beforeDestroy() {
    this.$store.commit('products/setProduct', null);
  },
  methods: {
    reloadProduct() {
      if (this.$refs.editProductForm) {
        this.$refs.editProductForm.getProduct();
      }
    },
  },
}
</script>

