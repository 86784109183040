<template>
  <driver-my-routes-route-box
    @click="navigateToRoute"
  >
    <span class="font-bold">Départ: {{ getDepartureString }}</span>
    <div class="mt-2 ">
      <delivery-quantity
        :quantity="route.totalDeliveries"
        class="w-16 inline-block mr-2"
      />
      <box-quantity
        :quantity="route.totalBoxQuantity"
        class="w-16 inline-block"
      />
    </div>
  </driver-my-routes-route-box>
</template>

<script>
import DriverMyRoutesRouteBox
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/Components/DriverMyRoutesRouteBox";
import BoxQuantity from "@/components/global/BoxQuantity/BoxQuantity";
import DeliveryQuantity from "@/components/global/DeliveryQuantity/DeliveryQuantity";

export default {
  name: "DriverMyRoutesRouteItem",
  components: {DeliveryQuantity, BoxQuantity, DriverMyRoutesRouteBox},
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  computed: {
    getDepartureString() {
      return this.$date(this.route.departure).format('HH:mm - DD.MM.YYYY');
    }
  },
  methods: {
    navigateToRoute() {
      this.$router.push({
        name: 'touchInterface.delivery.fulfilment.myroutes.route.index',
        params: {
          route: this.route.id
        },
      })
    }
  },
}
</script>
