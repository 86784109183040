<template>
  <div>
    <page-title>
      Créer vidange
    </page-title>

    <card>
      <create-or-update-deposit-form mode="create"></create-or-update-deposit-form>
    </card>
  </div>
</template>

<script>
import CreateOrUpdateDepositForm from "../Forms/CreateOrUpdateDepositForm";
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";

export default {
  name: "CreateDeposit",
  components: {Card, PageTitle, CreateOrUpdateDepositForm},
}
</script>

