<template>
  <div>
    <button-element
      :disabled="!canConfirm"
      type="dark"
      @click="confirmOrder()"
    >
      <fa-icon class="mr-2" fixed-width icon="check"/>
      Confirm
    </button-element>

  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import OrderRepository from "@/repositories/OrderRepository";
import {mapGetters} from "vuex";

export default {
  name: "ConfirmOrderButton",
  components: {ButtonElement},
  methods: {
    confirmOrder() {
      OrderRepository
        .confirmOrder(this.getOrderId)
        .then(() => {
          this.$sendSuccessToast('La commande a été confirmé.');
          this.$store.dispatch('order/refreshOrder');
        })
    }
  },
  computed: {
    ...mapGetters('order', [
      'getOrderId', 'canConfirm'
    ])
  },
}
</script>

<style scoped>

</style>