<template>
  <list-table
    :columns="columns"
    :create-route="{
        name: 'postalCodes.create'
      }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="postalCodesTable"
  />

</template>

<script>
import DeliveryPostalCodeRepository from "../../../repositories/DeliveryPostalCodeRepository";
import ListTable from "../../listTable/ListTable";
import {FILTER_TEXT} from "../../listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowPostalCodes",
  components: {ListTable},
  data() {
    return {
      title: 'Codes Postaux',
      repositoryFunc: DeliveryPostalCodeRepository.getPaginated,
      filters: [
        {
          caption: "Code postal",
          filterKey: "postalCode",
          type: FILTER_TEXT,
          forceIsVisible: true,
        },
        {
          caption: "Zone",
          filterKey: "zone",
          type: FILTER_TEXT,
          forceIsVisible: true,
        },
      ],
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'delete',
              hint: 'Supprimer code postal',
              confirmation: {
                title: 'Supprimer le code postal?',
                text: 'Étes vous sure que vous voulez supprimer ce code postal?'
              },
              successText: 'Le code postal a été supprimée',
              deleteAction: (row) => DeliveryPostalCodeRepository.deleteSingle(row.id)
            }
          ]
        },
        {
          caption: '#',
          dataField: 'id',
          width: 200,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: 'Code Postal',
          dataField: 'postalCode',
          width: 200,
          sort: {
            dataField: 'postalCode',
            default: 'asc'
          }
        },
        {
          caption: 'Zone',
          dataField: 'zone',
          sort: {
            dataField: 'zone'
          }
        },
      ]
    }
  },
}
</script>
