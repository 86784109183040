import apiService from './Base/BaseRepository';

const url = '/permissions';

export default {

  /**
   * Fetches a list of permissions.
   *
   * @return {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`);
  },
}
