<template>
  <list-table
    :columns="columns"
    :create-route="{
            name: 'devices.create'
        }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="devicesTable"
  >
  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable.vue";
import DeviceRepository from "@/repositories/DeviceRepository";
import {FILTER_BOOLEAN} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowDevices",
  components: {ListTable},
  data() {
    return {
      title: 'Terminaux',
      repositoryFunc: DeviceRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir appareil',
              route: (row) => ({
                name: 'devices.edit',
                params: {id: row.id},
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier appareil',
              route: (row) => ({
                name: 'devices.edit',
                params: {
                  id: row.id
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer appareil',
              confirmation: {
                title: 'Supprimer appareil?',
                text: 'Étes vous sure que vous voulez supprimer ce appareil?'
              },
              successText: 'L\'appareil a été supprimé.',
              deleteAction: (row) => DeviceRepository.deleteSingle(row.id),
            }
          ]
        },
        {
          caption: '#',
          dataField: 'id',
          width: 0,
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'IMEI',
          dataField: 'imei',
          sort: {
            dataField: 'imei'
          }
        },
        {
          caption: 'Terminal',
          dataField: 'name',
          sort: {
            dataField: 'name'
          }
        },
        {
          caption: 'Tél.',
          dataField: 'phoneNumber',
          sort: {
            dataField: 'phoneNumber'
          }
        },
      ],
      filters: [
        {
          caption: "IMEI",
          filterKey: "imei",
          forceIsVisible: true,
        },
        {
          caption: "Terminal",
          filterKey: "name",
        },
        {
          caption: "Numéro de tél.",
          filterKey: "phoneNumber",
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Affecté à un véhicule",
          filterKey: "isAssignedToVehicle",
          forceIsVisible: true,
          type: FILTER_BOOLEAN,
          small: true,
        },
      ]
    }
  },
}
</script>

