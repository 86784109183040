<template>
  <div v-show="childCounter !== 0">
    <div
      class="px-3 py-2 hover:bg-gray-600 text-lg cursor-pointer rounded-l-lg w-full flex flex-row hover:text-gray-200 items-center gap-x-4 select-none text-gray-400"
      @click="isOpen = !isOpen">

      <i :class="`fa-` + icon" class="fa fa-fw text-gray-400"></i>

      <span class="truncate flex-1">{{ label }}</span>

      <fa-icon :class="[isOpen ? '-rotate-90 text-luxcaddy-500' : 'text-gray-400']"
               class="transition transform text-gray-400"
               icon="chevron-left"></fa-icon>
    </div>

    <transition name="fade-up-down">
      <div v-show="isOpen" class="pl-6">
        <div class="w-full mt-2 p-2 flex flex-col border-l border-gray-700">
          <slot @count="countUp"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: "SidebarDropdownMenu",
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
    childCounter: 0,
  }),
  methods: {
    countUp() {
      this.childCounter++;
    }
  },
}
</script>

