<template>
  <div>
    <draggable v-model="categories" :disabled="shouldSplitRootCategories" ghost-class="opacity-40" handle=".handle"
               @input="onDragEnd">
      <transition-group
        :class="[shouldSplitRootCategories ? 'grid grid-cols-1 xl:grid-cols-2 gap-8' : 'space-y-4']"
        name="fade-up-down"
      >
        <category-tree-item
          v-for="category in categories"
          :key="category.id"
          :category="category"
          :show-handle-self="!shouldSplitRootCategories"
        />
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import CategoryTreeItem from "@/components/elements/categoryManagement/Tree/CategoryTreeItem";
import draggable from 'vuedraggable';
import {getCategoryConfigByType} from "@/luxcaddyConfig";

export default {
  name: "CategoryTree",
  components: {CategoryTreeItem, draggable},
  methods: {
    onDragEnd(categories) {
      let categoryIdsSorted = categories.flatMap(cat => cat.id);

      this.$store.dispatch('categories/updateRootSort', {
        type: this.$store.getters['categories/getCurrentCategoryType'],
        isVirtual: this.$store.getters['categories/getCurrentCategoryIsVirtual'],
        categoryIdsSorted: categoryIdsSorted
      })
    },
  },
  computed: {
    categoryConfigByType() {
      return getCategoryConfigByType(this.$store.getters['categories/getCurrentCategoryType'], this.$store.getters['categories/getCurrentCategoryIsVirtual']);
    },
    /**
     * Categories that should split (currently BlogPost only):
     * - Are split into two rows
     * - Don't allow for reordering root categories
     * @returns {boolean|*}
     */
    shouldSplitRootCategories() {
      return this.categoryConfigByType?.splitRootCategories || false;
    },
    categories: {
      get() {
        return this.$store.getters['categories/getCategories'];
      },
      set(categories) {
        this.$store.commit('categories/setCategories', categories);
      }
    },
  },
}
</script>

