/**
 * Converts cents into a string containing the amount in euros.
 *
 * @param cents
 * @return {string|null}
 */
export function centsToEuro(cents) {
  if (cents === null || cents === undefined)
    return null;

  return (cents / 100).toFixed(2);
}

/**
 * Converts cents into a string containing the amount in euros with a trailing € symbol.
 *
 * @param cents
 * @return {string}
 */
export function centsToEuroString(cents) {
  if (cents === null || cents === undefined)
    return '';

  return centsToEuro(cents) + ' €';
}

export function centsToMoneyString(value, currency) {
  currency = currency.toLowerCase();

  if (currency === "eur") {
    return centsToEuroString(value);
  } else if (currency === "usd") {
    return centsToDollarString(value);
  }

  return "[Err] Invalid currency";
}

/**
 * Converts cents into a string containing the amount in dollars with a preceeding $ symbol.
 *
 * @param cents
 * @return {string}
 */
export function centsToDollarString(cents) {
  if (cents === null || cents === undefined)
    return '';

  return `${centsToEuro(cents)} $`;
}

/**
 * Converts the integer into a percentage string with 2 trailing comma numbers.
 *
 * @return {string}
 * @param int
 */
export function intToPercentageString(int) {
  if (int === null || int === undefined || typeof int !== "number")
    return '';

  return `${int.toFixed(2)} %`
}

/**
 * If Euro, returns amount.
 * If any other currency, like usd, will be converted to EUR based on the conversionRate.
 *
 * This function is primarily used for the Invoice Table Totals System
 * @param amount
 * @param currency
 * @param conversionRate
 */
export function convertAmountToEurCents(amount, currency = 'EUR', conversionRate = 0) {
  if (currency === "EUR") {
    return amount;
  }

  return amount * conversionRate;
}