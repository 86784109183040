export function exportResponse(axiosResponse, title = 'luxcaddy', type = 'excel') {
  let data;

  // Handle File streams not downloaded through axios but rather directly by using an arraybuffer
  if (axiosResponse.data === undefined) {
    data = axiosResponse;
  } else {
    data = axiosResponse.data;
  }


  if (!['excel', 'pdf'].includes(type)) {
    return console.error("Invalid export type.");
  }

  const contentType = type === 'excel' ? 'application/vnd.ms-excel' : 'application/pdf';
  const ending = type === 'excel' ? 'xlsx' : 'pdf';

  const url = URL.createObjectURL(new Blob([data], {
    type: contentType
  }))
  const link = document.createElement('a')
  link.href = url
  let fileName = `${title}.${ending}`;
  if (axiosResponse.headers && axiosResponse.headers["content-disposition"]) {
    fileName = axiosResponse.headers["content-disposition"].split("filename=")[1];
    fileName = fileName.replaceAll("\"", "");
  }
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click();
}