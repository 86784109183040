<template>
  <div
    class="bg-stripes bg-gray-500 bg-stripes-gray-400 border border-black select-none px-1 md:px-2 py-1 md:py-2 flex justify-between items-center"
    @click="$emit('click', $event)"
  >
    <div class="font-bold w-16 md:w-24 text-center text-white">
      {{ rackNumber }}
    </div>
  </div>
</template>

<script>
export default {
  name: "StockRack",
  props: {
    rackNumber: {
      type: Number
    }
  }
}
</script>

<style scoped>

</style>