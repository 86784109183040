<!--
    Ready to go Product Search Input.
    Shows a list of 20 products by default, when searching, the first 20 results will be shown matching that search.
-->
<template>

  <div>
    <select-element
      :is-loading="isLoading"
      :label="label"
      :live-search="false"
      :occupy-validation-space="occupyValidationSpace"
      :options="getProducts"
      :type="type"
      :validation-rules="validationRules"
      :value="value"
      @input="$emit('input', $event)"
      @search="onSearchTermChange"
    >
    </select-element>
  </div>

</template>

<script>
import {debounce} from "@/helpers/debounceHelper";
import ProductRepository from "../../../../repositories/ProductRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "ProductSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Produit',
      required: false,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    showImages: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'single'
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(productId) {
        // Selected Product ID not null
        if (productId === null || productId === undefined) {
          return false;
        }

        // Selected Product ID not in included in the results, so probably value was there
        // before rendering component
        // We load only the selected product to show the members name in the list.
        const selectedMemberLoaded = this.getProducts.find(m => m.value === productId) !== undefined;
        if (!selectedMemberLoaded) {
          this.fetchPreselectedProduct(this.value);
        }
      }
    }
  },
  methods: {
    onSearchTermChange: debounce(function (q) {
      this.fetchProducts(q);
    }, 300),

    fetchProducts(searchTerm = "") {
      let filters = [];

      filters.push({
        filterKey: 'isDiscontinued',
        filterValue: 0
      });

      if (searchTerm !== "") {
        searchTerm = searchTerm.replaceAll('#', '');

        filters.push({
          filterKey: 'nameOrId',
          filterValue: searchTerm
        });
      }

      this.isLoading = true;

      ProductRepository
        .getPaginatedForSelect(20, 1, [], filters, false)
        .then((res) => {
          this.products = res.data.data;
        }).finally(() => this.isLoading = false)
    },

    fetchPreselectedProduct(productId) {
      this.isLoading = true;

      ProductRepository
        .getPaginatedForSelect(
          20,
          1,
          [],
          [
            {
              filterKey: 'nameOrId',
              filterValue: productId
            }
          ],
          false
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            this.preselectedProductOption = res.data.data.map(m => {
              return {
                label: this.formatProductLabel(m),
                value: m.id
              };
            })[0];
          }
        }).finally(() => this.isLoading = false)
    },
    formatProductLabel(p) {
      let formattedLabel = `#${p.id} ${p.name}`;
      if (p.brand && p.brand.name) {
        formattedLabel += ` - ${p.brand.name}`;
      }

      return formattedLabel;
    }
  },
  computed: {
    getProducts() {
      let products = this.products.map(p => {
        return {
          value: p.id,
          label: this.formatProductLabel(p),
        };
      });

      // Add the preselected product to the product list if its not already inside.
      if (this.preselectedProductOption !== null && !products.find(m => m.value === this.preselectedProductOption.value)) {
        products.unshift(this.preselectedProductOption);
      }

      return products;
    }
  },
  data: () => ({
    products: [],
    isLoading: false,
    preselectedProductOption: null
  })
}
</script>

