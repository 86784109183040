<template>
  <status-indicator
    :small="small"
    :type="getType"
  >
    <div class="font-bold">
      {{ getText }}
    </div>
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "TaskStatus",
  components: {StatusIndicator},
  props: {
    status: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getType() {
      switch (this.status) {
        default:
          return 'warning';
        case 'suspended':
          return 'danger';
        case 'done':
          return 'primary';
      }
    },
    getText() {
      switch (this.status) {
        default:
          return 'Dû';
        case 'suspended':
          return 'Suspend';
        case 'done':
          return 'Fait';
      }
    }
  },
}
</script>

<style scoped>

</style>