<template>
  <modal
    v-if="isProductSelected"
    ref="modal"
    :blocking="false"
    show-close-button
    title="Produit"
    @close="onModalClose"
  >
    <template v-if="isProductSelected">
      <div class="flex items-center gap-x-12">
        <div class="relative w-32 h-32 flex-none">
          <fruits-vegetable-organic-label
            v-if="getSelectedProductIsOrganic"
          />
          <img :alt="getSelectedProductName" :src="getImageUrl" class="h-full w-full object-contain"/>
        </div>

        <div class="text-7xl">
          {{ getNewStockValue }}
        </div>

        <div class="text-2xl">
          {{ getSelectedProductName }}
        </div>

        <div class="flex flex-col gap-2 flex-none">
          <touch-optimized-button
            class="flex-1"
            padding="py-6 px-8"
            type="secondary"
            @click="modifyStock(+1)"
          >
            +
          </touch-optimized-button>
          <touch-optimized-button
            class="flex-1"
            padding="py-6 px-8"
            type="secondary"
            @click="modifyStock(-1)"
          >
            -
          </touch-optimized-button>
        </div>
      </div>

      <touch-optimized-button
        :disabled="isLoading"
        class="mt-12 w-full"
        padding="py-6"
        @click="saveNewStock"
      >
        Enregistrer
      </touch-optimized-button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal";
import {mapGetters, mapMutations} from "vuex";
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import ProductRepository from "@/repositories/ProductRepository";
import FruitsVegetableOrganicLabel
  from "@/components/pages/TouchInterface/Stock/FruitsVegetables/Components/FruitsVegetableOrganicLabel";

export default {
  name: "FruitsVegetableAddStockModal",
  components: {FruitsVegetableOrganicLabel, TouchOptimizedButton, Modal},
  watch: {
    isProductSelected(bool) {
      this.stockToModify = 0;

      return bool
        ? this.$nextTick(() => this.$refs.modal.openModal())
        : false;
    }
  },
  methods: {
    ...mapMutations('fruitsVegetablesInterface', [
      'unsetSelectedProduct'
    ]),
    onModalClose() {
      this.unsetSelectedProduct();
    },
    modifyStock(value) {
      const newStockToModify = this.stockToModify + value;

      // Don't change Value if new stock would be negative
      if (newStockToModify + this.getSelectedProductOnShelfStock < 0) {
        return false;
      }

      this.stockToModify = newStockToModify;
    },
    saveNewStock() {
      if (!this.getSelectedProductId)
        return false;

      this.isLoading = true;
      ProductRepository
        .stock
        .modifyStock(
          this.getSelectedProductId,
          {
            quantity: this.stockToModify,
            comment: "Stock Modifiée - F&L Inventory"
          }
        ).then(() => {
        this.$emit('stockchange', {
          productId: this.getSelectedProductId,
          newStock: this.getNewStockValue
        });
        this.$nextTick(() => this.$refs.modal.closeModal());
      }).finally(() => this.isLoading = false);
    }
  },
  data: () => ({
    isLoading: false,
    stockToModify: 0,
  }),
  computed: {
    ...mapGetters('fruitsVegetablesInterface', [
      'isProductSelected',
      'getSelectedProductId',
      'getSelectedProductName',
      'getSelectedProductImages',
      'getSelectedProductOnShelfStock',
      'getSelectedProductIsOrganic',
    ]),
    getImageUrl() {
      return getPrimaryImageUrlFromImageObject(
        this.getSelectedProductImages,
      );
    },
    getNewStockValue() {
      if (this.getSelectedProductOnShelfStock === null) {
        return 0;
      }

      return this.getSelectedProductOnShelfStock + this.stockToModify;
    }
  },
}
</script>