<template>
  <div
    :class="
            [
                isHovered ? 'z-30 border-luxcaddy' : 'border-transparent',
                hasDelivery ? 'stepWithDelivery' : '', // Only items with this class count as draggable items
                isFulfilmentFinalized ? 'bg-luxcaddy-100' : 'bg-red-100'
            ]
        "
    class="border-2"
    @mouseenter="setHovered"
    @mouseleave="setUnhovered"
  >
    <div
      v-if="hasDelivery"
      class="py-0.5 px-2 flex items-stretch gap-x-2 text-xs border-b-2 border-gray-300">
      <div class="flex items-stretch gap-x-0.5 flex-grow-0 flex-shrink-0 w-24">
        <div class="w-10 h-full">
          <span v-if="isDelivered" class="text-sm text-luxcaddy-400 text-center">✔</span>
          <div v-else class="h-full flex justify-between items-stretch gap-x-0.5">
            <button class="bg-gray-200 px-0.5 border border-gray-400 text-xs w-4 font-light text-gray-500"
                    @click="removeDelivery"
            >
              <fa-icon icon="times"></fa-icon>
            </button>
            <button
              class="bg-gray-200 px-0.5 border border-gray-400 text-xs w-4 font-light text-gray-500 handle">
              <fa-icon icon="grip-vertical"></fa-icon>
            </button>
          </div>
        </div>

        <div :style="`background-color: ${numberColor}`"
             class="w-6 text-white text-center font-bold text-xs flex items-center justify-center">
          {{ number }}
        </div>
        <div
          class="w-10 bg-white text-red-800 border border-red-800 font-semibold text-xs flex items-center justify-center">
          {{ getTimeslotDisplayTime }}
        </div>
      </div>
      <div>
        <div v-if="isInitialTimeEarly"
             class="relative bg-gray-500 text-white -top-3 -mb-4 left-1 text-right w-max pt-0 px-0.5 text-xs">
          {{ getInitialTime }}
        </div>
        <span
          :class="{'bg-red-200 text-black' : isInitialTimeLate}"
          class="text-sm tracking-tight px-0.5"
        >
                {{ getInitialTimeDisplay }}
            </span>
      </div>
      <div>
        <div v-if="isLiveTimeEarly"
             class="relative bg-gray-500 text-white -top-3 -mb-4 left-1 text-right w-max pt-0 px-0.5 text-xs">
          {{ getLiveTime }}
        </div>
        <span
          :class="{'bg-red-200 text-white' : isLiveTimeLate, 'text-luxcaddy-600': isDelivered}"
          class="text-sm tracking-tight px-0.5"
        >
                {{ getDeliveredTime }}
            </span>
      </div>
      <div v-if="step.delivery" class="font-semibold text-sm">
        {{ step.delivery.boxQuantity }}/{{ step.delivery.estimatedBoxQuantity }}
      </div>
    </div>

    <!-- Step without Delivery (Departure/Arrival) -->
    <div
      v-else
      class="py-1 px-2 flex items-center gap-x-2 text-xs border-b-2 border-gray-300 w-full bg-gray-100">
      <div class="w-24 flex-grow-0">
                <span class="font-bold">
                    <span v-if="isDeparture">Départ</span>
                    <span v-else-if="isArrival">Arrivée</span>
                </span>
      </div>
      <div class="flex-shrink-0 flex gap-x-2">
        <div>
          <div
            v-if="isInitialTimeEarly"
            class="relative bg-gray-500 text-white -top-3 -mb-4 left-1 text-right w-max pt-0 px-0.5 text-xs">
            {{ getInitialTime }}
          </div>
          <span
            :class="{'bg-red-400 text-white' : isInitialTimeLate}"
            class="text-sm tracking-tight px-0.5"
          >
                {{ getInitialTimeDisplay }}
            </span>
        </div>
        <div>
          <div
            v-if="isLiveTimeEarly"
            class="relative bg-gray-500 text-white -top-3 -mb-4 left-1 text-right w-max pt-0 px-0.5 text-xs">
            {{ getLiveTime }}
          </div>
          <span
            :class="{'bg-red-400 text-white' : isLiveTimeLate, 'text-luxcaddy-600': isDelivered}"
            class="text-sm tracking-tight px-0.5"
          >
                    {{ getDeliveredTime }}
                </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "RouteManagerRouteBlockDeliveryStep",
  props: {
    numberColor: {
      type: String,
      default: '#fff'
    },
    step: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: null
    },
    isDeparture: {
      type: Boolean,
    },
    isArrival: {
      type: Boolean,
    },
    routeId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('routeManager', [
      'getHoveredDeliveryId',
      'getHoverInitiatorIsMap'
    ]),

    getInitialTimeDisplay() {
      return this.$date(this.step.initialETA.displayTime).format("HH:mm");
    },

    getInitialTime() {
      return this.$date(this.step.initialETA.time).format("HH:mm");
    },
    getLiveTime() {
      return this.$date(this.step.liveETA.time).format("HH:mm");
    },
    isInitialTimeEarly() {
      return this.step.initialETA.isEarly;
    },
    isInitialTimeLate() {
      return this.step.initialETA.isLate;
    },

    getDeliveredTime() {
      if (this.step.deliveredAt !== undefined) {
        return this.$date(this.step.deliveredAt.time).format("HH:mm");
      }

      return this.$date(this.step.liveETA.displayTime).format("HH:mm");
    },
    isLiveTimeEarly() {
      return this.step.liveETA.isEarly;
    },
    isLiveTimeLate() {
      return this.step.liveETA.isLate;
    },


    getDelivery() {
      return this.step.delivery;
    },
    hasDelivery() {
      return this.getDelivery !== undefined;
    },
    isDelivered() {
      return this.getDelivery?.deliveredAt !== null;
    },
    isFulfilmentFinalized() {
      return this.getDelivery?.isFulfilmentFinalized === null || this.getDelivery?.isFulfilmentFinalized === true;
    },

    getTimeslotDisplayTime() {
      if (this.hasDelivery) {
        if (this.getDelivery.timeSlot) {
          let startDate = this.step.delivery.timeSlot.startDate;
          let endDate = this.step.delivery.timeSlot.endDate;

          return this.$date(startDate).format('HH') + '-' + this.$date(endDate).format('HH');
        }
      }

      return "XX-YY";
    },

    isHovered() {
      return this.getHoveredDeliveryId === this.step.delivery?.id && this.getHoverInitiatorIsMap;
    }
  },
  methods: {
    ...mapMutations('routeManager', [
      'setHoveredDeliveryId',
      'clearHoveredDeliveryId'
    ]),
    ...mapActions('routeManager', [
      'removeDeliveryFromRoute'
    ]),
    setHovered() {
      if (!this.step.delivery)
        return false;

      this.setHoveredDeliveryId({
        deliveryId: this.step.delivery.id,
        initiator: 'sidebar'
      });
    },
    setUnhovered() {
      this.clearHoveredDeliveryId();
    },
    removeDelivery() {
      if (!this.hasDelivery)
        return false;

      this.removeDeliveryFromRoute({
        routeId: this.routeId,
        deliveryId: this.getDelivery.id
      });
    }
  },
}
</script>

