<template>
  <div>
    <tooltip
      text="Permet de sélectionner une date à laquelle toutes les créneaux seront supprimées."
      tooltip-classes="-ml-14 mt-2"
    >
      <button-element class="mb-0.5" type="danger" @click="openCloseDayModal">
        <fa-icon fixed-width icon="lock"/>
        Fermer jour
      </button-element>
    </tooltip>

    <modal
      ref="closeDayModal"
      blocking title="Fermer journée pour livraison"
      @close="onModalClose"
    >
      <date-picker-element
        v-model="dateToClose"
        display-format="DD.MM.YYYY"
        format="YYYY-MM-DD"
        label="Date"
        only-date
        only-future
        @input="getTotalForDate"
      />

      <div
        v-if="totalDeliveryCountOnDate !== null"
        :class="[dateAlreadyHasDeliveries ? 'bg-red-200' : 'bg-luxcaddy-200']"
        class="p-2 rounded-md text-center w-full"
      >
        <div
          v-if="dateAlreadyHasDeliveries"
        >
          Il y a déjà {{ totalDeliveryCountOnDate }} livraisons prévues à la date sélectionnée.<br>
          Impossible de fermer cette date.
        </div>
        <div
          v-else
        >
          <div>
            Aucune livraison à la date sélectionnée. <br>
            Veuillez cliquer sur le bouton ci-dessous pour supprimer tous les créneaux horaires pour cette
            date et le fermer.
          </div>

          <button-element
            class="mt-8"
            type="danger"
            @click="deleteAllTimeSlotsForSelectedDate"
          >

            Supprimer créneaux horaires du {{dateToClose }}
          </button-element>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import Modal from "@/components/elements/modals/Modal.vue";
import Tooltip from "@/components/elements/tooltips/Tooltip.vue";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";
import TimeSlotRepository from "@/repositories/TimeSlotRepository";

export default {
  name: "CloseZoneTimeSlotsDayAction",
  components: {DatePickerElement, Tooltip, Modal, ButtonElement},
  data() {
    return {
      dateToClose: null,
      totalDeliveryCountOnDate: null,
    }
  },
  methods: {
    openCloseDayModal() {
      this.$refs.closeDayModal.openModal();
    },
    getTotalForDate() {
      this.totalDeliveryCountOnDate = null;

      TimeSlotRepository.getTotalsForDate(this.dateToClose).then((res) => {
        const totals = res.data.data.totals;
        this.totalDeliveryCountOnDate = totals
          .flatMap(t => Number(t.totalDeliveryCount))
          .reduce((acc, val) => acc + val, 0)
      })
    },
    onModalClose() {
      this.dateToClose = null;
      this.totalDeliveryCountOnDate = null;
    },
    deleteAllTimeSlotsForSelectedDate() {
      if (!this.dateToClose) {
        return false;
      }
      TimeSlotRepository.closeDay(this.dateToClose)
        .then(() => {
          this.$sendSuccessToast("Tous les créneaux horaires du " + this.dateToClose + " ont été supprimés.");
          this.onModalClose();
          this.$refs.closeDayModal.closeModal();
          this.$emit('change');
        });
    }
  },
  computed: {
    dateAlreadyHasDeliveries() {
      if (this.totalDeliveryCountOnDate === null) {
        return null;
      }
      return this.totalDeliveryCountOnDate > 0;
    }
  },
}
</script>

<style scoped>

</style>