import auth from "../middlewares/authMiddleware";
import ShowCampaigns from "../../components/pages/Campaigns/ShowCampaigns";
import EditCampaign from "../../components/pages/Campaigns/EditCampaign";
import CreateCampaign from "../../components/pages/Campaigns/CreateCampaign";

export default [
  {
    path: '/campaigns',
    name: 'campaigns.list',
    component: ShowCampaigns,
    meta: {
      middleware: [auth],
      title: 'Liste des campagnes',
      permissions: ['CAMPAIGN_READ']
    }
  },
  {
    path: '/campaigns/:campaign/edit',
    name: 'campaigns.edit',
    component: EditCampaign,
    meta: {
      middleware: [auth],
      title: 'Modifier campagne',
      readOnly: {supported: true, title: 'Voir campagne', permissions: ['CAMPAIGN_READ']},
      breadcrumbs: ['campaigns.list'],
      permissions: ['CAMPAIGN_WRITE']
    }
  },
  {
    path: '/campaigns/create',
    name: 'campaigns.create',
    component: CreateCampaign,
    meta: {
      middleware: [auth],
      title: 'Créer campagne',
      breadcrumbs: ['campaigns.list'],
      permissions: ['CAMPAIGN_WRITE']
    }
  }
];