<template>
  <loading
    :active.sync="showLoadingIndicator"
    :can-cancel="false"
    :is-full-page="false"
    background-color="#8e8e8e"
    color="#9EB544"
    loader="spinner"
  >
  </loading>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "LoadingIndicator",
  components: {
    Loading
  },
  props: {
    force: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showLoadingIndicator() {
      return this.$store.getters['loading/showLoadingIndicator'] || this.force;
    },
  }
}
</script>

