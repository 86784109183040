import MemberRepository from "@/repositories/MemberRepository";
import dayjs from "dayjs";

const getDefaultState = () => {
  return {
    selectedYear: (new Date().getFullYear()),
    holidays: [],

    loadingHolidays: false,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getSelectedYear: state => state.selectedYear,
    getHolidays: state => {
      return state.holidays.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)));
    },
    getLoading: state => state.loadingHolidays,

    getSummedTotalDaysCount: state => {
      return state.holidays.reduce((acc, curr) => acc + curr.daysCount, 0);
    }
  },
  mutations: {
    setSelectedYear(state, year) {
      state.selectedYear = year;
    },
    setHolidays(state, holidays) {
      state.holidays = holidays;
    },
    setLoading(state, bool) {
      state.loadingHolidays = bool;
    },
  },
  actions: {
    fetchHolidaysForYear({commit}, year) {
      commit('setSelectedYear', year);
      commit('setLoading', true);
      return MemberRepository.my.fetchHolidaysForYear(year).then((res) => {
        commit('setHolidays', res.data.data);
      }).finally(() => {
        commit('setLoading', false);
      });
    },
  }
}