import router from "@/router/router";


const modelAndLinkFinderRegex = new RegExp("\\[(model|link)[^\\]]+\\][^\\[]+\\[\\/[^\\]]+\\]", 'g');

/**
 * Replaces eatch match for each [model] or [link] with a html link.
 * @param text
 * @param returnOnlyText
 * @returns {*}
 */
export function parseNotificationText(text, returnOnlyText = false) {
  // eslint-disable-next-line no-useless-escape
  return text.replace(modelAndLinkFinderRegex, function (match) {
    const decoded = decodeNotificationUrl(match);

    if (!decoded) {
      return match;
    }

    if (returnOnlyText) {
      return decoded.text;
    }

    return `<a href='${decoded.link}' class="text-luxcaddy-600 cursor-pointer hover:underline">${decoded.text}</a>`;
  });
}

export function decodeNotificationUrl(encodedString) {
  // First, we check if it's an external URL
  let matches = decodeExternalLink(encodedString);
  if (matches !== null) {
    return {
      link: matches[1] ?? null,
      text: matches[2] ?? null,
    }
  }

  // Then we check for a model link
  matches = decodeModelLink(encodedString);
  if (matches !== null) {
    let link = matches[1];

    if (link !== null) {
      link = buildLinkFromModelString(link);
    }

    return {
      link: link ?? null,
      text: matches[2] ?? null,
    }
  }

  return {
    link: null,
    text: null,
  }
}

const mappings = {
  member: "members.edit",
  order: "orders.edit",
  product: "products.edit",
  delivery: "deliveries.edit",
};

// Ex: [link|https://google.com]Go to google[/link]
function decodeExternalLink(encodedString) {
  let regexp = new RegExp("\\[link\\|([^\\s\\]]+)\\s*\\](.*(?=\\[\\/link\\]))\\[\\/link\\]", 'g');
  return regexp.exec(encodedString);
}

// Ex: [model|Member/show/10]Show member[/model]
function decodeModelLink(encodedString) {
  let regexp = new RegExp("\\[model\\|([^\\s\\]]+)\\s*\\](.*(?=\\[\\/model\\]))\\[\\/model\\]", 'g');
  return regexp.exec(encodedString);
}

// Ex: "Members/show/10"
function buildLinkFromModelString(modelString) {
  let splittedValues = modelString.split('/');
  let modelType = splittedValues[0];
  let action = splittedValues[1];
  let id = Number(splittedValues[2]);

  switch (modelType.toLowerCase()) {
    case "member":
      return buildMemberLink(id, action);
    case "order":
      return buildOrderLink(id, action);
    case "product":
      return buildProductLink(id, action);
    case "delivery":
      return buildDeliveryLink(id, action);
  }

  return null;
}

function buildMemberLink(id, action) {
  let route = mappings.member
  let routeObject = {
    name: route,
    params: {member: id}
  };

  if (action === "show") {
    routeObject.query = {readonly: true};
  }

  return router.resolve(routeObject).href;
}

function buildOrderLink(id, action) {
  let route = mappings.order
  let routeObject = {
    name: route,
    params: {order: id}
  };

  if (action === "show") {
    routeObject.query = {readonly: true};
  }

  return router.resolve(routeObject).href;
}

function buildDeliveryLink(id, action) {
  let route = mappings.delivery
  let routeObject = {
    name: route,
    params: {deliveryId: id}
  };

  if (action === "show") {
    routeObject.query = {readonly: true};
  }

  return router.resolve(routeObject).href;
}

function buildProductLink(id, action) {
  let route = mappings.product
  let routeObject = {
    name: route,
    params: {product: id}
  };

  if (action === "show") {
    routeObject.query = {readonly: true};
  }

  return router.resolve(routeObject).href;
}