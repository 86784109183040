<template>
  <div
    :class="[
            isFullyPicked ? 'bg-white' : 'bg-yellow-100'
        ]"
    class="flex items-center w-full space-x-4 p-0.5 shadow"
  >
    <div class="h-28 md:h-28 flex-shrink-0">
      <img
        :src="getImage"
        alt="Product" class="h-full py-1 w-24 md:w-24 bg-white object-contain">
    </div>

    <div class="flex-1 pr-2 md:pr-0">
      <div class="text-sm md:text-xl text-black font-bold break-all">
        {{ getItem.name }}
      </div>
      <div class="-ml-0.5 mt-0.5 md:mt-3 whitespace-pre text-xs md:text-md text-black font-medium break-all">
        {{ getPickingBundleString }}
      </div>

      <div class="flex gap-x-2 justify-between">
        <div class="text-5xl md:text-4xl font-bold text-blue-900 text-opacity-80">
          {{ getPickingQuantity }}
        </div>
        <stock-rack
          :rack-number="getRackNumber"
          class="w-16 md:w-max md:h-14 text-xl md:text-3xl"
        />
      </div>
    </div>

  </div>
</template>

<script>
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import StockRack from "@/components/global/StockRack/StockRack";

export default {
  name: "PickingListItem",
  components: {StockRack},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    getItem() {
      return this.item;
    },
    getPickingBundleString() {
      const bundle = this.getItem.bundle;
      return `${bundle.quantity} x ${bundle.content} ${bundle.unit}`;
    },
    getPickingQuantity() {
      return this.item.pickingQuantity;
    },
    isFullyPicked() {
      return this.getPickingQuantity === 0;
    },
    getImage() {
      return getPrimaryImageUrlFromImageObject(this.item.image);
    },
    getRackNumber() {
      return this.item.rackNumber;
    }
  },
}
</script>

