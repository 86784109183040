<template>
  <div>
    <form-label :label="label"/>

    <validation-provider ref="validationProvider" v-slot="{ errors }" :name="getName" :rules="validationRules" slim>
      <div class="flex w-full mt-1">
        <div
          class="inline-flex flex-shrink-0 items-center px-4 py-2 bg-gray-600 border border-gray-600 rounded-l font-semibold cursor-pointer text-sm text-white tracking-widest hover:bg-gray-500 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition"
          @click="openFileDialog"
        >
          {{ buttonLabel }}
        </div>
        <div class="flex-1 border border-gray-300 rounded-r-md flex items-center justify-between">
          <div class="p-2 pl-4 select-none text-sm">
            <span v-if="file !== null">{{ file.name }}</span>
            <span v-else class="italic text-gray-400">Aucun fichier sélectionné</span>
          </div>
        </div>
      </div>
      <input ref="uploadInput" class="hidden" multiple type="file" @change="onInputChange"/>

      <template v-if="occupyValidationSpace">
        <input-error-message v-if="!$isReadOnly()" :errors="errors"/>
        <div v-else class="h-4"></div>
      </template>

    </validation-provider>
  </div>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
import FormLabel from "@/components/elements/forms/elements/partials/FormLabel";
import InputErrorMessage from "@/components/elements/forms/elements/partials/InputErrorMessage";

export default {
  name: "FileUploadButtonElement",
  components: {InputErrorMessage, FormLabel, ValidationProvider},
  props: {
    label: {
      type: String,
    },
    buttonLabel: {
      type: String,
      default: "Sélectionnez fichiers"
    },
    value: {
      type: File
    },
    validationRules: {
      type: String,
      required: false,
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    openFileDialog() {
      this.$refs.uploadInput.click();
    },
    onInputChange() {
      const files = this.$refs.uploadInput.files;
      this.file = files.length ? files[0] : null;
    }
  },
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(value) {
        this.$refs.validationProvider.syncValue(value);
        this.$emit('input', value);
      }
    },
    getName() {
      if (this.id) {
        return this.id;
      }

      return this.label?.toLowerCase().replaceAll(' ', '-') || null;
    },
  },
}
</script>
