<template>
  <div>
    <div class="h-screen-minus-header w-full flex relative">
      <route-manager-route-planning-in-progress-overlay/>

      <route-manager-sidebar/>

      <div class="flex-1 relative z-auto">
        <route-manager-map/>

        <transition name="fade">
          <div v-if="getIsTrackingAnyRoute" class="absolute top-4 left-6">
            <route-manager-tracking-legend/>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="getInvalidDeliveriesCount" class="absolute top-4 left-56">
            <route-manager-geocode-warning-box/>
          </div>
        </transition>

        <div class="absolute top-4 right-6">
          <route-manager-action-box/>

          <route-manager-delivery-counter-box/>
        </div>

        <div class="absolute bottom-6 right-6">
          <route-manager-settings-box/>
        </div>
      </div>
    </div>

    <route-manager-query-observer
      v-if="getInitialRoutesAndDeliveriesLoaded"
    />
  </div>
</template>

<script>
import RouteManagerSidebar from "@/components/pages/RouteManager/Includes/Sidebar/RouteManagerSidebar";
import RouteManagerActionBox from "@/components/pages/RouteManager/Includes/ActionBox/RouteManagerActionBox";
import RouteManagerMap from "@/components/pages/RouteManager/Includes/Map/RouteManagerMap";
import {mapActions, mapGetters, mapMutations} from "vuex";
import RouteManagerSettingsBox from "@/components/pages/RouteManager/Includes/Settings/RouteManagerSettingsBox";
import RouteManagerDeliveryCounterBox
  from "@/components/pages/RouteManager/Includes/DeliveryCounter/RouteManagerDeliveryCounterBox";
import RouteManagerTrackingLegend
  from "@/components/pages/RouteManager/Includes/Map/Tracking/RouteManagerTrackingLegend";
import RouteManagerGeocodeWarningBox
  from "@/components/pages/RouteManager/Includes/GeocodeWarningBox/RouteManagerGeocodeWarningBox";
import RouteManagerQueryObserver
  from "@/components/pages/RouteManager/Includes/QueryObserver/RouteManagerQueryObserver";
import RouteManagerRoutePlanningInProgressOverlay
  from "@/components/pages/RouteManager/Includes/AutomaticRoutePlanning/RouteManagerRoutePlanningInProgressOverlay.vue";

export default {
  name: "RouteManager",
  components: {
    RouteManagerRoutePlanningInProgressOverlay,
    RouteManagerQueryObserver,
    RouteManagerGeocodeWarningBox,
    RouteManagerTrackingLegend,
    RouteManagerDeliveryCounterBox,
    RouteManagerSettingsBox,
    RouteManagerMap,
    RouteManagerActionBox,
    RouteManagerSidebar
  },
  created() {
    this.setInitialState();

    // Reset Stuff that could be potentially out of sync.
    this.fetchEmployees();
    this.clearSelectedDeliveries();
    this.clearHiddenTimeSlots();
    this.collapseAllRoutes();
    this.clearHoveredDeliveryId();
  },
  methods: {
    ...mapActions('routeManager', ['fetchEmployees']),
    ...mapMutations('routeManager', [
      'clearSelectedDeliveries',
      'clearHiddenTimeSlots',
      'collapseAllRoutes',
      'clearHoveredDeliveryId',
      'setInitialState',
    ]),
  },
  computed: {
    ...mapGetters('routeManager', [
      'getIsTrackingAnyRoute',
      'getInvalidDeliveriesCount',
      'getInitialRoutesAndDeliveriesLoaded'
    ])
  },
}
</script>
