<template>
  <div>
    <list-table
      :columns="columns"
      :create-route="{
                name: 'carts.selection'
            }"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :select-values="selectValues"
      :title="title"
      enable-totals
      identifier="cartsTable"
      selectable
    >
      <template v-slot:member-quick-action-template="{ row }">
        <member-quick-action-modal :id="row.memberId"/>
      </template>

    </list-table>
  </div>
</template>

<script>
import CartRepository from "../../../repositories/CartRepository";
import ListTable from "../../listTable/ListTable";
import {centsToEuroString} from "@/helpers/mathHelper";
import {FILTER_DATETIME_RANGE, FILTER_NUMERIC} from "../../listTable/includes/controls/filters/availableFilters";
import MemberQuickActionModal from "../Members/Subcomponents/MemberQuickActionModal";

export default {
  name: "ShowCarts",
  components: {MemberQuickActionModal, ListTable},
  data() {
    return {
      selectValues: cart => {
        return {
          memberId: cart.memberId
        };
      },

      title: 'Paniers d\'achat',
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir panier',
              route: (row) => ({
                name: 'carts.edit',
                params: {member: row.memberId},
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier panier',
              route: (row) => ({
                name: 'carts.edit',
                params: {member: row.memberId}
              }),
            },
          ],
        },
        {
          caption: '#',
          width: 80,
          cellTemplate: "member-quick-action-template",
          sort: {
            dataField: 'memberId'
          }
        },
        {
          caption: 'Créé à',
          calculateCellValue: (r) => {
            return this.$date(r.createdAt).format('DD.MM.YYYY HH:mm');
          },
          width: 160,
          sort: {
            dataField: 'createdAt',
            default: 'desc'
          }
        },
        {
          caption: 'Mis à jour à',
          calculateCellValue: (r) => {
            return this.$date(r.updatedAt).format('DD.MM.YYYY HH:mm');
          },
          width: 160,
          sort: {
            dataField: 'updatedAt',
            default: 'desc'
          }
        },
        {
          caption: 'Nom Client',
          dataField: 'member.fullName',
        },
        {
          caption: 'Qté',
          dataField: 'itemCount',
          sort: {
            dataField: 'totalProductQuantity',
          }
        },
        {
          caption: 'Total produits',
          dataField: 'total.amount',
          dataType: 'euro',
          totals: {
            sum: "total.amount",
            formatValue: (val) => centsToEuroString(val)
          },
        },
      ],
      filters: [
        {
          caption: "ID(s) ou Nom Client",
          filterKey: "memberIdOrName",
          forceIsVisible: true,
        },
        {
          caption: "Mis à jour entre",
          filterKey: 'updatedAt',
          type: FILTER_DATETIME_RANGE
        },
        {
          caption: "Crée entre",
          filterKey: 'createdAt',
          type: FILTER_DATETIME_RANGE
        },
        {
          filterKey: 'totalProductQuantity',
          inputs: [
            {
              caption: 'Qté produit entre',
              inputKey: 'totalProductQuantity_from',
              type: FILTER_NUMERIC
            },
            {
              caption: 'et',
              inputKey: 'totalProductQuantity_to',
              type: FILTER_NUMERIC
            }
          ]
        },
      ],
      repositoryFunc: CartRepository.getPaginated
    }
  }
}
</script>


