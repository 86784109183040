<template>
  <text-input-element
    v-if="isString"
    v-model="model"
    :label="getKey"
    @enter="$emit('enter')"
  />

  <date-picker-element
    v-else-if="isDate"
    v-model="model"
    :label="getKey"
    with-time
    @enter="$emit('enter')"
  />

  <checkbox-element
    v-else-if="isBool"
    v-model="model"
    :label="getKey"
    @enter="$emit('enter')"
  />
</template>

<script>
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";

export default {
  name: "ReportQueryPlaceholderInput",
  components: {CheckboxElement, DatePickerElement, TextInputElement},
  props: {
    placeHolder: {
      type: Object,
      required: true
    }
  },
  created() {
    // Set Default Value.

    if (this.placeHolder.default) {
      this.model = this.placeHolder.default;
    }
  },
  computed: {
    model: {
      get() {
        return this.$store.getters["reportQuery/getPlaceHolderValue"](this.getKey);
      },
      set(value) {
        this.$store.commit("reportQuery/setPlaceHolderValue", {
          key: this.getKey,
          value: value
        });
      }

    },
    getKey() {
      return this.placeHolder.placeholder;
    },

    // Types
    getType() {
      return this.placeHolder.type.toLowerCase();
    },
    isString() {
      return this.getType === "string";
    },
    isDate() {
      return this.getType === "date";
    },
    isBool() {
      return this.getType === "bool";
    },

    getDefaultValue() {
      return this.placeHolder.default;
    }
  },
}
</script>