<template>
  <status-indicator
    :small="small"
    :type="getType"
  >
    {{ getText }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "InvoiceStatus",
  components: {StatusIndicator},
  props: {
    status: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getText() {
      if (this.status === "finalized") {
        return 'Finalisée';
      } else if (this.status === "draft") {
        return "Brouillon";
      } else if (this.status === "initialized") {
        return "Initialisée";
      } else if (this.status === "monthlyInitialized") {
        return "Initialisée (menuselle)";
      } else if (this.status === "monthlyFinalized") {
        return "Finalise (mensuelle)";
      }
      return this.status;
    },
    getType() {
      if (this.status === "finalized" || this.status === 'monthlyFinalized') {
        return 'primary';
      } else if (this.status === "draft") {
        return "warning";
      }

      return 'light';
    },
  }
}
</script>

