<template>
  <status-indicator
    :small="small"
    :type="getStatusType"
  >
    {{ getStatus }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "PaymentInvoiceStatus",
  components: {StatusIndicator},
  props: {
    status: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getStatusType() {
      if (this.isPaid) {
        return 'primary';
      }

      if (this.isUnpaid) {
        return 'danger'
      }

      if (this.isOverPaid) {
        return 'warning'
      }

      if (this.isPaymentPending) {
        return 'dark'
      }


      return 'light'
    },

    isPaid() {
      return this.status === "paid";
    },

    isOverPaid() {
      return this.status === "overPaid";
    },

    isPaymentPending() {
      return this.status === "paymentPending";
    },

    isUnpaid() {
      return this.status === "unpaid";
    },

    getStatus() {
      return this.$luxcaddyConfig('invoice.availablePaymentStatuses').find(
        status => status.value === this.status)?.label || '??'
    }
  }
}
</script>

