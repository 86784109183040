<template>
  <div>
    <list-table
      ref="tableRef"
      :columns="columns"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      create-event
      identifier="instructionsTable"
      @create="createButtonClicked"
    >
      <template v-slot:type-template="{ row }">
        <instruction-table-type-include :type="row.type"/>
      </template>
    </list-table>

    <create-or-update-instruction-modal
      ref="modal"
      :apply-once-supported="applyOnceSupported"
      :call-endpoint-func="callEndpoint.bind(this)"
      :endpoint-types="ENDPOINT_TYPES"
      :endpoints="endpoints"
      :instructable-id="instructableId"
      :instruction-id="activeInstructionId"
      :instruction-types="instructionTypes"
      :mode="mode"
      @change="onInstructionSaved"
    />
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import {FILTER_BOOLEAN} from "@/components/listTable/includes/controls/filters/availableFilters";
import InstructionTableTypeInclude from "@/components/forms/instructions/Includes/InstructionTableTypeInclude";
import CreateOrUpdateInstructionModal from "@/components/forms/instructions/CreateOrUpdateInstructionModal";

export default {
  name: "ShowInstructionsTable",
  components: {CreateOrUpdateInstructionModal, InstructionTableTypeInclude, ListTable},
  props: {
    endpoints: {
      type: Array,
      required: true
    },
    instructableId: {
      type: Number,
      required: true
    },
    instructionTypes: {
      type: Array,
      required: true
    },
    applyOnceSupported: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const vm = this;
    const ENDPOINT_TYPES = {
      GET: 'get',
      LIST: 'list',
      UPDATE: 'update',
      CREATE: 'create',
      DELETE: 'delete',
    };

    let COLUMNS = [
      {
        caption: "Actions",
        showInColumnChooser: false,
        allowReordering: false,
        buttons: [
          {
            type: 'edit',
            hint: 'Modifier instruction',
            onClick: (row) => this.editButtonClicked(row),
          },
          {
            type: 'delete',
            hint: 'Supprimer instruction',
            confirmation: {
              title: 'Supprimer l\'instruction ?',
              text: 'Étes vous sure que vous voulez supprimer cette instruction ?'
            },
            successText: 'L\'instruction a été supprimé',
            deleteAction: (row) => this.callEndpoint(ENDPOINT_TYPES.DELETE, vm.instructableId, row.id)
          },
        ],
      },
      {
        caption: 'Instruction',
        dataField: 'text',
        sort: {dataField: 'id'}
      },
      {
        caption: 'De',
        dataField: 'authorMember.name',
      },
      {
        caption: 'Type',
        cellTemplate: 'type-template'
      },
      {
        id: 'applyOnce',
        caption: 'Appliquer 1x?',
        calculateCellValue: r => r.applyOnce,
        dataType: 'boolean'
      },
      {
        caption: 'Date d\'expiration',
        calculateCellValue: (r) => {
          if (r.expiresAt === null) {
            return "Jamais";
          }

          return this.$date(r.expiresAt).format('DD.MM.YYYY HH:mm');
        },
        sort: {dataField: 'expiresAt'}
      },
      {
        caption: 'Crée',
        calculateCellValue: (r) => {
          if (r.createdAt === null) {
            return "";
          }

          return this.$date(r.createdAt).format('DD.MM.YYYY HH:mm');
        },
        sort: {dataField: 'createdAt'}
      },
      {
        caption: 'Dernière modification ',
        calculateCellValue: (r) => {
          if (r.updatedAt === null) {
            return "";
          }

          return this.$date(r.updatedAt).format('DD.MM.YYYY HH:mm');
        },
        sort: {dataField: 'updatedAt'}
      },
    ];

    // Remove apply once
    if (!this.applyOnceSupported) {
      COLUMNS = COLUMNS.filter(c => c.id === undefined || c.id !== 'applyOnce');
    }

    return {
      ENDPOINT_TYPES,

      mode: 'create',
      activeInstructionId: null,

      title: 'Instructions',
      repositoryFunc: (...args) => {
        return this.callEndpoint(ENDPOINT_TYPES.LIST, this.instructableId, ...args);
      },
      columns: COLUMNS,
      filters: [
        {
          caption: 'Montrer seulement non expiré',
          filterKey: 'isStillValid',
          type: FILTER_BOOLEAN,
          defaultValue: 1,
          small: true
        },
      ]
    };
  },
  methods: {
    callEndpoint(type, ...args) {
      let endpoint = this.endpoints.find(t => t.type === type);
      if (!endpoint) {
        console.error(`[Instructions] Endpoint with type >${type}< not found.`);
        return;
      }

      return endpoint.func(...args);
    },
    createButtonClicked() {
      this.activeInstructionId = null;
      this.mode = 'create';

      this.$nextTick(() => {
        this.$refs.modal.fetchOrInitiate();
      });
    },
    editButtonClicked(row) {
      this.activeInstructionId = row.id;
      this.mode = 'update';

      this.$nextTick(() => {
        this.$refs.modal.fetchOrInitiate();
      })
    },

    onInstructionSaved() {
      this.$refs.tableRef.refresh();
    }
  },
}
</script>

