<template>
  <div>
    <div class="flex flex-wrap justify-between items-center mb-2">
      <div class="flex gap-x-4 items-center">
        <page-title
          :document-page-title="getMemberName + ' #' + $route.params.member"
          without-margin
        >
          {{ getMemberName + ' #' + $route.params.member }}
        </page-title>

        <member-status-indicator
          v-if="getStatus"
          :status="getStatus"
        />
      </div>

      <batch-actions-single-model
        v-if="getMember"
        :batch-actions="MemberBatchActions.batchActions"
        :selected-value="MemberBatchActions.selectValuesFromId($route.params.member)"
      />
    </div>

    <div class="bg-white border p-4 mb-4 flex gap-x-8 items-center">
      <span class="font-bold">ID Membre:</span>
      <member-quick-action-modal
        :id="Number($route.params.member)"
      />
    </div>


    <card
      ref="editMemberCard"
      :go-back-to="{name: 'members.list'}"
    >

      <card-tab
        id="memberTab"
        key="1"
        ref="memberTab"
        icon="database"
        title="Données"
        @open="setOpenTab('memberTab')"
      >

        <create-or-update-member-form
          v-if="member"
          :member="member"
          mode="update"
        />
      </card-tab>

      <card-tab
        id="employeeTab"
        key="2"
        ref="employeeTab"
        :is-hidden="!isMemberEmployee"
        icon="people-carry"
        title="Employé"
        @open="setOpenTab('employeeTab')"
      >
        <member-create-edit-employee-tab
          v-if="member"
          :member="member"
          @change="getMember"
          @delete="onDelete('employee')"/>
      </card-tab>

      <card-tab id="userTab"
                key="3"
                ref="userTab"
                :is-hidden="!isMemberUser"
                icon="user"
                title="Utilisateur"
                @open="setOpenTab('userTab')"
      >
        <member-create-edit-user-tab
          v-if="member"
          :member="member"
          @change="getMember"
          @delete="onDelete('user')"
        />
      </card-tab>

      <card-tab id="supplierTab" key="4" ref="supplierTab" :is-hidden="!isMemberSupplier" icon="truck"
                title="Fournisseur"
                @open="setOpenTab('supplierTab')">
        <member-create-edit-supplier-tab
          v-if="member"
          :member="member"
          @change="getMember"
          @delete="onDelete('supplier')"/>
      </card-tab>

      <card-tab id="customerTab" key="5" ref="customerTab" :is-hidden="!isMemberCustomer" icon="user-circle"
                title="Client"
                @open="setOpenTab('customerTab')">
        <member-create-edit-customer-tab
          v-if="member"
          :member="member"
          @change="getMember"
          @delete="onDelete('customer')"/>
      </card-tab>


      <card-tab
        id="contactsTab"
        key="6"
        ref="contactsTab"
        :enable-adding-manually="false"
        :is-hidden="!isMemberCustomer"
        icon="user-friends"
        title="Contacts de livraison"
        @open="setOpenTab('contactsTab')"
      >
        <member-contacts-tab
          v-if="member && isMemberCustomer"
          :member="member"
        />
      </card-tab>

      <card-tab id="instructionsTab" key="7" ref="instructionsTab" :enable-adding-manually="false" icon="book"
                title="Instructions" @open="setOpenTab('instructionsTab')">
        <member-instructions-tab
          v-if="member"
          :member-id="member.id"
        />
      </card-tab>

      <card-tab id="invoicePayments" icon="file-alt" title="Paiements">
        <show-payments-table
          v-if="member"
          :filter-key="getPaymentFilterKey"
          :filter-value="member.id"
          table-identifier="showMemberPaymentsTable"
        />
      </card-tab>

      <card-tab id="logTab" key="8" ref="logTab" icon="file-alt" title="Logs"
                @open="setOpenTab('logTab')">

        <show-logs-table
          v-if="member"
          :default-loggable-id="member.id"
          default-loggable-type="Member"
        />
      </card-tab>

    </card>
  </div>
</template>

<script>
import MemberRepository from "@/repositories/MemberRepository";
import Card from "@/components/elements/cards/Card";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import MemberCreateEditSupplierTab from "./Tabs/MemberCreateEditDeleteSupplierTab";
import MemberCreateEditEmployeeTab from "./Tabs/MemberCreateEditDeleteEmployeeTab";
import MemberCreateEditUserTab from "./Tabs/MemberUserForm/MemberCreateEditDeleteUserTab.vue";
import MemberCreateEditCustomerTab from "./Tabs/MemberCreateEditDeleteCustomerTab";
import MemberContactsTab from "./Tabs/DeliveryContacts/CustomerDeliveryContactsTab";
import CreateOrUpdateMemberForm from "@/components/pages/Members/CreateOrUpdateMemberForm";
import PageTitle from "@/components/elements/pages/PageTitle";
import MemberInstructionsTab from "@/components/pages/Members/EditMember/Tabs/MemberInstructionsTab";
import ShowLogsTable from "@/components/pages/Logs/ShowLogsTable";
import MemberStatusIndicator from "../../../elements/display/member/MemberStatusIndicator";
import ShowPaymentsTable from "@/components/pages/Invoices/Shared/EditInvoice/Payments/ShowPaymentsTable";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal.vue";
import BatchActionsSingleModel from "@/batchAction/components/BatchActionsSingleModel.vue";
import MemberBatchActions from "@/batchAction/memberBatchActions";

export default {
  name: "EditMember",
  components: {
    BatchActionsSingleModel,
    MemberQuickActionModal,
    ShowLogsTable,
    MemberInstructionsTab,
    PageTitle,
    CreateOrUpdateMemberForm,
    MemberContactsTab,
    MemberCreateEditCustomerTab,
    MemberCreateEditUserTab,
    MemberCreateEditEmployeeTab,
    MemberCreateEditSupplierTab,
    CardTab, Card,
    ShowPaymentsTable,
    MemberStatusIndicator
  },
  data: () => ({
    openedTab: null,
    member: null,
  }),
  created() {
    this.getMember();
  },
  methods: {
    setOpenTab(tab) {
      this.$refs.editMemberCard.setActiveTab(this.$refs[tab]);
    },
    getMember() {
      MemberRepository
        .getSingle(this.$route.params.member)
        .then((res) => {
          this.member = res.data.data;
        })
    },
    onDelete(type) {
      switch (type) {
        case 'supplier':
          this.$refs.supplierTab.hide();
          break;
        case 'employee':
          this.$refs.employeeTab.hide();
          break;
        case 'user':
          this.$refs.userTab.hide();
          break;
        case 'customer':
          this.$refs.customerTab.hide();
          break;
      }
      this.setOpenTab('memberTab');
    }
  },
  computed: {
    MemberBatchActions() {
      return MemberBatchActions;
    },
    getMemberName() {
      return this.member?.fullName || '...';
    },
    isMemberEmployee() {
      return this.member?.isEmployee;
    },
    isMemberCustomer() {
      return this.member?.isCustomer;
    },
    isMemberSupplier() {
      return this.member?.isSupplier;
    },
    isMemberUser() {
      return this.member?.isUser;
    },
    getPaymentFilterKey() {
      return "memberId"
    },
    isAnonymized() {
      return this.member?.isAnonymized;
    },
    getStatus() {
      if (this.isAnonymized) {
        return 'anonymized';
      }

      return false;
    },
  }
}
</script>

