<template>
  <div>
    <page-title>Créer frais</page-title>
    <card>
      <create-or-update-fee-form mode="create"/>
    </card>
  </div>
</template>

<script>
import CreateOrUpdateFeeForm from "./Forms/CreateOrUpdateFeeForm";
import PageTitle from "../../elements/pages/PageTitle";
import Card from "../../elements/cards/Card";

export default {
  name: "CreateFee",
  components: {Card, PageTitle, CreateOrUpdateFeeForm},
}
</script>

