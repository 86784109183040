import DeliveryRepository from "@/repositories/DeliveryRepository";
import DepositRepository from "@/repositories/DepositRepository";

const getDefaultState = () => {
  return {
    delivery: null,
    deposits: [],
    selectedDeposits: [],

    isAllowedOnSignaturePage: false,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getDelivery: state => state.delivery,
    getDeliveryId: state => state.delivery?.id,
    getDeposits: state => state.deposits,
    getSelectedDeposits: state => state.selectedDeposits,
    getSelectedDepositsWithQuantity: state => state.selectedDeposits.filter(d => d.quantity !== 0),

    getIsAllowedOnSignaturePage: state => state.isAllowedOnSignaturePage
  },
  mutations: {
    clearStore(state) {
      state.delivery = getDefaultState().delivery;
      state.deposits = getDefaultState().deposits;
      state.selectedDeposits = getDefaultState().selectedDeposits;
    },

    // When the passed ID does not match with the one in the store (user delivers a different delivery),
    // the store will be cleared.
    clearStoreIfDifferentDelivery(state, deliveryId) {
      if (state.delivery === null || state.delivery.id !== parseInt(deliveryId)) {
        state.delivery = getDefaultState().delivery;
        state.deposits = getDefaultState().deposits;
        state.selectedDeposits = getDefaultState().selectedDeposits;
      }
    },

    setDelivery(state, delivery) {
      state.delivery = delivery;
    },
    setDeposits(state, deposits) {
      state.deposits = deposits;

      deposits.forEach(d => {
        state.selectedDeposits.push({
          id: d.id,
          quantity: 0
        });
      });
    },

    setIsAllowedOnSignaturePage(state, bool) {
      state.isAllowedOnSignaturePage = bool;
    }
  },
  actions: {
    fetchDeliveryData({commit}, deliveryId) {
      const deliveryRequest = DeliveryRepository.getSingle(deliveryId);
      const depositRequest = DepositRepository.get();

      return Promise
        .all([
          deliveryRequest,
          depositRequest
        ]).then(values => {
          commit('setDelivery', values[0].data.data);
          commit('setDeposits', values[1].data.data);
        });
    },
    markDelivered({getters}, {id, customerPresent, signature = null}) {
      return DeliveryRepository.markDelivered(id, {
        wasCustomerPresentOnDelivery: customerPresent,
        deposits: getters.getSelectedDepositsWithQuantity,
        signature: signature
      });
    }
  }
}