<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :enable-search="false"
    :label="label"
    :occupy-validation-space="occupyValidationSpace"
    :options="availableLanguages"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "LanguageSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Pays',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    allowUnselecting: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.availableLanguages = this.$luxcaddyConfig('languages');
  },
  data: () => ({
    availableLanguages: [],
  })
}
</script>

