<template>
  <div>
    <page-title>
      {{ title }} #{{ getTaskId }}
    </page-title>

    <card>
      <create-or-update-task-form
        :task-id="getTaskId"
        mode="update"
        @updateTitle="onUpdateTitle"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import CreateOrUpdateTaskForm from "@/components/pages/Tasks/Form/CreateOrUpdateTaskForm";

export default {
  name: "EditTask",
  components: {CreateOrUpdateTaskForm, Card, PageTitle},
  computed: {
    getTaskId() {
      return Number(this.$route.params.task);
    }
  },
  data: () => ({
    title: ''
  }),
  methods: {
    onUpdateTitle(title) {
      this.title = title;
    }
  },
}
</script>

<style scoped>

</style>