<template>
  <div class="relative | px-2 py-1 sm:p-2 bg-white hover:bg-gray-100 cursor-pointer border border-gray-400"
       @click="setSelected">
    <fruits-vegetable-organic-label
      v-if="isOrganic"
    />

    <div
      v-if="getStockAmount > 0"
      class="absolute bottom-0 left-0 bg-red-500 text-white px-2 sm:px-3 sm:py-1 font-bold border-r border-t border-gray-400 shadow">
      {{ getStockAmount }}
    </div>
    <img :alt="product.name" :src="getImageUrl" class="w-full h-full object-contain">
  </div>
</template>

<script>
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import FruitsVegetableOrganicLabel
  from "@/components/pages/TouchInterface/Stock/FruitsVegetables/Components/FruitsVegetableOrganicLabel";

export default {
  name: "FruitsVegetableStockProduct",
  components: {FruitsVegetableOrganicLabel},
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    setSelected() {
      this.$store.commit('fruitsVegetablesInterface/setSelectedProduct', this.product);
    }
  },
  computed: {
    getImageUrl() {
      return getPrimaryImageUrlFromImageObject(this.product.images);
    },
    getStockAmount() {
      return this.product.onShelfStock;
    },
    isOrganic() {
      return this.product.isOrganic;
    }
  },
}
</script>

<style scoped>

</style>