<template>
  <div>
    <tooltip :class="[
                 (cartMode && isAlreadyInCartOrOrder) ? 'border-2 border-luxcaddy-300' : 'border border-gray-200',
                 (!cartMode) ? 'hover:bg-luxcaddy-50 transition hover:scale-105 cursor-pointer' : ''
                 ]"
             :text="!cartMode ? tooltip : ''"
             class="rounded-lg bg-white relative h-full shadow-lg hover:shadow-xl w-72 flex flex-col gap-y-2"
             tooltip-classes="mt-40 ml-2"
    >
      <div
        v-if="isAlreadyInCartOrOrder"
        class="absolute transform text-white top-0 right-0 py-1 px-2 bg-luxcaddy-500 opacity-50  rounded-tr-md">
        <fa-icon fixed-width icon="shopping-cart"></fa-icon>
      </div>

      <img :src="getDisplayImageUrl" alt="Product"
           class="max-h-36 mx-auto mt-4 mb-2">

      <div class="p-5 flex flex-col whitespace-pre-wrap">

        <product-name
          :product="product"
          class="text-lg text-center font-semibold"
        />

        <div v-if="context!=='supplierOrder'"
             class="text-gray-400 text-sm font-medium text-center tracking-tight  ">
          {{ product.sellingData.bundle.quantity }} x {{
            product.sellingData.bundle.content
          }}{{ product.sellingData.bundle.unit }}
          <div v-if="product.brand" class="mt-3">
            <span v-html="getBrand"></span>
          </div>
        </div>

        <div v-if="context==='supplierOrder'"
             class="text-gray-400 text-sm font-medium text-center tracking-tight">

          {{ product.buyingData.bundleQuantity }} x {{ product.sellingData.bundle.quantity }} x
          {{ product.sellingData.bundle.content }}{{ product.sellingData.bundle.unit }}
          <div class="mt-3">
            {{ product.supplier.fullName }} ({{ product.supplier.reference }})
          </div>
          <div v-if="product.brand" class="mt-3">
            <span v-html="getBrand"></span>
          </div>

        </div>
      </div>

      <div v-if="showPrice" class="mt-auto px-5 pb-5">
        <div class="flex items-center justify-between gap-x-4">
          <div v-if="context !== 'supplierOrder' && !useBuyingPriceWithAddOn"
               class="text-yellow-500 text-lg font-medium flex-grow text-center">
            <money-amount :amount="product.sellingData.price"/>
          </div>
          <div v-else-if="context === 'order' && useBuyingPriceWithAddOn"
               class="text-yellow-500 text-lg font-medium flex-grow text-center">
            <money-amount :amount="product.buyingData.buyingPriceWithAddOn"/>
            <div class="text-gray-400  flex text-sm font-medium flex-grow items-center justify-center whitespace-nowrap">
                 <money-amount :amount=product.buyingData.pricePerUnit  class="mr-1"/>
                <div>+ {{product.buyingData.buyingPriceAddOnDefault / 100}} %</div>
            </div>
          </div>
          <div v-if="context === 'supplierOrder'"
               class="text-yellow-500 text-lg font-medium flex-grow text-center">
            ID: {{ product.id }}
          </div>
          <add-amount-element v-model="quantityToAdd" @add="addProduct"></add-amount-element>
        </div>
      </div>
    </tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/elements/tooltips/Tooltip";
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import AddAmountElement from "@/components/elements/forms/elements/AddAmountElement";
import MoneyAmount from "@/components/global/Money/MoneyAmount";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";

export default {
  name: "ProductSelectionCard",
  components: { ProductName, MoneyAmount, AddAmountElement, Tooltip},
  props: {
    useBuyingPriceWithAddOn: {
      type: Boolean,
      default: false
    },
    quantityToAdd: {
      type: Number,
      default: 1
    },
    product: {
      type: Object,
      required: true
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    cartMode: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: String,
      required: false
    },
    context: {
      type: String,
      default: 'cart',
      validator: val => ['order', 'cart', 'supplierOrder'].includes(val)
    }
  },
  watch: {

    quantityToAdd: function (val) {
      // If we are currently referencing/substituting an item from the context of an order,
      // We will update the quantity of the product that is referenced to the one we manually set.
      if (this.$store.getters['order/getSubstituteReferencedItem'] !== null) {
        this.$store.commit('order/setReferencedItemQuantity', val);
      }
    }
  },
  computed: {
    getBrand() {
      return this.product.brand.name;
    },
    isInCartContext() {
      return this.context === "cart";
    },
    isInOrderContext() {
      return this.context === "order";
    },

    isInSupplierOrderContext() {
      return this.context === "supplierOrder";
    },
    getDisplayImageUrl() {
      return getPrimaryImageUrlFromImageObject(this.product, "medium");
    },
    isAlreadyInCartOrOrder() {
      if (this.isInCartContext) {
        return this.$store.getters['cart/getProductsIdsWithQuantities'].find(p => p.id === this.product.id)?.quantity || 0 > 0;
      } else if (this.isInSupplierOrderContext) {
        return this.$store.getters['supplierOrders/getOrderItems'].find(p => p.productId === this.product.id)?.orderedBundleQuantity || 0 > 0;
      }
      return this.$store.getters['order/getProductsIdsWithQuantities'].find(p => p.id === this.product.id)?.toBePackedQuantity || 0 > 0;
    },
  },
  methods: {
    addProduct() {
      if (this.isInOrderContext) {
        let payload = {
          itemId: this.product.id,
          quantity: this.quantityToAdd,
          useBuyingPriceWithAddOn: this.useBuyingPriceWithAddOn
        };

        if (this.$store.getters['order/isReferencingItem']) {
          payload.reference = this.$store.getters['order/getReferencedItemId'];
          payload.substituteReferencedItem = this.$store.getters['order/getSubstituteReferencedItem'];
        }

        this.$store.dispatch('order/addItemToOrder', payload).then(() => {
          this.$sendSuccessToast("Le produit a été ajouté.");
          this.$emit('added');
        });
      } else if (this.isInSupplierOrderContext) {
        let payload = {
          productId: this.product.id,
          quantity: this.quantityToAdd,
          force: this.$store.getters["supplierOrders/getIsInAddProductForceMode"]
        };

        this.$store.dispatch('supplierOrders/addItemToOrder', payload).then(() => {
          this.$sendSuccessToast("Le produit a été ajouté.");
          this.$emit('added');
        });
      } else if (this.isInCartContext) {
        let payload = {
          productId: this.product.id,
          quantity: this.quantityToAdd
        };

        this.$store.dispatch('cart/setCartItemQuantity', payload).then(() => {
          this.$sendSuccessToast("Le produit a été ajouté.");
          this.$emit('added');
        });
      }

    }
  }
}
</script>

