<template>
  <div>
    <div class="flex flex-wrap gap-2 justify-between items-center mb-6">
      <page-title>
        Envoyer un message
      </page-title>

      <info-alert
        size="small"
      >
        Un message de type "<span class="text-blue-500">info</span>" ou "<span
        class="text-yellow-500">avertissement</span>" sera envoyé en tant que notification régulière.<br>
        Un message de type "<span class="text-red-500">critique</span>" s'affiche sur l'écran de l'utilisateur
        et doit
        être confirmé avant de poursuivre.
      </info-alert>
    </div>


    <card>
      <form-wrapper
        ref="form"
        submit-button-icon="paper-plane"
        submit-button-label="Envoyer"
        @submit="sendMessage"
      >
        <form-grid class="lg:grid-cols-2">
          <div>
            <select-element
              v-model="type"
              :options="getTypes"
              label="Type"
            />
          </div>


          <member-select-input
            v-model="memberId"
            :only-member-types="['employee']"
            force-initial-load
            only-active-member-types
          />
        </form-grid>


        <hr class="my-6">


        <select-element
          v-model="selectedTemplate"
          :options="getTemplates"
          label="Modèle message"
          @input="onTemplateSelected"
        >
        </select-element>

        <text-area-element
          v-model="text"
          class="mt-2"
          label="Message"
          rows="6"
        />

        <p class="text-gray-400">L'employé qui reçoit la notification peut voir qui l'a envoyée.</p>
      </form-wrapper>
    </card>

  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import Card from "@/components/elements/cards/Card.vue";
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import FormGrid from "@/components/elements/forms/FormGrid.vue";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput.vue";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement.vue";
import InfoAlert from "@/components/elements/alerts/InfoAlert.vue";
import NotificationRepository from "@/repositories/NotificationRepository";

export default {
  name: "MemberSendNotificationPage",
  components: {InfoAlert, TextAreaElement, MemberSelectInput, FormGrid, SelectElement, FormWrapper, Card, PageTitle},
  data() {
    return {
      type: "danger",
      memberId: null,
      text: null,
      selectedTemplate: null
    }
  },
  created() {
    if (this.$route.query) {

      if (this.$route.query.template_id) {
        const q = this.$route.query.template_id;
        const template = this.getTemplates.find(t => t.templateID === q);

        if (template) {
          this.selectedTemplate = template.value;
          this.onTemplateSelected(template.value);
        }
      }

      if (this.$route.query.memberId) {
        this.memberId = Number(this.$route.query.memberId);
      }
    }
  },
  methods: {
    sendMessage(callback) {
      NotificationRepository.sendNotification(
        this.memberId,
        this.type,
        this.text
      ).then(() => {
        this.$sendSuccessToast("Message envoyé!");
        this.type = "danger";
        this.memberId = null;
        this.text = null;
        this.selectedTemplate = null;
      }).finally(() => callback());
    },

    onTemplateSelected(template) {
      this.text = template;
    }
  },
  computed: {
    getTypes() {
      return this.$luxcaddyConfig('notifications.types');
    },
    getSelectedTypeBorderColor() {
      return 'border-' + this.getTypes.find(t => t.value === this.type).color;
    },
    getTemplates() {
      return [
        // Template ID CALL_ME should not be changed as its used in "Send message to driver" on route manager
        {
          templateID: "CALL_ME",
          label: "Appélez-moi!",
          value: 'Urgent, appelez-moi dès que possible !\n\n[link|tel:+35226459033]<span class="text-2xl">>>> Cliquer ici pour appeler <<<</span>[/link]'
        },
        {
          templateID: "COME_TO_ME",
          label: "Venez me voir!",
          value: 'Venez me voir le plus vite possible, s\'il vous plaît.'
        },
        {
          templateID: "COME_BACK",
          label: "Revenir à l'entrepôt!",
          value: 'Veuillez revenir à l\'entrepôt le plus vite possible.'
        },
      ];
    }
  },
}
</script>