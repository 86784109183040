<template>
  <div
    :class="getClasses"
    :title="title"
    class="shadow h-8 w-8 flex justify-center items-center rounded"
    @click="disabled ? null : $emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ListTablePaginationActionButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    secondaryStyle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    }
  },
  computed: {
    getClasses() {
      if (this.secondaryStyle) {
        return this.disabled
          ? 'bg-luxcaddy-500 cursor-not-allowed opacity-20'
          : 'bg-luxcaddy-500 cursor-pointer text-white hover:bg-luxcaddy-600';
      }

      return this.disabled
        ? 'bg-gray-500 cursor-not-allowed opacity-20'
        : 'bg-gray-500 cursor-pointer hover:text-luxcaddy-500 text-gray-200 hover:bg-gray-600';
    }
  },
}
</script>

<style scoped>

</style>