import apiService from './Base/BaseRepository';

const url = '/deposits';

export default {
  /**
   * Fetches all deposits.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`);
  },

  /**
   * Fetches all deposits.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  /**
   * Fetches a single deposit
   *
   * @returns {Promise<AxiosResponse<any>>}
   * @param depositId
   */
  async getSingle(depositId) {
    return await apiService
      .get(`${url}/${depositId}`);
  },

  /**
   * Creates a new deposit.
   *
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  /**
   * Updates a given deposit.
   *
   * @param depositId
   * @param payload
   * @return {Promise<AxiosResponse<any>>}
   */
  async update(depositId, payload) {
    return await apiService.patch(`${url}/${depositId}`, payload);
  },

  localization: {
    async getForDeposit(depositId) {
      return await apiService.get(`${url}/${depositId}/localization`);
    },

    async saveForDeposit(depositId, payload) {
      return await apiService.post(`${url}/${depositId}/localization`, payload);
    },

    async translate(depositId, sourceLanguage, targetLanguages, attributes) {
      return await apiService.post(`${url}/${depositId}/localization/translation`, {
        sourceLanguage, targetLanguages, attributes
      });
    }
  },
}