import auth from "../middlewares/authMiddleware";
import PrintFulfilmentLabelPage from "@/components/pages/PrintFulfilmentLabel/PrintFulfilmentLabelPage";

export default [
  {
    path: '/print-fulfilment-label',
    name: 'fulfilmentlabel.print.index',
    component: PrintFulfilmentLabelPage,
    meta: {
      middleware: [auth],
      title: 'Imprimer étiquette fulfilment',
      permissions: ['FULFILMENT_PRINT_LABEL']
    }
  },
];