import auth from "../../middlewares/authMiddleware";
import PickingGroupList from "@/components/pages/TouchInterface/Packaging/Picking/List/PickingGroupList";
import PickingGroupDetails from "@/components/pages/TouchInterface/Packaging/Picking/Details/PickingGroupDetails";

export default [
  {
    path: '/touch/packaging/picking',
    name: 'touchInterface.packaging.picking.index',
    component: PickingGroupList,
    meta: {
      title: 'Picking',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/packaging/picking/:group',
    name: 'touchInterface.packaging.picking.details',
    component: PickingGroupDetails,
    meta: {
      title: 'Picking',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },

];