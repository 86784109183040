/* eslint-disable */
const PLACEHOLDER_IMAGE_URL = "https://via.placeholder.com/400x300?text=No+Image+found";

/**
 *
 * From the base image object, travels through to find the first primary image in the given size.
 *
 * @param imageObject
 * @param size
 * @param returnPlaceholder
 * @return {*|string}
 */
export function getPrimaryImageUrlFromImageObject(imageObject, size = "medium", returnPlaceholder = true) {
  return getImageFromImageObject(imageObject, 'primary', size, returnPlaceholder);
}

export function getImageFromImageObject(imageObject, label = 'primary', size = 'medium', returnPlaceholder = true) {
  let imageUrl = PLACEHOLDER_IMAGE_URL;
  if (!imageObject) {
    if (returnPlaceholder) {
      return imageUrl;
    }

    return null;
  }
  if (imageObject.url !== undefined) {
    return imageObject.url;
  }

  let base;
  if (imageObject.media) {
    base = imageObject.media;
  } else if (imageObject.images) {
    base = imageObject.images;
  } else {
    base = imageObject;
  }


  if (base.image) {
    base = base.image?.[label] || null;
  } else {
    base = base[label] || null;
  }

  if (Array.isArray(base)) {
    imageUrl = base[0].formats.find(f => f.size === size)?.url;
  }

  let image = imageObject.image;
  if (image !== undefined && image !== null) {
    if (!image[label])
      return null;

    imageUrl = image[label][0].formats.find(f => f.size === size)?.url;
  }

  if (imageUrl === PLACEHOLDER_IMAGE_URL && !returnPlaceholder) {
    return null;
  }

  return imageUrl;
}
