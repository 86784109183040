<template>
  <status-indicator
    :customClasses="getCustomClasses"
    :small="small"
  >
    {{ getText }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "DeliveryStatusIndicator",
  components: {StatusIndicator},
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getCustomClasses() {
      switch (this.status) {
        case "canceled":
          return "bg-red-700 text-white";
        case "confirmed":
          return "bg-yellow-500 text-white";
        case "delivered":
          return "bg-luxcaddy-600 text-white";
        case "enRoute":
          return "bg-pink-500 text-white";
        default:
          return '';
      }
    },
    getText() {
      return this.$luxcaddyConfig('delivery.availableStatuses').find(l => l.value === this.status)?.label || this.status;
    }
  },
}
</script>

