<template>
  <list-table
    :columns="columns"
    :create-route="{
            name: 'banners.create'
        }"
    :filters="getFilters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="bannersTable"
  >
    <template v-slot:type-template="{row}">
      <banner-type-label
        :type="row.type"
      />
    </template>
  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import BannerRepository from "@/repositories/BannerRepository";
import {getImageFromImageObject} from "@/helpers/imageHelper";
import {
  FILTER_BOOLEAN,
  FILTER_NUMERIC,
  FILTER_SELECT_SINGLE
} from "../../listTable/includes/controls/filters/availableFilters";
import BannerTypeLabel from "@/components/pages/Banner/Components/BannerTypeLabel.vue";

export default {
  name: "ShowBanners",
  components: {BannerTypeLabel, ListTable},
  data() {
    return {
      title: "Bannières",
      repositoryFunc: BannerRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir bannière',
              route: (row) => ({
                name: 'banners.edit',
                params: {
                  banner: row.id
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier bannière',
              route: (row) => ({
                name: 'banners.edit',
                params: {
                  banner: row.id
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer bannière',
              confirmation: {
                title: 'Supprimer bannière?',
                text: 'Étes vous sure que vous voulez supprimer cette bannière?'
              },
              successText: 'La bannière a été supprimée',
              deleteAction: (row) => BannerRepository.delete(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'Image',
          dataType: "image",
          width: 120,
          calculateCellValue: r => {
            return getImageFromImageObject(r.media, "primary", "large");
          }
        },
        {
          caption: 'Nom',
          dataField: 'name',
          sort: {
            dataField: 'name'
          }
        },
        {
          caption: 'Type',
          dataField: '',
          cellTemplate: 'type-template'
        },

      ]
    }
  },
  computed: {
    getFilters() {
      return [
        {
          caption: "Nom",
          filterKey: "name",
          forceIsVisible: true,
        },
        {
          caption: "En cours",
          filterKey: 'isRunning',
          type: FILTER_BOOLEAN,
          forceIsVisible: true,
          small: true
        },
        {
          filterKey: 'onPage',
          inputs: [
            {
              caption: 'Sur page',
              inputKey: 'onPage_name',
              type: FILTER_SELECT_SINGLE,
              filterOptions: this.getPageLinkTypesForSelect,
            },
            {
              caption: 'Sur ID',
              inputKey: 'onPage_id',
              type: FILTER_NUMERIC
            }
          ],
          forceIsVisible: true,
        },
        {
          caption: "Placement",
          filterKey: "placement",
          type: FILTER_SELECT_SINGLE,
          filterOptions: this.$luxcaddyConfig('banner.placementList'),
          forceIsVisible: true,
        },
      ]
    },
    getPageLinkTypesForSelect() {
      return this.$luxcaddyConfig('banner.pageLinkTypes').map(pl => {
        return {
          label: pl.label,
          value: pl.model,
        }
      })
    },
  },
}
</script>

