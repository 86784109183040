<template>
  <div class="group bg-gray-800 rounded px-1 cursor-pointer" @click="$store.commit('userInterface/toggleSidebar')">
    <fa-icon class="group-hover:text-gray-200" fixed-width icon="bars"></fa-icon>
  </div>
</template>

<script>
export default {
  name: "NavbarToggleSidebarButton"
}
</script>

