<template>
  <div class="w-full p-4 ">
    <template v-if="getSelectedCategoryInSelectionTree === null">
      <div class="w-full text-center text-2xl font-light">
        Veuller selectionner une catégorie.
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col lg:flex-row gap-y-8 lg:gap-y-0 gap-x-8 h-full">
        <div class="flex-1">
          <!-- Drag and drop product list -->
          <div>
            <!-- Temproray list -->
            <div>
              <div class="text-center text-gray-500 mb-2">
                Fais glisser le produit dans la liste du bas pour l'ajouter à la catégorie sélectionnée.
              </div>

              <draggable v-model="productsAvailableToAdd" :empty-insert-threshold="100"
                         class="bg-gray-50 rounded-md p-4"
                         group="products" handle=".handle">
                <transition-group class="gap-4 grid grid-cols-12" name="flip-list" type="transition">
                  <product-display-card
                    v-for="product in productsAvailableToAdd"
                    :key="'product-' + product.id"
                    :product="product"
                    :show-handle="true"
                    class="bg-yellow-100 hover:bg-yellow-200 opacity-50 border-2 border-dashed"
                  />
                </transition-group>
              </draggable>

              <div class="my-8 h-0.5 w-full border-2 border-dashed border-gray-200"></div>
            </div>

            <div class="flex gap-x-4 justify-between items-center mb-4">
              <div class="flex gap-x-4 items-center">
                <button-element
                  :disabled="!isProductAdded"
                  size="small"
                  type="danger"
                  @click="removeCurrentProduct"
                >
                  Enlever ce produit de la catégorie
                </button-element>
              </div>

              <div class="flex gap-x-4 items-center">
                <button-element
                  :disabled="!hasUnsavedChanges"
                  size="small"
                  type="light"
                  @click="reloadSavedProductSort"
                >
                  Réinitialisation
                </button-element>

                <button-element
                  :disabled="!hasUnsavedChanges"
                  size="small"
                  @click="setNewItemSort"
                >
                  <fa-icon class="mr-2" fixed-width icon="check"/>
                  Sauvegarder
                </button-element>
              </div>
            </div>

            <!-- Products in category list -->
            <card class="lg:max-h-screen-80 overflow-y-auto" type="light">

              <draggable v-model="products" :empty-insert-threshold="100" group="products"
                         handle=".handle">
                <transition-group class="gap-2 grid grid-cols-12" name="flip-list" type="transition">
                  <product-display-card
                    v-for="product in products"
                    :key="'product-' + product.id"
                    :product="product"
                    :show-handle="true"
                  />
                </transition-group>
              </draggable>
            </card>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductRepository from "@/repositories/ProductRepository";
import draggable from 'vuedraggable';
import ProductDisplayCard from "@/components/global/Products/ProductDisplayCard";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import CategoryRepository from "@/repositories/CategoryRepository";
import Card from "@/components/elements/cards/Card";

export default {
  name: "EditProductCategoryProductsInCategory",
  components: {Card, ButtonElement, ProductDisplayCard, draggable},
  data: () => ({
    products: [],
    productsSyncedWithBackend: [],

    productsAvailableToAdd: [],
    productsAvailableToAddSyncedWithBackend: [],
  }),
  props: {
    currentProduct: {
      type: Object,
      required: true
    }
  },
  methods: {
    setNewItemSort() {
      if (!this.getSelectedCategoryInSelectionTree)
        return false;

      CategoryRepository
        .setProducts(this.getSelectedCategoryInSelectionTree.id, this.getMappedProducts)
        .then(() => {
          this.$sendSuccessToast("Modifications enregistrées")
          // Emit event to reload
          this.$emit('change');
        })
        .finally((() => {
          this.reloadSavedProductSort();
        }))
    },
    reloadSavedProductSort() {
      this.getProductsInSelectedCategory(this.getSelectedCategoryInSelectionTree.id);
    },
    getProductsInSelectedCategory(categoryId) {
      ProductRepository.getAllProductsByCategoryId(categoryId)
        .then(res => {
          this.productsAvailableToAdd = [];
          this.productsAvailableToAddSyncedWithBackend = [];

          // Set all products from category
          this.products = res.data.data;
          this.productsSyncedWithBackend = res.data.data;

          // if current product is not part of that category, set it in the list of products to add
          if (!this.getMappedProducts.includes(this.currentProduct.id)) {
            this.productsAvailableToAdd.push(this.currentProduct);
            this.productsAvailableToAddSyncedWithBackend.push(this.currentProduct);
          }
        });
    },
    /**
     * Removes the current product from the list
     * of products added to this category
     */
    removeCurrentProduct() {
      this.products = this.products.filter(p => p.id !== this.currentProduct.id);
      this.productsAvailableToAdd.push(this.currentProduct);
    }
  },
  computed: {
    ...mapGetters('categories', [
      'getSelectedCategoryInSelectionTree'
    ]),
    getMappedProducts() {
      return this.products.flatMap(p => p.id);
    },
    isProductAdded() {
      return this.getMappedProducts.includes(this.currentProduct.id);
    },
    /**
     * Checks if the values from both array differ. If so, the user modified without changing.
     * @returns {boolean}
     */
    hasUnsavedChanges() {
      let productIds = this.products.flatMap(p => p.id);
      let syncedProductIds = this.productsSyncedWithBackend.flatMap(p => p.id);

      return !(productIds.length === syncedProductIds.length && productIds.every((value, index) => value === syncedProductIds[index]));
    },
  },
  watch: {
    /**
     * Whenever the selected category changes, we load the list of all products in that category
     * @param category
     */
    getSelectedCategoryInSelectionTree(category) {
      this.getProductsInSelectedCategory(category.id);
    }
  },
}
</script>

<style scoped>

</style>