<template>
  <div class="grid gap-x-8 gap-y-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FormGrid",
}
</script>

