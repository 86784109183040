export default {
  state: {
    showSidebar: true,
    readOnly: false,
    isOnTouchInterface: false,
    validation: {
      aggressiveErrorDisplayMode: false
    },

    warningNotifications: [],
  },
  getters: {
    isSidebarShown: state => state.showSidebar,
    isOnTouchInterface: state => state.isOnTouchInterface,
    isInReadOnlyMode: state => state.readOnly,
    isValidationInAggressiveErrorDisplayMode: state => state.validation.aggressiveErrorDisplayMode,

    getWarnings: state => state.warningNotifications
  },
  mutations: {
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
    closeSidebar(state) {
      state.showSidebar = false;
    },
    setSidebarState(state, boolean) {
      state.showSidebar = boolean;
    },
    setReadOnlyMode(state, boolean) {
      state.readOnly = boolean;
    },
    setValidationAggressiveErrorDisplayMode(state, boolean) {
      state.validation.aggressiveErrorDisplayMode = boolean;
    },

    setIsOnTouchInterface(state, bool) {
      state.isOnTouchInterface = bool;
    },

    setWarningNotifications(state, warnings) {
      state.warningNotifications = warnings;
    }
  },
  actions: {},
}