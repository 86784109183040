import apiService from './Base/BaseRepository';

const url = '/supplierOrders';


export default {
  async get(sinceSnapshotId = null, deliveryDate = null) {
    let previewUrl = `${url}/preview`;

    if (deliveryDate) {
      previewUrl = previewUrl + `?deliveryDate=${deliveryDate}`;
      if (sinceSnapshotId) {
        previewUrl = previewUrl + `&sinceSnapshotId=${sinceSnapshotId}`
      }
    } else {
      if (sinceSnapshotId) {
        previewUrl = previewUrl + `?sinceSnapshotId=${sinceSnapshotId}`
      }
    }


    return await apiService
      .get(previewUrl);
  },

  async export(sinceSnapshotId = null, deliveryDate = null) {
    let previewUrl = `${url}/preview/export`;

    if (deliveryDate) {
      previewUrl = previewUrl + `?deliveryDate=${deliveryDate}`;
      if (sinceSnapshotId) {
        previewUrl = previewUrl + `&sinceSnapshotId=${sinceSnapshotId}`
      }
    } else {
      if (sinceSnapshotId) {
        previewUrl = previewUrl + `?sinceSnapshotId=${sinceSnapshotId}`
      }
    }

    return await apiService
      .downloadBlob(previewUrl);
  },

  async getSnapshots() {
    return await apiService
      .get(`${url}/snapshots`);
  }
}
