<template>
  <router-link v-slot="{href, route, navigate, isExactActive }" :to="to" custom>
    <div
      :class="[isExactActive ? 'bg-gray-700 border-r-4 border-luxcaddy-500 text-white' : 'text-gray-400']"
      class="px-3 py-2 hover:bg-gray-600 hover:text-gray-200 text-lg cursor-pointer rounded-l-lg w-full flex flex-row items-center gap-x-4"
      @click="navigate">
      <fa-icon v-if="icon" :class="[isExactActive ? 'text-luxcaddy-500' : 'text-gray-400']" :icon="icon"
               fixed-width></fa-icon>
      <slot></slot>
    </div>
  </router-link>
</template>

<script>

export default {
  name: "SidebarNavLink",
  props: {
    icon: {
      type: String,
      required: false
    },
    to: {
      type: Object,
      required: true,
    },
  },
}
</script>