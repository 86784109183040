<template>
  <list-table
    :columns="columns"
    :create-route="{
        name: 'deposits.create'
      }"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="depositsTable"
  />

</template>

<script>
import DepositRepository from "../../../repositories/DepositRepository";
import ListTable from "../../listTable/ListTable";
import {centsToEuroString} from "@/helpers/mathHelper";

export default {
  name: "ShowDeposits",
  components: {ListTable},
  data() {
    return {
      title: 'Deposits',
      repositoryFunc: DepositRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir vidange',
              route: (row) => ({
                name: 'deposits.edit',
                params: {
                  deposit: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier vidange',
              route: (row) => ({
                name: 'deposits.edit',
                params: {
                  deposit: row.id
                }
              }),
            }
          ]
        },
        {
          caption: '#',
          dataField: 'id',
          width: 200,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: 'Name',
          dataField: 'name',
          width: 200,
          sort: {
            dataField: 'name',
            default: 'asc',
          }
        },
        {
          caption: 'Amount',
          dataField: 'price.amount',
          dataType: 'euro',
          calculateCellValue: (r) => centsToEuroString(r.price.amount),
          sort: {
            dataField: 'amount'
          }
        },
      ]
    }
  },
}
</script>
