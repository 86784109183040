import OrderRepository from "@/repositories/OrderRepository";

export default {
  selectValues: order => ({id: order.id}),
  selectValuesFromId: orderId => ({id: orderId}),

  batchActions: [
    {
      label: "Marquer comme préparé",
      action: (callBack, selectedRows) => {
        return OrderRepository
          .batchActions
          .markOrdersAsFulfilled(selectedRows)
          .finally(() => callBack());
      }
    },
  ],
};