<template>
  <div>
    <div>
      <!-- Add products -->
      <div class="mb-3">
        <h2 class="font-bold text-lg mb-4">Ajouter produits</h2>

        <card type="light">
          <text-input-element
            v-model="searchTerm"
            label="Recherche produit"
          />

          <div v-if="searchedProducts.length" class="gap-y-4">
            <draggable
              v-model="searchedProducts"
              :empty-insert-threshold="200"
              group="products"
              handle=".handle"
            >
              <transition-group class="gap-2 grid grid-cols-12" name="flip-list" type="transition">
                <product-display-card
                  v-for="product in searchedProducts"
                  :key="'product-' + product.id"
                  :product="product"
                  :show-handle="true"
                  class="bg-yellow-100 hover:bg-yellow-200 opacity-50 border-2 border-dashed"
                />
              </transition-group>
            </draggable>
          </div>

          <div v-else-if="searchTerm && searchedAtLeastOnce">
            Aucun produit ne correspond aux paramètres de votre requête.
          </div>
        </card>
      </div>

      <!-- Manage existing products -->
      <div>
        <div>
          <h2 class="font-bold text-lg mb-4">Produits dans catégorie</h2>

          <card type="light">

            <div class="gap-y-4">

              <selected-products-in-category-actions
                ref="actions"
                :is-virtual="isVirtual"
                :selected-products="selectedProducts"
                @remove="removeProductIds"
              />

              <div
                v-if="!$isReadOnly()"
                class="flex flex-wrap items-center justify-between gap-4"
              >
                <div class="flex gap-x-4 items-center">
                  <div class="flex items-baseline gap-x-2">
                    <span class="text-sm">Sélectionner:</span>
                    <span
                      class="text-sm text-luxcaddy border-b border-dotted border-luxcaddy hover:text-luxcaddy-600 hover:underline cursor-pointer select-none"
                      @click="selectAll"
                    >
                                        Tous
                                    </span>
                    <span>|</span>
                    <span
                      class="text-sm text-luxcaddy border-b border-dotted border-luxcaddy hover:text-luxcaddy-600 hover:underline cursor-pointer select-none"
                      @click="unselectAll"
                    >
                                        Aucun
                                    </span>
                  </div>

                  <button-with-menu-element
                    :actions="getActionLinks"
                    :disabled="!selectedProducts.length"
                    button-text="Actions"
                    close-after-action-executed
                    disabled-tooltip="Veullez sélectionner au moins un produit"
                    size="small"
                    small-menu
                    stick-direction="right"
                    type="light"
                  />
                </div>

                <div class="flex gap-x-2 items-center">
                  <button-element
                    :disabled="!hasUnsavedChanges"
                    size="small"
                    type="light"
                    @click="fetchProductsInCategory"
                  >
                    Réinitialisation
                  </button-element>

                  <button-element
                    :disabled="!hasUnsavedChanges"
                    size="small"
                    type="primary"
                    @click="setItems"
                  >
                    <fa-icon class="mr-2" icon="check"></fa-icon>
                    Sauvegarder
                  </button-element>
                </div>
              </div>


              <div
                v-if="!products.length"
                class="bg-gray-200 p-4 text-center mt-4"
              >
                Il n'y a aucun produit dans cette catégorie.
              </div>

              <draggable
                v-model="products"
                :empty-insert-threshold="200"
                class="py-4"
                group="products"
                handle=".handle"
              >
                <transition-group class="gap-2 grid lg:grid-cols-12" name="flip-list" type="transition">
                  <product-display-card
                    v-for="product in products"
                    :key="'product-' + product.id"
                    :product="product"
                    :selected="isProductSelected(product.id)"
                    :show-handle="true"
                  >
                    <template v-if="!$isReadOnly()">
                      <div class="flex items-center text-xl cursor-pointer">
                        <fa-icon v-if="isProductSelected(product.id)"
                                 class="text-luxcaddy-500 hover:text-luxcaddy-600" fixed-width
                                 icon="check-circle"
                                 @click="toggleProductSelection(product)"></fa-icon>
                        <fa-icon v-else
                                 :icon="['far', 'circle']"
                                 class="text-gray-200 hover:text-gray-400" fixed-width
                                 @click="toggleProductSelection(product)"></fa-icon>
                      </div>
                    </template>
                  </product-display-card>
                </transition-group>
              </draggable>
            </div>

          </card>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import ProductDisplayCard from "@/components/global/Products/ProductDisplayCard";
import Card from "@/components/elements/cards/Card";
import draggable from "vuedraggable";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import SelectedProductsInCategoryActions from "./SelectedProductsInCategoryActions";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import {debounce} from "@/helpers/debounceHelper";
import ProductRepository from "@/repositories/ProductRepository";
import CategoryRepository from "@/repositories/CategoryRepository";
import ButtonWithMenuElement from "@/components/elements/buttons/ButtonWithMenuElement";

export default {
  name: "EditProductsInCategory",
  components: {
    ButtonWithMenuElement,
    TextInputElement,
    SelectedProductsInCategoryActions,
    ButtonElement,
    Card,
    ProductDisplayCard,
    draggable
  },
  props: {
    categoryId: {
      type: Number,
      required: true
    },
    isVirtual: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.unselectAll();
    this.fetchProductsInCategory();
  },
  data: () => ({
    products: [],
    // Contains the list of products how they have been returned from the backend.
    // if changed, we inform the user he should remember to save.
    productsSyncedWithBackend: [],

    searchedProducts: [],
    searchTerm: null,
    searchedAtLeastOnce: false,
  }),
  watch: {
    searchTerm: debounce(function () {
      this.searchedAtLeastOnce = false;
      this.searchProducts();
    }),
  },
  computed: {
    getAllProductIds() {
      return this.products.flatMap(p => parseInt(p.id));
    },
    getCategoryId() {
      return this.categoryId;
    },
    selectedProducts() {
      return this.$store.getters['categories/getSelectedProducts'];
    },
    /**
     * Checks if the values from both array differ. If so, the user modified without changing.
     * @returns {boolean}
     */
    hasUnsavedChanges() {
      let productIds = this.products.flatMap(p => p.id);
      let syncedProductIds = this.productsSyncedWithBackend.flatMap(p => p.id);

      return !(productIds.length === syncedProductIds.length && productIds.every((value, index) => value === syncedProductIds[index]));
    },
    getActionLinks() {
      return [
        {
          label: 'Copier sél.', icon: 'clone', action: () => {
            this.$refs.actions.$refs.copyProductsModal.openModal();
          }
        },
        {
          label: 'Déplacer sél.', icon: 'exchange-alt', action: () => {
            this.$refs.actions.$refs.moveProductsModal.openModal();
          }
        },
        {
          label: 'Retirer sél.', icon: 'trash-alt', action: () => {
            this.$refs.actions.$refs.removeProductsModal.openModal();
          }
        },
      ];
    },
  },
  methods: {
    fetchProductsInCategory() {
      return ProductRepository.getAllProductsByCategoryId(this.getCategoryId)
        .then(res => {
          this.products = res.data.data;
          this.productsSyncedWithBackend = res.data.data;
        });
    },
    setItems() {
      CategoryRepository
        .setProducts(this.getCategoryId, this.getAllProductIds)
        .then(() => {
          this.$sendSuccessToast("Modifications enregistrées");
        })
        .finally((() => {
          this.searchTerm = null;
          this.searchedProducts = [];
          this.fetchProductsInCategory();
        }))
    },
    toggleProductSelection(product) {
      this.$store.commit('categories/toggleSelectedProduct', product);
    },
    selectAll() {
      this.$store.commit('categories/setSelectedProducts', this.products);
    },
    unselectAll() {
      this.$store.commit('categories/setSelectedProducts', []);
    },
    removeProductIds(productIds) {
      this.products = this.products.filter(p => !productIds.includes(p.id));
      this.unselectAll();
    },
    isProductSelected(productId) {
      return this.selectedProducts.flatMap(p => p.id).includes(productId);
    },
    searchProducts() {
      if (!this.searchTerm)
        return this.searchedProducts = [];

      ProductRepository
        .search(this.searchTerm)
        .then((res) => {
          this.searchedProducts = res.data.data.filter(p => !this.getAllProductIds.includes(p.id));
          this.searchedAtLeastOnce = true;
        })
    },
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>