import auth from "../middlewares/authMiddleware";
import ShowCarts from "../../components/pages/Carts/ShowCarts";
import EditCart from "../../components/pages/Carts/EditCart/EditCart";
import CartSelectMember from "@/components/pages/Carts/CartSelectMember";

export default [
  {
    path: '/carts',
    name: 'carts.list',
    component: ShowCarts,
    meta: {
      middleware: [auth],
      title: 'Liste des paniers d\'achat',
      permissions: ['CART_READ']
    }
  },
  {
    path: '/carts/select',
    name: 'carts.selection',
    component: CartSelectMember,
    meta: {
      middleware: [auth],
      title: 'Sélectionner un client pour voir leur panier',
      breadcrumbs: ['carts.list'],
      permissions: ['CART_WRITE']
    }
  },
  {
    path: '/carts/:member/edit',
    name: 'carts.edit',
    component: EditCart,
    meta: {
      middleware: [auth],
      title: 'Modifier un panier d\'achat',
      readOnly: {supported: true, title: 'Voir un panier d\'achat'},
      breadcrumbs: ['carts.list'],
      permissions: ['CART_WRITE']
    }
  },
];