<template>
  <div class="p-3 rounded-md bg-gray-100">
    <div class="flex items-center gap-x-6 justify-between mb-4">
      <div
        :class="{'text-red-500' : !hasSelectedRequiredAmount}"
        class="text-lg font-bold"
      >
        {{ group.group }}
      </div>
      <div
        v-if="group.isMandatory"
        :class="{'text-red-500' : !hasSelectedRequiredAmount}"
        class="font-medium"
      >
        <template v-if="group.onlyOneChoice">
          ({{ getAmountOfSelectionsInGroup }}/1)
        </template>
        <template v-else>
          ({{ getAmountOfSelectionsInGroup }}/&infin;)
        </template>

        <fa-icon v-if="!hasSelectedRequiredAmount" icon="exclamation-triangle"></fa-icon>
      </div>
    </div>
    <div class="flex flex-wrap gap-3">
      <package-selection-option
        v-for="packageOption in group.options"
        :key="packageOption.id"
        :group-name="group.group" :option="packageOption"
      ></package-selection-option>
    </div>
  </div>
</template>

<script>
import PackageSelectionOption from "./PackageSelectionOption";

export default {
  name: "PackageSelectionGroup",
  components: {PackageSelectionOption},
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  computed: {
    getAmountOfSelectionsInGroup() {
      const allSelections = this.$store.getters['cart/getSelectedPackagingOptions'];
      let selectionsInGroup = allSelections.filter(s => {
        return this.group.options.flatMap(o => o.id).includes(s.id);
      });

      return selectionsInGroup.length;
    },
    hasSelectedRequiredAmount() {
      const selected = this.getAmountOfSelectionsInGroup;

      if (this.group.isMandatory) {
        if (this.group.onlyOneChoice)
          return selected === 1;

        return selected >= 1;
      } else {
        if (this.group.onlyOneChoice)
          return selected <= 1;
      }
      return true;
    }
  }
}
</script>

