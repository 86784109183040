import CountryRepository from "@/repositories/CountryRepository";
import {vue} from "@/main";

const getDefaultState = () => {
  return {
    countries: {
      list: [],
      lastUpdateAt: null,
    },
    countriesPhonePrefix: {
      list: [],
      lastUpdateAt: null,
    },
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getCountries: state => state.countries.list,
    getCountriesPhonePrefix: state => state.countriesPhonePrefix.list,
  },
  mutations: {
    setGlobalData(state, values) {
      this.commit('global/setCountries', values[0].data.data);
    },
    setCountries(state, countries) {
      state.countries.list = countries;
      state.countries.lastUpdateAt = vue.$date();
    },
    setCountriesPhonePrefix(state, countriesPhonePrefix) {
      state.countriesPhonePrefix.list = countriesPhonePrefix;
      state.countriesPhonePrefix.lastUpdateAt = vue.$date();
    }
  },
  actions: {
    fetchAllInfo({commit}) {
      let countryRequest = CountryRepository.get();

      return Promise.all([
        countryRequest
      ]).then((values) => {
        commit('setGlobalData', values);
      });
    },
    fetchCountries({commit}) {
      CountryRepository.get().then((res) => {
        commit('setCountries', res.data.data);
      });
    },
    fetchCountriesPhonePrefix({commit}) {
      CountryRepository.getCountryPhonePrefix().then((res) => {
        commit('setCountriesPhonePrefix', res.data.data);
      });
    }
  },
}