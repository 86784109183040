<template>
  <div>
    <div class="flex flex-wrap gap-x-4 items-center mb-6">
      <page-title without-margin>
        <div v-if="getBankMovement">
          {{ getBankMovement.reference }} #{{ getBankMovement.id }}
        </div>
        <div v-else>
          [...]
        </div>
      </page-title>

      <bank-movement-treated-status
        :status="getBankMovementTreatedStatus"
      />
    </div>

    <card class="mb-6">
      <bank-movement-details
        v-if="getBankMovement"
      />

      <bank-movement-link-payment-transactions-entry
        v-if="!isDirectBookingSet"
        class="mt-4"
      />

      <bank-movement-linked-payment-transactions
        v-if="getBankMovement"
        class="mt-4"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import {mapActions, mapGetters, mapMutations} from "vuex";
import BankMovementDetails from "@/components/pages/Accounting/BankMovements/Edit/Sub/BankMovementDetails";
import BankMovementLinkedPaymentTransactions
  from "@/components/pages/Accounting/BankMovements/Edit/Sub/BankMovementLinkedPaymentTransactions";
import BankMovementTreatedStatus from "@/components/pages/Accounting/BankMovements/Edit/Sub/BankMovementTreatedStatus";
import BankMovementLinkPaymentTransactionsEntry
  from "@/components/pages/Accounting/BankMovements/Edit/Sub/LinkPaymentTransactions/BankMovementLinkPaymentTransactionsEntry";

export default {
  name: "EditBankMovement",
  components: {
    BankMovementLinkPaymentTransactionsEntry,
    BankMovementTreatedStatus,
    BankMovementLinkedPaymentTransactions,
    BankMovementDetails,
    Card,
    PageTitle
  },
  created() {
    this.clearData();
    this.fetchBankMovement(this.$route.params.id);
  },
  methods: {
    ...mapActions('bankMovements', [
      'fetchBankMovement',
    ]),
    ...mapMutations('bankMovements', [
      'clearData'
    ])
  },
  computed: {
    ...mapGetters('bankMovements', [
      'getBankMovement',
      'isBankMovementTreated',
      'getLinkedPaymentTransactionCount',
      'isDirectBookingSet',
      'getBankMovementTreatedStatus'

    ]),
  },
}
</script>

<style scoped>

</style>