<template>
  <div>
    <!-- use same table identifier as in SupplierSalesStats in order to keep same filters -->
    <list-table
      :columns="columns"
      :create-button="false"
      :filters="getFilters"
      :repository-func="repositoryFunc"
      :title="title"
      identifier="supplierSalesStatsTable"
      sub-key="items"
    >
      <template v-slot:product-name-template="{ row }">
        <product-name
          :custom-is-discontinued="row.productIsDiscontinued"
          :custom-is-published="row.productIsPublished"
          :custom-is-visibility-forced="row.productIsShopVisibilityForced"
          :custom-name="row.productName"
        />
      </template>
    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import OrderRepository from "@/repositories/OrderRepository";
import {centsToEuroString, intToPercentageString} from "@/helpers/mathHelper";
import {productOrderStatsFilters} from "@/helpers/listTableModels/ListTableProductOrderStatsFilters";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";

export default {
  name: "SupplierSalesStatsDetails",
  components: {ProductName, ListTable},
  data() {
    return {
      title: 'Statistiques ventes par fournisseur details',
      columns: [
        {
          caption: '#',
          dataField: 'productId',
          sort: {
            dataField: 'productId'
          }
        },
        {
          caption: 'Name',
          cellTemplate: 'product-name-template',
          sort: {
            dataField: 'productName'
          }
        },
        {
          caption: 'Nombre',
          dataField: 'totalQuantity',
          sort: {
            dataField: 'totalQuantity'
          }
        },
        {
          caption: 'PrixVenteTTC',
          dataField: 'totalSellingPriceWithVat',
          calculateCellValue: (row) => centsToEuroString(row.totalSellingPriceWithVat),
          sort: {
            dataField: 'totalSellingPriceWithVat'
          }
        },
        {
          caption: 'PrixVenteHTVA',
          dataField: 'totalSellingPrice',
          calculateCellValue: (row) => centsToEuroString(row.totalSellingPrice),
          sort: {
            dataField: 'totalSellingPrice'
          }
        },
        {
          caption: 'PrixAchatHTVA',
          dataField: 'totalBuyingPrice',
          calculateCellValue: (row) => centsToEuroString(row.totalBuyingPrice),
          sort: {
            dataField: 'totalBuyingPrice'
          }
        },
        {
          caption: 'Margue',
          dataField: 'margin',
          calculateCellValue: (r) => intToPercentageString(r.margin),
          sort: {
            dataField: 'margin'
          }
        },
        {
          caption: 'Gain',
          dataField: 'gain',
          calculateCellValue: (r) => intToPercentageString(r.gain),
          sort: {
            dataField: 'gain'
          }
        },
        {
          caption: 'GainMontant',
          dataField: 'gainMontant',
          calculateCellValue: (row) => centsToEuroString(row.gainMontant),
          sort: {
            dataField: 'gainMontant'
          }
        },

      ],
      repositoryFunc: (...args) => {
        return OrderRepository.stats.getSupplierSalesStatsDetails(this.$route.params.supplier, ...args);
      },
    }
  },
  methods: {
    showButtonClicked(row) {
      return this.$router.push({
        name: 'stats.supplier-sales-details',
        params: {supplier: row.supplierId},
      });
    },
  },
  computed: {
    getFilters() {
      // remove supplier member filter, as supplier is fix
      return productOrderStatsFilters.filter(f => f.filterKey !== 'supplierMemberIdentity');
    }
  }

}
</script>

<style scoped>

</style>