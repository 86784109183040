<template>
  <div>
    <button-with-menu-element
      ref="menuButton"
      :actions="getActions"
      :button-text="buttonLabel"
      :size="buttonSize"
      :truncate="false"
      button-icon="cog"
      type="danger"
    />

    <modal
      ref="confirmationModal"
      icon="cog"
      title="Action"
    >
      <template v-if="selectedAction">

        <form-wrapper
          v-if="hasAdditionalFields"
          ref="form"
          :show-submit-button="selectedAction.showSubmitBtn === undefined ? true : selectedAction.showSubmitBtn"
          submit-button-label="Executer"
          @submit="executeAction"
        >
          <div class="grid grid-cols-1 gap-y-2">
            <div
              v-for="field in selectedAction.additionalFields"
              :key="field.param"
              class="mb-4"
            >
              <text-input-element
                v-if="field.type === 'text'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />
              <number-input-element
                v-else-if="field.type === 'number'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />
              <currency-input-element
                v-else-if="field.type === 'currency'"
                v-model.number="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />
              <checkbox-element
                v-else-if="field.type === 'checkbox'"
                v-model="field.modelValue"
                :label="field.label"
                v-bind="field.props"
              />
              <product-select-input
                v-else-if="field.type === 'productId'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />
              <vat-input-element
                v-else-if="field.type === 'vat'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />
              <label-size-select-input
                v-else-if="field.type === 'size'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />
              <date-picker-element
                v-if="field.type === 'date'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />
              <vat-codes-select-input
                v-if="field.type === 'vatCode'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />

              <booking-account-select-input
                v-if="field.type === 'bookingAccount'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />

              <select-element
                v-if="field.type === 'select'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />

              <product-tag-selection-input
                v-if="field.type === 'productTag'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />
              <rack-select-input
                v-if="field.type === 'racks'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                v-bind="field.props"
              />

              <category-selection-input
                v-if="field.type === 'productCategories'"
                v-model="field.modelValue"
                :label="field.label"
                :validation-rules="field.validationRules !== undefined ? field.validationRules : ''"
                type="product"
                v-bind="field.props"
              />

              <upload-external-invoice-pdfs
                v-if="field.type === 'uploadExternalPDFs'"
                :upload-to-storage="false"
                show-cancel-bth
                submit-btn="Attache PDF"
                @cancel="cancelUpload"
                @upload="onFileUpload"
              />

            </div>
          </div>

          <template #additionalButtons>
            <button-element
              type="light"
              @click="abortExecution"
            >
              <fa-icon class="mr-2" fixed-width icon="times"></fa-icon>
              Annuler
            </button-element>
          </template>

        </form-wrapper>

        <!-- No additional fields. Show just a simple confirmation -->
        <div v-else>
          <div class="mb-12">Êtes-vous sure que vous voulez executer cette action?</div>

          <template>

            <div class="flex flex-wrap gap-1 space-x-2 justify-end">
              <button-element class=" flex" type="light" @click="abortExecution">
                <i class="fa fa-times mr-4"></i>
                Annuler
              </button-element>

              <button-element class="flex " type="submit" @click="executeAction">
                <fa-icon class="mr-4" icon="check"></fa-icon>
                Executer
              </button-element>
            </div>


          </template>
        </div>

      </template>
    </modal>
  </div>
</template>

<script>
import ButtonWithMenuElement from "@/components/elements/buttons/ButtonWithMenuElement";
import Modal from "@/components/elements/modals/Modal";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import CurrencyInputElement from "@/components/elements/forms/elements/CurrencyInputElement";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import ProductSelectInput from "@/components/elements/forms/elements/ProductSelectInput";
import VatInputElement from "@/components/elements/forms/elements/VatInputElement.vue";
import DatePickerElement from "../../../elements/forms/elements/base/DatePickerElement";
import LabelSizeSelectInput from "../../../elements/forms/elements/LabelSizeSelectInput";
import VatCodesSelectInput from "@/components/elements/forms/elements/VatCodesSelectInput";
import BookingAccountSelectInput from "@/components/elements/forms/elements/BookingAccountSelectInput";
import ProductTagSelectionInput from "../../../elements/forms/elements/ProductTagSelectionInput";
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import RackSelectInput from "@/components/elements/forms/elements/RackSelectInput";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import CategorySelectionInput from "@/components/elements/forms/elements/CategorySelectionInput.vue";
import UploadExternalInvoicePdfs
  from "@/components/pages/Accounting/ExternalInvoicePDFs/Sub/UploadExternalInvoicePdfs.vue";

export default {
  name: "BatchActionsButton",
  components: {
    UploadExternalInvoicePdfs,
    CategorySelectionInput,
    SelectElement,
    RackSelectInput,
    FormWrapper,
    ProductTagSelectionInput,
    BookingAccountSelectInput,
    VatCodesSelectInput,
    LabelSizeSelectInput,
    DatePickerElement,
    VatInputElement,
    ProductSelectInput,
    CheckboxElement,
    CurrencyInputElement,
    NumberInputElement,
    TextInputElement,
    ButtonElement,
    Modal,
    ButtonWithMenuElement
  },
  data: () => ({
    selectedAction: null,
    uploadedFiles: null
  }),
  props: {
    batchActions: {
      type: Array
    },
    refreshAfterBatchAction: {
      type: Boolean,
      default: true
    },

    // if set to true, this is only allowed to be used for one single model at a time.
    singleMode: {
      type: Boolean,
      default: true
    },
    // If singlemode is set to true, this is required. Holds the object with values to identify a model.
    // The most basic use is usually {id: modelId}
    singleModeIdentifierObject: {
      type: Object,
    },

    buttonSize: {
      type: String,
      default: "large"
    },
    buttonLabel: {
      type: String,
      default: "Batch"
    },
  },
  methods: {
    onFileUpload(filesToUpload) {
      this.uploadedFiles = filesToUpload;
      this.executeAction();
    },
    cancelUpload() {
      this.abortExecution();
    },
    /**
     * Show a confirmation dialog and save the function that should be executed
     * after confirming to a variable.
     * @param action
     */
    confirmAction(action) {
      if (!this.hasAnyRowSelected && !this.singleMode) {
        return this.$showErrorDialog('Veullez sélectionner au moins une ligne.');
      }

      if (action.additionalFields) {
        action.additionalFields = action.additionalFields.map(f => {
          // After a batch action has been executed, all values will be set to
          // either their set default value or to null. They are reset.
          const valueToSet = f.defaultValue !== undefined
            ? f.defaultValue
            : null;
          this.$set(f, 'modelValue', valueToSet);

          return f;
        });
      }

      this.selectedAction = action;

      const vm = this;
      this.$nextTick(() => {
          if (this.selectedAction.skipConfirmationModal) {
              vm.$refs.menuButton.onClickOutside(); // Close Menu
              this.executeAction();
          } else {
              vm.$refs.confirmationModal.openModal(); // Open confirmation modal
              vm.$refs.menuButton.onClickOutside(); // Close Menu
          }
      });

    },
    abortExecution() {
      this.$refs.confirmationModal.closeModal();
      this.$nextTick(() => {
        this.selectedAction = null;
      });
    },
    /**
     * Executes the action and passes all selected Values back as a param
     */
    executeAction() {
      this.$store.commit('listTable/clearBatchErrors');

      this.selectedAction.functionToExecute(
        this.onActionExecuted.bind(),
        this.getSelectedTableValues,
        this.getAdditionalModelValues
      ).then((res) => {
        this.$store.commit('listTable/resetBatchReturnedData');
        if (this.refreshAfterBatchAction) {
          this.$emit('executed', false);
        }

        if (res.data.data.downloadFile) {
          const blob = new Blob([res.data.data.content], { type: res.data.data.type });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.data.data.filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
        else if (res.data.data.length > 0) {
          this.$store.commit('listTable/setBatchReturnedData', res.data.data);
        }
         this.$sendSuccessToast("L'action sélectionnée a été exécutée.");
      }).catch((error) => {
        return this.handleExecutionError(error);
      });
    },
    handleExecutionError(error) {
      if (error.response) {

        // Some batch Action items failed.
        if (error.response.status === 422 && error.response.data.errors) {
          if (this.singleMode) {
            this.$showErrorDialog(error.response.data.errors[0]?.error);
          } else {
            this.$store.commit('listTable/setBatchErrors', error.response.data.errors);
          }

          this.$refs.confirmationModal.closeModal();
        }
        if (this.refreshAfterBatchAction) {
          this.$emit('executed', true);
        }
      }
    },
    onActionExecuted() {
      // this.$store.commit('listTable/clearAllSelectedRows');
      this.$refs.confirmationModal.closeModal();

      this.selectedAction = null;
      this.uploadedFiles = null;
    }
  },
  computed: {
    getActions() {
      // Hook the Confirmation dialog between the action.
      return this.batchActions.map(a => {
        // I don't know why, but this if is required. Otherwise it will not be possible
        // to confirm an Action the second time, this component renders?!

        // Reproduction:
        // Go to a List, execute a batch action. Works.
        // Go to a different list, does not work.
        if (!a.functionToExecute) {
          a.functionToExecute = a.action;
        }
        a.action = () => this.confirmAction(a);


        return a;
      });
    },
    getSelectedTableValues() {
      // If Batch Action used in Single Mode, we create an Array from the Object passed.
      if (this.singleMode) {
        return [this.singleModeIdentifierObject];
      }

      // if Used in List Table Mode, we get the computed value identifiers from the Store.
      return this.$store.getters["listTable/getSelectedRows"];
    },
    hasAdditionalFields() {
      return this.selectedAction !== null && (this.selectedAction.additionalFields !== undefined || this.uploadedFiles !== null);
    },
    getAdditionalModelValues() {
      if (!this.hasAdditionalFields) {
        return null;
      }

      let arr = this.selectedAction.additionalFields.map(f => {
        if (f.modelValue === undefined || f.modelValue === null) {
          f.modelValue = null;
        }

        if (this.uploadedFiles) {
          f.modelValue = this.uploadedFiles;
        }

        return [f.param, f.modelValue];
      });

      return Object.fromEntries(arr);
    },
    hasAnyRowSelected() {
      return this.$store.getters['listTable/getSelectedRows'].length > 0;
    }
  },
}
</script>

<style scoped>

</style>