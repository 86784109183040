<!--
    If the form is being populated from outside, for example if data has been loaded from an endpoint,
    remember to call resetForm() to reset the validation state!

    Also, call the onRequestComplete callback when the form has been sucessfully submitted to reset the validation state.
 -->
<template>
  <ValidationObserver ref="validationObserver" v-slot="{ changed }" tag="form" @submit.prevent="onSubmit">
    <form-display-status-updater :changed="changed" :form-id="getFormId"></form-display-status-updater>

    <div ref="form">
      <slot></slot>
    </div>

    <slot v-if="!isReadOnly && showSubmitButton" name="submitButton" v-bind:changed="changed">
      <div
        :class="{'space-x-4 space-y-8 md:space-x-0 md:space-y-0 md:gap-x-4 mt-2 sm:mt-8 justify-center md:justify-end': useButtonSpacing}"
        class="flex flex-wrap items-center content-evenly"
      >

        <transition name="fade">
          <div class="text-lg flex-grow">
            <div v-if="changed && showChanged"
                 class="font-light h-full rounded flex flex-col md:flex-row items-center gap-x-6">
              <fa-icon icon="exclamation-triangle"></fa-icon>
              <span class="text-sm text-gray-400">Vous avez des changements non sauvegardé.</span>
            </div>
          </div>
        </transition>

        <div v-if="additionalButtonsSlotSet">
          <slot name="additionalButtons" v-bind:changed="changed"></slot>
        </div>

        <form-submit-button
          :class="getButtonClasses"
          :disabled="loading || disableSubmitButton"
          :type="submitButtonType"
          class="flex-none flex"
        >
          <fa-icon v-if="showLoadingIndicator && loading" class="animate-spin transition mr-2"
                   icon="circle-notch"></fa-icon>
          <fa-icon v-else :icon="submitButtonIcon" class="mr-2"></fa-icon>
          {{ submitButtonLabel }}
        </form-submit-button>
      </div>
    </slot>
  </ValidationObserver>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import FormSubmitButton from "../buttons/FormSubmitButton";
import FormDisplayStatusUpdater from "./FormDisplayStatusUpdater";

// Replace Button using the following syntax:
// <template v-slot:submitButton="{changed}">
// </template>

export default {
  name: "FormWrapper",
  components: {FormDisplayStatusUpdater, FormSubmitButton, ValidationObserver},
  props: {
    name: {
      type: String,
      required: false,
    },

    submitButtonLabel: {
      type: String,
      default: 'Sauvegarder',
    },
    submitButtonIcon: {
      type: String,
      default: 'check',
    },
    submitButtonType: {
      type: String,
      default: 'primary',
    },
    showSubmitButton: {
      type: Boolean,
      default: true
    },
    showChanged: {
      type: Boolean,
      default: false
    },
    customButtonClasses: {
      type: String,
      required: false
    },
    showLoadingIndicator: {
      type: Boolean,
      default: true
    },
    useButtonSpacing: {
      type: Boolean,
      default: true
    },
    disableSubmitButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    this.$nextTick(() => {
      this.resetForm();

      this.$nextTick(() => {
        this.$store.commit('forms/addDisplayedForm', {
          id: this.getFormId,
          cardParentId: this.getFirstCardParent?.getCardId || null,
          changed: false
        });

        // Focus first Form Element.
        this.$nextTick(() => {
          this.focusFirstElement();
        });
      });
    });
  },
  beforeDestroy() {
    this.$store.commit('forms/removeDisplayedForm', this.getFormId);
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.form && this.$refs.form.querySelector('.formElement')) {
        this.$refs.form.querySelector('.formElement').focus();
      }
    },

    async onSubmit() {
      if (this.isReadOnly)
        return false;

      if (await this.$refs.validationObserver.validate()) {
        this.loading = true;

        this.$emit('submit', this.onRequestComplete);
      } else {
        // Enable aggressive Error Display mode which will render all validation messages in Red.
        this.$store.commit('userInterface/setValidationAggressiveErrorDisplayMode', true);
      }
    },
    onRequestComplete() {
      this.loading = false;
      this.resetForm();
    },
    resetForm(silent = false) {
      if (this.$refs.validationObserver) {
        this.$refs.validationObserver.reset(silent);
      }
    }
  },
  computed: {
    getFormId() {
      let formName = this.name !== undefined ? this.name : this.$parent.$options.name;
      return formName + '-' + this._uid;
    },

    /**
     *  Returns the first <Card> component node that is the parent of this form.
     */
    getFirstCardParent() {
      let component = null;
      let parent = this.$parent;
      while (parent && !component) {
        if (parent.$options.name === "Card") {
          component = parent;
        }
        parent = parent.$parent;
      }
      return component
    },

    isReadOnly() {
      return this.$isReadOnly();
    },

    getButtonClasses() {
      if (this.customButtonClasses) {
        return this.customButtonClasses;
      }
      return 'flex-none';
    },

    additionalButtonsSlotSet() {
      return !!this.$slots.additionalButtons;
    },
  }
}
</script>

