<template>
  <section class="min-h-screen flex items-stretch vld-parent">
    <loading-indicator></loading-indicator>

    <div class="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center luxcaddy-bg-pattern">
      <div class="absolute bg-black opacity-30 inset-0 z-0"></div>
      <div class="w-full px-24 z-10 text-white text-center">
        <h1 class="text-5xl font-bold tracking-wide">Luxcaddy Backoffice</h1>
        <p class="text-3xl my-4">Veuillez vous authentifier avec vos données.</p>
      </div>
    </div>
    <div class="lg:w-1/2 w-full flex items-center justify-center text-center md:px-8 px-0 z-0 relative bg-gray-900">
      <div
        class="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center luxcaddy-bg-pattern">
        <div class="absolute bg-black opacity-60 inset-0 z-0"></div>
      </div>
      <div
        class="lg:w-3/4 xl:w-2/3 2xl:1/2 w-full z-20 bg-white ring-4 ring-luxcaddy-500 border border-luxcaddy-600 | mx-3 sm:mx-6 md:mx-10 lg:mx-0 | py-6 p-1 md:p-6 lg:p-8 rounded-xl">
        <logo-svg
          class="mb-8 xl:mb-2 lg px-8 2xl:px-24"
        />

        <router-view></router-view>

        <div
          class="hidden sm:block bottom-0 right-0 absolute p-4 text-center bg-black opacity-70 text-gray-400 w-full flex justify-center space-x-4 text-white">
          Copyright &copy; 2006 - {{ new Date().getFullYear() }} Itix S.A.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoadingIndicator from "../../elements/loading/LoadingIndicator";
import LogoSvg from "@/components/assets/LogoSvg";

export default {
  name: "AuthLayout",
  components: {LogoSvg, LoadingIndicator}
}
</script>

