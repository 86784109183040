<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :enable-search="enableSearch"
    :label="label"
    :limit-option-menu-width="limitOptionMenuWidth"
    :options="availablePermissions"
    :touch-optimized="touchOptimized"
    :type="type"
    :validation-rules="validationRules"
    :value="value"
    multi
    @input="$emit('input', $event)"
  ></select-element>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import PermisssionRepository from "@/repositories/PermisssionRepository";

export default {
  name: "PermissionSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Autorisations',
      required: false,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'single'
    },
    /**
     * When enabled, the option menu will not have the same length as the  input, when the input is very long
     */
    limitOptionMenuWidth: {
      type: Boolean,
      default: true
    },
    touchOptimized: {
      type: Boolean,
      default: false,
    },
    allowUnselecting: {
      type: Boolean,
      default: true
    },
    /**
     * Options will be a flat array containing permission names
     */
    byName: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.options === undefined) {
      this.fetchPermissions();
    } else {
      this.availablePermissions = this.options;
    }
  },
  methods: {
    fetchPermissions() {
      PermisssionRepository.get().then((res) => {

        if (this.byName) {
            this.availablePermissions = res.data.data.map(r => {
              return {
                  label: r.name,
                  value: r.name
              };
            }).sort((a, b) => a.label - b.label);
        } else {
          this.availablePermissions = res.data.data.map(r => {
            return {
              label: r.name,
              value: r.id
            };
          }).sort((a, b) => a.label - b.label);
        }
      });
    }
  },
  data: () => ({
    availablePermissions: [],
  })
}
</script>

