<template>
  <card title="Créer produit">
    <create-or-update-product-form mode="create"></create-or-update-product-form>
  </card>
</template>

<script>
import CreateOrUpdateProductForm from "../Forms/CreateOrUpdateProductForm";
import Card from "../../../elements/cards/Card";

export default {
  name: "CreateProduct",
  components: {Card, CreateOrUpdateProductForm},
}
</script>

