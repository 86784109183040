<template>
  <div class="h-4 text-right">
        <span v-if="errors.length" :class="[aggressiveErrorDisplay ? 'text-red-400': 'text-gray-400 text-light']"
              class="text-sm">
            {{ errors[0] }}
        </span>
  </div>
</template>

<script>
export default {
  name: "InputErrorMessage",
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  computed: {
    aggressiveErrorDisplay() {
      return this.$store.getters['userInterface/isValidationInAggressiveErrorDisplayMode'];
    },
    isReadOnly() {
      return this.$isReadOnly();
    }
  }
}
</script>

