<template>
  <div>

    <info-alert class="mb-8" size="small">
      Attention : quelle que soit l'autorisation sélectionnée, seuls les utilisateurs disposant de l'autorisation
      "REPORT_QUERY_EXECUTE" peuvent accéder à la page d'exécution des rapports.
    </info-alert>

    <form-wrapper
      ref="form"
      :show-changed="true"
      :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer requête'"
      @submit="createOrUpdateQuery"
    >
      <form-grid class="mb-6 grid-cols-1 lg:grid-cols-3">
        <text-input-element
          v-model="name"
          label="Nom"
          validation-rules="required"
        >

        </text-input-element>

        <permission-select-input
          v-model="permissions"
          label="Autorisations nécessaires"
        />

        <text-area-element
          v-model="queryString"
          additional-input-classes="h-96"
          class="lg:col-span-3"
          style="font-family: monospace;"
          label="SQL Query"
          validation-rules="required"
        >

        </text-area-element>

        <text-area-element
          v-model="description"
          additional-input-classes="h-32"
          class="lg:col-span-3"
          label="Description"
          validation-rules="required"
        />
        <text-area-element
          v-model="graphConfig"
          additional-input-classes="h-96"
          class="lg:col-span-3"
          style="font-family: monospace;"
          label="Graph config"
        />
      </form-grid>

      <card icon="info-circle" size="small" title="Afficher en tant que graphique sur la page d'accueil"
            type="light">

        <template>
          <role-select-input
            v-model="graphRoles"
            label="Rôles pour lesquels le graphique est affiché"
            type="multi"
          />
        </template>
      </card>
    </form-wrapper>
  </div>
</template>

<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import ReportQueryRepository from "@/repositories/ReportQueryRepository";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement.vue";
import PermissionSelectInput from "@/components/elements/forms/elements/PermissionSelectInput.vue";
import FormGrid from "@/components/elements/forms/FormGrid.vue";
import InfoAlert from "@/components/elements/alerts/InfoAlert.vue";
import Card from "@/components/elements/cards/Card.vue";
import RoleSelectInput from "@/components/elements/forms/elements/RoleSelectInput.vue";

export default {
  name: "CreateOrUpdateReportQueryForm",
  components: {
    RoleSelectInput,
    Card,
    InfoAlert,
    FormGrid,
    PermissionSelectInput,
    TextAreaElement,
    TextInputElement,
    FormWrapper,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    }
  },
  data: () => ({
    id: '',
    name: '',
    description: '',
    queryString: '',
    permissions: [],
    graphConfig: null,
    graphRoles: [],

  }),
  mounted() {
    this.getReportQuery();
  },
  methods: {
    getReportQuery() {
      if (this.isUpdateMode) {
        ReportQueryRepository.getSingle(this.$route.params.reportQueryId).then((res) => {
          this.id = res.data.data.id;
          this.name = res.data.data.name;
          this.description = res.data.data.description;
          this.queryString = res.data.data.query;
          this.permissions = res.data.data.permissions;
          this.graphConfig = res.data.data.graphConfig;
          this.graphRoles = res.data.data.graphRoles;
        }).then(() => this.$refs.form.resetForm());
      }
    },
    createOrUpdateQuery(callback) {
      if (this.isUpdateMode) {
        return ReportQueryRepository.update(this.id, {
          name: this.name,
          description: this.description,
          queryString: this.queryString,
          permissions: this.permissions,
          graphRoles: this.graphRoles,
          graphConfig: this.graphConfig
        }).then(() => {
          this.$sendSuccessToast(`Requête a été modifié!`);
        }).finally(() => callback());
      }

      return ReportQueryRepository.create({
        name: this.name,
        description: this.description,
        queryString: this.queryString,
        permissions: this.permissions,
        graphConfig: this.graphConfig,
        graphRoles: this.graphRoles,
      }).then(res => {
        this.$router.push({name: 'reportQueries.edit', params: {reportQueryId: res.data.data.id}});
        this.$sendSuccessToast(`Requête a été crée!`);
      }).finally(() => callback());

    },
  },
  computed: {
    isUpdateMode() {
      return this.mode === 'update';
    }
  }
}
</script>
