<template>
  <div>
    <display-product-images ref="imageDisplay" :product-id="productId" class="mb-6"/>
    <upload-product-images :product-id="productId" @uploaded="onImagesUploaded"/>
  </div>
</template>

<script>
import UploadProductImages from "./Upload/UploadProductImages";
import DisplayProductImages from "./Existing/DisplayProductImages";

export default {
  name: "ManageProductImages",
  components: {DisplayProductImages, UploadProductImages},
  props: {
    productId: {
      type: Number,
      required: true
    }
  },
  methods: {
    // Executed whenever new images have been uploaded
    onImagesUploaded() {
      this.$refs.imageDisplay.fetchImages();
    }
  },
}
</script>

