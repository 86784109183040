import {FILTER_DATETIME_RANGE,} from "@/components/listTable/includes/controls/filters/availableFilters";
import {productFilters} from "@/helpers/listTableModels/ListTableProductFilters";
import dayjs from "dayjs";

let filters = [
  {
    filterKey: 'createdPeriod',
    caption: 'Crée entre',
    type: FILTER_DATETIME_RANGE
  },
  ...productFilters.filter(f => f.filterKey !== 'isPublished' && f.filterKey !== 'isDeleted'),
];

export let productOrderStatsFilters = [
  ...filters,
  {
    filterKey: 'deliveryPeriod',
    caption: 'Livraison entre',
    type: FILTER_DATETIME_RANGE,
    defaultValue: {
      startDate: dayjs().startOf('month').format('DD.MM.YYYY HH:mm:ss'),
      endDate: dayjs().startOf('month').format('DD.MM.YYYY HH:mm:ss'),
    },
    forceIsVisible: true,
  },
];

export let supplierSalesStatsFilters = [
  ...filters,
  {
    filterKey: 'deliveryPeriod',
    caption: 'Livraison entre',
    type: FILTER_DATETIME_RANGE,
    defaultValue: {
      startDate: dayjs().startOf('month').format('DD.MM.YYYY HH:mm:ss'),
      endDate: dayjs().endOf('month').format('DD.MM.YYYY HH:mm:ss'),
    },
    forceIsVisible: true,
  },
];