<template>
  <div id="app" class="p-0 m-0 w-screen h-screen overflow-y-hidden">
    <vue-confirm-dialog/>
    <transition mode="out-in" name="fade">
      <component :is="layout">
        <transition name="fade">
          <router-view/>
        </transition>
      </component>
    </transition>
  </div>
</template>

<script>
import DashboardLayout from "./components/layouts/dashboard/DashboardLayout";
import AuthLayout from "./components/layouts/auth/AuthLayout";
import BlankLayout from "./components/layouts/BlankLayout";
import TouchInterfaceLayout from "@/components/layouts/touchInterface/TouchInterfaceLayout";

export default {
  name: 'App',
  components: {
    DashboardLayout,
    AuthLayout,
    TouchInterfaceLayout,
    BlankLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'Dashboard') + 'Layout';
    }
  },
  beforeMount() {
    // this.$store.dispatch('loading/cancelAllRequests');
    this.$store.commit('authUser/syncFromLocalStorage');
  },
  watch: {
    '$route'() {
      // Hard refresh if app-reload-needed set
      if (this.$store.getters['app/isAppRefreshNeeded']) {
        window.location.reload();
      }
    }
  },
}
</script>