<template>
  <div v-if="loading" class="flex justify-center">
    <loading-svg-inline class="w-24 h-24"/>
  </div>

  <div v-else>
    <div class="p-4">
      <touch-optimized-button
        size="text-xl sm:text-2xl"
        type="dark"
        @click="depositMenuOpen = !depositMenuOpen"
      >
        <div class="px-2 flex gap-x-2">
          <div class="bg-gray-700 rounded-full px-1.5 flex flex-col justify-center">
            <fa-icon class="text-sm" fixed-width icon="plus"/>
          </div>
          <div class="text-center flex-1">
            Vidanges
          </div>
        </div>
      </touch-optimized-button>
    </div>

    <touch-optimized-modal ref="modal" @close="onModalClose">
      <template v-if="modalOpenedForDepositId">
        <h1 class="text-2xl sm:text-2xl mb-6">
          {{ getDepositObjectOpenedInModal.name }}
        </h1>

        <div class="flex space-x-2 items-center">
          <number-input-element
            ref="depositQuantityInput"
            v-model="depositQuantitySelection"
            label="Quantité"
            touch-optimized
          />

          <touch-optimized-modal-button
            border-class="border-yellow-200"
            text-class="text-lg text-yellow-200"
            @click="confirmQuantityFromModal"
          >
            Confirmer
          </touch-optimized-modal-button>
        </div>
      </template>
    </touch-optimized-modal>

    <div v-show="depositMenuOpen" class="px-4 pb-4">
      <div class="p-4 border border-gray-400 rounded flex flex-col space-y-1.5">
        <div
          v-for="deposit in getDeposits"
          :key="deposit.id"
          :class="{'font-bold': getSelectedDeposits.find(d => d.id === deposit.id).quantity !== 0}"
          class="p-2 bg-gray-200 rounded-md"
          @click="openSelectDepositQuantityModal(deposit.id)"
        >
          <div class="flex space-x-2 sm:space-x-3 text-sm sm:text-md">
            <div class="w-12 sm:w-16">{{
                getSelectedDeposits.find(d => d.id === deposit.id).quantity
              }}x
            </div>
            <span class="flex-1">{{ deposit.localizedName }}</span>
          </div>
        </div>
      </div>
    </div>

    <touch-interface-card-with-loading-indicator>
      <h3 class="font-bold">Checklist</h3>

      <div class="mt-2 bg-white p-1 sm:p-2 rounded-md flex">
        <checkbox-element
          v-model="checks.deposits"
          :occupy-validation-space="false"
          hide-checked-label
          label="Vidanges notés ?"
          label-inline
        />
      </div>

      <div
        v-if="hasFrozenItems"
        class="mt-2 bg-white p-1 sm:p-2 rounded-md flex"
      >
        <checkbox-element
          v-model="checks.frozen_products"
          :occupy-validation-space="false"
          hide-checked-label
          label="Produits surgelé délivrès ?"
          label-inline
        />
      </div>
    </touch-interface-card-with-loading-indicator>

    <div class="p-4">
      <touch-optimized-button
        :disabled="!canMarkAsDelivered"
        size="text-xl sm:text-2xl"
        type="secondary"
        @click="redirectToSignaturePage"
      >
        <div class="px-2 flex space-x-2">
          <div class="text-center flex-1">
            Client présent
          </div>

          <div class="bg-gray-700 text-white rounded-full px-2 flex flex-col justify-center">
            <fa-icon class="text-sm" fixed-width icon="arrow-right"/>
          </div>
        </div>
      </touch-optimized-button>
      <touch-optimized-button
        :disabled="!canMarkAsDelivered"
        class="mt-4"
        size="text-xl sm:text-2xl"
        type="secondary"
        @click="markDeliveryAsDelivered"
      >
        <div class="px-2 flex space-x-2">
          <div class="text-center flex-1">
            Client absent
          </div>

          <div class="bg-gray-700 text-white rounded-full px-2 flex flex-col justify-center">
            <fa-icon class="text-sm" fixed-width icon="arrow-right"/>
          </div>
        </div>
      </touch-optimized-button>
    </div>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchInterfaceCardWithLoadingIndicator
  from "@/components/pages/TouchInterface/Shared/TouchInterfaceCardWithLoadingIndicator";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "DriverMyRouteDeliveryDetailPage",
  components: {
    TouchOptimizedModalButton,
    NumberInputElement,
    TouchOptimizedModal,
    LoadingSvgInline,
    CheckboxElement,
    TouchInterfaceCardWithLoadingIndicator,
    TouchOptimizedButton
  },
  data: () => ({
    loading: false,
    checks: {
      deposits: false,
      frozen_products: false
    },

    depositMenuOpen: false,
    modalOpenedForDepositId: null,
    depositQuantitySelection: 0,
  }),
  created() {
    this.clearStoreIfDifferentDelivery(this.$route.params.delivery);
    this.setIsAllowedOnSignaturePage(false);

    // Only load data if we haven't already loaded data for this delivery.
    this.$nextTick(() => {
      if (this.getDeliveryId === null || this.getDeliveryId !== Number(this.$route.params.delivery)) {
        this.loading = true;
        this
          .fetchDeliveryData(this.$route.params.delivery)
          .finally(() => this.loading = false);
      }

      // Check if there is deposit preset. If so, the deposit menu
      // should be opened so the user visually sees there is deposit preselected.
      if (this.getSelectedDeposits.filter(d => d.quantity > 0).length > 0) {
        this.depositMenuOpen = true;
      }
    })
  },
  methods: {
    ...mapActions('touchInterface/drivers/deliveries', [
      'fetchDeliveryData',
      'markDelivered',
    ]),
    ...mapMutations('touchInterface/drivers/deliveries', [
      'clearStoreIfDifferentDelivery',
      'setIsAllowedOnSignaturePage'
    ]),

    markDeliveryAsDelivered() {
      this.markDelivered({
        id: this.$route.params.delivery,
        customerPresent: false
      }).then(() => {
        this.$sendSuccessToast("Livraison marqué comme delivré!");
        // Redirect back to the list of deliveries of the same route.
        this.$router.push({
          name: 'touchInterface.delivery.fulfilment.myroutes.route.index',
          params: {
            route: this.$route.params.route
          }
        });
      });
    },
    // Customer is present, go to Signature page where we also show an overview of the deposits.
    redirectToSignaturePage() {
      // User will be redirected back if not set.
      this.setIsAllowedOnSignaturePage(true);

      this.$nextTick(() => {
        this.$router.push({
          name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.items.mark.signature.index',
          params: {
            route: this.$route.params.route,
            delivery: this.$route.params.delivery
          }
        })
      })
    },
    openSelectDepositQuantityModal(depositId) {
      this.modalOpenedForDepositId = depositId;
      const selectedDepositQuantity = this.getSelectedDeposits.find(d => d.id === this.modalOpenedForDepositId).quantity;

      if (selectedDepositQuantity !== 0) {
        this.depositQuantitySelection = selectedDepositQuantity;
      }

      this.$nextTick(() => {
        this.$refs.modal.open();

        this.$nextTick(() => {
          this.$refs.depositQuantityInput.focus();
        })
      });
    },
    onModalClose() {
      this.depositQuantitySelection = 0;
    },
    confirmQuantityFromModal() {
      this.getSelectedDeposits.find(d => d.id === this.modalOpenedForDepositId).quantity = this.depositQuantitySelection;
      this.$refs.modal.close();
    }
  },
  computed: {
    ...mapGetters('touchInterface/drivers/deliveries', [
      'getDelivery',
      'getDeposits',
      'getSelectedDeposits',
      'getDeliveryId',
    ]),
    hasFrozenItems() {
      if (this.getDelivery === null)
        return false;

      return this.getDelivery.hasFrozenItems;
    },
    canMarkAsDelivered() {
      if (this.hasFrozenItems) {
        return this.checks.deposits && this.checks.frozen_products;
      }
      return this.checks.deposits;
    },
    getDepositObjectOpenedInModal() {
      if (this.modalOpenedForDepositId === null) {
        return null;
      }
      return this.getDeposits.find(d => d.id === this.modalOpenedForDepositId);
    }
  },
}
</script>

<style scoped>

</style>