<template>
  <div>
    <modal ref="copyBlogPostsModal" show-close-button title="Copier articles" @close="onModalClose">
      <div style="min-width: 600px;">
        <div class="text-xl mb-6">Veulliez sélectionner une catégorie:</div>

        <card class="max-h-screen" type="light">
          <category-selection-tree type="BlogPost"/>
        </card>

        <transition name="fade">
          <info-alert v-if="categorySelected" class="mt-10">
            {{ getBlogPostCount }} produits seront copiés dans la catégorie <span
            class="font-bold">{{ selectedCategory.name }}</span>
          </info-alert>
        </transition>
      </div>

      <template #footer>
        <div class="flex justify-end gap-4">
          <button-element :disabled="!categorySelected" type="primary" @click="copySelectedBlogPosts">
            <fa-icon class="mr-2" fixed-width icon="clone"></fa-icon>
            Copier produits selectionnées
          </button-element>
        </div>
      </template>
    </modal>

    <modal ref="moveBlogPostsModal" show-close-button title="Déplacer produits">
      <div style="min-width: 600px;">
        <div class="text-xl mb-6">Veulliez sélectionner une catégorie:</div>

        <card class="max-h-screen" type="light">
          <category-selection-tree type="BlogPost"/>
        </card>

        <transition name="fade">
          <info-alert v-if="categorySelected" class="mt-10">
            {{ getBlogPostCount }} produits seront déplacés dans la catégorie <span
            class="font-bold">{{ selectedCategory.name }}</span>
          </info-alert>
        </transition>
      </div>

      <template #footer>
        <div class="flex justify-end gap-4">
          <button-element :disabled="!categorySelected" type="primary" @click="moveSelectedBlogPosts">
            <fa-icon class="mr-2" fixed-width icon="exchange-alt"></fa-icon>
            Déplacer produits selectionnées
          </button-element>
        </div>
      </template>
    </modal>

    <delete-confirmation-modal ref="removeBlogPostsModal" title="Retirer articles"
                               @delete="removeSelectedBlogPosts">
      Étes vous sure que vous voulez retirer les articles selectionnées de cette catégorie?
    </delete-confirmation-modal>
  </div>
</template>

<script>
import Modal from "@/components/elements/modals/Modal";
import CategorySelectionTree from "@/components/elements/categoryManagement/Tree/Selection/CategorySelectionTree";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import InfoAlert from "@/components/elements/alerts/InfoAlert";
import Card from "@/components/elements/cards/Card";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal";
import CategoryRepository from "@/repositories/CategoryRepository";

export default {
  name: "SelectedBlogPostsInCategoryActions",
  components: {DeleteConfirmationModal, Card, InfoAlert, ButtonElement, CategorySelectionTree, Modal},
  props: {
    selectedBlogPosts: {
      type: Array
    }
  },
  methods: {
    /*
     *  ACTIONS
     */
    removeSelectedBlogPosts() {
      const postIds = this.getSelectedBlogPosts.flatMap(p => p.id);
      this.$emit('remove', postIds);
    },
    copySelectedBlogPosts() {
      const articlesToCopy = this.getSelectedBlogPosts.flatMap(p => p.id);

      CategoryRepository.copyBlogPosts(this.selectedCategory.id, articlesToCopy).then(() => {
        this.$sendSuccessToast('Les articles sélectionnés ont été copiés.');
        this.$store.commit('categories/setSelectedBlogPosts', []);
        this.$refs.copyBlogPostsModal.closeModal();
        this.$emit('change');
      });
    },
    moveSelectedBlogPosts() {
      const articlesToMove = this.getSelectedBlogPosts.flatMap(p => p.id);

      CategoryRepository.moveBlogPosts(this.getActiveCategory.id, this.selectedCategory.id, articlesToMove).then(() => {
        this.$sendSuccessToast('Les articles sélectionnés ont été déplacés.');
        this.$store.commit('categories/setSelectedBlogPosts', []);
        CategoryRepository.getSingle(this.getActiveCategory.id).then((res) => {
          this.$store.commit('categories/setActiveCategory', res.data.data);
          this.$emit('change');
        });
        this.$refs.moveBlogPostsModal.closeModal();
      });
    },

    onModalClose() {
      this.$store.commit('categories/setSelectedCategoryInSelectionTree', null);
    }
  },
  computed: {
    categorySelected() {
      return this.selectedCategory !== null;
    },
    selectedCategory() {
      return this.$store.getters['categories/getSelectedCategoryInSelectionTree'];
    },
    getActiveCategory() {
      return this.$store.getters['categories/getActiveCategory'];
    },
    getSelectedBlogPosts() {
      return this.$store.getters['categories/getSelectedBlogPosts'];
    },
    getBlogPostCount() {
      return this.selectedBlogPosts?.length || 0;
    }
  }
}
</script>

