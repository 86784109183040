<template>
  <div>
    <list-table
      :columns="columns"
      :create-route="{
                name: 'brands.create'
            }"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      identifier="brandsTable"
      title="Marques"
    >
    </list-table>
  </div>
</template>

<script>
import BrandRepository from "../../../repositories/BrandRepository";
import ListTable from "../../listTable/ListTable";

export default {
  name: "ShowBrands",
  components: {ListTable},
  data() {
    return {
      title: "Brands",
      repositoryFunc: BrandRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir marque',
              route: (row) => ({
                name: 'brands.edit',
                params: {
                  brand: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier marque',
              route: (row) => ({
                name: 'brands.edit',
                params: {
                  brand: row.id
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Delete',
              confirmation: {
                title: 'Abandonner marque?',
                text: 'Étes vous sure que vous voulez abandonner ce marque?'
              },
              successText: 'Le marque a été abandonné',
              deleteAction: (row) => BrandRepository.delete(row.id),
              deletePermissions: ['BRAND_WRITE']
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id',
          }
        },
        {
          caption: 'Nom',
          dataField: 'name',
          sort: {
            dataField: 'name',
            default: 'asc'
          }
        },

      ],
      filters: [
        {
          caption: "ID(s) ou Nom",
          filterKey: "brandIdsOrName",
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>
