import {FILTER_DATETIME_RANGE} from "@/components/listTable/includes/controls/filters/availableFilters";

const getDefaultState = () => {
  return {
    filters: [],
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getFilters: (state) => state.filters || [],
    getFiltersByTableIdentifier: (state) => (tableIdentifier) => {
      return [...state.filters].filter(f => f.tableIdentifier === tableIdentifier);
    },
    getFilterByIdentifierAndKey: (state) => (tableIdentifier, filterKey) => {
      return [...state.filters].find(f => f.tableIdentifier === tableIdentifier && f.filterKey === filterKey);
    }
  },
  mutations: {
    // Keeps filters but sets every value to null.
    resetFilterValues(state, tableIdentifier) {
      state
        .filters
        .filter(f => f.tableIdentifier === tableIdentifier)
        .forEach(f => {
          // Set each Sub Input Value to null
          if (f.filterValue !== null && typeof f.filterValue === "object") {
            Object.keys(f.filterValue).forEach(() => {
              f.filterValue = null;
            });
          }else {
            // Set normal input values to default value / null
            f.filterValue = f.defaultValue !== undefined
              ? f.defaultValue
              : null;
          }

          if (f.type === FILTER_DATETIME_RANGE) {
            if (f.defaultValue !== undefined) {
              f.filterValue = f.defaultValue;
            } else {
              f.filterValue = f.defaultValue = {
                startDate: null,
                endDate: null
              };
            }
          }

          // Hide every filter after resetting value that is not force to be visible
          if (!f.forceIsVisible) {
            f.isVisibleCurrently = false;
          }
        });
    },
    updateFilter(state, filter) {
      let savedFilter = findOrCreateFilter(state, filter);

      // Used to support reactivity
      Object.keys(filter).forEach(key => {
        savedFilter[key] = filter[key];
      });
    },

    moveFilterToEnd(state, {tableIdentifier, filterKey}) {
      const currentIndex = state.filters.findIndex(f => f.tableIdentifier === tableIdentifier && f.filterKey === filterKey);
      const filter = state.filters[currentIndex];

      state.filters.splice(currentIndex, 1);
      state.filters.splice(state.filters.length, 0, filter);
    },

    clearFilters(state) {
      state.filters = [];
    },
  },
}


/**
 * Finds or creates a filter for a table.
 *
 * @param state
 * @param filterData
 * @return {*}
 */
const findOrCreateFilter = function (state, filterData) {
  let filter = [...state.filters].find(filter => filter.filterKey === filterData.filterKey && filter.tableIdentifier === filterData.tableIdentifier);

  if (!filter) {
    // Properties that should always have a default, even when not set in the Filters Array on each Table:

    // Without this, reactivity on the properties here won't work.
    filterData.forceIsVisible = filterData.forceIsVisible === undefined ? false : filterData.forceIsVisible;

    // Initially make Filters visible that are forced to be visible.
    filterData.isVisibleCurrently = filterData.forceIsVisible === undefined ? false : filterData.forceIsVisible;

    state.filters.push(filterData);
  }

  return [...state.filters].find(filter => filter.filterKey === filterData.filterKey && filter.tableIdentifier === filterData.tableIdentifier);
}


