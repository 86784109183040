<template>
  <create-update-device-form mode="create"/>
</template>

<script>
import CreateUpdateDeviceForm from "@/components/pages/Devices/Forms/CreateUpdateDeviceForm";

export default {
  name: "CreateDevice",
  components: {CreateUpdateDeviceForm}
}
</script>

