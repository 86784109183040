import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";
import {vue} from "@/main";

const getDefaultState = () => {
  return {
    order: null,
    receivedItemValues: [],
    batchErrors: [],
    isInAddProductForceMode: false, // = Forces that you can add items to a supplier order with delivery date of today

    possibleOrderItemSorts: [ // value = item property to sort by
      {label: 'Nom produit (croissant)', value: 'name,asc'},
      {label: 'Nom produit (décroissant)', value: 'name,desc'},
      {label: 'Id produit (croissant)', value: 'productId,asc'},
      {label: 'Id produit (décroissant)', value: 'productId,desc'},
      {label: 'Réf. fournisseur (croissant)', value: 'supplierReference,asc'},
      {label: 'Réf. fournisseur (décroissant)', value: 'supplierReference,desc'},
    ],
    orderItemSort: 'name,asc',
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getOrder: state => state.order,
    allItemsTreated: state => (state.order.items || []).filter(i => i.receivedSellingUnitQuantity === null).length === 0,
    hasOrder: state => state.order !== null,
    getOrderId: state => state.order?.id || null,
    getOrderItems: state => {
      let sort = state.orderItemSort.split(',');
      let sortKey = sort[0];
      let direction = sort[1];

      return (state.order?.items || []).sort((a, b) => {
        return direction === "asc"
          ? typeof a[sortKey] === "string" ? a[sortKey].localeCompare(b[sortKey]) : a[sortKey] - b[sortKey]
          : typeof b[sortKey] === "string" ? b[sortKey].localeCompare(a[sortKey]) : b[sortKey] - a[sortKey];
      });
    },
    getJustInTimeOrderItems: state => state.order?.items.filter(i => i.isJustInTime) || [],
    isDraft: state => state.order?.isDraft || false,
    hasReceivedInvoice: state => state.order?.invoiceReceived || false,
    getInvoiceNumber: state => state.order?.invoiceNumber,
    getReceivedItemValues: state => state.receivedItemValues,
    getBatchErrors: state => state.batchErrors || [],
    getFiles: state => state.order?.files || [],
    getIsInAddProductForceMode: state => state.isInAddProductForceMode,

    getPossibleItemSorts: state => state.possibleOrderItemSorts,
    getOrderItemSort: state => state.orderItemSort,

    hasLinkedInvoicePDFIds: state => Array.isArray(state.order.externalInvoicePDFIds) && state.order.externalInvoicePDFIds.length > 0,
  },
  mutations: {
    setOrder(state, order) {
      state.order = order;
    },

    setOrderItemSort(state, sort) {
      state.orderItemSort = sort;
    },

    setIsInAddProductForceMode(state, bool) {
      state.isInAddProductForceMode = bool;
    },
    clearOrder(state) {
      state.order = null;
      state.receivedItemValues = null;
      state.batchErrors = [];
    },
    setItemReceived(state, {itemId, quantity}) {
      let item = state.receivedItemValues.find(i => i.itemId === itemId);
      if (!item) {
        state.receivedItemValues.push({
          itemId,
          quantity
        });
      } else {
        item.quantity = quantity;
      }
    },
    removeItemReceived(state, itemId) {
      state.receivedItemValues = state.receivedItemValues.filter(rV => rV.itemId !== itemId);
    },

    clearReceivedItemValues(state) {
      state.receivedItemValues = [];
    },

    setBatchErrors(state, errors) {
      state.batchErrors = errors;
    },
  },
  actions: {
    fetchOrder({commit}, orderId) {
      return SupplierOrderRepository.getSingle(orderId).then((res) => {
        commit('setOrder', res.data.data);
      });
    },
    addItemToOrder({dispatch, getters}, payload) {
      return SupplierOrderRepository.addItemToOrder(getters.getOrderId, payload)
        .then(() => {
          dispatch('fetchOrder', getters.getOrderId);
        });
    },
    editItem({dispatch, getters}, payload) {
      return SupplierOrderRepository.editItem(getters.getOrderId, payload)
        .then(() => {
          dispatch('fetchOrder', getters.getOrderId);
        });
    },
    updateReceivedQuantities({getters, dispatch, commit}, context = 'backoffice') {
      let itemsToUpdate = getters.getReceivedItemValues;

      let request = null;
      if (context === "backoffice") { // used by admins
        request = SupplierOrderRepository.setReceivedQuantities(
          getters.getOrderId,
          itemsToUpdate,
          true
        );
      } else if (context === "touchinterface") { // Used by warehouse workers
        request = SupplierOrderRepository.batchSetReceivedQuantityForTouchInterface(
          getters.getOrderId,
          itemsToUpdate,
          true
        );
      } else {
        alert("[DEVERROR] - Unsupported context for updateReceivedQuantities. Context: " + context);
        throw new Error("[DEVERROR] - Unsupported context for updateReceivedQuantities. Context: " + context);
      }

      commit('setBatchErrors', []);
      return request.then(() => {
        commit('clearReceivedItemValues');
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            dispatch('fetchOrder', getters.getOrderId).then(() => {
              commit('setBatchErrors', err.response.data.errors);

              if (err.response.data.message) {
                vue.$sendWarningToast(err.response.data.message);
              }
            });
          }
        }
      })
    },
  },
}