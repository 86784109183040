<template>
  <div>
    <list-table
      :columns="columns"
      :create-route="{name: 'roles.create'}"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      identifier="rolesTable"
      show-filters-immediately
    >
      <template v-slot:permissions-template="{ row }">
        <div class="font-mono">
          {{ row.permissions.slice(0, 5).join('&nbsp;&nbsp;&middot;&nbsp;&nbsp;') }}
        </div>

        <div
          v-if="row.permissions.length > 5"
          class="mt-3 text-luxcaddy-500 font-bold">
          + {{ (row.permissions.length - 5) }} autres
        </div>
      </template>
    </list-table>
  </div>
</template>

<script>
import ListTable from "../../listTable/ListTable";
import RoleRepository from "@/repositories/RoleRepository";

export default {
  name: "ShowRoles",
  components: {ListTable},
  data() {
    return {
      title: 'Rôles',
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir rôle',
              route: (row) => {
                return {
                  name: 'roles.edit',
                  params: {
                    roleId: row.id,
                  },
                  query: {readonly: true}
                };
              },
            },
            {
              type: 'edit',
              hint: 'Modifier requête',
              route: (row) => {
                return {
                  name: 'roles.edit',
                  params: {
                    roleId: row.id
                  }
                };
              }
            },
            {
              type: 'delete',
              hint: 'Delete',
              confirmation: {
                title: 'Supprimer rôle?',
                text: 'Étes vous sure que vous voulez supprimer cette rôle?'
              },
              successText: 'La rôle a été supprimmée',
              deleteAction: (row) => RoleRepository.delete(row.id),
              deletePermissions: ['ROLES_MANAGE']
            }
          ]
        },
        {
          caption: '#',
          width: 80,
          dataField: 'id',
          sort: {
            dataField: 'id',
          }
        },
        {
          caption: 'Nom',
          dataField: 'name',
          sort: {
            dataField: 'name',
            default: 'asc'
          }
        },
        {
          caption: 'Level',
          dataField: 'level',
          sort: {
            dataField: 'level',
          }
        },
        {
          caption: 'Permissions',
          cellTemplate: 'permissions-template'
        },
      ],
      repositoryFunc: RoleRepository.getRoles,
    }
  },
}

</script>
