<template>
  <base-input-element
    ref="input"
    :additional-input-classes="additionalInputClasses"
    :disabled="disabled"
    :inset-position="insetPosition"
    :inset-text="insetText"
    :label="label"
    :max="max"
    :min="min"
    :occupy-validation-space="occupyValidationSpace"
    :placeholder="placeholder"
    :select-on-focus="selectOnFocus"
    :small="small"
    :step="step"
    :touch-optimized="touchOptimized"
    :validation-rules="getValidationRules"
    :value="value"
    type="number"
    @blur="onBlur"
    @enter="$emit('enter', $event)"
    @input="onInput"
  />
</template>

<script>
import BaseInputElement from "./BaseInputElement";

export default {
  name: "NumberInputElement",
  components: {
    BaseInputElement,
  },
  data: () => ({
    errorMessage: ''
  }),
  props: {
    value: {
      type: [Number, null],
      required: false,
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: [String, Number],
      required: false
    },
    validationRules: {
      type: String,
      default: ''
    },
    insetText: {
      type: String,
      required: false
    },
    insetPosition: {
      type: String,
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    step: {
      type: String,
      required: false
    },
    min: {
      type: String,
      required: false
    },
    max: {
      type: String,
      required: false
    },
    touchOptimized: {
      type: Boolean,
      default: false
    },
    selectOnFocus: {
      type: Boolean,
      default: false
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    additionalInputClasses: {
      type: String,
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', this.getParsedValue(e));
    },
    onBlur(e) {
      this.$emit('blur', this.getParsedValue(e));
    },
    getParsedValue(val) {
      if (typeof val === "string") {
        if (val.length === 0) {
          return null;
        }
      }

      let num = Number.isNaN(val) ? null : parseInt(val);

      if (!this.allowNegative) {
        if (num !== null && num < 0) {
          num = 0;
        }
      }

      return num;
    },
    focus() {
      this.$refs.input.focus();
    }
  },
  computed: {
    getValidationRules() {
      let defaultRule = this.allowNegative ? 'integer' : 'numeric';

      return this.validationRules?.length ? `${this.validationRules}|${defaultRule}` : defaultRule;
    }
  }

}
</script>

