<template>
  <div>
    <select-element
      :allow-unselecting="allowUnselecting"
      :disabled="disabled"
      :enable-search="false"
      :label="label"
      :options="titleList"
      :validation-rules="validationRules"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "TitleSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Titre',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    allowUnselecting: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    titleList: [
      {value: 'M', label: 'Monsieur'},
      {value: 'F', label: 'Madame'},
      {value: 'Other', label: 'Autre'},
    ]
  })
}
</script>

