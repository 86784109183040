<template>
  <label class="flex gap-x-2 items-center">
    <input :checked="isChecked" :value="value" class="checkbox" type="radio"
           @change="$emit('change', $event.target.value)"/>
    <span v-if="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "RadioButtonComponent",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    "label": {type: String},
    "modelValue": {default: ""},
    "value": {type: String, default: undefined}
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value
    }
  }
}
</script>
