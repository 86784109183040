<template>
  <div>
    <modal
      ref="createUpdateModal"
      :blocking="true"
      :show-close-button="true"
      :title="(updateMode ? 'Modifier' : 'Créer') + ' créneau de livraison'"
      class="height: 400px"
      @close="$emit('close')"
    >
      <form-wrapper
        ref="form"
        :show-changed="false"
        :show-submit-button="false"
      >
        <div class="flex gap-x-16">
          <div>
            <form-label label="Date"/>
            <span v-if="date !== null">{{ date.format("DD.MM.YYYY") }}</span>
          </div>

          <time-picker-element
            v-model="startTime"
            class="flex-1"
            inline
            label="Heure de début"
            validation-rules="required"
          />

          <time-picker-element
            v-model="endTime"
            class="flex-1"
            inline
            label="Heure de fin"
            validation-rules="required"
          />

          <number-input-element
            v-model="deliveryLimit"
            class="flex-1"
            label="Nombre de commandes"
            validation-rules="required"
          />
        </div>
      </form-wrapper>

      <template #footer>
        <div :class="updateMode ? 'justify-between' : 'justify-end'" class="flex">
          <button-element v-if="updateMode" type="danger" @click="openDeleteModal">
            <fa-icon class="mr-2" fixed-width icon="trash"></fa-icon>
            Supprimer
          </button-element>

          <button-element type="primary" @click="onSubmit">
            <fa-icon class="mr-2" fixed-width icon="check"></fa-icon>
            {{ updateMode ? 'Sauvegarder' : 'Créer créneau' }}
          </button-element>
        </div>
      </template>
    </modal>

    <delete-confirmation-modal v-if="timeSlotId" ref="deleteModal" title="Supprimer créneau?"
                               @delete="deleteTimeSlot">
      Êtes-vous sure que vous voulez supprimer le créneau de livraison suivant?

      <div class="mt-4 p-4 w-full text-center">
        <div class="font-medium">
          {{ date.format('DD.MM.YYYY') }}
        </div>
        <div>
          <fa-icon fixed-width icon="clock"></fa-icon>
          {{ startTime }} - {{ endTime }}
        </div>
      </div>
    </delete-confirmation-modal>
  </div>
</template>

<script>
import Modal from "../../../../../elements/modals/Modal";
import FormWrapper from "../../../../../elements/forms/FormWrapper";
import FormLabel from "../../../../../elements/forms/elements/partials/FormLabel";
import TimePickerElement from "../../../../../elements/forms/elements/base/TimePickerElement";
import NumberInputElement from "../../../../../elements/forms/elements/base/NumberInputElement";
import TimeSlotRepository from "../../../../../../repositories/TimeSlotRepository";
import DeleteConfirmationModal from "../../../../../elements/modals/DeleteConfirmationModal";
import ButtonElement from "../../../../../elements/buttons/ButtonElement";

export default {
  name: "CreateEditDeleteDeliveryTimeSlotModal",
  components: {
    ButtonElement,
    DeleteConfirmationModal, NumberInputElement, TimePickerElement, FormLabel, FormWrapper, Modal
  },
  data: () => ({
    timeSlotId: null,
    zone: null,
    date: null,
    startTime: null,
    endTime: null,
    deliveryLimit: 20,
  }),
  methods: {
    openModal() {
      this.$refs.createUpdateModal.openModal();
    },
    closeModal() {
      this.$refs.createUpdateModal.closeModal();
    },
    openDeleteModal() {
      this.$refs.deleteModal.openModal();
    },
    setInitialData(id, {zone, startDate, endDate, deliveryLimit}) {
      this.timeSlotId = id;
      this.zone = zone;
      this.date = startDate;
      this.startTime = startDate.format("HH:mm");
      this.endTime = endDate.format("HH:mm");
      this.deliveryLimit = deliveryLimit;
    },
    createDateFromTime(time) {
      if (this.date === null || time === null)
        return null;

      let splitTime = time.split(':');

      return this.date
        .set('hours', Number(splitTime[0]))
        .set('minutes', Number(splitTime[1]));
    },
    onSubmit() {
      if (this.updateMode) {
        return this.updateTimeSlot();
      }
      return this.createTimeSlot();
    },
    createTimeSlot() {
      return TimeSlotRepository.create({
        zone: this.zone,
        startDate: this.getStartDate.format('YYYY-MM-DD HH:mm:ss'),
        endDate: this.getEndDate.format('YYYY-MM-DD HH:mm:ss'),
        deliveryLimit: this.deliveryLimit,
      }).then(() => {
        this.$emit("change");
        this.resetData();
        this.$refs.createUpdateModal.closeModal();
        this.$sendSuccessToast("Créneau crée!");
      })
    },
    updateTimeSlot() {
      return TimeSlotRepository.update(this.timeSlotId, {
        startDate: this.getStartDate.format('YYYY-MM-DD HH:mm:ss'),
        endDate: this.getEndDate.format('YYYY-MM-DD HH:mm:ss'),
        deliveryLimit: this.deliveryLimit,
      }).then(() => {
        this.$emit("change");
        this.resetData();
        this.$refs.createUpdateModal.closeModal();
        this.$sendSuccessToast("Créneau modifiée!");
      })
    },
    deleteTimeSlot() {
      return TimeSlotRepository.delete(this.timeSlotId).then(() => {
        this.$emit("change");
        this.resetData();
        this.$refs.createUpdateModal.closeModal();
        this.$sendSuccessToast("Créneau supprimé!");
      })
    },
    resetData() {
      this.timeSlotId = null;
      this.zone = null;
      this.date = null;
      this.startTime = null;
      this.endTime = null;
      this.deliveryLimit = 20;
    }
  },
  computed: {
    updateMode() {
      return this.timeSlotId !== null && this.timeSlotId !== undefined;
    },
    getStartDate() {
      return this.createDateFromTime(this.startTime);
    },
    getEndDate() {
      return this.createDateFromTime(this.endTime);
    }
  },
}
</script>

