import ShowVehicles from "@/components/pages/Vehicles/ShowVehicles.vue";
import CreateVehicle from "@/components/pages/Vehicles/Create/CreateVehicle.vue";
import UpdateVehicle from "@/components/pages/Vehicles/Edit/UpdateVehicle.vue";
import auth from "@/router/middlewares/authMiddleware";

export default [
  {
    component: ShowVehicles,
    path: '/vehicles',
    name: 'vehicles.list',
    meta: {
      title: "Véhicules",
      middleware: [auth],
      permissions: ['VEHICLES_READ'],
    }
  },
  {
    component: CreateVehicle,
    path: '/vehicles/create',
    name: 'vehicles.create',
    meta: {
      title: "Créer véhicule",
      middleware: [auth],
      breadcrumbs: ['vehicles.list'],
      permissions: ['VEHICLES_WRITE'],
    }
  },
  {
    component: UpdateVehicle,
    path: '/vehicles/:id/update',
    name: 'vehicles.update',
    meta: {
      title: "Créer véhicule",
      middleware: [auth],
      breadcrumbs: ['vehicles.list'],
      permissions: ['VEHICLES_WRITE'],
    }
  }
]