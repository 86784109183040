import apiService from './Base/BaseRepository';

const url = '/campaigns';


export default {
  /**
   * Fetches all campaigns.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`);
  },

  async updateProductCampaignsCache() {
    return await apiService.patch(`${url}/cacheProductCampaigns`);
  },

  /**
   * Fetches all campaigns.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  /**
   * Fetches a single campaign.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getSingle(campaignId) {
    return await apiService
      .get(`${url}/${campaignId}`);
  },

  /**
   * Creates a new campaign.
   *
   * @param payload
   * @returns {Promise<*>}
   */
  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  /**
   * Updates a given campaign.
   *
   * @param campaignId
   * @param payload
   * @returns {Promise<*>}
   */
  async update(campaignId, payload) {
    return await apiService.patch(`${url}/${campaignId}`, payload);
  },

  async endCampaign(campaignId) {
    return await apiService
      .patch(`${url}/${campaignId}/end`);
  },


  localization: {
    async getForCampaign(campaignId) {
      return await apiService.get(`${url}/${campaignId}/localization`);
    },

    async saveForCampaign(campaignId, payload) {
      return await apiService.post(`${url}/${campaignId}/localization`, payload);
    },

    async translate(campaignId, sourceLanguage, targetLanguages, attributes) {
      return await apiService.post(`${url}/${campaignId}/localization/translation`, {
        sourceLanguage, targetLanguages, attributes
      });
    }
  },


  rules: {
    async createRule(campaignId, payload) {
      return await apiService.post(`${url}/${campaignId}/rules`, payload);
    },
    async updateRule(campaignId, ruleContext, payload) {
      return await apiService.patch(`${url}/${campaignId}/rules/${ruleContext}`, payload);
    },
    async deleteRule(campaignId, ruleContext) {
      return await apiService.delete(`${url}/${campaignId}/rules/${ruleContext}`);
    },
  },

  batchActions: {
    async endCampaigns(campaignIds) {
      return await apiService.patch(`${url}/batchAction`, {
        jobAction: "endCampaigns",
        items: campaignIds
      });
    },
  }

}
