<template>
  <update-localization-form
    v-if="localizations"
    :localizable-id="getCategoryId"
    :localizations="localizations"
    localizable-type="category"
    @submit="saveLocalizations"
  />
</template>

<script>
import UpdateLocalizationForm from "@/components/forms/UpdateLocalizationForm";
import CategoryRepository from "@/repositories/CategoryRepository";

export default {
  name: "UpdateCategoryLocalization",
  components: {UpdateLocalizationForm},
  data: () => ({
    localizations: null
  }),
  props: {
    categoryId: {
      type: Number,
      required: true
    }
  },
  mounted() {
    CategoryRepository.localization.getForCategory(this.getCategoryId).then((res) => {
      this.localizations = res.data.data;
    })
  },
  computed: {
    getCategoryId() {
      return this.categoryId;
    }
  },
  methods: {
    saveLocalizations(payload) {
      CategoryRepository
        .localization
        .saveForCategory(this.getCategoryId, {
          localizableData: payload
        })
        .then(() => {
          this.$sendSuccessToast('Traduction sauvegardé!')
        })
    },
  },
}
</script>

