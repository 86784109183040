import MemberRepository from "@/repositories/MemberRepository";

export default {
  selectValues: member => ({
    id: member.id
  }),
  selectValuesFromId: memberId => ({id: memberId}),

  batchActions: [
    {
      label: "Envoyer un rappel de factures",
      action: (callBack, selectedRows) => {
        return MemberRepository
          .batchActions
          .sendInvoiceReminder(selectedRows)
          .finally(() => callBack());
      }
    }
  ],
};