<template>
  <div
    class="text-luxcaddy inline-block cursor-pointer border-dotted border-b-2 border-luxcaddy"
    @click.exact.stop="onClick(false)"
    @click.ctrl.exact.stop="onClick(true)"
    @mousedown.middle.stop="onClick(true)"
    @click.meta.stop="onClick(true)"
  >
    <slot></slot>
  </div>
</template>

<script>
import newTabMixin from "@/mixins/newTabMixin";

export default {
  name: "TextLink",
  props: {
    to: {
      type: Object,
    }
  },
  mixins: [newTabMixin],
  methods: {
    onClick(newTab = false) {
      if (!this.to) {
        this.$emit('click');
        return;
      }

      this.navigateFunc(newTab);
    },
    navigateFunc(newTab = false) {
      return newTab
        ? this.openRouteInNewTab(this.to)
        : this.$router.push(this.to);
    },
  },
}
</script>

<style scoped>

</style>