<template>
  <div>
    <list-table
      :columns="getColumns"
      :select-values="selectValues"
      :sent-table-rows="getResponseData"
      identifier="queryResponseTable"
      selectable
      enable-totals
    >
    </list-table>


    <div v-if="getResponseMeta" class="flex justify-between mt-8">
      <pagination-page-size-selector
        :items-from="getResponseMeta.from"
        :items-to="getResponseMeta.to"
        :items-total="getResponseMeta.total"
        :per-page.sync="getMetaPerPage"
      />

      <pagination-page-selector
        :page.sync="getMetaCurrentPage"
        :total-pages="getResponseMeta.lastPage"
      />
    </div>
  </div>
</template>

<script>
import PaginationPageSelector
  from "@/components/listTable/includes/controls/pagination/includes/PaginationPageSelector.vue";
import PaginationPageSizeSelector
  from "@/components/listTable/includes/controls/pagination/includes/PaginationPageSizeSelector.vue";
import ListTable from "@/components/listTable/ListTable.vue";

export default {
  name: "ReportQueryResponseTable",
  components: {ListTable, PaginationPageSizeSelector, PaginationPageSelector },
  data() {
    return {
      selectValues: row => {
        return {
          _rowIndex: row._rowIndex
        };
      },
    }
  },
  computed: {
    getColumns() {
      return this.getHeadings.map((val) => {
        const heading = val.label;
        return {
          caption: heading,
          dataField: heading,

          totals: {
            sum: heading
          },
        };
      })
    },

    getResponseData() {
      return this.$store.getters['reportQuery/getSelectedQueryExecutionResponse'];
    },
    getResponseMeta() {
      return this.$store.getters['reportQuery/getSelectedQueryExecutionMeta'];
    },
    getHeadings() {
      return Object.keys(this.getResponseData[0])
        .filter(key => key !== "_rowIndex") //used for selecting only
        .map(key => {
          let label = key.replaceAll('_', ' ');

          return {
            label: label,
          }
        });
    },

    getMetaPerPage: {
      get() {
        return this.$store.getters['reportQuery/getMetaPerPage'];
      },
      set(perPage) {
        this.$store.commit('reportQuery/setMetaPerPage', perPage);
        this.$store.dispatch('reportQuery/executeSelectedQuery', {
          perPage: perPage,
          page: 1
        });

      }
    },
    getMetaCurrentPage: {
      get() {
        return this.$store.getters['reportQuery/getMetaCurrentPage'];
      },
      set(currentPage) {
        this.$store.commit('reportQuery/setMetaCurrentPage', currentPage);
        this.$store.dispatch('reportQuery/executeSelectedQuery', {
          perPage: this.getMetaPerPage,
          page: currentPage
        });
      }
    }
  },
}
</script>