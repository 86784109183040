<template>
  <modal
    ref="modal"
    title="Calcul d'itinéraires automatique"
    @open="onModalOpen"
  >
    <div
      class="md:w-256 grid grid-cols-2 gap-16 text-left"
    >
      <automatic-route-planning-vehicle-selection/>

      <div>
        <div class="font-bold mb-4">
          Configuration
        </div>

        <div class="flex gap-x-3">
          <time-picker-element
            v-model="startTime"
            label="Heure de début"
            validation-rules="required"
          />

          <time-picker-element
            v-model="endTime"
            label="Heure fin"
            validation-rules="required"
          />
        </div>

        <div class="mt-4">
          <div class="font-bold mb-0.5">
            Quels objectives doivent être pris en compte dans la planification?
          </div>
          <div class="italic mb-4 text-xs">
             <span class="underline">
                 Attention:
             </span>
            l'ordre représente l'importance. 1 = le plus important, 2 = un peu moins important, etc.
          </div>

          <automatic-route-planning-goals-selection
            v-model="objectives"
            class="col-span-3"
          />
        </div>
      </div>


    </div>

    <template #footer>
      <div class="mt-16 flex gap-x-2 justify-end">
        <button-element
          type="danger"
          @click="$refs.modal.closeModal()"
        >
          Annuler
        </button-element>

        <button-element
          :disabled="!hasSelectedAtLeastOneVehicle || !hasObjectives"
          disabled-tooltip="Au moins un véhicule doit être sélectionné."
          @click="startAutoPlanning"
        >
          Lancer
        </button-element>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal";
import {mapActions, mapGetters} from "vuex";
import AutomaticRoutePlanningVehicleSelection
  from "@/components/pages/RouteManager/Includes/ActionBox/Includes/AutomaticRoutePlanning/Includes/ModalComponents/AutomaticRoutePlanningVehicleSelection";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import TimePickerElement from "@/components/elements/forms/elements/base/TimePickerElement.vue";
import AutomaticRoutePlanningGoalsSelection
  from "@/components/pages/RouteManager/Includes/ActionBox/Includes/AutomaticRoutePlanning/Includes/ModalComponents/AutomaticRoutePlanningGoalsSelection.vue";

export default {
  name: "RouteManagerAutomaticRoutePlanningModal",
  components: {
    AutomaticRoutePlanningGoalsSelection,
    TimePickerElement, ButtonElement, AutomaticRoutePlanningVehicleSelection, Modal
  },
  data() {
    return {
      startTime: "10:00",
      endTime: "14:00",
      objectives: []
    }
  },
  methods: {
    ...mapActions('routeManager/automaticPlanning', [
      'fetchAvailableVehicles',
      'startAutoPlanningJob'
    ]),
    onModalOpen() {
      this.fetchAvailableVehicles();
      this.setDefaultObjectives();
    },

    startAutoPlanning() {
      const date = this.getSelectedDate;

      const startDate = this.$date(date + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
      const endDate = this.$date(date + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');

      this.startAutoPlanningJob({
        startDate: startDate,
        endDate: endDate,
        objectives: this.objectives
      }).then(() => {
        this.$sendSuccessToast("Le système va maintenant générer automatiquement des itinéraires en fonction de vos paramètres. Veuillez noter que cela peut prendre jusqu'à 30 minutes.");
        this.$refs.modal.closeModal();
      })
    },

    setDefaultObjectives() {
      let allObjectives = this.$luxcaddyConfig('route.planningObjectives');

      // remove last objective maximizeTourCount as is opposite to  minimizeTourCount
      this.objectives = allObjectives.slice(0, 4).flatMap(i => i.value);
    }
  },
  computed: {
    ...mapGetters('routeManager', [
      'getSelectedDate'
    ]),
    ...mapGetters('routeManager/automaticPlanning', [
      'hasSelectedAtLeastOneVehicle'
    ]),

    hasObjectives() {
      return this.objectives?.length > 0;

    }
  },
}
</script>

<style scoped>

</style>