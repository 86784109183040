import apiService from './Base/BaseRepository';
import ApiService from "@/repositories/Base/BaseRepository";

const url = '/arrival-temperatures';


export default {

  /**
   * Fetches all arrival temperatures.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await ApiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/`);
  },

  /**
   * Returns a single temperature
   * @param temperatureId
   * @returns {Promise<*>}
   */
  async getSingle(temperatureId) {
    return await apiService.get(`${url}/${temperatureId}`);
  },

  async create(department, supplierMemberId, temperature, date) {
    return await apiService.post(`${url}/`, {
      department, supplierMemberId, temperature, date
    });
  },

  /**
   * Creates a new Arrival Temperature for the current day.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async createForToday(department, supplierId, temperature) {
    return await apiService.post(`${url}/today`, {
      department,
      supplierMemberId: supplierId,
      temperature
    });
  },

  /**
   * Delete a Temperature.
   * @param temperatureId
   * @returns {Promise<*>}
   */
  async delete(temperatureId) {
    return await apiService.delete(`${url}/${temperatureId}`);
  }
}
