<template>
  <form-wrapper ref="form" :show-submit-button="!usedInQuickAdd" @submit="modifyStock">
    <div class="flex flex-col md:flex-row justify-center gap-4 items-center">
      <number-input-element
        v-model="stock.quantity"
        :allow-negative="true"
        :label="showLabels ? 'Quantité' : ''"
        class="w-full md:flex-1"
        validation-rules="int_not_zero"></number-input-element>

      <text-input-element
        v-if="showCustomReasonField" v-model="stock.comment"
        :label="showLabels ? 'Explication' : ''"
        class="w-full md:flex-1"
        validation-rules="required"
      />

      <select-element
        v-model="stock.comment"
        :enable-search="false"
        :label="showLabels ? 'Sélection rapide' : ''"
        :options="getQuickSelectStockModificationReasons"
        class="w-full md:flex-1"
      />

      <checkbox-element
        v-model="stock.isIncome"
        checked-label="Is Income"
        unchecked-label="Is Income"
      />

      <form-submit-button v-if="usedInQuickAdd" class="self-start py-1 mx-auto md:mx-0" size="medium">
        <fa-icon v-show="isLoading" icon="circle-notch"></fa-icon>
        <fa-icon v-show="!isLoading" icon="check"></fa-icon>
      </form-submit-button>
    </div>
  </form-wrapper>
</template>

<script>
import FormWrapper from "../../../../elements/forms/FormWrapper";
import NumberInputElement from "../../../../elements/forms/elements/base/NumberInputElement";
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";
import ProductRepository from "../../../../../repositories/ProductRepository";
import FormSubmitButton from "../../../../elements/buttons/FormSubmitButton";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "AddManualStockToProductForm",
  components: {SelectElement, FormSubmitButton, TextInputElement, NumberInputElement, FormWrapper, CheckboxElement},
  props: {
    productId: {type: Number, required: true},
    showCustomReasonField: {type: Boolean, default: true},
    showLabels: {type: Boolean, default: true},
    formFieldsEqualWidth: {type: Boolean, default: false}
  },
  data: () => ({
    stock: {
      quantity: 0,
      comment: '',
      isIncome: false
    }
  }),
  methods: {
    modifyStock(callback) {
      if (this.usedInQuickAdd) {
        if (!this.stock.comment.length) {
          this.$showErrorDialog('Veuillez choisir une raison.')
          callback();
          return false;
        }
      }

      ProductRepository.stock.modifyStock(this.productId, this.stock).then(() => {
        this.$sendSuccessToast('Modification du stock sauvegardé!');
        this.stock = {quantity: 0, comment: '', isIncome: false};
        this.$refs.form.resetForm();
        this.$emit('update');
      })
        .finally(() => callback());
    }
  },
  computed: {
    getQuickSelectStockModificationReasons() {
      return this.$luxcaddyConfig('product.stock.stockModificationReasons');
    },
    usedInQuickAdd() {
      return this.showCustomReasonField === false && this.showLabels === false;
    },
    isLoading() {
      return this.$store.getters['loading/isRunningRequest'];
    }
  }
}
</script>

