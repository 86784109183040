import apiService from './Base/BaseRepository';

const url = '/departments';

export default {
  /**
   * Fetches all departments.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getAll(perPage = 999, page = 1) {
    return await apiService
      .setPagination(perPage, page)
      .get(`${url}`);
  },


  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getSingle(departmentId) {
    return await apiService.get(`${url}/${departmentId}`);
  },

  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  async update(departmentId, payload) {
    return await apiService.patch(`${url}/${departmentId}`, payload);
  },

  async delete(departmentId) {
    return await apiService.delete(`${url}/${departmentId}`);
  },

}
