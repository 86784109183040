<template>
  <div
    class="mt-4 shadow-xl bg-white bg-opacity-80 rounded-md px-2 w-max float-right select-none border border-gray-200"
  >
    <div class="text-6xl font-bold">{{ getDeliveriesWithRouteCount }} / {{ getTotalDeliveriesCount }}</div>
    <div :class="{'text-red-500': !allDeliveriesShown}" class="text-xl text-right  tracking-wider">
      Visible: <span class="">{{ getDeliveriesShownCount }}/{{ getTotalDeliveriesCount }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "RouteManagerDeliveryCounterBox",
  computed: {
    ...mapGetters('routeManager', [
      'getTotalDeliveriesCount',
      'getHiddenDeliveriesCount',
      'getDeliveriesWithRouteCount',
      'getDeliveriesWithoutGeocodingCount'
    ]),
    getDeliveriesShownCount() {
      return this.getTotalDeliveriesCount - this.getHiddenDeliveriesCount - this.getDeliveriesWithoutGeocodingCount;
    },
    allDeliveriesShown() {
      return this.getHiddenDeliveriesCount === 0;
    }
  }
}
</script>

