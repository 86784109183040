<template>
  <div class="flex justify-center">
    <div class="p-1 w-full lg:w-1/3">
      <touch-interface-holidays-year-list/>

      <touch-interface-card-with-loading-indicator
        :loading="$store.getters['touchInterface/myHolidays/getLoading']"
        class="mt-5 lg:mt-12 p-1.5"
        color="white"
        disable-padding
      >
        <table class="w-full table-auto">
          <thead>
          <tr class="lg:text-2xl">
            <th class="text-right pr-3 lg:pr-6">Date de début</th>
            <th class="text-left pl-3 lg:pl-6">Jour de reprise</th>
            <th class="text-right">Jours</th>
          </tr>
          </thead>
          <tbody>
          <touch-interface-holidays-single-holiday
            v-for="holiday in getHolidays"
            :key="holiday.id"
            :holiday="holiday"
          />

          <tr>
            <td class="pt-2 lg:text-3xl text-right font-bold text-luxcaddy" colspan="3">
              Total:
              {{ getTotalDays }}
            </td>
          </tr>
          </tbody>
        </table>
      </touch-interface-card-with-loading-indicator>
    </div>
  </div>
</template>

<script>
import TouchInterfaceHolidaysYearList
  from "@/components/pages/TouchInterface/PersonalSpace/Holidays/Sub/Year/TouchInterfaceHolidaysYearList.vue";
import TouchInterfaceCardWithLoadingIndicator
  from "@/components/pages/TouchInterface/Shared/TouchInterfaceCardWithLoadingIndicator.vue";
import TouchInterfaceHolidaysSingleHoliday
  from "@/components/pages/TouchInterface/PersonalSpace/Holidays/Sub/Holidays/TouchInterfaceHolidaysSingleHoliday.vue";

export default {
  name: "TouchInterfaceHolidayList",
  components: {
    TouchInterfaceHolidaysSingleHoliday,
    TouchInterfaceCardWithLoadingIndicator, TouchInterfaceHolidaysYearList
  },
  created() {
    this.$store.dispatch('touchInterface/myHolidays/fetchHolidaysForYear', new Date().getFullYear());
  },
  computed: {
    getHolidays() {
      return this.$store.getters['touchInterface/myHolidays/getHolidays'];
    },
    getTotalDays() {
      return this.$store.getters["touchInterface/myHolidays/getSummedTotalDaysCount"];
    }
  },
}
</script>