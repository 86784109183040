<template>
  <div>
    <page-title>Congés</page-title>
    <card>
      <card-tab id="graphTab" icon="chart-bar" title="Graphique">
        <show-member-holidays-timeline/>
      </card-tab>

      <card-tab id="chartTab" icon="list" title="Liste">
        <list-table
          :columns="columns"
          :create-route="{
                        name: 'member-holidays.create'
                    }"
          :filters="filters"
          :pagination="true"
          :repository-func="repositoryFunc"
          :select-values="selectValues"
          :title="title"
          enable-totals
          identifier="memberHolidaysTable"
          selectable
        >
        </list-table>
      </card-tab>
    </card>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import MemberHolidayRepository from "@/repositories/MemberHolidayRepository";
import {
  FILTER_BOOLEAN,
  FILTER_DATETIME_RANGE,
  FILTER_SELECT_SINGLE
} from "../../listTable/includes/controls/filters/availableFilters";
import Card from "@/components/elements/cards/Card";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import ShowMemberHolidaysTimeline from "@/components/pages/MemberHoliday/ShowMemberHolidaysTimeline";
import PageTitle from "@/components/elements/pages/PageTitle";
import dayjs from "dayjs";

export default {
  name: "ShowMemberHolidays",
  components: {PageTitle, ShowMemberHolidaysTimeline, CardTab, Card, ListTable},
  data() {
    return {
      selectValues: holiday => {
        return {
          id: holiday.id
        };
      },
      title: 'Congés Membres',
      repositoryFunc: (...args) => {
        return MemberHolidayRepository.getPaginated(...args);
      },
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'edit',
              hint: 'Modifier congé',
              route: (row) => ({
                name: 'member-holidays.update',
                params: {
                  memberId: row.memberId,
                  holidayId: row.id,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer le congé',
              confirmation: {
                title: 'Supprimer congé ?',
                text: 'Étes vous sure que vous voulez supprimer ce congé ?'
              },
              successText: 'Le congé a été supprimé.',
              deleteAction: (row) => MemberHolidayRepository.deleteSingle(row.memberId, row.id),
            }
          ]
        },
        {
          caption: '#',
          dataField: 'id',
          width: 0,
        },
        {
          caption: 'Member',
          dataField: 'memberName',
          sort: {
            dataField: 'memberName'
          }
        },
        {
          caption: 'Début',
          dataField: 'startDate',
          calculateCellValue: (r) => this.$date(r.startDate).format('DD.MM.YYYY'),
          sort: {
            dataField: 'startDate',
            default: 'desc'
          }
        },
        {
          caption: 'Fin',
          dataField: 'endDate',
          calculateCellValue: (r) => {
            let date = this.$date(r.endDate);

            if (this.$date().isAfter(date)) {
              return `<span class="text-red-500 line-through">${date.format('DD.MM.YYYY')}</span>`;
            }

            return date.format('DD.MM.YYYY');
          },
          sort: {
            dataField: 'endDate'
          }
        },
        {
          caption: 'Jours total',
          dataField: 'daysCount',
          totals: {
            sum: "daysCount",

          },
        },
      ],
      filters: [
        {
          caption: "En congé aujourd’hui",
          filterKey: "crossesToday",
          type: FILTER_BOOLEAN,
          small: true,
          forceIsVisible: true,
        },
        {
          caption: "ID(s) ou Nom Membre",
          filterKey: "memberIdOrName",
          forceIsVisible: true,
        },
        {
          caption: "Type du membre",
          filterKey: "memberType",
          type: FILTER_SELECT_SINGLE,
          filterOptions: [
            {label: "Tous", value: null},
            {label: "Employées", value: "employee"},
            {label: "Fournisseurs", value: "supplier"},
          ],
          forceIsVisible: true,
        },
        {
          filterKey: 'holidayStartDateBetween',
          caption: "Début entre",
          type: FILTER_DATETIME_RANGE,
          defaultValue: {
            startDate: dayjs().startOf('year').format('DD.MM.YYYY HH:mm:ss'),
            endDate: dayjs().endOf('year').format('DD.MM.YYYY HH:mm:ss'),
          },
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>

