<template>
  <div>
    <page-title>Créer article / recette</page-title>
    <card>
      <create-or-update-blog-post
        mode="create"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import CreateOrUpdateBlogPost from "@/components/pages/Blog/Posts/Forms/CreateOrUpdateBlogPost";
import Card from "@/components/elements/cards/Card";

export default {
  name: "CreateBlogPostPage",
  components: {Card, CreateOrUpdateBlogPost, PageTitle},
}
</script>

<style scoped>

</style>