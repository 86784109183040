import auth from "../middlewares/authMiddleware";
import ShowRecurringTasks from "@/components/pages/TasksRecurring/ShowRecurringTasks";
import CreateRecurringTask from "@/components/pages/TasksRecurring/CreateRecurringTask";
import EditRecurringTask from "@/components/pages/TasksRecurring/EditRecurringTask";

export default [
  {
    path: '/tasks-recurring',
    name: 'taskRecurring.list',
    component: ShowRecurringTasks,
    meta: {
      middleware: [auth],
      title: 'Listes des tâches récurrentes',
      permissions: ['RECURRING_TASKS_READ']
    }
  },
  {
    path: '/tasks-recurring',
    name: 'taskRecurring.create',
    component: CreateRecurringTask,
    meta: {
      middleware: [auth],
      title: 'Créer une tâche',
      breadcrumbs: ['taskRecurring.list'],
      permissions: ['RECURRING_TASKS_WRITE']
    }
  },
  {
    path: '/tasks-recurring/:task/edit',
    name: 'taskRecurring.edit',
    component: EditRecurringTask,
    meta: {
      middleware: [auth],
      title: 'Modifier une tâche',
      readOnly: {supported: true, title: 'Voir une tâche'},
      breadcrumbs: ['taskRecurring.list'],
      permissions: ['RECURRING_TASKS_WRITE']
    }
  },
]