<template>
  <GmapMap
    v-if="getCenterCoordinates"
    ref="map"
    :center="getCenterCoordinates"
    :options="options"
    :style="getStyles"
    :zoom="getZoomLevel"
    style="height: 600px"
  >

    <GmapInfoWindow
      :opened="infoWindowOpen"
      :options="infoOptions"
      :position="infoWindowPosition"
      @closeclick="infoWindowOpen = false"/>

    <GmapMarker
      v-for="(marker, i) in markers" :key="i"
      :clickable="true"
      :draggable="marker.draggable"
      :position="marker.position"
      @click="toggleInfoWindow(marker, i)"
      @dragend="marker.dragEvent"
    />

    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>

  </GmapMap>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    longitude: {
      type: Number,
      required: false
    },
    latitude: {
      type: Number,
      required: false
    },
    markers: {
      type: Array,
      required: false
    },
    heightInPx: {
      type: Number,
    },
    widthInPx: {
      type: Number,
      required: false
    },
    zoomLevel: {
      type: Number,
      default: 10
    },
    options: {
      type: Object
      // https://diegoazh.github.io/gmap-vue/#map-options
    }
  },
  data: () => ({
    infoWindowPosition: null,
    infoWindowOpen: false,
    currentMarkerIndex: null,
    infoOptions: {
      content: '',
      pixelOffset: {
        width: 0,
        height: -35
      }
    },
  }),
  mounted() {
    this.$refs.map.$mapPromise.then(() => {
      if (this.hasMarkers) {
        this.markers.forEach((m, i) => {
          if (m.showOnCreate) {
            this.toggleInfoWindow(m, i);
          }
        });
      }
    })
  },
  methods: {
    toggleInfoWindow: function (marker, index) {
      if (marker.infoText === undefined)
        return false;

      this.infoWindowPosition = marker.position;
      this.infoOptions.content = marker.infoText;

      if (this.currentMarkerIndex === index) {
        this.infoWindowOpen = !this.infoWindowOpen;
      } else {
        this.infoWindowOpen = true;
        this.currentMarkerIndex = index;
      }
    }

  },
  computed: {

    hasMarkers() {
      return this.markers !== undefined && this.markers.length;
    },
    validCoordinates() {
      return this.latitude !== null &&
        this.latitude !== undefined &&
        this.longitude !== null &&
        this.longitude !== undefined;
    },
    getCenterCoordinates() {
      if (this.validCoordinates) {
        return {
          lat: this.latitude,
          lng: this.longitude,
        }
      }

      // Mersch
      return {
        lat: 49.745689,
        lng: 6.1020854
      };
    },

    getZoomLevel() {
      if (this.validCoordinates) {
        return this.zoomLevel;
      }
      return 10;
    },

    getHeightAttr() {
      return `${this.heightInPx}px`;
    },
    getWidthAttr() {
      return this.widthInPx
        ? `${this.widthInPx}px`
        : undefined;
    },
    getStyles() {
      let styles = {
        height: this.getHeightAttr
      };
      if (this.getWidthAttr) {
        styles.width = this.getWidthAttr;
      }
      return styles;
    }
  },
}
</script>

<style>
.gm-ui-hover-effect {
  @apply bg-red-500;
}
</style>