<template>
  <form-wrapper ref="form"
                :show-changed="true"
                :submit-button-label="'Sauvegarder'"
                @submit="storeCustomerDetails">

    <form-grid class="grid-cols-12">
      <div class="flex items-center gap-x-4 col-span-12 mb-2">
        <div class="font-bold">Status:</div>
        <member-status-indicator
          :status="getStatus"
        />
      </div>
      <currency-input-element
        v-model="maxInvoicedAmountBeforeWarning" class="col-span-12 md:col-span-4"
        label="Dette maximale"
        validation-rules="required"
      />

      <email-input-element
        v-model="invoicingEmail"
        class="col-span-12 md:col-span-4"

        label="E-Mail comptabilité"
      />

      <select-element
        v-model="allowedPaymentProviders"
        :allow-unselecting="false"
        :options="getAllowedPaymentProviderList"
        class="col-span-12 md:col-span-4"
        label="Moyens de paiement"
        multi
        validation-rules="required"
      />
      <text-input-element
        v-model="peppolEndpointId"
        class="col-span-12 md:col-span-4"
        label="Peppol EndpointId">
      </text-input-element>

    </form-grid>


    <template #additionalButtons>

      <delete-confirmation-modal v-if="isMemberCustomer && isActive" ref="deactivateCustomerModal"
                                 delete-button-label="Désactiver" title="Désactivé le client?"
                                 @delete="deactivateCustomerDetails">
        Êtes vous sûre que vous voulez désactiver ce membre comme client?
      </delete-confirmation-modal>

      <button-element v-if="isMemberCustomer && isActive" class="float-right" type="danger"
                      @click="openDeactivateModal">
        Désactiver
      </button-element>

      <button-element v-if="isMemberCustomer && !isActive" class="float-right" type="warning"
                      @click="activateCustomer">
        Activer
      </button-element>

      <button-element :disabled="!isMemberCustomer" class="float-right mr-8" type="light"
                      @click="openShoppingCart">
        <fa-icon class="mr-2" fixed-width icon="shopping-basket"></fa-icon>
        Ouvrir panier d'achat
      </button-element>
    </template>

  </form-wrapper>
</template>

<script>
import FormWrapper from "../../../../elements/forms/FormWrapper";
import CustomerRepository from "../../../../../repositories/CustomerRepository";
import ButtonElement from "../../../../elements/buttons/ButtonElement";
import EmailInputElement from "../../../../elements/forms/elements/EmailInputElement";
import FormGrid from "../../../../elements/forms/FormGrid";
import CurrencyInputElement from "../../../../elements/forms/elements/CurrencyInputElement";
import DeleteConfirmationModal from "../../../../elements/modals/DeleteConfirmationModal";
import MemberStatusIndicator from "../../../../elements/display/member/MemberStatusIndicator";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "MemberCreateEditCustomerTab",
  components: {
    SelectElement,
    TextInputElement,
    DeleteConfirmationModal,
    CurrencyInputElement,
    FormGrid,
    EmailInputElement, ButtonElement, FormWrapper, MemberStatusIndicator
  },
  props: {
    member: {type: Object, required: true}
  },
  data: () => ({
    maxInvoicedAmountBeforeWarning: 0,
    invoicingEmail: null,
    allowedPaymentProviders: [],
    isActive: false,
    peppolEndpointId: null
  }),
  mounted() {
    if (this.isMemberCustomer) {
      this.getCustomerDetails(this.member.id);
    }
  },
  methods: {
    getCustomerDetails(id = this.member.id) {
      return CustomerRepository.getCustomerDetails(id).then((res) => {
        let data = res.data.data;

        this.maxInvoicedAmountBeforeWarning = data.maxInvoicedAmountBeforeWarning;
        this.invoicingEmail = data.invoicingEmail;
        this.allowedPaymentProviders = data.allowedPaymentProviders;
        this.isActive = data.isActive;
        this.peppolEndpointId = data.peppolEndpointId
      }).then(() => {
        this.$nextTick(() => this.$refs.form.resetForm());
      });
    },
    storeCustomerDetails(callback) {
      let payload = {
        maxInvoicedAmountBeforeWarning: this.maxInvoicedAmountBeforeWarning,
        invoicingEmail: this.invoicingEmail,
        allowedPaymentProviders: this.allowedPaymentProviders,
        peppolEndpointId: this.peppolEndpointId
      };

      CustomerRepository.storeCustomerDetails(this.member.id, payload).then(() => {
        this.$sendSuccessToast(`Mise à jour réussie du client ${this.member.fullName}!`);
      }).finally(() => {
        this
          .getCustomerDetails(this.member.id)
          .finally(() => {
            this.$emit('change');
            callback();
          });
      })
    },
    deactivateCustomerDetails() {

      if (!this.isMemberCustomer)
        return false;

      CustomerRepository.deactivateCustomerDetails(this.member.id).then(() => {
        this.closeDeactivateModal();
        this.$sendSuccessToast(`Client ${this.member.fullName} a été désactivé!`);
      }).finally(() =>
        this.getCustomerDetails(this.member.id).finally(() => this.$emit('change'))
      );

    },

    activateCustomer() {

      if (!this.isMemberCustomer)
        return false;

      CustomerRepository.activateCustomerDetails(this.member.id).then(() => {
        this.$sendSuccessToast(`Client ${this.member.fullName} a été activé!!`);
      }).finally(() =>
        this.getCustomerDetails(this.member.id).finally(() => this.$emit('change'))
      );

    },
    openDeactivateModal() {
      this.$refs.deactivateCustomerModal.openModal();
    },
    closeDeactivateModal() {
      this.$refs.deactivateCustomerModal.closeModal();
    },
    openShoppingCart() {
      let route = this.$router.resolve({
        name: 'carts.edit',
        params: {
          customer: this.member.id,
        }
      });

      window.open(route.href, '_blank');
    }
  },
  computed: {
    isMemberCustomer() {
      return this.member.isCustomer;
    },
    getAllowedPaymentProviderList() {
      return this.$luxcaddyConfig('member.customer.allowedPaymentProviders');
    },
    getStatus() {
      if (this.isActive) {
        return 'active';
      }

      return 'deactivated';

    },
  }
}
</script>

