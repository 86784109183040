<template>
  <div>
    <page-title>New</page-title>
    <div class="grid grid-cols-4 gap-2">
      <div>
        <member-select-input
          v-model="memberId"
          label="Member select with preselected"
        />

        {{ memberId }}
      </div>

      <div>
        <product-select-input
          v-model="productId"
          label="Product select with preselected"
        />

        {{ productId }}
      </div>

      <div>
        <brand-select-input
          v-model="brandId"
          label="Brand select with preselected"
        />

        {{ brandId }}
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput.vue";
import ProductSelectInput from "@/components/elements/forms/elements/ProductSelectInput.vue";
import BrandSelectInput from "@/components/elements/forms/elements/BrandSelectInput.vue";

export default {
  name: "TestPageSelectElement",
  components: {BrandSelectInput, ProductSelectInput, MemberSelectInput, PageTitle},
  data() {
    return {
      memberId: 40642,
      productId: 9798,
      brandId: 2,
    }
  },
}
</script>

<style scoped>

</style>