const ROLE_ADMIN = 'ADMIN';
const ROLE_SUPPLIER = 'SUPPLIER';

const getDefaultState = () => {
  return {
    user: {
      memberId: null,
      email: null,
      firstName: null,
      lastName: null,
      companyName: null,
      fullName: null,
      roles: [],
      permissions: [],
    },
    authenticated: false,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getAuthenticated(state) {
      return state.user.authenticated
    },
    getFullName: state => state.user.fullName,
    getFirstName: state => state.user.firstName ?? "",
    getLastName: state => state.user.lastName ?? "",
    isUserAuthenticated: state => state.user.authenticated,

    getEmail: state => state.user.email,
    getRoles: state => state.user.roles,
    isAdmin: state => state.user.roles.includes(ROLE_ADMIN),
    isSupplier: state => state.user.roles.includes(ROLE_SUPPLIER),
    getPermissions: state => {
      // We check localStorage first as on a reload, this might be
      // called before localStorage has been synced to the store.
      if (!state.user.permissions?.length) {
        const localStorageUser = localStorage.getItem('user');
        if (localStorageUser) {
          let parsedUser = JSON.parse(localStorageUser);

          return parsedUser.permissions;
        }
      }

      return state.user.permissions;
    },
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
      localStorage.setItem('user', JSON.stringify(userData));
    },

    setAuthenticated(state, value) {
      state.user.authenticated = value;
    },
    clearUser(state) {
      state.user = getDefaultState().user;
      localStorage.removeItem('user');
    },
    syncFromLocalStorage(state) {
      let user = localStorage.getItem('user');

      if (user) {
        state.user = JSON.parse(user);
      } else {
        state.user = getDefaultState().user;
      }
    }
  },
}