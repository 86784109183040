<template>
  <div class="relative">
    <transition name="fade">
      <div v-if="isMenuOpen"
           class="z-50 h-screen w-screen bg-gray-800 bg-opacity-90 left-0 top-0 overflow-y-auto fixed"
           @click="closeMenu"
      >
      </div>
    </transition>
    <transition name="slide">
      <div v-if="isMenuOpen"
           class="z-50 2xl:w-4/12 bg-white top-12 h-screen-minus-header overflow-y-auto absolute right-0">
        <div class="px-8 pt-8 pb-6 flex items-center justify-between">
          <p class="focus:outline-none text-2xl font-semibold leading-6 text-gray-800">
            Notifications
          </p>
          <button
            aria-label="close modal"
            class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md cursor-pointer"
            role="button"
            @click="closeMenu"
          >
            <fa-icon class="text-gray-600" fixed-width icon="times"/>
          </button>
        </div>

        <dashboard-notification-list/>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DashboardNotificationList
  from "@/components/layouts/dashboard/partials/notifications/DashboardNotificationList.vue";

export default {
  name: "DashboardNotificationMenu",
  components: {DashboardNotificationList},
  watch: {
    isMenuOpen(menuIsOpen) {
      if (menuIsOpen) {
        this.blockBackgroundScroll();
        this.killTimer();
      } else {
        this.unblockBackgroundScroll();
        this.initTimer();

        // Load first again after closing menu
        if (this.getCurrentPage !== 1) {
          this.getNotifications(1);
        }
      }
    }
  },
  data: () => ({
    notificationTimer: null
  }),
  beforeDestroy() {
    this.killTimer();
  },
  created() {
    this.fetchNotifications();
    this.initTimer();
  },
  methods: {
    killTimer() {
      clearInterval(this.notificationTimer);
      this.notificationTimer = null;
    },
    initTimer() {
      let vm = this;
      this.notificationTimer = setInterval(function () {
        vm.fetchNotifications();
      }, (1000 * 60)); // Load Once per Minute
    },

    ...mapMutations('notifications', [
      'setMenuOpen'
    ]),
    closeMenu() {
      this.setMenuOpen(false);
    },
    fetchNotifications(page = 1) {
      this.$store.dispatch('notifications/fetchNotifications', page);
    },
    blockBackgroundScroll() {
      document.getElementsByTagName("html")[0].classList.add('overflow-y-hidden');
    },
    unblockBackgroundScroll() {
      document.getElementsByTagName("html")[0].classList.remove('overflow-y-hidden');
    },

  },
  computed: {
    ...mapGetters('notifications', [
      'isMenuOpen',
      'getNotifications',
      'canGoToPreviousPage',
      'canGoToNextPage',
      'getCurrentPage'
    ])
  },
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 0.3s ease-in 0s;
}
</style>