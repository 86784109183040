<template>
  <form-wrapper ref="form"
                :show-changed="true"
                :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer campagne'"
                @submit="createOrUpdate"
  >
    <form-grid class="grid-cols-1 lg:grid-cols-3">
      <text-input-element v-model="getCampaign.name" label="Nom" validation-rules="required"></text-input-element>


      <date-range-picker-element
        v-model="dateRange"
        end-date-end-of-day
        label="Durée"
        validation-rules="required"
        with-time
      />


      <select-element
        v-model="getCampaign.type"
        :enable-search="false"
        :options="availableTypes"
        label="Appliqué à"
        validation-rules="required"
      ></select-element>

      <select-element
        v-model="getCampaign.valueType"
        :allow-unselecting="false"
        :enable-search="false"
        :options="availableValueTypes"
        label="Type campagne"
        validation-rules="required"
      />


      <template>
        <text-input-element
          v-if="getCampaign.valueType === 'discount'"
          v-model="getCampaign.discountValue" inset-text="%"
          label="Remise"
          validation-rules="required"/>

        <currency-input-element
          v-if="getCampaign.valueType === 'fixed'"
          v-model.number="getCampaign.value" label="Remise"
          validation-rules="required"
        />

        <product-select-input
          v-if="getCampaign.valueType === 'gift'"
          v-model="getCampaign.value" label="Produit offert"
          validation-rules="required"
        />
      </template>

      <number-input-element
        v-model="getCampaign.applyPerTranche"
        inset-text="de la condition"
        label="Par tranches de"
        validation-rules="int_not_zero"
      />

      <number-input-element
        v-model="getCampaign.quantity" label="Quantité"
        validation-rules="required"
      />

      <number-input-element
        v-model="getCampaign.reservedStock" inset-text="fois"
        label="Limite"/>

      <number-input-element
        v-model="getCampaign.availableStock" aria-readonly="true"
        disabled label="Stock disponible"/>

      <checkbox-element
        v-model="getCampaign.needsConfirmation"
        label="Client doit confirmer?"
      />

      <checkbox-element
        v-model="getCampaign.isShortDate"
        label="Date courte?"
      />
    </form-grid>
  </form-wrapper>
</template>

<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import FormGrid from "../../../elements/forms/FormGrid";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import NumberInputElement from "../../../elements/forms/elements/base/NumberInputElement";
import CurrencyInputElement from "../../../elements/forms/elements/CurrencyInputElement";
import ProductSelectInput from "../../../elements/forms/elements/ProductSelectInput";
import CampaignRepository from "../../../../repositories/CampaignRepository";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CreateOrUpdateCampaignForm",
  components: {
    SelectElement,
    DateRangePickerElement,
    CheckboxElement,
    ProductSelectInput,
    CurrencyInputElement,
    NumberInputElement,
    TextInputElement,
    FormGrid,
    FormWrapper
  },
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    campaignId: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    availableValueTypes: [
      {label: 'Remise Pourcentage', value: 'discount', icon: 'percent'},
      {label: 'Remise Fixe', value: 'fixed', icon: 'euro-sign'},
      {label: 'Cadeau', value: 'gift', icon: 'gift'},
    ],
    availableTypes: [
      {label: 'Panier', value: 'Cart'},
      {label: 'Produit', value: 'Product'},
    ]
  }),
  created() {
    // Delete old value
    this.$store.commit('campaigns/clearCampaign');

    // On Create, set default start date in one hour, so that we make sure we have rules configured,
    // before the campaigns are published so we don't have them applied to all products/modifiers.
    // TODO Remove after MVP
    if (!this.isUpdateMode && this.getCampaign.startDate === null) {
      this.$store.commit('campaigns/setStartDate', this.$date().add(1, 'h').format('DD.MM.YYYY HH:mm:ss'));
    }

    // Set initial valueType so field is shown.
    this.$store.commit('campaigns/setValueType', this.availableValueTypes[0].value);

    // Fetch Campaign in Update Mode
    if (this.isUpdateMode && this.campaignId !== undefined) {
      this
        .$store
        .dispatch('campaigns/fetchSingle', this.campaignId)
        .then(() => this.$refs.form.resetForm());
    }
  },
  methods: {
    createOrUpdate(callback) {
      return this.isUpdateMode ? this.updateCampaign(callback) : this.createCampaign(callback);
    },
    createCampaign(callback) {
      if (this.isUpdateMode)
        return false;

      let campaign = this.getCampaign;

      if (campaign.valueType === 'discount') {
        campaign.value = campaign.discountValue * 100
      }
      CampaignRepository
        .create(campaign)
        .then((res) => {
          this.$sendSuccessToast('Campagne crée!');
          this.$router.push({
            name: 'campaigns.edit',
            params: {
              campaign: res.data.data.id
            }
          });
        }).finally(() => callback());
    },
    updateCampaign(callback) {
      if (!this.isUpdateMode)
        return false;

      let campaign = this.getCampaign;
      if (campaign.valueType === 'discount') {
        campaign.value = campaign.discountValue * 100
      }
      delete campaign.id;

      CampaignRepository
        .update(this.campaignId, campaign)
        .then(() => {
          this.$sendSuccessToast('Campagne modifiée!');
        }).finally(() => callback());
    }
  },
  computed: {
    isUpdateMode() {
      return this.mode === "update";
    },
    getCampaign() {
      return this.$store.getters['campaigns/getCampaign'];
    },

    dateRange: {
      get() {
        return {
          startDate: this.getCampaign.startDate,
          endDate: this.getCampaign.endDate,
        }
      },
      set({startDate, endDate}) {
        this.getCampaign.startDate = startDate;
        this.getCampaign.endDate = endDate;
      }
    }
  }
}
</script>