<template>
  <div>
    <template
      v-if="isBannerOfTypeText"
    >
      Impossible d'ajouter des images pour une bannière du type texte.
    </template>

    <template
      v-else
    >
      <display-banner-images
        ref="imageDisplay"
        :banner-id="bannerId"
      />
      <upload-banner-images
        :banner-id="bannerId"
        class="mt-4"
        @uploaded="onImagesUploaded"
      />
    </template>
  </div>
</template>

<script>
import DisplayBannerImages from "@/components/pages/Banner/Management/EditBanner/BannerImages/DisplayBannerImages";
import UploadBannerImages from "@/components/pages/Banner/Management/EditBanner/BannerImages/UploadBannerImages";
import {mapGetters} from "vuex";

export default {
  name: "ManageBannerImages",
  components: {UploadBannerImages, DisplayBannerImages},
  props: {
    bannerId: {
      type: Number,
      required: true
    }
  },
  methods: {
    // Executed whenever new images have been uploaded
    onImagesUploaded() {
      this.$refs.imageDisplay.fetchImages();
    }
  },
  computed: {
    ...mapGetters('banners', [
      'getBanner'
    ]),
    isBannerOfTypeText() {
      return this.getBanner?.type === 'text';
    }
  },
}
</script>

<style scoped>

</style>