<template>
  <div>
    <page-title>Catégories Blog</page-title>

    <show-categories
      type="BlogPost"
    />
  </div>
</template>

<script>
import ShowCategories from "@/components/elements/categoryManagement/ShowCategories";
import PageTitle from "@/components/elements/pages/PageTitle";

export default {
  name: "ShowBlogCategories",
  components: {PageTitle, ShowCategories}
}
</script>

<style scoped>

</style>