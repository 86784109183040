<template>
  <div class="p-4">
    <scanner-listener
      :accept-quantity="false"
      @scan="onScan"
    />

    <div v-if="isInDevMode" class="flex items-center gap-x-2 mb-4">
      <div class="font-bold text-red-500">
        Debug
      </div>
      <touch-optimized-button @click="onScan({code: '97000123'})">Fulfilment</touch-optimized-button>
      <touch-optimized-button @click="onScan({code: '9800000001218'})">Delivery</touch-optimized-button>
      <touch-optimized-button type="danger" @click="onScan({code: '9800000001218'})">Error
      </touch-optimized-button>
      <touch-optimized-button
        type="secondary"
        @click="resetData">
        Reset
      </touch-optimized-button>
    </div>

    <div class="p-4 bg-gray-200">
      <div class="text-2xl text-center">
        <div v-if="hasScannedDeliveryCode && hasScannedFulfilmentCode" class="text-3xl text-center, ">
          <span v-if="hasValidDeliveryBarcode" class='text-green-600 font-bold'>Etiquette valide</span>
          <span v-else-if="hasSameDeliveryCodeScanned" class='text-red-600'>Même etiquette scannée. Veuillez scanner un autre code-barres</span>
          <span v-else class='text-red-600'>Etiquette non valide</span>
        </div>

        <div v-else>
          Scan étiquette fulfilment
          <div :class="hasScannedFulfilmentCode ? 'text-green-600 font-bold' : 'text-red-600'">
            <div v-if="loading" class="flex justify-center">
              <loading-svg-inline class="w-24 h-24"/>
            </div>
            <div v-else>
              <span v-if="!hasScannedFulfilmentCode">En attente scan fulfilment etiquette...</span>
              <span v-else>Scan OK [{{ scannedFulfilmentCode }}]</span>
            </div>
          </div>

          <div v-if="hasScannedFulfilmentCode" class="mt-4">
            Scan étiquette du livraison
            <div :class="hasScannedDeliveryCode ? 'text-green-600 font-bold' : 'text-red-600'">
              <span v-if="!hasScannedDeliveryCode">En attente scan du livraison...</span>
              <span v-else>Scan OK [{{ hasScannedDeliveryCode }}]</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScannerListener from "../../Packaging/Fulfilment/Includes/scanner/ScannerListener";
import LoadingSvgInline from "../../../../elements/loading/LoadingSvgInline";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton.vue";

export default {
  name: "DeliveryFulfilmentBarcodeCheckPage",
  components: {TouchOptimizedButton, LoadingSvgInline, ScannerListener},
  data: () => ({
    loading: false,

    scannedFulfilmentCode: null,
    returnedFulfilmentNumber: null,

    scannedDeliveryCode: null,
    returnedDeliveryFulfilmentNumber: null,
    previouslyScannedDeliveryCode: null,

    isSameDeliveryCodeScanned: false,
    isValidDeliveryBarcode: false,

  }),

  methods: {
    onScan({code}) {
      // Fulfilment Barcodes start with 97
      if (code.substring(0, 2) === '97') {
        this.resetData();
        this.scanFulfilmentBarcode(code);
      } else if (code.substring(0, 2) === '98') {
        this.isValidDeliveryBarcode = false;
        this.isSameDeliveryCodeScanned = false;

        this.scanDeliveryBarcode(code);
      }
    },
    scanFulfilmentBarcode(barcode) {
      this.loading = true;
      this.returnedFulfilmentNumber = parseInt(barcode.substring(2, 7));
      this.scannedFulfilmentCode = barcode;

      this.loading = false;
    },
    scanDeliveryBarcode(barcode) {
      this.returnedDeliveryFulfilmentNumber = null;

      let hasError = true;

      if (this.previouslyScannedDeliveryCode != null && this.previouslyScannedDeliveryCode === barcode) {
        this.isSameDeliveryCodeScanned = true;
        this.scannedDeliveryCode = barcode;
      } else {
        this.returnedDeliveryFulfilmentNumber = parseInt(barcode.substring(8, 11));
        this.previouslyScannedDeliveryCode = barcode
        this.scannedDeliveryCode = barcode;
      }


      if (this.hasScannedFulfilmentCode) {
        if (this.returnedFulfilmentNumber === this.returnedDeliveryFulfilmentNumber) {
          hasError = false;
          this.isValidDeliveryBarcode = true;
        }
      } else {
        this.scannedDeliveryCode = null;
        this.$showErrorDialog("Erreur: L'étiquette de fulfilment doit être scannée en premier");
      }

      if (hasError) {
        this.beep(100, 520, 1000, 2000);
      } else {
        this.beep(100, 800, 200);
      }
    },

    beep(vol, freq, duration, vibrationDuration) {
      let a = new AudioContext();
      if (vibrationDuration > 0) {
        navigator.vibrate(vibrationDuration);
      }
      let v = a.createOscillator();
      let u = a.createGain();
      v.connect(u);
      v.frequency.value = freq;
      v.type = "square";
      u.connect(a.destination);
      u.gain.value = vol * 0.01;
      v.start(a.currentTime);
      v.stop(a.currentTime + duration * 0.001);
    },

    resetData() {
      this.scannedFulfilmentCode = null;
      this.scannedDeliveryCode = null;
      this.returnedFulfilmentNumber = null;
      this.returnedDeliveryFulfilmentNumber = null;
      this.previouslyScannedDeliveryCode = null;
      this.isSameDeliveryCodeScanned = false
    }
  },
  computed: {
    hasScannedDeliveryCode() {
      return this.scannedDeliveryCode !== null;
    },
    hasScannedFulfilmentCode() {
      return this.scannedFulfilmentCode !== null;
    },
    hasValidDeliveryBarcode() {
      return this.isValidDeliveryBarcode;
    },
    hasSameDeliveryCodeScanned() {
      return this.isSameDeliveryCodeScanned;
    },
    isInDevMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
}
</script>

<style scoped>

</style>