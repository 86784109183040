<template>
    <div>
        <select-element
                :allow-unselecting="allowUnselecting"
                :disabled="disabled"
                :label="label"
                :nothing-selected-text="nothingSelectedText"
                :options="getCountriesPhonePrefix"
                :prioritized-options="prioritizedCountriesPrefix"
                :validation-rules="validationRules"
                :value="value"
                @input="$emit('input', $event)"
        />
    </div>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
    name: "CountryPhonePrefixSelectInput",
    components: {SelectElement},
    props: {
        value: {
            required: true
        },
        label: {
            default: 'Pays',
            required: false,
        },
        options: {
            required: false,
            validator: val => typeof val === "object"
        },
        validationRules: {
            type: String,
            required: false,
        },
        nothingSelectedText: {
            type: String,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        onlyLuxembourg: {
            type: Boolean,
            default: false
        },
        allowUnselecting: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        if (!this.onlyLuxembourg) {
            if (this.options === undefined) {
                this.fetchCountries();
            } else {
                this.availableCountries = this.options;
            }
        }
    },
    methods: {
        fetchCountries() {
            this.$store.dispatch('global/fetchCountriesPhonePrefix');
        }
    },
    computed: {
        getCountriesPhonePrefix() {
            return this.$store.getters['global/getCountriesPhonePrefix'].map(c => {
                return {
                    value: c.prefix,
                    label: c.countryName + ' (+' + c.prefix + ')',
                };
            });
        }
    },
    data: () => ({
        availableCountries: [],
        prioritizedCountriesPrefix: [352, 49, 33, 32]
    })
}
</script>

