<template>
  <touch-optimized-modal ref="modal">
    <template #titlebar>
      <div class="flex items-center space-x-2">
        <div
          v-if="canAccessBackofficeDashboard"
          class="border border-white px-3 py-1 lg:py-2 text-xs hover:bg-white hover:text-black animate ease-in-out duration-300 cursor-pointer text-white"
          @click="$router.push({ name: 'home' })"
        >
          Backoffice
        </div>

        <div class="text-white cursor-pointer" @click="reloadPage">
          <fa-icon fixed-width icon="sync"/>
        </div>
      </div>
    </template>


    <div class="w-full flex flex-col items-center overflow-auto ">
      <div class="w-full lg:w-4/5 2xl:w-3/4">
        <touch-interface-nav-menu-notification-list/>

        <div
          class="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-8 lg:gap-x-16 xl:gap-x-32"
        >
          <div
            v-for="menuLink in getMenu"
            :key="menuLink.name"
          >
            <div
              class="flex gap-x-3 items-center"
            >
              <fa-icon
                v-if="menuLink.icon !== undefined"
                :icon="menuLink.icon"
                class="text-lg"
                fixed-width
              />
              <div>
                {{ menuLink.name }}
              </div>
            </div>

            <div
              class="h-px w-full bg-gray-600 my-2"
            />


            <div v-if="menuLink.pages" class="my-3 flex flex-wrap">
              <div
                v-for="subMenuLinks in menuLink.pages"
                :key="subMenuLinks.name"
                class="m-1 border-2 rounded-md border-gray-500 px-4 lg:px-6 md:px-4 py-2 lg:py-4 xl:py-6 hover:bg-white group animate ease-in-out duration-300 cursor-pointer text-center flex flex-col justify-center"
                @click="onPageLinkClick(subMenuLinks)"
              >
                <h3 class="text-sm md:text-lg xl:text-xl select-none text-gray-100 group-hover:text-gray-600">
                  {{ subMenuLinks.name }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </touch-optimized-modal>
</template>

<script>
import TouchOptimizedModal from "../../../../touchOptimized/Modal/TouchOptimizedModal";
import {menuLinks} from "@/helpers/touchInterfaceHelper"
import {userHasPermission} from "@/helpers/permissionHelper";
import TouchInterfaceNavMenuNotificationList
  from "@/components/layouts/touchInterface/partials/Nav/Notifications/TouchInterfaceNavMenuNotificationList.vue";

export default {
  name: "TouchInterfaceNavMenu",
  components: {TouchInterfaceNavMenuNotificationList, TouchOptimizedModal},
  methods: {
    open() {
      this.$refs.modal.open();
    },
    onPageLinkClick(pageLinkName) {
      if (pageLinkName.route !== undefined) {
        this.$refs.modal.close();
        return this.$router.push(pageLinkName.route);
      }
    },
    canAccessPage(page) {
      if (page.permissions === undefined) {
        return true;
      }
      return this.userHasPermission(page.permissions);
    },
    userHasPermission(permissionsArray) {
      return userHasPermission(permissionsArray, this.$store.getters['authUser/getPermissions']);
    },
    reloadPage() {
      if (window) {
        window.location.reload();
      }
    }
  },
  computed: {
    getMenu() {
      return menuLinks.filter(s => {
        return this.canAccessPage(s);
      });
    },
    canAccessBackofficeDashboard() {
      return this.userHasPermission(['ADMIN_PANEL_ACCESS'])
    }
  }
}
</script>