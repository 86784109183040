<template>
  <div>
    <form-wrapper ref="feeForm" :show-changed="true"
                  :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer frais'" @submit="createOrUpdateFee">
      <form-grid class="grid-cols-1 lg:grid-cols-3">
        <text-input-element v-model="getFee.name" label="Nom" validation-rules="required"></text-input-element>
        <date-picker-element
          v-model="getFee.startDate"
          :max-date="getFee.endDate"
          label="Date & heure debut"
          validation-rules="required"
          with-time
        />

        <div>
          <date-picker-element
            v-model="getFee.endDate"
            :min-date="getFee.startDate"
            label="Date & heure fin"
            with-time
          />
        </div>

        <currency-input-element v-model.number="getFee.price" label="Valeur"
                                validation-rules="required"></currency-input-element>

        <number-input-element v-model="getFee.applyPerTranche" inset-text="du produit conditionnel"
                              label="Par tranches de"></number-input-element>

        <vat-input-element
          v-model="getFee.vat"
          :allow-unselecting="false"
          validation-rules="required"
        />

      </form-grid>
    </form-wrapper>
  </div>
</template>

<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import DatePickerElement from "../../../elements/forms/elements/base/DatePickerElement";
import FormGrid from "../../../elements/forms/FormGrid";
import CurrencyInputElement from "../../../elements/forms/elements/CurrencyInputElement";
import VatInputElement from "../../../elements/forms/elements/VatInputElement.vue";
import NumberInputElement from "../../../elements/forms/elements/base/NumberInputElement";

export default {
  name: "CreateOrUpdateFeeForm",
  components: {
    NumberInputElement,
    VatInputElement,
    CurrencyInputElement, FormGrid, DatePickerElement, TextInputElement, FormWrapper
  },
  props: {
    mode: {
      type: String,
      default: "create"
    },
  },
  created() {
    if (this.isCreateMode) {
      this.$store.commit('fees/clearFee');
    }
  },
  methods: {
    createOrUpdateFee(callback) {
      if (this.isUpdateMode) {
        return this.$store.dispatch('fees/updateFee', {
          name: this.getFee.name,
          price: this.getFee.price,
          vat: this.getFee.vat,
          applyPerTranche: this.getFee.applyPerTranche,
          startDate: this.getFee.startDate,
          endDate: this.getFee.endDate,
        }).then(() => {
          this.$sendSuccessToast("Frais modifiée.")
        }).finally(() => callback());
      }

      return this.$store.dispatch('fees/createFee', {
        name: this.getFee.name,
        price: this.getFee.price,
        vat: this.getFee.vat,
        applyPerTranche: this.getFee.applyPerTranche,
        startDate: this.getFee.startDate,
        endDate: this.getFee.endDate,
      }).then((res) => {
        this.$store.dispatch('fees/fetchSingle', res.data.data.id).then(() => {
          this.$sendSuccessToast("Frais crée.");
          this.$router.push({name: 'fees.edit', params: {fee: res.data.data.id}});
        });
      }).finally(() => callback());
    }
  },
  computed: {
    getFee() {
      return this.$store.getters['fees/getFee'];
    },
    isCreateMode() {
      return this.mode === "create";
    },
    isUpdateMode() {
      return this.mode === "update";
    },
  },
}
</script>

