<template>
  <div>
    <media-image-display-and-management
      ref="imageManagement"
      :media-able-id="productId"
      :possible-labels="getPossibleLabels"
      media-able-type="product"
    />
  </div>
</template>

<script>
import MediaImageDisplayAndManagement from "../../../../../global/Media/Image/MediaImageDisplayAndManagement";

export default {
  name: "DisplayProductImages",
  components: {MediaImageDisplayAndManagement},
  props: {
    productId: {
      type: Number,
      required: true
    },
  },
  computed: {
    getPossibleLabels() {
      return this.$luxcaddyConfig("product.images.availableLabels");
    }
  },
  methods: {
    fetchImages() {
      this.$refs.imageManagement.fetchImages();
    }
  }
}
</script>

