import ApiService from "./Base/BaseRepository";

export default {

  /**
   * Fetches all supplier holidays
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await ApiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`/members/holidays`);
  },

  async getForTimeline(dateStart, dateEnd, memberTypeFilter = null, crossToday = false, memberFilter = null) {
    let filters = [
      {filterKey: 'holidayStartDateBetween', filterValue: `${dateStart},${dateEnd}`}
    ];

    if (memberTypeFilter !== null) {
      filters.push({
        filterKey: 'memberType',
        filterValue: memberTypeFilter
      })
    }

    if (crossToday) {
      filters.push({
        filterKey: 'crossesToday',
        filterValue: 1
      });
    }

    if (memberFilter) {
      filters.push({
        filterKey: 'memberIdOrName',
        filterValue: memberFilter
      });
    }

    return this.getPaginated(
      1000,
      1,
      [
        {dataField: 'memberName', sort: 'asc'}
      ],
      filters
    );
  },


  /**
   * Deletes a given supplier holiday
   *
   * @param supplierMemberId
   * @param holidayId
   * @returns {Promise<AxiosResponse<any>>}
   */
  async deleteSingle(supplierMemberId, holidayId) {
    return await ApiService.delete(`/members/${supplierMemberId}/holidays/${holidayId}`);
  },

  /**
   * Add holiday to supplier
   *
   * @param supplierMemberId
   * @param values
   * @returns {Promise<AxiosResponse<any>>}
   */
  async add(supplierMemberId, values) {
    return await ApiService.post(`/members/${supplierMemberId}/holidays`, values);
  },

  async getSingle(supplierMemberId, holidayId) {
    return await ApiService.get(`/members/${supplierMemberId}/holidays/${holidayId}`);
  },

  async update(supplierMemberId, holidayId, payload) {
    return await ApiService.patch(`/members/${supplierMemberId}/holidays/${holidayId}`, payload);
  },

}