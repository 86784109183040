<template>
  <div class="bg-yellow-300 border-2 border-black rounded-md w-24 font-bold text-black text-center tracking-widest">
    <div
      class="flex gap-x-2"
    >
      <div
        :class="[small ? 'text-xs': 'text-sm']"
        class=" w-4 bg-blue-400 rounded-l-md px-0.5 text-white select-none"
      >
        L
      </div>
      <div
        :class="[small ? 'text-xs': 'text-md']"
        class="text-center"
      >
        {{ plate }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LuxembourgishNumberPlate",
  props: {
    plate: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

