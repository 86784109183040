<template>
  <form-wrapper ref="form"
                :show-changed="true"
                :submit-button-label="isMemberEmployee ? 'Sauvegarder' : 'Créer employé'"
                name="createOrEditMemberForm"
                @submit="storeEmployeeDetails">

    <form-grid>
      <div class="flex items-center gap-x-4 col-span-12">
        <div class="font-bold">Status:</div>
        <member-status-indicator
          :status="getStatus"
        />
      </div>

      <text-input-element v-model="rfId" class="col-span-12"
                          label="Rfid"
                          validation-rules="required"></text-input-element>
      <text-input-element
        v-model="deliverySpeed"
        :inset-text="getDeliverySpeedInsetText"
        label="Vitesse de livraison"
      />
    </form-grid>

    <template #additionalButtons>
      <delete-confirmation-modal v-if="isMemberEmployee && isActive" ref="deactivateEmployeeModal"
                                 delete-button-label="Désactiver" title="Désactiver employé?"
                                 @delete="deactivateEmployeeDetails">
        Êtes vous sûre que vous voulez désactiver ce membre comme employé?
      </delete-confirmation-modal>

      <div class="flex gap-x-4 items-center">
        <button-element v-if="isMemberEmployee && isActive" class="flex-grow" type="danger"
                        @click="openDeactivateModal">
          Désactiver
        </button-element>

        <button-element v-if="isMemberEmployee && !isActive" class="float-right" type="warning"
                        @click="activateEmployee">
          Activer
        </button-element>

        <button-element :disabled="!isMemberEmployee" class="flex-grow" type="light" @click="printLoginLabel">
          Imprimer étiquette code barre
        </button-element>
      </div>
    </template>

  </form-wrapper>
</template>

<script>
import FormWrapper from "../../../../elements/forms/FormWrapper";
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";
import EmployeeRepository from "../../../../../repositories/EmployeeRepository";
import ButtonElement from "../../../../elements/buttons/ButtonElement";
import FormGrid from "../../../../elements/forms/FormGrid";
import DeleteConfirmationModal from "../../../../elements/modals/DeleteConfirmationModal";
import MemberStatusIndicator from "../../../../elements/display/member/MemberStatusIndicator";

export default {
  name: "MemberCreateEditEmployeeTab",
  components: {
    DeleteConfirmationModal,
    FormGrid,
    ButtonElement,
    TextInputElement,
    FormWrapper,
    MemberStatusIndicator
  },
  props: {
    member: {type: Object, required: true}
  },
  data: () => ({
    isActive: false,
    rfId: '',
    deliverySpeed: null
  }),
  mounted() {
    if (this.isMemberEmployee) {
      this.getEmployee();
    }
  },
  methods: {
    getEmployee(id = this.member.id) {
      return EmployeeRepository.getEmployeeDetails(id).then((res) => {
        let data = res.data.data;
        this.rfId = data.rfId;
        this.isActive = data.isActive;
        this.deliverySpeed = data.deliverySpeed ? data.deliverySpeed / 100 : null;

      }).then(() => this.$refs.form.$refs.validationObserver.reset());
    },
    storeEmployeeDetails(callback) {
      let deliverySpeedPercentage = this.deliverySpeed ? this.deliverySpeed * 100 : null;
      let payload = {
        rfId: this.rfId,
        deliverySpeed: deliverySpeedPercentage
      };

      EmployeeRepository.storeEmployeeDetails(this.member.id, payload).then(() => {
        this.$sendSuccessToast(`Mise à jour réussie de l'employé ${this.member.fullName}!`);
      }).finally(() => {
        this
          .getEmployee(this.member.id)
          .then(() => this.$emit('change'))
          .finally(() => callback());
      })
    },
    deactivateEmployeeDetails() {
      if (!this.isMemberEmployee)
        return false;

      EmployeeRepository.deactivateEmployeeDetails(this.member.id).then(() => {
        this.closeDeactivateModal();
        this.$sendSuccessToast(`Employé ${this.member.fullName} désactivé!`);
      }).finally(() => {
        this.getEmployee(this.member.id).then(() => this.$emit('change'));
      })
    },
    activateEmployee() {
      if (!this.isMemberEmployee)
        return false;

      EmployeeRepository.activateEmployeeDetails(this.member.id).then(() => {
        this.$sendSuccessToast(`Employé ${this.member.fullName} a été activé!`);
      }).finally(() => {
        this.getEmployee(this.member.id).then(() => this.$emit('change'));
      })
    },

    openDeactivateModal() {
      this.$refs.deactivateEmployeeModal.openModal();
    },
    closeDeactivateModal() {
      this.$refs.deactivateEmployeeModal.closeModal();
    },
    printLoginLabel() {
      if (!this.isMemberEmployee)
        return false;

      EmployeeRepository.printLoginLabel(this.member.id).then(() => {
        this.$sendSuccessToast("Étiquette imprimée!");
      })
    }
  },
  computed: {
    isMemberEmployee() {
      return this.member.isEmployee;
    },
    getStatus() {
      if (this.isActive) {
        return 'active';
      }

      return 'deactivated';

    },
    getDeliverySpeedInsetText() {
      if (this.deliverySpeed >= 0) {
        return "% plus lent"
      }

      return "% plus vite";
    }
  }
}
</script>

