<template>

  <div>
    <form-wrapper
      ref="form"
      :disable-submit-button="!canEditUser"
      :show-changed="true"
      :submit-button-label="'Sauvegarder'"
      @submit="storeUserDetails"
    >
      <form-grid>
        <error-alert

          v-if="!canEditUser"
          class="mb-3"
        >
          Vous n'êtes pas autorisé à modifier cet utilisateur, car il dispose d'autorisations de niveau
          supérieur.
        </error-alert>

        <div class="flex flex-col md:flex-row items-stretch gap-4">
          <div class="col-span-12 md:col-span-4">
            <div class="font-bold">Status:</div>
            <member-status-indicator
              :status="getStatus"
              class="w-44"
            />
          </div>

          <password-input-element
            v-model="password"
            :disabled="!canEditUser"
            class="w-full md:w-72"
            label="Mot de passe"
          />

          <member-user-crud-roles
            v-if="roleList.length > 0"
            v-model="roles"
            :role-list="roleList"
            class="w-full"
          />
        </div>
      </form-grid>

      <template #additionalButtons>
        <delete-confirmation-modal v-if="isMemberUser" ref="deactivateUserModal"
                                   delete-button-label="Désactiver"
                                   title="Désactiver utilisateur?" @delete="deactivateUser">
          Êtes vous sûre que vous voulez désactiver ce membre comme utilisateur?
        </delete-confirmation-modal>

        <button-element
          v-if="isMemberUser && isEnabled"
          :disabled="!canEditUser"
          class="float-right"
          type="danger"
          @click="openDeactivateModal"
        >
          Désactiver
        </button-element>

        <button-element
          v-if="isMemberUser && !isEnabled"
          :disabled="!canEditUser"
          class="float-right"
          type="warning"
          @click="activateUser"
        >
          Activer
        </button-element>
      </template>

    </form-wrapper>
  </div>
</template>

<script>
import FormWrapper from "../../../../../elements/forms/FormWrapper.vue";
import ButtonElement from "../../../../../elements/buttons/ButtonElement.vue";
import UserRepository from "../../../../../../repositories/UserRepository";
import PasswordInputElement from "../../../../../elements/forms/elements/PasswordInputElement.vue";
import RoleRepository from "../../../../../../repositories/RoleRepository";
import FormGrid from "../../../../../elements/forms/FormGrid.vue";
import DeleteConfirmationModal from "../../../../../elements/modals/DeleteConfirmationModal.vue";
import MemberStatusIndicator from "../../../../../elements/display/member/MemberStatusIndicator.vue";
import MemberUserCrudRoles from "@/components/pages/Members/EditMember/Tabs/MemberUserForm/Sub/MemberUserCrudRoles.vue";
import {userAllowedToEditOtherUser} from "@/helpers/permissionHelper";
import ErrorAlert from "@/components/elements/alerts/ErrorAlert.vue";

export default {
  name: "MemberCreateEditUserTab",
  components: {
    ErrorAlert,
    MemberUserCrudRoles,
    DeleteConfirmationModal,
    FormGrid,
    MemberStatusIndicator, PasswordInputElement, ButtonElement, FormWrapper
  },
  props: {
    member: {type: Object, required: true}
  },
  data: () => ({
    isEnabled: false,
    password: '',
    roles: [],

    roleList: [],
  }),
  mounted() {
    if (this.isMemberUser) {
      this.getUser();
    }

    this.fetchRoles();
  },
  methods: {
    getUser(id = this.member.id) {
      return UserRepository.getUserDetails(id).then((res) => {
        let data = res.data.data;
        this.isEnabled = data.isEnabled;
        this.roles = data.roles;
      }).then(() => this.$refs.form.$refs.validationObserver.reset());
    },
    storeUserDetails(callback) {
      let payload = {
        isEnabled: this.isEnabled,
        role: this.roles,
      };

      if (this.password.length) {
        payload.password = this.password;
      }

      UserRepository.storeUserDetails(this.member.id, payload).then(() => {
        this.password = '';
        this.$sendSuccessToast(`Mise à jour réussie de l'utilisateur ${this.member.fullName}!`);
      }).then(() => {
        this
          .getUser(this.member.id)
          .then(() => this.$emit('change'))
          .finally(() => callback());
      }).catch(() => callback());
    },
    deactivateUser() {
      if (!this.isMemberUser)
        return false;

      UserRepository.deactivateUserDetails(this.member.id).then(() => {
        this.closeDeactivateModal();
        this.$sendSuccessToast(`Utilisateur ${this.member.fullName} désactivé!`);
      }).finally(() => {
        this.getUser(this.member.id).then(() => this.$emit('change'));
      })
    },
    openDeactivateModal() {
      this.$refs.deactivateUserModal.openModal();
    },
    closeDeactivateModal() {
      this.$refs.deactivateUserModal.closeModal();
    },
    activateUser() {
      if (!this.isMemberUser)
        return false;

      UserRepository.activateUserDetails(this.member.id).then(() => {
        this.$sendSuccessToast(`Utilisateur ${this.member.fullName} a été activé!`);
      }).finally(() => {
        this.getUser(this.member.id).then(() => this.$emit('change'));
      })

    },
    fetchRoles() {
      RoleRepository.getRoles().then((res) => {
        this.roleList = res.data.data;
      });
    }
  },
  computed: {
    getStatus() {
      if (this.isEnabled) {
        return 'active';
      }

      return 'deactivated';

    },
    isMemberUser() {
      return this.member.isUser;
    },

    canEditUser() {
      const currentUserRoleIds = this.roleList
        .filter(r => this.$store.getters["authUser/getRoles"].includes(r.name))
        .flatMap(r => r.id);

      return userAllowedToEditOtherUser(this.roleList, currentUserRoleIds, this.roles);
    }
  }
}
</script>

