<template>
  <!-- Use pointer-events-none on hidden deliveries to be able to click through the invisible div -->
  <gmap-custom-marker
    :class="isDeliveryHidden ? 'pointer-events-none' : ''"
    :marker="getMarkerPos"
    :z-index="isHovered ? 30 : 0"
  >
    <div
      @mouseenter="setHovered"
      @mouseleave="setUnhovered"
    >
      <div class="z-40"
           @click="toggleSelectDelivery"
      >
        <div class="flex flex-col">
          <div
            v-if="isInDevMode"
            class="bg-red-500 text-white"
          >
            {{ delivery.id }}
          </div>
          <div
            v-if="!isDeliveryHidden"
            :class="[isSelected ? 'border-red-500' : 'border-transparent']"
            class="flex flex-col border-2 z-40"
          >
            <div
              class="border bg-white flex flex-col items-center font-medium whitespace-nowrap"
              style="width: 30px; font-size: 0.65rem !important;"
            >
              {{ getTimeSlotSpan }}
            </div>
            <div
              :style="`background-color: ${getDeliveryHexColor}; width: 30px;`"
              class="text-xs text-white font-bold flex flex-col items-center"
            >
                        <span class="h-full py-0.5 font-extrabold text-sm">
                            {{ getNumberOfDelivery }}
                        </span>
            </div>
          </div>
          <div
            v-else
            style="height:43px; width: 34px"
          >
            <!-- Delivery Hidden, have a block in the same size so the arrow does not jump around -->
          </div>

          <span
            :style="`color: ${getDeliveryHexColor}`"
            class="-mt-3.5 mx-auto z-40"
          >
                        <fa-icon class="text-xl" fixed-width icon="sort-down"/>
                    </span>
        </div>
      </div>

      <!-- Hover Overlay -->
      <transition name="fade-up-down">
        <div
          v-show="isHovered && getShowDeliveryOverlays"
          :style="`border-color: ${getDeliveryHexColor}; margin-top: -68px`"
          class="z-20 absolute cursor-auto -ml-4 rounded-md p-4 border-2 shadow-2xl min-w-max opacity-95 bg-white"
        >
          <div class="flex justify-between gap-x-4">
            <!-- Spacer -->
            <div style="width: 26px"></div>

            <div
              class="mt-0.5 font-bold mb-4 flex gap-x-4 justify-between items-center py-2.5 px-3 bg-gray-100 border border-gray-100">
              <i :style="`color: ${getDeliveryHexColor}`" class="fa fa-truck"></i>
              <span>{{
                  $date(delivery.timeSlot.startDate).format('HH:mm')
                }} - {{ $date(delivery.timeSlot.endDate).format('HH:mm') }}</span>
            </div>
          </div>

          <div class="flex flex-col gap-y-1.5 justify-between" style="max-width: 200px">
            <div
              v-html="getFormattedAddress"
            />

            <div class="flex-none | flex items-center gap-1">
              <fulfilment-number-display
                :fulfilment-number="delivery.fulfilmentNumber"
              />
              <box-quantity
                :quantity="delivery.boxQuantity"
              />
              <text-link
                class="ml-1"
                @click="openDeliveryInNewTab"
              >
                Liv#{{ delivery.id }}
              </text-link>
            </div>
          </div>


          <div v-if="!hasPrecisGeocoding" class="mt-4 border-2 p-2 border-red-500">
            <div class="text-red-500 font-bold">
              Cette livraison n'a pas de géocodage précis.<br>
              Veuillez la corriger en cliquant sur le lien ci-dessous.

              <div class="mt-6">
                <router-link
                  :to="{name: 'deliveries.edit', params: {deliveryId: this.delivery.id}}"
                  class="text-red-700 hover:text-red-800 text-md group"
                  target="_blank"
                >
                  <fa-icon fixed-width icon="edit"></fa-icon>&nbsp;
                  <span class="group-hover:underline">Modifier livraison</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </gmap-custom-marker>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import {mapGetters, mapMutations} from "vuex";
import {getColorByRouteIndex} from "@/helpers/routeManagerHelper";
import BoxQuantity from "@/components/global/BoxQuantity/BoxQuantity";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";
import TextLink from "@/components/global/TextLink";
import newTabMixin from "@/mixins/newTabMixin";

export default {
  name: "RouteManagerDeliveryMapMarker",
  components: {
    TextLink,
    FulfilmentNumberDisplay,
    BoxQuantity,
    GmapCustomMarker,
  },
  props: {
    delivery: {
      type: Object,
      required: true
    },
  },
  mixins: [newTabMixin],
  computed: {
    ...mapGetters('routeManager',
      [
        'getRouteCount',
        'getHoveredDeliveryId',
        'getShowDeliveryOverlays',
        'getHiddenDeliveryIds'
      ]),

    getMarkerPos() {
      return {
        lat: this.delivery.destination.geocoding.latitude,
        lng: this.delivery.destination.geocoding.longitude
      }
    },
    hasPrecisGeocoding() {
      return this.delivery.destination.geocoding.isPrecise;
    },
    getTimeSlotSpan() {
      let startDate = this.$date(this.delivery.timeSlot.startDate);
      let endDate = this.$date(this.delivery.timeSlot.endDate);

      startDate = startDate.minute() === 0
        ? startDate.format('HH')
        : startDate.format('HH:mm');

      endDate = endDate.minute() === 0
        ? endDate.format('HH')
        : endDate.format('HH:mm');

      return `${startDate}-${endDate}`;
    },

    /*
        Selection to assign to a new route
     */
    getIsSelectedForNewRoute() {
      return this.$store.getters['routeManager/isDeliverySelected'](this.delivery.id);
    },
    isSelected() {
      return this.getIsSelectedForNewRoute.isSelected;
    },

    /*
        Delivery Marker Hover Window
     */
    getFormattedAddress() {
      let dest = this.delivery.destination;

      return `<span class="font-bold">${dest.fullName}</span></br>${dest.city}`;
    },


    getColorAndNumberOfDelivery() {
      return this.$store.getters['routeManager/getColorAndNumberOfDelivery'](this.delivery.id);
    },
    getNumberOfDelivery() {
      if (this.isSelected) {
        return this.getIsSelectedForNewRoute.number;
      }
      if (this.isAssignedToRoute) {
        return this.getColorAndNumberOfDelivery.number;
      }
      if (!this.hasPrecisGeocoding) {
        return "!!!";
      }

      return '-'
    },

    isAssignedToRoute() {
      return this.getColorAndNumberOfDelivery?.color !== null && this.getColorAndNumberOfDelivery?.number !== null;
    },

    getNextAvailableMarkerColor() {
      return getColorByRouteIndex(this.getRouteCount);
    },
    getDeliveryHexColor() {
      if (this.isSelected) {
        return this.getNextAvailableMarkerColor;
      }
      if (this.isAssignedToRoute) {
        return this.getColorAndNumberOfDelivery.color;
      }
      return '#000000';
    },

    isHovered() {
      return this.getHoveredDeliveryId === this.delivery.id;
    },

    isDeliveryHidden() {
      return this.getHiddenDeliveryIds.includes(this.delivery.id);
    },

    isInDevMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
  methods: {
    ...mapMutations('routeManager', [
      'setHoveredDeliveryId',
      'selectDelivery',
      'clearHoveredDeliveryId'
    ]),
    setHovered() {
      if (this.isDeliveryHidden) {
        return false;
      }

      this.setHoveredDeliveryId({
        deliveryId: this.delivery.id,
        initiator: 'map'
      })
    },
    setUnhovered() {
      this.clearHoveredDeliveryId();
    },
    openDeliveryInNewTab() {
      this.openRouteInNewTab({
        name: 'deliveries.edit',
        params: {deliveryId: this.delivery.id},
        query: {readonly: true}
      });
    },
    toggleSelectDelivery() {
      if (this.isDeliveryHidden) {
        return false;
      }

      this.selectDelivery(this.delivery.id);
    }
  },
}
</script>

