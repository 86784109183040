<template>
  <base-input-element
    :id="id"
    ref="input"
    :active-in-read-only="activeInReadOnly"
    :additional-input-classes="additionalInputClasses"
    :disabled="disabled"
    :displayCharCounter="displayCharCounter"
    :inset-position="insetPosition"
    :inset-text="insetText"
    :label="label"
    :occupy-validation-space="occupyValidationSpace"
    :placeholder="placeholder"
    :touch-optimized="touchOptimized"
    :type="type"
    :validation-rules="validationRules"
    :value="value"
    @blur="$emit('blur', $event)"
    @enter="$emit('enter', $event)"
    @escape="$emit('escape', $event)"
    @input="$emit('input', $event)"
  ></base-input-element>
</template>

<script>
import BaseInputElement from "./BaseInputElement";
import formInputMixin from "@/mixins/formInputMixin";

export default {
  name: "TextInputElement",
  components: {BaseInputElement},
  mixins: [formInputMixin],
  props: {
    displayCharCounter: {
      type: Boolean,
      default: false
    },
    value: {
      required: false,
    },
    id: {
      type: [String, null]
    },
    type: {
      type: String,
      default: "text"
    },

    placeholder: {
      type: String,
      required: false
    },
    insetText: {
      type: String,
      required: false
    },

    insetPosition: {
      type: String,
    },
    touchOptimized: {
      type: Boolean,
      default: false
    },
    isDiscount: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  },

}
</script>

