<template>
  <div class="group bg-gray-100 rounded-md p-4">
    <article
      class="relative w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent group-hover:text-luxcaddy-500 shadow-sm h-32"
      tabindex="0"
    >

      <div
        class="absolute top-0 h-16 w-full opacity-0 group-hover:opacity-70 transition duration-200 rounded-md z-30 bg-gradient-to-b from-black"></div>
      <div
        class="absolute bottom-0 h-16 w-full opacity-0 group-hover:opacity-70 transition duration-200 rounded-md z-30 bg-gradient-to-t from-black"></div>

      <img
        :src="file.objectURL"
        alt="Upload preview"
        class="w-full h-56 sticky object-contain shadow-lg z-0 rounded-md bg-fixed"
      />

      <section
        class="flex flex-col rounded-md text-xs break-words w-full h-full z-40 absolute top-0 transition duration-200 py-2 px-3 select-none">

        <div class="p-1 text-xs font-bold text-center">
          {{ file.file.name }}
        </div>
        <div class="flex mt-auto">
                              <span class="p-1">
                                <fa-icon fixed-width icon="image"></fa-icon>
                              </span>

          <p class="p-1 text-xs">{{ getHumanReadableSize(file.file.size) }}</p>
          <div
            class="hover:text-white ml-auto focus:outline-none hover:bg-red-500 p-1 rounded-md"
            @click="removeFile(file)">
            <fa-icon fixed-width icon="trash"></fa-icon>
          </div>
        </div>

      </section>
    </article>

    <div class="mt-8">
      <select-element
        v-if="availableLabels.length" :id="'image_type_' + file.objectURL"
        v-model="file.label"
        :allow-unselecting="false" :enable-search="false"
        :options="availableLabels" label="Type" validation-rules="required"></select-element>

      <select-element
        v-if="enableMediaLocalization" v-model="file.localization"
        :allow-unselecting="false"
        :enable-search="false" :options="availableLanguages" label="Langue"></select-element>
    </div>
  </div>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import {getHumanReadableSize} from "@/helpers/fileHelper";

export default {
  name: "FileUploadImageCard",
  components: {SelectElement},
  props: {
    // A file object which format's defined thriough "FileInpoutElement" component
    file: {
      type: Object,
      required: true
    },
    enableMediaLocalization: {
      type: Boolean,
    },
    availableLabels: {
      type: Array,
      default: () => ([])
    },
    availableLanguages: {
      type: Array,
      default: () => ([])
    },
  },
  methods: {
    getHumanReadableSize,

    removeFile() {
      this.$emit('remove', this.file.objectURL);
    },

  }
}
</script>

<style scoped>

</style>