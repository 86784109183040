<template>
  <div v-if="loading" class="flex justify-center">
    <loading-svg-inline class="w-24 h-24"/>
  </div>
  <div v-else>
    <!-- Delivery Infos & Buttons -->
    <div class="flex flex-col divide-y divide-gray-300">
      <div class="py-3 flex space-x-6 items-center justify-center bg-white">
        <div>{{ getETATime }}h</div>
        <div>ID: {{ delivery.id }}</div>
        <div>Zone: {{ delivery.destination.zoneId }}</div>
      </div>
      <div class="py-3 px-4 flex space-x-2 items-stretch justify-center">
        <fulfilment-number-display
          :fulfilment-number="delivery.fulfilmentNumber"
          class="w-16"
        />
        <box-quantity :quantity="delivery.boxQuantity"/>

        <div
          v-if="hasFrozenItems || hasCooledItems"
          class="bg-gray-900 text-white font-bold pl-2 pr-1 py-1 rounded-md text-lg flex space-x-2 items-center"
        >
          <snow-svg v-if="hasFrozenItems" class="text-white h-5 w-5"/>
          <fridge-svg v-if="hasCooledItems" class="text-white h-5 w-5"/>
        </div>
      </div>

      <div v-if="delivery.instructions.length > 0" class="p-3 bg-red-100">
        <h3 class="text-red-500 text-xs mb-1">Instructions</h3>
        <ul>
          <li
            v-for="instruction in delivery.instructions"
            :key="instruction.id"
            class="list-disc list-inside text-sm text-black"
          >
            {{ instruction.text }}
          </li>
        </ul>
      </div>

      <driver-my-routes-route-box
        padding-classes="px-4 py-3"
        @click="openGoogleMaps"
      >
        <div class="text-sm sm:text-md font-semibold border-b border-gray-400 pb-1">
          {{ getDestination.fullName }}<br>
          {{ getDestination.street }}<br>
          L-{{ getDestination.postalCode }} <span class="mr-3">{{ getDestination.city }}</span>
        </div>
        <div class="font-semibold mt-1">
          <span class="text-gray-600">Étage: </span>
          <span>{{ getFloor }}</span>
          &middot;
          <span class="text-gray-600">Tél: </span>
          {{ getDestination.phone }}
        </div>
      </driver-my-routes-route-box>

      <div v-if="delivery.description" class="p-3">
        <h3 class="text-gray-500 text-xs">Description</h3>
        <div class="mt-1 text-sm">
          {{ delivery.description ?? '/' }}
        </div>
      </div>
      <div class="p-3">
        <div class="flex flex-wrap justify-between items-start">
          <h3 class="text-gray-500 text-xs">
            Instructions en cas d'absence:
          </h3>
          <button-element
            v-if="getDestination.instructions && !isTranslated"
            size="xxsmall"
            @click="translateAbsenceMessage"
          >
            Traduction
          </button-element>
          <button-element
            v-if="isTranslated"
            size="xxsmall"
            type="danger"
            @click="translatedAbsenceMessage = null"
          >
            <fa-icon class="mr-1" fixed-width icon="times"/>
            Traduction
          </button-element>
        </div>
        <div class="mt-1 text-sm">
                    <span v-if="isTranslated" class="italic">
                        {{ translatedAbsenceMessage }}
                    </span>
          <span v-else>
                        {{ getDestination.instructions ?? '/' }}
                    </span>
        </div>


      </div>
      <div class="px-3 py-2 sm:p-6 bg-gray-200 | flex flex-col space-y-1">
        <touch-optimized-button
          v-if="hasOrder"
          type="dark"
          @click="showOrderedProducts"
        >
          <div class="px-2 flex space-x-2 items-center">
            <div
              class="flex-shrink-0 bg-gray-700 rounded-full px-1 h-8 w-8 mt flex flex-col justify-center">
              <fa-icon class="ml-0.5 text-sm" fixed-width icon="search"/>
            </div>
            <div class="flex-1">
              Voir détail de la commande
            </div>
          </div>
        </touch-optimized-button>
        <touch-optimized-button
          v-if="!isMarkedAsDelivered"
          type="primary"
          @click="navigateToMarkDeliveredPage"
        >
          <div class="px-2 flex gap-x-2">
            <div
              class="flex-shrink-0 bg-luxcaddy-600 rounded-full px-1 h-8 w-8 flex flex-col justify-center">
              <fa-icon class="ml-0.5 text-sm text-luxcaddy-300" fixed-width icon="check"/>
            </div>
            <div class="flex-1">
              Marquer livré
            </div>
          </div>
        </touch-optimized-button>
        <div v-else class="p-2 text-center font-bold flex justify-center items-center gap-x-4">
          <fa-icon class="text-luxcaddy" fixed-width icon="check"/>
          Cette livraison a déjà été livré!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryRepository from "@/repositories/DeliveryRepository";
import SnowSvg from "@/components/assets/Pictograms/SnowSvg";
import FridgeSvg from "@/components/assets/Pictograms/FridgeSvg";
import DriverMyRoutesRouteBox
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/Components/DriverMyRoutesRouteBox";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";
import BoxQuantity from "@/components/global/BoxQuantity/BoxQuantity";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "DriverMyRouteDeliveryDetailPage",
  components: {
    ButtonElement,
    BoxQuantity,
    FulfilmentNumberDisplay,
    LoadingSvgInline,
    TouchOptimizedButton,
    DriverMyRoutesRouteBox,
    FridgeSvg,
    SnowSvg
  },
  data: () => ({
    loading: false,
    delivery: null,

    translatedAbsenceMessage: null,
  }),
  created() {
    this.fetchDelivery();
  },
  methods: {
    fetchDelivery() {
      this.loading = true;
      DeliveryRepository
        .getSingle(this.$route.params.delivery)
        .then((res) => {
          this.$set(this, 'delivery', res.data.data);
        }).finally(() => this.loading = false);
    },
    openGoogleMaps() {
      const geo = this.getDestination.geocoding;
      if (geo === null || geo === undefined) {
        alert("Pas de géocodage pour cette adresse!");
        return false;
      }

      window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${geo.latitude},${geo.longitude}&travelmode=driving&dir_action=navigate`;
    },
    showOrderedProducts() {
      if (!this.hasOrder) {
        return false;
      }

      this.$router.push({
        name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.items.index',
        params: {
          route: this.$route.params.route,
          delivery: this.$route.params.delivery,
          order: this.delivery.orderId
        }
      })
    },
    navigateToMarkDeliveredPage() {
      this.$router.push({
        name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.items.mark.index',
        params: {
          route: this.$route.params.route,
          delivery: this.$route.params.delivery,
        }
      })
    },
    translateAbsenceMessage() {
      if (!this.getDestination.instructions) {
        return false;
      }

      DeliveryRepository
        .getTranslatedAbsenceMessage(this.$route.params.delivery)
        .then((res) => {
          this.translatedAbsenceMessage = res.data.data.instructions;
        });
    }
  },
  computed: {
    isMarkedAsDelivered() {
      return this.delivery.status === "delivered";
    },
    getDestination() {
      return this.delivery.destination;
    },
    hasFrozenItems() {
      return this.delivery.hasFrozenItems;
    },
    hasCooledItems() {
      return this.delivery.hasCooledItems;
    },
    hasOrder() {
      return this.delivery.orderId !== null;
    },
    getFloor() {
      const floor = this.getDestination.floor;
      if (floor === "ground")
        return "Rez-de-chaussée"
      else if (floor === "house")
        return "Maison"
      else if (floor === "reception")
        return "Réception"

      return floor;
    },
    getETATime() {
      if (this.$route.query.eta) {
        try {
          return this.$date(this.$route.query.eta).format('HH:mm');
        } catch (e) {
          return "???";
        }
      }
      return "???";
    },
    isTranslated() {
      return this.translatedAbsenceMessage !== null;
    }
  },
}
</script>

<style scoped>

</style>