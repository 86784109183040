<template>
  <select-element
    :enable-search="enableSearch"
    :label="label"
    :limit-option-menu-width="limitOptionMenuWidth"
    :multi="type === 'multi'"
    :options="availableDepartments"
    :touch-optimized="touchOptimized"
    :type="type"
    :validation-rules="validationRules"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import DepartmentRepository from "@/repositories/DepartmentRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "DepartmentSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Departement',
      required: false,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      validator: val => ['single', 'multi'].includes(val)
    },
    /**
     * When enabled, the option menu will not have the same length as the  input, when the input is very long
     */
    limitOptionMenuWidth: {
      type: Boolean,
      default: true
    },
    touchOptimized: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    if (this.options === undefined) {
      this.fetchDepartments();
    } else {
      this.availableDepartments = this.options;
    }
  },
  methods: {
    fetchDepartments() {
      DepartmentRepository
        .getAll()
        .then((res) => {
          this.availableDepartments = res.data.data.map(d => {
            return {
              value: d.id,
              label: d.name,
            };
          });
        });
    }
  },
  data: () => ({
    availableDepartments: [],
  })
}
</script>

