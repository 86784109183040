<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :disabled="disabled"
    :label="label"
    :occupy-validation-space="occupyValidationSpace"
    :options="availableProviders"
    :validation-rules="validationRules"
    :value="value"
    @blur="$emit('blur', $event)"
    @input="$emit('input', $event)"
  ></select-element>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "PaymentProvideSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Prestataire',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    /**
     * Whether it is possible to click on the selected option again to unselect it an emit null.
     */
    allowUnselecting: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    manualOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.manualOnly) {
      return {
        availableProviders: this.$luxcaddyConfig('invoice.paymentTransactions.manualPaymentProviders')
      }
    }
    return {
      availableProviders: this.$luxcaddyConfig('invoice.paymentTransactions.paymentProviders')

    }

  }
}
</script>

