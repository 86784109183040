<template>
  <div>
    <!-- Responsible for fetching notifications in a delay. -->
    <notification-load-timer/>
    <loading-indicator/>

    <div class="justify-between | h-screen flex flex-col lg:flex-row">

      <!-- Content -->
      <div
        v-show="getDisplayBars"
        class="print:hidden flex-shrink-0 text-xs md:text-md h-8 md:h-12 lg:hidden bg-gray-800 flex justify-between items-center px-2 gap-x-4"
      >
        <div
          class="bg-gray-600 px-1 md:px-2 rounded-md w-24 md:w-32 text-center text-gray-200 truncate flex-shrink-0">
          {{ getName }}
        </div>
        <div class="truncate text-gray-300 font-medium italic">
                    <span v-if="$route.meta">
                        {{ $route.meta.title }}
                    </span>
        </div>
      </div>

      <div class="flex-grow overflow-auto">
        <transition name="fade">
          <div>
            <router-view/>
          </div>
        </transition>
      </div>

      <!-- Nav Bar -->
      <touch-interface-nav-bar
        v-show="getDisplayBars"
        ref="navbar"
        class="print:hidden flex-none"
        style="z-index: 40"
      />
    </div>

    <touch-interface-lock-screen-timer/>
  </div>
</template>

<script>
import TouchInterfaceNavBar from "./partials/Nav/TouchInterfaceNavBar";
import TouchInterfaceLockScreenTimer
  from "@/components/layouts/touchInterface/partials/Timer/TouchInterfaceLockScreenTimer";
import LoadingIndicator from "@/components/elements/loading/LoadingIndicator.vue";
import NotificationLoadTimer
  from "@/components/layouts/touchInterface/partials/Nav/Notifications/NotificationLoadTimer.vue";

export default {
  name: "TouchInterfaceLayout",
  components: {NotificationLoadTimer, LoadingIndicator, TouchInterfaceLockScreenTimer, TouchInterfaceNavBar},
  created() {
    this.$store.commit('userInterface/setIsOnTouchInterface', true);
  },
  beforeDestroy() {
    this.$store.commit('userInterface/setIsOnTouchInterface', false);
  },
  computed: {
    getName() {
      return this.$store.getters["authUser/getFirstName"].charAt(0) + ". " + this.$store.getters["authUser/getLastName"];
    },
    getDisplayBars() {
      return this.$store.getters["touchInterface/ui/getDisplayBars"];
    }
  },
}
</script>

<style scoped>
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #9EB544 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #9EB544;
  border-radius: 14px;
  border: 3px solid transparent;
}
</style>
