<template>
  <div class="flex flex-col md:flex-row flex-wrap gap-8">
    <text-input-element v-model="customerMessage" class="w-full" label="Message client"
                        @blur="onCustomerMessageBlur"/>
  </div>
</template>

<script>
import OrderRepository from "../../../../../repositories/OrderRepository";
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";

export default {
  name: "EditOrderSettings",
  components: {TextInputElement},
  data: () => ({
    customerMessage: ''
  }),
  mounted() {
    this.customerMessage = this.$store.getters['order/getCustomerMessage'];
  },
  methods: {
    onCustomerMessageBlur(customerMessage) {
      OrderRepository.editCustomerMessage(this.getOrderId, customerMessage).then(() => {
        this.$store.dispatch('order/refreshOrder').then(() => {
          this.customerMessage = customerMessage;
          this.$sendSuccessToast(`Message client sauvegardé.`);
        });
      })
    }
  },
  computed: {
    getOrderId() {
      return parseInt(this.$route.params.order);
    },
  }
}
</script>

