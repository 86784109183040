/**
 * @typedef Role
 * @type {object}
 * @property {number} id - The ID of the role.
 * @property {string} name - The name of the role.
 * @property {number} level - The level of the role.
 */

const CUSTOMER_ROLE_LEVEL = 0;
const ADMIN_ROLE_LEVEL = 1000;

/**
 * Returns true if the user has ALL of the given permissions
 * @param permissionsRequired
 * @param allUserPermissions
 * @returns {*}
 */
export const userHasPermission = function (permissionsRequired, allUserPermissions) {
  return permissionsRequired.every(p => {
    return allUserPermissions.includes(p);
  })
}

/**
 * Returns true if the user has at least one of the given permissions
 *
 * @param permissionsRequired
 * @param allUserPermissions
 * @returns {*}
 */
export const userHasAtLeastOnePermission = function (permissionsRequired, allUserPermissions) {
  if (!permissionsRequired || (Array.isArray(permissionsRequired) && permissionsRequired.length === 0)) {
    return true;
  }

  return permissionsRequired.some(p => {
    return allUserPermissions.includes(p);
  });
}

/**
 * Determines if a user is allowed to changes the roles of another user.
 *
 * @param {Role[]} allRoles
 * @param {array} currentUserRoleIds - Array of all Role IDs the user trying to edit has
 * @param {array} targetUserRoleIds - Array of all Role IDs the user being edited has
 */
export const userAllowedToEditOtherUser = function (allRoles, currentUserRoleIds, targetUserRoleIds) {
  // Builds full objects from all Roles that the current user has
  const mappedCurrentUserRoles = allRoles.filter(r => currentUserRoleIds.includes(r.id));
  const currentUserRoleLevels = buildUserRoleLevels(mappedCurrentUserRoles);

  // Build full object from all roles that the user being edited has.
  const mappedTargetUserRoles = allRoles.filter(r => targetUserRoleIds.includes(r.id));
  const targetUserRoleLevels = buildUserRoleLevels(mappedTargetUserRoles);

  return canEditRoles(currentUserRoleLevels, targetUserRoleLevels);
}

/**
 *
 * @param {Role[]} mappedRoles
 * @returns {array}
 */
const buildUserRoleLevels = function (mappedRoles) {
  return mappedRoles.flatMap(r => r.level);
}

/**
 * Determines if targetUser only has roles in the role group
 * (RoleGroup = Area between f.ex 100-199, 200-299, ...)
 * as the user.
 *
 * If one rule of the user being edited is in a different ruleGroup that currentUser does not have,
 * currentUser is not allowed to edit the roles of this user.
 *
 * @param {array} currentUserRoleLevels
 * @param {array} targetUserRoleLevels
 */
const canEditRoles = function (currentUserRoleLevels, targetUserRoleLevels) {
  const currentUserMaxLevel = Math.max(...currentUserRoleLevels);
  const targetUserMaxLevel = Math.max(...targetUserRoleLevels);

  // Admins can edit everyone
  if (currentUserMaxLevel >= ADMIN_ROLE_LEVEL) {
    return true;
  }

  // Everyone can edit customers
  if (targetUserMaxLevel === CUSTOMER_ROLE_LEVEL) {
    return true;
  }

  // Check if the current user can edit the target user's roles
  return currentUserMaxLevel > targetUserMaxLevel;
}

/**
 * Determines if the current user can set the role given.
 * Only true when the user is not trying to set a role with a level equal to his role or higher.
 *
 * @param {Number} highestCurrentUserLevel
 * @param {Role} role
 */
export const userCanSetRole = function (highestCurrentUserLevel, role) {

  return highestCurrentUserLevel > role.level || highestCurrentUserLevel === ADMIN_ROLE_LEVEL;
}