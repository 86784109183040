<template>
  <div>
    <page-title>Date & Time Picker Simple</page-title>
    <div class="grid grid-cols-4 gap-2">
      <date-picker-element
        v-model="test"
        label="Date only"
      />

      <date-picker-element
        v-model="testPre"
        label="Date prefilled"
      />

      <div>
        <date-picker-element
          v-model="testDateTimePre"
          label="Date & time prefilled"
          with-time
        />
        {{ testDateTimePre === null }}
        {{ testDateTimePre }}
      </div>

      <div>
        <date-picker-element
          v-model="test2"
          label="Date & Time"
          with-time
        />
        {{ test2 }}
      </div>

      <date-picker-element
        v-model="test2"
        disabled
        label="Date & Time (Disabled)"
        with-time
      />
    </div>

    <hr class="mb-4">
    <page-title>Date Range Picker</page-title>

    <div class="grid grid-cols-3 gap-2">
      <div>
        <date-range-picker-element
          v-model="dateRangeTest1"
          label="Date"
        />
        {{ dateRangeTest1 }}
      </div>

      <div>
        <date-range-picker-element
          v-model="dateRangeTest2"
          label="Date & Time"
          validation-rules="required"
          with-time
        />
        {{ dateRangeTest2 }}
      </div>

      <div>
        <date-range-picker-element
          v-model="dateRangeTest3"
          disabled
          label="Date & Time (Disabled)"
          validation-rules="required"
          with-time
        />
        {{ dateRangeTest3 }}
      </div>

    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";

export default {
  name: "TestPageDatePickerElement",
  components: {DateRangePickerElement, DatePickerElement, PageTitle},
  data() {
    return {
      test: null,
      test2: null,
      testPre: '2024-03-02',
      testDateTimePre: '2024-03-02 10:00',


      dateRangeTest1: {
        startDate: null,
        endDate: null,
      },
      dateRangeTest2: {
        startDate: null,
        endDate: null,
      },
      dateRangeTest3: {
        startDate: null,
        endDate: null,
      },
    }
  },
}
</script>

<style scoped>

</style>