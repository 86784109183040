<template>
  <div>
    <upload-supplier-product-catalog
      v-if="showUploadCard"
      class="mb-6"
      @change="onCatalogUploadDone"
    />
    <list-table
      ref="table"
      :columns="columns"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      create-event
      identifier="productCatalogCountTable"
      @create="showUploadCard = !showUploadCard"
    />
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import SupplierCatalogRepository from "@/repositories/SupplierCatalogRepository";
import UploadSupplierProductCatalog
  from "@/components/pages/SupplierProductCatalogCount/Upload/UploadSupplierProductCatalog";

export default {
  name: "ShowProductCatalogCountList",
  components: {UploadSupplierProductCatalog, ListTable},
  data() {
    return {
      showUploadCard: false,

      title: 'Import Catalogue',
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'delete',
              icon: 'trash',
              hint: 'Supprimér catalogue',
              confirmation: {
                title: 'Supprimér catalogue?',
                text: 'Étes vous sure que vous voulez supprimé ce fournisseur catalogue?'
              },
              deleteAction: (row) => SupplierCatalogRepository.delete(row.supplierId),
              successText: 'Catalogue a ete supprimé',
              deletePermissions: ['SUPPLIER_CATALOG_WRITE']
            },
          ],
        },
        {
          caption: '#',
          width: 80,
          dataField: 'supplierId',
        },
        {
          caption: 'Fournisseur',
          dataField: 'supplierName',
          sort: {dataField: 'supplier'}
        },
        {
          caption: 'Nbr de produits',
          dataField: 'productCount',
          sort: {dataField: 'productCount'}
        },
        {
          caption: 'Dernier import',
          calculateCellValue: row => this.$date(row.importedAt).format('DD.MM.YYYY HH:mm')
        },

      ],
      repositoryFunc: SupplierCatalogRepository.getProductCatalogCounts
    }
  },
  methods: {
    onCatalogUploadDone() {
      this.$refs.table.refresh().then(() => {
        this.showUploadCard = false;
      });
    }
  },
}
</script>