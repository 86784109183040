<template>
  <div
    v-if="filters.length > 0"
    class="relative select-none"
  >
    <button-element
      size="small"
      type="light"
      @click="showMenu = !showMenu"
    >
      <fa-icon fixed-width icon="plus"/>
    </button-element>

    <div
      v-if="showMenu"
      v-click-outside="closeMenu"
      class="absolute top-8 left-0 bg-white rounded-lg border p-3 shadow z-30 w-72"
    >
      <ul class="divide-y">
        <li
          v-for="(filter, i) in filters"
          :key="filter.caption + '-' + i"
          class="py-1 px-2 hover:bg-gray-100 rounded cursor-pointer"
          @click="onFilterClick(filter)"
          v-html="getFilterCaption(filter)"
        />
      </ul>
    </div>
  </div>
</template>

<script>

import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "AddFilterMenu",
  components: {ButtonElement},
  props: {
    filters: {
      type: Array,
      required: true
    },
    tableIdentifier: {
      type: String,
      required: true
    }
  },
  methods: {
    getFilterCaption(filter) {
      if (filter.caption) {
        return filter.caption;
      }

      // Filters without captions are usually filters with sub inputs.
      return filter.inputs[0].caption + ' <span class="font-bold">X</span> ' + filter.inputs[1].caption + ' <span class="font-bold">Y</span>'
    },
    onFilterClick(filter) {
      this.moveFilterInStoreToEnd(filter);
      this.$emit('select', filter);
      this.showMenu = false;
    },
    moveFilterInStoreToEnd(filter) {
      // Moves the filter that has just been added to the end of all filter
      // So that it appears last in list, which feels more natural for a filter
      // where visibility has just been toggled on.
      this.$store.commit('listTable/filters/moveFilterToEnd', {
        tableIdentifier: this.tableIdentifier,
        filterKey: filter.filterKey
      });
    },

    closeMenu() {
      this.showMenu = false;
    }
  },
  data() {
    return {
      showMenu: false
    }
  },
}
</script>

<style scoped>

</style>