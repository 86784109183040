<template>
  <div class="flex justify-between gap-2">
    <button-element
      :disabled="!canFulfill"
      type="dark"
      @click="finalizeWithInvoice"
    >
      <fa-icon class="mr-2" fixed-width icon="check"/>
      Finaliser
    </button-element>

    <modal ref="exceptionModal" title="Instructions">
      <div>
        Pour cette commande, il y a quelques instructions.<br>
        Veuillez cliquer sur le bouton ci-dessous pour confirmer les instructions.
      </div>

      <div class="mt-8 flex gap-x-4">
        <button-element
          type="danger"
          @click="sendFulfilmentRequest(false, finalizeInvoice)"
        >
          <fa-icon fixed-width icon="exclamation-triangle"/>
          {{ modalBtnTitle }}
        </button-element>

        <button-element
          type="light"
          @click="$refs.exceptionModal.closeModal()"
        >
          Annuler
        </button-element>
      </div>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import Modal from "@/components/elements/modals/Modal";
import {mapGetters} from "vuex";
import OrderRepository from "@/repositories/OrderRepository";

export default {
  name: "OrderFulfillButton",
  components: {Modal, ButtonElement},
  data: () => ({
    instructionRelatedExceptionGiven: false,
    finalizeInvoice: true,
    modalBtnTitle: ''
  }),
  methods: {

    finalizeWithInvoice() {
      this.modalBtnTitle = 'Forcer "Finaliser"';
      this.sendFulfilmentRequest(true, true);
    },

    sendFulfilmentRequest(withValidation, finalizeInvoice) {
      OrderRepository.fulfillOrder(
        this.getOrderId,
        withValidation,
        finalizeInvoice
      )
        .then(() => {
          if (!withValidation) {
            this.$refs.exceptionModal.closeModal();
          }
          this.$sendSuccessToast("Commande marquée comme préparé.");
          this.$store.dispatch('order/refreshOrder');
        })
        .catch(err => {
          if (withValidation && err.response && err.response.status === 422) {
            const exceptionName = err.response.data.name;
            this.finalizeInvoice = finalizeInvoice;
            if (exceptionName.includes("AdminInstructionsShouldBeValidatedException")) {
              this.$refs.exceptionModal.openModal();
            }
          }
        });
    }
  },
  computed: {
    ...mapGetters('order', [
      'getOrderId', 'canFulfill', 'refreshOrder'
    ])
  },
}
</script>