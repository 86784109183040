import CartRepository from "../../../repositories/CartRepository";
import TimeSlotRepository from "../../../repositories/TimeSlotRepository";

const getDefaultState = () => {
  return {
    cart: {
      member: {},
      items: [],
      total: {
        vatDetails: []
      },
      packages: [],
      selectedPackagingOptions: []
    },
    errors: [],
    timeSlots: {
      zone: null,
      slots: []
    }
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getCartItems: state => state.cart.items || [],
    getMember: state => state.cart.member || {},
    getMemberId: state => state.cart.memberId || null,
    getTotal: state => state.cart.total || {},
    getVatDetails: state => state.cart.total?.vatDetails || [],
    getPackageGroups: state => state.cart.packages,
    getSelectedPackagingOptions: state => state.cart.selectedPackagingOptions,
    getProductsIdsWithQuantities: state => (state.cart.items || []).map((p) => {
      return {
        id: p.id,
        quantity: p.quantity
      }
    }),
    getTimeSlots: state => state.timeSlots.slots,
    getErrors: state => state.errors,
    hasErrors: state => state.errors?.length > 0 || false,
    getIsAnythingInCart: state => state.cart.items?.length || 0,
  },
  mutations: {
    setCartData(state, cartData) {
      if (Array.isArray(cartData) && !cartData.length) {
        this.commit('cart/resetCartState');
      }

      cartData.selectedPackagingOptions = [];
      state.cart = cartData;
    },
    resetPackagingSelection(state) {
      state.cart.selectedPackagingOptions = [];
    },
    setCartErrors(state, errors) {
      state.errors = errors;
    },
    setProductQuantity(state, payload) {
      let itemInCart = state.cart.items.find(p => p.id === payload.productId);

      if (itemInCart) {
        return state.cart.items.find(i => i.id === payload.productId).quantity = payload.quantity;
      }
    },
    setTimeSlots(state, {zone, timeSlots}) {
      state.timeSlots.zone = parseInt(zone);
      state.timeSlots.slots = timeSlots;
    },


    selectOrUnselectPackaging(state, {id, quantity}) {
      let options = state.cart.selectedPackagingOptions;
      let existingOption = options.find(o => o.id === id);

      if (existingOption) {
        state.cart.selectedPackagingOptions = options.filter(o => o.id !== id);
      } else {
        options.push({
          id: id,
          quantity: quantity !== undefined ? quantity : 1
        })
      }
    },
    setSelectedPackagingQuantity(state, {id, quantity}) {
      let options = state.cart.selectedPackagingOptions;
      let existingOption = options.find(o => o.id === id);

      if (existingOption) {
        existingOption.quantity = quantity;
      }
    },

    resetCartState(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    fetchCart({commit, getters}, payload) {
      const showLoadingIndicator = payload.showLoadingIndicator === undefined ? true : payload.showLoadingIndicator;

      // Reset state first when cart trying to load belongs to a
      // different  customer than the one that is currently loaded
      if (getters.getMemberId) {
        if (payload.memberId !== getters.getMemberId) {
          commit('resetCartState');
        }
      }

      return CartRepository.getSingle(payload.memberId, showLoadingIndicator).then((res) => {
        commit('setCartData', res.data.data);
        commit('setCartErrors', res.data.notifications?.errors || []);
      });
    },
    setCartItemQuantity({getters}, {productId, quantity}) {
      CartRepository.setItemQuantity(getters.getMemberId, productId, quantity);
    },
    fetchTimeSlots({commit}, payload) {
      return TimeSlotRepository.getForZone(payload.zone, {
        startDate: payload.startDate,
        endDate: payload.endDate,
      }).then((res) => {
        commit('setTimeSlots', {
          zone: payload.zone,
          timeSlots: res.data.data
        });
      }).catch((err) => {
        if (err.response?.status === 400) {
          commit('setTimeSlots', {
            zone: payload.zone,
            timeSlots: []
          })
        }
      })
    }
  },
}