<template>
  <div
    class="relative | select-none | bg-white px-2 border border-gray-400 border-opacity-80 flex-1 flex items-center justify-center object-contain cursor-pointer"
    @click="increasePackedQuantity"
  >
    <div
      v-if="isFulfilledCompletely"
      class="absolute bg-gray-200 bg-opacity-30 inset-0 flex flex-col items-center justify-center"
    />
    <div class="absolute bottom-1 left-1 bg-luxcaddy-900">
      <fa-icon
        v-if="isFulfilledCompletely"
        class="text-3xl p-1 text-luxcaddy-500 border border-luxcaddy-800  rounded-lg"
        fixed-width
        icon="check"
      />
    </div>

    <div
      class="absolute | px-2 top-1 left-1 text-2xl text-center font-bold shadow bg-packed text-white border border-white shadow-xl">
      {{ getToBePackedQuantity }}
    </div>

    <div class="absolute | px-2 right-1 bottom-1 text-2xl text-center font-bold shadow bg-gray-200 shadow-xl">
      {{ getPackedQuantity }}
    </div>

    <img
      :alt="packagingItem.name"
      :src="getPackagingImageUrl"
      :title="packagingItem.name"
      class="h-24"
    />
  </div>
</template>

<script>
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";

export default {
  name: "OrderFulfilmentPackagingItem",
  props: {
    packagingItem: {
      type: Object
    },
  },
  methods: {
    increasePackedQuantity() {
      if (this.getPackedQuantity >= this.getToBePackedQuantity) {
        this.$store.dispatch('fulfilment/resetItemPackedQuantity', this.packagingItem.id);
        return;
      }

      this.$store.dispatch('fulfilment/fulfillItem', {
        itemId: this.packagingItem.id,
        packedQuantity: 1,
        type: "package"
      });
    }
  },
  computed: {
    getPackagingImageUrl() {
      return getPrimaryImageUrlFromImageObject(this.packagingItem.image);
    },
    getToBePackedQuantity() {
      return this.packagingItem.toBePackedQuantity;
    },
    getPackedQuantity() {
      return this.packagingItem.packedQuantity;
    },
    isFulfilledCompletely() {
      return this.getToBePackedQuantity === this.getPackedQuantity;
    }
  },
}
</script>

<style scoped>
.bg-packed {
  background-color: #2C587B;
}
</style>