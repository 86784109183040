<template>
  <div>
    <category-selection-tree
      :categories-marked-as-included="includedCategoryIds"
      :is-virtual="isVirtual"
      :set-max-height="false"
      compact
      start-expanded
    />
  </div>
</template>

<script>

import CategorySelectionTree from "@/components/elements/categoryManagement/Tree/Selection/CategorySelectionTree";

export default {
  name: "EditProductCategoryList",
  components: {CategorySelectionTree},
  data: () => ({
    categoryList: []
  }),
  props: {
    productId: {
      type: Number,
      required: true
    },
    isVirtual: {
      type: Boolean,
      required: false
    },
    /**
     * An array of category ids the given product is part of.
     */
    includedCategoryIds: {
      type: Array
    }
  },
}
</script>

<style scoped>

</style>