<template>
  <table-row>
    <table-data-cell>
      <product-quick-action-modal v-if="reference.type === 'Product'" :id="row.id"/>
      <span v-else>{{ reference.id }}</span>
    </table-data-cell>
    <table-data-cell>
      {{ reference.type }}
    </table-data-cell>
    <table-data-cell>
      <product-name :product="reference"/>
    </table-data-cell>
    <table-data-cell>
      {{ reference.totalQuantity }}
    </table-data-cell>
    <table-data-cell>
      <money-amount
        :amount="reference.totalPrice"
      />
    </table-data-cell>
  </table-row>
</template>
<script>

import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal.vue";
import TableDataCell from "@/components/elements/tables/TableDataCell.vue";
import TableRow from "@/components/elements/tables/TableRow.vue";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";
import MoneyAmount from "@/components/global/Money/MoneyAmount.vue";

export default {
  name: "BankMovementSuggestionItem",
  components: {ProductQuickActionModal, TableDataCell, TableRow, ProductName, MoneyAmount},
  props: {
    reference: {
      type: Object,
      required: true
    },
  }
}
</script>

