<template>
  <delivery-time-slot-calendar
    :allow-modifications="true"
  />
</template>

<script>
import DeliveryTimeSlotCalendar from "./Manage/Calendar/DeliveryTimeSlotCalendar";

export default {
  name: "ShowDeliveryTimeSlots",
  components: {DeliveryTimeSlotCalendar},
}
</script>

