<template>
  <div>
    <button-element
      size="small"
      type="danger"
      @click="openModal"
    >
      <fa-icon fixed-width icon="trash"></fa-icon>
    </button-element>

    <delete-confirmation-modal ref="modal" title="Supprimer produit" @delete="deleteItem">
      Voulez-vous supprimer ce produit de la commande?
    </delete-confirmation-modal>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal";
import {mapActions, mapGetters} from "vuex";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";

export default {
  name: "SupplierOrderDeleteItemButton",
  components: {DeleteConfirmationModal, ButtonElement},
  props: {
    itemId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters('supplierOrders', ['getOrderId']),
  },
  methods: {
    ...mapActions('supplierOrders', ['fetchOrder']),
    openModal() {
      this.$refs.modal.openModal();
    },
    deleteItem() {
      SupplierOrderRepository
        .deleteItem(this.getOrderId, this.itemId)
        .then(() => {
          this.fetchOrder(this.getOrderId).finally(() => {
            this.$sendSuccessToast('Le produit a été supprimé.');
            this.$emit('deleted');
          });
        })
    }
  },
}
</script>

