<template>
  <div>
    <h3 class="font-bold mb-2">
      Lier transactions de paiement
    </h3>


    <!-- Linking is possible -->
    <template v-if="canLinkPaymentTransactions">
      <card
        :navigation-through-query-params="false"
        size="small"
        type="light"
      >
        <card-tab
          id="createNew"
          icon="plus"
          title="Créer et lier nouvelle transaction"
        >
          <bank-movement-create-and-link-transaction v-if="getBankMovementId"/>
        </card-tab>
        <card-tab
          id="linkExisting"
          icon="link"
          title="Lier une transaction existante"
        >
          <bank-movement-list-existing-payment-transaction/>
        </card-tab>
      </card>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Card from "@/components/elements/cards/Card";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import BankMovementListExistingPaymentTransaction
  from "@/components/pages/Accounting/BankMovements/Edit/Sub/LinkPaymentTransactions/Sub/LinkExisting/BankMovementListExistingPaymentTransaction";
import BankMovementCreateAndLinkTransaction
  from "@/components/pages/Accounting/BankMovements/Edit/Sub/LinkPaymentTransactions/Sub/CreateAndLinkNew/BankMovementCreateAndLinkTransaction";

export default {
  name: "BankMovementLinkPaymentTransactionsEntry",
  components: {BankMovementCreateAndLinkTransaction, BankMovementListExistingPaymentTransaction, CardTab, Card},
  computed: {
    ...mapGetters('bankMovements', [
      'isBankMovementTreated', 'getBankMovementUnattributedAmount', 'getBankMovementId'
    ]),
    canLinkPaymentTransactions() {
      return !this.isBankMovementTreated;
    }
  },
}
</script>