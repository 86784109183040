import RouteRepository from "@/repositories/RouteRepository";
import VehicleRepository from "@/repositories/VehicleRepository";

const getDefaultState = () => {
  return {
    planningRouteJobId: null,
    selectedVehicleIds: [],


    availableVehicles: [],
  };
}


export default {
  state: getDefaultState(),
  getters: {
    getPlanningRouteJobId: state => state.planningRouteJobId,
    isPlanningRouteJobInProgress: state => state.planningRouteJobId !== null,

    getAvailableVehicles: state => state.availableVehicles,

    isVehicleSelected: (state) => (id) => state.selectedVehicleIds.includes(id),
    getSelectedVehicleIds: state => state.selectedVehicleIds,
    hasSelectedAtLeastOneVehicle: state => state.selectedVehicleIds.length > 0,
  },
  mutations: {
    setPlanningRouteJobId(state, jobId) {
      state.planningRouteJobId = jobId;
    },

    setAvailableVehicles(state, vehicles) {
      state.availableVehicles = vehicles;
    },

    selectAllAvailableVehicles(state) {
      state.selectedVehicleIds = state.availableVehicles.flatMap(v => v.id);
    },
    toggleVehicleSelection(state, vehicleId) {
      if (state.selectedVehicleIds.includes(vehicleId)) {
        state.selectedVehicleIds = state.selectedVehicleIds.filter(vId => vId !== vehicleId);
      } else {
        state.selectedVehicleIds.push(vehicleId);
      }
    }
  },
  actions: {
    cancelRoutePlanningRequest({commit, dispatch, getters}) {
      RouteRepository.cancelPlanningRequest(getters.getPlanningRouteJobId).then(() => {
        dispatch('routeManager/fetchAllRoutes', null, {root: true});
        commit('routeManager/clearSelectedDeliveries', null, {route: true});
      })
    },
    fetchAvailableVehicles({commit}) {
      VehicleRepository.getPaginated(999, 1, [], [
        {filterKey: "isActive", filterValue: true},
        {filterKey: "isOperational", filterValue: true}
      ]).then((res) => {
        commit('setAvailableVehicles', res.data.data);
        commit('selectAllAvailableVehicles');
      })
    },

    startAutoPlanningJob({rootGetters, getters, dispatch}, {
      startDate,
      endDate,
      objectives
    }) {
      return RouteRepository.startPlanningRequest(
        rootGetters["routeManager/getSelectedDeliveries"],
        getters["getSelectedVehicleIds"],
        startDate,
        endDate,
        objectives
      ).then(() => {
        dispatch('routeManager/fetchAllRoutes', null, {root: true});
      })
    }
  }
}