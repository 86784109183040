<template>
  <div class="w-full">
    <div class="flex justify-between mb-4">
      <h3 class="text-xl text-luxcaddy mb-2">{{ getTitle }}</h3>

      <rule-actions
        v-if="!editMode && !isInReadOnlyMode"
        :rule="rule"
        :rule-able-model="ruleAbleModel"
        @edit="editMode = true"
      />
    </div>

    <rule-filter-table
      v-if="!editMode"
      :rule="rule"
    />

    <create-or-update-rule-form
      v-if="editMode"
      :context="rule.appliedOn"
      :mode="getFormModeByRule(rule)"
      :rule-able-model="ruleAbleModel"
      @cancel="onEditCancel"
      @done="onEditDone"
    />
  </div>
</template>

<script>
import RuleActions from "./RuleActions";
import CreateOrUpdateRuleForm from "./CreateOrUpdateRuleForm";
import RuleFilterTable from "./RuleFilterTable";

export default {
  name: "RuleDisplay",
  data: () => ({
    editMode: false,
  }),
  components: {RuleFilterTable, CreateOrUpdateRuleForm, RuleActions},
  props: {
    rule: {
      type: Object,
      required: true
    },
    ruleAbleModel: {
      type: String,
      required: true
    }
  },
  mounted() {
    // Rule with Context has just been created locally, instantly go to edit mode
    if (!this.rule.filters.length && this.editMode === false) {
      this.editMode = true;
    }
  },
  watch: {
    isInReadOnlyMode() {
      this.editMode = false;
    }
  },
  methods: {
    onEditCancel() {
      this.editMode = false;
      this.$emit('refetch');

      switch (this.ruleAbleModel) {
        case "Fee":
          return this.$store.dispatch('fees/refetchCurrentFee');
        case "Campaign":
          return this.$store.dispatch('campaigns/refetchCurrentCampaign');
      }
    },
    onEditDone() {
      this.editMode = false;
      this.$emit('done');
    },
    getFormModeByRule(rule) {
      return rule.new !== undefined && rule.new === true ? 'create' : 'update';
    }
  },
  computed: {
    getTitle() {
      switch (this.rule.appliedOn) {
        case "Cart":
          return "Régles panier d'achat";
        case "Product":
          return "Régles produit";
        case "MemberOrdersCount":
          return "Régles Nombre de commandes membres";
        case "Promocode":
          return "PromoCode";
        case "ZoneTimeSlot":
          return "ZoneTimeSlot";
        case "PaymentProvider":
          return "Prestataire de paiement";
        default:
          return "";
      }
    },
    isInReadOnlyMode() {
      return this.$isReadOnly();
    }
  }
}
</script>

