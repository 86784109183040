<!--
    The checkbox will actually not do anything.
    The underlying logic on a ListTableTableRow handles selection.
 -->
<template>
  <input
    :checked="isChecked"
    class="form-checkbox h-4 w-4 text-gray-600"
    type="checkbox"
  />
</template>

<script>
export default {
  name: "ListTableRowSelect",
  props: {
    row: {
      type: Object,
      required: true
    },
    /*
        The key which will be used to trigger batch actions.
        ["id"] or multiple values:
        ["id", "productId]"
     */
    selectValues: {
      type: [Array, Function],
    },
  },
  computed: {
    isChecked() {
      return this.$store.getters['listTable/isRowSelected'](this.selectValues(this.row));
    }
  },
}
</script>

<style scoped>

</style>