<template>
  <div>

    <div class="flex justify-between gap-x-2">
      <page-title>
        {{ $store.getters['authUser/getFullName'] }}
        <span v-if="getExpectedDeliveryDate">
                -
                Actuellement en commande client pour le
                {{ getExpectedDeliveryDate }}
                </span>
        <span v-if="getCurrentSnapshotDate">
                    (Changements depuis {{ $date(getCurrentSnapshotDate).format("DD.MM HH:mm") }})
                </span>
      </page-title>

      <div class="flex gap-x-2 items-center">
        <date-picker-element
          v-model="expectedDeliveryDate"
          :only-future="true"
          label="Date de livraison"
          @input="preview"
        />
        <button-with-menu-element
          :actions="getActionLinks"
          button-text="Filtrer par date d'export"
          class="mt-1.5"
          close-after-action-executed
          size="small"
          type="dark"
        />

        <button-element
          class="mt-1.5"
          size="small"
          type="dark"
          @click="exportList(getCurrentSnapshotId, getExpectedDeliveryDate)"
        >
          <fa-icon class="mr-2" fixed-width icon="download"/>
          Export
        </button-element>
      </div>
    </div>


    <custom-table
      :headings="getHeadings"
      :show-empty="$store.getters['supplierOrderPreview/getItems'].length === 0"
    >
      <table-row
        v-for="item in $store.getters['supplierOrderPreview/getItems']"
        :key="item.productId + item.name"
      >
        <table-data-cell>{{ item.supplierReference }}</table-data-cell>
        <table-data-cell>{{ item.tags }}</table-data-cell>
        <table-data-cell>
          {{ item.orderedBundleQuantity }}
        </table-data-cell>
        <table-data-cell>
          {{ item.bundle.quantity + 'x' + item.bundle.content + item.bundle.unit }}
        </table-data-cell>
        <table-data-cell>
          {{ item.name }}
        </table-data-cell>
      </table-row>
    </custom-table>

  </div>
</template>

<script>
import ButtonWithMenuElement from "@/components/elements/buttons/ButtonWithMenuElement";
import CustomTable from "@/components/elements/tables/CustomTable";
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import PageTitle from "@/components/elements/pages/PageTitle";
import SupplierPreviewRepository from "@/repositories/SupplierPreviewRepository";
import {exportResponse} from "@/helpers/exportHelper";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";

export default {
  name: "ShowSupplierPreviewTable",
  components: {
    DatePickerElement,
    ButtonElement,
    PageTitle,
    TableDataCell,
    TableRow,
    CustomTable,
    ButtonWithMenuElement
  },
  data: () => ({
    currentSnapShotId: null,
    expectedDeliveryDate: null,
  }),
  created() {
    const date = this.$date().add(1, 'day').format('DD.MM.YYYY');

    this.$store.dispatch('supplierOrderPreview/fetchSnapshots');
    this.$store.dispatch('supplierOrderPreview/fetchItems', {
      sinceSnapshotId: this.$route.query.snapshotId ?? null,
      deliveryDate: date
    })
      .then(() => {
        this.expectedDeliveryDate = this.getExpectedDeliveryDate;
      });
  },
  methods: {
    setSnapshot(snapshotId = null) {
      let link = {
        name: 'suppliers.preview.list',
      };

      if (snapshotId !== null) {
        link["query"] = {
          snapshotId: snapshotId,
        }
      }

      this.$router.push(link);
      this.$store.dispatch('supplierOrderPreview/fetchItems', {
        sinceSnapshotId: snapshotId,
        deliveryDate: this.expectedDeliveryDate
      })
        .then(() => {
          this.expectedDeliveryDate = this.getExpectedDeliveryDate;
        });
    },
    preview(params) {
      if (this.$route.query.snapshotId) {
        const snapshots = this.$store.getters['supplierOrderPreview/getSnapshots'];
        let selectedSnapshot = null;
        snapshots.forEach(snapshot => {
          if (snapshot.id === this.$route.query.snapshotId) {
            selectedSnapshot = snapshot;
          }
        });

        if (selectedSnapshot &&
          this.$date(selectedSnapshot.deliveryDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).format('DD.MM.YYYY') !== this.$date(params, ['YYYY-MM-DD', 'DD.MM.YYYY']).format('DD.MM.YYYY')) {
          this.$router.replace({'snapshotId': null});
        }
      }

      this.$store.dispatch('supplierOrderPreview/fetchItems', {
        sinceSnapshotId: this.$route.query.snapshotId ?? null,
        deliveryDate: params
      });
    },

    exportList(snapshotId = null, deliveryDate = null) {
      SupplierPreviewRepository.export(snapshotId, deliveryDate)
        .then(res => {
          exportResponse(res, 'commande-luxcaddy');
          this.$store.dispatch('supplierOrderPreview/fetchSnapshots');
        });
    }
  },
  computed: {
    getActionLinks() {
      const snapshots = this.$store.getters['supplierOrderPreview/getSnapshots'];

      let links = [];

      links.push({
        label: 'Afficher tous',
        action: () => this.setSnapshot(),
        key: null,
      });


      snapshots.forEach(snapshot => {
        if (this.$date(snapshot.deliveryDate).format('DD.MM.YYYY', ['YYYY-MM-DD', 'DD.MM.YYYY']) === this.$date(this.expectedDeliveryDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).format('DD.MM.YYYY')) {
          links.push({
            label: this.$date(snapshot.date, ['YYYY-MM-DD HH:mm', 'DD.MM.YYYY HH:mm']).format('DD.MM.YYYY HH:mm'),
            action: () => this.setSnapshot(snapshot.id),
            key: snapshot.id
          });
        }
      });

      return links;
    },
    getCurrentSnapshotId() {
      return this.$route.query?.snapshotId
        ? this.$route.query.snapshotId
        : null;
    },
    getExpectedDeliveryDate() {
      return this.$store.getters["supplierOrderPreview/getExpectedDeliveryDate"]
    },
    getCurrentSnapshotDate() {
      if (this.getCurrentSnapshotId === null) {
        return null;
      }

      return this.$store.getters["supplierOrderPreview/getSnapshots"].find(s => s.id === this.getCurrentSnapshotId)?.date ?? null;
    },
    getHeadings() {
      return [
        {label: 'Réf.'},
        {label: 'Catégorie'},
        {label: 'Quantité'},
        {label: 'Contenu'},
        {label: 'Produit'}
      ];
    }
  },
}
</script>