<template>
  <card
    size="small"
    title="Téléchargement"
  >
    <form-wrapper
      ref="form"
      :show-submit-button="false"
      @submit="uploadFiles"
    >
      <file-input-element
        v-model="filesToUpload"
        :image-only="false"
        :max-file-size-per-file="12 * (1024 * 1024)"
        title="Ajouter fichiers"
      />
    </form-wrapper>

    <div v-if="filesToUpload.length && !hideUploadBtn" class="flex mt-4 justify-between">
      <button-element v-if="showCancelBth" class=" ml-1 float-left" type="light" @click="cancel">
        Annuler
      </button-element>
      <button-element class="ml-auto" type="primary" @click="$refs.form.onSubmit()">
        {{ submitBtn }}
      </button-element>

    </div>
  </card>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import FileInputElement from "@/components/elements/forms/elements/base/FileInputElement.vue";
import ExternalInvoicePDFRepository from "@/repositories/ExternalInvoicePDFRepository";
import Card from "@/components/elements/cards/Card.vue";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";

export default {
  name: "UploadExternalInvoicePdfs",
  components: {Card, FileInputElement, ButtonElement, FormWrapper},
  props: {
    uploadToStorage: {
      type: Boolean,
      default: true
    },
    showCancelBth: {
      type: Boolean
    },
    submitBtn: {
      type: String,
      default: 'Télécharger'
    },
    supplierOrderId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      filesToUpload: [],
      hideUploadBtn: false
    }
  },
  methods: {
    cancel() {
      this.filesToUpload = [];
      this.$emit('cancel');
    },
    uploadFiles(callback) {
      if (!this.filesToUpload.length) {
        return false;
      }

      if (!this.uploadToStorage) {
        this.hideUploadBtn = true;
        this.$emit('upload', this.filesToUpload);
        return;
      }

      if (this.supplierOrderId !== null) {
        SupplierOrderRepository.uploadAndLinkInvoicePDF(this.supplierOrderId, this.filesToUpload)
          .then(() => {
            this.filesToUpload = [];
            this.$emit('done');
          }).finally(() => callback());
      } else {
        ExternalInvoicePDFRepository
          .uploadPDFs(this.filesToUpload)
          .then(() => {
            this.$sendSuccessToast("Les fichiers ont été téléchargées.");
            this.filesToUpload = [];
            this.$emit('after-upload');
          }).finally(() => callback());
      }

    },
  },
}
</script>

<style scoped>

</style>