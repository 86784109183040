import apiService from '@/repositories/Base/BaseRepository';
import ApiService from "./Base/BaseRepository";

const url = '/invoices';

export default {
  async get() {
    return await apiService.get(`${url}/`)
  },

  async getPaginated(
    perPage = 20,
    page = 1,
    sorts = [],
    filters = [],
    withTotals = 0
  ) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async executeBatchAction(action, items, params) {
    return await apiService.patch(`${url}/batchAction`, {
      jobAction: action,
      items: items,
      params: params
    });
  },

  async getInvoice(invoiceId) {
    return await apiService.get(`${url}/${invoiceId}`);
  },
  payments: {
    async getPayments(
      filterKey,
      filterValue,
      sorts = [],
      filters = []
    ) {
      filters.push({
        filterKey: filterKey,
        filterValue: filterValue
      })

      return await apiService
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/transactions`);
    },
    async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = []) {
      return await apiService
        .setPagination(perPage, page, withTotals)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/transactions`);
    },
    async getPaymentsLogPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
      return await apiService
        .setPagination(perPage, page, withTotals)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/paymentLogs`);
    },

    async getPaymentsLogsByInvoice(
      filterKey,
      filterValue,
      sorts = [],
      filters = []
    ) {
      filters.push({
        filterKey: filterKey,
        filterValue: filterValue
      })

      return await apiService
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/paymentLogs`);
    },

    async addPayment(invoiceId, payload) {
      return await apiService.post(`${url}/${invoiceId}/transactions/add`, payload);
    },

    async deleteInvoicePayment(invoiceId, $paymentId) {
      return await apiService.delete(`${url}/${invoiceId}/transactions/${$paymentId}`);
    },

  },


  external: {
    async createExternalInvoice(payload) {
      return await apiService.post(`${url}/external`, payload);
    },


    async finalizeInvoice(invoiceId) {
      return await apiService.patch(`${url}/external/${invoiceId}/finalize`);
    },


    async update(invoiceId, payload) {
      return await ApiService
        .patch(`${url}/external/${invoiceId}`, payload);
    },

    async removeLinkToExternalInvoicePDF(invoiceId) {
      return await ApiService
        .patch(`${url}/external/${invoiceId}/external-invoice-pdf/unlink`, null);
    },


    items: {
      async addItem(invoiceId, payload) {
        return await apiService.post(`${url}/external/${invoiceId}/items`, payload);
      },

      async editItem(invoiceId, invoiceItemId, payload) {
        return await apiService.patch(`${url}/external/${invoiceId}/items/${invoiceItemId}`, payload);
      },

      async deleteItem(invoiceId, itemId) {
        return await apiService.delete(`${url}/external/${invoiceId}/items/${itemId}`);
      }
    },

    async deleteSingle(invoiceId) {
      return await apiService.delete(`${url}/external/${invoiceId}`);
    },
  },

  internal: {
    async createInvoice(payload) {
      return await apiService.post(`${url}/internal`, payload);
    },

    async eInvoice(invoiceId) {
      return await apiService.post(`${url}/internal/${invoiceId}/e-invoice`);
    },

    async update(invoiceId, payload) {
      return await ApiService
        .patch(`${url}/internal/${invoiceId}`, payload);
    },
    async getPaginated(
      perPage = 20,
      page = 1,
      sorts = [],
      filters = [],
      withTotals = 0
    ) {
      return await apiService
        .setPagination(perPage, page, withTotals)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/internal`);
    },

    async getInvoice(invoiceId) {
      return await apiService.get(`${url}/internal/${invoiceId}`);
    },


    async finalizeInvoice(invoiceId) {
      return await apiService.patch(`${url}/internal/${invoiceId}/finalize`);
    },

    async deleteSingle(invoiceId) {
      return await apiService.delete(`${url}/internal/${invoiceId}`);
    },


    async downloadPdf(invoiceId, $issuedByItix) {
      let pdfUrl = `${url}/internal/${invoiceId}/pdf`;

      if ($issuedByItix) {
        pdfUrl = `${pdfUrl}?itixAsIssuer`;
      }

      return await apiService
        .downloadBlob(pdfUrl);
    },

    payments: {
      async getPayments(
        filterKey,
        filterValue,
        sorts = [],
        filters = []
      ) {
        filters.push({
          filterKey: filterKey,
          filterValue: filterValue
        });

        return await apiService
          .setSorts(sorts)
          .setFilters(filters)
          .get(`${url}/internal/transactions`);
      },

      async getPaymentsLogs(
        filterKey,
        filterValue,
        sorts = [],
        filters = []
      ) {
        filters.push({
          filterKey: filterKey,
          filterValue: filterValue
        })

        return await apiService
          .setSorts(sorts)
          .setFilters(filters)
          .get(`${url}/internal/paymentLogs`);
      },

      async payByWallet(invoiceId, sendCustomerNotification) {
        return await apiService
          .post(`${url}/internal/${invoiceId}/payment/wallet`, {sendCustomerNotification: sendCustomerNotification});
      },
    },


    items: {
      async addItem(invoiceId, payload) {
        return await apiService.post(`${url}/internal/${invoiceId}/items`, payload);
      },

      async deleteItem(invoiceId, itemId) {
        return await apiService.delete(`${url}/internal/${invoiceId}/items/${itemId}`);
      },

      async updateBookingData(invoiceId, itemId, payload) {
        return await apiService.patch(`${url}/internal/${invoiceId}/items/${itemId}`, payload);
      },

      async editItem(invoiceId, invoiceItemId, payload) {
        return await apiService.patch(`${url}/internal/${invoiceId}/items/${invoiceItemId}`, payload);
      },
    },

  },
}
