<template>
  <div>
    <page-title>{{ title }} #{{ getBlogPostId }}</page-title>
    <card>
      <card-tab id="postTab" icon="file-alt" title="Article">
        <create-or-update-blog-post
          :blog-post-id="getBlogPostId"
          mode="update"
          @updateTitle="onTitleUpdate"
        />
      </card-tab>


      <card-tab id="imageTab" icon="images" title="Images">
        <manage-blog-post-images
          :blog-post-id="getBlogPostId"
        />
      </card-tab>
        <card-tab id="localizationTab" icon="globe" title="Traduction">
            <update-blog-post-localization :blog-post-id="getBlogPostId"></update-blog-post-localization>
        </card-tab>
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import CreateOrUpdateBlogPost from "@/components/pages/Blog/Posts/Forms/CreateOrUpdateBlogPost";
import Card from "@/components/elements/cards/Card";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import ManageBlogPostImages from "@/components/pages/Blog/Posts/EditBlogPost/Images/ManageBlogPostImages";
import UpdateBlogPostLocalization from "@/components/pages/Blog/Posts/Forms/UpdateBlogPostLocalizationForm.vue";

export default {
  name: "UpdateBlogPostPage",
  components: {
      UpdateBlogPostLocalization,
      ManageBlogPostImages, CardTab, Card, CreateOrUpdateBlogPost, PageTitle},
  computed: {
    getBlogPostId() {
      return Number(this.$route.params.blogPostId);
    }
  },
  data: () => ({
    title: ''
  }),
  methods: {
    onTitleUpdate(title) {
      this.title = title;
    }
  },
}
</script>

<style scoped>

</style>