<template>
  <div
    v-if="hasItemBeenTreated"
    :class="getBgClass"
    class="flex justify-between"
  >
    <div>
      Reçu:
      <span class="font-bold">
                {{ item.receivedSellingUnitQuantity }}
            </span>
    </div>

    <div v-if="item.treatedByManager" class="font-bold">
      [-- MANUEL --]
    </div>
  </div>
  <div v-else-if="!$isReadOnly()" class="flex items-center gap-x-2">
    <tooltip
      v-if="hasError"
      :text="getErrorMessage"
    >
      <fa-icon class="text-red-500" fixed-width icon="exclamation-triangle"/>
    </tooltip>

    <radio-button-component
      v-model="decision"
      value="none"
      @change="removeItemFromReceivedArray"
    />
    |
    <radio-button-component
      v-model="decision"
      value="received"
      @change="setItemInReceivedArray(null)"
    />
    <number-input-element
      :key="'input_' + item.id"
      v-model="receivedQuantity"
      :disabled="income"
      :occupy-validation-space="false"
      class="w-24"
      small
    />
    |
    <radio-button-component
      ref="notReceived"
      v-model="decision"
      label="Pas reçu"
      value="not_received"
      @change="setItemInReceivedArrayWithQuantityZero"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Tooltip from "@/components/elements/tooltips/Tooltip.vue";
import RadioButtonComponent from "@/components/elements/radio/RadioButtonComponent.vue";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement.vue";

export default {
  name: "SupplierOrderItemArrivalColumn",
  components: {NumberInputElement, RadioButtonComponent, Tooltip},
  props: {
    item: {
      type: Object,
      required: true
    },
    income: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    receivedQuantity: 0,
    orderedBundleQuantity: 0,

    decision: "none"
  }),
  watch: {
    // Sync quantity set with the quantity in the store
    receivedQuantity(quantity) {
      if (this.decision === "received") {
        this.setItemInReceivedArray(quantity);
      }
    },
    // If Item just got marked as treated, unset it from the values to send.
    hasBeenTreated(bool, oldBool) {
      if (oldBool === false && bool === true) {
        this.removeItemFromReceivedArray();
      }
    }
  },
  created() {
    if (!this.hasItemBeenTreated) {
      this.receivedQuantity = this.getTotalBundleQuantity;
    }

    this.orderedBundleQuantity = this.item.orderedBundleQuantity;
  },
  methods: {
    ...mapMutations('supplierOrders', [
      'setItemReceived',
      'removeItemReceived'
    ]),
    removeItemFromReceivedArray() {
      this.removeItemReceived(this.item.id);
    },
    setItemInReceivedArray(quantity = null) {
      const realQuantity = quantity === null ? this.receivedQuantity : quantity;
      this.setItemReceived({
        itemId: this.item.id,
        quantity: realQuantity
      })
    },
    setItemInReceivedArrayWithQuantityZero() {
      this.setItemInReceivedArray(0);
    },
  },
  computed: {
    ...mapGetters('supplierOrders', [
      'getBatchErrors'
    ]),
    hasItemBeenTreated() {
      return this.item.isTreated;
    },
    hasError() {
      return this.getError !== undefined;
    },
    getError() {
      return this.getBatchErrors.find(e => e.id === this.item.id);
    },
    getTotalBundleQuantity() {
      return this.item.orderedBundleQuantity * this.item.bundleQuantity;
    },
    getErrorMessage() {
      return this.getError?.error || "";
    },

    /*
        ==== Row coloring ====
     */

    receivedNothing() {
      return this.item.receivedSellingUnitQuantity === 0;
    },
    receivedLessThanOrdered() {
      return this.item.receivedSellingUnitQuantity < this.getTotalBundleQuantity;
    },
    getBgClass() {
      if (this.receivedNothing) {
        return 'bg-red-300';
      }
      if (this.receivedLessThanOrdered) {
        return 'bg-yellow-300';
      }
      return '';
    }
  },
}
</script>

<style scoped>

</style>