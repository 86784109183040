const toRgba = (hexCode, opacity = 50) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const flattenColorPalette = (obj, sep = '-') => Object.assign(
  {},
  ...function _flatten(o, p = '') {
    return [].concat(...Object.keys(o)
      .map(k =>
        typeof o[k] === 'object' ?
          _flatten(o[k], k + sep) :
          ({[p + k]: o[k]})
      )
    );
  }(obj)
);


module.exports = {
  //mode: process.env.NODE_ENV ? 'jit' : undefined,
  purge: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    fontFamily: {
      'sans': ['Open Sans', 'sans-serif']
    },
    fill: theme => ({
      current: 'currentColor',
      'luxcaddy': theme('colors.luxcaddy.500'),
      'gray-800': theme('colors.gray.800'),
    }),
    extend: {
      screens: {
        'print': {'raw': 'print'},
        '3xl': '1900px'
      },
      zIndex: {
        '9999': '9999'
      },
      colors: {
        luxcaddy: {
          DEFAULT: '#9EB544',
          '50': '#F3F6E8',
          '100': '#EAEFD6',
          '200': '#D8E2B0',
          '300': '#C5D48B',
          '400': '#B2C666',
          '500': '#9EB544',
          '600': '#7E9036',
          '700': '#5D6B28',
          '800': '#3D461A',
          '900': '#1D210C'
        },
      },
      width: {
        'screen-10': '10vw',
        'screen-20': '20vw',
        'screen-30': '30vw',
        'screen-40': '40vw',
        'screen-50': '50vw',
        'screen-60': '60vw',
        'screen-70': '70vw',
        'screen-80': '80vw',
        'screen-90': '90vw',
        132: '36rem',
        256: '64rem'
      },
      height: {
        'screen-10': '10vh',
        'screen-20': '20vh',
        'screen-30': '30vh',
        'screen-40': '40vh',
        'screen-50': '50vh',
        'screen-60': '60vh',
        'screen-65': '65vh',
        'screen-70': '70vh',
        'screen-80': '80vh',
        'screen-90': '90vh',
        102: '27rem',
        132: '36rem',
        256: '64rem'
      },
      maxHeight: {
        'screen-10': '10vh',
        'screen-20': '20vh',
        'screen-30': '30vh',
        'screen-40': '40vh',
        'screen-50': '50vh',
        'screen-60': '60vh',
        'screen-70': '70vh',
        'screen-80': '80vh',
        'screen-90': '90vh',
        108: '28rem',
        120: '32rem',
        132: '36rem',
      },
      minWidth: {
        64: '16rem'
      },
    },
  },
  variants: {
    extend: {
      visibility: ['group-hover']
    }
  },
  plugins: [
    function ({addUtilities, theme}) {
      const utilities = {
        '.bg-stripes': {
          backgroundImage:
            'linear-gradient(45deg, var(--stripes-color) 12.50%, transparent 12.50%, transparent 50%, var(--stripes-color) 50%, var(--stripes-color) 62.50%, transparent 62.50%, transparent 100%)',
          backgroundSize: '5.66px 5.66px',
        },
      }

      const addColor = (name, color) =>
        (utilities[`.bg-stripes-${name}`] = {'--stripes-color': color})

      const colors = flattenColorPalette(theme('backgroundColor'))
      for (let name in colors) {
        try {
          const [r, g, b, a] = toRgba(colors[name])
          if (a !== undefined) {
            addColor(name, colors[name])
          } else {
            addColor(name, `rgba(${r}, ${g}, ${b}, 0.4)`)
          }
        } catch (_) {
          addColor(name, colors[name])
        }
      }

      addUtilities(utilities)
    },
  ]
}
