import auth from "@/router/middlewares/authMiddleware";
import ShowUnfulfilledItemsTable from "@/components/pages/UnfulfilledItems/ShowUnfulfilledItemsTable";

export default [
  {
    path: '/unfulfilled-items',
    name: 'unfulfilled-items.list',
    component: ShowUnfulfilledItemsTable,
    meta: {
      middleware: [auth],
      title: 'Produits manquants',
      permissions: ['ACCESS_ORDER_VIEW']
    }
  },
]