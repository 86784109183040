<template>
  <div
    :class="getNavbarColorClass"
    class="select-none z-20 text-xl flex justify-between px-4 md:pl-4 md:pr-8"
  >
    <div class="flex gap-x-4 flex-wrap content-center text-white">
      <navbar-toggle-sidebar-button/>

      <router-link :to="{name: 'home'}" class="inline-block hover:text-gray-200">
        <div class="hidden md:inline-flex flex items-center gap-x-2">
          Luxcaddy Backoffice
          <div
            v-if="getHostName"
            :class="[isProd ? 'text-red-200' : 'text-luxcaddy-400']"
            class="italic text-sm"
          >
            ({{ getHostName }})
          </div>
        </div>
      </router-link>
    </div>

    <div>

    </div>

    <div class="flex flex-wrap content-center gap-x-6">

      <div class="flex flex-wrap content-center gap-x-2">
        <navbar-touch-interface-button/>
        <navbar-notification-button/>
      </div>

      <navbar-logout-button/>

      <navbar-button-template
        type="dark"
        @click="$emit('refresh')"
      >
        <fa-icon
          fixed-width
          icon="sync"
        />
      </navbar-button-template>
    </div>
  </div>
</template>

<script>
import NavbarLogoutButton from "./navbar/NavbarLogoutButton";
import NavbarToggleSidebarButton from "./navbar/NavbarToggleSidebarButton";
import NavbarNotificationButton from "@/components/layouts/dashboard/partials/notifications/NavbarNotificationButton";
import NavbarTouchInterfaceButton from "@/components/layouts/dashboard/partials/navbar/NavbarTouchInterfaceButton.vue";
import NavbarButtonTemplate from "@/components/layouts/dashboard/partials/navbar/NavbarButtonTemplate.vue";

export default {
  name: "DashboardNavbar",
  components: {
    NavbarButtonTemplate,
    NavbarTouchInterfaceButton,
    NavbarNotificationButton,
    NavbarToggleSidebarButton,
    NavbarLogoutButton
  },
  computed: {
    getHostName() {
      const appUrl = process.env.VUE_APP_API_URL;

      if (appUrl.includes("localhost")) {
        return "Local";
      } else if (appUrl.includes("api.turing")) {
        return "Staging";
      } else if (appUrl.includes("api.luxcaddy")) {
        return "Production";
      }

      return null;
    },
    getNavbarColorClass() {
      const appUrl = process.env.VUE_APP_API_URL;
      if (appUrl.includes("localhost") || appUrl.includes("turing")) {
        return "bg-red-600";
      }

      return 'bg-luxcaddy-600';
    },
    isProd() {
      return this.getHostName === "Production";
    },
  },
}
</script>

