<template>
  <div>
    <div>
      <custom-table
        v-if="references.length > 0"
        :headings="getHeadings"
      >
        <product-sales-stats-details-item
          v-for="reference in references"
          :key="reference.id"
          :reference="reference"
        />
      </custom-table>
    </div>
  </div>
</template>

<script>
import CustomTable from "@/components/elements/tables/CustomTable";
import ProductSalesStatsDetailsItem from "@/components/pages/Stats/ProductSalesStatsDetailsItem.vue";

export default {
  name: "ProductSalesStatsDetails",
  components: {ProductSalesStatsDetailsItem, CustomTable},
  props: {
    references: {
      type: Array,
      required: true
    },

  },
  computed: {
    getHeadings() {
      return [
        {label: '#'},
        {label: 'Type'},
        {label: 'Nom'},
        {label: 'Nombre'},
        {label: 'Total Prix'},
      ];
    }
  }
}
</script>