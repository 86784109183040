<template>
  <div>
    <div class="font-bold mb-4">Quels véhicules doivent être pris en compte dans la planification ?</div>

    <div class="grid grid-cols-1 gap-1">
      <div
        v-for="vehicle in getAvailableVehicles"
        :key="vehicle.id"
      >
        <div
          :class="[isVehicleSelected(vehicle.id) ? 'border-luxcaddy-300 hover:bg-luxcaddy-100' : 'border-gray-500 hover:bg-gray-100 opacity-25 hover:opacity-70']"
          class="flex gap-x-2 items-center py-1 px-2 border rounded-lg cursor-pointer"
          @click="toggleVehicleSelection(vehicle.id)"
        >
          <div class="w-full flex items-center gap-x-3">

            <div>
              <fa-icon
                v-if="isVehicleSelected(vehicle.id)"
                class="text-luxcaddy"
                fixed-width
                icon="check"
              />

              <fa-icon
                v-else
                class="text-gray-500"
                fixed-width
                icon="times"
              />
            </div>

            <luxembourgish-number-plate
              :plate="vehicle.vehiclePlate"
              class="flex-none w-28"
            />

            <div class="w-full flex justify-between items-center">
              <div>
                <div class="text-gray-600">{{ vehicle.makeAndModel }}</div>
                <div class="text-red-500 -mt-1">
                  <span class="text-xs">Distance maximale en km:</span>
                  <span class="text-xs font-bold ml-1">{{
                      vehicle.distanceLimit.toLocaleString()
                    }}</span>
                </div>

              </div>

              <box-quantity
                :quantity="vehicle.boxCapacity"
                class="w-16"
                small
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import LuxembourgishNumberPlate from "@/components/pages/Devices/Includes/LuxembourgishNumberPlate.vue";
import BoxQuantity from "@/components/global/BoxQuantity/BoxQuantity.vue";

export default {
  name: "AutomaticRoutePlanningVehicleSelection",
  components: {BoxQuantity, LuxembourgishNumberPlate},
  computed: {
    ...mapGetters('routeManager/automaticPlanning', [
      'getAvailableVehicles',
      'isVehicleSelected'
    ])
  },
  methods: {
    ...mapMutations('routeManager/automaticPlanning', [
      'toggleVehicleSelection'
    ])
  },
}
</script>

<style scoped>

</style>