<template>
  <div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">

      <div class="flex flex-col gap-3 flex-wrap">
        <order-info-card
          class="w-full flex-grow"
          icon="calendar"
        >
          <div class="h-full | flex flex-col justify-center">
            {{ $date(getOrder.createdAt).format('DD.MM.YY HH:mm') }}
          </div>
        </order-info-card>

        <order-info-card
          class="w-full flex-grow"
          icon="user"
        >
          <div class="h-full | flex flex-col justify-center">
            <div class="flex flex-wrap items-center gap-x-3">
              <member-quick-action-modal :id="getOrder.memberId"/>
              <div>{{ getInvoiceAddress.fullName }}</div>
            </div>
          </div>
        </order-info-card>

        <order-info-card
          class="w-full flex-grow"
          icon="file-invoice"
        >
          <div class="h-full | flex flex-col justify-center">
            <div class="flex flex-nowrap">
              <address-display :address="getInvoiceAddress" :show-map-button="false"/>
            </div>
          </div>
        </order-info-card>
      </div>

      <order-info-card
        icon="truck"
      >

        <template
          v-if="getOrder.delivery"
        >
          <div class="flex flex-col gap-y-3">
            <div class="flex items-center flex-wrap gap-x-3 gap-y-4 mb-2">
              <delivery-status-indicator
                v-if="getOrder"
                :status="getOrder.delivery.status"
                small
              />

              <box-quantity :quantity="getOrder.delivery.boxQuantity"/>
              <fulfilment-number-display
                :fulfilment-number="getOrder.delivery && getOrder.delivery.fulfilmentNumber"/>
              <time-slot-as-string
                v-if="getDeliveryAddress"
                :from="$date(getDeliveryTimeSlot.startDate)"
                :to="$date(getDeliveryTimeSlot.endDate)"
                :zone="getDeliveryTimeSlot.zoneId"
              />
            </div>

            <address-display :address="getDeliveryAddress" :show-map-button="true"/>


            <div v-if="getDeliveryInstructions" class="flex items-center gap-4 break-words">
              <fa-icon class="text-gray-400" icon="quote-right"></fa-icon>
              <span class="text-gray-400 text-sm">
                            {{ getDeliveryInstructions }}
                        </span>
            </div>

            <div class="flex items-center gap-3 mt-1">
              <button-element
                :disabled="getOrder.delivery === null"
                class="flex-start"
                size="xsmall"
                type="light"
                @click="$router.push({name: 'deliveries.edit', params: {deliveryId: getOrder.delivery.id}})"
              >
                <fa-icon class="mr-2" fixed-width icon="truck"/>
                Voir Livraison
              </button-element>

              <!--              <edit-order-delivery-details/>-->
            </div>
          </div>
        </template>

        <div
          v-else
        >
          <div class="italic">
            Cette commande n'est pas associée à une livraison.
          </div>
        </div>

      </order-info-card>

      <edit-order-settings class="lg:col-span-2"></edit-order-settings>
    </div>
  </div>
</template>

<script>
import AddressDisplay from "../../../../elements/display/address/AddressDisplay";
import EditOrderSettings from "../Settings/EditOrderSettings";
import {mapGetters} from "vuex";
import DeliveryStatusIndicator from "@/components/elements/display/deliveries/DeliveryStatusIndicator";
import OrderInfoCard from "@/components/elements/display/order/OrderInfoCard";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import TimeSlotAsString from "../../../../global/TimeSlots/TimeSlotAsString";
import BoxQuantity from "../../../../global/BoxQuantity/BoxQuantity";

export default {
  name: "OrderInformation",
  components: {
    BoxQuantity,
    TimeSlotAsString,
    MemberQuickActionModal,
    ButtonElement,
    FulfilmentNumberDisplay,
    OrderInfoCard,
    DeliveryStatusIndicator,
    EditOrderSettings,
    AddressDisplay,
  },
  computed: {
    ...mapGetters('order', [
      'getOrder',
      'getInvoiceAddress',
      'getDeliveryAddress',
      'getCustomerGeneralDetails',
      'getDeliveryTimeSlot',
      'getDeliveryInstructions',
    ]),
  }
}
</script>

