<template>
  <div
    :class="getClasses"
    class="relative  font-semibold cursor-pointer px-2 rounded-md transition duration-150 ease-in-out"
    @click="onClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NavbarButtonTemplate",
  props: {
    emitClick: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'primary',
      validator: val => ['primary', 'dark'].includes(val)
    }
  },
  methods: {
    onClick() {
      if (this.emitClick) {
        this.$emit('click');
      }
    }
  },
  computed: {
    getClasses() {
      if (this.type === "dark") {
        return "bg-black hover:bg-gray-800 text-white hover:text-gray-200";
      }

      return "text-luxcaddy-200 bg-luxcaddy-700 hover:bg-luxcaddy-400 hover:text-luxcaddy-800";
    }
  },
}
</script>

<style scoped>

</style>