<template>
  <div>
    <div
      class="flex items-center rounded select-none gap-x-2"
    >
      <div class="px-4 w-8">
        <template v-if="isRoot">
          <div class="w-full">
            <fa-icon class="text-gray-600" fixed-width icon="sitemap"></fa-icon>
          </div>
        </template>
        <template v-else>
          <div v-if="hasChildren" class="w-full cursor-pointer" @click="isExpanded = !isExpanded">
            <fa-icon :class="{'-rotate-90 text-gray-400': !isExpanded}"
                     class="transition transform text-gray-600 hover:text-gray-400"
                     fixed-width icon="chevron-down"></fa-icon>
          </div>

          <div v-else class="w-full">
            <fa-icon class="text-gray-300" fixed-width icon="chevron-right"></fa-icon>
          </div>
        </template>
      </div>
      <div
        :class="{'hover:bg-luxcaddy-100 bg-luxcaddy-200': isSelected, 'px-2 py-1': !compact, 'px-1 py-1 text-sm': compact, 'text-luxcaddy-600 font-bold': isMarkedAsIncluded}"
        class="hover:bg-gray-200 cursor-pointer rounded-lg flex gap-x-2 items-center"
        @click="onCurrentCategoryClick">
        <div
          v-if="isMarkedAsIncluded"
          class="bg-luxcaddy-500 text-white rounded-md px-1 text-sm"
        >
          <fa-icon fixed-width icon="check"/>
        </div>

        {{ getName }}
      </div>
    </div>
    <div v-if="isExpanded" class="ml-8">
      <category-selection-tree-item
        v-for="subCat in category.subCategories"
        :key="subCat.id"
        :categories-marked-as-included="categoriesMarkedAsIncluded"
        :category="subCat"
        :compact="compact"
        :parent-level="getLevel"
        :start-expanded="startExpanded"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CategorySelectionTreeItem",
  components: {},
  props: {
    isRoot: {
      type: Boolean,
      default: false
    },
    category: {
      type: Object,
      required: false
    },
    parentLevel: {
      type: Number,
      default: 0
    },
    startExpanded: {
      type: Boolean
    },
    compact: {
      type: Boolean,
      default: false
    },
    categoriesMarkedAsIncluded: {
      type: Array,
      default: () => []
    }
  },
  created() {
    if (this.startExpanded) {
      this.isExpanded = true;
    }
  },
  data: () => ({
    isExpanded: false
  }),
  methods: {
    onCurrentCategoryClick() {
      if (this.isRoot) {
        return this.$store.commit('categories/setSelectedCategoryInSelectionTree', "root");
      }

      return this.$store.commit('categories/setSelectedCategoryInSelectionTree', this.category);
    }
  },
  computed: {
    /**
     * Whether a check mark should be shown beneath the name
     * @returns {boolean}
     */
    isMarkedAsIncluded() {
      if (this.isRoot || !Array.isArray(this.categoriesMarkedAsIncluded))
        return false;

      return this.categoriesMarkedAsIncluded.includes(this.category.id);
    },

    getName() {
      if (this.isRoot)
        return "Root";

      return this.category.name;
    },

    hasChildren() {
      if (this.isRoot)
        return false;

      return this.category?.subCategories?.length > 0 || false;
    },
    hasParent() {
      if (this.isRoot)
        return false;

      return this.parentLevel > 0;
    },
    getLevel() {
      if (this.isRoot)
        return 0;

      return this.parentLevel + 1;
    },
    isSelected() {
      if (this.isRoot) {
        return this.$store.getters['categories/getSelectedCategoryInSelectionTree'] === "root";
      }

      return this.$store.getters['categories/getSelectedCategoryInSelectionTree']?.id === this.category.id;
    }
  }
}
</script>

