export default {
  state: {
    currentRequests: [],
  },
  getters: {
    getCurrentRequestCount: state => state.currentRequests.length,
    getCurrentRequest: state => state.currentRequests,
    isRunningRequest: state => state.currentRequests.length > 0,
    showLoadingIndicator: state => state.currentRequests.filter(r => r.showLoadingIndicator).length > 0,
  },
  mutations: {
    addRequest(state, payload) {
      state.currentRequests.push(payload);
    },
    removeRequestByUrl(state, url) {
      state.currentRequests = state.currentRequests.filter(req => req.url !== url);
    },
    clearRequests(state) {
      state.currentRequests = [];
    }
  },
  actions: {
    cancelAllRequests({state, commit}) {
      state.currentRequests.forEach((cts) => {
        if (typeof cts.cancel !== 'undefined') {
          cts.cancel();
        }
      });
      commit('clearRequests');
    }
  },
}