<template>
  <create-arrival-temperature-form/>
</template>

<script>
import CreateArrivalTemperatureForm from "@/components/pages/ArrivalTemperatures/Form/CreateArrivalTemperatureForm";

export default {
  name: "CreateArrivalTemperature",
  components: {CreateArrivalTemperatureForm}
}
</script>

