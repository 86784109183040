import auth from "../middlewares/authMiddleware";
import ShowBanners from "@/components/pages/Banner/ShowBanners";
import EditBanner from "@/components/pages/Banner/Management/EditBanner";
import CreateBanner from "@/components/pages/Banner/Management/CreateBanner";

export default [
  {
    path: '/banners',
    name: 'banners.list',
    component: ShowBanners,
    meta: {
      middleware: [auth],
      title: 'Bannières',
      permissions: ['BANNERS_READ'],
    }
  },
  {
    path: '/banners/:banner/edit',
    name: 'banners.edit',
    component: EditBanner,
    meta: {
      middleware: [auth],
      title: 'Modifier une bannière',
      readOnly: {supported: true, title: 'Voir une bannière', permissions: ['BANNERS_READ']},
      breadcrumbs: ['banners.list'],
      permissions: ['BANNERS_WRITE'],

    }
  },
  {
    path: '/banners/create',
    name: 'banners.create',
    component: CreateBanner,
    meta: {
      middleware: [auth],
      title: 'Créer une bannière',
      breadcrumbs: ['banners.list'],
      permissions: ['BANNERS_WRITE'],

    }
  }
];