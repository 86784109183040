<template>
  <update-localization-form
    v-if="localizations"
    :localizable-id="campaignId"
    :localizations="localizations"
    localizable-type="campaign"
    @submit="saveLocalizations"
  />
</template>

<script>
import UpdateLocalizationForm from "../../../forms/UpdateLocalizationForm";
import CampaignRepository from "../../../../repositories/CampaignRepository";

export default {
  name: "UpdateCampaignLocalizationForm",
  components: {UpdateLocalizationForm},
  props: {
    campaignId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    localizations: null
  }),
  mounted() {
    CampaignRepository.localization.getForCampaign(this.campaignId).then((res) => {
      this.localizations = res.data.data;
    })
  },
  methods: {
    saveLocalizations(payload) {
      CampaignRepository
        .localization
        .saveForCampaign(this.campaignId, {
          localizableData: payload
        })
        .then(() => {
          this.$sendSuccessToast('Traduction sauvegardé!')
        })
    },
  },
}
</script>

