<template>
  <div>
    <list-table
      :columns="columns"
      :create-route="{
                name: 'tag.create'
            }"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      identifier="tagsTable"
    />
  </div>
</template>

<script>
import TagsRepository from "../../../repositories/TagsRepository";
import ListTable from "../../listTable/ListTable";
import {FILTER_SELECT_MULTI} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowTags",
  components: {ListTable},
  data() {
    return {
      title: 'Tags',
      repositoryFunc: TagsRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          showInColumnChooser: false,
          allowReordering: false,
          buttons: [
            {
              type: 'show',
              hint: 'Voir tag',
              route: (row) => ({
                name: 'tag.edit',
                params: {
                  tag: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier tag',
              route: (row) => ({
                name: 'tag.edit',
                params: {
                  tag: row.id,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer tag',
              confirmation: {
                title: 'Supprimer tag?',
                text: 'Étes vous sure que vous voulez supprimer cette tag?'
              },
              successText: 'La tag a été supprimée',
              deleteAction: (row) => TagsRepository.deleteSingle(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: 'Image',
          dataType: "image",
          calculateCellValue: (r) => {
            return r.image?.url || null;
          },
          width: 80
        },
        {
          caption: 'Nom',
          dataField: 'name',
          sort: {
            dataField: 'name',
            default: 'asc'
          }
        },
        {
          caption: 'Type',
          calculateCellValue: r => r.type.toUpperCase(),
          sort: {
            dataField: 'type'
          }
        },

      ],
      filters: [
        {
          caption: "Nom",
          filterKey: "name",
          forceIsVisible: true,
        },
        {
          caption: "Type",
          filterKey: "type",
          type: FILTER_SELECT_MULTI,
          filterOptions: this.$luxcaddyConfig('tagTypes'),
          forceIsVisible: true,
        }
      ]
    }
  },
}
</script>
