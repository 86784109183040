<template>
  <div>
    <button-element
      v-if="!isPlanningRouteJobInProgress"
      :disabled="!hasSelectedAnyDeliveries"
      disabled-tooltip="Vous devez sélectionner au moins une livraison."
      size="xsmall"
      type="primary"
      @click="openAutomaticRoutePlanningModel"
    >
      Automatisation
    </button-element>

    <route-manager-automatic-route-planning-modal
      ref="routePlanningModal"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import RouteManagerAutomaticRoutePlanningModal
  from "@/components/pages/RouteManager/Includes/ActionBox/Includes/AutomaticRoutePlanning/Includes/RouteManagerAutomaticRoutePlanningModal.vue";

export default {
  name: "RouteManagerAutomaticRoutePlanningButtons",
  components: {RouteManagerAutomaticRoutePlanningModal, ButtonElement},
  methods: {
    ...mapActions('routeManager/automaticPlanning', [
      'cancelRoutePlanningRequest',
    ]),
    cancelPlanningRequest() {
      this.cancelRoutePlanningRequest().then(() => {
        this.$sendSuccessToast('La planification automatique d\'itinéraires a été annulée!');
      });
    },
    openAutomaticRoutePlanningModel() {
      this.$refs.routePlanningModal.$refs.modal.openModal();
    }
  },
  computed: {
    ...mapGetters('routeManager/automaticPlanning', [
      'isPlanningRouteJobInProgress'
    ]),
    ...mapGetters('routeManager', [
      'hasSelectedAnyDeliveries'
    ])
  },
}
</script>

<style scoped>

</style>