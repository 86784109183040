<template>
  <div>
    <page-title>
      Modifier rôle
    </page-title>

    <card>
      <create-or-update-role-form
        ref="editRoleForm"
        mode="update"
      />
    </card>
  </div>
</template>

<script>
import Card from "../../../elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import CreateOrUpdateRoleForm from "@/components/pages/Roles/Forms/CreateOrUpdateRoleForm.vue";

export default {
  name: "EditRole",
  components: {CreateOrUpdateRoleForm, PageTitle, Card}
}
</script>
