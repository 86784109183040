import auth from "../middlewares/authMiddleware";
import ShowProductCategories from "../../components/pages/Categories/ShowProductCategories";
import ShowProductCategoriesVirtual from "@/components/pages/Categories/ShowProductCategoriesVirtual.vue";

export default [
  {
    path: '/product-categories',
    name: 'product-categories.list',
    component: ShowProductCategories,
    meta: {
      middleware: [auth],
      title: 'Liste des catégories produits',
      readOnly: {supported: true},
      permissions: ['CATEGORY_READ']
    }
  },
  {
    path: '/product-categories-virtual',
    name: 'product-categories-virtual.list',
    component: ShowProductCategoriesVirtual,
    meta: {
      middleware: [auth],
      title: 'Liste des catégories produits virtuel',
      readOnly: {supported: true},
      permissions: ['CATEGORY_READ']
    }
  },
];