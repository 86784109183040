import OrderRepository from "../../../repositories/OrderRepository";
import DeliveryRepository from "../../../repositories/DeliveryRepository";

const getDefaultState = () => {
  return {
    fulfilmentOrderList: [],
    orderData: null,
    orderMeta: {
      itemIdForWeightInput: null
    },
  }
}

const getIndexOfOrderInList = function (state, orderId) {
  const index = state.fulfilmentOrderList.findIndex(o => o.id === orderId);

  return index < 0
    ? null
    : index;
};

export default {
  state: getDefaultState(),
  getters: {
    getFulfilmentOrderList: state => state.fulfilmentOrderList,
    getNextFulfilmentOrderId: state => {
      const index = getIndexOfOrderInList(state, (state.orderData?.orderId || null));
      return state.fulfilmentOrderList[index + 1]?.id || null;
    },
    getPreviousFulfilmentOrderId: state => {
      const index = getIndexOfOrderInList(state, (state.orderData?.orderId || null));
      return state.fulfilmentOrderList[index - 1]?.id || null;
    },

    getOrder: state => state.orderData,
    getOrderFulfilmentStatus: state => state.orderData?.fulfilmentStatus || null,
    getOrderFulfilmentNumber: state => state.orderData?.fulfilmentNumber || null,
    getOrderInstructions: state => state.orderData?.instructions || [],
    getOrderMeta: state => state.orderMeta,
    getOrderId: state => state.orderData?.orderId || null,
    getDeliveryId: state => state.orderData?.deliveryId || null,
    getOrderDeliveryTimeStartDate: state => state.orderData?.deliveryTimeSlot?.startDate || null,
    getOrderDeliveryTimeEndDate: state => state.orderData?.deliveryTimeSlot?.endDate || null,
    getOrderDeliveryZone: state => state.orderData?.deliveryTimeSlot?.zoneId || null,
    getBoxQuantity: state => state.orderData?.boxQuantity || 0,
    getFulfilmentNumber: state => state.orderData?.fulfilmentNumber || null,
    getPackagingItems: state => state.orderData?.packaging || [],

    getItemById: (state) => (id) => {
      return state.orderData.items.find(i => i.id === id);
    }
  },
  mutations: {
    setOrder(state, order) {
      state.orderData = order;
      // Clear order meta
      state.orderMeta = getDefaultState().orderMeta;
    },
    setFulfilmentOrderList(state, orders) {
      state.fulfilmentOrderList = orders;
    },
    setItemIdForWeightInput(state, itemId) {
      state.orderMeta.itemIdForWeightInput = itemId;
    },
    setBoxQuantity(state, quantity) {
      state.orderData.boxQuantity = quantity;
    },
    /**
     * Marks a specific quantity (or weight) of an item as packed.
     * @param state
     * @param itemId
     * @param quantity
     * @param weight
     * @param addWeight
     */
    setItemPacked(state, {itemId, quantity, weight, addWeight = true}) {
      let item = state.orderData.items.find(i => i.id === itemId);
      if (item) {
        item.packedQuantity = (item.packedQuantity + quantity);

        if (addWeight) {
          item.packedWeight = (item.packedWeight + weight);
        } else {
          item.packedWeight = weight;
        }

        const remainingQuantity = (item.toBePackedQuantity - item.packedQuantity);
        if (remainingQuantity === 0) {
          this.commit('fulfilment/moveItemToEndOfList', itemId);
        }
      } else {
        let packaging = state.orderData.packaging.find(i => i.id === itemId);
        if (packaging) {
          packaging.packedQuantity = (packaging.packedQuantity + quantity);
        }
      }
    },

    increasePackagingPacked(state, packageId) {
      let packageFound = state.orderData.packaging.find(p => p.id === packageId);

      if (packageFound) {
        packageFound.packedQuantity++;
      }
    },

    /**
     * Marks an item completely as unpacked.
     * @param state
     * @param itemId
     */
    resetItemPackedQuantity(state, itemId) {
      let item = state.orderData.items.find(i => i.id === itemId);
      if (item) {
        item.packedQuantity = 0;

        if (item.packedWeight !== undefined) {
          item.packedWeight = 0;
        }

      } else {
        let packageFound = state.orderData.packaging.find(p => p.id === itemId);

        if (packageFound) {
          packageFound.packedQuantity = 0;
        }
      }
    },
    moveItemToEndOfList(state, itemId) {
      let allItems = state.orderData.items;
      const item = allItems.find(i => i.id === itemId);

      state.orderData.items = allItems.filter(i => i.id !== itemId);
      state.orderData.items.push(item);
    }
  }
  ,
  actions: {
    fetchOrder({commit}, {orderId, filters}) {
      return OrderRepository.fulfilment.getOrderFulfilmentDetails(orderId, filters).then((res) => {
        commit('setOrder', res.data.data);
      });
    }
    ,

    /**
     * Marks a certain quantity of an item as packed.
     *
     * @param commit
     * @param getters
     * @param itemId
     * @param packedQuantity
     * @param packedWeight
     * @returns {Promise<*>}
     */
    fulfillItem({commit, getters}, {itemId, packedQuantity, packedWeight, type = 'item'}) {
      return OrderRepository.fulfilment.addItemPackedQuantity(
        getters.getOrderId,
        itemId,
        packedQuantity,
        packedWeight
      ).then(() => {
        if (type === "item") {
          commit('setItemPacked', {
            itemId: itemId,
            quantity: packedQuantity,
            weight: packedWeight
          });
        } else if (type === "package") {
          commit('increasePackagingPacked', itemId);
        }
      });
    }
    ,

    /**
     * Marks an item as not packed.
     * @param commit
     * @param getters
     * @param itemId
     * @returns {Promise<*>}
     */
    resetItemPackedQuantity({commit, getters}, itemId) {
      return OrderRepository
        .fulfilment
        .resetItemPackedQuantity(getters.getOrderId, itemId).then(() => {
          commit('resetItemPackedQuantity', itemId);
        });
    }
    ,

    /**
     * Marks a certain quantity of an item as packed by the items barcode.
     * @param commit
     * @param getters
     * @param barCode
     * @param quantity
     * @returns {Promise<*>}
     */
    fulfillItemByBarcode({commit, getters}, {barCode, quantity}) {
      return OrderRepository.fulfilment.addItemPackedQuantityByBarcode(
        getters.getOrderId,
        barCode,
        quantity
      ).then((res) => {
        commit('setItemPacked', {
          itemId: res.data?.data?.itemId,
          quantity: quantity ?? 1,
          weight: res.data?.data?.packedWeight ?? null,
          addWeight: false
        })
      });
    }
    ,

    /**
     * Sets the amount of boxes needed for packing the items.
     * @param commit
     * @param getters
     * @param quantity
     * @returns {Promise<*>}
     */
    setBoxQuantity({commit, getters}, quantity) {
      return DeliveryRepository.setBoxQuantity(
        getters.getDeliveryId,
        quantity
      ).then(() => {
        commit('setBoxQuantity', quantity);
      })
    }
    ,

    attributeFulfilmentNumber({dispatch, getters, rootGetters}) {


      return DeliveryRepository.attributeFulfilmentNumber(
        getters.getDeliveryId
      ).then(() => {
        let filters = [];

        if (rootGetters['fulfilmentFilters/getSelectedDepartmentId']) {
          filters.push({
            filterKey: "departmentId",
            filterValue: rootGetters['fulfilmentFilters/getSelectedDepartmentId']
          });
        }
        dispatch('fetchOrder', {orderId: getters.getOrderId, filters: filters});
      })
    }
  }
  ,
}