<template>
  <status-indicator
    :type="getStatus"
    with-icon
  >
    {{ getText }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "ProductPublicationStatus",
  components: {StatusIndicator},
  props: {
    isPublished: {
      type: Boolean,
      default: false
    },
    isVisibilityForced: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    getStatus() {
      if (this.isPublished) {
        return "primary";
      }
      if (!this.isPublished && this.isVisibilityForced) {
        return "warning";
      }

      return "danger";
    },
    getText() {
      if (this.isPublished) {
        return "Publié"
      }

      if (!this.isPublished && this.isVisibilityForced) {
        return "Pas publié avec visibilité forcée"
      }

      return "Pas publié";
    }
  },
}
</script>

<style scoped>

</style>