<template>
  <div>
    <page-title>
      Créer commande fournisseur
    </page-title>
    <card>
      <form-wrapper
        ref="form"
        :show-changed="true"
        submit-button-label="Créer commande"
        @submit="createSupplierOrder"
      >
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-4 md:gap-x-8">
          <member-select-input
            v-model="memberSupplierId"
            label="Fournisseur"
            only-active-product-suppliers
            validation-rules="required"
          />

          <div>
            <date-picker-element
              v-model="expectedDeliveryDate"
              :only-date="false"
              label="Date de livraison"
              only-future-including-today
            />
            <div class="italic">Si aucune date n'est sélectionnée, une livraison sera effectuée le plus
              rapidement
              possible.
            </div>
          </div>
        </div>
      </form-wrapper>
    </card>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";

export default {
  name: "CreateSupplierOrder",
  components: {DatePickerElement, MemberSelectInput, FormWrapper, PageTitle, Card},
  data: () => ({
    memberSupplierId: null,
    expectedDeliveryDate: null,
  }),
  methods: {
    createSupplierOrder(callback) {
      return SupplierOrderRepository
        .create({
          supplierMemberId: this.memberSupplierId,
          expectedDeliveryDate: this.expectedDeliveryDate
        })
        .then(res => {
          this.$sendSuccessToast("Commande crée!");
          this.$router.push({
            name: 'supplier-orders.edit',
            params: {
              order: res.data.data.id
            }
          });
        }).finally(() => callback());
    }
  },
}
</script>

