<template>
  <touch-interface-card-with-loading-indicator
    color="white"
  >
    <div class="text-xs pb-2 flex gap-x-2 h-6 justify-between items-center">
      {{ delivery.destination.fullName }}
      <loading-svg-inline v-if="isSaving" class="self-end"/>
    </div>

    <div class="flex space-x-2">
      <div class="flex flex-col gap-y-3 flex-1">
        <h3 class="text-5xl">
          {{ count }}
        </h3>
        <div
          class="flex-grow auto px-2 py-1.5 bg-black font-bold text-2xl md:text-5xl w-16 md:w-28 | text-white | flex flex-col items-center justify-center">
          {{ delivery.fulfilmentNumber || '?' }}
        </div>
      </div>

      <input
        v-model.number="boxQuantity"
        class="border w-16 md:w-24 border-black text-right pr-2 text-5xl"
        max="99"
        min="0"
        type="number"
        @blur="updateBoxQuantity"
        @focus="$event.target.select()"
      >

      <div class="flex flex-col gap-y-2 w-20">
        <touch-optimized-button
          class="flex-1"
          size="text-4xl"
          type="secondary"
          @click="modifyBoxQuantity(+1)"
        >
          +
        </touch-optimized-button>
        <touch-optimized-button
          class="flex-1"
          size="text-4xl"
          type="secondary"
          @click="modifyBoxQuantity(-1)"
        >
          -
        </touch-optimized-button>
      </div>

      <div class="w-20 flex-none bg-black | flex flex-col gap-y-2 justify-center items-center">
        <fridge-svg v-if="hasCooledItems" class="text-white h-12 w-14"/>
        <snow-svg v-if="hasFrozenItems" class="text-white h-10 w-10"/>
      </div>
    </div>
  </touch-interface-card-with-loading-indicator>
</template>

<script>
import TouchInterfaceCardWithLoadingIndicator
  from "@/components/pages/TouchInterface/Shared/TouchInterfaceCardWithLoadingIndicator";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import SnowSvg from "@/components/assets/Pictograms/SnowSvg";
import FridgeSvg from "@/components/assets/Pictograms/FridgeSvg";
import {debounce} from "@/helpers/debounceHelper";
import DeliveryRepository from "@/repositories/DeliveryRepository";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline";

export default {
  name: "DriverRouteCheckDetailDeliveryBox",
  components: {LoadingSvgInline, FridgeSvg, SnowSvg, TouchOptimizedButton, TouchInterfaceCardWithLoadingIndicator},
  props: {
    delivery: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    isSaving: false,
    boxQuantity: 0,
  }),
  created() {
    this.boxQuantity = this.delivery.boxQuantity;
  },
  methods: {
    modifyBoxQuantity(amount) {
      if ((this.boxQuantity + amount) >= 0 && (this.boxQuantity + amount) <= 99) {
        this.boxQuantity = Number((this.boxQuantity + amount));
        this.updateBoxQuantity();
      }
    },

    updateBoxQuantity: debounce(function () {
      this.isSaving = true;
      DeliveryRepository.setBoxQuantity(
        this.delivery.id,
        this.boxQuantity
      ).finally(() => this.isSaving = false);
    }, 300),
  },
  computed: {
    hasFrozenItems() {
      return this.delivery.hasFrozenItems;
    },
    hasCooledItems() {
      return this.delivery.hasCooledItems;
    },
  },
}
</script>

<style scoped>

</style>