<template>
  <checkbox-element
    v-model="model"
    :label="locale | languageByIsoCode"
    :occupy-validation-space="false"
    hide-checked-label
    label-inline
    size="xsmall"
  />
</template>

<script>
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";

export default {
  name: "LocalizationTranslationBoxTargetLanguage",
  components: {CheckboxElement},
  props: {
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return this.$store.getters["localization/translation/isLocaleSelectedAsTarget"](this.locale);
      },
      set() {
        this.$store.commit("localization/translation/toggleTargetLocale", this.locale);
      }
    }
  },
}
</script>

<style scoped>

</style>