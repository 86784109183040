<template>
  <div class="px-2 py-4 md:p-8">
    <div v-if="tasks.length > 0" class="grid grid-cols-1 lg:grid-cols-3 gap-2 md:gap-4">
      <touch-interface-task-card
        v-for="task in tasks"
        :key="task.id"
        :task="task"
        @reload="fetchTasks"
      />
    </div>

    <div v-else class="bg-gray-100 text-center p-4 italic">
      Vous n'avez aucune tâche à accomplir pour le moment.
    </div>
  </div>
</template>

<script>
import MyRepository from "@/repositories/MyRepository";
import TouchInterfaceTaskCard
  from "@/components/pages/TouchInterface/PersonalSpace/Tasks/Includes/TouchInterfaceTaskCard";

export default {
  name: "TouchInterfaceTasksList",
  components: {TouchInterfaceTaskCard},
  data: () => ({
    tasks: []
  }),
  created() {
    this.fetchTasks();
  },
  methods: {
    fetchTasks() {
      MyRepository.tasks.fetchMyUnfinishedTasks().then(res => {
        this.tasks = res.data.data;
      })
    }
  },
}
</script>

<style scoped>

</style>