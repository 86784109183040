var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center w-full gap-x-6 p-1 pr-4 border-b-2 border-gray-300",class:[
          _vm.isFullyPacked ? 'bg-white' : 'bg-yellow-100'
      ]},[_c('div',{staticClass:"h-24 w-24 flex-shrink-0"},[(_vm.getImage)?_c('img',{staticClass:"h-full py-1 w-24 bg-white object-contain",attrs:{"src":_vm.getImage,"alt":"Product"}}):_vm._e()]),_c('div',{staticClass:"text-4xl font-bold text-blue-900 text-opacity-80"},[_vm._v(" "+_vm._s(_vm.getToBePackedQuantity)+" ")]),_c('div',{staticClass:"flex-grow"},[_c('div',{staticClass:"text-xl text-blue-900 font-bold"},[_vm._v(" "+_vm._s(_vm.getItem.name)+" ")]),_c('div',{staticClass:"mt-3 whitespace-pre text-sm text-blue-900 font-medium"},[_vm._v(" "+_vm._s(_vm.getBundleSizeString)),_c('br'),(_vm.getStockOnShelfQuantity !== null)?_c('div',[_vm._v(" Restant: "+_vm._s(_vm.getStockOnShelfQuantity)+" ")]):_vm._e()])]),_c('div',{staticClass:"flex gap-x-6 items-center"},[(_vm.getToBePackedWeight)?_c('div',{staticClass:"flex-shrink-0 text-right"},[_c('span',{staticClass:"text-md text-blue-900 font-semibold"},[_vm._v(" "+_vm._s(_vm.getToBePackedWeight)+" "+_vm._s(_vm.getItem.bundle.unit)+" ")]),(_vm.getPackedWeight && _vm.isFullyPacked)?_c('span',{staticClass:"text-md font-bold",class:{
                      'text-gray-500': _vm.isPackedWeightLessOrEqualThanToBePackedWeight,
                      'text-red-500': _vm.isPackedWeightMoreThanToBePackedWeight,
                      'text-luxcaddy-500': _vm.isPackedWeightEqualToToBePackedWeight,
                 }},[_c('br'),(_vm.isPackedWeightLessOrEqualThanToBePackedWeight)?_c('span',[_vm._v("<")]):(_vm.isPackedWeightMoreThanToBePackedWeight)?_c('span',[_vm._v(">")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getPackedWeight)+" "+_vm._s(_vm.getItem.bundle.unit)+" ")]):_vm._e()]):_vm._e(),(_vm.getShouldRenderModal)?_c('order-fulfilment-item-weight-modal',{ref:"modal",attrs:{"item-id":_vm.getItem.id}}):_vm._e(),(_vm.getPackedQuantity > 0)?_c('order-fulfilment-undo-button',{on:{"click":_vm.resetPacked}}):_vm._e(),(!_vm.isFullyPacked && _vm.getIsLocked)?[(_vm.requiresManualWeightEntry)?_c('touch-optimized-button',{staticClass:"border border-gray-400 p-6 text-red-500",attrs:{"type":"secondary"},on:{"click":_vm.openManualWeightInputModal}},[_vm._v(" POIDS "+_vm._s(_vm.getUnfulfilledQuantity)+" ")]):_c('touch-optimized-button',{staticClass:"border border-gray-400 p-6",attrs:{"disabled":!_vm.allowsManualValidation,"type":"secondary"},on:{"click":function($event){return _vm.increasePacked()}}},[_vm._v(" OK "+_vm._s(_vm.getUnfulfilledQuantity)+" ")])]:_vm._e(),(!_vm.getIsLocked)?_c('div',{staticClass:"text-gray-600"},[_vm._v(" Pas de stock ")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }