import luxcaddyConfig from "@/luxcaddyConfig";
import ProductRepository from "@/repositories/ProductRepository";
import TagsRepository from "@/repositories/TagsRepository";
import FeeRepository from "@/repositories/FeeRepository";
import CampaignRepository from "@/repositories/CampaignRepository";
import CategoryRepository from "@/repositories/CategoryRepository";
import PackageRepository from "@/repositories/PackageRepository";
import DepositRepository from "@/repositories/DepositRepository";
import BannerRepository from "@/repositories/BannerRepository";
import BlogRepository from "@/repositories/BlogRepository";

const getDefaultState = () => {
  return {
    selectedLocale: null,
    selectedAttributes: [],

    selectedTargetLocales: []
  }
}

let getLocalesExceptFor = function (locale) {
  return luxcaddyConfig
    .supportedLocalizationLanguages
    .flatMap(l => l.value)
    .filter(l => l !== locale);
}

export default {
  state: getDefaultState(),
  getters: {
    getSelectedLocale: state => state.selectedLocale,
    getSelectedAttributes: state => state.selectedAttributes,

    isAttributeForLocaleSelected: (state) => (locale, attribute) => {
      return locale === state.selectedLocale && state.selectedAttributes.findIndex(a => a === attribute) !== -1;
    },

    // Available target locales are all locales minus the one selected as source
    getAvailableTargetLocales: state => {
      if (state.selectedLocale === null) {
        return [];
      }

      return getLocalesExceptFor(state.selectedLocale);
    },

    isLocaleSelectedAsTarget: (state) => (locale) => {
      return state.selectedTargetLocales.includes(locale);
    }
  },
  mutations: {
    clear(state) {
      state.selectedLocale = getDefaultState().selectedLocale;
      state.selectedAttributes = getDefaultState().selectedAttributes;
      state.selectedTargetLocales = getDefaultState().selectedTargetLocales;
    },

    setAttributes(state, {locale, attributes}) {
      if (state.selectedLocale !== locale) {
        state.selectedTargetLocales = getLocalesExceptFor(locale);
      }

      state.selectedLocale = locale;
      state.selectedAttributes = attributes;
    },

    toggleAttributeSelection(state, {locale, attribute}) {
      // Change language and clear all attributes
      if (state.selectedLocale !== locale) {
        state.selectedLocale = locale;
        state.selectedAttributes = [];
      }

      const isAttributeSelected = state.selectedAttributes.findIndex(a => a === attribute) !== -1;
      if (isAttributeSelected) {
        state.selectedAttributes = state.selectedAttributes.filter(a => a !== attribute);
      } else {
        // if this is the first attribute to be added, preselect all target languages
        if (state.selectedAttributes.length === 0) {
          state.selectedTargetLocales = getLocalesExceptFor(locale);
        }

        state.selectedAttributes.push(attribute);
      }

      // Reset if no attribute selected anymore
      if (state.selectedAttributes.length === 0) {
        state.selectedLocale = null;
        state.selectedTargetLocales = [];
      }
    },

    removeSelectedAttribute(state, attribute) {
      state.selectedAttributes = state.selectedAttributes.filter(a => a !== attribute);
    },

    toggleTargetLocale(state, locale) {
      if (state.selectedTargetLocales.includes(locale)) {
        state.selectedTargetLocales = state.selectedTargetLocales.filter(l => l !== locale);
      } else {
        state.selectedTargetLocales.push(locale);
      }
    }
  },

  actions: {
    fetchTranslation({state}, {localizableType, localizableId}) {
      let request;

      switch (localizableType) {
        case "product":
          request = ProductRepository.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
        case "tag":
          request = TagsRepository.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
        case "fee":
          request = FeeRepository.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
        case "campaign":
          request = CampaignRepository.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
        case "category":
          request = CategoryRepository.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
        case "package":
          request = PackageRepository.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
        case "deposit":
          request = DepositRepository.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
        case "banner":
          request = BannerRepository.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
        case "BlogPost":
          request = BlogRepository.posts.localization.translate(localizableId, state.selectedLocale, state.selectedTargetLocales, state.selectedAttributes);
          break;
      }

      return request;
    },
  }
}