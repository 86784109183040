import PrintDeliveryFulfilmentLabelsPage
  from "@/components/pages/TouchInterface/Delivery/PrintLabels/PrintDeliveryFulfilmentLabelsPage";
import auth from "@/router/middlewares/authMiddleware";
import DriverMyRoutesInterfacePage
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/DriverMyRoutesInterfacePage";
import DriverMyRoutesRouteDetailPage
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/RouteDetail/DriverMyRoutesRouteDetailPage";
import DriverMyRouteDeliveryDetailPage
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/DeliveryDetail/DriverMyRouteDeliveryDetailPage";
import DriverMyRouteDeliveryDetailOrderedProductsPage
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/DeliveryDetail/DriverMyRouteDeliveryDetailOrderedProductsPage";
import DriverMyRouteDeliveryDetailMarkDeliveredPage
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/DeliveryDetail/DriverMyRouteDeliveryDetailMarkDeliveredPage";
import DriverRouteCheckInterfacePage
  from "@/components/pages/TouchInterface/Delivery/RouteCheck/DriverRouteCheckInterfacePage";
import DeliveryFulfilmentBarcodeCheckPage
  from "../../../components/pages/TouchInterface/Delivery/BarcodeCheck/DeliveryFulfilmentBarcodeCheckPage";
import DriverRouteCheckDetailInterfacePage
  from "@/components/pages/TouchInterface/Delivery/RouteCheck/Detail/DriverRouteCheckDetailInterfacePage";
import DriverMyRouteDeliveryDetailSignatureOverview
  from "@/components/pages/TouchInterface/Delivery/MyRoutes/DeliveryDetail/SignatureOverview/DriverMyRouteDeliveryDetailSignatureOverview.vue";

export const TouchDeliveryRoutes = [
  {
    path: '/touch/delivery/labels',
    name: 'touchInterface.delivery.fulfilment.printlabels',
    component: PrintDeliveryFulfilmentLabelsPage,
    meta: {
      title: 'Imprimer Etiquettes',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/checkBarcodes',
    name: 'touchInterface.delivery.barcodeCheck.index',
    component: DeliveryFulfilmentBarcodeCheckPage,
    meta: {
      title: 'Emballage check',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/route-check',
    name: 'touchInterface.delivery.fulfilment.routecheck.index',
    component: DriverRouteCheckInterfacePage,
    meta: {
      title: 'Route check',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/route-check/:route',
    name: 'touchInterface.delivery.fulfilment.routecheck.detail.index',
    component: DriverRouteCheckDetailInterfacePage,
    meta: {
      title: 'Route check - Détail',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/my-routes',
    name: 'touchInterface.delivery.fulfilment.myroutes',
    component: DriverMyRoutesInterfacePage,
    meta: {
      title: 'Mes routes',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/my-routes/:route/deliveries',
    name: 'touchInterface.delivery.fulfilment.myroutes.route.index',
    component: DriverMyRoutesRouteDetailPage,
    meta: {
      title: 'Détail de la route',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/my-routes/:route/deliveries/:delivery',
    name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.index',
    component: DriverMyRouteDeliveryDetailPage,
    meta: {
      title: 'Détail de la livraison',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/my-routes/:route/deliveries/:delivery/order/:order',
    name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.items.index',
    component: DriverMyRouteDeliveryDetailOrderedProductsPage,
    meta: {
      title: 'Détail de la livraison',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/my-routes/:route/deliveries/:delivery/mark',
    name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.items.mark.index',
    component: DriverMyRouteDeliveryDetailMarkDeliveredPage,
    meta: {
      title: 'Détail de la livraison',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
  {
    path: '/touch/delivery/my-routes/:route/deliveries/:delivery/mark/signature',
    name: 'touchInterface.delivery.fulfilment.myroutes.route.delivery.items.mark.signature.index',
    component: DriverMyRouteDeliveryDetailSignatureOverview,
    meta: {
      title: 'Client présent',
      layout: 'TouchInterface',
      middleware: [auth],
      permissions: ['TOUCH_INTERFACE_ACCESS'],
    }
  },
];