<template>
  <list-table
    :columns="columns"
    :filters="filters"
    :repository-export-func="repositoryExportFunc"
    :repository-func="repositoryFunc"
    :title="title"
    export-button
    identifier="unfulfilledItemsTable"
    show-filters-immediately
  >
    <template v-slot:product-quick-action-template="{ row }">
      <product-quick-action-modal :id="row.productId"/>
    </template>
    <template v-slot:product-name-template="{ row }">
        <product-name
          :custom-name="row.name"
          :custom-is-discontinued="row.productIsDiscontinued"
          :custom-is-published="row.productIsPublished"
          :custom-is-visibility-forced="row.productIsShopVisibilityForced"
        />
    </template>
  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import {
  FILTER_BOOLEAN,
  FILTER_DATETIME_RANGE,
  FILTER_DEPARTMENT_MULTI,
  FILTER_PRODUCT_SUPPLIER_MULTI
} from "@/components/listTable/includes/controls/filters/availableFilters";
import UnfulfilledItemsRepository from "@/repositories/UnfulfilledItemsRepository";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";

export default {
  name: "ShowUnfulfilledItemsTable",
  components: {ProductName, ProductQuickActionModal, ListTable},
  data() {
    const defaultBeginYear = this.$date().startOf('day').format('DD.MM.YYYY HH:mm:ss');
    const defaultEndYear = this.$date().endOf('day').format('DD.MM.YYYY HH:mm:ss');

    return {
      title: 'Produits manquants',
      repositoryFunc: UnfulfilledItemsRepository.get,
      repositoryExportFunc: UnfulfilledItemsRepository.getExport,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              showIcon: false,
              customText: 'Info',
              hint: "Voir Produit",
              route: (row) => ({name: 'products.packaging', params: {product: row.productId}}),
            },
          ]
        },
        {
          caption: 'ID Produit',
          cellTemplate: "product-quick-action-template",
        },
        {
          caption: 'Réf. Fourn.',
          dataField: 'supplierReference',
        },
        {
          caption: 'Qte',
          dataField: 'unfulfilledQuantity',
        },
        {
          caption: 'Contenu',
          dataField: 'sellingContent',
        },
        {
          caption: 'Produit',
          cellTemplate: 'product-name-template'
        },
        {
          caption: 'Fournisseur',
          dataField: 'supplierName',
        },
        {
          caption: 'Marque',
          dataField: 'brand',
        },
        {
          caption: 'Prix achat htva',
          dataField: 'sellingUnitBuyingPriceWithVat',
          dataType: 'euro'
        },
      ],
      filters: [
        {
          filterKey: 'timeslot',
          caption: "Date début entre",
          type: FILTER_DATETIME_RANGE,
          defaultValue: {
            startDate: defaultBeginYear,
            endDate: defaultEndYear
          }
        },
        {
          caption: "Exclure fournisseur",
          filterKey: "excludeSupplierId",
          type: FILTER_PRODUCT_SUPPLIER_MULTI,
        },
        {
          caption: "Exclure Département",
          filterKey: "excludeDepartments",
          type: FILTER_DEPARTMENT_MULTI,
        },
        {
          caption: 'Just in Time',
          filterKey: 'isJustInTime',
          type: FILTER_BOOLEAN,
          small: true
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>