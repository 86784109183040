<template>
  <text-input-element
    ref="input"
    :disabled="disabled"
    :inset-position="insetPosition"
    :inset-text="getZone"
    :label="label"
    :touch-optimized="touchOptimized"
    :type="type"
    :validation-rules="getValidationRules"
    :value="value"
    @blur="$emit('blur', $event)"
    @enter="$emit('enter', $event)"
    @escape="$emit('escape', $event)"
    @input="$emit('input', $event)"
  ></text-input-element>
</template>

<script>

import DeliveryPostalCodeRepository from "../../../../../../../repositories/DeliveryPostalCodeRepository";
import TextInputElement from "../../../../../../elements/forms/elements/base/TextInputElement";

export default {
  name: "ZonePostalCodeInput",
  components: {TextInputElement},
  props: {
    type: {
      type: String,
      default: "text"
    },
    touchOptimized: {
      type: Boolean,
      default: false
    },
    value: {
      required: true
    },
    label: {
      type: String,
      default: 'Code Postal'
    },
    validationRules: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    insetPosition: {
      type: String,
    },
  },
  data: () => ({
    zonePostalCodes: []
  }),
  mounted() {
    this.fetchZonePostalCodes();
  },

  methods: {
    fetchZonePostalCodes() {
      DeliveryPostalCodeRepository.get().then((res) => {
        this.zonePostalCodes = res.data.data;
      })
    },
    focus() {
      this.$refs.input.focus();
    }
  },
  computed: {
    getValidationRules() {
      let defaultRule = 'numeric';

      return this.validationRules?.length ? `${this.validationRules}|${defaultRule}` : defaultRule;
    },
    getZone() {
      let zonePostalCode = this.zonePostalCodes.find(f => f.postalCode === this.value);

      return zonePostalCode?.zone ? 'Zone ' + zonePostalCode.zone : 'Zone / ';
    }
  }
}
</script>

<style scoped>

</style>