<template>
    <span
      :class="isRecipe ? 'bg-red-400' :  isFaq ? 'bg-green-400' :'bg-gray-700'"
      class="text-white px-2 py-1 rounded text-xs tracking-wider"
    >
        {{ getLabelByType }}
    </span>
</template>

<script>
export default {
  name: "BlogPostTypeLabel",
  props: {
    type: {
      type: String,
      validator: val => ["BlogPost", "Recipe", "Faq"].includes(val)
    }
  },
  computed: {
    getLabelByType() {
      switch (this.type) {
        case "BlogPost":
          return "Blog";
        case "Recipe":
          return "Recette";
        case "Faq":
          return "Faq";
        default:
          return this.type;
      }
    },
    isRecipe() {
      return this.type === "Recipe";
    },
    isFaq() {
      return this.type === "Faq";
    }
  },
}
</script>

<style scoped>

</style>