<template>

  <div>
    <page-title>
      Modifier requête
    </page-title>

    <card>
      <create-or-update-report-query-form
        ref="editReportQueryForm"
        mode="update"
      />
    </card>
  </div>
</template>

<script>
import Card from "../../../elements/cards/Card";
import CreateOrUpdateReportQueryForm from "@/components/pages/ReportQueries/Forms/CreateOrUpdateReportQueryForm.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";

export default {
  name: "EditReportQuery",
  components: {PageTitle, CreateOrUpdateReportQueryForm, Card}
}
</script>
