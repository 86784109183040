<!--
    Updates whether an input in a specific form has been changed from its initial value.
-->
<template>
  <span class="hidden"></span>
</template>

<script>
export default {
  name: "FormDisplayStatusUpdater",
  props: {
    formId: {
      type: String,
      required: true
    },
    changed: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    changed(bool) {
      this.$store.commit('forms/updateDisplayedForm', {
        id: this.formId,
        changed: bool
      });
    }
  }
}
</script>

