<template>
  <text-input-element
    :disabled="disabled"
    :inset-position="insetPosition"
    :inset-text="insetText"
    :label="label"
    :validation-rules="getValidationRules"
    :value="value"
    @input="$emit('input', $event)"
  ></text-input-element>
</template>

<script>
import TextInputElement from "./base/TextInputElement";

export default {
  name: "PhoneInputElement",
  components: {
    TextInputElement
  },
  data: () => ({
    errorMessage: ''
  }),
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      default: 'Tél'
    },
    validationRules: {
      type: String,
      default: ''
    },
    insetText: {
      type: String,
      required: false
    },

    insetPosition: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    getValidationRules() {
      return this.validationRules;
    }
  }

}
</script>

