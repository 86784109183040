<template>
  <comment-box-template>
    <text-area-element
      ref="textElement"
      v-model="text"
      :occupy-validation-space="false"
      :rows="4"
      label="Commentaire"
    />

    <date-picker-element
      v-model="expiresAt"
      :occupy-validation-space="false"
      class="mt-2"
      disallow-past-date
      label="Date d'expiration"
      with-time
    />

    <div class="flex justify-end mt-3.5">
      <button-element
        :loading="isLoading"
        size="xsmall"
        @click="createComment"
      >
        <fa-icon class="mr-1.5" fixed-width icon="check"/>
        Créer
      </button-element>
    </div>
  </comment-box-template>
</template>

<script>
import CommentBoxTemplate from "@/components/global/Comments/Modal/Sub/CommentBoxTemplate.vue";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";

export default {
  name: "CommentBoxAdd",
  components: {DatePickerElement, ButtonElement, TextAreaElement, CommentBoxTemplate},
  data() {
    return {
      text: null,
      expiresAt: null,

      isLoading: false,
    }
  },
  mounted() {
    this.$refs.textElement.focus();
  },
  methods: {
    createComment() {
      this.isLoading = true;
      this.$store.dispatch('comments/createComment', {
        text: this.text,
        expiresAt: this.expiresAt,
      }).finally(() => this.isLoading = false);
    }
  }
}
</script>