<template>
  <list-table-type-boolean
    :value="checkGeocodingPrecise"
  />
</template>

<script>
import ListTableTypeBoolean from "../../../../../../listTable/includes/types/ListTableTypeBoolean";

export default {
  name: "GeocodingPrecisionStatus",
  components: {ListTableTypeBoolean},
  props: {
    geocoding: {
      type: Object
    },
  },
  computed: {
    checkGeocodingPrecise() {
      return this.geocoding?.isPrecise || false;
    },
  },
}
</script>