<template>
  <div class="p-0 sm:p-4">
    <scanner-listener
      :accept-quantity="false"
      @scan="onScan"
    />

    <div v-if="isInDevMode" class="flex items-center space-x-2">
      <div class="font-bold text-red-500">
        Debug
      </div>
      <touch-optimized-button @click="onScan({code: '4009249019916'})">Scan Shelf</touch-optimized-button>
      <touch-optimized-button type="secondary" @click="scannedBarcode = null">Reset</touch-optimized-button>
    </div>

    <div class="bg-gray-200">
      <div v-if="scannedBarcode === null" class="p-4 text-3xl text-center">
        Scan étiquette étagère ou produit...
      </div>
      <scan-edit-manager-product-interface
        v-else
        :scanned-barcode="scannedBarcode"
        @close="scannedBarcode = null"
      />
    </div>
  </div>
</template>

<script>
import ScannerListener from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/scanner/ScannerListener";
import ScanEditManagerProductInterface
  from "@/components/pages/TouchInterface/Stock/ScanEdit/Components/ScanEditManagerProductInterface";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton.vue";

export default {
  name: "ScanEditInterfacePage",
  components: {TouchOptimizedButton, ScanEditManagerProductInterface, ScannerListener},
  data: () => ({
    scannedBarcode: null,
  }),
  methods: {
    onScan({code}) {
      // allow scanning different barcode while one is already scanned.
      this.scannedBarcode = null;
      this.$nextTick(() => {
        this.scannedBarcode = code;
      });
    }
  },
  computed: {
    isInDevMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
}
</script>