<template>
  <comment-box-template
    :author="comment.authorMember.name"
    :class="{'border-dashed': isExpired, 'opacity-40': isExpired && !(isEditingComment || isDeletingComment)}"
    :created-at="comment.createdAt"
    :expires-at="isEditingComment ? null : comment.expiresAt"
  >
    <template #actions>
      <transition
        mode="out-in"
        name="fade"
      >
        <template
          v-if="isEditingComment || isDeletingComment">
          <div
            key="editOrDeleting"
            class="flex gap-x-2 items-center"
          >
            <comment-box-delete
              v-if="isDeletingComment"
            />
            <fa-icon
              class="text-gray-500 hover:text-gray-600 cursor-pointer text-lg"
              fixed-width
              icon="times"
              @click="abortEditAndDelete"
            />
          </div>
        </template>
        <template
          v-else
        >
          <div
            key="default"
            class="whitespace-nowrap space-x-1"
          >
            <fa-icon
              class="text-yellow-500 hover:text-yellow-600 cursor-pointer opacity-30 group-hover:opacity-100 transition-opacity"
              fixed-width
              icon="edit"
              @click="[setCommentIdBeingEdited(comment.id), setCommentIdBeingDeleted(null)]"
            />
            <fa-icon
              class="text-red-500 hover:text-red-600 cursor-pointer opacity-30 group-hover:opacity-100 transition-opacity"
              fixed-width
              icon="trash"
              @click="[setCommentIdBeingDeleted(comment.id), setCommentIdBeingEdited(null)]"
            />
          </div>
        </template>
      </transition>
    </template>

    <transition
      mode="out-in"
      name="fade-up-down"
    >
      <template
        v-if="isEditingComment"
      >
        <comment-box-edit/>
      </template>
      <template
        v-else
      >
        <p
          :class="{'line-through': isExpired}"
          class="text-sm font-serif tracking-tighter"
        >
          {{ comment.text }}
        </p>
      </template>
    </transition>
  </comment-box-template>
</template>

<script>
import {mapMutations} from "vuex";
import CommentBoxEdit from "@/components/global/Comments/Modal/Sub/CommentBoxEdit.vue";
import CommentBoxDelete from "@/components/global/Comments/Modal/Sub/CommentBoxDelete.vue";
import CommentBoxTemplate from "@/components/global/Comments/Modal/Sub/CommentBoxTemplate.vue";

export default {
  name: "CommentBox",
  components: {CommentBoxTemplate, CommentBoxDelete, CommentBoxEdit},
  props: {
    comment: {
      type: Object
    },
  },
  methods: {
    ...mapMutations('comments', [
      'setCommentIdBeingEdited',
      'setCommentIdBeingDeleted',
    ]),
    abortEditAndDelete() {
      this.setCommentIdBeingEdited(null);
      this.setCommentIdBeingDeleted(null);
    }
  },
  computed: {
    isEditingComment() {
      return this.$store.getters['comments/isEditingComment'](this.comment.id);
    },
    isDeletingComment() {
      return this.$store.getters['comments/isDeletingComment'](this.comment.id);
    },
    isExpired() {
      if (this.comment.expiresAt === null) {
        return false;
      }
      return this.$date(this.comment.expiresAt).isSameOrBefore(this.$date());
    }
  },
}
</script>