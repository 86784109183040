<template>
  <div>
    <page-title>Exécuter un rapport</page-title>

    <card>
      <report-query-select/>

      <report-query-placeholder-list
        @submit="executeQuery"
      />

      <button-element
        v-if="hasSelectedAndLoadedQuery"
        class="mt-4"
        size="small"
        @click="executeQuery"
      >
        <fa-icon
          class="mr-1.5"
          fixed-width
          icon="play"
        />
        Executer et afficher
      </button-element>

      <button-element
        v-if="hasSelectedAndLoadedQuery"
        class="mt-4 ml-4"
        size="small"
        type="light"
        @click="openModal(true)"
      >
        <fa-icon
          class="mr-1.5"
          fixed-width
          icon="play"
        />
        Executer et envoyer par email
      </button-element>

      <button-element
        v-if="hasSelectedAndLoadedQuery"
        class="mt-4 ml-4"
        size="small"
        type="light"
        @click="openModal(false)"
      >
        <fa-icon
          class="mr-1.5"
          fixed-width
          icon="play"
        />
        Executer et télécharger
      </button-element>

      <modal ref="fileTypeModal" title="Choisir le format du fichier" @close="onCloseModal">
        <div class="flex gap-x-4">
          <button-element
            type="primary"
            @click="executeQueryWithFile('xlsx')"
          >
            XLSX
          </button-element>

          <button-element
            type="warning"
            @click="openCsvOptionsModal"
          >
            CSV
          </button-element>
        </div>
      </modal>
    </card>

    <report-query-response-container
      class="mt-8"
    />
      <export-csv-options-modal ref="csvOptionModal" :execute-async="executeCsvAsync"
      @close="onCloseCsvOptionModal"
      ></export-csv-options-modal>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import Card from "@/components/elements/cards/Card.vue";
import ReportQuerySelect from "@/components/pages/ReportQueries/Execute/Sub/ReportQuerySelect.vue";
import ReportQueryPlaceholderList
  from "@/components/pages/ReportQueries/Execute/Sub/Placeholder/ReportQueryPlaceholderList.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import ReportQueryResponseContainer
  from "@/components/pages/ReportQueries/Execute/Sub/ReportQueryResponseContainer.vue";
import Modal from "@/components/elements/modals/Modal.vue";
import ExportCsvOptionsModal from "@/components/pages/ReportQueries/Execute/Sub/ExportCsvOptionsModal.vue";

export default {
  name: "ExecuteReportQuery",
  data: () => ({
    executeAsync: null,
    executeCsvAsync: null
  }),
  components: {
      ExportCsvOptionsModal,
    Modal,
    ReportQueryResponseContainer, ButtonElement, ReportQueryPlaceholderList, ReportQuerySelect, Card, PageTitle
  },
  created() {
    this.$store.commit('reportQuery/resetSelection', null);
  },
  computed: {
    hasSelectedAndLoadedQuery() {
      return this.$store.getters['reportQuery/hasSelectedAndLoadedQuery'];
    }
  },
  methods: {
    onCloseCsvOptionModal() {
        this.executeCsvAsync = null
    },
    openCsvOptionsModal() {
        this.executeCsvAsync = this.executeAsync;

        this.$refs.fileTypeModal.closeModal();
        this.$refs.csvOptionModal.openModal()
    },
    openModal(executeAsync) {
      if (executeAsync) {
        this.executeAsync = true;
      }
      this.$refs.fileTypeModal.openModal();
    },
    onCloseModal() {
      this.executeAsync = null;
      this.$refs.fileTypeModal.closeModal();
    },
    executeQuery() {
      this.$store.dispatch('reportQuery/executeSelectedQuery', {
        page: 1,
        perPage: 100
      });
    },
    executeQueryAsync(fileFormat) {

      this.$store.dispatch('reportQuery/executeSelectedQueryAsync', {fileFormat: fileFormat}).then(() => {
        this.$sendSuccessToast(`Rêquete a été envoyé! Vous recevrez un e-mail dans quelques minutes.`);
      });
    },

    executeQueryAndDownload(fileFormat) {
      this.$store.dispatch('reportQuery/executeSelectedQueryAndDownload', {fileFormat: fileFormat}).then(() => {
        this.$sendSuccessToast(`Done!`);
      });

    },

    executeQueryWithFile(fileFormat) {
      if (this.executeAsync) {
        this.executeQueryAsync(fileFormat)
      } else {
        this.executeQueryAndDownload(fileFormat)
      }
      this.$refs.fileTypeModal.closeModal();
      this.executeAsync = null;
    }
  },
}
</script>