<template>
  <div class="container mb-3">
    <dashboard-notification-menu-notification
      :notification="notification"
      in-toast-context
      is-first
      @toggleReadClick="toggleReadClick"
    />
  </div>
</template>

<script>
import DashboardNotificationMenuNotification
  from "@/components/layouts/dashboard/partials/notifications/DashboardNotificationMenuNotification";

export default {
  name: "NotificationToast",
  components: {DashboardNotificationMenuNotification},
  props: {
    notification: {
      type: Object,
      required: true,
    }
  },
  methods: {
    toggleReadClick(id) {
      this.$emit('toggleReadClick', id);
    }
  },
}
</script>

<style>
.Vue-Toastification__toast--default.notificationtoast {
  @apply bg-white text-black;
}

.Vue-Toastification__toast--default.notificationtoast .Vue-Toastification__progress-bar {
  @apply bg-gray-400 bg-opacity-70;
}

.Vue-Toastification__toast--default.notificationtoast .Vue-Toastification__close-button {
  @apply text-black ml-4;
}
</style>