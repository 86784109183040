<template>
  <div>
    <media-image-display-and-management
      ref="imageManagement"
      :media-able-id="blogPostId"
      :possible-labels="getPossibleLabels"
      media-able-type="blogPost"
    />
  </div>
</template>

<script>
import MediaImageDisplayAndManagement from "@/components/global/Media/Image/MediaImageDisplayAndManagement";

export default {
  name: "DisplayBlogPostImages",
  components: {MediaImageDisplayAndManagement},
  props: {
    blogPostId: {
      type: Number,
      required: true
    },
  },
  computed: {
    getPossibleLabels() {
      return this.$luxcaddyConfig("blogPost.images.availableLabels");
    }
  },
  methods: {
    fetchImages() {
      this.$refs.imageManagement.fetchImages();
    }
  }
}
</script>

