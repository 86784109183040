<template>
  <div v-if="getPolyLines.length > 0">
    <gmap-polyline
      v-for="(polyline, i) in getPolyLines"
      :key="polyline.path[0].lat + '_' + i"
      :clickable="true"
      :options="{'strokeColor': polyline.color, 'strokeWeight': 4}"
      :path="polyline.path"
      @mouseout="unsetHoveredSpeed"
      @mouseover="onHoverPolyline(polyline)"
    >
    </gmap-polyline>
  </div>
</template>

<script>
import {getColorBySpeed} from "@/helpers/routeManagerHelper";
import {mapMutations} from "vuex";

export default {
  name: "RouteManagerPolyLineGenerator",
  props: {
    trackingPoints: {
      type: Array
    }
  },
  methods: {
    ...mapMutations('routeManager', [
      'setHoveredSpeed',
      'setHoveredDateTime',
    ]),
    getColorBySpeed(speed) {
      return getColorBySpeed(speed);
    },
    onHoverPolyline(polyline) {
      this.setHoveredSpeed(polyline.speed);
      this.setHoveredDateTime(polyline.createdAt);
    },
    unsetHoveredSpeed() {
      this.setHoveredSpeed(null);
      this.setHoveredDateTime(null);
    }
  },
  computed: {
    getPolyLines() {
      return this.getMappedPoints.map((point, i) => {
        let nextPoint = this.getMappedPoints[i + 1];
        if (!nextPoint) {
          return null;
        }

        return {
          path: [
            {lat: point.lat, lng: point.lng},
            {lat: nextPoint.lat, lng: nextPoint.lng},
          ],
          color: this.getColorBySpeed(nextPoint.speed),
          speed: nextPoint.speed,
          createdAt: nextPoint.createdAt,
        }
      }).filter(pl => pl !== null);
    },

    /**
     *
     * @returns {{lng: *, lat: *, speed: *}[]|*[]}
     */
    getMappedPoints() {
      if (!this.trackingPoints)
        return [];

      return this.trackingPoints.map(point => {
        return {
          lat: point.latitude,
          lng: point.longitude,
          speed: point.speed,
          createdAt: point.createdAt,
        }
      });
    },
  },
}
</script>

