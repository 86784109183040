<template>
  <div>
    <select-element
      :allow-unselecting="allowUnselecting"
      :disabled="disabled"
      :label="label"
      :nothing-selected-text="nothingSelectedText"
      :options="getCountries"
      :prioritized-options="prioritizedCountries"
      :validation-rules="validationRules"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CountrySelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Pays',
      required: false,
    },
    options: {
      required: false,
      validator: val => typeof val === "object"
    },
    validationRules: {
      type: String,
      required: false,
    },
    nothingSelectedText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    onlyLuxembourg: {
      type: Boolean,
      default: false
    },
    allowUnselecting: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (!this.onlyLuxembourg) {
      if (this.options === undefined) {
        this.fetchCountries();
      } else {
        this.availableCountries = this.options;
      }
    }
  },
  methods: {
    fetchCountries() {
      this.$store.dispatch('global/fetchCountries');
    }
  },
  computed: {
    getCountries() {
      if (this.onlyLuxembourg) {
        return [{label: 'Luxembourg', value: 'lu'}]
      }

      return this.$store.getters['global/getCountries'].map(c => {
        return {
          value: c.isoCode,
          label: c.name,
        };
      });
    }
  },
  data: () => ({
    availableCountries: [],
    prioritizedCountries: ['lu', 'fr', 'be', 'de']
  })
}
</script>

