<template>
  <div
    :class="{'opacity-40': notification.isRead, 'border-t': !isFirst, 'text-black': !onTouchInterface, 'border-gray-800 text-gray-200': onTouchInterface}"
    class="pt-2 lg:pt-4 w-full flex items-center"
  >
    <div
      v-if="!onTouchInterface"
      class="flex-shrink-0 w-8 h-8 border rounded-md border-gray-200 flex items-center justify-center"
    >
      <fa-icon :class="getIconInfo.classes" :icon="getIconInfo.icon" fixed-width></fa-icon>
    </div>
    <div class="lg:pl-6 flex-1">
      <div class="flex flex-col lg:flex-row lg:items-center gap-y-4 lg:gap-x-16">
        <div class="flex-1">
          <div
            class="focus:outline-none text-sm leading-none"
            v-html="getParsedText"
          />

          <div
            v-if="getButtons.length"
            class="mt-4 flex flex-wrap gap-x-2"
          >
            <dashboard-notification-button
              v-for="(button, i) in getButtons"
              :key="button + '_' + i"
              :encoded-string="button"
              :type="i % 2 ? 'light' : 'dark'"
            />
          </div>
        </div>

        <div
          class="w-full lg:w-36 flex flex-row justify-between lg:justify-start lg:flex-col items-end gap-y-3">
          <p
            class="focus:outline-none text-gray-400 text-xs">
            {{ $date(notification.createdAt).fromNow() }}
          </p>

          <template>
            <button-element
              v-if="notification.isRead"
              :size="onTouchInterface ? 'small' : 'xsmall'"
              type="light"
              @click="toggleNotificationRead"
            >
              <fa-icon fixed-width icon="eye-slash"></fa-icon>
            </button-element>
            <button-element
              v-else
              :size="onTouchInterface ? 'small' : 'xsmall'"
              @click="toggleNotificationRead"
            >
              <fa-icon fixed-width icon="eye"></fa-icon>
            </button-element>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardNotificationButton
  from "@/components/layouts/dashboard/partials/notifications/button/DashboardNotificationButton";
import {parseNotificationText} from "@/helpers/NotificationLinkHelper";
import ButtonElement from "@/components/elements/buttons/ButtonElement";

export default {
  name: "DashboardNotificationMenuNotification",
  components: {ButtonElement, DashboardNotificationButton},
  props: {
    notification: {
      type: Object,
      required: true
    },
    isFirst: {
      type: Boolean,
      required: true
    },
    /**
     * When set to true, this means that this component is rendered in a toast
     * Where we have limited access (no access to Vuex at all)
     */
    inToastContext: {
      type: Boolean,
      default: false
    },

    /**
     * When set to true, it means we are displaying the notification in the
     * menu on the touch interface, instead of the regular Backoffice menu.
     */
    onTouchInterface: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleNotificationRead() {
      if (this.inToastContext) {
        this.$emit('toggleReadClick', this.notification.id);
      } else {
        this.$store.dispatch('notifications/toggleNotificationRead', this.notification.id);
      }
    },
  },
  computed: {
    getIconInfo() {
      switch (this.notification.type) {
        case "info":
          return {icon: 'info-circle', classes: 'text-blue-500'};
        case "warning":
          return {icon: 'exclamation-triangle', classes: 'text-yellow-500'};
        default:
          return {icon: 'times-circle', classes: 'text-red-500'};
      }
    },
    getButtons() {
      return this.notification.buttons || [];
    },
    getParsedText() {
      return parseNotificationText(this.notification.text);
    }
  },
}
</script>

<style scoped>

</style>