<template>
  <div>
    <display-blog-post-images ref="imageDisplay" :blog-post-id="blogPostId" class="mb-6"/>
    <upload-blog-post-images :blog-post-id="blogPostId" @uploaded="onImagesUploaded"/>
  </div>
</template>

<script>
import UploadBlogPostImages from "@/components/pages/Blog/Posts/EditBlogPost/Images/Upload/UploadBlogPostImages";
import DisplayBlogPostImages from "@/components/pages/Blog/Posts/EditBlogPost/Images/Existing/DisplayBlogPostImages";

export default {
  name: "ManageBlogPostImages",
  components: {UploadBlogPostImages, DisplayBlogPostImages},
  props: {
    blogPostId: {
      type: Number,
      required: true
    }
  },
  methods: {
    // Executed whenever new images have been uploaded
    onImagesUploaded() {
      this.$refs.imageDisplay.fetchImages();
    }
  },
}
</script>

