<template>
  <div>
    <media-image-display-and-management
      ref="imageManagement"
      :media-able-id="productCategoryId"
      :possible-labels="getPossibleLabels"
      media-able-type="category"
    />
  </div>
</template>

<script>
import MediaImageDisplayAndManagement from "@/components/global/Media/Image/MediaImageDisplayAndManagement";

export default {
  name: "DisplayProductCategoryImages",
  components: {MediaImageDisplayAndManagement},
  props: {
    productCategoryId: {
      type: Number,
      required: true
    },
  },
  computed: {
    getPossibleLabels() {
      return this.$luxcaddyConfig("category.images.availableLabels");
    }
  },
  methods: {
    fetchImages() {
      this.$refs.imageManagement.fetchImages();
    }
  }
}
</script>

