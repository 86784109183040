<template>
  <div
    v-click-outside="closeMenu"
    :class="{'z-30': showMenu}"
    class="relative"
  >
    <fa-icon
      v-if="enableShortCuts"
      class="ml-1.5 text-sm text-gray-400 hover:text-gray-500 cursor-pointer"
      fixed-width
      icon="bolt"
      @click="showMenu = !showMenu"
    />

    <div
      v-if="showMenu"
      class="absolute bg-white border border-gray-300 p-2 w-max mt-1"
    >
      <div
        v-for="(shortcutGroup, i) in shortcuts"
        :key="'shortcutGroup-' + i"
        :class="{'mb-4': i !== shortcuts.length -1}"
        class="grid grid-cols-2 gap-1.5"
      >
        <div
          v-for="shortcut in shortcutGroup"
          :key="shortcut.label"
          class=" text-center flex flex-col items-center justify-center flex-none py-0.5 px-1 cursor-pointer bg-gray-100 hover:bg-gray-200 border border-gray-200 rounded"
          @click="applyShortcut(shortcut)"
        >
          {{ shortcut.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateRangePickerShortcutsIcon",
  data() {
    return {
      showMenu: false
    }
  },
  props: {
    enableShortCuts: {
      type: Boolean,
      default: true
    },
    shortcuts: {
      type: Array,
    }
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },

    applyShortcut(shortcut) {
      this.$emit('shortcut', {
        start: shortcut.start,
        end: shortcut.end,
      });

      this.showMenu = false;
    }
  },
}
</script>

<style scoped>

</style>