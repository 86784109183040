<template>
  <create-update-vehicle-form mode="create"/>
</template>

<script>

import CreateUpdateVehicleForm from "@/components/pages/Vehicles/Forms/CreateUpdateVehicleForm.vue";

export default {
  name: "CreateVehicle",
  components: {CreateUpdateVehicleForm}
}
</script>

<style scoped>

</style>