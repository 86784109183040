<template>
  <select-element
    :is-loading="isLoading"
    :label="label"
    :live-search="false"
    :options="getMappedBrands"
    :validation-rules="validationRules"
    :value="value"
    enable-search
    @input="$emit('input', $event)"
    @search="onSearchTermChange"
  />
</template>

<script>
import {debounce} from "@/helpers/debounceHelper";
import BrandRepository from "@/repositories/BrandRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "BrandSelectInput",
  components: {SelectElement},
  data: () => ({
    availableBrands: [],
    isLoading: false,

    preselectedBrandOption: null
  }),
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Marque',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(brandId) {
        // Selected Brand ID not null
        if (brandId === null || brandId === undefined) {
          return false;
        }

        // Selected Brand ID not in included in the results, so probably value was there
        // before rendering component
        // We load only the selected brand to show the brand name in the list.
        const selectedBrandLoaded = this.availableBrands.find(m => m.value === brandId) !== undefined;
        if (!selectedBrandLoaded) {
          this.isLoading = true;

          BrandRepository
            .getPaginated(1, 1, [], [{
              filterKey: 'brandIdsOrName',
              filterValue: brandId
            }], false)
            .then((res) => {
              if (res.data.data.length > 0) {
                this.preselectedBrandOption = res.data.data.map(m => {
                  return this.mapBrandObject(m);
                })[0];
              }
            }).finally(() => this.isLoading = false);
        }
      }
    }
  },
  created() {
    this.fetchBrands();
  },
  methods: {
    onSearchTermChange: debounce(function (q) {
      this.fetchBrands(q);
    }, 300),

    fetchBrands(searchTerm = "") {
      this.isLoading = true;
      let filters = [];

      if (searchTerm !== "") {
        filters.push({
          filterKey: 'brandIdsOrName',
          filterValue: searchTerm
        });
      }

      BrandRepository
        .getPaginated(999, 1, [], filters, false)
        .then((res) => {
          this.availableBrands = res.data.data;
        }).finally(() => this.isLoading = false);
    },
    mapBrandObject(b) {
      return {
        label: `#${b.id} ${b.name}`,
        value: b.id
      }
    }
  },
  computed: {
    getMappedBrands() {
      let brands = this.availableBrands.map(b => {
        return this.mapBrandObject(b)
      });

      // Add the preselected brand to the brand list if its not already inside.
      if (this.preselectedBrandOption !== null && !brands.find(m => m.value === this.preselectedBrandOption.value)) {
        brands.unshift(this.preselectedBrandOption);
      }

      return brands;
    }
  },
}
</script>

