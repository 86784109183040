<template>
  <div class="blog">
    <form-label
      :label="label"
    />

    <modal v-if="hasProvidedImages" ref="imageListSelection" show-close-button title="Images">
      <div class="flex gap-x-4 overflow-x-auto">
        <div
          v-for="image in getConfig.image_list"
          :key="image.url"
          class="flex flex-col items-center bg-gray-50 py-4 rounded-md"
        >
          <img :src="image.url"
               class="w-44 h-44 object-cover rounded-md border-4 border-luxcaddy-500 hover:shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
          <div class="italic tracking-tight text-gray-500 text-center w-80 mt-6">{{ image.title }}</div>
          <button-element
            class="mt-8"
            size="small"
            type="primary"
            @click="onImageInsert(image.url, image.title)"
          >
            Insérer
          </button-element>
        </div>
      </div>
    </modal>

    <validation-provider ref="validationProvider" v-slot="{ errors }" :name="getName" :rules="validationRules" slim>

      <editor
        ref="tinyMce"
        :api-key="getTinyMceApiKey"
        :init="getConfig"
        :value="value"
        @input="$emit('input', $event)"
      />

      <template v-if="occupyValidationSpace">
        <input-error-message v-if="!isInReadOnlyMode" :errors="errors"/>
        <div v-else class="h-4"></div>
      </template>

    </validation-provider>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import FormLabel from "@/components/elements/forms/elements/partials/FormLabel";
import {ValidationProvider} from 'vee-validate';
import InputErrorMessage from "@/components/elements/forms/elements/partials/InputErrorMessage";
import Modal from "@/components/elements/modals/Modal";
import ButtonElement from "@/components/elements/buttons/ButtonElement";


export default {
  name: "TinyMceEditorElement",
  components: {
    ButtonElement,
    Modal,
    InputErrorMessage,
    FormLabel,
    Editor,
    ValidationProvider
  },
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: String
    },
    validationRules: {
      type: String,
      required: false,
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    mergeCustomConfig: {
      type: Object
    }
  },
  methods: {
    isNullOrEmptyString(val) {
      return val === null || (typeof val === "string" && val === "");
    },
    onImageInsert(imageUrl, imageTitle) {
      this.$refs.tinyMce.editor.insertContent(
        `<img src="${imageUrl}" alt="${imageTitle}">`
      );
      this.$refs.imageListSelection.closeModal();
    }
  },
  computed: {
    getTinyMceApiKey() {
      return process.env.VUE_APP_TINYMCE_API_KEY;
    },

    getName() {
      if (this.id) {
        return this.id;
      }

      return this.label?.toLowerCase().replaceAll(' ', '-') || null;
    },
    hasProvidedImages() {
      if (this.getConfig.image_list === undefined) {
        return false;
      }
      if (Array.isArray(this.getConfig.image_list)) {
        if (this.getConfig.image_list.length === 0) {
          return false;
        }

        return true;
      }
      return false;
    },
    getConfig() {
      let vm = this;

      let config = {
        selector: 'textarea',
        height: 800,
        menubar: false,
        resize: 'both',
        convert_urls: false,
        body_class: 'blog',
        paste_data_images: true,
        block_unsupported_drop: false,
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: '',
        toolbar: [
          'styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link imageListCustom | removeformat visualblocks code',
          'simpleProductGroupButton expandableProductGroupButton | recipeResumeContainer'
        ],
        setup: function (e) {
          e.modalRef = vm.$refs.imageListSelection;

          e.ui.registry.addButton('imageListCustom', {
            icon: 'gallery',
            onAction: function () {
              if (vm.hasProvidedImages) {
                e.modalRef.openModal();
              } else {
                vm.$showErrorDialog("Vous n'avez pas téléchargé des images.");
              }
            }
          });

          e.ui.registry.addButton('simpleProductGroupButton', {
            text: 'Groupe produit simple',
            tooltip: 'Utilisée pour montrer le client un ou plusieurs produit il peut ajouter au panier',
            onAction: function () {
              let productIds = window.prompt("Veuiller entrer une ou multiple IDs des produits separée par un virgule:");
              if (vm.isNullOrEmptyString(productIds))
                return;
              const productIdsString = productIds.replaceAll(' ', '').split(',').flatMap(id => `[[${id}]]`).join('');
              e.insertContent(`{${productIdsString}}`);
            }
          });

          e.ui.registry.addButton('expandableProductGroupButton', {
            text: 'Groupe Produits nommé & dépliable',
            tooltip: 'Utilisée pour montrer le client une groupe des produits',
            onAction: function () {
              let groupName = window.prompt("Veuiller entrer le nom de la groupe:");
              if (vm.isNullOrEmptyString(groupName)) return;
              let productIds = window.prompt("Veuiller entrer les IDs des produits separée par un virgule:");
              if (vm.isNullOrEmptyString(productIds)) return;

              const productIdsString = productIds.replaceAll(' ', '').split(',').flatMap(id => `[[${id}]]`).join('');
              e.insertContent(`{${groupName}:${productIdsString}}`);
            }
          });

          e.ui.registry.addButton('recipeResumeContainer', {
            text: 'Container Recette',
            tooltip: 'Utiliser cette option si vous avez plusieurs boîtes de recettes que vous souhaitez afficher sur une seule ligne.',
            onAction: function () {
              let personcount = window.prompt("Veuillez entrer la nombre des personnes:");
              if (vm.isNullOrEmptyString(personcount)) return;

              let preptime = window.prompt("Veuillez indiquer le temps pour la préparation:");
              if (vm.isNullOrEmptyString(preptime)) return;

              let cookingtime = window.prompt("Veuillez indiquer le temps pour la cuisson:");
              if (vm.isNullOrEmptyString(cookingtime)) return;


              e.insertContent(`[recipe personcount="${personcount}" preptime="${preptime}" cookingtime="${cookingtime}"]`);
            }
          });
        },
        content_style: 'body,html { padding: 10px }',
        style_formats: [
          {title: 'H1', block: 'h1'},
          {title: 'H2', block: 'h2'},
          {title: 'Quote', block: 'blockquote'},
          {
            title: 'Image', items: [
              {title: 'Ronde', selector: 'img', classes: 'round'},
              {
                title: 'Aligner gauche avec espaces',
                selector: 'img',
                classes: 'imgFloatLeft marginBottom marginRight'
              },
              {title: 'Aligner gauche', selector: 'img', classes: 'imgFloatLeft'},
              {title: 'Aligner droite', selector: 'img', classes: 'imgFloatRight'},
              {title: 'Espace haut', selector: 'img', classes: 'marginTop'},
              {title: 'Espace bas', selector: 'img', classes: 'marginBottom'},
              {title: 'Espace gauche', selector: 'img', classes: 'marginLeft'},
              {title: 'Espace droite', selector: 'img', classes: 'marginRight'},
            ]
          },
        ],
        content_css: '/tinyMceContentCss.css',
        plugins: [
          'link image lists code visualblocks',
        ]
      };

      if (this.mergeCustomConfig) {
        config = {...config, ...this.mergeCustomConfig};
      }

      return config;
    },
    isInReadOnlyMode() {
      return this.$isReadOnly();
    },
  },
}
</script>

<style>
/* Remove dumb tiny cloud message */
.tox-notification--warning {
  display: none !important;
}
</style>