<template>
  <div
    :class="[
            color === 'gray' ? 'bg-gray-200' : 'bg-white',
            disablePadding ? '' : 'p-4'
        ]"
  >
    <div v-if="loading" class="flex justify-center">
      <loading-svg-inline class="w-24 h-24"/>
    </div>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline";

export default {
  name: "TouchInterfaceCardWithLoadingIndicator",
  components: {LoadingSvgInline},
  props: {
    loading: Boolean,
    color: {
      type: String,
      default: 'gray',
      validator: val => ['gray', 'white'].includes(val)
    },
    disablePadding: Boolean,
  }
}
</script>

<style scoped>

</style>