import auth from "@/router/middlewares/authMiddleware";
import ShowProductCatalogCountList from "@/components/pages/SupplierProductCatalogCount/ShowProductCatalogCountList";
import ShowSupplierProductCatalog from "@/components/pages/SupplierProductCatalog/ShowSupplierProductCatalog";
import ShowSupplierPriceComparison from "@/components/pages/SupplierPriceComparison/ShowSupplierPriceComparison";

export default [
  {
    path: '/supplier-catalog',
    name: 'supplier.catalog.count-list',
    component: ShowProductCatalogCountList,
    meta: {
      middleware: [auth],
      title: 'Import Catalogue',
      permissions: ['SUPPLIER_CATALOG_READ']
    }
  },
  {
    path: '/supplier-catalog-products',
    name: 'supplier.catalog',
    component: ShowSupplierProductCatalog,
    meta: {
      middleware: [auth],
      title: 'Listes produits fournisseurs',
      permissions: ['SUPPLIER_CATALOG_READ']
    }
  },
  {
    path: '/supplier-catalog-price-comparison',
    name: 'supplier.catalog.price-comparison',
    component: ShowSupplierPriceComparison,
    meta: {
      middleware: [auth],
      title: 'Vérification prix',
      permissions: ['SUPPLIER_CATALOG_READ']
    }
  },
]