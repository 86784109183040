<template>
  <div>
    <page-title>
      Créer tâche récurrente
    </page-title>

    <card>
      <create-or-update-recurring-task-form
        mode="create"
      />
    </card>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import CreateOrUpdateRecurringTaskForm from "@/components/pages/TasksRecurring/Form/CreateOrUpdateRecurringTaskForm";

export default {
  name: "EditRecurringTask",
  components: {CreateOrUpdateRecurringTaskForm, Card, PageTitle},
}
</script>

<style scoped>

</style>