<template>
  <div>
    <form-wrapper
      v-if="loaded"
      ref="form"
      :show-changed="true"
      :submit-button-label="getSubmitButtonLabel"
      @submit="onSubmit"
    >

      <form-grid class="grid-cols-1 lg:grid-cols-4">
        <select-element
          v-model="assignedToMemberId"
          :options="getEmployeeOptions"
          label="Pour employé"
        />

        <select-element
          v-model="assignedToBackupMemberId"
          :options="getEmployeeOptions"
          label="Pour employé si congé"
        />

        <text-input-element
          v-model="title"
          class="lg:col-span-2"
          label="Titre"
          validation-rules="required"
        />

        <text-area-element
          v-model="description"
          class="lg:col-span-4"
          label="Description"
        />

        <select-element
          v-model="dueOnWeekdays"
          :enable-search="false"
          :options="getWeekdaysForSelect"
          label="Due tue les"
          multi
          validation-rules="required"
        />

        <number-input-element
          v-model="frequencyInWeeks"
          inset-text="semaines"
          label="Périodicité"
          validation-rules="required"
        />

        <number-input-element
          v-model="daysToComplete"
          inset-text="jours"
          label="Délais pour accomplir"
          validation-rules="required"
        />

        <date-picker-element
          v-model="startOn"
          label="Injecter à partir de"
        />

      </form-grid>
    </form-wrapper>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper";
import FormGrid from "@/components/elements/forms/FormGrid";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";
import MemberRepository from "@/repositories/MemberRepository";
import TaskRecurringRepository from "@/repositories/TaskRecurringRepository";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "CreateOrUpdateRecurringTaskForm",
  components: {
    SelectElement,
    NumberInputElement,
    DatePickerElement,
    TextAreaElement,
    TextInputElement,
    FormGrid,
    FormWrapper
  },
  props: {
    mode: {default: 'create', validator: val => ['create', 'update'].includes(val)},
    recurringTaskId: {type: Number}
  },
  data: () => ({
    assignedToMemberId: null,
    assignedToBackupMemberId: null,
    title: '',
    description: null,
    dueOnWeekdays: [],
    frequencyInWeeks: 1,
    daysToComplete: 1,
    startOn: null,


    loaded: false,
    employees: []
  }),
  created() {
    this.fetchEmployees();

    if (this.isUpdateMode) {
      this.fetchTask();
    } else {
      this.loaded = true;
    }
  },
  watch: {
    title: function (val) {
      this.$emit('updateTitle', val);
    }
  },
  methods: {
    fetchTask() {
      this.loaded = false;

      TaskRecurringRepository
        .getSingle(this.recurringTaskId)
        .then((res) => {
          const task = res.data.data;

          this.assignedToMemberId = task.assignedToMemberId;
          this.assignedToBackupMemberId = task.assignedToBackupMemberId;
          this.title = task.title;
          this.description = task.description;
          this.dueOnWeekdays = task.dueOnWeekdays;
          this.frequencyInWeeks = task.frequencyInWeeks;
          this.daysToComplete = task.daysToComplete;
          this.startOn = task.startOn;
        }).finally(() => {
        this.loaded = true;
        this.$nextTick(() => {
          this.$ref.form.resetForm()
        })
      });
    },
    fetchEmployees() {
      MemberRepository
        .getAllEmployees()
        .then(res => this.employees = res.data.data)
    },

    updateTask(callback) {
      TaskRecurringRepository.update(
        this.recurringTaskId,
        this.getPayload
      ).then(() => {
        this.$sendSuccessToast("La tâche récurrente a été modifiée.");
        this.$refs.form.resetForm();
      }).finally(() => callback());
    },

    createTask(callback) {
      TaskRecurringRepository.create(
        this.getPayload
      ).then((res) => {
        this.$sendSuccessToast("La tâche récurrente a été crée.");
        this.$refs.form.resetForm();
        this.$router.push({
          name: 'taskRecurring.edit',
          params: {
            task: res.data.data.id,
          }
        })
      }).finally(() => callback());
    },

    onSubmit(callback) {
      return this.isUpdateMode ? this.updateTask(callback) : this.createTask(callback);
    }
  },
  computed: {
    isUpdateMode() {
      return this.mode === "update";
    },
    getSubmitButtonLabel() {
      return this.isUpdateMode ? "Sauvegarder" : "Créer";
    },

    getPayload() {
      return {
        assignedToMemberId: this.assignedToMemberId,
        assignedToBackupMemberId: this.assignedToBackupMemberId,
        title: this.title,
        description: this.description,
        dueOnWeekdays: this.dueOnWeekdays,
        frequencyInWeeks: this.frequencyInWeeks,
        daysToComplete: this.daysToComplete,
        startOn: this.startOn,
      };
    },

    getEmployeeOptions() {
      return this.employees.map(e => {
        return {
          label: e.fullName,
          value: e.id
        }
      });
    },

    getWeekdaysForSelect() {
      return [
        {label: 'Lundi', value: 1},
        {label: 'Mardi', value: 2},
        {label: 'Mercredi', value: 3},
        {label: 'Jeudi', value: 4},
        {label: 'Vendredi', value: 5},
        {label: 'Samedi', value: 6},
      ]
    }
  },
}
</script>