<template>
  <create-update-vehicle-form
    :vehicle-id="getVehicleId"
    mode="update"
  />
</template>

<script>
import CreateUpdateVehicleForm from "@/components/pages/Vehicles/Forms/CreateUpdateVehicleForm.vue";

export default {
  name: "UpdateVehicle",
  components: {CreateUpdateVehicleForm},
  computed: {
    getVehicleId() {
      return Number(this.$route.params.id);
    }
  },
}
</script>

<style scoped>

</style>