<!--
    Provides a button which on click fetches a list of additional data (currently ingredients) from external services and gives
    the user the ability to apply each localization
-->
<template>
  <div>
    <modal ref="localizationManageModal" disable-padding title="Remplacer ingrédients">
      <custom-table :headings="getTableHeadings">
        <table-row
          v-for="lang in getLanguages"
          :key="lang.value"
        >
          <table-data-cell>
            <span class="font-bold">{{ lang.label }}</span>
          </table-data-cell>

          <table-data-cell style="max-width: 400px">
                        <span :class="{'text-luxcaddy-600': !isExternalIngredientSelectedForLanguage(lang.value)}">
                            {{ getExistingIngredient(lang.value) }}
                        </span>
          </table-data-cell>

          <table-data-cell style="max-width: 400px">
                        <span :class="{'text-luxcaddy-600': isExternalIngredientSelectedForLanguage(lang.value)}">
                            {{ getExternalIngredient(lang.value) }}
                        </span>
          </table-data-cell>

          <table-data-cell>
            <checkbox-element
              :occupy-validation-space="false"
              :value="isExternalIngredientSelectedForLanguage(lang.value)"
              label="Utiliser ingrédients du service externe"
              @input="toggleUseExternalIngredient(lang.value, $event)"
            />
          </table-data-cell>
        </table-row>

        <table-row>
          <table-data-cell colspan="3"></table-data-cell>
          <table-data-cell>
            <div class="flex justify-end">
              <button-element type="primary" @click="applyIngredientSelection">
                Accepter
              </button-element>
            </div>
          </table-data-cell>
        </table-row>
      </custom-table>
    </modal>

    <div class="flex justify-center">
      <button-element type="light" @click="fetchIngredients">
        <fa-icon class="mr-2" fixed-width icon="download"/>
        Télécharger des ingrédients à partir de services externes
      </button-element>
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import ProductRepository from "@/repositories/ProductRepository";
import Modal from "@/components/elements/modals/Modal";
import CustomTable from "@/components/elements/tables/CustomTable";
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";

export default {
  name: "ProductLocalizationApiMerge",
  components: {CheckboxElement, TableDataCell, TableRow, CustomTable, Modal, ButtonElement},
  data: () => ({
    externalIngredients: [],

    saveExternalIngredientsForLanguages: [],
  }),
  props: {
    productId: {
      type: Number,
      required: true
    },
    existingLocalizations: {
      type: Object,
      required: true
    }
  },
  methods: {
    fetchIngredients() {
      ProductRepository.getProductDataFromApi(this.productId).then((res) => {
        const data = res.data.data;

        if (data?.ingredients) {
          this.externalIngredients = data.ingredients;
          this.$refs.localizationManageModal.openModal();
        } else {
          this.$showErrorDialog("On a pas trouvé des ingrédients pour ce produit. Avez-vous déjà ajouté des codes-barres ?");
        }
      });
    },
    applyIngredientSelection() {
      let localizations = {...this.existingLocalizations};

      this.saveExternalIngredientsForLanguages.forEach(l => {
        if (localizations[l] && (localizations[l].ingredients !== undefined)) {
          localizations[l].ingredients = this.externalIngredients[l];
        }
      });

      this.$emit('update:existingLocalizations', localizations);
      this.$emit('reload');
      this.$refs.localizationManageModal.closeModal();
      this.$sendSuccessToast('Ingrédients repris avec succès. N\'oublie pas de sauvegarder.');
    },
    getExistingIngredient(language) {
      return this.existingLocalizations[language]?.ingredients || null;
    },
    getExternalIngredient(language) {
      return this.externalIngredients[language] || null;
    },
    toggleUseExternalIngredient(lang, bool) {
      if (bool) {
        this.saveExternalIngredientsForLanguages.push(lang);
      } else {
        this.saveExternalIngredientsForLanguages = this.saveExternalIngredientsForLanguages.filter(l => l !== lang);
      }
    },
    isExternalIngredientSelectedForLanguage(lang) {
      return this.saveExternalIngredientsForLanguages.filter(l => l === lang).length > 0;
    }
  },
  computed: {
    getTableHeadings() {
      return [
        {label: 'Langue'},
        {label: 'Ingrédients actuelles'},
        {label: 'Ingrédients service externe'},
        {label: 'Actions'},
      ];
    },
    getLanguages() {
      return this.$luxcaddyConfig('supportedLocalizationLanguages');
    }
  },
}
</script>

<style scoped>

</style>