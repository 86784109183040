import auth from "../middlewares/authMiddleware";

import ShowReportQueries from "@/components/pages/ReportQueries/ShowReportQueries.vue";
import CreateReportQuery from "@/components/pages/ReportQueries/Create/CreateReportQuery.vue";
import EditReportQuery from "@/components/pages/ReportQueries/Edit/EditReportQuery.vue";
import ExecuteReportQuery from "@/components/pages/ReportQueries/Execute/ExecuteReportQuery.vue";

export default [
  {
    path: '/reportQueries',
    name: 'reportQueries.list',
    component: ShowReportQueries,
    meta: {
      middleware: [auth],
      title: 'Liste des requête',
      // This is an exception, list should not be visible to users with only read permission
      permissions: ['REPORT_QUERY_MANAGE']
    }
  },
  {
    path: '/execute-report-query',
    name: 'reportQueries.execute',
    component: ExecuteReportQuery,
    meta: {
      middleware: [auth],
      title: 'Exécuter un rapport',
      permissions: ['REPORT_QUERY_EXECUTE']
    }
  },
  {
    path: '/reportQueries/:reportQueryId/edit',
    name: 'reportQueries.edit',
    component: EditReportQuery,
    meta: {
      middleware: [auth],
      title: 'Modifier une requête',
      readOnly: {supported: true, title: 'Voir un requête'},
      breadcrumbs: ['reportQueries.list'],
      permissions: ['REPORT_QUERY_MANAGE']
    }
  },
  {
    path: '/reportQueries/create',
    name: 'reportQueries.create',
    component: CreateReportQuery,
    meta: {
      middleware: [auth],
      title: 'Créé une requête',
      breadcrumbs: ['reportQueries.list'],
      permissions: ['REPORT_QUERY_MANAGE']
    }
  },
];