import auth from "../middlewares/authMiddleware";

import ShowInternalInvoices from "@/components/pages/Invoices/Internal/ShowInternalInvoices";
import CreateInternalInvoice from "@/components/pages/Invoices/Internal/CreateInvoice/CreateInternalInvoice";
import EditInternalInvoice from "@/components/pages/Invoices/Internal/EditInternalInvoice";

export default [
  {
    path: '/invoices',
    name: 'invoices.list',
    component: ShowInternalInvoices,
    meta: {
      middleware: [auth],
      title: 'Liste des factures',
      permissions: ['INTERNAL_INVOICE_READ']
    }
  },
  {
    path: '/invoices/create',
    name: 'invoices.create',
    component: CreateInternalInvoice,
    meta: {
      middleware: [auth],
      title: 'Créer une facture',
      breadcrumbs: ['invoices.list'],
      permissions: ['INTERNAL_INVOICE_WRITE']
    }
  },
  {
    path: '/invoices/:id',
    name: 'invoices.edit',
    component: EditInternalInvoice,
    meta: {
      middleware: [auth],
      readOnly: {
        supported: true,
        title: 'Voir une facture',
        permissions: ['INTERNAL_INVOICE_READ']
      },
      title: 'Modifier facture',
      breadcrumbs: ['invoices.list'],
      permissions: ['INTERNAL_INVOICE_WRITE']
    }
  },
];