import apiService from './Base/BaseRepository';

const url = '/tasks';

export default {
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getSingle(taskId) {
    return await apiService.get(`${url}/${taskId}`)
  },

  async update(taskId, values) {
    return await apiService.patch(`${url}/${taskId}`, values)
  },

  async create(values) {
    return await apiService.post(`${url}/`, values)
  },

  async deleteSingle(taskId) {
    return await apiService.delete(`${url}/${taskId}`)
  },
}
