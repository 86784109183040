<template>
  <div>
    <page-title>Catégories produits virtuel</page-title>

    <show-categories
      is-virtual
      type="Product"
    />
  </div>
</template>

<script>

import ShowCategories from "@/components/elements/categoryManagement/ShowCategories";
import PageTitle from "@/components/elements/pages/PageTitle";

export default {
  name: "ShowProductCategoriesVirtual",
  components: {
    PageTitle,
    ShowCategories
  },
}
</script>

