<template>
  <select-element
    :enable-search="false"
    :label="label"
    :options="getTags"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "ListTableTagTypeSelection",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Type tag',
      required: false,
    },
  },
  computed: {
    getTags() {
      return [
        {label: 'Allergène', value: 'allergen'},
        {label: 'Labelle', value: 'label'},
      ];
    }
  }

}
</script>

