<template>
  <form-wrapper ref="form" :show-changed="true" :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer vidange'"
                @submit="createOrUpdateDeposit">

    <div class="grid grid-cols-12 gap-y-4 md:gap-x-8">
      <text-input-element v-model="name" class="col-span-12 lg:col-span-6 xl:col-span-5"
                          label="Nom de le vidange"
                          validation-rules="required">
      </text-input-element>
    </div>
    <currency-input-element v-model.number="price" label="Valeur"
                            validation-rules="required"></currency-input-element>

  </form-wrapper>
</template>

<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import DepositRepository from "../../../../repositories/DepositRepository";
import CurrencyInputElement from "../../../elements/forms/elements/CurrencyInputElement";

export default {
  name: "CreateOrUpdateDepositForm",
  components: {
    TextInputElement,
    FormWrapper,
    CurrencyInputElement
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    }
  },
  data: () => ({
    name: '',
    price: ''
  }),
  mounted() {
    this.getDeposit();
  },
  watch: {
    name: function (val) {
      this.$emit('updateName', val);
    }
  },
  methods: {
    getDeposit() {
      if (this.isUpdateMode) {
        DepositRepository.getSingle(this.$route.params.deposit).then((res) => {
          this.name = res.data.data.name;
          this.price = res.data.data.price.amount
        }).then(() => this.$refs.form.resetForm());
      }
    },
    createOrUpdateDeposit(callback) {
      if (this.isUpdateMode) {
        return DepositRepository.update(this.$route.params.deposit, {
          name: this.name,
          amount: this.price
        }).then(() => {
          this.$sendSuccessToast(`Vidange ${this.name} a été modifié!`);
        }).finally(() => callback());
      }

      return DepositRepository.create({
        name: this.name,
        amount: this.price
      }).then((res) => {
        this.$router.push({name: 'deposits.edit', params: {deposit: res.data.data.id}});
        this.$sendSuccessToast(`Vidange ${this.name} a été crée!`);
      }).finally(() => callback());
    },
  },
  computed: {
    isUpdateMode() {
      return this.mode === 'update';
    },
    getTitle() {
      if (this.isUpdateMode) {
        return 'Modifier vidange';
      }
      return 'Créer nouvelle vidange';
    }
  }
}
</script>

