<template>
  <modal ref="barcodeModal" :blocking="!(mode === 'show')" title="Code barre"
         @close="onModalClose" @closeButtonPressed="closeModal">

    <form-wrapper
      ref="form"
      :show-changed="false"
      :show-submit-button="mode !== 'show'"
      :submit-button-label="mode === 'create' ? 'Créer code barre' : 'Sauvegarder code barre'"
      @submit="createOrUpdateBarCode"
    >

      <form-grid v-if="barcodeLoaded">
        <text-input-element v-model="barcode.code" label="Code barre"></text-input-element>
        <text-input-element v-model="barcode.comment" label="Commentaire"></text-input-element>
      </form-grid>

      <template v-slot:additionalButtons>
        <button-element class="float-right" type="light" @click="closeModal(true)">Annuler</button-element>
      </template>

    </form-wrapper>
  </modal>
</template>

<script>
import Modal from "../../../../elements/modals/Modal";
import FormWrapper from "../../../../elements/forms/FormWrapper";
import FormGrid from "../../../../elements/forms/FormGrid";
import ButtonElement from "../../../../elements/buttons/ButtonElement";
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";
import ProductRepository from "../../../../../repositories/ProductRepository";

export default {
  name: "CreateOrUpdateProductBarcodeForm",
  components: {TextInputElement, ButtonElement, FormGrid, FormWrapper, Modal},
  data: () => ({
    barcode: {
      code: '',
      comment: ''
    },
    readOnlyBeforeModalOpen: false,
  }),
  props: {
    mode: {type: String, default: ''},
    productId: {type: Number},
    barcodeId: {type: Number},
  },
  watch: {
    barcodeId(id) {
      if (['update'].includes(this.mode) && id !== null) {
        ProductRepository.barcodes.getSingle(this.productId, this.barcodeId).then(res => {
          this.barcode = res.data.data;
        }).finally(() => {
          this.$nextTick(() => this.$refs.form.resetForm());
        })
      } else {
        this.barcode = {
          code: '',
          comment: ''
        };
      }
    },
  },
  methods: {
    createOrUpdateBarCode(callback) {
      if (this.isUpdateMode) {
        ProductRepository.barcodes.update(this.productId, this.barcodeId, this.barcode).then(() => {
          this.$sendSuccessToast('Code barre sauvegardé!');
          this.$emit('change');
          this.resetForm();
          this.closeModal();
        }).finally(() => callback());
      } else {
        ProductRepository.barcodes.create(this.productId, this.barcode).then(() => {
          this.$sendSuccessToast('Code barre crée!');
          this.$emit('change');
          this.resetForm();
          this.closeModal();
        }).finally(() => callback());
      }
    },
    closeModal(confirmIfChange = false) {
      if (confirmIfChange) {
        if (this.anyFormInputHasChanged) {
          if (!window.confirm("Êtes-vous sûr de vouloir annuler vos modifications?"))
            return false;
        }
      }

      this.$emit('update:mode', '');
      this.$refs.barcodeModal.closeModal();
    },
    onModalOpen() {
      if (this.isUpdateMode) {
        ProductRepository.barcodes.getSingle(this.productId, this.barcodeId).then(res => {
          this.barcode = res.data.data;
        })
      }
    },
    onModalClose() {
      this.$emit('modalClosed');
    },
    resetForm() {
      this.$refs.form.resetForm();
    }
  },
  computed: {
    anyFormInputHasChanged() {
      return this.$refs.form.hasUnsavedChanges;
    },
    isUpdateMode() {
      return this.mode === 'update';
    },
    barcodeLoaded() {
      if (this.isUpdateMode) {
        return this.barcode.code !== '';
      }
      return true;
    }
  }
}
</script>

