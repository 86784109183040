<template>
  <div class="flex items-center gap-x-2">
    <span class="text-2xl font-bold">N°</span>
    <text-input-element
      :disabled="true"
      :occupy-validation-space="false"
      :value="getFulfilmentNumber"
      additional-input-classes="bg-gray-100 cursor-not-allowed text-3xl text-gray-700 font-semibold"
      class="flex-grow"
    />
    <touch-optimized-button
      :disabled="getFulfilmentNumber !== null"
      class="min-w-min p-3"
      padding="py-2"
      type="dark"
      @click="attributeFulfilmentNumber"
    >
      SET
    </touch-optimized-button>
  </div>
</template>

<script>
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "OrderFulfilmentAttributeFulfilmentNumber",
  components: {TouchOptimizedButton, TextInputElement},
  computed: {
    ...mapGetters('fulfilment', [
      'getFulfilmentNumber'
    ])
  },
  methods: {
    ...mapActions('fulfilment', [
      'attributeFulfilmentNumber'
    ])
  },
}
</script>

