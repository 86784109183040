import apiService from './Base/BaseRepository';

const url = '/languages';

export default {

  async get() {
    return await apiService.get(`${url}/`);
  }
}
