<template>
  <div class="flex gap-x-2 | items-start">
    <div class="flex flex-col justify-center items-center gap-y-0.5">
      <toggle-element v-model="isPublished" :hex-color="color" small/>
      <span class="text-xs">Publié</span>
    </div>

    <div class="flex flex-col justify-center items-center gap-y-0.5">
      <toggle-element v-model="areDeliveriesVisible" :hex-color="color" small/>
      <span class="text-xs">Visible</span>
    </div>

    <div class="flex flex-col justify-center items-center gap-y-0.5">
      <toggle-element v-model="isTracked" :hex-color="color" small/>
      <span class="text-xs">Tracking</span>
    </div>

    <div class="flex flex-col justify-center items-center gap-y-0.5">
      <toggle-element v-model="isDriverDistanceCheckEnabled" :hex-color="color" small/>
      <span class="text-xs">Stricte</span>
    </div>


    <button-with-menu-element
      :actions="getActionLinks"
      button-text="Actions"
      class="mt-0.5"
      close-after-action-executed
      size="xsmall"
      small-menu
      stick-direction="right"
      type="routeManager"
    />
  </div>
</template>

<script>
import ButtonWithMenuElement from "@/components/elements/buttons/ButtonWithMenuElement";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ToggleElement from "@/components/elements/toggles/ToggleElement.vue";
import RouteRepository from "@/repositories/RouteRepository";
import {exportResponse} from "@/helpers/exportHelper";

export default {
  name: "RouteManagerRouteBlockActions",
  components: {ToggleElement, ButtonWithMenuElement},
  props: {
    routeId: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('routeManager', [
      'getTrackedRouteId',
      'isRoutePublished',
      'hasRouteAnyDeliveries',
      'isDriverDistanceCheckEnabledForRoute',
    ]),


    areDeliveriesVisible: {
      get() {
        return !this.$store.getters["routeManager/areAllDeliveriesOfRouteHidden"](this.routeId) || !this.hasRouteAnyDeliveries(this.routeId);
      },
      set(bool) {
        if (bool) {
          this.showAllDeliveriesOfRoute(this.routeId);
        } else {
          this.hideAllDeliveriesOfRoute(this.routeId);
        }
      }
    },

    isTracked: {
      get() {
        return this.routeId === this.getTrackedRouteId;
      },
      set(bool) {
        if (bool) {
          this.setTrackedRouteId(this.routeId);
        } else {
          this.setTrackedRouteId(null);
          this.setTrackingPoints([]);
        }
      }
    },

    isPublished: {
      get() {
        return this.isRoutePublished(this.routeId);
      },
      set(bool) {
        if (bool) {
          this.publishRoute(this.routeId)
        } else {
          if (!window.confirm("Êtes-vous certain?")) {
            return false;
          }
          this.unpublishRoute(this.routeId);
        }
      }
    },


    isDriverDistanceCheckEnabled: {
      get() {
        return this.isDriverDistanceCheckEnabledForRoute(this.routeId);
      },
      set(bool) {
        return bool
          ? this.enableDriverDistanceTresholdCheck(this.routeId)
          : this.disableDriverDistanceTresholdCheck(this.routeId);
      }
    },

    getActionLinks() {
      let links = [
        {
          label: 'Ajouter à cette route',
          action: () => {
            this.addSelectedDeliveriesToRoute(this.routeId);
          }
        },
        {
          label: 'Supprimer',
          action: () => {
            if (!window.confirm("Êtes-vous certain?")) {
              return false;
            }
            this.deleteRoute(this.routeId);
          },
        },
        {
          label: 'Print',
          action: () => {
            this.printRoute(this.routeId);
          },
        },
        {
          label: 'Télécharger PDF',
          action: () => {
            this.downloadRoutePdf(this.routeId);
          },
        },
        {
          label: 'Envoyer méssage',
          action: () => {
            this.navigateToSendMessagePage();
          }
        },
      ];

      return links;
    },
  },
  methods: {
    ...mapActions('routeManager', [
      'deleteRoute',
      'addSelectedDeliveriesToRoute',
      'publishRoute',
      'unpublishRoute',
      'printRoute',
      'enableDriverDistanceTresholdCheck',
      'disableDriverDistanceTresholdCheck',
    ]),
    ...mapMutations('routeManager', [
      'setTrackedRouteId',
      'setTrackingPoints',
      'hideAllDeliveriesOfRoute',
      'showAllDeliveriesOfRoute',
    ]),

    downloadRoutePdf(routeId) {
      return RouteRepository.downloadRoutePdf(routeId)
        .then(res => {
          exportResponse(res, `route-${routeId}`, 'pdf');
        });
    },

    navigateToSendMessagePage() {
      const route = this.$store.getters['routeManager/getRouteById'](this.routeId);
      const employeeId = route.driverMemberId;

      if (!employeeId) {
        window.alert("Un chauffeur doit être assigné au route.")
        return;
      }

      this.$store.commit('userInterface/setSidebarState', true);

      return this.$router.push({
        name: 'members.notifications.send',
        query: {
          memberId: employeeId,
          template_id: 'CALL_ME'
        }
      })
    }
  },
}
</script>

