<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :disabled="disabled"
    :enable-search="!onlyProductSuppliers"
    :is-loading="isLoading"
    :label="label"
    :live-search="false"
    :occupy-validation-space="occupyValidationSpace"
    :options="getMappedMembers"
    :validation-rules="validationRules"
    :value="value"
    :multi="multi"
    @input="onInput"
    @search="onSearchTermChange"
  />
</template>

<script>
import {debounce} from "@/helpers/debounceHelper";
import MemberRepository from "@/repositories/MemberRepository";
import SupplierRepository from "@/repositories/SupplierRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "MemberSelectInput",
  components: {SelectElement},
  data: () => ({
    availableMembers: [],
    isLoading: false,

    preselectedMemberOption: null
  }),
  watch: {
    value: {
      immediate: true,
      handler(memberId) {
        // Selected Member ID not null
        if (memberId === null || memberId === undefined) {
          return false;
        }

        // Selected Member ID not in included in the results, so probably value was there
        // before rendering component
        // We load only the selected member to show the members name in the list.
        const selectedMemberLoaded = this.getMappedMembers.find(m => m.value === memberId) !== undefined;
        if (!selectedMemberLoaded) {
          this.isLoading = true;
          MemberRepository
            .getPaginatedForSelect(
              1,
              1,
              [],
              [{
                filterKey: 'idOrName',
                filterValue: memberId
              }],
              false
            )
            .then((res) => {
              if (res.data.data.length > 0) {
                this.preselectedMemberOption = res.data.data.map(m => {
                  return this.formatMemberObject(m);
                })[0];
              }
            }).finally(() => {
            this.isLoading = false;
          })
        }
      }
    }
  },
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Membre',
      required: false,
    },
    // If set, the result will be filtered with the following member types.
    onlyMemberTypes: {
      type: Array,
      default: () => [],
      // One or multiple of ["user", "customer", "employee", "supplier"]
    },
    onlyActiveMemberTypes: {
      type: Boolean,
      default: false
    },
    validationRules: {
      type: String,
      required: false,
    },
    onlyProductSuppliers: {
      type: Boolean,
      default: false
    },
    onlyActiveProductSuppliers: {
      type: Boolean,
      default: false
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true,
    },
    displayMemberVat: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowUnselecting: {
      type: Boolean,
      default: true
    },
    forceInitialLoad: {
      type: Boolean,
      default: false
    },
    multi: {
        type: Boolean,
        default: false
    },
  },
  created() {
    if (this.forceInitialLoad) {
      this.fetchMembers();
    }
  },
  methods: {
    onSearchTermChange: debounce(function (q) {
      this.fetchMembers(q);
    }, 300),

    onInput(value) {
      const member = this.availableMembers.find(m => m.id === value);
      this.$emit('selected', member === undefined ? null : member);


      this.$emit('input', value);

      if (!this.preselectedMemberId) {
        this.preselectedMemberId = null;
      }
    },

    fetchMembers(searchTerm = "") {
      let filters = [];

      // Search Filter
      if (searchTerm !== "") {
        filters.push({
          filterKey: 'idOrName',
          filterValue: searchTerm
        });
      }

      // Member Type Filter
      if (this.onlyMemberTypes.length) {
        let memberTypeFilterValue = this.onlyMemberTypes.join(",");

        if (this.onlyActiveMemberTypes) {
          filters.push({
            filterKey: 'activeMemberTypes',
            filterValue: memberTypeFilterValue
          })
        } else {
          filters.push({
            filterKey: 'memberTypes',
            filterValue: memberTypeFilterValue
          })
        }

      }

      // Product Suppliers only
      if (this.onlyProductSuppliers) {
        return SupplierRepository.getAllProductSuppliers(searchTerm)
          .then((res) => {
            this.availableMembers = res.data.data;
          });
      }

      // Active Product Suppliers only
      if (this.onlyActiveProductSuppliers) {
        return SupplierRepository.getActiveProductSuppliers(searchTerm)
          .then((res) => {
            this.availableMembers = res.data.data;
          });
      }

      this.isLoading = true;
      MemberRepository
        .getPaginatedForSelect(100, 1, [], filters, false)
        .then((res) => {
          this.availableMembers = res.data.data;
        }).finally(() => {
        this.isLoading = false;
      })
    },

    formatMemberObject(m) {
      const name = m.fullName ? m.fullName : m.name;
      let vat = '';
      if (this.displayMemberVat) {
        vat = m.vatNumber ? '(' + m.vatNumber + ')' : '';
      }
      const id = m.id ? m.id : m.memberId;

      return {
        label: `#${id} ${name} ${vat}`,
        value: id
      }
    }
  },
  computed: {
    getMappedMembers() {
      let members = this.availableMembers.map(m => {
        return this.formatMemberObject(m);
      });

      // Add the preselected member to the member list if its not already inside.
      if (this.preselectedMemberOption !== null && !members.find(m => m.value === this.preselectedMemberOption.value)) {
        members.unshift(this.preselectedMemberOption);
      }

      return members;
    }
  },
}
</script>

