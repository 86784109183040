<template>
  <div
    v-if="amount !== null"
    :class="{'text-red-500': isNegative}"
    class="whitespace-nowrap"
  >
    <span v-if="isEUR">{{ amount | centsToEuroString }}</span>
    <span v-else-if="isUSD">{{ amount | centsToDollarString }}</span>
  </div>
</template>

<script>
export default {
  name: "MoneyAmount",
  props: {
    amount: {
      type: [Number, String],
      default: null
    },
    currency: {
      type: String,
      default: 'EUR',
      validator: val => ['EUR', 'USD'].includes(val)
    },
  },
  computed: {
    isNegative() {
      return this.amount < 0;
    },
    isEUR() {
      return this.currency === "EUR";
    },
    isUSD() {
      return this.currency === "USD";
    }
  },
}
</script>

<style scoped>

</style>