<template>
  <div>
    <form-wrapper
      ref="form"
      :show-changed="true"
      :submit-button-label="isUpdateMode ? 'Sauvegarder' : 'Créer rôle'"
      @submit="createOrUpdateRole"
    >
      <div class="flex gap-x-4 flex-wrap">
        <text-input-element
          v-model="name"
          class="w-full lg:w-64"
          label="Nom du rôle"
          validation-rules="required"
        />

        <number-input-element
          v-model="level"
          label="Level"
          validation-rules="required"
        />

        <permission-select-input
          v-model="permissions"
          by-name
          class="w-full"
          label="Autorisations"
        />

      </div>
    </form-wrapper>
  </div>
</template>
<script>
import FormWrapper from "../../../elements/forms/FormWrapper";
import TextInputElement from "../../../elements/forms/elements/base/TextInputElement";
import RoleRepository from "@/repositories/RoleRepository";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement.vue";
import PermissionSelectInput from "@/components/elements/forms/elements/PermissionSelectInput.vue";

export default {
  name: "CreateOrUpdateRoleForm",
  components: {
    PermissionSelectInput,
    NumberInputElement,
    FormWrapper,
    TextInputElement,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['create', 'update'].includes(val)
    }
  },
  data() {
    return {
      name: null,
      level: null,
      permissions: [],
    }
  },
  mounted() {
    this.getRole()
  },
  watch: {
    name: function (val) {
      this.$emit('updateName', val);
    }
  },
  methods: {
    getRole() {
      if (this.isUpdateMode) {
        RoleRepository.getSingle(this.$route.params.roleId).then((res) => {
          this.name = res.data.data.name;
          this.level = res.data.data.level;
          this.permissions = res.data.data.permissions;
        }).then(() => this.$refs.form.resetForm());
      }
    },
    createOrUpdateRole(callback) {
      if (this.isUpdateMode) {
        return RoleRepository.update(this.$route.params.roleId, {
          name: this.name,
          level: this.level,
          permissions: this.permissions,
        }).then(() => {
          this.$sendSuccessToast(`Rôle ${this.name} a été modifiée!`);
        }).finally(() => callback());
      }

      return RoleRepository.create({
        name: this.name,
        level: this.level,
        permissions: this.permissions,
      }).then((res) => {
        this.$router.push({name: 'roles.edit', params: {roleId: res.data.data.id}});
        this.$sendSuccessToast(`Rôle ${this.name} a été créee!`);
      }).finally(() => callback());
    },
  },
  computed: {
    isUpdateMode() {
      return this.mode === 'update';
    },

    getTitle() {
      if (this.isUpdateMode) {
        return 'Modifier rôle';
      }
      return 'Créer nouvelle rôle';
    },
  }
}
</script>