<template>
  <list-table
    :columns="columns"
    :create-route="{
            name: 'timeclock.create'
        }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    :title="title"
    identifier="timeClockTable"
  >
    <template v-slot:time-template="{row}">
            <span
              v-if="row.secondsSincePrevious"
              :class="{'text-red-500': row.type === 'in'}"
              class="font-bold"
            >
                {{ new Date(row.secondsSincePrevious * 1000).toISOString().substring(11, 19) }}
            </span>
    </template>
  </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import {
  FILTER_DATETIME_RANGE,
  FILTER_MEMBER_EMPLOYEES,
  FILTER_SELECT_SINGLE
} from "@/components/listTable/includes/controls/filters/availableFilters";
import TimeClockEntryRepository from "@/repositories/TimeClockEntryRepository";

export default {
  name: "ShowTimeClockEntries",
  components: {ListTable},
  data() {
    return {
      employees: [],

      title: 'Pointage',
      repositoryFunc: TimeClockEntryRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          showInColumnChooser: false,
          allowReordering: false,
          buttons: [
            {
              type: 'show',
              hint: 'Voir pointage',
              route: (row) => ({
                name: 'timeclock.edit',
                params: {
                  entry: row.id,
                },
                query: {readonly: true}
              }),
            },
            {
              type: 'edit',
              hint: 'Modifier pointage',
              route: (row) => ({
                name: 'timeclock.edit',
                params: {
                  entry: row.id,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer pointage',
              confirmation: {
                title: 'Supprimer pointage?',
                text: 'Étes vous sure que vous voulez supprimer ce pointage?'
              },
              successText: 'Le pointage a été supprimée',
              deleteAction: (row) => TimeClockEntryRepository.deleteSingle(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id'
          }
        },
        {
          caption: 'Employé',
          dataField: 'memberName',
          sort: {
            dataField: 'memberFullName'
          }
        },
        {
          caption: 'Type',
          calculateCellValue: row => row.type === 'in' ? 'Entrée' : 'Sortie',
          sort: {
            dataField: 'type',
          }
        },
        {
          caption: 'Date & heure',
          calculateCellValue: row => this.$date(row.dateTime).format('DD.MM.YYYY HH:mm:ss'),
          sort: {
            dataField: 'dateTime',
            default: 'desc'
          }
        },
        {
          caption: 'Temps',
          cellTemplate: 'time-template'
        },

      ],
      filters: [
        {
          caption: "Employé",
          filterKey: "memberId",
          type: FILTER_MEMBER_EMPLOYEES,
          forceIsVisible: true,
        },
        {
          filterKey: 'dateTimeBetween',
          caption: "Date début entre",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true,
        },
        {
          caption: "Type",
          filterKey: "type",
          type: FILTER_SELECT_SINGLE,
          filterOptions: [
            {label: 'Entrée', value: 'in'},
            {label: 'Sortie', value: 'out'},
          ],
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>