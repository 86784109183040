<template>
  <create-or-update-booking-account
    mode="update"
  />
</template>

<script>
import CreateOrUpdateBookingAccount from "@/components/pages/BookingAccounts/Form/CreateOrUpdateBookingAccount";

export default {
  name: "UpdateBookingAccount",
  components: {CreateOrUpdateBookingAccount}
}
</script>

<style scoped>

</style>