<template>
  <div
    :class="isSelected ? 'border-luxcaddy-600 bg-luxcaddy text-white' : 'border-gray-200 bg-gray-100'"
    class="rounded-md p-3 lg:p-6 lg:text-2xl border"
    @click="setAsSelected"
  >
    {{ year }}
  </div>
</template>

<script>
export default {
  name: "TouchInterfaceHolidaysSingleYear",
  props: {
    year: {type: Number, required: true}
  },
  methods: {
    setAsSelected() {
      this.$store.dispatch('touchInterface/myHolidays/fetchHolidaysForYear', this.year);
    }
  },
  computed: {
    isSelected() {
      return this.$store.getters["touchInterface/myHolidays/getSelectedYear"] === this.year;
    }
  },
}
</script>