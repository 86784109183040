<template>
  <div>
    <list-table
      :batch-actions="MemberBatchActions.batchActions"
      :columns="columns"
      :create-route="{name: 'members.create'}"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :select-values="MemberBatchActions.selectValues"

      :title="title"
      identifier="membersTable"
      selectable
    >
      <template v-slot:member-quick-action-template="{ row }">
        <member-quick-action-modal :id="row.id"/>
      </template>
    </list-table>
  </div>
</template>

<script>
import MemberRepository from "../../../repositories/MemberRepository";
import ListTable from "../../listTable/ListTable";
import {
  FILTER_BOOLEAN,
  FILTER_CURRENCY,
  FILTER_DATETIME_RANGE,
  FILTER_EMAIL,
  FILTER_LANGUAGE_SELECT,
  FILTER_NUMERIC,
  FILTER_PERMISSIONS,
  FILTER_ROLES,
  FILTER_SELECT_MULTI,
  FILTER_SELECT_SINGLE
} from "../../listTable/includes/controls/filters/availableFilters";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import MemberBatchActions from "@/batchAction/memberBatchActions";

export default {
  name: "ShowMembers",
  computed: {
    MemberBatchActions() {
      return MemberBatchActions
    }
  },
  components: {MemberQuickActionModal, ListTable},
  data() {
    return {
      title: 'Membres',
      repositoryFunc: MemberRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir panier',
              icon: 'shopping-basket',
              condition: (row) => row.isCustomer,
              route: (row) => {
                return {
                  name: 'carts.edit',
                  params: {
                    member: row.id
                  }
                }
              }
            },
            {
              type: 'show',
              hint: 'Voir membre',
              route: (row) => {
                return {
                  name: 'members.edit',
                  params: {member: row.id},
                  query: {readonly: true}
                };
              },
            },
            {
              type: 'edit',
              hint: 'Modifier membre',
              condition: (row) => !row.isAnonymized,
              route: (row) => {
                return {
                  name: 'members.edit',
                  params: {member: row.id},
                };
              },
            },
            {
              type: 'delete',
              hint: 'Anonymiser',
              icon: 'user-secret',
              condition: (row) => !row.isAnonymized,
              confirmation: {
                title: 'Désactiver et anonymiser membre?',
                text: 'Étes vous sure que vous voulez désactiver et anonymiser ce membre?'
              },
              deleteAction: (row) => MemberRepository.anonymizeSingle(row.id),
              successText: 'Le membre a été anonymisé',
              deletePermissions: ['MEMBER_WRITE']
            },
          ],
        },
        {
          caption: '#',
          width: 50,
          cellTemplate: "member-quick-action-template",
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'Membre',
          dataField: 'fullName',
          sort: {
            dataField: 'fullName'
          }
        },
        {
          caption: 'Ville',
          dataField: 'city',
          sort: {
            dataField: 'city'
          }
        },
        {
          caption: 'Code postal',
          dataField: 'postalCode',
          visible: false,
          sort: {
            dataField: 'postalCode'
          }
        },
        {
          caption: 'Pays',
          dataField: 'country',
          calculateCellValue: (r) => r.country.toUpperCase(),
          visible: false,
          sort: {
            dataField: 'country'
          }
        },
        {
          caption: 'User',
          dataType: 'boolean',
          dataField: 'isUser',
          calculateCellValue: (r) => r.isUser,
        },
        {
          caption: 'Client',
          dataType: 'boolean',
          dataField: 'isCustomer',
          calculateCellValue: (r) => r.isCustomer,
        },
        {
          caption: 'Fournisseur',
          dataType: 'boolean',
          dataField: 'isSupplier',
          calculateCellValue: (r) => r.isSupplier,
        },
        {
          caption: 'Employé',
          dataType: 'boolean',
          dataField: 'isEmployee',
          calculateCellValue: (r) => r.isEmployee,
        },
        {
          caption: 'Créé le',
          calculateCellValue: (r) => this.$date(r.createdAt).format('DD.MM.YYYY'),
          visible: false,
          sort: {
            dataField: 'createdAt'
          },
        },

      ],
      filters: [
        {
          caption: 'ID(s) ou Nom',
          filterKey: 'idOrName',
          forceIsVisible: true,
          small: true
        },
        {
          caption: 'Type',
          filterKey: 'companyOrIndividual',
          type: FILTER_SELECT_SINGLE,
          small: true,
          defaultValue: null,
          filterOptions: [
            {label: 'Tous', value: null},
            {label: 'Société', value: "company"},
            {label: 'Privé', value: "individual"},
          ]
        },
        {
          caption: 'Type de membre',
          filterKey: 'activeMemberTypes',
          type: FILTER_SELECT_MULTI,
          small: true,
          filterOptions: [
            {label: 'Client', value: 'customer'},
            {label: 'Employée', value: 'employee'},
            {label: 'Fournisseur', value: 'supplier'},
            {label: 'Utilisateur', value: 'user'},
          ],
          forceIsVisible: true,
        },
        {
          caption: 'Fournisseur de marchandise',
          filterKey: 'productSupplier',
          type: FILTER_BOOLEAN,
          small: true
        },
        {
          caption: "Roles",
          filterKey: "roles",
          type: FILTER_ROLES,
          small: true
        },
        {
          caption: "Permissions",
          filterKey: "permissions",
          type: FILTER_PERMISSIONS,
          small: true
        },
        {
          caption: 'E-Mail',
          filterKey: 'email',
          type: FILTER_EMAIL,
          small: true,
          forceIsVisible: true,
        },

        {
          caption: 'Tél',
          filterKey: 'phoneNumber',
          small: true,
        },
        {
          caption: 'Adresse',
          filterKey: 'address',
          forceIsVisible: true,
          small: true,

        },
        {
          caption: 'Code Postal',
          filterKey: 'postalCode',
          small: true,
          forceIsVisible: true
        },
        {
          caption: 'Zone de livraison',
          filterKey: 'deliveryContactZone',
          small: true,
        },
        {
          caption: 'Langue',
          filterKey: 'language',
          type: FILTER_LANGUAGE_SELECT,
          small: true,
        },
        {
          caption: 'Numero TVA',
          filterKey: 'vatNumber',
          small: true,
        },
        {
          caption: 'Est anonymisé',
          filterKey: 'isAnonymized',
          type: FILTER_BOOLEAN,
          defaultValue: 0,
          small: true,
        },
        {
          caption: 'Active',
          filterKey: 'isActive',
          type: FILTER_BOOLEAN,
          defaultValue: 1,
          small: true,
        },
        {
          caption: 'Mode de paiement',
          filterKey: 'paymentType',
          type: FILTER_SELECT_SINGLE,
          small: true,
          filterOptions: this.$luxcaddyConfig('member.customer.allowedPaymentProviders'),
        },
        {
          filterKey: 'createdAt',
          caption: 'Crée entre',
          type: FILTER_DATETIME_RANGE
        },
        {
          caption: 'A des facture au retard de paiement',
          filterKey: 'hasUnpaidInvoicesRange',
          type: FILTER_DATETIME_RANGE
        },
        {
          filterKey: 'monthlyOrderVolume',
          inputs: [
            {
              caption: 'Volume/mois entre',
              inputKey: 'monthlyOrderVolume_from',
              type: FILTER_CURRENCY
            },
            {
              caption: 'et',
              inputKey: 'monthlyOrderVolume_to',
              type: FILTER_CURRENCY
            }
          ]
        },
        {
          filterKey: 'monthlyOrderCount',
          inputs: [
            {
              caption: 'Com./mois entre',
              inputKey: 'monthlyOrderCount_from',
              type: FILTER_NUMERIC
            },
            {
              caption: 'et',
              inputKey: 'monthlyOrderCount_to',
              type: FILTER_NUMERIC
            }
          ]
        },
        {
          filterKey: 'orderAmountRange',
          inputs: [
            {
              caption: 'Com./Total Montant de',
              inputKey: 'amount_from',
              type: FILTER_CURRENCY
            },
            {
              caption: 'Com./Total Montant à',
              inputKey: 'amount_to',
              type: FILTER_CURRENCY
            }
          ]
        },
      ]
    }
  },
}
</script>

