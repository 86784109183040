<template>
  <div class="flex flex-col gap-y-2">
    <sidebar-dropdown-menu icon="dolly" label="Fournisseur">
      <sidebar-nav-link-in-drop-down :to="{name: 'suppliers.preview.list'}">
        Précommandes
      </sidebar-nav-link-in-drop-down>

    </sidebar-dropdown-menu>

  </div>
</template>

<script>
import SidebarDropdownMenu from "@/components/layouts/dashboard/partials/sidebar/SidebarDropdownMenu";
import SidebarNavLinkInDropDown from "@/components/layouts/dashboard/partials/sidebar/SidebarNavLinkInDropDown";

export default {
  name: "SidebarLinksSupplier",
  components: {SidebarNavLinkInDropDown, SidebarDropdownMenu}
}
</script>

<style scoped>

</style>