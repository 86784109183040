<template>
  <div
    v-click-outside="closeAllPickers"
    :class="{'z-30 relative': applyZLevel}"
    class="relative"
  >
    <base-input-element
      v-model="model"
      :active-in-read-only="activeInReadOnly"
      :additional-input-classes="withTime ? 'pr-14' : 'pr-8'"
      :disabled="disabled || isInReadOnlyMode"
      :label="label"
      :occupy-validation-space="occupyValidationSpace"
      :placeholder="placeholder"
      :small="small"
      :validation-rules="validationRules"
      type="text"
      @blur="onDateBlur"
    />

    <div
      :class="[hasLabel ? 'top-7' : 'top-1']"
      class="absolute right-2"
    >
      <date-time-picker-icons-with-overlay
        ref="dateTimePickerIcons"
        v-model="model"
        :disabled="disabled"
        :disallow-past-date="disallowPastDate"
        :with-time="withTime"
        @close="onOverlayShown(false)"
        @open="onOverlayShown(true)"
      />
    </div>
  </div>
</template>

<script>


import BaseInputElement from "./BaseInputElement";
import formInputMixin from "@/mixins/formInputMixin";
import DateTimePickerIconsWithOverlay
  from "@/components/elements/forms/elements/base/includes/DateTimePickerIconsWithOverlay.vue";

export default {
  name: "DatePickerElement",
  components: {DateTimePickerIconsWithOverlay, BaseInputElement},
  mixins: [formInputMixin],
  data() {
    return {
      applyZLevel: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === this.value) {
          return false;
        }
        if (value === "") {
          value = null;
        }
        this.$emit('input', value);
      }
    },
    hasLabel() {
      return this.label !== undefined && this.label !== null;
    }
  },
  methods: {
    closeAllPickers() {
      if (this.$refs.dateTimePickerIcons) {
        this.$refs.dateTimePickerIcons.showDatePicker = false;
        this.$refs.dateTimePickerIcons.showTimePicker = false;
      }
    },
    onOverlayShown(shown) {
      this.applyZLevel = shown;
    },

    /**
     * Called when the user leaves the date field after typing manually.
     *
     * When the user entered a valid date but not a time, and the date picker has time enabled, it should set the time automatically.
     */
    onDateBlur(value) {
      let dayJsDate = this.$date(value,
        [
          this.$refs.dateTimePickerIcons.getReadableFormat(false),
          this.$refs.dateTimePickerIcons.getReadableFormat(true)
        ], true);

      if (dayJsDate.isValid()) {
        // Date is entered in exact format: DD.MM.YYYY but supports Time.
        if (this.withTime) {
          this.model = dayJsDate.format('DD.MM.YYYY HH:mm:ss');
        }
      }
    },

  },
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: 'Pas de date sélectionnée'
    },
    validationRules: {
      type: String,
      required: false,
    },
    withTime: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    small: {
      type: Boolean
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    disallowPastDate: {
      type: Boolean,
      default: false
    },
  }
}
</script>

