<template>
  <toggle-element
    v-if="canUserSwitch"
    v-model="isInReadOnlyMode"
    disabled-icon="edit"
    enabled-icon="eye"
  >
  </toggle-element>
</template>

<script>
import ToggleElement from "../toggles/ToggleElement";
import {userHasPermission} from "@/helpers/permissionHelper";

export default {
  name: "ReadOnlyToggle",
  components: {ToggleElement},
  computed: {
    isInReadOnlyMode: {
      get: function () {
        return this.$store.getters['userInterface/isInReadOnlyMode'];
      },
      set: function (boolean) {
        this.$store.commit('userInterface/setReadOnlyMode', boolean);
      }
    },
    getPermissionsForCurrentRoute() {
      const permissions = this.$route.meta?.permissions || [];
      const readOnlyPermissions = this.$route.meta?.readOnly?.permissions || [];

      return {permissions, readOnlyPermissions};
    },
    getUserPermissions() {
      return this.$store.getters['authUser/getPermissions'];
    },
    userHasRoutePermission() {
      return userHasPermission(this.getPermissionsForCurrentRoute.permissions, this.getUserPermissions);
    },
    userHasRouteReadOnlyPermission() {
      return userHasPermission(this.getPermissionsForCurrentRoute.readOnlyPermissions, this.getUserPermissions);
    },
    canUserSwitch() {
      if (!this.userHasRoutePermission || !this.userHasRouteReadOnlyPermission) {
        return false;
      }

      return true;
    }
  },
}
</script>

