<template>
  <div>
    <page-title>
      Créer tag
    </page-title>

    <card>
      <create-or-update-tag-form mode="create"/>
    </card>
  </div>
</template>
<script>
import CreateOrUpdateTagForm from "../Forms/CreateOrUpdateTagForm";
import Card from "../../../elements/cards/Card";
import PageTitle from "../../../elements/pages/PageTitle";

export default {
  name: "CreateTag",
  components: {PageTitle, Card, CreateOrUpdateTagForm}
}
</script>