<template>
  <div>
    <div class="grid grid-cols-4 gap-4">
      <external-invoice-pdf-card
        v-for="externalInvoicePDF in externalInvoicePDFs"
        :key="externalInvoicePDF.id"
        :allow-text-layer="false"
        :external-invoice-pdf="externalInvoicePDF"
        @request-reload="fetchExternalInvoicePDFsLinkedToSupplierOrder"
      >
        <template #footer>
          <div class="flex justify-between items-center">
            <template>
              <div
                v-if="externalInvoicePDF.invoice === null"
                class="italic text-sm"
              >
                Aucune facture liée
              </div>
              <div
                v-else
                class="flex gap-x-2 items-center"
              >
                <fa-icon fixed-width icon="file-invoice"/>
                <text-link
                  :to="{name: 'accounting.invoices.edit', params: {id: externalInvoicePDF.invoice.id}}"
                >
                  #{{ externalInvoicePDF.invoice.id }}
                </text-link>

                <payment-invoice-status
                  :status="externalInvoicePDF.invoice.paymentStatus"
                  small
                />
              </div>
            </template>

            <button-element
              size="xsmall"
              type="danger"
              @click="openUnlinkConfirmationModal(externalInvoicePDF.id)"
            >
              <fa-icon
                class="mr-2"
                fixed-width
                icon="unlink"
              />

              Délier
            </button-element>
          </div>
        </template>
      </external-invoice-pdf-card>
    </div>

    <delete-confirmation-modal
      ref="unlinkConfirmationModal"
      title="Délier PDF facture"
      @delete="unlinkInvoicePdfFromSupplierOrder"
    >
      Voulez-vous vraiement délier le PDF facture de cette commande?
    </delete-confirmation-modal>
  </div>
</template>

<script>
import ExternalInvoicePdfCard
  from "@/components/pages/Accounting/ExternalInvoicePDFs/Sub/UnlinkedList/Sub/ExternalInvoicePdfCard.vue";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import TextLink from "@/components/global/TextLink.vue";
import PaymentInvoiceStatus from "@/components/pages/Invoices/Shared/Misc/PaymentInvoiceStatus.vue";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal.vue";

export default {
  name: "SupplierOrderInvoicePdfList",
  components: {DeleteConfirmationModal, PaymentInvoiceStatus, TextLink, ButtonElement, ExternalInvoicePdfCard},
  data() {
    return {
      externalInvoicePDFs: [],

      selectedInvoicePdfIdToUnlink: null
    }
  },
  created() {
    this.fetchExternalInvoicePDFsLinkedToSupplierOrder();
  },
  methods: {
    fetchExternalInvoicePDFsLinkedToSupplierOrder() {
      SupplierOrderRepository
        .getLinkedInvoicePDFs(this.getSupplierOrderId)
        .then((res) => {
          this.externalInvoicePDFs = res.data.data;
        })
    },
    openUnlinkConfirmationModal(invoicePdfId) {
      this.selectedInvoicePdfIdToUnlink = invoicePdfId;
      this.$refs.unlinkConfirmationModal.openModal();
    },
    unlinkInvoicePdfFromSupplierOrder() {
      if (this.selectedInvoicePdfIdToUnlink === null) {
        return false;
      }

      SupplierOrderRepository.unlinkLinkedInvoicePDF(
        this.getSupplierOrderId,
        this.selectedInvoicePdfIdToUnlink
      ).then(() => {
        this.$sendSuccessToast("Le PDF de la facture sélectionnée a été désenclavé avec succès de la commande du fournisseur.");
        this.selectedInvoicePdfIdToUnlink = null;
        this.$refs.unlinkConfirmationModal.closeModal();
        this.fetchExternalInvoicePDFsLinkedToSupplierOrder();
      })
    }
  },
  computed: {
    getSupplierOrderId() {
      return this.$store.getters['supplierOrders/getOrder'].id;
    }
  },
}
</script>

<style scoped>

</style>