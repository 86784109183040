import CampaignRepository from "../../../repositories/CampaignRepository";
import Vue from "vue";

const getDefaultState = () => {
  return {
    campaign: {
      id: 0,
      name: "",
      applyPerTranche: 1,
      startDate: null,
      endDate: null,
      quantity: 1,
      reservedStock: null,
      type: "",
      value: 1,
      valueType: "",
      needsConfirmation: false,
      isShortDate: false,
      availableStock: null
    },
    rules: []
  }
}

export default {
  state: getDefaultState(),
  getters: {
    hasCampaignLoaded: state => state.campaign.id !== null,
    getCampaign: state => state.campaign,
    getCampaignId: state => state.campaign?.id,
    getCampaignName: state => state.campaign?.name,
    getRules: state => state.rules.sort((a, b) => a.appliedOn.toLowerCase().localeCompare(b.appliedOn.toLowerCase())),
  },
  mutations: {
    setStartDate(state, startDate) {
      Vue.set(state.campaign, 'startDate', startDate)
    },

    setCampaign(state, payload) {
      state.rules = payload.rules || [];

      delete payload.rules;

      payload.discountValue = (payload.value / 100).toFixed(2);
      state.campaign = payload;
    },
    clearCampaign(state) {
      state.campaign = getDefaultState().campaign;
      state.rules = getDefaultState().rules;
    },
    clearRules(state) {
      state.rules = getDefaultState().rules;
    },
    setValueType(state, valueType) {
      state.campaign.valueType = valueType;
    },
    addNewRule(state, context) {
      state.rules.push({
        appliedOn: context,
        filters: [],
        new: true
      })
    }
  },
  actions: {
    fetchSingle({commit}, campaignId) {
      return CampaignRepository.getSingle(campaignId).then((res) => {
        commit('setCampaign', res.data.data);
      })
    },
    refetchCurrentCampaign({dispatch, getters}) {
      return dispatch('fetchSingle', getters.getCampaignId);
    },
    // eslint-disable-next-line no-empty-pattern
    createCampaign({}, payload) {
      return CampaignRepository.create(payload);
    },
    updateCampaign({getters}, payload) {
      return CampaignRepository.update(getters.getCampaignId, payload);
    },
  },
}