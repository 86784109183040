<template>
  <div
    v-if="!$isReadOnly()"
    class="flex flex-col md:flex-row gap-y-2 justify-between items-stretch gap-x-4 bg-gray-100 p-4 rounded-b-lg mb-4">

    <template>
      <div class="flex flex-col gap-4 bg-gray-200 p-3 rounded-md">
        <div class="flex gap-x-16">
          <toggle-element v-if="!$isReadOnly()" v-model="category.isPublished" class="float-right"
                          disabled-text="Desactivé"
                          enabled-text="Activé" @input="onItemEnabledOrDisabled"></toggle-element>
        </div>
      </div>

      <transition mode="out-in" name="fade-up-down">
        <div key="categoryEnabledMenu" class="flex flex-col md:flex-row justify-end gap-y-2 gap-x-4">
          <div class="flex flex-col gap-4 bg-gray-200 p-3 rounded-md">
            <div class="flex gap-x-16">
              <div class="flex justify-between gap-x-12">
                <div class="flex justify-between gap-x-4">
                  <tooltip class="block" text="Créer sous-catégorie" tooltip-classes="-ml-14 mt-2">
                    <button-element size="small" type="primary"
                                    @click="$refs.createProductCategoryModal.openModal()">
                      <fa-icon class="text-white cursor-pointer" icon="plus"></fa-icon>
                    </button-element>
                  </tooltip>

                  <tooltip v-if="category.isVirtual" :text="getIsHomepageModalTitle" class="block"
                           tooltip-classes="-ml-14 mt-2">
                    <button-element :type="getIsHomepageBtnType" size="small"
                                    @click="openIsHomepageConfirmationModal">
                      <fa-icon class="text-white cursor-pointer" icon="home"></fa-icon>
                    </button-element>
                  </tooltip>

                  <tooltip class="block" text="Modifier catégorie" tooltip-classes="-ml-14 mt-2">
                    <button-element size="small" type="warning" @click="goToEditPage">
                      <fa-icon class="text-white cursor-pointer" icon="edit"></fa-icon>
                    </button-element>
                  </tooltip>

                  <tooltip class="block" text="Supprimer catégorie" tooltip-classes="-ml-14 mt-2">
                    <button-element size="small" type="danger" @click="openDeleteConfirmationModal">
                      <fa-icon class="text-white cursor-pointer" icon="trash-alt"></fa-icon>
                    </button-element>
                  </tooltip>

                </div>

                <div>
                  <tooltip class="block" text="Déplacer catégorie" tooltip-classes="-ml-14 mt-2">
                    <button-element size="small" type="light"
                                    @click="$refs.moveCategoryModal.openModal()">
                      <fa-icon class="text-white cursor-pointer" icon="exchange-alt"></fa-icon>
                    </button-element>
                  </tooltip>
                </div>

                <div>
                  <tooltip
                    :text="category.isVirtual ? 'Transformer en catégorie réelle' : 'Transformer en catégorie virtuelle'"
                    class="block"
                    tooltip-classes="-ml-44 mt-2 w-56"
                  >
                    <button-element size="small" type="light"
                                    @click="openSwitchCategoryConfirmationModal">
                      <fa-icon class="text-white cursor-pointer" icon="random"></fa-icon>
                    </button-element>
                  </tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>


    <!-- Modals -->
    <template>
      <delete-confirmation-modal ref="deleteCategoryModal" title="Supprimer catégorie?" @delete="deleteCategory">
        Êtes vous sûre que vous voulez supprimer la catégorie "<span class="font-bold">{{
          category.name
        }}</span>"?
      </delete-confirmation-modal>

      <delete-confirmation-modal ref="setIsHomepageCategoryModal" :title="getIsHomepageModalTitle"
                                 deleteButtonIcon="fa-check" deleteButtonLabel="Ok"
                                 @delete="setIsHomepage">
        {{ getIsHomepageModalText }}
      </delete-confirmation-modal>

      <delete-confirmation-modal
        ref="switchCategoryModal"
        :title="getSwitchCategoryModalTitle"
        deleteButtonIcon="fa-check"
        deleteButtonLabel="Ok"
        @delete="switchCategory"
      >
        {{ getSwitchCategoryText }}
      </delete-confirmation-modal>

      <product-category-create-modal
        ref="createProductCategoryModal"
        :parent-id="category.id"
      />


      <product-category-move-modal
        ref="moveCategoryModal"
        :category="category"
      />
    </template>
  </div>
</template>

<script>
import ToggleElement from "@/components/elements/toggles/ToggleElement";
import CategoryRepository from "@/repositories/CategoryRepository";
import Tooltip from "@/components/elements/tooltips/Tooltip";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import {mapGetters} from "vuex";
import {getCategoryConfigByType} from "@/luxcaddyConfig";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal";
import ProductCategoryCreateModal from "@/components/pages/Categories/Common/Modals/ProductCategoryCreateModal";
import ProductCategoryMoveModal from "@/components/pages/Categories/Common/Modals/ProductCategoryMoveModal";

export default {
  name: "CategoryTreeItemActions",
  components: {
    ProductCategoryMoveModal,
    ProductCategoryCreateModal,
    DeleteConfirmationModal,
    ButtonElement,
    ToggleElement,
    Tooltip,
  },
  props: {
    category: {
      type: Object,
      required: true
    },
  },
  methods: {
    onItemEnabledOrDisabled(enabled) {
      if (enabled) {
        CategoryRepository.enable(this.category.id);
      } else {
        CategoryRepository.disable(this.category.id);
      }
    },
    goToEditPage() {
      this.$router.push({
        name: this.getCategoryTypeConfig.editCategoryRouteName,
        params: {
          category: this.category.id
        },
      });
    },

    openDeleteConfirmationModal() {
      this.$refs.deleteCategoryModal.openModal();
    },
    openIsHomepageConfirmationModal() {
      this.$refs.setIsHomepageCategoryModal.openModal();
    },
    openSwitchCategoryConfirmationModal() {
      this.$refs.switchCategoryModal.openModal();
    },
    deleteCategory() {
      CategoryRepository.delete(this.category.id).then(() => {
        this.$sendSuccessToast('La catégorie a été supprimé.');
      });
    },
    setIsHomepage() {
      CategoryRepository.setIsHomepage(this.category.id, !this.category.isHomepage).then(() => {
        let text = 'La catégorie a été marquer comme homepage';
        if (!this.category.isHomepage) {
          text = 'La catégorie a été demarquer comme homepage'
        }
        this.$sendSuccessToast(text);
      });
    },
    switchCategory() {
      CategoryRepository.switchCategoryBetweenVirtualAndReal(this.category.id).then(() => {
        this.$sendSuccessToast("Catégorie transformé. N'oubliez pas d'adapter l'ordre de la catégorie!");
      });
    }
  },
  computed: {
    ...mapGetters('categories', [
      'getCurrentCategoryType',
      'getCurrentCategoryIsVirtual'
    ]),
    isPublished() {
      return this.category.isPublished;
    },
    getCategoryTypeConfig() {
      return getCategoryConfigByType(this.getCurrentCategoryType, this.getCurrentCategoryIsVirtual);
    },

    getIsHomepageModalTitle() {
      if (this.category.isHomepage) {
        return 'Décocher comme page d\'acceuil';
      } else {
        return 'Marquer comme page d\'acceuil';
      }
    },
    getIsHomepageBtnType() {
      if (this.category.isHomepage) {
        return 'light';
      } else {
        return 'primary'
      }
    },

    getSwitchCategoryModalTitle() {
      if (this.category.isVirtual) {
        return "Transformer -> Catégorie réelle";
      }

      return "Transformer -> Catégorie virtuelle";
    },

    getSwitchCategoryText() {
      if (this.category.isVirtual) {
        return "Êtes-vous sûr de vouloir transformer cette catégorie virtuelle en catégorie réelle ?";
      }

      return "Êtes-vous sûr de vouloir transformer cette catégorie réelle en catégorie virtuelle ?";
    },

    getIsHomepageModalText() {
      if (this.category.isHomepage) {
        return 'Êtes vous sûre que vous voulez décocher la catégorie  comme Homepage?';
      } else {
        return 'Êtes vous sûre que vous voulez marquer la catégorie  comme Homepage?'
      }
    }

  }
}
</script>

