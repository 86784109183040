<template>
  <div>
    <text-input-element
      ref="searchInput"
      v-model="searchTerm"
      label="Recherche"
      placeholder="p. ex. Battin Gambrinus"
    />

    <toggle-element
      v-if="context === 'order' && hasPermissionForAddOn"
      v-model="useBuyingPriceWithAddOn"
      label="Utilisez prix d'achat + suppléments"
    />
    <transition-group
      v-if="getFilteredProducts.length"
      class="flex flex-no-wrap gap-x-6 overflow-x-auto overflow-y-hidden pb-6 mt-2"
      name="fade"
    >
      <product-selection-card
        v-for="product in getFilteredProducts"
        :key="product.id"
        :cart-mode="cartMode"
        :use-buying-price-with-add-on="useBuyingPriceWithAddOn"
        :context="context"
        :product="product"
        :quantity-to-add="quantityToAdd"
        :show-price="showPrice"
        :tooltip="tooltip"
        @added="onProductAddedToOrder"
      />
    </transition-group>

    <div v-else-if="searchTerm && searchedAtLeastOnce">
      Aucun produit ne correspond aux paramètres de votre requête.
    </div>
  </div>
</template>

<script>
import TextInputElement from "../../elements/forms/elements/base/TextInputElement";
import {debounce} from "@/helpers/debounceHelper";
import ProductSelectionCard from "./ProductSelectionCard";
import ProductRepository from "../../../repositories/ProductRepository";
import ToggleElement from "@/components/elements/toggles/ToggleElement.vue";

export default {
  name: "ProductSearchSelection",
  components: {ToggleElement, ProductSelectionCard, TextInputElement},
  data: () => ({
    searchTerm: '',
    useBuyingPriceWithAddOn: false,
    products: [],

    searchedAtLeastOnce: false
  }),
  props: {
    quantityToAdd: {
      type: Number,
      default: 1
    },
    context: {
      type: String,
      default: "cart"
    },
    showQuantitySelector: {
      type: Boolean,
      default: false
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    cartMode: {
      type: Boolean,
      default: true
    },
    excludedProductIds: {
      type: Array,
      default: () => []
    },
    // If set, only products by this supplier will be returned
    filterBySupplierId: {
      type: Number,
    },
    tooltip: {
      type: String,
      required: false
    },
  },
  watch: {
    searchTerm: debounce(function () {
      this.searchedAtLeastOnce = false;
      this.searchProducts();
    }),
  },
  methods: {
    searchProducts() {
      if (!this.searchTerm)
        return this.products = [];

      ProductRepository
        .search(this.searchTerm)
        .then((res) => {
          this.products = res.data.data;
          this.searchedAtLeastOnce = true;
        })
    },
    onProductAddedToOrder() {
      this.searchTerm = '';
      this.$nextTick(() => this.$refs.searchInput.focus());
      this.$emit('added');
    }
  },
  computed: {
    /**
     * Returns only products that are not filtered in excludedProductIds.
     * @return {*[]}
     */
    getFilteredProducts() {
      return this.products.filter(p => !this.excludedProductIds.includes(p.id));
    },
    hasPermissionForAddOn() {
        let permissions = this.$store.getters['authUser/getPermissions'];

        return  permissions.includes('ORDER_WRITE_WITH_ADD_ONS');
    }
   }
}
</script>

