import ShowDepartments from "@/components/pages/Departments/ShowDepartments.vue";
import CreateDepartment from "@/components/pages/Departments/CreateDepartment.vue";
import EditDepartment from "@/components/pages/Departments/EditDepartment.vue";

export default [
  {
    name: 'departments.list',
    path: '/departments',
    component: ShowDepartments,
    meta: {
      title: 'Voir departements',
      permissions: ['DEPARTMENT_READ']
    }
  },
  {
    name: 'departments.create',
    path: '/departments/create',
    component: CreateDepartment,
    meta: {
      title: 'Créer departement',
      permissions: ['DEPARTMENT_WRITE'],
      breadcrumbs: ['departments.list'],
    }
  },
  {
    name: 'departments.edit',
    path: '/departments/:department/edit',
    component: EditDepartment,
    meta: {
      title: 'Modifier departement',
      permissions: ['DEPARTMENT_WRITE'],
      breadcrumbs: ['departments.list'],
    }
  },
]