<template>
  <div v-click-outside="onClickOutside" class="relative opacity-100">
    <button-element
      :disabled="disabled"
      :disabled-tooltip="disabledTooltip"
      :size="size"
      :type="type"
      class="z-0"
      @click="openMenu = !openMenu"
    >
      <fa-icon
        v-if="buttonIcon"
        :icon="buttonIcon"
        fixed-width
      />
      {{ buttonText }}
    </button-element>

    <transition name="fade">
      <div
        v-if="openMenu"
        :class="[stickDirection === 'right' ? 'right-0' : 'left-0', smallMenu ? 'p-1.5 w-48 text-xs' : 'p-4 w-64 text-sm', useDivider ? 'divide-y divide-gray-200' : '']"
        class="z-30 absolute bg-white shadow rounded-md border border-gray-200 text-gray-900  font-medium dark:bg-gray-700"
      >
        <div
          v-for="action in actions"
          :key="action.key ? action.key : action.label"
          :class="[smallMenu ? 'px-2 py-1' : 'px-4 py-2', isActionDisabled(action) ? 'text-gray-300 cursor-not-allowed' : 'hover:bg-gray-100 hover:text-luxcaddy-600']"
          class="block w-full focus:outline-none focus:ring-2 focus:ring-luxcaddy-600 focus:text-luxcaddy-600 cursor-pointer flex items-center gap-x-6"
          @click="executeAction(action)"
        >
          <fa-icon v-if="action.icon" :icon="action.icon" class="flex-shrink-0" fixed-width></fa-icon>
          <span :class="{'truncate': truncate}">{{ action.label }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ButtonElement from "./ButtonElement";

export default {
  name: "ButtonWithMenuElement",
  components: {ButtonElement},
  data: () => ({
    openMenu: false,
  }),
  props: {
    buttonText: {type: String, required: false},
    buttonIcon: {type: String, required: false},
    size: {type: String, default: 'large'},
    type: {type: String, default: 'primary'},
    stickDirection: {type: String, default: 'right'},
    smallMenu: {type: Boolean, default: false},
    useDivider: {type: Boolean, default: false},
    truncate: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    disabledTooltip: {type: String, required: false},

    actions: {type: Array, required: true},
    closeAfterActionExecuted: {type: Boolean, default: false}
  },
  methods: {
    executeAction(action) {
      if (this.isActionDisabled(action)) {
        return false;
      }

      // Execute
      action.action();
      if (this.closeAfterActionExecuted) {
        this.openMenu = false;
      }
    },

    isActionDisabled(action) {
      return action.disabled !== undefined && action.disabled === true;
    },

    onClickOutside() {
      if (this.openMenu) {
        this.openMenu = false;
      }
    }
  }
}
</script>

