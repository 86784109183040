<template>
  <list-table
    :columns="columns"
    :create-route="{
            name: 'booking-accounts.create'
        }"
    :filters="filters"
    :pagination="true"
    :repository-func="repositoryFunc"
    identifier="bookingAccountsTable"
    title="Comptes"
  />
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import {FILTER_TEXT} from "@/components/listTable/includes/controls/filters/availableFilters";
import BookingAccountRepository from "@/repositories/BookingAccountRepository";

export default {
  name: "ShowBookingAccounts",
  components: {ListTable},
  data() {
    return {
      repositoryFunc: BookingAccountRepository.getPaginated,
      columns: [
        {
          caption: "Actions",
          showInColumnChooser: false,
          allowReordering: false,
          buttons: [
            {
              type: 'edit',
              hint: 'Modifier compte',
              route: (row) => ({
                name: 'booking-accounts.update',
                params: {
                  bookingAccount: row.id,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer compte',
              confirmation: {
                title: 'Supprimer compte?',
                text: 'Étes vous sure que vous voulez supprimer cette compte?'
              },
              successText: 'La compte a été supprimée',
              deleteAction: (row) => BookingAccountRepository.deleteSingle(row.id)
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id',
            default: 'asc'
          }
        },
        {
          caption: 'Nom',
          dataField: 'name',
          sort: {
            dataField: 'name'
          }
        }
      ],
      filters: [
        {
          caption: "ID",
          filterKey: "id",
          type: FILTER_TEXT,
          forceIsVisible: true,
        },
        {
          caption: "Nom",
          filterKey: "name",
          type: FILTER_TEXT,
          forceIsVisible: true,
        },
      ]
    }
  },
}
</script>

