<template>
    <modal ref="exportCsvOptions" :show-close-button="true" title="Set csv options" @close="onCloseModal">
        <template>
            <div>
                <text-input-element
                    label="Charset"
                    v-model="charset"
                />
            </div>
            <div>
                <text-input-element
                    label="Delimiter"
                    v-model="separator"
                />
            </div>
            <div>
                <text-input-element
                    label="Enclosure"
                    v-model="enclosure"
                />
            </div>
            <div>
                <text-input-element
                    label="Escape"
                    v-model="escape"
                />
            </div>
            <div>
                <text-input-element
                    label="New Line"
                    v-model="eol"
                />
            </div>

        </template>

        <template #footer>
            <div class="flex flex-row-reverse mt-4">
                <button-element @click="exportCsv">
                    Export csv
                </button-element>
            </div>
        </template>
    </modal>
</template>

<script>

import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement.vue";
import ReportQueryRepository from "@/repositories/ReportQueryRepository";
import {exportResponse} from "@/helpers/exportHelper";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import Modal from "@/components/elements/modals/Modal.vue";

export default {
    name: "ExportCsvOptionsModal",
    components: {
        TextInputElement,
        ButtonElement, Modal
    },
    props: {
        executeAsync: {
            default: false
        }
    },

    data: () => ({
        separator: ";",
        charset: "UTF-8",
        enclosure: '"',
        escape: "\\",
        eol: "\n"
    }),
    methods: {
        openModal() {
            this.$refs.exportCsvOptions.openModal();
        },
        closeModal() {
            this.$refs.exportCsvOptions.closeModal();
        },
        onCloseModal() {
            this.separator = ";";
            this.charset = "UTF-8";
            this.enclosure ='"';
            this.escape = "\\";
            this.eol = "\n";
        },
        exportCsv() {
            if (this.executeAsync) {
               return  this.$store.dispatch('reportQuery/executeSelectedQueryAsync', {
                   fileFormat: 'csv',
                   options: {
                       separator: this.separator,
                       charset: this.charset,
                       enclosure: this.enclosure,
                       escape: this.escape,
                       eol: this.eol
                   }
               }).then(() => {
                    this.closeModal();
                    this.$sendSuccessToast(`Rêquete a été envoyé! Vous recevrez un e-mail dans quelques minutes.`);
                });
            }

            let selectionPlaceholders = this.$store.getters["reportQuery/getSelectionPlaceholders"];
            let values = [];

            selectionPlaceholders.forEach(ph => {
                values.push({
                    name: ph.placeholder,
                    value: ph.value
                });
            });


            return ReportQueryRepository
                .exportQuery('csv',
                    this.$store.getters["reportQuery/getSelectedQueryId"],
                    values,
                    {
                        separator: this.separator,
                        charset: this.charset,
                        enclosure: this.enclosure,
                        escape: this.escape,
                        eol: this.eol
                    }
                )
                .then((res) => {
                    exportResponse(res, `report`, 'pdf');
                    this.closeModal();
                })

        }
    }
}
</script>