<template>
  <div class="py-2 px-4 bg-gray-50 rounded-lg border">
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 gap-x-4 gap-y-1.5 items-start">
      <template
        v-for="filter in getVisibleFilters"
      >
        <!-- Only Top level Input -->
        <list-table-filter-input
          v-if="filter.inputs === undefined"
          :key="filter.filterKey"
          :caption="filter.caption"
          :class="{'md:col-span-2': !filter.small}"
          :default-value="filter.defaultValue"
          :filter-key="filter.filterKey"
          :filter-options="filter.filterOptions"
          :table-identifier="tableIdentifier"
          :type="filter.type || 'text'"
          show-reset-button
          @enter="$emit('apply')"
          @escape="$emit('reset')"
        />

        <!-- Has Sub Inputs -->
        <div
          v-else
          :key="filter.filterKey"
          :class="{'md:col-span-2': !filter.small}"
        >
          <span class="text-gray-500">{{ filter.caption }}</span>

          <div class="flex flex-wrap gap-x-4">
            <list-table-filter-input
              v-for="(input) in filter.inputs"
              :key="input.inputKey"

              :caption="input.caption"
              :default-value="input.defaultValue"

              :filter-key="filter.filterKey"
              :filter-options="input.filterOptions"
              :input-key="input.inputKey"
              :show-reset-button="true/* Show only for last input */"
              :table-identifier="tableIdentifier"
              :type="input.type"
              class="flex-1"
              @enter="$emit('apply')"
              @escape="$emit('reset')"
            />
          </div>
        </div>
      </template>

      <div class="flex gap-x-2 self-end mt-1">
        <add-filter-menu
          v-if="getInvisibleFilters.length > 0"
          :filters="getInvisibleFilters"
          :table-identifier="tableIdentifier"
          class="mr-2"
          @select="makeFilterVisible"
        />
        <button-element size="small" type="primary" @click="$emit('apply')">
          <fa-icon fixed-width icon="check"/>
        </button-element>
        <button-element size="small" type="danger" @click="$emit('reset')">
          <fa-icon fixed-width icon="times"/>
        </button-element>
      </div>
    </div>


  </div>
</template>

<script>

import ListTableFilterInput from "@/components/listTable/includes/controls/filters/ListTableFilterInput.vue";
import AddFilterMenu from "@/components/listTable/includes/controls/filters/AddFilter/AddFilterMenu.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import {FILTER_DATETIME_RANGE} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ListTableFiltersList",
  components: {ButtonElement, AddFilterMenu, ListTableFilterInput},
  created() {
    this.saveFiltersToStore();
  },
  props: {
    filtersToSaveToStore: {
      type: Array,
      required: true
    },
    tableIdentifier: {
      type: String,
      required: true
    }
  },
  mounted() {
    // Put focus on first filter field
    this.$nextTick(() => {
      if (this.$refs.filterCard && this.$refs.filterCard.querySelector('.formElement')) {
        this.$refs.filterCard.querySelector('.formElement').focus();
      }
    });
  },
  methods: {
    getFilterValue(filter) {
      let filterValue = null;

      // Filter has sub inputs
      if (this.hasFilterSubInputs(filter)) {
        filterValue = {};
        filter.inputs.forEach(input => {
          const inputFromQuery = this.$route.query['filter_' + input.inputKey];

          filterValue[input.inputKey] = inputFromQuery !== undefined
            ? inputFromQuery
            : null;
        });
      } else {
        // Apply default filter
        if (filter.defaultValue !== undefined) {
          filterValue = filter.defaultValue;
        }

        // Apply default filter from query url (higher importance then regular default value)
        const filterFromQuery = this.$route.query['filter_' + filter.filterKey];
        if (filterFromQuery !== undefined) {
          filterValue = filterFromQuery;
        }
      }

      return filterValue;
    },
    hasFilterSubInputs(filter) {
      return (filter.inputs || []).length > 0;
    },
    saveFiltersToStore() {
      this.filtersToSaveToStore.forEach(filter => {
        // Check if there is a value in store already (filter already set)
        // if so, don't update. This happens when going back to the list that has been previously shown
        let filterInStore = this.getFiltersFromStore.find(f => f.filterKey === filter.filterKey);

        if (!filterInStore) {
          this.updateStoreFilter({
            tableIdentifier: this.tableIdentifier,
            filterKey: filter.filterKey,
            filterValue: this.getFilterValue(filter),
            ...filter,
          });
        }
      });
    },
    updateStoreFilter(filter) {
      this.$store.commit('listTable/filters/updateFilter', filter);
    },

    /**
     * Called when user selects a filter currently not displayed from the list that should be displayed.
     * @param filter
     */
    makeFilterVisible(filter) {
      let storeFilter = this.getFiltersFromStore.find(f => f.filterKey === filter.filterKey);
      storeFilter.isVisibleCurrently = true;
      this.updateStoreFilter(storeFilter);
    }
  },
  computed: {
    getFiltersFromStore() {
      return this.$store.getters['listTable/filters/getFiltersByTableIdentifier'](this.tableIdentifier);
    },

    /**
     * Returns only filters that should be shown with their input to the user.
     * @returns {{[p: string]: *}[]}
     */
    getVisibleFilters() {
      return this
        .getFiltersFromStore
        .filter(filter => {
          if (filter.isVisibleCurrently) {
            return true;
          }

          // Filters with Sub Inputs + DATETIME_RANGE filters have an object as value.
          if (this.hasFilterSubInputs(filter) || filter.type === FILTER_DATETIME_RANGE) {
            // this happens when default object not set yet
            if (filter.filterValue === null || filter.filterValue === undefined) {
              return false
            }

            // if any sub input has a value, filter will be shown.
            return Object.values(filter.filterValue).some(v => v !== null)
          }

          return filter.filterValue !== null;
        })
    },

    /**
     * Return filters that are currently not visible and can be made visible by the user.
     * @returns {*}
     */
    getInvisibleFilters() {
      return this
        .getFiltersFromStore
        .filter(filter => {
          const isFilterVisible = this.getVisibleFilters.find(visibleFilter => visibleFilter.filterKey === filter.filterKey);

          return isFilterVisible === undefined;
        });
    },
  }
}
</script>