<template>
  <checkbox-element
    v-model="model"
    :label="getLabel"
    :occupy-validation-space="false"
    hide-checked-label
    label-inline
    size="xsmall"
  />
</template>

<script>
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";

export default {
  name: "RouteManagerActionBoxTimeslotCheckbox",
  components: {CheckboxElement},
  props: {
    startTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return !this.$store.getters['routeManager/isTimeSlotHidden']({
          startTime: this.startTime,
          endTime: this.endTime
        });
      },
      set() {
        this.$store.commit('routeManager/hideOrShowTimeSlot', {
          startTime: this.startTime,
          endTime: this.endTime
        });
      }
    },
    getLabel() {
      let string = '';
      let start = this.$date(this.startTime, 'HH:mm');
      let end = this.$date(this.endTime, 'HH:mm');

      string = start.minute() === 0
        ? start.format('HH')
        : start.format('HH:mm');

      string += '-';

      string += end.minute() === 0
        ? end.format('HH')
        : end.format('HH:mm');

      return `${string}h`;
    }
  }
}
</script>

