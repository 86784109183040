<template>
  <div class="bg-white border border-gray-200 rounded-md p-4 shadow-lg w-40">
    <div class="font-medium mb-3 text-sm">
      Légende:
    </div>

    <div class="flex flex-col gap-y-2">
      <div
        v-for="(color, i) in getColors"
        :key="color.speedUntil"
        class="flex gap-x-4 items-center"
      >
        <div
          :style="`background-color: ${color.color}; border-color: ${shadeColor(color.color)}`"
          class="w-8 h-6 border rounded">
        </div>

        <div class="text-xs">
          <!-- First element -->
          <template v-if="i === 0">0 - {{ color.speedUntil }} km/h</template>

          <!-- Last element -->
          <template v-else-if="i === (getColors.length -1)">
            {{ (getColors[i - 1].speedUntil + 1) }}+ km/h
          </template>

          <!-- any other element -->
          <template v-else>&lt; {{ color.speedUntil }} km/h</template>
        </div>
      </div>
    </div>

    <div class="mt-4 text-sm flex flex-col gap-y-2">
      <div class="text-xs">
        Vitesse sur cursor:
      </div>
      <div class="text-lg h-12 w-full flex flex-col gap-y-1">
        <span v-if="getHoveredSpeed">{{ getHoveredSpeed }} km/h</span>
        <span v-if="getHoveredDateTime" class="text-sm">
                    {{ $date(getHoveredDateTime).format('HH:mm') }}h
                </span>
      </div>
    </div>
  </div>
</template>

<script>
import {shadeColor, speedColors} from "@/helpers/routeManagerHelper";
import {mapGetters} from "vuex";

export default {
  name: "RouteManagerTrackingLegend",
  computed: {
    ...mapGetters('routeManager', [
      'getHoveredSpeed',
      'getHoveredDateTime',
    ]),
    getColors() {
      return speedColors;
    },
  },
  methods: {
    shadeColor(color) {
      return shadeColor(color, +20);
    },
  },
}
</script>

