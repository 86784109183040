<template>
  <google-map
    ref="map"
    :latitude="49.8425618"
    :longitude="6.1729622"
    :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            clickableIcons: false
        }"
  >

    <transition-group name="fade-up-down">
      <route-manager-delivery-map-marker
        v-for="delivery in getDeliveriesForMap"
        :key="delivery.id"
        :delivery="delivery"
      />
    </transition-group>


    <transition-group v-if="getDriverPositions.length > 0" name="fade-up-down">
      <route-manager-driver-map-marker
        v-for="driver in getDriverPositions"
        :key="driver.color + '-' + driver.routeId"
        :driver="driver"
      />
    </transition-group>

    <route-manager-map-tracking-polylines/>
  </google-map>
</template>

<script>
import GoogleMap from "@/components/elements/display/map/GoogleMap";
import RouteManagerDeliveryMapMarker
  from "@/components/pages/RouteManager/Includes/Map/Marker/RouteManagerDeliveryMapMarker";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import RouteManagerDriverMapMarker
  from "@/components/pages/RouteManager/Includes/Map/Marker/RouteManagerDriverMapMarker";
import RouteManagerMapTrackingPolylines
  from "@/components/pages/RouteManager/Includes/Map/Tracking/RouteManagerMapTrackingPolylines";

export default {
  name: "RouteManagerMap",
  components: {
    RouteManagerMapTrackingPolylines,
    RouteManagerDriverMapMarker,
    RouteManagerDeliveryMapMarker,
    GoogleMap
  },
  data: () => ({
    refreshRoutesInterval: null
  }),
  created() {
    // Re-fetch Routes every 30 seconds to update delivery statuses and driver positions
    this.refreshRoutesInterval = setInterval(() => {
      this.fetchAllRoutes(false);
    }, 1000 * 30);
  },
  beforeDestroy() {
    clearInterval(this.refreshRoutesInterval);
    this.refreshRoutesInterval = null;
  },
  computed: {
    ...mapGetters('routeManager', [
      'getDeliveriesForMap',
      'getHoverInitiatorIsSidebar',
      'getHoveredDeliveryId',
      'getDriverPositionsWithColor',
      'getClickedDriverRouteId',
      'getShowDriverPositions'
    ]),
    ...mapState('routeManager', [
      'settings'
    ]),

    getDriverPositions() {
      if (!this.getShowDriverPositions) {
        return [];
      }
      return this.getDriverPositionsWithColor;
    }
  },
  watch: {
    /**
     * Zoom to Marker if Delivery selected from sidebar
     * @param id
     * @returns {boolean}
     */
    getHoveredDeliveryId(id) {
      if (!id || !this.getHoverInitiatorIsSidebar)
        return false;

      let delivery = this.$store.getters['routeManager/getDeliveriesForMap']
        .find(d => d.id === id)

      if (!delivery || delivery?.destination?.geocoding === null)
        return false;

      if (this.settings.hoverEffectsMap) {
        this.panAndZoomTo(
          delivery?.destination?.geocoding.latitude,
          delivery?.destination?.geocoding.longitude
        );
      }
    },
    /**
     * Zoom to Marker if a driver position button from the sidebar has been clicked.
     * We zoom and pan first then the id will be set to null again.
     */
    getClickedDriverRouteId(id) {
      if (id === null)
        return false;

      let {latitude, longitude} = this.getDriverPositionsWithColor.find(d => d.routeId === id).position;
      this.panAndZoomTo(latitude, longitude, 16);

      // reset
      this.setClickedDriverRouteId(null);
    }
  },
  methods: {
    ...mapMutations('routeManager', ['setClickedDriverRouteId']),
    ...mapActions('routeManager', ['fetchAllRoutes']),

    panAndZoomTo(lat, lng, zoomLevel = 14) {
      this.$refs.map.$refs.map.$mapPromise.then((map) => {
        map.panTo({lat: lat, lng: lng});
        map.setZoom(zoomLevel);
      });
    }
  },
}
</script>