<template>
  <div>
    <delivery-label-html
      v-if="selectedDeliveryId"
      :delivery-id="selectedDeliveryId"
      class="hidden print:block"
      @ready="openPrintDialog"
    />

    <div class="print:hidden p-2 flex gap-3 flex-wrap">
      <print-delivery-fulfilment-label-button
        v-for="delivery in deliveries"
        :key="delivery.fulfilmentNumber"
        :delivery-id="delivery.deliveryId"
        :fulfilment-number="delivery.fulfilmentNumber"
        @click="selectedDeliveryId = delivery.deliveryId"
      />
    </div>
  </div>
</template>

<script>
import DeliveryRepository from "@/repositories/DeliveryRepository";
import PrintDeliveryFulfilmentLabelButton
  from "@/components/pages/TouchInterface/Delivery/PrintLabels/Components/PrintDeliveryFulfilmentLabelButton";
import DeliveryLabelHtml from "@/components/pages/TouchInterface/Packaging/Fulfilment/order/DeliveryLabelHtml";

export default {
  name: "PrintDeliveryFulfilmentLabelsPage",
  components: {DeliveryLabelHtml, PrintDeliveryFulfilmentLabelButton},
  created() {
    this.fetchFulfilmentIds();
  },
  data: () => ({
    deliveries: [],
    selectedDeliveryId: null,
  }),
  methods: {
    fetchFulfilmentIds() {
      DeliveryRepository
        .getUndeliveredFulfilmentIds()
        .then(res => {
          this.deliveries = res.data.data;
        })
    },
    openPrintDialog() {
      window.print();
      this.selectedDeliveryId = null;
    }
  },
}
</script>