<template>
  <div class="px-3 xl:p-8">

    <button-element
      class="mb-3"
      type="light"
      @click="$router.push({name: 'auth.login.barcode'})"
    >
      <div class="flex flex-wrap items-center justify-center space-x-2">
        <fa-icon fixed-width icon="barcode"/>
        <span>Code barre</span>
      </div>
    </button-element>

    <form-wrapper
      :show-changed="false"
      :show-submit-button="true"
      custom-button-classes="float-left font-semibold uppercase hover:shadow-none focus:outline-none"
      submit-button-label="Connexion"
      @submit="authenticate"
    >

      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <email-input-element
          v-model="email"
          label="Identifiant"
          validation-rules="required"
        />

        <password-input-element
          v-model="password"
          validation-rules="required"
        />
      </div>
    </form-wrapper>
  </div>
</template>

<script>
import authHelper from "@/helpers/authHelper";
import FormWrapper from "../../elements/forms/FormWrapper";
import PasswordInputElement from "../../elements/forms/elements/PasswordInputElement";
import EmailInputElement from "../../elements/forms/elements/EmailInputElement";
import ButtonElement from "@/components/elements/buttons/ButtonElement";

export default {
  name: "Login",
  components: {ButtonElement, EmailInputElement, PasswordInputElement, FormWrapper},
  data: () => ({
    email: '',
    password: '',

    renderBarcodeModal: false,
  }),
  methods: {
    authenticate(callback) {
      authHelper.login(this.email, this.password).then(() => {
        this.$sendSuccessToast(`Bonjour ${this.$store.getters["authUser/getFullName"]}!`);

        this.$store.dispatch('global/fetchAllInfo').then(() => {
          let permissions = this.$store.getters['authUser/getPermissions'];

          if (permissions.includes('ADMIN_PANEL_ACCESS')) {
            this.$router.push({name: 'home'});
          } else if (permissions.includes('BACKOFFICE_SUPPLIER_ACCESS')) {
            this.$router.push({name: 'suppliers.preview.list'});
          } else {
            this.$router.push({name: 'touchInterface.index'});
          }
        });
      }).finally(() => callback());
    },
  }
}
</script>

