import apiService from './Base/BaseRepository';

const url = '/supplierCatalog';

export default {

  async getProductsPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/products`);
  },

  async getProductCatalogCounts(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async uploadCatalog(supplierId, file) {
    let formData = new FormData();
    formData.append("file", file);

    return await apiService
      .post(`${url}/${supplierId}/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
  },
  async delete(supplierId) {
    return await apiService.delete(`${url}/${supplierId}`);
  },
}