import ShowDeposits from "../../components/pages/Deposit/ShowDeposits";
import auth from "../middlewares/authMiddleware";
import EditDeposit from "../../components/pages/Deposit/EditDeposit/EditDeposit";
import CreateDeposit from "../../components/pages/Deposit/CreateDeposit/CreateDeposit";

export default [
  {
    path: '/deposits',
    name: 'deposits.list',
    component: ShowDeposits,
    meta: {
      middleware: [auth],
      title: 'Liste des deposits',
      permissions: ['ACCESS_DEPOSITS_VIEW']
    }
  },
  {
    path: '/deposits/:deposit/edit',
    name: 'deposits.edit',
    component: EditDeposit,
    meta: {
      middleware: [auth],
      title: 'Modifier deposit',
      readOnly: {supported: true, title: 'Voir deposit', permissions: ['DEPOSIT_READ']},
      breadcrumbs: ['deposits.list'],
      permissions: ['DEPOSIT_WRITE']
    }
  },

  {
    path: '/deposits/create',
    name: 'deposits.create',
    component: CreateDeposit,
    meta: {
      middleware: [auth],
      title: 'Créer un deposit',
      breadcrumbs: ['deposits.list'],
      permissions: ['DEPOSIT_WRITE']
    }
  }
]