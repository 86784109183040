<template>
  <div>
    <scanner-listener
      v-if="!showTimeClockScanHandler"
      :accept-quantity="false"
      accept-code128
      @scan="onScan"
    />

    <div v-if="hasScanned">
      Authentification en cours...

      <div class="h-36 mt-12 ">
        <fa-icon
          class="text-luxcaddy-500 animate-spin transition mr-2 text-6xl"
          icon="circle-notch"
        />
      </div>
    </div>

    <div v-else>

      <div class="flex justify-center mt-1">
        <div v-if="showTabletScanInput">
          <div>
            <fa-icon icon="spinner" spin/>
          </div>
          <input ref="tabletScanInput" class="opacity-0" type="text" value="BCSCAN"/>
        </div>
        <template v-else>

          <template
            v-if="showTimeClockScanHandler"
          >
            <barcode-time-clock-handler
              @close="showTimeClockScanHandler = false"
            />
          </template>

          <template v-else>
            <div class="flex space-x-2">
              <button-element
                size="small"
                type="primary"
                @click="enableTabletScan"
              >
                Scan Caméra
              </button-element>

              <button-element
                v-if="showTimeClockButton"
                size="small"
                type="warning"
                @click="enableTimeClockScan"
              >
                Pointage
              </button-element>
            </div>
          </template>
        </template>
      </div>

      <div v-if="!showTimeClockScanHandler" class="h-36">
        <scan-indicator-animation/>
      </div>

      <div v-if="!showTimeClockScanHandler">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import ScannerListener
  from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/scanner/ScannerListener.vue";
import authHelper from "@/helpers/authHelper";
import ScanIndicatorAnimation from "@/components/elements/loading/ScanIndicatorAnimation.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import BarcodeTimeClockHandler from "@/components/pages/Auth/sub/BarcodeTimeClockHandler.vue";

export default {
  name: "BarcodeLoginHandler",
  components: {BarcodeTimeClockHandler, ButtonElement, ScanIndicatorAnimation, ScannerListener},
  data: () => ({
    hasScanned: false,

    showTabletScanInput: false,
    showTimeClockScanHandler: false,
  }),
  props: {
    showTimeClockButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    /**
     * The tablets have an app installed that will launch a QR Code reader through camera
     * as soon as it has focus on a text input with the value "BCSCAN"
     */
    enableTabletScan() {
      this.showTabletScanInput = true;
      this.$nextTick(() => {
        this.$refs.tabletScanInput.focus();
      })
    },
    enableTimeClockScan() {
      this.showTimeClockScanHandler = true;
    },

    onScan({code}) {
      if (this.hasScanned) {
        return false;
      }

      this.hasScanned = true;
      authHelper.loginByToken(code)
        .then(() => {
          this.$sendSuccessToast(`Bonjour ${this.$store.getters["authUser/getFullName"]}!`);

          this.$store.dispatch('global/fetchAllInfo').then(() => {
            /* View in fullscreen */
            let elem = document.documentElement;

            if (elem.requestFullscreen) {
              elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
              elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
              elem.msRequestFullscreen();
            }

            this.$router.push({name: 'touchInterface.index'});
          });
        })
        .finally(() => {
          this.hasScanned = false;
        });
    },
  },
}
</script>

<style scoped>

</style>