<template>
  <div
    :class="paddingClasses"
    class="bg-gray-200 hover:bg-gray-300 flex items-center justify-between gap-x-4"
    @click="$emit('click', $event)"
  >
    <div class="overflow-hidden flex-1">
      <slot></slot>
    </div>
    <div class="rounded-full bg-gray-400 text-white px-2 py-1.5 flex-none">
      <fa-icon fixed-width icon="chevron-right"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "DriverMyRoutesRouteBox",
  props: {
    paddingClasses: {
      type: String,
      default: "px-4 py-6"
    }
  }
}
</script>