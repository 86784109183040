<template>
  <div
    :class="[
            isFullyPacked ? 'bg-white' : 'bg-yellow-100'
        ]"
    class="flex items-center w-full gap-x-6 p-1 pr-4 border-b-2 border-gray-300"
  >
    <div class="h-24 w-24 flex-shrink-0">
      <img
        v-if="getImage"
        :src="getImage" alt="Product"
        class="h-full py-1 w-24 bg-white object-contain"
      >
    </div>

    <div class="text-4xl font-bold text-blue-900 text-opacity-80">
      {{ getToBePackedQuantity }}
    </div>

    <div class="flex-grow">
      <div class="text-xl text-blue-900 font-bold">
        {{ getItem.name }}
      </div>
      <div class="mt-3 whitespace-pre text-sm text-blue-900 font-medium">
        {{ getBundleSizeString }}<br>

        <div
          v-if="getStockOnShelfQuantity !== null"
        >
          Restant: {{ getStockOnShelfQuantity }}
        </div>
      </div>
    </div>

    <div class="flex gap-x-6 items-center">
      <div v-if="getToBePackedWeight" class="flex-shrink-0 text-right">
                <span class="text-md text-blue-900 font-semibold">
                    {{ getToBePackedWeight }} {{ getItem.bundle.unit }}
                </span>
        <span
          v-if="getPackedWeight && isFullyPacked"
          :class="{
                        'text-gray-500': isPackedWeightLessOrEqualThanToBePackedWeight,
                        'text-red-500': isPackedWeightMoreThanToBePackedWeight,
                        'text-luxcaddy-500': isPackedWeightEqualToToBePackedWeight,
                   }"
          class="text-md font-bold"
        >
                    <br>
                    <span v-if="isPackedWeightLessOrEqualThanToBePackedWeight">&lt;</span>
                    <span v-else-if="isPackedWeightMoreThanToBePackedWeight">&gt;</span>
                    {{ getPackedWeight }} {{ getItem.bundle.unit }}
                </span>
      </div>

      <order-fulfilment-item-weight-modal
        v-if="getShouldRenderModal"
        ref="modal"
        :item-id="getItem.id"
      />

      <order-fulfilment-undo-button
        v-if="getPackedQuantity > 0"
        @click="resetPacked"
      />

      <template v-if="!isFullyPacked && getIsLocked">
        <touch-optimized-button
          v-if="requiresManualWeightEntry"
          class="border border-gray-400 p-6 text-red-500"
          type="secondary"
          @click="openManualWeightInputModal">
          POIDS {{ getUnfulfilledQuantity }}
        </touch-optimized-button>

        <touch-optimized-button
          v-else
          :disabled="!allowsManualValidation"
          class="border border-gray-400 p-6"
          type="secondary"
          @click="increasePacked()"
        >
          OK {{ getUnfulfilledQuantity }}
        </touch-optimized-button>
      </template>

      <div v-if="!getIsLocked" class="text-gray-600">
        Pas de stock
      </div>
    </div>

  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import {getPrimaryImageUrlFromImageObject} from "@/helpers/imageHelper";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import OrderFulfilmentItemWeightModal from "./Sub/OrderFulfilmentItemWeightModal";
import OrderFulfilmentUndoButton from "./Sub/OrderFulfilmentUndoButton";

export default {
  name: "OrderFulfilmentItem",
  components: {OrderFulfilmentUndoButton, OrderFulfilmentItemWeightModal, TouchOptimizedButton},
  data: () => ({
    deliverAbleValue: 500,
  }),
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions('fulfilment', ['fulfillItem', 'resetItemPackedQuantity']),
    ...mapMutations('fulfilment', ['setItemIdForWeightInput']),
    increasePacked(amount = 1, weight = null) {
      let payload = {
        itemId: this.getItem.id,
        packedQuantity: amount,
      };

      if (weight) {
        payload.packedWeight = weight;
      }

      this.fulfillItem(payload);
    },
    resetPacked() {
      this.resetItemPackedQuantity(this.getItem.id);
    },
    openManualWeightInputModal() {
      this.setItemIdForWeightInput(this.getItem.id);
      this.$nextTick(() => {
        this.$refs.modal.open();
      })
    }
  },
  computed: {
    getItem() {
      return this.item;
    },

    getIsLocked() {
      return this.item.isLocked;
    },

    allowsManualValidation() {
      return this.getItem.manualValidation;
    },

    isFullyPacked() {
      return this.item.toBePackedQuantity === this.item.packedQuantity;
    },

    getToBePackedQuantity() {
      return this.getItem.toBePackedQuantity;
    },
    getPackedQuantity() {
      return this.getItem.packedQuantity;
    },
    getPackedWeight() {
      return this.getItem?.packedWeight || null;
    },
    isPackedWeightMoreThanToBePackedWeight() {
      if (this.getPackedWeight === null)
        return false;

      return this.getPackedWeight > this.getToBePackedWeight;
    },

    isPackedWeightEqualToToBePackedWeight() {
      if (this.getPackedWeight === null)
        return false;

      return this.getPackedWeight === this.getToBePackedWeight;
    },

    isPackedWeightLessOrEqualThanToBePackedWeight() {
      if (this.getPackedWeight === null)
        return false;

      return this.getPackedWeight < this.getToBePackedWeight;
    },

    getUnfulfilledQuantity() {
      return this.getToBePackedQuantity - this.getPackedQuantity;
    },

    hasVariableWeight() {
      return this.getItem.bundle?.isVariable;
    },

    getToBePackedWeight() {
      if (this.hasVariableWeight) {
        return this.getItem.toBePackedWeight;
      }
      return null;
    },

    getImage() {
      return getPrimaryImageUrlFromImageObject(this.getItem.image, 'small', false);
    },

    getBundleSizeString() {
      const bundle = this.getItem.bundle;
      return `${bundle.quantity} x ${bundle.content}${bundle.unit}`
    },

    getStockOnShelfQuantity() {
      return this.getItem.stockOnShelf;
    },

    requiresManualWeightEntry() {
      return this.getItem.bundle.isVariable && this.getItem.manualValidation;
    },

    ...mapGetters('fulfilment', ['getOrderMeta']),

    getItemIdForWeightInput() {
      return this.getOrderMeta.itemIdForWeightInput;
    },

    getShouldRenderModal() {
      return this.getItemIdForWeightInput === this.getItem.id && this.getItem.manualValidation;
    }
  },
}
</script>

