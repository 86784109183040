<template>
  <text-link
    v-if="orderId"
    :to="{name: 'orders.edit', params: {order: this.orderId}}"
  >
    #{{ orderId }}
  </text-link>
  <span v-else>/</span>
</template>

<script>
import TextLink from "@/components/global/TextLink";

export default {
  name: "OrderIdLink",
  components: {TextLink},
  props: {
    orderId: {
      type: Number
    }
  },
}
</script>

<style scoped>

</style>