<template>
  <div>
    <list-table
      :columns="columns"
      :create-route="{
                  name: 'reportQueries.create'
                }"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      identifier="reportQueriesTable"
      show-filters-immediately>

      <template v-slot:member-quick-action-template="{ row }">
        <member-quick-action-modal
          :id="row.memberId"
        />
      </template>

    </list-table>
  </div>
</template>

<script>
import ListTable from "../../listTable/ListTable";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal.vue";
import ReportQueryRepository from "@/repositories/ReportQueryRepository";
import {FILTER_ROLES} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowReportQueries",
  components: {MemberQuickActionModal, ListTable},
  data() {
    return {
      title: 'Gérer rapports',
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir requête',
              route: (row) => {
                return {
                  name: 'reportQueries.edit',
                  params: {
                    reportQueryId: row.id,
                  },
                  query: {readonly: true}
                };
              },
            },
            {
              type: 'edit',
              hint: 'Modifier requête',
              route: (row) => {
                return {
                  name: 'reportQueries.edit',
                  params: {
                    reportQueryId: row.id
                  }
                };
              }
            },
            {
              type: 'delete',
              hint: 'Delete',
              confirmation: {
                title: 'Abandonner requête?',
                text: 'Étes vous sure que vous voulez abandonner ce requête?'
              },
              successText: 'Le requête a été abandonné',
              deleteAction: (row) => ReportQueryRepository.delete(row.id),
              deletePermissions: ['REPORT_QUERY_MANAGE']
            }
          ]
        },
        {
          caption: '#',
          width: 80,
          dataField: 'id',
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'Nom',
          dataField: 'name',
          sort: {
            dataField: 'name',
          }
        },
        {
          caption: 'Description',
          dataField: 'description',
          sort: {
            dataField: 'description',
          },
          visible: false,

        },
        {
          caption: 'Créé par',
          dataField: 'memberId',
          cellTemplate: "member-quick-action-template",
          visible: false,
        },
      ],
      filters: [
        {
          caption: 'ID',
          filterKey: 'id',
          small: true,
          forceIsVisible: true,
        },
        {
          caption: 'Nom',
          filterKey: 'name',
          small: true,
          forceIsVisible: true,
        },
        {
          caption: 'Description',
          filterKey: 'description',
          small: true,
          forceIsVisible: true,
        },
        {
          caption: 'Query',
          filterKey: 'query',
          forceIsVisible: true,
        },
        {
          caption: 'Rôles graphique',
          filterKey: 'graphRoles',
          forceIsVisible: true,
          type: FILTER_ROLES,
        },
      ],
      repositoryFunc: ReportQueryRepository.getPaginated,
    }
  },
}

</script>
