<template>
  <div>
    <page-title>
      Créer tâche
    </page-title>

    <card>
      <create-or-update-task-form
        mode="create"
      />
    </card>
  </div>
</template>

<script>
import CreateOrUpdateTaskForm from "@/components/pages/Tasks/Form/CreateOrUpdateTaskForm";
import Card from "@/components/elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle";

export default {
  name: "CreateTask",
  components: {PageTitle, Card, CreateOrUpdateTaskForm}
}
</script>

<style scoped>

</style>