<template>
  <select-element
    :allow-unselecting="allowUnselecting"
    :disabled="disabled"
    :enable-search="false"
    :label="label"
    :limit-option-menu-width="limitOptionMenuWidth"
    :occupy-validation-space="occupyValidationSpace"
    :options="getFilteredVatCodes"
    :prioritized-options="mostUsedVatCodes"
    :validation-rules="validationRules"
    :value="value"
    @blur="$emit('blur', $event)"
    @input="$emit('input', $event)"
  ></select-element>
</template>

<script>
import AccountingBankMovementRepository from "../../../../repositories/AccountingBankMovementRepository";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  name: "VatCodesSelectInput",
  components: {SelectElement},
  props: {
    value: {
      required: true
    },
    label: {
      default: 'Code TVA',
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    /**
     * Whether it is possible to click on the selected option again to unselect it an emit null.
     */
    allowUnselecting: {
      type: Boolean,
      default: true
    },

    limitOptionMenuWidth: {
      type: Boolean,
      default: true
    },

    options: {
      required: false,
      validator: val => typeof val === "object"
    },

    disabled: {
      type: Boolean,
      default: false
    },

    internalInvoice: {
      type: Boolean,
      default: false
    },

    externalInvoice: {
      type: Boolean,
      default: false
    },

    currentBookingAccountNumber: {
      type: Number,
      required: false,
      default: null
    },
    memberId: {
        type: Number,
        default: null
    },

  },
  mounted() {
    if (this.options === undefined) {
      this.fetchVatCodes().then(() =>{
          this.setDefault();
          this.emitLoaded();
      } );
    } else {
      this.availableVatCodes = this.options;
      this.setDefault();
      this.emitLoaded();
    }
  },
  methods: {
    // Executed when available booking accounts are loaded.
    setDefault() {
      const codes = this.getFilteredVatCodes.flatMap(c => c.value);

      // When the currently selected is not included in the list anymore,
      // we reset the selection.
      if (this.value !== null && !codes.includes(this.value)) {
        this.$emit('input', null);
      }

      // Whenever the list of available Vat Codes changes and there is only one code, preselect it.
      if (codes.length === 1) {
        this.$emit('input', codes[0]);
      }
    },

    fetchVatCodes() {
      return AccountingBankMovementRepository
        .getVatCodes(this.memberId)
        .then((res) => {
          this.availableVatCodes = res.data.data.map(c => {
            if (c.countUsagePerMember > 0) {
                this.mostUsedVatCodes.push(c.code);
            }
            return {
              value: c.code,
              label: c.name
            };
          });
        });
    },
    emitLoaded() {
        this.$emit('loaded', this.availableVatCodes, this.mostUsedVatCodes);
    },
  },
  data: () => ({
    availableVatCodes: [],
    mostUsedVatCodes: []
  }),
  watch: {
    getFilteredVatCodes: function () {
      this.setDefault();
    }
  },
  computed: {
    getInvoiceVatNumber() {
      return this.$store.getters['invoices/getInvoiceVatNumber'];
    },
    isInvoiceVatNumberLuxembourgish() {
      return this.getInvoiceVatNumber !== null && this.getInvoiceVatNumber.startsWith("LU");
    },
    isInvoiceVatNumberForeign() {
      return this.getInvoiceVatNumber !== null && !this.getInvoiceVatNumber.startsWith("LU");
    },

    getFilteredVatCodes() {
      const vatNumber = this.getInvoiceVatNumber;
      let availableVatCodes = this.availableVatCodes;

      /*
        Internal
       */

      if (this.internalInvoice) {
        if (vatNumber === null) {
          return availableVatCodes;
        }

        // Internal and Luxembourgish -> Only allow NSS, NUP
        if (this.isInvoiceVatNumberLuxembourgish) {
          return availableVatCodes.filter(c => ['NSS', 'NUP'].includes(c.value));
        } else if (this.isInvoiceVatNumberForeign) {
          // Internal && Not Luxembourgish -> Only allow 'ESEPR', 'ESTRF', 'ISSP'
          return availableVatCodes.filter(c => ['ESEPR', 'ESTRF', 'ISSP'].includes(c.value));
        }
      }


      /*
        External
       */

      if (this.externalInvoice) {

        const selectedBookingAccountNumber = this.currentBookingAccountNumber?.toString();
        if (selectedBookingAccountNumber === null || selectedBookingAccountNumber === undefined) {

          return availableVatCodes.map(c => {
              if (c.countUsagePerMember > 0) {
                  this.mostUsedVatCodes.push(c.code);
              }
              return c;
          });
        }

        /*
              Customer / Supplier is from Luxembourg
         */
        if (this.isInvoiceVatNumberLuxembourgish) {
          // BookingAccount starts with 60 -> Only allow NSTF
          if (selectedBookingAccountNumber.startsWith("60")) {
            return availableVatCodes.filter(c => ['NSTF'].includes(c.value));
          }

          // BookingAccount starts with 61 -> Only allow NSGSD
          if (selectedBookingAccountNumber.startsWith("61")) {
            return availableVatCodes.filter(c => ['NSGSD'].includes(c.value));
          }

          // BookingAccount starts with 2 -> Only allow NSID
          if (selectedBookingAccountNumber.startsWith("2")) {
            return availableVatCodes.filter(c => ['NSID'].includes(c.value));
          }


        }

        /*
             Customer / Supplier is from a foreign country
        */
        if (this.isInvoiceVatNumberForeign) {
          // BookingAccount starts with 60 -> Only allow ESTD, EUMPE, ISIT, IUPIM
          if (selectedBookingAccountNumber.startsWith("60")) {
            return availableVatCodes.filter(c => ['ESTD', 'EUMPE', 'ISIT', 'IUPIM'].includes(c.value));
          }

          // BookingAccount starts with 61 -> Only allow ESSDD, EUMFE, ISIGS, IPI
          if (selectedBookingAccountNumber.startsWith("61")) {

              return availableVatCodes.filter(c => ['ESSDD', 'EUMFE', 'ISIGS', 'IPI'].includes(c.value));
          }

          // BookingAccount starts with 2 -> Only allow ESIGD, EUDTI
          if (selectedBookingAccountNumber.startsWith("2")) {
            return availableVatCodes.filter(c => ['ESIGD', 'EUDTI'].includes(c.value));
          }
        }

      }

      return availableVatCodes;
    }
  }
}
</script>

